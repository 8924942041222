export class DrawingComment {
  constructor(obj = null, existingComment = null) {
    if(obj) {
      let sourceData = JSON.parse(obj.sourceData)
      
      this.uuid = obj.uuid
      this.contentType = obj.contentType
      this.sourceType = obj.sourceType
      this.text = sourceData.text
      this.x = sourceData.x
      this.y = sourceData.y
      this.scale = sourceData.scale
      this.page = sourceData.page
      this.task = obj.task
      this.src = obj.file
      this.user = obj.creator
      this.createDate = obj.createDate
      this.hover = false
    }
    if(existingComment) {
      this.uuid = existingComment.uuid
      this.contentType = existingComment.contentType
      this.sourceType = existingComment.sourceType
      this.text = existingComment.text
      this.x = existingComment.x
      this.y = existingComment.y
      this.scale = existingComment.scale
      this.page = existingComment.page
      this.task = existingComment.task
      this.src = existingComment.src
      this.user = existingComment.user
      this.createDate = existingComment.createDate
      this.hover = existingComment.hover
    }
  }
}