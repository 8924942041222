<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 32.00 32.00"
  >
    <path
      fill="currentColor"
      d="
        M 3.41 4.14
        L 28.74 10.95
        A 0.35 0.35 0.0 0 1 29.00 11.29
        L 29.00 17.51
        A 0.35 0.35 0.0 0 1 28.65 17.86
        L 3.40 17.83
        A 0.35 0.35 0.0 0 1 3.05 17.48
        L 2.97 4.48
        A 0.35 0.35 0.0 0 1 3.41 4.14
        Z"
    />
    <path
      fill="currentColor"
      d="
        M 4.20 25.22
        L 5.49 25.24
        A 0.27 0.26 -46.1 0 1 5.50 25.77
        Q 4.97 25.80 4.48 25.65
        Q 3.63 25.39 3.67 26.27
        L 3.77 28.49
        Q 3.78 28.68 3.59 28.71
        L 3.28 28.75
        A 0.07 0.07 0.0 0 1 3.20 28.68
        L 3.14 22.55
        Q 3.14 22.28 3.40 22.30
        L 3.71 22.33
        Q 3.78 22.33 3.77 22.50
        L 3.61 24.58
        A 0.59 0.59 0.0 0 0 4.20 25.22
        Z"
    />
    <path
      fill="currentColor"
      d="
        M 27.87 25.67
        Q 27.19 25.79 26.50 25.77
        A 0.27 0.27 0.0 0 1 26.50 25.23
        Q 27.05 25.22 27.59 25.30
        Q 28.28 25.41 28.28 24.70
        L 28.25 22.50
        Q 28.25 22.32 28.48 22.30
        L 28.73 22.28
        Q 28.78 22.27 28.79 22.33
        L 28.83 28.47
        Q 28.84 28.75 28.56 28.72
        L 28.28 28.70
        Q 28.24 28.69 28.25 28.50
        L 28.35 26.10
        Q 28.37 25.58 27.87 25.67
        Z"
    />
    <path
      fill="currentColor"
      d="
        M 8.40 25.24
        L 11.73 25.26
        Q 11.93 25.26 11.94 25.47
        L 11.94 25.74
        Q 11.94 25.76 11.93 25.76
        L 8.30 25.73
        Q 8.18 25.73 8.18 25.72
        L 8.18 25.44
        Q 8.19 25.24 8.40 25.24
        Z"
    />
    <path
      fill="currentColor"
      d="
        M 17.35 25.33
        Q 18.20 25.44 17.37 25.66
        Q 16.20 25.97 14.51 25.67
        Q 13.50 25.49 14.51 25.30
        Q 15.60 25.09 17.35 25.33
        Z"
    />
    <path
      fill="currentColor"
      d="
        M 22.74 25.18
        Q 23.04 25.17 23.35 25.27
        A 0.26 0.26 0.0 0 1 23.26 25.78
        L 20.52 25.75
        A 0.25 0.25 0.0 0 1 20.48 25.26
        Q 21.13 25.16 21.77 25.21
        Q 21.90 25.23 22.74 25.18
        Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>
