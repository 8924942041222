export default {
  namespaced: true,

  state: {
    // Объект с полями
    // error	"access_denied"
    // error_description	"Доступ запрещен"
    error: null,
  },

  getters: {
    errorInfo: ({ error }) => error?.['error_description'],
  },

  mutations: {
    SET_ERROR: (state, payload) => {
      state.error = payload
    },

    RESET_ERROR: (state) => {
      state.error = null
    }
  },

  actions: {
    clearError ({ commit }) {
      commit('RESET_ERROR')
    }
  }
}