import { ClassificatorTree, ClassificatorNode, ClassificatorNodeRule, SmetaUtils } from "."
import store from '@/store'

export class SmetaTreeStateCache {
  constructor () {
    this.tree = new ClassificatorTree
    this.opened = new WeakSet()
    this.openedCount = 0

    this.searchText = null
    this.founded = null

    this.rulesCount = 0
    this.validRulesCount = 0
    this.withSmetaCount = 0
  }

  get totalNodes () {
    return this.tree.onlyNodes.length
  }
  
  get totalEndpoints () {
    return this.tree.endpointUuids.length
  }

  counts() {
    this.rulesCount = 0
    this.validRulesCount = 0
    this.withSmetaCount = 0

    this.tree.map.forEach(el => {
      if (el.nodeType.name == "RULE") {
        this.rulesCount++
        if (el.logicValidy && el.logicValidy.valid) this.validRulesCount++
      } else {
        el.hasGrandSmeta ? this.withSmetaCount++ : this.withSmetaCount
      }
    })
  }

  getNode(uuid) {
    return this.tree.map.get(uuid)
  }

  collapseAll() {
    this.opened = new WeakSet()
    this.openedCount = 0
  }

  expandRules() {
    let r = SmetaUtils.flatListRules(this.tree.items)
    this.opened = new WeakSet(r)
    this.openedCount = r.length
  }

  expandAll() {
    this.opened = new WeakSet(this.tree.onlyNodes)
    this.openedCount = this.totalNodes
  }

  expandTo(uuid) {
    // this.collapseAll()
    let node = this.tree.map.get(uuid)
    if (node) {
      let f = true
      while(f){
        if (!this.opened.has(node)) {
          this.opened.add(node)
          this.openedCount++
        }
        if (node.parent) node = this.tree.map.get(node.parent.uuid)
        else f = false
      }
    }
  }

  saveTree (tree) {
    this.opened = new WeakSet()
    this.openedCount = 0

    this.searchText = null
    this.founded = null
    
    this.tree = new ClassificatorTree(tree)
    this.tree.map.forEach(el => {
      if (el.hasRules) this.markWithRules(el)
      if (el.hasValidRule || (el.logicValidy && el.logicValidy.valid)) this.markWithValidRules(el)
    })

    this.counts()
  }

  markWithRulesList(list) {
    let arr = list.filter(n => n.hasRules)
    arr.forEach(n => this.markWithRules(n))
  }

  markWithRules(node) {
    let par = node.parent
    while (par) {
      par.hasRules = true
      par = par.parent
    }
  }

  markWithValidRules(node) {
    let par = node.parent
    while (par) {
      par.hasValidRule = true
      par = par.parent
    }
  }

  markWithLinks(nodeUuid, isWormNodeLink, isTaskLink, isCompoudLink, isClassificatorNodeLink, isAttributeDictionaryLink) {
    let node = this.tree.map.get(nodeUuid)
    while (node) {
      if (isWormNodeLink) node.hasWormNodeLink = true
      if (isTaskLink) node.hasTaskLink = true
      if (isCompoudLink) node.hasCompundLink = true
      if (isClassificatorNodeLink) node.hasClassificatorNodeLink = true
      if (isAttributeDictionaryLink) node.hasAttributeDictionaryLink = true
      node = node.parent
    }
  }

  addRootElement(data) {
    let newElem = new ClassificatorNode(data.element, 0, null)
    this.tree.items.push(newElem)
    this.tree.map.set(newElem.uuid,newElem)
    this.tree.endpointUuids.push(newElem.uuid)
  }

  addParentElement(data) {
    let chldNode = this.tree.map.get(data.parentForUuid)
    let parent
    if (data.element.parent) parent = this.tree.map.get(data.element.parent.uuid)
    
    let newElem = new ClassificatorNode(data.element, parent ? parent.level + 1 : 0, parent ? parent : null)
    this.tree.map.set(newElem.uuid,newElem)

    if (parent) {
      parent.children.push(newElem)
    } else {
      this.tree.items.push(newElem)
    }
    
    this.moveElement({moveUuid:chldNode.uuid, toUuid:newElem.uuid})
    newElem.hasChilds = (newElem.children && newElem.children.length>0) ? true : false
    newElem.hasRules = newElem.children.some(el => el.isRule || el.hasRules)
    newElem.isPenult = newElem.children.every(child => child.children.length == 0)
    if (newElem.parent) newElem.parent.isPenult = false

    // if (!newElem.hasRules) {
    //   newElem.hasRules = true
    //   this.markWithRules(newElem)
    // }
    if (newElem.hasRules && parent) {
      parent.hasRules = true
      this.markWithRules(parent)
    }
    this.tree.onlyNodes.push(newElem)

    if (parent) {
      parent.hasChilds = (parent.children && parent.children.length>0) ? true : false
      parent.isPenult = parent.children.every(child => child.children.length == 0)
      if (parent.parent) parent.parent.isPenult = false

      if (!this.opened.has(parent)) {
        this.opened.add(parent)
        this.openedCount++
      }
    }

  }

  addChildElement(data) {
    if (data.elemType == 'RULE') {
      let parent = this.tree.map.get(data.parentUuid)

      let newElem = new ClassificatorNodeRule(data.element, parent.level + 1, parent)
      parent.children.push(newElem)

      if (!this.tree.map.has(newElem.uuid)) this.tree.map.set(newElem.uuid,newElem)

      let ind
      ind = this.tree.endpointUuids.findIndex(el => el.uuid == newElem.uuid)
      if (ind == -1) this.tree.endpointUuids.push(newElem.uuid)

      ind = this.tree.onlyNodes.findIndex(el => el.uuid == parent.uuid)
      if (ind == -1) this.tree.onlyNodes.push(parent)

      if (!parent.hasRules) {
        parent.hasRules = true
        this.markWithRules(parent)
      }

      if (newElem.hasValidRule || (newElem.logicValidy && newElem.logicValidy.valid)) this.markWithValidRules(newElem)

      parent.hasChilds = (parent.children && parent.children.length>0) ? true : false
      parent.isPenult = parent.children.every(child => child.children.length == 0)
      if (parent.parent) parent.parent.isPenult = false

      if (!this.opened.has(parent)) {
        this.opened.add(parent)
        this.openedCount++
      }
      this.counts()
      return newElem
    } else {
      let parent = null
      if (data.parentUuid) parent = this.tree.map.get(data.parentUuid)

      let newElem = new ClassificatorNode(data.element, parent.level + 1, parent)

      if (!this.tree.map.has(newElem.uuid)) this.tree.map.set(newElem.uuid,newElem)

      let ind
      ind = this.tree.endpointUuids.findIndex(el => el.uuid == newElem.uuid)
      if (ind == -1) this.tree.endpointUuids.push(newElem.uuid)

      parent.children.push(newElem)

      ind = this.tree.onlyNodes.findIndex(el => el.uuid == parent.uuid)
      if (ind == -1) this.tree.onlyNodes.push(parent)

      parent.hasChilds = (parent.children && parent.children.length>0) ? true : false
      parent.isPenult = parent.children.every(child => child.children.length == 0)
      if (parent.parent) parent.parent.isPenult = false
      
    }

    this.counts()
  }

  updateElement(data) {
    let node = this.tree.map.get(data.uuid)
    if (node) {
      if (node.nodeType.name != 'RULE') {
        node.nodeType = data.nodeType
        node.pressmark = data.pressmark
        node.title = data.title
        node.unitType = data.unitType
        node.unitValue = data.unitValue
        node.taskUuid = data.taskUuid
      } else {
        node.findRule = data.findRule
        node.notGetMaterials = data.notGetMaterials
        node.dataExportType = data.dataExportType
        node.showVolume = data.showVolume
        node.title = data.title
        node.titleAttribute = data.titleAttribute
        node.unitExpression = data.unitExpression
        node.unitExpressionFormula = data.unitExpressionFormula
        node.titleAttributeFormula = data.titleAttributeFormula
        node.ruleType = data.ruleType
        node.elementName = data.elementName
        node.elementValue = data.elementValue
        node.logicValidy = data.logicValidy

        if (node.hasValidRule || (node.logicValidy && node.logicValidy.valid)) this.markWithValidRules(node)
      }
    }

    this.counts()

    return node
  }

  removeElementByUuid(uuid, selectRule) {
    let node = this.tree.map.get(uuid)
    let parent
    if (node.parent) parent = this.tree.map.get(node.parent.uuid)

    let ind
    if (parent) {
      ind = parent.children.findIndex(el => el.uuid == uuid)
      if (ind > -1) parent.children.splice(ind,1)
      if (node.children) {
        node.children.forEach(el => {
          if(!el.isRule){
            el.level = el.level - 1
            el.isPenult = el.children.every(child => child.children.length == 0)
            el.parent = parent
          } else {
            if(selectRule && selectRule.uuid == el.uuid){
              store.commit('smeta/setEditRuleItem', null)
            }
            ind = node.children.findIndex(nd => nd.uuid == el.uuid)
            if (ind > -1) node.children.splice(ind,1)
          }
          
        })
        parent.children = parent.children.concat(node.children)
      }
      
      if (!parent.children.some(el => !el.isRule || !el.hasRules)) parent.hasRules = false

      parent.hasChilds = (parent.children && parent.children.length>0) ? true : false
      parent.isPenult = parent.children.every(child => child.children.length == 0)
      if (!parent.isPenult && parent.parent) parent.isPenult = false

      if (!parent.hasRules) {
        let pp = parent.parent
        while (pp) {
          pp.hasRules = pp.children.some(el => el.isRule || el.hasRules)
          if (!pp.hasRules) pp = pp.parent
          else pp = false
        }
      }
    } else {
      if (node.children) {
        ind = this.tree.items.findIndex(el => el.uuid == uuid)
        if (ind > -1) this.tree.items.splice(ind,1)
        node.children.forEach(el => {
          el.level = el.level - 1
          el.isPenult = el.children.every(child => child.children.length == 0)
          el.parent = null
        })
        this.tree.items = this.tree.items.concat(node.children)
      }
    }

    this.tree.map.delete(uuid)

    ind = this.tree.onlyNodes.findIndex(el => el.uuid == uuid)
    if (ind > -1) this.tree.onlyNodes.splice(ind,1)

    ind = this.tree.endpointUuids.findIndex(el => el.uuid == uuid)
    if (ind > -1) this.tree.endpointUuids.splice(ind,1)

    if (this.opened.has(node)) {
      this.opened.delete(node)
      this.openedCount--
    }
    
    this.counts()

  }

  removeElementByUuidDeep(uuid) {
    let node = this.tree.map.get(uuid)
    
    let ind
    let allChlids = SmetaUtils.flatlist(node.children)
    allChlids.forEach(chldEl => {
      this.tree.map.delete(chldEl.uuid)

      ind = this.tree.onlyNodes.findIndex(el => el.uuid == chldEl.uuid)
      if (ind > -1) this.tree.onlyNodes.splice(ind,1)

      ind = this.tree.endpointUuids.findIndex(el => el.uuid == chldEl.uuid)
      if (ind > -1) this.tree.endpointUuids.splice(ind,1)

      if (this.opened.has(chldEl)) {
        this.opened.delete(chldEl)
        this.openedCount--
      }
    })

    let parent
    if (node.parent) parent = this.tree.map.get(node.parent.uuid)

    if (parent){
      ind = parent.children.findIndex(el => el.uuid == uuid)
      if (ind > -1) parent.children.splice(ind,1)
    } else {
      ind = this.tree.items.findIndex(el => el.uuid == uuid)
      if (ind > -1) this.tree.items.splice(ind,1)
    }

    if (this.opened.has(node)) {
      this.opened.delete(node)
      this.openedCount--
    } 

    this.counts()
  }

  moveElement(data){
    let moveNode = this.tree.map.get(data.moveUuid)
    let toNode = this.tree.map.get(data.toUuid)

    let ind
    let nodeParent
    if (moveNode.parent) {
      nodeParent = this.tree.map.get(moveNode.parent.uuid)
      ind = nodeParent.children.findIndex(e => e.uuid == moveNode.uuid)
      if (ind > -1) nodeParent.children.splice(ind,1)
      nodeParent.hasRules = nodeParent.children.some(el => el.isRule || el.hasRules)
    } else {
      ind = this.tree.items.findIndex(e => e.uuid == moveNode.uuid)
      this.tree.items.splice(ind,1)
    }

    moveNode.parent = toNode
    toNode.children.push(moveNode)
    this.recountLevel(moveNode, toNode.level+1)
    toNode.hasRules = toNode.children.some(el => el.isRule || el.hasRules)

  }

  recountLevel(node, level) {
    node.level = level
    node.children.forEach(e => this.recountLevel(e, node.level+1))
  }

  bindGrandsmeta(grandSmeta) {
    if (this.tree.map && grandSmeta) {
      for (let chpt of grandSmeta.chapters){
        for (let pos of chpt.positions){
          if (pos.classificatorNodeUuid) {
            let node = this.tree.map.get(pos.classificatorNodeUuid)
            if (node) {
              node.grandSmetaPosition = pos
              node.hasGrandSmeta = true
              node.hasGrandSmetaAndRules = node.hasRules && node.hasGrandSmeta
              let par = node.parent
              while (par) {
                par.hasGrandSmeta = true
                par.hasGrandSmetaAndRules ||= node.hasGrandSmetaAndRules
                par = par.parent
              }
            }
          }

          if (pos.resources) {
            for (let res of pos.resources) {
              if (res.classificatorNodeUuid) {
                let node = this.tree.map.get(res.classificatorNodeUuid)
                if (node) {
                  node.grandSmetaPosition = res
                  node.hasGrandSmeta = true
                  node.hasGrandSmetaAndRules = node.hasRules && node.hasGrandSmeta
                  let par = node.parent
                  while (par) {
                    par.hasGrandSmeta = true
                    par.hasGrandSmetaAndRules ||= node.hasGrandSmetaAndRules
                    par = par.parent
                  } 
                }
              }
            }
          }
        }
      }
    }
  }
}