export class SearchElementsFilter {
  constructor(json) {
    this.searchText = json?.searchText ?? ""
    this.projectUuid = json?.projectUuid ?? ""
    this.bclassUuids = json?.bclassUuids ?? []
    this.page = json?.page ?? 0
    this.pageSize = json?.pageSize ?? 35
    this.elementUuids = null
  }

  resetFilter() {
    this.searchText = ""
    this.projectUuid = ""
    this.bclassUuids = []
    this.page = 0
    this.pageSize = 35
    this.elementUuids = null
  }

  setProjectUuid(uuid) {
    if (!this.projectUuid) this.projectUuid = uuid
    if (this.projectUuid != uuid) {
      this.resetFilter()
      this.projectUuid = uuid
    }
  }
}