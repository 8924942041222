import DeviceDetector from "device-detector-js"

// Example
// const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36"

export class AppDevice {
  #_device

  constructor () {
    const deviceDetector = new DeviceDetector()
    this.#_device= deviceDetector.parse(navigator.userAgent).device;
    console.log(this.#_device.type);
    // return deviceDetector.parse(navigator.userAgent)

    // const clientInfo = deviceDetector.parse(navigator.userAgent)
    // return Object.freeze(clientInfo)
  }

  get isDesktop () {
    return this.#_device?.type === 'desktop'
  }

  get isPortable () {
    return this.#_device && this.#_device.type !== 'desktop'
  }

  get isSmatphone () {
    return this.#_device && this.#_device.type !== 'smartphone'
  }
}

// export const device = new AppDevice