<template lang="pug">
v-checkbox.app-checkbox( 
  v-bind="$attrs"
  :input-value="selected"
  :ripple="false"
  :on-icon="onIcon"
  :off-icon="offIcon"
  hide-details 
  @change="$emit('update:selected', $event)"
)
  template( v-if="$slots.default" #label )
    .app-checkbox__label
      slot
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'update:selected'
  },
  props: {
    selected: Array,
    onIcon: {
      type: String,
      default: '$checkbox-on-icon'
    },
    offIcon: {
      type: String,
      default: '$checkbox-off-icon'
    },
  },
}
</script>

<style>
.app-checkbox .v-icon__component,
.app-checkbox .v-input--selection-controls__input {
  width: 20px;
  height: 20px;
}
.app-checkbox__label {
  color: var(--v-primary-darken1);
  line-height: 24px;
  font-size: 12px;
}
</style>