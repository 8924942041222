import { XeokitNode } from "../xeokit/XeokitNode/XeokitNode"
import { GridAxis } from "./gridAxis"

const NO_STATE_INHERIT = false
export class Grid {
  constructor(owner, cfg = {}) {
    this.uuid = cfg.uuid ?? null

    this.owner = owner
    this.node = null

    this.uAxes = {}
    this.vAxes = {}
    this.wAxes = {}

    this.config = {
      matrix: cfg.matrix,
      labelScale: cfg.labelScale,
      color: cfg.color ?? [0.5, 0.5, 0.5],
      font: cfg.font,
      glowThrough: cfg.glowThrough ?? false,
      visible: cfg.visible ?? true,
      revisionAabb: cfg.revisionAabb ?? null
    }

    this.zCoord = null

    this.culled = false

    this.#create(cfg)
  }
  
  get axes() {
    return Object.assign(this.uAxes, this.vAxes, this.wAxes)
  }

  #create(cfg) {
    this.node = new XeokitNode(this.owner)

    const uAxes = cfg.u
    const vAxes = cfg.v
    const wAxes = cfg.w
  
    for (const axis of uAxes) {
      this.uAxes[axis.uuid] = new GridAxis(this, this.node, Object.assign(axis, this.config))
    }
    for (const axis of vAxes) { 
      this.vAxes[axis.uuid] = new GridAxis(this, this.node, Object.assign(axis, this.config)) 
    }
    for (const axis of wAxes) {
      this.wAxes[axis.uuid] = new GridAxis(this, this.node, Object.assign(axis, this.config)) 
    }

    this.owner.addChild(this.node, NO_STATE_INHERIT)
  }

  setVisible(value) {
    let flag
    if (this.culled) flag = false
    else flag = value 

    Object.keys(this.uAxes).forEach(uuid => {
      this.uAxes[uuid].setVisible(flag)
    })
    Object.keys(this.vAxes).forEach(uuid => {
      this.vAxes[uuid].setVisible(flag)
    })
    Object.keys(this.wAxes).forEach(uuid => {
      this.wAxes[uuid].setVisible(flag)
    })
  }

  setGlowThrough(value) {
    Object.keys(this.uAxes).forEach(uuid => {
      this.uAxes[uuid].setGlowThrough(value)
    })
    Object.keys(this.vAxes).forEach(uuid => {
      this.vAxes[uuid].setGlowThrough(value)
    })
    Object.keys(this.wAxes).forEach(uuid => {
      this.wAxes[uuid].setGlowThrough(value)
    })
  }

  redrawByAabb(aabb) {
    Object.keys(this.uAxes).forEach(uuid => {
      this.uAxes[uuid].redrawByAabb(aabb)
    })
    Object.keys(this.vAxes).forEach(uuid => {
      this.vAxes[uuid].redrawByAabb(aabb)
    })
    Object.keys(this.wAxes).forEach(uuid => {
      this.wAxes[uuid].redrawByAabb(aabb)
    })
  }

  setCulled(value) {
    this.culled = value
  }
}