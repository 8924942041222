import { defineStore } from 'pinia'

export const useGridStore = defineStore('viewer-grid', {
  state: () => {
    return {
      gridsGlowingThrough: true,
      isGridsAvailable: true,
      isGridsActive: false,
      needDebounce: true,
      gridsIfcTree: null
    }
  },
})