<template lang="pug">

  div.floating-panel-body-container( ref="elementBody" :style="style" ) 
    div( :style="resizable ? 'height: calc(100% - 4px);' : 'height: 100%'" )
      div.floating-panel-body-content( :style="styleBody" )
        slot
      div.floating-panel-body-resizer-height

    div.floating-panel-body-resizer-container( ref="elementResizer" v-show="resizable" )
      div.floating-panel-body-resizer
</template>

<script>

export default {
  name: "FloatingPanelBody",

  props: {
    panel: null,

    minHeight: {
      type: [String, Number],
      default: 36
    },

    backgroundColor: {
      type: String,
      default: "white"
    },

    maxHeight: {
      type: [String, Number],
    },

    bodyHeight: {
      type: [String, Number],
    },

    headerBorderSize: {
      type: [String, Number]
    },

    resizable: {
      type: Boolean,
      default: false
    },

    overflow: {
      type: String,
      default: "auto"
    },

    startingHeight:{
      type:[String, Number]
    },
  },

  mounted(){
    this.$emit('createElementBody', this.$refs.elementBody, this.$refs.elementResizer)
  },

  beforeDestroy() {
    this.$emit('destroyElementBody', this.$refs.elementBody?.offsetHeight)
  },

  computed: {
    style() {
      return {
        'min-height': this.minHeight + "px",
        'margin-top': this.headerBorderSize + "px"
      }  
    },

    styleBody() {
      return {
        'background-color': this.backgroundColor,
        'overflow': this.overflow
      }
    },
  },

}
</script>

<style>
.floating-panel-body-container{
  width: 100%;
  overflow:hidden;
  min-height: 25px;
}

.floating-panel-body{
  height: calc(100% - 4px);
}

.floating-panel-body-content{
  /* overflow: auto; */
  border-radius: 0px 0px 3px 3px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.floating-panel-body-resizer-height{
  height: 3px;
}

.floating-panel-body-resizer-container{
  width: 100%;
  padding-top: 2px;
}

.floating-panel-body-resizer {
  cursor: row-resize;
  width: 40px;
  height: 2px;
  /* background: #C4C4C4; */
  background:  var(--v-surface-lighten3);
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.floating-panel-body-resizer:before{
  content: '';
  position: relative;
  display: block;
  height: 2px;
  top: 0px;
  border-radius: 2px;
  background: var(--v-accent-base);
}
</style>