import search from "./collision-search.module"
import table from "./collision-table.module"

import { api } from '@/api'

const getDefaultState = () => {
  return {
    settings: [
      'displayedStatus', 'displayedSection', 'displayedModelA', 'displayedModelB', 'displayedSample', 'displayedFloorA', 'displayedNote', 'displayedAxis', 'displayedDistance', 'displayedResponder'
    ],
    tab: null,
    prevTab: null,
    width: {
      tree: window.innerWidth / 4 - 45,
      rules: window.innerWidth / 2 - 45,
      atributes: window.innerWidth / 4 - 45,
    },
    // TODO: временно
    collisionPickedElement: null,
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  modules: {
    search,
    table
  },

  state,

  getters: {
    
  },

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },
    
    setTab: (state, tab) => {state.tab = tab},
    setPrevTab: (state, prevTab) => {state.prevTab = prevTab},
    setSettings: (state, settings) => { state.settings = settings },

    setTreeWidth: (state, width) => { state.width.tree = width },
    setRulesWidth: (state, width) => { state.width.rules = width },
    setAttrWidth: (state, width) => { state.width.atributes = width },

    // TODO: временно
    setCollisionPickedElement: (state, el) => { 
      if (state.collisionPickedElement) state.collisionPickedElement.highlighted = false
      state.collisionPickedElement = el
      if (state.collisionPickedElement != null) state.collisionPickedElement.highlighted = true
    },
  },

  actions: {
    setNote ({ dispatch }, { uuid, text }) {
      api.collisions.setNote(uuid, text)
      .then(data => {
        dispatch('table/replaceCollisions', data)
        dispatch('rule/reloadTree', null, { root: true })
        dispatch('axis/tree/fetchTree', null, { root: true })
      })
    },

    setNoteToGroup ({ dispatch }, {uuids, text}) {
      api.collisions.setNoteToGroup(uuids, text)
      .then(data => {
        dispatch('table/replaceCollisions', data)
        dispatch('rule/reloadTree', null, { root: true })
        dispatch('axis/tree/fetchTree', null, { root: true })
      })
    },

    setCamera (obj, { uuid, camera }) {
      api.collisions.setCamera(uuid, camera)
    },

    setCameraToGroup (obj, { uuids, camera }) {
      api.collisions.setCameraToGroup(uuids, camera)
    },

    setAllowed ({ dispatch }, { uuid }) {
      api.collisions.setAllowed(uuid)
      .then(data => {
        dispatch('table/replaceCollisions', data)
      })
    },

    /**
     * @deprecated
     */
    allowCollisions ({ dispatch }, uuids) {
      api.collisions.allowCollisions(uuids)
      .then(data => {
        dispatch('table/replaceCollisions', data)
        dispatch('rule/reloadTree', null, { root: true })
        dispatch('axis/tree/fetchTree', null, { root: true })
        dispatch('task/loadTasks', null, { root: true })

      })
    },

    setCollisionStatus ({ dispatch }, { uuid, status, prevStatus }) {
      return api.collisions.setCollisionStatus(uuid, status).then(collision => { 
        dispatch('table/replaceCollisions', collision)
        dispatch('task/loadTasks', null, { root: true })
        if (status === 'ALLOWED' || prevStatus === 'ALLOWED') {
          dispatch('rule/reloadTree', null, { root: true })
          dispatch('axis/tree/fetchTree', null, { root: true })
        }
        return collision
      })
    }, 

    setCollisionsStatus({ dispatch, rootState }, { collisions, status }) {
      let isNeedReload = false
      if(collisions.length == 1){
        let curCollision = rootState.collision.table.tableItems.find(col => col.uuid === collisions[0])
        isNeedReload = (curCollision?.status?.name != 'ALLOWED' && status === 'ALLOWED') || (curCollision?.status?.name === 'ALLOWED' && status != 'ALLOWED')
      } else isNeedReload = true
      
      return api.collisions.setCollisionsStatus(collisions, status).then(collision => {
        dispatch('table/replaceCollisions', collision)
        dispatch('task/loadTasks', null, { root: true })
        if (isNeedReload) {
          dispatch('rule/reloadTree', null, { root: true })
          dispatch('axis/tree/fetchTree', null, { root: true })
        }
      })
    },
  }
}