// import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { EmphasisMaterial, Mesh, PhongMaterial, VBOGeometry } from "@xeokit/xeokit-sdk";

const NO_COLOR = [0, 0, 0]
export class CrosshairLines {

  #owner; #pin;
  #origin;
  #visible; #color;

  constructor(owner, cfg = {}) {
    this.#owner = owner

    this.#pin = null
    this.#origin = cfg.origin ?? [0, 0, 0]

    this.#visible = cfg.visible ?? true
    this.#color = cfg.color ?? [1, 1, 1]

    this.#create()
  }

  #create() {
    const geometry = new VBOGeometry(this.#owner, {
      primitive: 'lines',
      positions: [
        0, -0.5, 0, 0, 0.5, 0, // y axis
        -0.5, 0, 0, 0.5, 0, 0, // x axis
        0, 0, -0.5, 0, 0, 0.5 // z axis
      ],
      indices: [0, 1, 2, 3, 4, 5]
    })

    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255]
    const material = new PhongMaterial(this.#owner, {
      emissive: color,
      diffuse: NO_COLOR,
      ambient: NO_COLOR,
    })

    const selectedMaterial = new EmphasisMaterial(this.#owner, {
      fill: false,
      edges: true,
      edgeColor: color,
      edgeAlpha: 1,
    })

    this.#pin = new Mesh(this.#owner, {
      geometry: geometry,
      material: material,
      selected: true,
      selectedMaterial: selectedMaterial,
      visible: this.#visible,
      origin: this.#origin,
      clippable: false
    })

    this.#owner.addChild(this.#pin, true)
  }

  destroy() {
    this.#pin?.destroy()
    this.#pin = null
  }
}