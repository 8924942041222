let themes = {
  DARK: {
    name: 'dark',
    title: 'Темная',
    style: {
      base: '#535353',      
      darken1: '#282828',
      darken2: '#dddddd',
      darken3: '#8c8c8c',  
      lighten1: '#666666',  
      lighten2: '#B5B5B5',
      lighten4: '#282828',
      toolbar: {
        base: '#434343',
        darken1: '#1E1E1E',
        lighten1: '#FFFFFF'
      },   // Шапка проекта и дашборда
      scroll: { // Цвета скролла в меню и коллизиях таблицы (может на все скроллы повесить?)
        base: "#666666",
        // darken1: "#6D6D6D",
      },
    },
  },
  LIGHT: {
    name: 'light',
    title: 'Светлая',
    style: {
      base: '#dbdbdb',
      darken1: '#303030', 
      darken2: '#303030',
      darken3: '#B5B5B5',
      lighten1: '#e8e8e8',
      lighten2: '#b5b5b5',
      lighten4: '#F0F0F0',
      toolbar: {            // Шапка проекта и дашборда
        base: '#888888',
        darken1: '#FFFFFF',
        lighten1: '#000000'
      },
      scroll: { // Цвета скролла в меню и коллизиях таблицы (может на все скроллы повесить?)
        base: "#e8e8e8",
        // darken1: "#778094",
      },
    }
  },
  GREY: {
    name: 'grey',
    title: 'Серая',
    style: {
      base: '#A3A3A3',
      darken1: '#303030', 
      darken2: '#303030',
      darken3: '#d0d0d0',
      lighten1: '#b8b8b8',
      lighten2: '#D0D0D0',
      lighten4: '#6c6c6c',
      toolbar: {  // Шапка проекта и дашборда
        base: '#858585',
        darken1: '#FFFFFF',
        lighten1: '#000000'
      },
      scroll: { // Цвета скролла в меню и коллизиях таблицы (может на все скроллы повесить?)
        base: "#dbdbdb",
        // darken1: "#1f3c51",
      },
    }
  }
}

export {
  themes
}

let currentTheme = themes.DARK

try {
  currentTheme = JSON.parse(localStorage.getItem("theme")).style
} catch {
  currentTheme = themes.DARK
}

export default {
  namespaced: true,

  state: {
    theme: currentTheme
  },

  getters: {
    themeName: ({ theme }) => theme.name,
    themeStyle: ({ theme }) => theme.style,
  },

  mutations: {
    setTheme: (state, theme) => {
      state.theme = theme
      Object.keys(themes).map((k,i) => {
        if (themes[k].name===theme.name) localStorage.setItem("themeItem", i)
      })
      localStorage.setItem("themeName", theme.name)
      localStorage.setItem('theme', JSON.stringify(theme))
    },
  },

  actions: {
    SET_THEME: ({ commit }, theme) => {
      commit('setTheme', theme)
    },
  }
}