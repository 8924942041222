<template lang="pug">
  v-list(light dense)
    .sud-list__wrapper(v-for="(item, index) in items" :key="index" test-id="fab-item")
      v-divider(v-if="item.divider")
      v-list-item.sud-list__item(v-else :disabled="item.disabled" ripple tag="div" @click="handleSelect(item, index)")
        slot(name="list-item" :item="item" :index="index")
          v-progress-circular.sud-list__item-icon(v-if="item.loading" size="18" width="3" indeterminate)
          v-icon.sud-list__item-icon.material-icons-outlined(v-else-if="item.icon" :disabled="item.disabled" size="24") {{ item.icon }}
          span.pl-4.sud-list__item-text {{ itemText ? item[itemText] : item.title }}
</template>

<script>
export default {
  name: 'app-list',
  props: {
    items: Array,
    itemText: String
  },
  methods: {
    handleSelect (item, index) {
      if (item.action) {
        item.action()
      }
      this.$emit('select', item, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.sud-list__item[aria-disabled] {
  color: #C4C4C4;
}

.sud-list__item {
  margin: 8px 0px;
  padding: 0px;

  color: #505254;

  &:hover {
    background: var(--v-surface-lighten1);
    color: var(--v-primary-darken2);
  }
}

.sud-list__item-icon {
  position: absolute;
  left: 8px;
}

.sud-list__item-text {
  margin-left: 32px;

  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
</style>
