import { defineStore } from "pinia"
import { useDrawingsStore } from "@/pinia"
import { activateTransformController,
         setCroppingMode,
         toggleCroppingMode,
         toggleCommentMode,
         toggleFitMode,
         startPageLoading,
         imageWidth,
         imageHeight } from "@/components/drawings/facades/drawingPage.facade"
import { isDrawingSettingMode } from "../../facades/imageSectionPlanes.facade"

export const useDrawingPageState = defineStore('drawing-page-state', {
  getters: {
    grabbingCursor() { 
      if ( useDrawingsStore().drawingIsGrabbing ) return { cursor: "grabbing" }
      else return ""
    },

    isCroppingModeActive: () => useDrawingsStore().isCroppingModeActive,
    isCommentModeActive: () => useDrawingsStore().commentMode,
    isFittingModeActive: () => useDrawingsStore().pageIsFittingSize,
    isDrawingSettingMode: () => isDrawingSettingMode(),

    imageLayoutWidth: () => imageWidth(),
    imageLayoutHeight: () => imageHeight()
  },

  actions: {
    startPageLoading(page) {
      return startPageLoading(page)
    },

    activateTransformController() {
      activateTransformController()
    },

    setCroppingMode(value) {
      setCroppingMode(value)
    }, 

    toggleCroppingMode() {
      toggleCroppingMode()
    },

    toggleCommentMode() {
      toggleCommentMode()
    }, 

    toggleFitMode() {
      toggleFitMode()
    }
  }
})