import { useSelectionFrameStore } from '@/pinia'
import { VTF } from '@/plugins/viewerTools/VTF'

/*eslint-disable no-dupe-class-members*/
export class SelectionFrame {

  static get #_selectionFrameStore() {
    return useSelectionFrameStore()
  }

  /** Текущая активность выделения рамкой */
  static get isSelectionFrameActive() {
    return this.#_selectionFrameStore.isSelectionFrameActive
  }

  /**
   * Включение рамки выделения
   */
  static activate() {
    if (!this.isSelectionFrameActive) {
      this.#_selectionFrameStore.setSelectionFrameStatus(true)
      VTF.SelectionFrame.activate()
    }
  }
  
  /**
   * Выключение рамки выделения
   */
  static deactivate() {
    if (this.isSelectionFrameActive) {
      this.#_selectionFrameStore.setSelectionFrameStatus(false)
      VTF.SelectionFrame.deactivate()
    }
  }
}