<template lang="pug">
v-autocomplete(
  ref="appAutocomplete"
  :value="value"
  :items="items"
  :label="label"
  :item-text="itemText" 
  :item-value="itemValue"
  :return-object="!itemValue || returnObject"
  :multiple="multiple"
  :rules="rules"
  :clearable="clearable"
  :background-color="backgroundColor"
  color="var(--v-accent-base)"
  :menu-props="{ ...menuProps, maxWidth: maxWidth }"
  dense
  flat
  filled
  hide-details
  light
  outlined
  v-bind="$attrs"
  :no-data-text="$t('missingData')"
  
  @change="handleChange" 
  @input="handleSelect"
  @focus="handleFocus"
  @blur="handleBlur"
  @click:append-outer="$emit('click:append-outer')"
)
</template>

<script>
export default {
  name: "AppAutocomplete",
  
  props: {
    value: [String, Number, Object, Array],
    label:  String,
    items: Array,
    itemText: [String, Function],
    itemValue: String,
    multiple: Boolean,
    clearable: Boolean,
    rules: [Object, Array],
    returnObject:{ type: Boolean, default: false },
    backgroundColor: { type: String, default: "#fff" },
    menuProps: {
      type: [String, Object, Array],
      default: () => ({ 
        bottom: true, offsetY: true, contentClass: 'menu-scroll', light: true
      })
    },
  },

  data() {
    return {
      focused: false,
      maxWidth: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.maxWidth = this.$refs?.appAutocomplete?.$el?.clientWidth ?? null
    });
  },

  methods: {
    handleChange(event) {
      this.$emit('change', event)
    },

    handleSelect(event) {
      this.$emit('input', event)
    },

    handleFocus() {
      this.focused = true
    },

    handleBlur() {
      this.focused = false
    }
  },
}
</script>