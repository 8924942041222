export default ({ Factory }) => ({

  /**
   * Список типов схем
   */
  schemas: () => Factory.schemas.get(),

  /**
   * Список Классификаций
   */
  classificationAll: () => Factory.bclassificators.get(),

  /**
   * Создание новой классификации
   * @param {Object} obj Данные классификации
   */
  createClassification: (obj) => Factory.bclassificators.post(obj),
  
  /**
   * Редактирование классификации
   * @param {Object} obj Данные классификации
   */
  editClassification: (obj) => Factory.bclassificators.put(obj),
  
  /**
   * Удаление классификатора
   * @param {String} uid UUID классификатора
   */
  deleteClassification: (uid) => Factory.bclassificators[uid].delete(),
  
  
  /**
   * Список классов по UUID классификатора
   * @param {String} uid UUID классификатора
   */
  bClassesAll: (uid) => Factory.bclassificators[uid].bclasses.get(),
  
  /**
   * Создание нового класса
   * @param {String} uid UUID классификатора
   * @param {Object} bClass Данные класса
   */
  createBClass: (uid, bClass) => Factory.bclassificators[uid].bclasses.post(bClass),
  
  /**
   * Редактирование класса
   * @param {Object} obj Данные класса
   */
  editBClass: (obj) => Factory.bclasses.put(obj),
  
  /**
   * Удаление класса
   * @param {String} uid UUID класса
   */
  deleteBClass: (uid) => Factory.bclasses[uid].delete(),
  
  
  // BAttributeType
  /**
   * Спикок типов атрибута
   */
  bAttributeTypes: () => Factory.battributetypes.get(),
})