import { math } from "@xeokit/xeokit-sdk"
import { geometry } from "./geometry"

export const highPrecisionPick = {

  getObjectFaceByRay(entity, ray) {
    let pickedMeshData = null
    let meshesData = []
    for (let i = 0; i < entity.meshes.length; i++) {
      meshesData.push(this.getPickedTriangleAndHisMeshGeometryData(entity.meshes[i], ray))
    }

    let destIndex = 0
    let pickedDistance = meshesData[0].pickedDistance
    for (let i = 1; i < meshesData.length; i++) {
      if (meshesData[i].pickedDistance < pickedDistance) {
        pickedDistance = meshesData[i].pickedDistance
        destIndex = i
      }
    }

    // Получение полигона и преобразование данных - в среднем 65 мс
    pickedMeshData = meshesData[destIndex]

    const positions = pickedMeshData.positions
    const normals = pickedMeshData.normals
    const pickedTriangleIndices = pickedMeshData.pickedTriangleIndices
    const edges = pickedMeshData.edges

    const adjacentTrianglesIndices = []

    const enclosingEdges = []

    const thresholdDot = Math.cos(math.DEGTORAD * 10)

    let pickedTriangleNormal = normals[pickedTriangleIndices[0] / 3]

    adjacentTrianglesSearch(pickedTriangleIndices)

    const adjacentTriangles = []
    for (let i = 0; i < adjacentTrianglesIndices.length; i++) {
      adjacentTriangles.push(positions[adjacentTrianglesIndices[i]])
    }

    //return pickedMeshData
    return {
      entity: entity,
      trianglesPositions: adjacentTriangles,
      faceEdges: enclosingEdges,
    }

    // function adjacentTrianglesSearch(triangleIndices) {
    //   // const enclosingEdges = []
    //   for(let index = 0; index < positions.length; index+=3) {
    //     for(let j = 0; j < adjacentTrianglesIndices.length; j+=3) {
    //       if (index == adjacentTrianglesIndices[j]) {
    //         index+=3
    //         j = -3
    //         if (index >= positions.length) {
    //           return
    //         }
    //       }
    //     }
    //     let t1 = [positions[triangleIndices[0]], positions[triangleIndices[1]], positions[triangleIndices[2]]]
    //     let t2 = [positions[index], positions[index + 1], positions[index + 2]]
    //     if (areTrianglesAdjacent(t1, t2, index)) {
    //       for(let k = 0; k < edges.length; k++) {
    //         if (compareEdges(edges[k], t2)) {
    //           enclosingEdges.push(edges[k])
    //         }
    //       }
    //       adjacentTrianglesIndices.push(index, index + 1, index + 2)
    //       adjacentTrianglesSearch([index, index + 1, index + 2])
    //     }
    //   }
    //   // return enclosingEdges
    // }

    function adjacentTrianglesSearch(triangleIndices) {
      const numPositions = positions.length
      // const numAdjacentTrianglesIndices = adjacentTrianglesIndices.length;
      const selectedEdgesMap = {} // Используем объект для хранения выбранных рёбер

      const t1 = [positions[triangleIndices[0]], positions[triangleIndices[1]], positions[triangleIndices[2]]]
      let t2 = []

      for (let index = 0; index < numPositions; index += 3) {
        if (adjacentTrianglesIndices.includes(index)) continue

        t2 = [positions[index], positions[index + 1], positions[index + 2]]

        if (areTrianglesAdjacent(t1, t2, index)) {
          for (const edge of edges) {
            if (compareEdges(edge, t2)) {
              selectedEdgesMap[edge] = true // Используем ребро в качестве ключа
              enclosingEdges.push(edge)
            }
          }
          adjacentTrianglesIndices.push(index, index + 1, index + 2)
          adjacentTrianglesSearch([index, index + 1, index + 2])
        }
      }
    }

    function compareEdges(edge, triangle) {
      for (let i = 0; i < triangle.length; i++) {
        if (compare(edge[0], triangle[i])) {
          for (let j = 0; j < triangle.length; j++) {
            if (compare(edge[1], triangle[j])) {
              return true
            }
          }
        }
      }
      return false
    }

    function areTrianglesAdjacent(t1, t2, index) {
      let dot = math.dotVec3(normals[index / 3], pickedTriangleNormal)
      if (dot > thresholdDot) {
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 3; j++) {
            if (compare(t1[i], t2[j])) {
              return true
            }
          }
        }
      }
      return false
    }

    function compare(point1, point2) {
      const epsilon = 0.000000001
      return (
        Math.abs(point1[0] - point2[0]) < epsilon && Math.abs(point1[1] - point2[1]) < epsilon && Math.abs(point1[2] - point2[2]) < epsilon
      )
    }
  },

  getPickedTriangleAndHisMeshGeometryData(mesh, ray) {
    const mulMat4v4 = geometry.math.mulMat4v3
    const transformByModelSettings = geometry.transform.transformByModelSettings
    const cross3Vec3 = math.cross3Vec3
    const getRayPlaneIntersectionPoint = geometry.intersection.getRayPlaneIntersectionPoint

    let pickedTriangleIndices = []
    const transformedPositions = []
    const transformedNormals = []

    const rayTrianglesIntersectionPoints = []
    let pickedDist = null

    let tempVec3a = math.vec3()
    let tempVec3b = math.vec3()
    let tempVec3c = math.vec3()
    const uniquePositions = []
    const indicesLookup = []
    const weldedIndices = []
    const compa = new Uint16Array(3)
    const compb = new Uint16Array(3)
    const compc = new Uint16Array(3)
    const a = math.vec3()
    const b = math.vec3()
    const c = math.vec3()
    const faces = []

    const positions = mesh.positions
    const indices = mesh.indices
    const indicesLength = indices.length
    const positionsDecodeMatrix = mesh.positionsDecodeMatrix
    const entityMatrix = mesh.entityMatrix
    const entityModel = mesh

    const indicesReverseLookup = []
    const edgeIndices = []

    weldVertices(positions, indices)
    buildFaces(indicesLength, positionsDecodeMatrix)

    const thresholdDot = Math.cos(math.DEGTORAD * 10)
    const edges = {}
    let edge1
    let edge2
    let index1
    let index2
    let key
    let largeIndex = false
    let edge
    let normal1
    let normal2
    let dot
    let ia
    let ib
    for (let i = 0, len = indices.length; i < len; i += 3) {
      const faceIndex = i / 3
      for (let j = 0; j < 3; j++) {
        edge1 = weldedIndices[i + j]
        edge2 = weldedIndices[i + ((j + 1) % 3)]
        index1 = Math.min(edge1, edge2)
        index2 = Math.max(edge1, edge2)
        key = index1 + ',' + index2
        if (edges[key] === undefined) {
          edges[key] = {
            index1: index1,
            index2: index2,
            face1: faceIndex,
            face2: undefined,
          }
        } 
        else {
          edges[key].face2 = faceIndex
        }
      }
    }
    for (key in edges) {
      edge = edges[key]
      // an edge is only rendered if the angle (in degrees) between the face normals of the adjoining faces exceeds this value. default = 1 degree.
      if (edge.face2 !== undefined) {
        normal1 = faces[edge.face1].normal
        normal2 = faces[edge.face2].normal
        dot = math.dotVec3(normal1, normal2)
        if (dot > thresholdDot) {
          continue
        }
      }
      ia = indicesReverseLookup[edge.index1]
      ib = indicesReverseLookup[edge.index2]
      if ((!largeIndex && ia > 65535) || ib > 65535) {
        largeIndex = true
      }
      edgeIndices.push(ia)
      edgeIndices.push(ib)
    }

    const edgeIndexes = largeIndex ? new Uint32Array(edgeIndices) : new Uint16Array(edgeIndices)
    const builtEdges = []
    for (let i = 0; i < edgeIndexes.length; i += 2) {
      let compPosA = [positions[edgeIndexes[i] * 3], positions[edgeIndexes[i] * 3 + 1], positions[edgeIndexes[i] * 3 + 2]]
      let compPosB = [positions[edgeIndexes[i + 1] * 3], positions[edgeIndexes[i + 1] * 3 + 1], positions[edgeIndexes[i + 1] * 3 + 2]]
      let decompA = []
      let decompB = []

      if (positionsDecodeMatrix) {
        if (entityMatrix) {
          math.decompressPosition(compPosA, positionsDecodeMatrix, decompA)
          mulMat4v4(entityMatrix, decompA, decompA)
          math.decompressPosition(compPosB, positionsDecodeMatrix, decompB)
          mulMat4v4(entityMatrix, decompB, decompB)
        } 
        else {
          math.decompressPosition(compPosA, positionsDecodeMatrix, decompA)
          math.decompressPosition(compPosB, positionsDecodeMatrix, decompB)
        }

        const tileCenter = mesh.layer._state.origin

        const a = transformByModelSettings([decompA[0] + tileCenter[0], decompA[1] + tileCenter[1], decompA[2] + tileCenter[2]], mesh)
        const b = transformByModelSettings([decompB[0] + tileCenter[0], decompB[1] + tileCenter[1], decompB[2] + tileCenter[2]], mesh)
        edge = [a, b]
      } 
      else {
        edge = [
          [positions[ia * 3], positions[ia * 3 + 1], positions[ia * 3 + 2]],
          [positions[ib * 3], positions[ib * 3 + 1], positions[ib * 3 + 2]],
        ] // ПОЧЕМУ НЕ EDGEINDEXES???
      }

      builtEdges.push(edge)
    }
    //return (largeIndex) ? new Uint32Array(edgeIndices) : new Uint16Array(edgeIndices);

    return {
      pickedTriangleIndices: pickedTriangleIndices,
      positions: transformedPositions,
      weldedIndices: weldedIndices, // Вроде как уже отсортированы по welded - может не стоит возвращать?
      edgeIndices: edgeIndices,
      edges: builtEdges,
      normals: transformedNormals,
      pickedDistance: pickedDist,
    }

    function weldVertices(positions, indices) {
      const positionsMap = {}
      let vx
      let vy
      let vz
      let key
      const precision = 10000
      let i
      let len
      let lenUniquePositions = 0
      for (i = 0, len = positions.length; i < len; i += 3) {
        vx = positions[i]
        vy = positions[i + 1]
        vz = positions[i + 2]
        key = vx * precision + '_' + vy * precision + '_' + vz * precision
        if (positionsMap[key] === undefined) {
          positionsMap[key] = lenUniquePositions / 3
          uniquePositions[lenUniquePositions++] = vx
          uniquePositions[lenUniquePositions++] = vy
          uniquePositions[lenUniquePositions++] = vz
        }
        indicesLookup[i / 3] = positionsMap[key]
      }
      for (i = 0, len = indices.length; i < len; i++) {
        weldedIndices[i] = indicesLookup[indices[i]]
        indicesReverseLookup[weldedIndices[i]] = indices[i]
      }
    }

    function buildFaces(numIndices, positionsDecodeMatrix) {
      const intersectedTriangleIndices = []

      for (let i = 0, len = numIndices; i < len; i += 3) {
        const triangle = []
        const triangleIndices = []
        const ia = weldedIndices[i] * 3
        const ib = weldedIndices[i + 1] * 3
        const ic = weldedIndices[i + 2] * 3
        if (positionsDecodeMatrix) {
          compa[0] = uniquePositions[ia]
          compa[1] = uniquePositions[ia + 1]
          compa[2] = uniquePositions[ia + 2]
          compb[0] = uniquePositions[ib]
          compb[1] = uniquePositions[ib + 1]
          compb[2] = uniquePositions[ib + 2]
          compc[0] = uniquePositions[ic]
          compc[1] = uniquePositions[ic + 1]
          compc[2] = uniquePositions[ic + 2]
          // Decode
          if (entityMatrix) {
            math.decompressPosition(compa, positionsDecodeMatrix, a)
            mulMat4v4(entityMatrix, a, a)
            math.decompressPosition(compb, positionsDecodeMatrix, b)
            mulMat4v4(entityMatrix, b, b)
            math.decompressPosition(compc, positionsDecodeMatrix, c)
            mulMat4v4(entityMatrix, c, c)
          } 
          else {
            math.decompressPosition(compa, positionsDecodeMatrix, a)
            math.decompressPosition(compb, positionsDecodeMatrix, b)
            math.decompressPosition(compc, positionsDecodeMatrix, c)
          }
        } 
        else {
          a[0] = uniquePositions[ia]
          a[1] = uniquePositions[ia + 1]
          a[2] = uniquePositions[ia + 2]
          b[0] = uniquePositions[ib]
          b[1] = uniquePositions[ib + 1]
          b[2] = uniquePositions[ib + 2]
          c[0] = uniquePositions[ic]
          c[1] = uniquePositions[ic + 1]
          c[2] = uniquePositions[ic + 2]
        }

        const tileCenter = mesh.layer._state.origin
        triangle.push(transformByModelSettings([a[0] + tileCenter[0], a[1] + tileCenter[1], a[2] + tileCenter[2]], entityModel))
        triangle.push(transformByModelSettings([b[0] + tileCenter[0], b[1] + tileCenter[1], b[2] + tileCenter[2]], entityModel))
        triangle.push(transformByModelSettings([c[0] + tileCenter[0], c[1] + tileCenter[1], c[2] + tileCenter[2]], entityModel))
        transformedPositions.push(...triangle)
        triangleIndices.push(i, i + 1, i + 2)

        const face = { normal: math.vec3(), coords: [] }
        face.coords = triangle

        let polygonN = math.vec3()
        math.subVec3(triangle[0], triangle[1], tempVec3a)
        math.subVec3(triangle[0], triangle[2], tempVec3b)
        cross3Vec3(tempVec3a, tempVec3b, tempVec3c)
        math.normalizeVec3(tempVec3c, polygonN)
        face.normal = polygonN

        faces.push(face)
        transformedNormals.push(polygonN)

        let polygonO = triangle[0]
        let polygonEdges = [
          [triangle[0], triangle[1]],
          [triangle[1], triangle[2]],
          [triangle[2], triangle[0]],
        ]
        let intersPoint = getRayPlaneIntersectionPoint(ray.o, ray.dir, polygonN, polygonO)

        // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
        if (isPointOnPolygon(polygonN, polygonEdges, intersPoint)) {
          rayTrianglesIntersectionPoints.push(intersPoint)
          intersectedTriangleIndices.push(triangleIndices)
        }
      }

      //Вернуть ближайшую из них
      if (rayTrianglesIntersectionPoints.length != 0) {
        math.subVec3(rayTrianglesIntersectionPoints[0], ray.o, tempVec3a)
        pickedDist = Math.abs(math.lenVec3(tempVec3a))
        pickedTriangleIndices = intersectedTriangleIndices[0]
        for (let i = 1; i < rayTrianglesIntersectionPoints.length; i++) {
          if (rayTrianglesIntersectionPoints[i]) {
            math.subVec3(rayTrianglesIntersectionPoints[i], ray.o, tempVec3a)
            let dist2 = Math.abs(math.lenVec3(tempVec3a))
            if (dist2 < pickedDist) {
              // Обновить pickedTriangleIndices
              pickedTriangleIndices = intersectedTriangleIndices[i]
              pickedDist = dist2 // СПОРНОЕ
            }
          }
        }
      } 
      else {
        pickedDist = Number.MAX_VALUE
      }
    }

    function isPointOnPolygon(planeN, edges, rayO) {
      let tempVec3a = math.vec3()
      let voluntaryVec = math.vec3([1, 1, 1])
      math.cross3Vec3(planeN, voluntaryVec, tempVec3a)
      let lenCrossVec = math.lenVec3(tempVec3a)
      if (lenCrossVec >= -0.01 && lenCrossVec <= 0.01) {
        voluntaryVec = math.vec3([-0.2, -0.8, -0, 5])
      }
      let crossVec = math.vec3()
      math.cross3Vec3(voluntaryVec, planeN, crossVec)

      let intersCount = 0
      for (let i = 0; i < edges.length; i++) {
        if (isRayIntersectsSegment(rayO, crossVec, edges[i][0], edges[i][1])) {
          intersCount++
        }
      }

      if (intersCount & 1) {
        // Нечет
        return true
      } 
      else return false
    }

    function isRayIntersectsSegment(rayO, rayV, p1, p2) {
      let tempVec3a = math.vec3()
      let tempVec3b = math.vec3()
      let tempVec3c = math.vec3()

      let rayP = []
      math.mulVec3Scalar(rayV, 10, tempVec3a)
      math.addVec3(rayO, tempVec3a, rayP)

      let x0 = rayO[0]
      let x1 = rayP[0]
      let x2 = p1[0]
      let x3 = p2[0]

      let y0 = rayO[1]
      let y1 = rayP[1]
      let y2 = p1[1]
      let y3 = p2[1]

      let z0 = rayO[2]
      let z1 = rayP[2]
      let z2 = p1[2]
      let z3 = p2[2]

      let d1343 = (x0 - x2) * (x3 - x2) + (y0 - y2) * (y3 - y2) + (z0 - z2) * (z3 - z2)
      let d4321 = (x3 - x2) * (x1 - x0) + (y3 - y2) * (y1 - y0) + (z3 - z2) * (z1 - z0)
      let d1321 = (x0 - x2) * (x1 - x0) + (y0 - y2) * (y1 - y0) + (z0 - z2) * (z1 - z0)
      let d4343 = (x3 - x2) * (x3 - x2) + (y3 - y2) * (y3 - y2) + (z3 - z2) * (z3 - z2)
      let d2121 = (x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0) + (z1 - z0) * (z1 - z0)

      let muA = (d1343 * d4321 - d1321 * d4343) / (d2121 * d4343 - d4321 * d4321)
      let muB = (d1343 + muA * d4321) / d4343

      let pointOne = math.vec3()
      let pointTwo = math.vec3()
      math.subVec3(rayP, rayO, tempVec3a)
      math.mulVec3Scalar(tempVec3a, muA, tempVec3b)
      math.addVec3(rayO, tempVec3b, pointOne)

      math.subVec3(p2, p1, tempVec3a)
      math.mulVec3Scalar(tempVec3a, muB, tempVec3b)
      math.addVec3(p1, tempVec3b, pointTwo)

      math.subVec3(pointTwo, pointOne, tempVec3a)
      let pointsDist = Math.abs(math.lenVec3(tempVec3a))
      if (pointsDist >= -0.0001 && pointsDist <= 0.0001) {
        // Доделать поиск по расстояниям
        math.subVec3(p2, p1, tempVec3a)
        math.subVec3(pointOne, p1, tempVec3b)
        math.subVec3(p2, pointOne, tempVec3c)
        let abLength = Math.abs(math.lenVec3(tempVec3a))
        let acLength = Math.abs(math.lenVec3(tempVec3b))
        let bcLength = Math.abs(math.lenVec3(tempVec3c))
        if (isCloseEqual(acLength + bcLength, abLength)) {
          // Если сумма расстояний между точками ребра и найденной точкой равна длине ребра, то точка лежит внутри него
          math.subVec3(pointOne, rayO, tempVec3a)
          math.normalizeVec3(tempVec3a)
          if (math.dotVec3(tempVec3a, rayV) >= 0) {
            // Дополнительно проверяем, лежит ли найденная точка на луче, а не позади него
            return true
          } 
          else return false
        } 
        else return false
      } 
      else return false
    }

    function isCloseEqual(a, b) {
      return a >= b - 0.0001 && a <= b + 0.0001
    }
  },
}