<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 48.00 48.00">
<path stroke="#d6d6d6" stroke-opacity="0.651" stroke-width="2.00" fill="none" stroke-linecap="butt" vector-effect="non-scaling-stroke" d="
  M 21.00 17.00
  A 3.00 3.00 0.0 0 0 18.00 14.00
  A 3.00 3.00 0.0 0 0 15.00 17.00
  A 3.00 3.00 0.0 0 0 18.00 20.00
  A 3.00 3.00 0.0 0 0 21.00 17.00"
/>
<path fill="currentColor" d="
  M 24.95 17.00
  A 6.95 6.95 0.0 0 1 18.00 23.95
  A 6.95 6.95 0.0 0 1 11.05 17.00
  A 6.95 6.95 0.0 0 1 18.00 10.05
  A 6.95 6.95 0.0 0 1 24.95 17.00
  Z
  M 21.00 17.00
  A 3.00 3.00 0.0 0 0 18.00 14.00
  A 3.00 3.00 0.0 0 0 15.00 17.00
  A 3.00 3.00 0.0 0 0 18.00 20.00
  A 3.00 3.00 0.0 0 0 21.00 17.00
  Z"
/>
<path fill="currentColor" d="
  M 27.31 11.23
  Q 27.03 10.76 27.54 10.59
  C 36.00 7.65 40.86 19.12 33.00 23.31
  Q 30.59 24.59 27.66 23.42
  Q 27.14 23.21 27.41 22.73
  Q 30.67 16.95 27.31 11.23
  Z"
/>
<circle fill="transparent" fill-opacity="0.298" cx="18.00" cy="17.00" r="3.00"/>
<path fill="currentColor" d="
  M 4.23 38.00
  A 0.42 0.42 0.0 0 1 3.81 37.58
  L 3.81 35.73
  A 14.10 8.19 -0.0 0 1 17.91 27.54
  L 18.11 27.54
  A 14.10 8.19 -0.0 0 1 32.21 35.73
  L 32.21 37.58
  A 0.42 0.42 0.0 0 1 31.79 38.00
  L 4.23 38.00
  Z
  M 20.06 31.65
  Q 18.53 31.21 17.24 31.44
  Q 13.22 32.17 9.45 33.22
  A 0.26 0.26 0.0 0 0 9.52 33.74
  L 26.27 33.75
  Q 27.38 33.75 26.37 33.28
  C 24.39 32.36 22.19 32.26 20.06 31.65
  Z"
/>
<path fill="currentColor" d="
  M 35.90 37.30
  C 36.29 34.93 35.49 28.81 32.71 27.90
  A 0.05 0.05 0.0 0 1 32.73 27.80
  C 38.03 28.28 45.25 30.64 43.91 37.48
  Q 43.81 38.00 43.28 38.00
  L 36.50 38.00
  A 0.61 0.61 0.0 0 1 35.90 37.30
  Z"
/>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>