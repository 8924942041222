<template lang="pug">
.d-flex.align-center
  div.relative( style="margin-left: 65px;" )
    v-icon( size="18" color="var(--v-primary-darken2)") mdi-filter

  app-toolbar-item.filters-fonts.ml-2( :items="listOwners" :mainItem="$t('filters.allProjects')"
    key-name="name" min-width="120" :all="$t('headerToolbar.all')" @select="filterByOwner") {{ currentOwner }}

  app-toolbar-item.filters-fonts.ml-2.mr-3( :items="listColorMarkers" :mainItem="$t('filters.showAll')" 
    key-name="color" :all="$t('headerToolbar.all')" min-width="120" @select="filterByColorMarker") 
    span.toolbar-fonts(v-if="!selectedMarkerColor") {{ $t('filters.showAll') }}
    span.filters-fonts.marker-color-block(v-else) {{ $t('filters.show') }}
      span.filters-fonts.marker-color.ml-1(v-bind:style="{ background: selectedMarkerColor.color }")
    template(slot="list-item" slot-scope="{ item, index }" )
      span(v-if="item.value == -1") {{ item.color }}
      span.filters-fonts.marker-color-block(v-else-if="item.value == 0") {{ $t('filters.showAll') }}
      span.filters-fonts.marker-color-block(v-else) {{ $t('filters.show') }}
        span.marker-color.ml-1(v-bind:style="{ background: item.color }")
        
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { App } from '@app/App'

export default {
  name: "DashboardToolbar",

  computed: {
    ...mapState('dashboard', ['selectedOwner', 'selectedMarkerColor', 'owners']),
    ...mapGetters('dashboard', ['filteredProjects']),

    currentOwner () {
      return this.selectedOwner ? this.selectedOwner.name : this.$t('filters.allProjects')
    },

    listOwners () {
      let list = [
        {
          name: this.$t('filters.allProjects'),
          value: 0,
        },
        {
          name: this.$t('filters.iOwner'),
          value: 1,
        },
        {
          name: this.$t('filters.iMember'),
          value: 2
        }
      ]
      return list
    },

    listColorMarkers () {
      let list = []
      list.push({color: '#747474', value: 0})
      App.assets.colors.forEach((c, index) => {
        list.push({color: c, value: index+1})
      })
      
      return list
    }
  },

  methods: {
    ...mapMutations('dashboard', ['selectOwner', 'selectMarkerColor', 'setOwners']),

    filterByColorMarker(item) {
      if (item.value === 0)
        this.selectMarkerColor(null)
      else
        this.selectMarkerColor(item)
    },

    filterByOwner(item) {
      if (item.value == 0)
        this.selectOwner(null)
      else
        this.selectOwner(item)
    },
  }
}
</script>

<style scoped>
.marker-color-block {
  height: 20px;
  /* padding: 0 30px; */
  border-radius: 10px;
  display: inline-block;
  font-style: normal;
}

.marker-color {
  width: 22px;
  height: 12px;
  margin: 0 auto;
  border-radius: 2px;
  margin-bottom: -2px;
  display: inline-block;
}

.toolbar-fonts {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}

.filters-fonts {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
</style>