import { Curve } from '../core/curve.js'
import { QuadraticBezier } from '../core/interpolations.js'
import { Vector2 } from '../../../src/math/vector2.js'

class QuadraticBezierCurve extends Curve {
  constructor(v0 = new Vector2(), v1 = new Vector2(), v2 = new Vector2()) {
    super()

    this.isQuadraticBezierCurve = true

    this.type = 'QuadraticBezierCurve'

    this.v0 = v0
    this.v1 = v1
    this.v2 = v2
  }

  getPoint(t, optionalTarget = new Vector2()) {
    const point = optionalTarget

    const v0 = this.v0,
      v1 = this.v1,
      v2 = this.v2

    point.set(QuadraticBezier(t, v0.x, v1.x, v2.x), QuadraticBezier(t, v0.y, v1.y, v2.y))

    return point
  }

  copy(source) {
    super.copy(source)

    this.v0.copy(source.v0)
    this.v1.copy(source.v1)
    this.v2.copy(source.v2)

    return this
  }

  toJSON() {
    const data = super.toJSON()

    data.v0 = this.v0.toArray()
    data.v1 = this.v1.toArray()
    data.v2 = this.v2.toArray()

    return data
  }

  fromJSON(json) {
    super.fromJSON(json)

    this.v0.fromArray(json.v0)
    this.v1.fromArray(json.v1)
    this.v2.fromArray(json.v2)

    return this
  }
}

export { QuadraticBezierCurve }
