<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="450px"
    height="101px"
    viewBox="0 0 450 101"
    enable-background="new 0 0 450 101"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="450"
      height="101"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcIAAABlCAYAAADeduOnAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
            CXBIWXMAAAsTAAALEwEAmpwYAAAbF0lEQVR42u3deXzU1b3/8dfnO5PMBEIAl1qtG3pdaqvgD9zq
            UrUuuLbWyv2pFcW19tp7tVZ7va2NuLa2Vks3tSIkIGq0LiAE1Jau1qv2atV6a7VV0dpaF1AkySQz
            875/zIiAAbLM+Z5vZs7z8cgDCMk57/OF5JM53/M9xySdCuwO1FO9OoEVwBvA68BLwEtm9hffwfpL
            0t7AaQ67eNjMbhwiWV8zs4sctl91JH0T+JDDLq43s0d8j3Od1+AxDgHuA5ptAldVpE3p08CnfY8t
            6Ldu4JE0sAcwCWjwncihLqADWAq8BbwIvCDpSeApM3vWd8B+GAOc4LD9CKhIIYwh6z8lXWJmOYd9
            VA1JWeBEYGOH3bQDiS6EiLMw6oCzJb5phirQ6sdx+389cKMTsDQwEhjlO41jmfI4Ny3/eZ9V/1LS
            X4CHgNvMbIHvsOvRUB6PKyOHUNYtgB/h9lVnNfkBsLnjPrK+B7kueogGjInlP27BY+xGZQp3I27/
            rwduZICREaVXSp2+03i2LXASMF/SS5KukrSd71Br0e24/Y4hlBXgVEnbx9DPkFb+/xzHDww9vse6
            TnUcDgxb+WfjsxVqucv30IIB6QQ6It8pEmhL4D+BP0tqkbST70DBes3xHWAIuMV3gIRYc/pykp6u
            6vURQR+kfQdIuMnAZEk/AL5qZpV8tRRUznhJnzOzO30HSSJJY4HdfOeIdcylBTEXYdj772QkMG6N
            Dx1DF3/S71myxvvvsfFc53scQTxCIeybc4DPSjrbzOb6DhP06iYgFMLeXeo7QOzEsRj793EZzBjE
            mDXetwGEQlgrwtRo320G3Cvp276DBL0aKelbvkMkjaS9gKN954id8TVg0QA/+38pra4NakQohP33
            FUn3+Q4R9OpCSZv5DpEwrb4D+GATeIPxHIZxIf1bwDOdOnazCTzlewxBfEIhHJgjJD0qKUwtJ88s
            3wGSQtJZwL/4zuGLGbLxfJsiewOvrefDC8CJNoHTbSwrfGcP4hUK4cBNoPTsYZAsB0r6lO8QvknK
            ANN850gC251HEQ+s58Oetwlh9XGtCoVwcHaTFBbPJE9NTgeuYRrVvW1in0lEGOv74WgHPcaWvrMG
            foRCOHhHSWr2HSJYzWaSLvAdwhdJ2wJn+s6RGI+zB+/vKrV24ljfUQM/QiGsjEskjfcdIljN1ZJc
            bi6dZLN9B0iUIp9b4z33U2R3xA9Xe2/ldpkJhphQCCvnXt8Bgg8433eAuEk6CtjTd46EOaL8ax7x
            1V8s54iWtxpOnv5m0zcQn6a0ET/A3nqIDXyHDeIXCmHlfETShb5DBKs5U1Kd7xAxu9l3gMQRDyIe
            Q+xtu3H1klzmW4b+rY7uO2802skzFqMd41fUs9x33CB+Yfl/ZV0MXO07RLDSKEq7g/yb7yBxkHQx
            sJHvHElju3HOe79vbc+eIvhy+Y8HZN7ITrOJXWcDh/vOGfgTAU1U91mEcWqUtJ/vEMFqvlhePFLV
            JG1ALW6l1g8t7dl9ZKuftWnwhZb2zBd9Zwv8SgNLgL9SKoaVOKDSl2J5PBvgd9n4RcCvfF+MYDWz
            gE/4DuHYTb4DJNns+dmtCsZPgQ9OlZtNm9meffaUw7p+5jtn4Eea0oKC8wFjCBdCM5OkFKVC+GFg
            V+AQSvssjogxykRJm5rZ331fk2ClvSQdZWbzfAdxQdJuwDG+cyRV22IaO3M2F9a6ijhlpjtm3J/Z
            Y8ohuecG2E2c32OCymkARqXN7L3iN2SL4HvMrAC8Xn57CmiVNBL4EnAJkIopygnANb6vR7Cam4GN
            fYdwJDwusRYS1rIoM8vQLuv+SBttRebNWDxqzykHLFs2gK6WAC9SmpkKhgajdDDvc1W/WMbM3gYu
            lzQHaAPieN7vGEIhTJqNJF1qZt/wHaSSJE0BtvedI6lm3Z+9wuAzfflYgx2iXNetbW0cOWkShf70
            Y2bTCFvaDVk18/iEmf3VzOLaH3Sv8ivRIFkullQ1rwrLm77/cNANVamZ7Q3HS1zUn88RTOxoynzH
            d/YgXjVTCFexD+vfiX6wImAv3wMNetXiO0AFfY+w4rtXMxc27G6mAT1Tadi5re3Z03yPIYhPzRXC
            8j3R42LoalffYw16dZikfXyHGCxJWwNh2X8vpt8/bDND9wLZgbYh48czF2XDo1A1ouYKIYCZ/Rr4
            reNudvA9zmCtqmFxSTh3sRc3zGVYuliYS2nl+GDUmbhzRnt2a99jCtyryUJY5vrGdjjSJbm2kvTv
            vkMMlKRDKU3xB6uQsEwmOx2sUgviNo6MedPvCY9GVLtaLoSLcbvUOWx1lWzXShruO8QAzfQdIIla
            F2W/buL/V7jZj6ezDbObm2v6e2XVq9l/XDN7HXjBYRdNvscYrFME3OA7RH9J+i8GP+1XdVrbGz6L
            sy3mdPQ2e2Wu9D3GwJ2aLYRlrzpsu9av7VBwoqSdfYfoK0mjgCt850ialgXDdpXJ8T1T++rMRdkT
            fY81cKPWv1nHtdNMkFxzfAfoh5/4DpA0cx4cvglR8R5gmOu+TEyfsaAhnPVYhWq9EG7tsO2c78EF
            ffJxSZ/3HWJ9JI2DD5y0XtOmLSDTky/cTXwL0zJRpLtnL2jY3PfYg8qq2UIoaRtgM4ddvDX4JoKY
            XF/esD3JbvEdIGlGRtkbiX/jig8XouLcG+a6fwUaxKdmCyFwhOP2/+Z7gEGfDQe+6zvE2kg6HtjJ
            d44kaV3YcCEw2U/vtmu2PjtDwnxfh6AyarkQnuu4/ed9DzDol3+XtJXvEGvRr/0yq13LosyRQld5
            jjGpdWG22fe1CCqjJguhpDOBbRx385TvcQb9lrjdWiQdBAyZla2uzVxY/zFkc0jC9y6juWVRQ7hv
            WwWq/himNUnajnieH/ud77EG/bavpMPNbIHvIKuopk3CB6XlwREbku+ZR5IOwZVaZy0c9teTJnb8
            j+8ofY+88jGcNJXfVKQOeMbMEnuroTc1VQjLRfCRGLp60czC1OjQNBP40GAbqQRJF+J2QdfQ0tMz
            B2OM7xhraChSvHf6Pex02mdY7jtMH43C7YbtS0jwPffe+J9eiEl5wcEzlP4TuDbf93iDAdtYkvfD
            eyU1Ad/ynSNJLCqej/FL3zlWI/7XpLOGUBEE6IH+HTzcTy537HKi6guhpImSFlJ6cDquV8BhqXvJ
            UF1VN1WS771ib/R9EZJm8qHdT598aNf+wvYwcSPwT09RVhj6qZmObljetfPkw3JJmkoPBiAt6aOU
            poLqcftTgmsq/9oIbAuMA/aD2KdSXjKzcH+wxOWm5q7dDBzto2NJuwD/6vsCJNUpEzsfAR5pbubs
            MXs1TIhg365c9vQoKuxYV9dd8f7y+ToK+bo3Mg0dP1CR3yjb9dspB9Dl+zoElZOmdNP0GN9Bqsi3
            fQdIkHdjaL/RUdtHSdrHzH7jeAy9cXleYp4qWRswdSpF6HzkG9ftObawLLMjMiwqUt/QRV0mR6qu
            m3RdnnR9NxYViVIFQESpAlaeqyjkS/soFAspVIwo5OvId9dT6EnTk8vQ3dlAsZAC2ChqzO102QW/
            m+p73EHlpYHlVNEXRwLM9B0gQVy/IpwDrADOc9T+T4CPOh7DaiSdiLvHJW4FOoDT4hyTS1/73l6f
            KLxdfz0qVTYVI3IrhpFbUfmNX4rvZiZdfO2ev7/svIev9j3uoLIiStOhPb6DVIkFZrbCd4gEcX2P
            MAN8BXdT+jvGeTqFpAi43mEXZ1P6waEqfGv6DiPSw7tnRsO7I0u7/ZnL6gpEo7qIRuS+ftmsXXbw
            Pfagsqp+sUzMzvYdoMZsZ2ZF4KsO+7g5xvFcjbup3u+Z2dtUyVZtbW3HpTrrsnOsPr9dauMO0lu9
            TXqLd0ht1EE0ohurLwz8x7BIWKZA1JQjtXEH6S3fJr3lO6Q27MTqiyMKsrlX3bLzaN/XIKicMB1a
            GQLON7MlvoPUmCKAmV0j6T8BFys9J0g6zszucDkQSZsB5ztqPrdK20N1Je9q/ph79irDjlz1fVZf
            KBXAVQ5+UT6CfIQKBoWo9JUqQ0XDIoEJotKbpQTpIn14dbl9Vz6644Ybxh921lm/D7NpVSC8IqyM
            v5vZtb5D1CCt8vtTHfYTxzmArQ7bvtXM3ps+HsoreQGYOmvsFJNd0JePtXQRy+aJhvcQNeWIRuaI
            RnWR2qCzNNU5Mkc0optoeA+WzfelCJbaNT716rD8931fi6AyQiGsjE0kPTKUTjuvNmY2D3e7Bo2U
            5GyBhKQDgE+5ah/4L4dtx6p55s77FRXLFonrZeKsqS1jv+Q7RzB4oRBWRgrYDXhSUtznowXvc3nA
            7gXl6UsXXL4avMTM/u6w/dhc1rrLGEV2l5X2s0wEGdc1zxx7iO8cweCEQlh5D0kKO9J7YGbPAdMd
            dlHx0ykknQe4OvH8TTOriufemmfv0VTA7ouwDX1nWUMkaGuesfOOvoMEAxcKoRt3SDrLd4gadQ6l
            52JdOFBSxaYwJTUC33F4LU532HZs2tqOS1mx63YSuuI1ihhpUTT/ypbdk1akgz4KhdCd68sPRwcx
            MrMu4MsOu6jIq0JJBtyBu6/BR83sHofXITZ/7HzuGmCi7xzrZGyTs66fNrftVO87StB/oRC6NVvS
            J32HqDVm9n1KR8G4sKmkSj236PLB7JMcth2b5taxZ5rpP3zn6AvDPklX+ge+cwT9Fwqhez+TtLHv
            EDXoZIdtf7N8uOmAmZmAwxzlm2FmzzocfyyaW3c5EBhShcWwM5pbx53rO0fQP6EQupcCHvAdotaY
            2S+AxQ67uKkCGZ8FvlnhXD2U7pMOaZfdMm47sDuTtEK0rwxdM3XWuCN85wj6LhTCeIyVVDXPcg0h
            lS4yqzpW0oTBNmJmFwGVfLzhy2bW4XDczjXPGDcqX9A8g6G6jVlUlG69dObYj/kOEvRNKITxucLh
            c2hBL8zsfuCPDruo1HFJx1WonRfMbEhNJa6pefH+aVJqM7f3T50zGFGMuK95znjfBzwHfRABmwEN
            voPUiETsiFFjXD5kv4OkQT+iYGa/BW6vQB6X90XjsWTZNIODfceokK3J5+8KK0mTLw3cRWlqJkMV
            7EPYBwVgJLAlpbPmsjH2faSknczsGd8XoVaY2ROS2oBJjrr4vqRZZpYbZDtTgM9Q+jociEVm9mtH
            Y4xFc8u4L5qpqk5wMdhXnXXX43Yv3GCQ0mZ2I3Cj7yA+SNoA2B84ATg2pm6vAI7xPfYacxbuCmEW
            mFbuY8DMrFPSaQx8unWKo/HForll14Ow4jTfOVwwY0pz67g/TZ38RDjQN6Fq+h6hmb1lZneZ2eeA
            ccDTMXT7mfA4RbzMbBluN54+U9J2Fch5C/C7AXzq1UN5P9HmGTvvKBXvtNIK66pk6Kqprbsc5TtH
            0LuaLoSrMrM/AGOBBTF0F6ZJYmZmVwGvO+zilgq109+FM2+bmcuDiZ1qvmmnDSwVzYsiRvrO4lgk
            bM6ls3cOJ9QkUCiEqzCzopkdATzouKvjfY+1RrmcPtxN0qCnvM3sb8DF/fiULzgck3uZ+h0FK3zH
            iINgeaGQCoUwgUIh7IWZHQy86bCLsYN4lGK44+FX7cbBZjYfeNhhFxU5+cLMLgde6sOHPmlmtzkc
            j3NTT3rioUtO+sOuEkcJa9fqhy1Xi0eETSHbs/XUk5+Y4ztM8EFp3wES7HTgboftHwLMHMDnvQI8
            g5sTFuqAxx2OOQk+DzzvqO3Rkq40s0rcj/ws8Ps+jGXIM0Pwh/uA+y6/+eNbFNKpSUU4zmAP39kG
            SvCswR1Ct0+d/GQcaw+CQTDfAZJM0vPAto6av8HMhva01nqUpwrvctjFr81svwHkuhE4w2GuzSqx
            eEXSdNZ+P/k2M+v3FLuk+3H7nN7xlXqVesWs8ZvmlT+oCAcjJpqR3EVmsg7gQUMPFmHR1JP/8Gff
            kdYaVfoIpRkHV4uTfmlm+/seZ3+EQrgO5W3RrnDU/ENmtrfvMbqU4EKYBd7B3T6WD5jZoE8tl5QC
            lgGNvfz1SDN7ZwBtDplC+J5r7x41asVftn051zGssW5YF6mGHlRXpJA2lCL+72IFSOWF9RjqStPT
            kUVF0wab/uPA8854/hcxp+m3UAg/KEyNrpvLB5S3lRSZWS1sYpAoZtZVPhne1XZkB0s6wMwGtem3
            mRUkfR64Z42/umAgRXAoam4m6lqy5eOdy0c0AuSWD4flq14jkc7kSGV6sLoCSheFWU4ReaUsUkSW
            SFGfi2XRUIHOVJGCFZWKisqoEEXKpyjk0uS7MqgY0fPBz7R33tqw/dpZPducd9JLQ/ZRlloVCuG6
            vUDp5r2Lnzk3Kb+FLxoPzOyHkr4CbO2oi1ZgiwrkvFfSk8Au5Xf9zcxcnmqfKFtNaPruGy/3bJ1K
            5ynkP/jtSjJ6urL0dK3cIMroZbcoM2FR6WfOKFVY+f5iMQIZUoSKK7/MG/p7Az5d303j6KXZEU1L
            b1+8mAMPOMDJPfzAkVAI1+1tSku7Gwfb0Fp8iFAIfToZ+KWjtjeXdL6ZXVOBti4C5pd/f0ocFyYJ
            Wtuzp8i6/2OTbV5gkzEv0rm8kRXLRtH5ThNd7zYi9f3nU8lQoTQTWCwMfkbQoiINI5YzbOQ7DB+1
            jMzwlU+A7Lskl50GXV/0ff2CvguFcN2yuN2LdJjvAdYyM/uVpEXAoY66+LakFjN7Y5A5F0haAmTM
            zPUzronQ0p7dR7bK1o8mGpqW09BUmhdVMSK3YhhdK4bTtaKR7o4Gerqyvb5qHKxUOk99Qyf1wzrJ
            Dl9BpvFdMsM6KJ2t3KuzW9ozT598WO5Hvq9j0DehEK7bR3B7jcL9Qf9OBf7mqG0Dvg6cW4G2DqZG
            vl5ntGe3xriLdSxmsqhIdsS7ZEe8C7y28v2FfLpUEHvS5LvrKeTrKPSkVxbI0qtBo1iIiFJFQCun
            SlPpPKm6PKl0D+n6blL1PdRlcqTSA5jlNJs28/7M86cckrvf9/UM1q8mvrAGYbzj9sOGBp6Z2auS
            vgt82VEXp5anSAuDacTMErscv5JmL6CpYNwHA3tUIpXOk2p81/cwAFIUaZuxqH7PKYd2/8l3mGDd
            wjfidftXx+2HG+rJcAGrrUWsqBHAdb4HOBS0tZEqpLK3AlVxsrthIyNF825a2LSB7yzBuoVCuBaS
            tsL9AaEut3EL+qj8CIvLxQ3nSNra9ziTrrMpczXicN85Kuxf6ui+84bHnD2zGlRAKIRrd7Pj9t8l
            rBhNDDObjdtjuCp1OkVVam3PngbmanratwMaXs+6emY1qIBQCHsh6RzgQMfdvGhmnb7HGqzmZIdt
            f0LSEb4HmEQz2rP7y/ix7xwuyTizdWHDub5zBL0LhXANkk4Evh9DV8/4HmuwOjP7H9xuCTfD9xiT
            ZuaCzLaR6ae42+4uMYSuaVmUOcx3juCDQiFchaSLgdkxdfeo7/EGvXK5GffGkpp9DzApbniAkRbZ
            fWC1spgkQnZb6wP1H/UdJFhdzRdCSSbpSEmLgUtj7PrnvscefJCZvUX/Dsbtr0skbeR7nL61tZFq
            KGRvA3b0nSVmTSpE8+fMHVHz/weSJC1pe0rP7KSp/ge8i+VxbkxpH8jxwP6UHpyP0z/K03D9dvat
            zx1N0V3BNqP9Rydsd1HM1yNRzOxySV+itAWeCzOBI32P06fOpobrQBN95/BkTHd9z11tbRw0aRLd
            vsMEpaJwJXCs7yA15s6BfqKJzTEb6yyZ7LXBN1IVzgDuddT2EZI+YWYP+R6kD60LM18QOsd3Dp8M
            9u1syv4Yuk7znSUoTY12EB7sjttPBvqJwlYM9HP7wigOal/MamFmc4GHHXYR173oRGldlD2wiMWx
            GG0oOLV1YcP5vkMEpUKYh96O1wocedzMnvQdIuiTMx22Pab8mE7NaJmf2V7SnRa2dlxJ6OrWRZmj
            fOeodTW/WMaDmr7/NpSY2VPAsw67uFZSTZxAcst9I0eTsnlgo31nSZioKJszY379zr6D1LJQCOP1
            tJkt8h0i6BeXrwrTUN0PkgMsXkw6n87dAWzvO0sSGTRaKprXuqjR1eKsYD1CIYyXy51LAgfM7FeA
            yx9eJkuqik2m16Z0UC2f8p0jyQy2UjHvcjOHYB1CIYzP9IE+MhF4d6rj9qt6H1IztYPuAsKWgr0r
            SvyciJslzHeYWhRuWsfj72Z2uu8QwcCUzyz8DvAVR12MlXS8md3qe6wuTD40Nw+Y19ZGQ8eIhomY
            DgEOMNjBdzaPXjWxuGj8rL67bt4JRy9/A8KUkS+hEMbD9QbegXsXUrpf2OSo/Rsl3V4+EqoqTZpE
            J3TeDdwNMGP+8A9blN8dbHczJgD/jwEeyJtkgncNnjDp0SLRo0oVH5tySO659z+iy3fEmhcKoXvH
            mFk4oXqIMzNJ+gIwx1EXjcA1wHm+xxqXKUes+Acwt/wGQMuDIzaM8j07FWEHk3aS2fZgW0Bx84Tv
            SdoheNngZeA5SX8y45mCRc+eemjny77DBesWCqFbZ5jZPb5DBJVhZrdKughwtdT9XEnXmtkS32P1
            5eSDlr8J/Lr8tpq2NhpyIzJbFrDNMT5iso0s0uiibLSh0RijEKOB0cUebQKMBiPqx7kWxTwgkLQi
            VW+vAksRyzCWCpZGxlJky0BLZXqlmNcr9Wp45cQj317q+9oFAxcKoTunmlk4dqf6TAYed9j+LOCT
            vgeZRKWp1dyz9OHZzotP51yMa9d8v6XAolXWo0il4rcGiTsvv4lT+pYsbBc61IVVo5W3Ajg0FMHq
            ZGZPMIi9YvtgP0mH+h5ntVIBij16/y1sLhkQCmGlvQR8zMzu9x0kcMrlmYUALb4HGAS1JBTCyrke
            +KiZveQ7SOCWmS0Dvuawi00kuWw/CIJVhEI4eO3AnmZ2tpmFB4ZrhJldCfzTYReXS0ryKskgqBqh
            EA5MD6Vl9Pua2eFm9t++AwVeuN5x5mbfAwyCWhABw4AG30GGgA5gAfAlYCszO9HMfhN3CIOsy/Zl
            0YgKNtePhesD0ui4/XUys/nAIw67+LSkQyrcputr5vrfPBi8CEg5bN/r1+VApIG3gWWEYpgHcpRW
            fXYBS4FXgCcofbN72Mze9h1SxjITbwJy0HoEvFrBBnPlN1fecth2X50IPIqbr58UpaOaJlRw2n0p
            bv9NwjYpyVeg9IO9q2KYhK/LfkkD/03pm2q97zCe9VD6BrGc0ubAb1AqhE+b2Yu+w73HzP6qom7B
            VPmtuGQRpkcr2OLLuNuJBeDPDtvuEzN7XtItlGZWXIigohsx/xx4zeElCYvFkq8TuBV3t8ZcnuHp
            xP8BW1NXCKC9nBgAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMTItMTNUMTI6NDY6NTArMDE6MDA6
            kbdNAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTEyLTEzVDEyOjQ2OjUwKzAxOjAwS8wP8QAAAABJ
            RU5ErkJggg=="
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>