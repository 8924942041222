import { math } from "@xeokit/xeokit-sdk"
import { geometry } from "./geometry"

export const pointToPlane = {

  findShortestSegmentBetweenPointAndPlane(firstPoint, faceEdges) {
    let tempVec3a = math.vec3()
    let tempVec3b = math.vec3()
    let tempVec3c = math.vec3()
    //let tempPoint = []
    
    let point = firstPoint
    let faceN = math.vec3()
    let edges = faceEdges
    let faceVertices = [edges[0][0], edges[0][1]]

    for (let i = 1; i < edges.length; i++) {
      if (!geometry.utils.contains(faceVertices, edges[i][0])) {
        faceVertices.push(edges[i][0])
      }
      if (!geometry.utils.contains(faceVertices, edges[i][1])) {
        faceVertices.push(edges[i][1])
      }
    }

    let faceO = faceVertices[0]
    
    math.subVec3(faceVertices[0], faceVertices[1], tempVec3a)
    math.subVec3(faceVertices[0], faceVertices[2], tempVec3b)
    math.cross3Vec3(tempVec3a, tempVec3b, tempVec3c)
    math.normalizeVec3(tempVec3c, faceN)

    // Выпускаем луч из точки в направлении плоскости многоугольника
    math.subVec3(point, faceO, tempVec3a)
    let rayV = []

    if (math.dotVec3(tempVec3a, faceN) > 0) {
      rayV = faceN
    } 
    else {
      math.mulVec3Scalar(faceN, -1, tempVec3a)
      rayV = tempVec3a
    }
    let intersPoint = geometry.intersection.getRayPlaneIntersectionPoint(point, rayV, faceN, faceO) // РЕЗУЛЬТАТ для перпендикулярного режима

    return [intersPoint, firstPoint]

  },
}