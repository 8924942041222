<template>
  <svg
    width="32"
    height="32"
    viewBox="-7 -4 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.4 21.6667C1.02222 21.6667 0.694444 21.5278 0.416667 21.25C0.138889 20.9723 0 20.6445 0 20.2667C0 20.0889 0.0500003 19.8834 0.15 19.65C0.25 19.4167 0.366667 19.2334 0.5 19.1L9.16667 11L0.5 2.90004C0.366667 2.76671 0.25 2.58893 0.15 2.36671C0.0500003 2.14449 0 1.93337 0 1.73337C0 1.3556 0.138889 1.02782 0.416667 0.750041C0.694444 0.472263 1.02222 0.333374 1.4 0.333374C6.78799 1.32156 9.60965 1.29207 14.3333 0.333374C14.8 0.333374 15.1944 0.494485 15.5167 0.816708C15.8389 1.13893 16 1.53337 16 2.00004C16 2.46671 15.8389 2.86115 15.5167 3.18337C15.1944 3.5056 14.4667 3.5 14 3.5L5.2 3.66671L11.4667 9.53337C11.6889 9.73337 11.85 9.96115 11.95 10.2167C12.05 10.4723 12.1 10.7334 12.1 11C12.1 11.2667 12.05 11.5278 11.95 11.7834C11.85 12.0389 11.6889 12.2667 11.4667 12.4667L5.2 18.3334H14.3333C14.8 18.3334 15.1944 18.4945 15.5167 18.8167C15.8389 19.1389 16 19.5334 16 20C16 20.4667 15.8389 20.8612 15.5167 21.1834C15.1944 21.5056 14.8 21.6667 14.3333 21.6667C9.19387 20.6251 6.37276 20.5967 1.4 21.6667Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>