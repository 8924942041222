<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 48.00 48.00"
  >
    <path
      fill="currentColor"
      d="
        M 12.52 24.00
        L 17.18 24.00
        Q 17.94 24.00 17.40 24.54
        L 10.40 31.44
        Q 9.96 31.87 9.53 31.44
        L 2.61 24.53
        Q 2.10 24.01 2.83 24.01
        L 7.49 24.00
        Q 7.98 24.00 8.02 23.50
        C 9.35 4.39 34.58 -0.92 42.50 17.07
        C 50.20 34.55 27.98 50.17 13.77 37.08
        Q 13.37 36.71 13.76 36.34
        L 15.85 34.32
        A 0.61 0.60 -46.1 0 1 16.68 34.30
        C 23.88 40.76 35.37 38.31 38.85 29.54
        C 43.60 17.58 32.22 6.35 20.38 11.14
        Q 12.64 14.27 12.12 23.58
        Q 12.10 24.00 12.52 24.00
        Z"
    />
    <path
      fill="currentColor"
      d="
        M 27.19 24.43
        L 33.65 28.29
        A 0.40 0.40 0.0 0 1 33.78 28.84
        L 32.71 30.63
        A 0.40 0.40 0.0 0 1 32.17 30.77
        L 24.20 26.09
        A 0.40 0.40 0.0 0 1 24.00 25.74
        L 24.00 16.40
        A 0.40 0.40 0.0 0 1 24.40 16.00
        L 26.60 16.00
        A 0.40 0.40 0.0 0 1 27.00 16.40
        L 27.00 24.08
        A 0.40 0.40 0.0 0 0 27.19 24.43
        Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>