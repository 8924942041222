<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.2138 6V8.27502H9.07872V8.96886H11.2138V11.2439H11.8649V8.96886H14V8.27502H11.8649V6H11.2138ZM5.71019 8.27502H3.15564C2.28258 8.27502 1.53837 8.60324 0.923023 9.25969C0.307674 9.91616 0 10.7101 0 11.6415C0 12.5728 0.307674 13.3654 0.923023 14.0193C1.53837 14.6731 2.28258 15 3.15564 15H5.71019V14.3062H3.15564C2.46691 14.3062 1.87732 14.0448 1.38685 13.5222C0.896394 12.9996 0.651163 12.3714 0.651163 11.6375C0.651163 10.9036 0.896394 10.2754 1.38685 9.75278C1.87732 9.23017 2.46691 8.96886 3.15564 8.96886H5.71019V8.27502ZM4.47674 11.2906V11.9844H9.52326V11.2906H4.47674ZM14 11.6375H13.3488C13.3488 12.3714 13.1036 12.9996 12.6131 13.5222C12.1227 14.0448 11.5331 14.3062 10.8444 14.3062H8.28981V15H10.8444C11.7174 15 12.4616 14.6722 13.077 14.0165C13.6923 13.3608 14 12.5678 14 11.6375Z" fill="currentColor"/>
  <path d="M0 7V6.61379C0 6.33546 0.0827852 6.08105 0.248355 5.85056C0.41394 5.62006 0.642113 5.45888 0.932873 5.367C1.52365 5.13622 2.11096 4.95967 2.6948 4.83733C3.27865 4.71501 3.87458 4.65385 4.48259 4.65385C5.10228 4.65385 5.70405 4.71501 6.28789 4.83733C6.87174 4.95967 7.45904 5.13622 8.0498 5.367C8.34058 5.45888 8.57164 5.62006 8.74298 5.85056C8.91433 6.08105 9 6.33546 9 6.61379V7H0ZM4.49496 3.65385C3.95601 3.65385 3.49327 3.47624 3.10673 3.121C2.72018 2.76576 2.52691 2.3344 2.52691 1.82692C2.52691 1.32318 2.71873 0.892757 3.10239 0.535646C3.48604 0.178549 3.95192 0 4.5 0C5.04403 0 5.50889 0.178007 5.89457 0.534021C6.28025 0.890049 6.47309 1.32257 6.47309 1.83158C6.47309 2.33061 6.28084 2.75908 5.89633 3.11698C5.51182 3.4749 5.0447 3.65385 4.49496 3.65385Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>