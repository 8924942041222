import { api } from "@/api"

const getDefaultState = () => {
  return {
    dictionary: [],
    types: [],
    isTreeInPreparation: false,
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  modules: {
    
  },

  state,

  mutations: {
    setTreeInPreparation: (state, flag) => {
      state.isTreeInPreparation = flag
    },
  },

  getters: {
    getAllVersions: (state) => [].concat.apply([], state.dictionary.map(dict => dict.versions))
  },

  actions: {
    initSmetaDictionaries({dispatch}) {
      dispatch('loadSmetaDictionaries')
      dispatch('loadSmetaDictionaryTypes')
    },

    loadSmetaDictionaries: ({ state }) => {
      api.smetadictionary.list().then(data => {
        state.dictionary = data
      })
    },

    loadSmetaDictionaryTypes({state}) {
      api.smetadictionary.types().then(data => {
        state.types = data
      })
    },

    createSmetaDictionary({ dispatch }, smetadictionary) {
      api.smetadictionary.createSmetaDictionary(smetadictionary).then(() => {
        dispatch('loadSmetaDictionaries')
      })
    },

    createSmetaDictionaryVersion({ dispatch }, { smetaDictionaryUuid, smetaDictionaryVersion }) {
      api.smetadictionary.createSmetaDictionaryVersion(smetaDictionaryUuid, smetaDictionaryVersion).then(() => {
        dispatch('loadSmetaDictionaries')
      })
    },

    addSmetaDictionaryVersionFiles({ commit, dispatch }, {smetaDictionaryVersionUuid, files}) {
      commit('setTreeInPreparation', true)
      return api.smetadictionary.addSmetaDictionaryVersionFiles(smetaDictionaryVersionUuid, files).then(() => {
        dispatch('loadSmetaDictionaries')
        commit('setTreeInPreparation', false)
      })
    }

  }
}