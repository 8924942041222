import { DrawingComment } from "./DrawingComment"

export class DrawingPage{
  constructor(obj) {
    this.uuid = obj.uuid
    this.name = obj.name
    this.included = !obj.excluded
    this.model = obj.model
    this.drawing = obj.drawing
    this.number = obj.number
    this.attachments = obj.attachments.map(attach => new DrawingComment(attach))
    this.version = obj.version
    this.offsets = obj.offsets ? obj.offsets : null

    this.width = obj.width
    this.height = obj.height
  }
}