import { api } from '@/api'
import date_utils from '@/plugins/gantt/date_utils'
import i18n from '@/plugins/i18n'
import { AlertService } from '@app/AlertService'
import { defineStore } from 'pinia'

import vuexStore from '@/store'

export const useMaintenanceScheduleStore = defineStore('maintenanceSchedule', {
  state: () => ({
    currentTask: null,
    selectedDate: null,
    selectedEquipment: null,

    months: [],
    mTasks: [],

    monthTask: [],
    daysInMonth: 0,
    prettyDate: null,

    viewSettings: {
      month: null,
      index: null,
    },
  }),

  getters: {
    specificationUuid() {
      return this.selectedEquipment?.equipmentSpecification?.uuid
    },

    treeFlatList() {
      return vuexStore.getters['maintenance/treeFlatList']
    },

    hasMaintainceDateTO() {
      return vuexStore.getters['projectPermissions/hasMaintainceDateTO']
    },

    hasMaintainceSettingChartTO() {
      return vuexStore.getters['projectPermissions/hasMaintainceSettingChartTO']
    },
  },

  actions: {
    async loadData() {
      return api.maintenance.getSchedule(vuexStore.getters['project/projectUuid']).then((data) => {
        this.months = Object.keys(data)
        this.mTasks = Object.values(data)
      })
    },

    async updateData() {
      return this.loadData().then(() => {
        if (this.daysInMonth) {
          this.goToMonthView(this.viewSettings.month, this.viewSettings.index)
        }
        
        if (
          this.currentTask &&
          this.currentTask.equipmentId &&
          this.selectedEquipment.uuid === this.currentTask?.equipmentId
        ) {
          return api.maintenance.loadTasks(this.currentTask.equipmentId).then((data) => {
            vuexStore.commit('maintenance/setEquipmentTasks', data)
          })
        }
      })
    },

    goToTask() {
      if (vuexStore.state.task.tasks && vuexStore.state.task.tasks.length > 0) {
        this.openTask()
      } else {
        vuexStore.dispatch('task/loadTasks').then(() => {
          this.openTask()
        })
      }
    },

    openTask() {
      let task
      if (this.currentTask?.taskUuid) {
        task = vuexStore.state.task.tasks.find((t) => t.uuid === this.currentTask?.taskUuid)
      }

      if (!task) {
        task = vuexStore.state.task.tasks.find((t) => t.maintenanceTaskUuid === this.currentTask?.uuid)
      }

      if (task) {
        vuexStore.commit('project/setTopUnit', 'task')
        vuexStore.commit('task/setTaskDisplay', 'dashboard')
        vuexStore.commit('task/setSelectedTask', task)
      } else {
        AlertService.info({ info: 'На данное ТО нет задачи' })
      }
    },

    getMonthTitle(month) {
      return monthTitle(month)
    },

    async goToMonthView(month, index) {
      if (month) {
        this.viewSettings = { month, index }
        const [m, y] = month.split(' ')
        const date = new Date(y, Number(m) + 1, 0)
        this.daysInMonth = date.getDate()
        this.prettyDate = date_utils.format(date, 'MMMM YYYY', localStorage.getItem('locale') ?? 'ru')
        const monthTask = []
        for (let i = 0; i < this.daysInMonth; i++) monthTask.push([])

        for (const i in this.mTasks[index]) {
          const date = this.mTasks[index][i]?.maintenanceDate
          if (date) {
            const day = new Date(date).getDate() - 1
            monthTask[day].push(this.mTasks[index][i])
          }
        }

        this.monthTask = monthTask
      }
    },

    getDayWorkShift(date) {
      const ms = vuexStore.state.project.maintenanceTaskSettings
      const oneDay = 1000 * 60 * 60 * 24

      const start = new Date(ms.schedulerStartDate || vuexStore.state.project.project.createDate)
      const now = new Date(date)
      start.setHours(0, 0, 0, 0)
      now.setHours(0, 0, 0, 0)

      if (now.getTime() < start.getTime()) return 'Error'
      const totalDays = Math.round((now.getTime() - start.getTime()) / oneDay)
      const currentDutu = (totalDays % ms.numDuty) + 1

      return currentDutu
    },

    returnFromMonthView() {
      this.daysInMonth = 0
      this.prettyDate = null
      this.monthTask = []
    },

    async deleteMaintenanceTask(maintenanceTaskUuid) {
      return api.maintenance.deleteMaintenanceTask(maintenanceTaskUuid).then(this.updateData)
    },
  },
})

function monthTitle(month) {
  const months = [
    i18n.t('section.schedule.month.jan'),
    i18n.t('section.schedule.month.feb'),
    i18n.t('section.schedule.month.mar'),
    i18n.t('section.schedule.month.apr'),
    i18n.t('section.schedule.month.may'),
    i18n.t('section.schedule.month.jun'),
    i18n.t('section.schedule.month.jul'),
    i18n.t('section.schedule.month.aug'),
    i18n.t('section.schedule.month.sep'),
    i18n.t('section.schedule.month.oct'),
    i18n.t('section.schedule.month.nov'),
    i18n.t('section.schedule.month.dec'),
  ]
  const [day, year] = month.split(' ')
  return months[day] + ' ' + year.substr(2)
}
