<script>
export default {
  model: {
    prop: 'appDialogShow',
    event: 'updateAppDialogShow'
  },
  props: {
    appDialogShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_show: {
      get () {
        return this.appDialogShow
      },
      set (value) {
        this.$emit('updateAppDialogShow', value)
      }
    }
  },
  methods: {
    $_beforeShow () {},
    $_beforeHide () {},

    show (...rest) {
      this.$_beforeShow(...rest)
      this.$_show = true
    },

    hide (...rest) {
      this.$_beforeHide(...rest)
      this.$_show = false
    }
  },
}
</script>
