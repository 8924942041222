export default ({ Factory }) => ({

  /**
   * Список профилей с кем я работал или работаю
   */
   getMyTeam: () => {
    return Factory.members.get()
  },

  /**
   * Список участников на проекта
   * @param {String} projectUid UUID проекта
   */
  getProjectBy: (projectUid) => {
    return Factory.members.projectby[projectUid].get()
  },

  /**
   * Список участников на моделе
   * @param {String} modelUuid UUID проекта
   */
   getModelBy: (modelUuid) => {
    return Factory.members.modelby[modelUuid].get()
  },

  /**
   * Добавление профиля к модели с ролями
   * @param {String} modelUuid UUID модели
   * @param {String} profileUuid UUID профиля
   * @param {Array<String>} roleUuids Array<UUID> Список UUID'ов ролей 
   */
  create: (modelUuid, profileUuid, isGroup, roleUuids) => {
    if(isGroup)
      return Factory.members.model[modelUuid].group[profileUuid].post(roleUuids)
    else
      return Factory.members.model[modelUuid].profile[profileUuid].post(roleUuids)
  },

  inviteModelAndRoles: (inviteToProject) => {
    return Factory.members.invite[inviteToProject].get()
  },

  invite: (members) => {
    return Factory.members.invite.post(members)
  },

  generateLinkInvite: (inviteToProject) => {
    return Factory.members.invite.project.post(inviteToProject)
  },
  

  /**
   * Редактирование ролей у участника
   * @param {String} modelUuid UUID модели
   * @param {String} memberUuid UUID участника проекта
   * @param {Array<String>} roleUuids Array<UUID> Список UUID'ов ролей 
   */
  setRoles: (modelUuid, memberUuid, roleUuids) => {
    return Factory.members[memberUuid].model[modelUuid].role.patch(roleUuids)
  },

  /**
   * Удаление роли у участника на моделе
   * @param {UUID} memberUuid UUID участника проекта
   * @param {UUID} roleUuid  UUID роле
   */
  delRole: (memberUuid, roleUuid, modelUuid) => {
    return Factory.members[memberUuid].role[roleUuid].model[modelUuid].delete()
  },

  /**
   * Заменить профиль участника
   * @param {String} memberUuid UUID участника
   * @param {String} profileUuid UUID профиля
   */
  setProfile: (memberUuid, profileUuid) => {
    return Factory.members[memberUuid].profileю.replaceOn[profileUuid].patch()
  },

  /**
   * Удалить участника
   * @param {String} memberUuid UUID участника
   */
  delete: (memberUuid) => {
    return Factory.members[memberUuid].delete()
  },

  /**
   * Удалить участника из Модели
   * @param {String} memberUuid UUID участника
   * @param {String} modelUuid UUID модели
   */
   deleteFromModel: (memberUuid, modelUuid) => {
    return Factory.members[memberUuid].model[modelUuid].delete()
  },

  /**
   * Копировать команду из Модели
   * @param {String} currentModelUuid UUID модели откуда копируем
   * @param {String} targetModelUuid UUID модели куда копируем
  */
  copyTeamFromModel: (currentModelUuid, targetModelUuid, deleteTeamFlag) => {
    return Factory.members.currentmodel[currentModelUuid].targetmodel[targetModelUuid].deleteteam[deleteTeamFlag].patch()
  },
})