<template>
  <svg
    width="35"
    height="31"
    viewBox="0 0 35 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="6.5" r="5.5" stroke="currentColor" stroke-width="2" />
    <path
      d="M27.0976 29.3145C27.0976 29.3145 20.0978 23.8146 21.0978 18.3145C22.0978 12.8144 32.1747 12.3147 33.0976 18.3145C34.0205 24.3142 27.0976 29.3145 27.0976 29.3145Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M21 22H1C1 17.1975 6.5 12 11.5 12C13.5 12 16.5 13 18 15"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="27" cy="20" r="2" fill="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>