<template lang="pug">
  v-snackbar( v-model="snackbar" ) {{ $t('app.dev.text') }}
    template( v-slot:action="{ attrs }" )
      v-btn( color="#3B93AF" text v-bind="attrs" @click="snackbar = false" )  {{ $t('button.close') }}
</template>

<script>
export default {
  name: 'AppDev',
  data () { 
    return { snackbar: false }
  },
  created () {
    this.$root.$on('dev', this.show)
  },
  methods: {
    show () {  this.snackbar = true }
  }
}
</script>