<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 5H5V19H19V5ZM5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 13V17H13V13H17V11H13V7H11V11H7V13H11Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {};
</script>

<style>
</style>