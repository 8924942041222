<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 48.00 48.00"
  >
    <path
      fill="currentColor"
      d="
      M 23.55 27.94
      L 16.36 35.56
      Q 16.00 35.94 16.00 36.47
      L 15.99 41.26
      Q 15.99 42.00 15.24 42.00
      L 6.66 41.99
      A 0.65 0.65 0.0 0 1 6.01 41.34
      L 6.02 32.63
      A 0.59 0.59 0.0 0 1 6.61 32.04
      L 13.58 31.98
      A 1.21 1.19 22.2 0 0 14.43 31.63
      L 21.64 24.43
      Q 22.01 24.05 22.01 23.53
      L 22.01 18.27
      Q 22.01 17.74 21.55 17.46
      C 12.02 11.59 24.16 0.47 29.00 9.00
      Q 31.81 13.97 26.45 17.67
      Q 26.00 17.98 26.00 18.53
      L 25.99 23.51
      Q 25.99 24.04 26.36 24.41
      L 33.58 31.63
      Q 33.95 31.99 34.47 31.99
      L 41.26 32.02
      Q 42.00 32.02 42.00 32.76
      L 42.00 41.25
      Q 42.00 42.00 41.25 42.00
      L 32.75 41.99
      Q 32.01 41.99 32.01 41.25
      L 32.00 36.47
      Q 32.00 35.94 31.64 35.56
      L 24.46 27.94
      Q 24.00 27.46 23.55 27.94
      Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>