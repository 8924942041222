export default ({ Factory, Presets}) => ({

  /**
   * Полный список зарегистрированных пользователей для админки с подсчетом размерности их проектов по всем параметрам - долго
   */
  usersAll: () => Factory.profiles.get(),

  /**
   * Список пользователей aктивных (урезаны данные)
   */
  getActiveUsersShort: () => Factory.profiles.all.get(),

  /**
   * Список пользователей для пагинации
   * @param {Object} filter Фильтр списка пользователей
   */
  usersAllList: (filter) => {
    return Factory.profiles.list.post(filter)
  },

  /**
   * Регистрация нового пользователя с подтверждением по E-mail
   * @param {Object} user Данные пользователя
   */
  registration: (user) => Factory.profiles.register.post(user, Presets.GUEST),

  /**
   * Регистрация нового пользователя без подтверждения
   * @param {Object} user Данные пользователя
   */
  create: (user) => Factory.profiles.post(user),

  /**
   * Редактирование данных пользователя
   * @param {Object} user Данные пользователя
   */
  edit: (user) => Factory.profiles.put(user),

  /**
   * Редактирование данных пользователя вместе с аватаром
   * @param {Object} user 
   */
  editWithAvatar: (user) => {
    let formData = new FormData()
    formData.append('uuid', user.uuid)
    formData.append('email', user.email)
    formData.append('name', user.name)
    formData.append('phone', user.phone)
    formData.append('avatar', user.avatar)
    formData.append('grantTypeStr', user.grantType.name)
    
    if (user.avatarFile)
      formData.append('avatarFile', user.avatarFile)

    return Factory.profiles.edit.put(formData)
  },

  /**
   * Архивирование пользователя - сброс email, удаление записи с авторизационного сервера
   * @param {*} id UUID пользователя
   */
  delete: (id) => Factory.profiles[id].delete(),

  /**
   * Активирование пользователя
   * @param {*} id UUID пользователя
   */
  activate: (id) => Factory.profiles[id].activate.post(),

  /**
   * Получение даннчх авторизованного пользователя
   */
  authorized: () => Factory.profiles.authorized.get(),

  /**
   * Создание тестовой базы пользователей, без регистрации на авризационном сервере
   */
  createTestUsers: () => Factory.profiles.testusers.get(),

  /**
   * Настройках пользователя
   * @param {Object} param Наименование (En) темы {name: 'paramType', value: paramName}
   */
  setSettings: (param) => Factory.profiles.settings.post(param),

  /**
   * @typedef {{switcher: Boolean, type: { value: Number }, value: String}} ProfileSetting 
   * @param {Array<ProfileSetting>} settings 
   * @returns {Promise<Array<ProfileSetting>>}
   */
  setSettingList: (settings) => Factory.profiles.settings.list.post(settings),

  /**
   * Настройки уведомлений
   * 
   */
  setNotifySettings: (notifySettings) => Factory.profiles.notifysettings.post(notifySettings),

  /**
   * Настройки пользователя
   */
  getSettings: () => Factory.profile.settings.get(),

  /**
   * Добавить аватар профилю
   * @param {String} id UUID пользователя
   * @param {File} file Файл (изображение)
   */
  setAvatar: (id, file) => {
    let formData = new FormData()
    formData.append("file", file)
    return Factory.profiles[id].avatar.post(formData, Presets.headers.MULTIPART)
  },

  activeUsersSearch: (text) => Factory.profiles.searchactive[text].get(),

  feedback: (form) => {
    let formData = new FormData()
    formData.append("name", form.lastName + " " + form.firstName)
    formData.append("contact", `${form.email ? 'e-mail:' + form.email: ''}${form.phone ? ' телефон:' + form.phone: ''}`)
    formData.append("info", form.info)
    let headers = Presets.headers.MULTIPART.headers
    return Factory.support.post(formData, { '_guest': true, headers})
  },

  getStatisticXlsx: (options) => Factory.statistic.report.user.post(options, { responseType: 'blob' }),

  uploadXlsx: (file) => {
    let formData = new FormData()
    formData.append("file", file)
    return Factory.report.statistic.user.upload.post(formData)    
  }
})