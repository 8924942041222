export default ({ Factory }) => ({
  /**
   * Постраничный поиск списка коллизий по фильтру
   * @deprecated
   * @param {Object} filter Фильтр для поиска коллизий
   * @param {String} filter.project UUID проекта
   * @param {String} filter.axisId ID оси (Axis): если это FixedAxis, то от 1 до 4, иначе UUID пользовательской Оси (CustomAxis)
   * @param {Array<String[]>} filter.elementClassPath Набор, состоящий из стэков UUID; каждый стэк формируется из UUID узлов дерева от корня до выделенного узла
   * @param {String[]} filter.elementGuids Список globalId элементов 3D модели, которые выделил пользователь
   * @param {String} filter.groupUuid UUID группы коллизий, если поиск ведется по группе коллизий
   * @param {Number} filter.page Номер подгружаемой страницы (начинаются с 1)
   * @param {Number} [filter.size] Количество элементов на странице
   * @param {Object} [filter.sort = { field: 'createDate', direction: 'asc' }] Сортировщик
   * @param {String} filter.sort.field Поле, по которому должны сортироваться элементы
   * @param {String} filter.sort.direction Порядок сортировки
   */
  search: (filter) => Factory.collisions.search.post(filter),

  /**
   * Получение списка групп коллизий и коллизиями внутри них
   * @param {Object} filter Фильтр для поиска коллизий
   */
  loadCollisionsTableGroups: (filter) => Factory.collisions.search.uuid.post(filter),
  
  /**
   * Получение списка коллизий по списку UUID
   * @param {Array<String>} collisionsUuid Список UUID коллизий
   */
  getCollisionsList: (collisionsUuid) => Factory.collisions.search.list.post(collisionsUuid),

  /**
   * Получение списка правил и атрибутов для атрибутного поиска
   * @param {Object} filter Фильтр для поиска атрибутов
   * @param {UUID} filter.projectUuid UUID проекта
   * @param {List<UUID>} filter.collisionRules Список правил
   * @param {UUID} filter.collisionRule UUID правила
   * @param {UUID} filter.collisionAttr UUID атрибута
   * @param {List<UUID>} filter.collisionAttrList Список атрибутов
   * @param {List<UUID>} filter.elementUuids Список uuid элементов
   */
  attrSearch: (filter) => Factory.collisions.attr.search.post(filter),

  /**
   * Получение списка элементов для атрибутного поиска
   * @param {Object} filter Фильтр для поиска атрибутов
   * @param {UUID} filter.projectUuid UUID проекта
   * @param {List<UUID>} filter.collisionRules Список правил
   * @param {UUID} filter.collisionRule UUID правила
   * @param {UUID} filter.collisionAttr UUID атрибута
   * @param {List<UUID>} filter.collisionAttrList Список атрибутов
   * @param {List<UUID>} filter.elementUuids Список uuid элементов
   */
  attrElementSearch: (filter) => Factory.collisions.attr.elements.post(filter),

  attrTaskSearch: (filter) => Factory.collisions.attr.task.post(filter),

  /**
   * Обновление статуса выбранных атрибутов
   * @param {List<UUID>} uuids Uuids атрибутов
   * @param {Integer} status Cтатус атрибутов
   * @returns 
   */
  attrUpdateStatus:(uuids, status) => Factory.collisions.attr.status[status].post([...new Set(uuids)]),

  // ------ NOTE
  /**
   * Добавить или изменить текст заметки к указанной коллизии
   * @param {String} collisionUuid UUID коллизии
   * @param {String} text Текст заметки
   */
  setNote: (collisionUuid, text) => Factory.collisions[collisionUuid].note.patch(text),

  /**
   * Добавить или изменить текст заметки к указанным коллизиям
   * @param {String} collisionsUuid UUID коллизий
   * @param {String} text Текст заметки
   */
  setNoteToGroup: (collisionsUuid, text) => Factory.collisions.group.note.post({ note: text, uuidList: collisionsUuid}),

  // ------ CAMERA / VIEW
  /**
   * Добавить или изменить камеру указанной коллизии
   * @param {String} collisionUuid UUID коллизии
   * @param {String} camera Сохранение камеры
   */
  setCamera: (collisionUuid, camera) => Factory.collisions[collisionUuid].camera.patch(camera),

  /**
   * Добавить или изменить камеру у указанных коллизий
   * @param {String} collisionsUuid UUID коллизий
   * @param {String} camera Сохранение камеры
   */
  setCameraToGroup: (collisionsUuid, camera) => Factory.collisions.group.camera.patch({ camera: camera, uuidList: collisionsUuid}),

  /**
   * Получить вид отображения на коллизию по uuid
   * @param {String} collisionUuid UUID коллизии
   */
  getCollisionView: (collisionUuid) => Factory.collisions[collisionUuid].geometry.get(),
  
  // ------ COLLISION_GEOMETRY
  /**
   * Получить геометрию коллизии по uuid (фигура пересечения)
   * @param {String} collisionUuid UUID коллизии
   */
  getCollisionGeometry: (collisionUuid) => Factory.geometry.collision[collisionUuid].get(),

  // ------ STATUS
  /**
   * Указать статус коллизии
   * @param {String} collisionUuid UUID коллизии
   * @param {String} status Новый статус
   */
  setCollisionStatus: (collisionUuid, status) => Factory.collisions[collisionUuid].status[status].patch(),
  
  /**
   * Групповая установка статусов
   * @param {Array<String>} collisions UUIDы коллизий 
   * @param {String} status Новый статус
   */
  setCollisionsStatus: (collisions, status) => Factory.collisions.status[status].patch(collisions),

  /**
   * Пометить указанную коллизию как допустимую
   * @param {String} collisionUuid UUID коллизии
   */
  setAllowed: (collisionUuid) => Factory.collisions[collisionUuid].allowed.patch(),

  /**
   * Пометить все перечисленные коллизии как допустимые
   * @param {Array<String>} uuids Список UUID коллизий
   * @deprecated
   */
  allowCollisions: (uuids) => Factory.collisions.allowed.patch(uuids),

  /**
   * Взять в работу указанный список коллизий
   * @deprecated
   * @param {Array<String>} uuids список UUID коллизий, которые пользователь собирается взять в работу
   */
  takeToWork: (uuids) => Factory.collisions.work.patch(uuids),

  // ------ IS_COLLISION
  /**
   * Сохранить истинность или ложность для коллизий
   * @param {Map<String, Boolean>} collisionsTrueOrFalse Map<String, Boolean> словарь с ключем-идентификатором и булевым значением
   */
  setTrueOrFalse: (collisionsTrueOrFalse) => Factory.collisions.move_to_not_collisions_list.post(collisionsTrueOrFalse),

  // ------ COLLISION_TYPE
  /**
   * Установить истинность или ложность коллизии
   * @param {String} collisionUuid Идентификатор коллизии
   */
  changeIsCollisionValue: (collisionUuid) => Factory.collisions[collisionUuid].is_collision.patch(),
  
  /**
   * Получить список типов коллизий
   */
  getCollisionTypes: () => Factory.collisions.types.get(),

  /**
   * Установить тип коллизии для списка коллизий
   * @param {Map<String, CollisionType} collisionsTypes Map<String, Boolean> словарь с ключом-идентификатором и типом коллизии
   */
  setCollisionTypes: (collisionsTypes) => Factory.collisions.set_types.post(collisionsTypes),

  /**
   * Обновить допустимые расстояния коллизий
   * @param {Map<String, BigInt64} collisionsTypes Map<String, Integer> словарь с ключом-идентификатором и допустимым значением
   */
  updateAllowedDistances: (allowedDistances) => Factory.collisions.update_allowed_distance.post(allowedDistances),

  // ------ DETAILS
  /**
   * Получить детали коллизии по uuid
   * @param {String} collisionUuid UUID коллизии
   */
  getDetails: (collisionUuid) => Factory.collisions[collisionUuid].details.get(),

  // ------ STATISTIC
  /**
   * Получить статистику по коллизиям по проекту
   * @param {String} projectUuid UUID проекта
   */
  getStatistic: (projectUuid) => Factory.projects[projectUuid].collisions.statistic.get(),

  // ------ AVAILABLE_MODELS
  /**
   * Получение списка моделей на которые можно перевести стрелки, если пусто то нельзя перевести
   * @param {Array<String>} uuids UUID коллизий, для которых будет проведен поиск доступных моделей
   */
  getAvailableModels: (uuids) => Factory.collisions.pingponglist.search.post(uuids),

  // ------ Distance

  /**
   * Получить список расстояний для коллизии
   * @param {UUID} collisionUuid 
   * @returns {List<String>} Список расстояний
   */
  getDistanceList: (collisionUuid) => Factory.collisions[collisionUuid].distance.get(),

  /**
   * 
   * @param {Object} obj 
   * @param {UUID} obj.collisionUuid UUID коллизии
   * @param {UUID} obj.value Значение "погружения" одного элемента в другой
   * @returns nothing
   */
  changeActiveDistance: (obj) => Factory.collisions[obj.collisionUuid].value.post(obj.value),

  // ------ REASSIGN_TO
  /**
   * Переназначить указанные коллизии на указанную дисциплину (Пинг-Понг)
   * @param {String} model UUID модели, куда назначить выбранные коллизии
   * @param {Array<String>} uuids Массив UUID коллизий, которые необходимо переназначить
   */
  reassignTo: (model, uuids) => Factory.collisions.ping[model].patch(uuids),

  initCollisionsReportProcess: (projectUuid, reportType, body) => Factory.report[projectUuid][reportType].post(body),
})