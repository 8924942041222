// import { taskService } from '@/_services'
import _ from 'lodash'
import { api } from '@/api'

import project from './project.module'
import { Task } from '@/assets/model/task/Task'
import { TaskGantt } from '@/assets/model/task/TaskGantt'
import { TaskType } from '@/assets/model/task/TaskType'
import { TaskTreeCache } from '@/assets/model/task/TaskTreeCache'

import SelectedModule from './selected.module'
import {XeokitMediator} from "@/plugins/xeokit/XeokitMediator";
import { TaskAttachment } from '@/assets/model/task/TaskAttachment'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'

let numberToFixed = function ( number, maxFixed = 4 ) {
  let cntFixed = 0
  if (number.toString().indexOf(".") != -1 && maxFixed > 0) {
    cntFixed = number.toString().split(".")[1].length 
    cntFixed = cntFixed < maxFixed ? cntFixed : maxFixed     
  }
  return cntFixed > 0 ? Number(number.toFixed(cntFixed)) : Number(number) 
}

const defaultForm = Object.freeze({
  uuid: null,
  name: null,
  project: null,
  text: null,
  creator: false,
  forMe: false,
  membership: [],
  statuses: [],
  pluginStatuses:[],
  priorities: [],
  models: [],
  dateStart: null,
  dateEnd: null,
  attachment: false,
  archived: false, 
  reverseClippingPlanes: false
})

const defaultTask = Object.freeze({
  title: '', 
  project:  null, 
  startDate: '',
  endDate: '',
  description: '',
  taskType: null,
  uuid: null
})

let getTasksFlatList = function (tree) {
  let array = []
  tree.forEach(element => {
    var task = new Task(element)
    if (task?.report?.estimation) {
      task.report.estimation = numberToFixed(task.report.estimation)
    }
    array.push(...task.convertToFlatList())
  })
  //array.sort((a, b) => Date.parse(b.createDate) - Date.parse(a.createDate))
  return array
}

let getTasksFlatListWithoutClosed = function (tree) {
  let array = []
  tree.forEach(element => {
    var task = new Task(element)
    if (task?.report?.estimation) {
      task.report.estimation = numberToFixed(task.report.estimation)
    }
    array.push(...task.convertToFlatList())
  })
  const filteredItems = array.filter(item => item.completeStatus.name !== 'CLOSED')
  return filteredItems
}

let computeExpectedProgress = function (task) {
  let expected_progress = 0
  const milliseconds_in_day = 86400000
  
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  let start = new Date(task.startDate).getTime()
  let end = new Date(task.endDate).getTime()

  const working_days_count = 1 + (end - start) / milliseconds_in_day
  let progress_per_day = 100 / working_days_count
  
  if(today.getTime() >= start && today.getTime() <= end) {
    let gone_days_count = (today.getTime() - start) / milliseconds_in_day
    expected_progress = progress_per_day * gone_days_count
  }
  else if(today > end) {
    expected_progress = 100
  }
  
  return expected_progress
}

const getDefaultState = () => {
  return {
    tasks: [],
    gantt: [],
    selectedTask: null,
    showSmetaTask: null,
    priorities: [],
    completeStatuses: [],
    filterPanel: false,
    taskType: null, 
    creatingTask: null,
    creatingTaskType: null,
    newTaskType: null,
    sortDes: false,
    sortAsc: false,
    filter: Object.assign({}, defaultForm),
    defaultForm,

    filterList: [],
    taskDisplay: 'dashboard',

    smetaElementsSample: [],

    smetaDetails: false,

    taskIsLoad: false,

    measureUnitType: [],
    
    taskColumns: [],

    isTaskCreating: false,

    tree: [],

    treeHeaders: [
      { text: "module.task.columns.theme", value: 'title', sortable: false, show: true, required: true },
      { text: "module.task.columns.status", value: 'completeStatus', sort: (a, b) => a.value - b.value, show: true },
      { text: "module.task.columns.progress", value: 'progress', show: true },
      { text: "module.task.columns.executor", value: 'workers', sortable: false, show: true },
      { text: "module.task.columns.start", value: 'startDate', sort: (a, b) => new Date(a).getTime() - new Date(b).getTime(), show: true },
      { text: "module.task.columns.end", value: 'endDate', sort: (a, b) => new Date(a).getTime() - new Date(b).getTime(), show: true }
    ],

    subTasksOpen: [],

    ganttInitScroll: null,

    taskTreeCache: null,

    checkChildrenApi: false,

    prevTaskPanel: {
      panelName: 'dashboard',
      width: window.innerWidth / 6,
    },

    treeWidth: window.innerWidth / 2 - 24,
    diagramWidth: window.innerWidth / 2 - 24,
    dashboardWidth: window.innerWidth / 6,

    pluginStatus: [],
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  modules: {
    SelectedModule
  },

  state,

  getters: {
    //filteredTasks: ({ tasks }) => tasks.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime()),//.map(task => new Task(task, task.parent, task.level)),
    filteredTasks: ({ tasks, filter }) => {
      return tasks.filter(task => {
        let taskValid = true

        if (filter.text) {
          taskValid = task.title.toLowerCase().includes(filter.text.toLowerCase())
          if (!taskValid) return false
        }
        if (filter.membership.length) {
          taskValid = task.workers.find(membership => filter.membership.includes(membership.uuid))
          if (!taskValid) return false
        }
        if (filter.statuses.length) {
          taskValid = filter.statuses.find(status => task.completeStatus.name === status.name)
          if (!taskValid) return false
        }
        if (filter.pluginStatuses.length) {
          taskValid = filter.pluginStatuses.find(status => task.pluginStatus === status)
          if (!taskValid) return false
        }
        if (filter.priorities.length) {
          taskValid = filter.priorities.find(priority => task.priority.name === priority.name)
          if (!taskValid) return false
        }
        if (filter.models.length) {
          taskValid = filter.models.find(model => task.models.value === model.value)
          if (!taskValid) return false
        }
        if (filter.creators) {
          taskValid = filter.creators.includes(task.creator.uuid)
          if (!taskValid) return false
        }
        if (filter.dateEnd) {
          let f = new Date(filter.dateEnd)
          let t = new Date(task.endDate)
          taskValid = t.getTime() < f.getTime()
          if (!taskValid) return false
        }
        if (filter.dateStart) {
          let f = new Date(filter.dateStart)
          let t = new Date(task.startDate)
          taskValid = f.getTime() < t.getTime()
          if (!taskValid) return false
        }

        return taskValid
      })
    },

    allWorkersOnTask: ({ selectedTask }) => {
      if (!selectedTask) return []
      let list = selectedTask.responder ? [{ ...selectedTask.responder, isResponder: true }] : []
      list = selectedTask.workers ? _.uniqBy([...list, ...selectedTask.workers], 'uuid') : list
      return list
    },

    getGroupWorkers: ({ selectedTask }) => {
      if (!selectedTask) return []
      return selectedTask.groupWorkers || []
    },

    getTaskType: ({ selectedTask }) => {
      return selectedTask.taskType
    },

    getCreatingTaskType: ({ creatingTaskType }) => {
      return creatingTaskType
    },

    tasksUuids: ({ tasks }) => {
      return tasks.filter(task => task.classificatorNodeUuid).map( task => task.uuid )
    },
    endDateAfter: ({ tasks, smetaElementsSample }) => (date) => {
      let ready = []
      let inJob = []
      let newEl = []
      let lateness = []

      Object.keys(smetaElementsSample).forEach(task => {
        let t = tasks.find(el => el.uuid == task)
        if(t) {
          let endDate = Date.parse(t.endDate)
          let startDate = Date.parse(t.startDate)
  
          const expectedProgress = computeExpectedProgress(t)
          if(expectedProgress > t.progress) {
            lateness = lateness.concat(smetaElementsSample[task])
          }
  
          if (endDate < date) ready = ready.concat(smetaElementsSample[task])
          else if (startDate >= date) newEl = newEl.concat(smetaElementsSample[task])
          else if (startDate <= date && endDate >= date) inJob = inJob.concat(smetaElementsSample[task])
        }
      })

      return { ready: ready, new: newEl, inJob: inJob, lateness: lateness }
    },
    firstTaskDate: ({ tasks }) => {
      let date = Date.parse(new Date())
      tasks.forEach(task => {
        if (Date.parse(task.startDate) < date)
          date = Date.parse(task.startDate)
      })
      return date
    },
    lastTaskDate: ({ tasks }) => {
      let date = 0
      tasks.forEach(task => {
        if (Date.parse(task.endDate) > date)
          date = Date.parse(task.endDate)
      })
      return date
    },

    taskTreeIsOpened: ({taskTreeCache}) => (task) => {
      if(taskTreeCache.opened.has(task.uuid)) return true
      return false
    },

    isTaskManager() {
      return window.location.href.includes('task/manager')
    }
  },

  mutations: {
    resetState (state) { 
      const priorities = state.priorities
      const completeStatuses = state.completeStatuses
      const measureUnitType = state.measureUnitType
      Object.assign(state, getDefaultState())
      state.priorities = priorities 
      state.completeStatuses = completeStatuses
      state.measureUnitType = measureUnitType
    },
    
    setTasks: (state, list) => { state.tasks = list },
    setSelectedTask: (state, task) => { state.selectedTask = task, state.isTaskCreating = false },
    setSelectedTaskByUuid: (state, taskUuid) => {state.selectedTask = state.tasks.find(task => task.uuid == taskUuid)},
    setSelectedTaskField: (state, {value, index}) => {state.selectedTask.taskType.fields[index].value = value},
    setSelectedTaskType: (state, {taskType}) => {state.selectedTask.taskType = new TaskType(taskType)},
    setShowSmetaTask: (state, task) => { state.showSmetaTask = task },
    setPriorities: (state, list) => { state.priorities = list || [] },
    setCompleteStatuses: (state, list) => { state.completeStatuses = list || [] },
    setMeasureUnitType: (state, list) => { state.measureUnitType = list || [] },
    setFilterPanel: (state, filterPanel) => { state.filterPanel = filterPanel || false },
    setFilter: (state, filter) => { state.filter = filter || Object.assign({}, defaultForm) },
    setFilterList: (state, list) => { state.filterList = list || [] },
    setFilterToFilterList: (state) => { state.filterList.push(state.filter) },
    setTaskDisplay: (state, method) => { state.taskDisplay = method },
    setSmetaElementsSample: (state, payload) => { state.smetaElementsSample = payload },
    setTaskType: (state, {taskType, index} ) => { taskType ? state.tasks[index].taskType = new TaskType(taskType) : state.tasks[index].taskType = null },
    setCreatingTaskType: (state, { taskType }) => { state.creatingTask.taskType = new TaskType(taskType); state.creatingTaskType = new TaskType(taskType); },
    setNewTaskType: (state, {taskType, fields}) => { state.newTaskType = taskType; state.newTaskType.fields = fields },
    setTreeHeaders: (state, headers) => { state.treeHeaders = headers },
    setCreatingTaskTitle: (state, title) => {state.creatingTask.title = title},
    setCreatingTaskDesc: (state, desc) => {state.creatingTask.description = desc},
    setCreatingTaskDate: (state, start, stop) => { state.creatingTask.startDate = start; state.creatingTask.endDate = stop },
    setCreatingTaskTypeBind: (state, bind) => {state.creatingTaskType.bind = bind},
    setTaskTreeColumns: (state, columns) => { state.taskColumns = columns },
    setMaintenanceTask: (state, maintenanceTask) => { state.selectedTask.maintenanceTask = maintenanceTask },
    setTaskHeaders: (state, headers) => { state.treeHeaders = headers },

    showSmetaDetails: (state) => { state.smetaDetails = true },
    hideSmetaDetails: (state) => { state.smetaDetails = false }, 

    setGantt: (state, listGantt) => { state.gantt = listGantt },

    setTree: (state, tree) => { state.tree = tree.map(task => new Task(task)) },

    addSubTaskOpen: (state, task) => { state.subTasksOpen.push(task) },
    delSubTaskOpen: (state) => { state.subTasksOpen.pop() },
    delAllSubTaskOpen: (state) => { state.subTasksOpen=[] },

    setGanttInitScroll: (state, scroll) => { state.ganttInitScroll = scroll },
    
    handleTreeClose: (state, task) => { state.taskTreeCache.close(task)},
    handleTreeOpen: (state, task) => { state.taskTreeCache.open(task)},
    setTaskIsLoad: (state, taskIsLoad) => { state.taskIsLoad = taskIsLoad},
    setIsTaskCreating: (state, isCreating) => { state.isTaskCreating = isCreating },

    setPrevTaskPanel: (state, taskPanel) => { 
      state.prevTaskPanel.panelName = taskPanel.panelName
      state.prevTaskPanel.width = taskPanel.panelWidth
    },

    setTreeWidth: (state, treeWidth) => { state.treeWidth = treeWidth },
    setDiagramWidth: (state, diagramWidth) => { state.diagramWidth = diagramWidth },
    setDashboardWidth: (state, dashboardWidth) => { state.dashboardWidth = dashboardWidth },

    setPluginStatus: (state, status) => { state.pluginStatus = status },
    setSortDes: (state, sortDes) => { state.sortDes = sortDes },
    setSortAsc: (state, sortAsc) => { state.sortAsc = sortAsc },
  },

  actions: {
    setCreatingTask({commit, state}) {
      state.creatingTask = Object.assign({}, defaultTask)
      // let startDate = state.creatingTask.startDate.length > 0 ? new Date(state.creatingTask.startDate).getUTCDate(): new Date().getUTCDate()
      // let endDate = state.creatingTask.endDate.length > 0 ? new Date(state.creatingTask.endDate).getUTCDate(): new Date().getUTCDate()
      // commit('setCreatingTaskDate', startDate, endDate)
      commit('setSelectedTask', state.creatingTask)
    },

    setCreatingTaskFromTaskManager({state}){
      state.creatingTask = Object.assign({}, defaultTask)
    },

    setSelectedTaskByUuid( { commit, state }, taskUuid ) {
      let task = state.tasks.find(task => task.uuid === taskUuid)

      if (state.selectedTask?.uuid != task?.parent?.uuid) {
        commit('delAllSubTaskOpen')
      }

      commit('addSubTaskOpen', task.uuid)
      commit('setSelectedTask', task)
    },

    setTaskType({commit, state}, taskType){
      state.tasks.map((task, index) => {
        if(taskType && task.uuid == taskType.bind) {
          commit('setTaskType', {taskType, index})
        }
      })

      if(state.creatingTask != null){
        commit('setCreatingTaskType', {taskType})
      }
      
    },

    setTaskTypeFields({dispatch, commit, state}, taskType) {
      let newTaskType = {}
      let indexTask = null
      state.tasks.map((task, index) => {
        if(task.uuid == taskType.bind) {
          newTaskType = task.taskType
              if(taskType.fields.length > 0) {
            taskType.fields.map((field, index) => {
              taskType.form.fields[index].name ? newTaskType.fields[index].field.name = taskType.form.fields[index].name : ""
              field.value ? newTaskType.fields[index].value = field.value : ""
            })
          }
          indexTask = index
        }
      })
      //commit('setSelectedTaskType', newTaskType)

      if(newTaskType != null && indexTask != null){
        commit('setTaskType', {taskType: newTaskType, index: indexTask})
        dispatch('loadTasks')
      }
    },

    loadTasks({ commit, state, rootState, getters, dispatch }) {
      state.taskIsLoad = false
      let user = rootState.authUser.user
      let filters = user && user !== undefined && user.profileSettings ? user.profileSettings.find(setting => setting.type.name === 'TASK_FILTER') : null
      let taskFilters = filters ? JSON.parse(filters.value) : []
      commit('setFilterList', taskFilters)

      state.filter.project = project.state.project.uuid
      return api.tasks.search(state.filter).then(list => {
        commit('setTree', list)
        if (state.filter.statuses.find(status => status.name === 'CLOSED')) {
          commit('setTasks', getTasksFlatList(list))
        }
        else {
          commit('setTasks', getTasksFlatListWithoutClosed(list))
        }
        state.taskIsLoad = true

        let listGantt = state.tasks.map(task => new TaskGantt(task) )
        commit("setGantt", listGantt)
        
        dispatch('getGlobalIdsByUuids', getters.tasksUuids)
  
        if (rootState.project?.projectSettings?.workSpace) {
          dispatch('getPluginTaskStatus', rootState.project.projectSettings.workSpace)
        } else {
          setTimeout(() => dispatch('getPluginTaskStatus', rootState.project.projectSettings.workSpace), 300)
        }
        
        if (state.selectedTask) {
          let task = state.tasks.find(t => t.uuid === state.selectedTask.uuid)       
          if (task) {
            task.taskType = state.selectedTask.taskType
            commit('setSelectedTask', task)
          }
          else {
            commit('setSelectedTask', null)
          }
        }

        if(!state.taskTreeCache) {
          state.taskTreeCache = new TaskTreeCache()
          state.taskTreeCache.setTasks(getters.filteredTasks)
        }
        else {
          state.taskTreeCache.setTasks(getters.filteredTasks, state.taskTreeCache.opened)
        }

        if (state.showSmetaTask) {
          const taskSmeta = state.tasks.find(t => t.uuid === state.showSmetaTask.uuid)
          if (taskSmeta) commit('setShowSmetaTask', taskSmeta)
        }

        TaskBimAnnotations.showTaskBimAnnotations()
        return true
      })
        .catch((e) => {
          console.log(e);
          commit('setTasks', [])
        })
    },

    setShowSmetaTask({ commit, dispatch }, task) {
      commit('setShowSmetaTask', task)
      dispatch('selected/dropSmetaHighlights', [], { root: true })
    },

    loadDictionaries({ state, commit }) {
      if (state.priorities.length === 0)
        api.tasks.priorityTypes().then(data => { commit('setPriorities', data) })
      if (state.completeStatuses.length === 0)
        api.tasks.completeStatusTypes().then(data => { commit('setCompleteStatuses', data) })
      if (state.measureUnitType.length === 0)
        api.tasks.getMeasureUnitType().then(data => { commit('setMeasureUnitType', data) })
    },

    loadTaskType({ commit }, task) {
      if (task?.uuid && task.uuid != null && task.taskType && (task.taskType.uuid == null || task.taskType.uuid == undefined)) {
        return api.forms.dataList(task.uuid).then((data) => {
          if (data.length > 0) {
            commit('setSelectedTaskType', { taskType: data[data.length - 1] })
          }
        })
      }
    },

    loadMaintenanceTask({state, commit}){
      let uuid = state.selectedTask.maintenanceTaskUuid
      if(uuid) {
        return api.maintenance.getMaintenanceTask(uuid).then(data => commit('setMaintenanceTask', data))
      }
    },

    addTask({state, commit, dispatch }, obj) {
      obj.taskType = null
      return api.tasks.create(obj).then(task => {
        commit('setSelectedTask', task)
        commit('addSubTaskOpen', task.uuid)
        if(state.creatingTaskType != null){
          commit('setCreatingTaskTypeBind', task.uuid)
        }
        dispatch('loadTasks')
        return task.uuid
      })
    },

    addSubTask({state, commit, dispatch }, obj) {
      obj.taskType = null  
      state.checkChildrenApi = true;
      return api.tasks.create(obj).then(task => {
        // commit('addSubTaskOpen', task)
        if(state.creatingTaskType != null){
          commit('setCreatingTaskTypeBind', task.uuid)
        }
        dispatch('loadTasks')
        state.checkChildrenApi = false;
        return task.uuid
      })
    },

    addTaskByManager({dispatch },obj){
      obj.taskType = null
      api.tasks.create(obj).then(() => { return dispatch('loadTasks') })
    },

    async deleteTask({ dispatch, rootGetters, rootState, commit }, task) {
      // Выносим дочерние задачи на один родительский уровень вверх
      const reorderTasks = task.parent ? 
        task.children.map(childTask => api.tasks.changeParent(childTask.uuid, task.parent)) : 
        task.children.map(childTask => api.tasks.taskOutOfParent(childTask.uuid))
      
      await Promise.all(reorderTasks)

      if (rootGetters['collision/search/preparedCollisionsTask']?.length > 0) {
        let taskCollision = rootState.collision.search.collisionsTask
        taskCollision.forEach(collision => {
          let index = collision.tasks.findIndex(collisionTask => collisionTask.uuid === task.uuid)
          if (index != -1) {
            collision.tasks = collision.tasks.splice(index, 1)
          }
        })
      }

      return api.tasks.archiving(task.uuid).then(() => { 
        if (task.collision?.length && rootGetters['project/activeGlobalTab'] === 'collision') {
          dispatch('collision/search/loadCollisions', {}, {root: true})
          if (rootState.collision.table.taskCollision.taskUuid === task.uuid) {
            commit('collision/table/CHANGE_COLLISION_SOURCE', { isActive: false }, { root: true })
            commit('collision/search/SET_COLLISIONS_TASK', [], { root: true })
          }
        }
        return dispatch('loadTasks') 
      })
    },

    addTaskOpenPanel({ commit, dispatch }, obj) {
      return api.tasks.create(obj).then(task => {
        if (task) {
          commit('project/setTopUnit', 'task', { root: true })
          dispatch('loadTasks').then(() => {
            commit('setSelectedTask', task)
          })
          return task
        }
      })
    },

    changeTaskTitle({commit, dispatch, rootState }, obj) {
      if(obj.uuid != null || obj.uuid != undefined){
        rootState.collision.table.tableItems.forEach(collision => {
          if (collision.tasks && collision.tasks.length > 0) {
            collision.tasks.forEach(task => {
              if (task.uuid === obj.uuid) task.title = obj.title
            })
          }
        })

        if (rootState.collision.table.taskCollision.isActive) {
          rootState.collision.table.tableItemsBuffer.forEach(collision => {
          if (collision.tasks && collision.tasks.length > 0) {
            collision.tasks.forEach(task => {
              if (task.uuid === obj.uuid) 
              task.title = obj.title
            })
          }
        })
        }
        api.tasks.setTitle(obj.uuid, obj.title).then(() => { dispatch('loadTasks') })
      } else {
        commit('setCreatingTaskTitle', obj.title)
      }
    },

    changeTaskDesc({ commit, dispatch }, obj) {
      if(obj.uuid != null || obj.uuid != undefined){
        api.tasks.setDescription(obj.uuid, obj.description).then(() => { dispatch('loadTasks') })
      } else {
        commit('setCreatingTaskDesc', obj.description)
      }
    },

    changeTaskStatus({ dispatch }, obj) {
      api.tasks.setStatus(obj.uuid, obj.status).then(() => { dispatch('loadTasks') })
    },

    changeTaskPriority({ dispatch }, obj) {
      api.tasks.setPriority(obj.uuid, obj.priority).then(() => { dispatch('loadTasks') })
    },

    addComment({ dispatch }, { uuid, text, quotedCommentUuid }) {
      return api.tasks.createComment(uuid, text, quotedCommentUuid).then(comment => {
        dispatch('loadTasks')
        return comment
      })
    },

    editComment({ dispatch }, { uuid, text }) {
      api.tasks.editComment(uuid, text).then(() => { dispatch('loadTasks') })
    },

    editCommentAttachment({ dispatch }, { uuid, text }) {
      let sourceData = JSON.stringify({comment: text})
      api.tasks.editSourceDataAttachment(uuid, sourceData).then(() => { dispatch('loadTasks') })
    },

    editCommentAttachmentWithCam({ dispatch }, { uuid, data }) {
      let sourceData = JSON.stringify( data )
      return api.tasks.editSourceDataAttachment(uuid, sourceData).then(() => { dispatch('loadTasks') })
    },

    delComment({ dispatch }, { taskUid, uuid }) {
      api.tasks.deleteComment(taskUid, uuid).then(() => { dispatch('loadTasks') })
    },

    delCommentOrAttachment({ dispatch }, { taskUid, comment }) {
      if (comment instanceof TaskAttachment) {
        return api.tasks.deleteAttachment(comment.uuid).then(() => { return dispatch('loadTasks') })
      } else {
        return api.tasks.deleteComment(taskUid, comment.uuid).then(() => { return dispatch('loadTasks') })
      }
    },
    replyCommentOrAttachment({ dispatch }, { taskUid, comment }) {
      if (comment.camera) {
        api.tasks.replyAttachment(comment.uuid).then(() => { dispatch('loadTasks') })
      } else {
        api.tasks.replyComment(taskUid, comment.uuid).then(() => { dispatch('loadTasks') })
      }
    },
    setResponder({ dispatch }, { uuid, responderUuid }) {
      api.tasks.setResponder(uuid, responderUuid).then(() => { dispatch('loadTasks') })
    },

    setGroupResponder({ dispatch }, { uuid, wGroupUuid }) {
      api.tasks.setGroupResponder(uuid, wGroupUuid).then(() => dispatch('loadTasks'))
    },

    setWorkers({ dispatch }, { uuid, workerIds }) {
      api.tasks.setWorkers(uuid, workerIds).then(() => { dispatch('loadTasks') })
    },

    setGroupWorkers({ dispatch }, { uuid, groupWorkerIds }) {
      api.tasks.setGroupWorkers(uuid, groupWorkerIds).then(() => { dispatch('loadTasks') })
    },

    setModels({ dispatch }, { uuid, models }) {
      api.tasks.setModels(uuid, models).then(() => { dispatch('loadTasks') })
    },

    setProgress({ dispatch }, { uuid, progress }) {
      api.tasks.setProgress(uuid, progress).then(() => { dispatch('loadTasks') })
    },

    setStartDate({ dispatch }, { uuid, date }) {
      api.tasks.setStartDate(uuid, date).then(() => { dispatch('loadTasks') })
    },

    setEndDate({ dispatch }, { uuid, date }) {
      api.tasks.setEndDate(uuid, date).then(() => { dispatch('loadTasks') })
    },

    setDateInterval({commit, dispatch }, { uuid, start, end }) {
      if(uuid != undefined || uuid != null){
        api.tasks.setDateInterval(uuid, start, end).then(() => { dispatch('loadTasks') })
      } else {
        commit('setCreatingTaskDate', start, end)
      }
    },

    setDateIntervalOfTasks({ dispatch }, tasks) {
      return api.tasks.setDateIntervalOfTasks(tasks).then(() => { dispatch('loadTasks') })
    },

    addAttachment({ dispatch }, { uuid, name, file }) {
      return api.tasks.createAttachment(uuid, name, file).then(() => {
        dispatch('loadTasks')
        return true
      })
    },

    addAttachmentWithCam({ dispatch }, { uuid, name, file, sourceData, collision = null }) {
      return api.tasks.createAttachment(uuid, name, file, sourceData, collision).then(() => {
        return dispatch('loadTasks')
      })
    },

    deleteAttachment({ dispatch }, attachmentUUID) {
      return api.tasks.deleteAttachment(attachmentUUID).then(() => { return dispatch('loadTasks') })
    },

    search({ commit }, filter) {
      console.log("****")
      api.tasks.search(filter).then(list => {
        commit('setTasks', getTasksFlatList(list))
      })
    },

    saveFilter({ commit, dispatch }) {
      commit("setFilterToFilterList")
      dispatch('setFilterSettings')
    },

    clearFilter({ state, dispatch }, filter) {
      state.filterList = state.filterList.filter(item => item.uuid !== filter.uuid)
      dispatch('setFilterSettings')
    },

    setFilterSettings({ state, rootState, commit, dispatch }) {
      let user = rootState.authUser.user
      api.users.setSettings({ type: { value: "TASK_FILTER" }, value: JSON.stringify(state.filterList) }).then(data => {
        commit('setFilterList', JSON.parse(data.value))
        user.profileSettings = user.profileSettings.filter(item => item.type.name !== "TASK_FILTER")
        user.profileSettings.push(data)
        dispatch("authUser/updateUser", user, { root: true })
      })
    },

    bindToSmeta({ state }, bindData) {
      console.log(state.tasks)
      api.tasks.bindToSmeta(bindData).then(data => {
        console.log(data)
      })
    },

    getSmetasByTaskUuid({ dispatch }, taskUuid) {
      let items = {
        "NOSTATUS" : {color:[1, 1, 0], ids: []},
        "NEW" : {color:[1, 1, 0], ids: []},
        "DISCUSS": {color:[1, 1, 0], ids: []},
        "PROGRESS" : {color:[1, 0, 0], ids: []},
        "READY" : {color:[0, 1, 0], ids: []},
        "CLOSED" : {color:[0, 1, 0], ids: []}
      }

      
        return api.tasks.getSmetaData(taskUuid).then(smetaData => {
          // console.log("bind: ", smetaData)

          // добавить проверку находимся ли мы в модели или нет
          if(XeokitMediator.viewer) {
            api.tasks.getTaskElementsHistory(project.state.project.uuid, taskUuid).then(async hist => {
              dispatch
              if (hist?.length > 0) {
                hist.forEach(el => {
                  if (el.lastStatus != null) items[el.lastStatus.status.name].ids.push(el.globalId)
                  else items["NOSTATUS"].ids.push(el.globalId)
                })

                let objIds = []

                for (let key of Object.keys(items)) {
                  objIds = objIds.concat(items[key].ids)
                  if (items[key].ids.length > 0) XeokitMediator.ElementsSettings.setElementsColorized(items[key].ids, items[key].color)
                  await XeokitMediator.ElementsSettings.addExcludeColorizeItems(items[key].ids)
                }
                XeokitMediator.ElementsSelection.selectElements([])
                XeokitMediator.ElementsSelection.selectElements(objIds)

              }
            })
          }
          // так было по старому 
          // api.tasks.getGlobalIds(project.state.project.uuid, taskUuid).then(globalIds => {
          //   if (globalIds?.length > 0) {
          //     dispatch('selected/setSelectedElements', globalIds, { root: true })
          //   }
          // })

          return smetaData
        }).catch(() => {
          console.log('smetaList return error');
          return null
        })
    },
    getGlobalIdsByUuids({ commit }, uuids) {
      return api.tasks.getGlobalIdsByUuidsList(project.state.project.uuid, uuids).then(data => {
        commit('setSmetaElementsSample', data)
      })
    },

    getSmetaVolumes({ state }, obj) {
      let { taskUuid, dataTask } = obj

      if (!taskUuid) {
        if (!state.selectedTask) return null
        taskUuid = state.selectedTask.uuid
      }

      return api.tasks.getSmetaVolumes(taskUuid).then(volumes => {
        if (volumes && volumes.length > 0) {
          const unit = ` (${volumes[0].units})`
          let quantityAll = numberToFixed(volumes[0].quantityAll)
          let spent = numberToFixed(( dataTask.progress / 100 ) * quantityAll)
          let projected = numberToFixed( quantityAll - spent )

          return {
            quantityAll: quantityAll + unit,
            spent: spent + unit,
            projected: projected + unit,
          }
        } else {
          return {
            quantityAll: '',
            spent: '',
            projected: '',
          }
        }
        
      })
    },

    findTaskByElementGlobalId({ state, commit }, globalId) {
      Object.keys(state.smetaElementsSample).forEach(task => {
        state.smetaElementsSample[task].forEach(elem => {
          if (globalId == elem) {
            commit('setShowSmetaTask', state.tasks.find(t => t.uuid == task))
            commit('showSmetaDetails')
          }
        })
      })

    },
    getFirstTaskBySmetaNode({ state }, nodeUuid) {
      return api.tasks.getTasksBySmetaNode(nodeUuid).then(taskList => {
        return state.tasks.find(el => el.uuid == taskList[0].taskUuid)
      })
    },

    getTaskById({state}, id) {
      return state.tasks.find(el => el.uuid == id)
    },

    createTaskReport ({ dispatch }, {selectedTask, report}) {
      return api.tasks.createTaskReport(selectedTask.uuid, report).then(() => { return dispatch('loadTasks').then(() => {return report}) })
    },

    editTaskReport ({ dispatch }, {selectedTask, report}) {
      return api.tasks.editTaskReport(selectedTask.uuid, report).then(() => { return dispatch('loadTasks').then(() => {return report}) })
    },

    createTaskReportVolume ({ dispatch }, {selectedTask, reportVolume}) {
      return api.tasks.createTaskReportVolume(selectedTask.uuid, reportVolume).then((report) => { return dispatch('loadTasks').then(() => {return report}) })
    },

    editTaskReportVolume ({ dispatch }, {selectedTask, reportVolume}) {
      return api.tasks.editTaskReportVolume(selectedTask.uuid, reportVolume).then(() => { return dispatch('loadTasks') })
    },

    setTaskReportVolumeValue ({ dispatch }, {selectedTask, reportVolume}) {
      return api.tasks.setTaskReportVolumeValue(selectedTask.uuid, reportVolume.uuid, reportVolume.value).then(() => { return dispatch('loadTasks') })
    },

    setTaskReportVolumeReportDate ({ dispatch }, {selectedTask, reportVolume}) {
      return api.tasks.setTaskReportVolumeReportDate(selectedTask.uuid, reportVolume.uuid, reportVolume.reportDate).then(() => { return  dispatch('loadTasks') })
    },

    deleteTaskReportVolume ({ dispatch }, {selectedTask, reportVolumeUid}) {
      return api.tasks.deleteTaskReportVolume(selectedTask.uuid, reportVolumeUid).then(() => { return dispatch('loadTasks') })
    },

    deleteAllTaskReportVolume ({ dispatch }, selectedTask) {
      return api.tasks.deleteAllTaskReportVolume(selectedTask.uuid).then(() => { return dispatch('loadTasks') })
    },

    importTask: ({ dispatch }, { project, file, classificator}) => {
      return api.tasks.importTask(project, file, classificator).then(data => {
        dispatch('loadTasks')
        return data
      })
    },

    recalcTask: ({ dispatch }, { project, start}) => {
      return api.tasks.recalcTask(project, start).then(data => {
        dispatch('loadTasks')
        return data
      })
    },

    getNewTaskType({state}) {
      return state.newTaskType
    },

    sortTreeHeaders( { state }, {columnType, taskColumns} ) {
      state.treeHeaders.map(header => {
        header.position = taskColumns.find(column => column.name.value === columnType.get(header.name)).position
        header.show = !taskColumns.find(column => column.name.value === columnType.get(header.name)).excluded
      })

      state.treeHeaders.sort(function(a, b){
        return a.position - b.position
      })
    },

    reorderTaskChildren({ dispatch }, uuid){
      return api.tasks.changeOrder(uuid).then(() => { dispatch('loadTasks') })      
    },

    changeWorkspace({ dispatch },{ uuid, value}){
      return api.tasks.changeWorkspace(uuid,value).then(() => { dispatch('loadTasks') })
    }, 

    changeParent({ dispatch }, { uuid, value}){
      return api.tasks.changeParent(uuid,value).then(() => { dispatch('loadTasks') })
    },
    
    taskOutOfParent({ dispatch }, { uuid }){
      return api.tasks.taskOutOfParent(uuid).then(() => {  dispatch('loadTasks') })
    },

    reorderTask({ dispatch }, { uuid, order, parent}){
      return api.tasks.reorderTask(uuid, order, parent).then(() => {  dispatch('loadTasks') })
    },

    getPluginTaskStatus({ commit }, workspace) {
      if (!workspace) return commit('setPluginStatus', [])
      else return api.tasks.getPluginTaskStatus(workspace).then(data => { commit('setPluginStatus', data) })
    },

    changePluginStatus({ dispatch }, { uuid, status}) {
      return api.tasks.changePluginStatus(uuid, status).then(() => { dispatch('loadTasks') })
    },
  }
}