<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0002 11.8753C18.0702 11.8753 19.7502 13.5553 19.7502 15.6253C19.7502 16.1123 19.6522 16.5703 19.4792 16.9973L21.6692 19.1873C22.8022 18.2423 23.6942 17.0203 24.2422 15.6253C22.9442 12.3323 19.7422 10.0003 15.9922 10.0003C14.9422 10.0003 13.9372 10.1873 13.0072 10.5253L14.6272 12.1453C15.0552 11.9723 15.5122 11.8753 16.0002 11.8753ZM8.50024 9.82727L10.2102 11.5383L10.5552 11.8833C9.31024 12.8503 8.33524 14.1403 7.75024 15.6253C9.04724 18.9173 12.2502 21.2503 16.0002 21.2503C17.1622 21.2503 18.2722 21.0253 19.2852 20.6203L19.6002 20.9353L21.7972 23.1243L22.7492 22.1723L9.45224 8.87527L8.50024 9.82727ZM12.6472 13.9753L13.8102 15.1373C13.7722 15.2953 13.7502 15.4603 13.7502 15.6253C13.7502 16.8703 14.7542 17.8753 16.0002 17.8753C16.1652 17.8753 16.3302 17.8523 16.4872 17.8153L17.6502 18.9773C17.1472 19.2253 16.5922 19.3753 16.0002 19.3753C13.9302 19.3753 12.2502 17.6953 12.2502 15.6253C12.2502 15.0323 12.4002 14.4773 12.6472 13.9753ZM15.8802 13.3903L18.2422 15.7523L18.2572 15.6323C18.2572 14.3873 17.2522 13.3823 16.0072 13.3823L15.8802 13.3903Z"
      fill="currentColor"
    />
    <path
      d="M3.501 5.99999V3.49999H6M3.501 7.99999V12M3.501 25.999V28.499H6M3.501 13.991V17.991M3.501 19.994V23.994M28.473 5.99999V3.49999H25.973M28.473 7.99999V12M24 3.49899H20M28.473 25.999V28.499H25.973M28.473 13.991V17.991M18.008 3.49899H14.008M28.473 19.994V23.994M12.005 3.49899H8.005M24 28.487H20M18.008 28.487H14.008M12.005 28.487H8.005"
      stroke="currentColor"
      stroke-width="0.567018"
      stroke-miterlimit="22.9256"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>