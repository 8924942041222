<template lang="pug">
  v-textarea.field.darkTextareaStyle(
    :value="value" 
    :label="label" 
    light
    :background-color="backgroundColor"
    color="var(--v-accent-base)"
    @input="handleInput"
    filled
    flat
    single-line
    :auto-grow="autoGrow"
    :height="height"
    :outlined="outlined"
    :rules="rules"
    :dense="dense"
    :solo="solo"
    :rows="rows"
    :hide-details="hideDetails"
    :no-resize="noResize"
    :rounded="rounded"
    :disabled="disabled"
    :readonly="readonly"
    @change="handleChange"
    @focus="handleFocus"
    @blur="handleBlur"
    :autofocus="autofocus"
  )
</template>

<script>
export default {
  name: 'AppTextarea',
  props: {
    backgroundColor: {
      type: String,
      default: "#fff"
    },
    value: [String, Number],
    rows: {
      type: [Number, String],
      default: 5
    },
    label: {
      type: String,
      default: 'Комментарий'
    },
    rounded: {
      type: Boolean,
      delfault: false
    },
    noResize: Boolean,
    height: [String, Number],
    autoGrow: Boolean,
    outlined: Boolean,
    dense: Boolean,
    hideDetails: Boolean,
    rules: [Object, Array],
    disabled: Boolean,
    readonly: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    autofocus: null
  },
  methods: {
    handleChange (event) {
      this.$emit('change', event)
    },
    
    handleInput (event) {
      this.$emit('input', event)
    },

    handleFocus () {
      this.focused = true
    },

    handleBlur () {
      this.focused = false
    }
  },
  data () {
    return {
      focused: false
    }
  }
}
</script>

<style scoped>
.field {
  border-radius: 3px;
}

.field >>> textarea {
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin; 
  min-height: 56px;
  max-height: 128px;
  margin: 0 !important;
}

.field >>> textarea::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

.field >>> textarea::-webkit-scrollbar-thumb  {
  background-color: var(--v-accent-base);
}
</style>

<style lang="scss">
.darkTextareaStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 0.9em;
  color: #4D4D4D;
}
</style>
