import { defineStore } from 'pinia'

export const useDrawings3DStore = defineStore('drawings-3d-store', {
  state: () => {
    return {
      editedDrawing3DTitle: 'untitled',
      selectedDrawing3DUuid: null,
      drawing3DList: [],
    }
  },
})