import { MeasurementsPlugin } from "@/plugins/viewerTools/tools/distanceMeasurement/measurementsPlugin";

/*eslint-disable no-dupe-class-members*/
export class DistanceMeasurement {

  /**
   * @type { MeasurementsPlugin }
   */
  static #_distanceMeasurementsPlugin = null

  static get measurements() {
    return this.#_distanceMeasurementsPlugin?._measurements || {}
  }

  static createDistanceMeasurement(cfg) {
    return this.#_distanceMeasurementsPlugin.createMeasurement(cfg)
  }

  static setMeasurementSettings(settings) {
    this.#_distanceMeasurementsPlugin.setMeasurementSettings(settings)
  }

  static removeDistanceMeasurements(distanceMeasurements = []) {
    this.#_distanceMeasurementsPlugin.removeDistanceMeasurements(distanceMeasurements)
  }

  /**
   * @deprecated
   */
  static clearAllMeasurement() {
    this.#_distanceMeasurementsPlugin.clear()
  }

  static setDistanceMeasurementsVisible(distanceMeasurements = [], visible) {
    this.#_distanceMeasurementsPlugin.setMeasurementsVisible(distanceMeasurements, visible)
  }

  static init() {
    this.#_distanceMeasurementsPlugin = new MeasurementsPlugin()
  }
}