import { XKTLoaderPlugin } from "@xeokit/xeokit-sdk";

/*eslint-disable-next-line no-unused-vars*/
import { SceneModel } from "@xeokit/xeokit-sdk/dist/xeokit-sdk.es";
import { ParserV3 } from "./ParserV3";
import { ParserV10 } from "./ParserV10";

const parsers = {}

parsers[ParserV3.version] = ParserV3
parsers[ParserV10.version] = ParserV10

export class Loader extends XKTLoaderPlugin {

  constructor(viewer, cfg = {}) {
    super(viewer, cfg)
  }

  /**
   * @param {SceneModel} sceneModel 
   */
  async _loadModel(src, params, options, sceneModel) {
    sceneModel.once('destroyed', () => {
        abortController.abort()
    })

    const abortController = new AbortController()
    const abortSignal = abortController.signal

    const response = await fetch(src, { signal: abortSignal }).catch(() => {
      console.log("Принудительная отмена загрузки модели с сервера")
    })
  
  
    if (!response) return
    const reader = response.body.getReader()

    let receivedBytesLength = 0
    const byteChunks = []
    let lastMS = performance.now()

    try {
      /*eslint-disable-next-line no-constant-condition*/
      while (true) {
        const { done, value: bytes } = await reader.read()

        if (done) break

        byteChunks.push(bytes)
        receivedBytesLength += bytes.length

        if (performance.now() - lastMS > 100) {
          this.fire('receivedBytesLength', {
            modelId: sceneModel.id,
            bytes: receivedBytesLength
          })

          lastMS = performance.now()
        }
      }

      const bytes = new Uint8Array(receivedBytesLength)
      let bufferPosition = 0

      for (const byteChunk of byteChunks) {
        bytes.set(byteChunk, bufferPosition)
        bufferPosition += byteChunk.length
      }

      await this._parseModel(bytes.buffer, params, options, sceneModel).catch(() => {
        console.log("Принудительное прерывание процесса загрузки модели")
        return
      })
    }
    catch (err) {
      console.log("Принудительное прерывание загрузки модели")
      return
    }

    // FIXME Xeokit 2.3.9 Баг при финализации моделей (Временное решение)
    try {
      sceneModel.finalize();
    }
    catch (error) {
      console.error(`[${sceneModel.id}] Finalize Error`, error)
      sceneModel.destroy()
    }

    // this._createDefaultMetaModelIfNeeded(sceneModel, params, options);

    sceneModel.scene.once("tick", () => {
      if (sceneModel.destroyed) {
        return;
      }
      sceneModel.scene.fire("modelLoaded", sceneModel.id); // FIXME: Assumes listeners know order of these two events
      sceneModel.fire("loaded", true, false); // Don't forget the event, for late subscribers
    });
  }

  async _parseModel(arrayBuffer, params, options, sceneModel) {
    if (sceneModel.destroyed) {
      return
    }

    // Исключение элементов по ID
    if (params.excludeIds) {
      options.excludeIdsMap = {}

      params.excludeIds.forEach(objectId => {
        options.excludeIdsMap[objectId] = true
      })
    }

    if (params.transformId) {
      options.transformId = params.transformId
    }

    const dataView = new DataView(arrayBuffer)
    const dataArray = new Uint8Array(arrayBuffer)
    const xktVersion = dataView.getUint32(0, true)
    const parser = parsers[xktVersion]

    if (!parser) {
      this.error("Unsupported .XKT file version: " + xktVersion + " - this XKTLoaderPlugin supports versions " + Object.keys(parsers))
      return
    }
    this.log("Loading .xkt V" + xktVersion)

    const numElements = dataView.getUint32(4, true)
    const elements = []
    let byteOffset = (numElements + 2) * 4


    for (let i = 0; i < numElements; i++) {
      const elementSize = dataView.getUint32((i + 2) * 4, true)
      elements.push(dataArray.subarray(byteOffset, byteOffset + elementSize))
      byteOffset += elementSize
    }

    await parser.parse(this.viewer, options, elements, sceneModel)
  }
}