<template>
  <svg
    width="37"
    height="35"
    viewBox="0 0 37 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 16L19 21"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M26 20L19 25"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M23 14L19 17"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M19 9L19 34"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13.3334 26C13.8857 26 14.3334 25.5523 14.3334 25C14.3334 24.4477 13.8857 24 13.3334 24V26ZM10.5001 9V10C11.0524 10 11.5001 9.55228 11.5001 9H10.5001ZM28.5 11H27.5C27.5 11.5523 27.9477 12 28.5 12V11ZM26.5 24C25.9477 24 25.5 24.4477 25.5 25C25.5 25.5523 25.9477 26 26.5 26V24ZM11.5001 9C11.5001 8.25942 11.9847 6.46912 13.227 4.86148C14.4324 3.30161 16.2825 2 18.9857 2L18.9857 0C15.5365 0 13.1438 1.6984 11.6445 3.63855C10.1821 5.53091 9.50012 7.74061 9.50012 9H11.5001ZM28.5 12C32.679 12 34.9904 15.0295 34.9377 17.9821C34.9113 19.4568 34.2966 20.9322 32.9839 22.0516C31.6672 23.1745 29.5728 24 26.5 24V26C29.9272 26 32.5203 25.0755 34.2817 23.5734C36.0472 22.0678 36.9012 20.0432 36.9373 18.0179C37.0096 13.9705 33.821 10 28.5 10V12ZM18.9857 2C21.7999 2 23.8978 3.01992 25.3023 4.6015C26.7205 6.19864 27.5 8.44771 27.5 11H29.5C29.5 8.05229 28.5985 5.30136 26.7977 3.2735C24.9831 1.23008 22.3238 0 18.9857 0L18.9857 2ZM13.3334 24C6.29857 24 3.14771 20.2704 2.85037 16.9118C2.69801 15.1909 3.26602 13.4803 4.51203 12.1964C5.7525 10.9182 7.72926 10 10.5001 10V8C7.27103 8 4.74779 9.08175 3.07679 10.8036C1.41133 12.5197 0.656392 14.8091 0.858162 17.0882C1.26908 21.7296 5.53481 26 13.3334 26V24Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>