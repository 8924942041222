import { api } from '@/api'

export default {
  namespaced: true,

  state: {},
  mutations: {},
  getters: {},

  actions: {
    getIFCAnnotations(obj, projectUuid) {
      return api.IFCAnnotations.getIFCAnnotationsByProjectUuid(projectUuid).then(data => {
        return data
      })
    },

    // downloadIFCGridsByRevisionUuid(obj, revisionUuid) {
    //   return api.IFCGrids.getIFCGridsByModelRevisionUuid(revisionUuid).then(data => {
    //     return data
    //   })
    // }
    // getIFCTree(obj, projectUuid) {
    //   return api.IFCGrids.getIFCTreeByProjectUuid(projectUuid).then(data => {
    //     return data
    //   })
    // },

    // saveIFCGridsVisibility(obj, revisionUuid) {
    //   return api.IFCGrids.changeIFCGridsVisibility(revisionUuid).then(data => {
    //     return data
    //   })
    // },

    // getLabelSize(obj, projectUuid) {
    //   return api.IFCGrids.getLabelSize(projectUuid).then(data => {
    //     return data
    //   })
    // }
  }
}