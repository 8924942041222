import { authUserService } from "../_services"
import { api } from '@/api'

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

export default {
  namespaced: true,

  state: {
    users:  [],
    selectedRole: null,
    actionProf: false,

    tariffList: {
      list: [],
      status: status.INITIAL
    },

    grantTypes: {
      list: [],
      status: status.INITIAL
    },

    selectedArchive: {title: 'headerToolbar.active', value: false},
    selectedSort: {title: 'headerToolbar.createDate', value: 'createDate'},
  },

  getters: {
    users: state => state.users.sort((a, b) => a.name.localeCompare(b.name)),
    
    grantTypes: state => {
      return state.grantTypes.list
    },

    tariffList: state => {
      return state.tariffList.list
    },
    
    isGrantTypesLoaded: ({ grantTypes }) => grantTypes.status == status.LOADED,
    isTariffListLoaded: ({ tariffList }) => tariffList.status == status.LOADED,
  },

  mutations: {
    setUsers: (state, users) => {
      state.users = users
    },

    profileInfo (state, actionProf) {
      state.actionProf = actionProf
    },

    setUserInUsers: (state, user) => {
      if (state.users.length > 0) {
        let curr = state.users.filter(item => item.uuid === user.uuid)[0]
        if (curr !== undefined) {
          Object.assign(curr, user)
        }
      }
    },

    selectRole: (state, role) => {
      state.selectedRole = role
    },
    
    setTariffList: (state, tariffs) => {
      state.tariffList.list = tariffs
    },

    setGrantTypes: (state, types) => {
      state.grantTypes.list = types
    },
    
    setTariffListStatus: ({ tariffList }, status) => {
      tariffList.status = status
    },

    setGrantTypesStatus: ({ grantTypes }, status) => {
      grantTypes.status = status
    },

    selectArchive: (state, archive) => {
      state.selectedArchive = archive
    },

    selectSort: (state, sort) => {
      state.selectedSort = sort
    }

  },
  
  actions: {
    INIT: ({ dispatch }) => {
      dispatch('LOAD_USERS')
      dispatch('LOAD_GRANT_TYPES')
      dispatch('LOAD_TARIFF_LIST')
    },

    LOAD_USERS: ({ commit }) => {
      return api.users.usersAll().then(data => {
        commit('setUsers', data)
        return true
      })  
    },

    LOAD_GRANT_TYPES: ({ state, commit }) => {
      if (state.grantTypes.status == status.INITIAL ) {
        commit('setGrantTypesStatus', status.LOADING)

        authUserService.listGrantTypes().then(data => {
          commit('setGrantTypes', data)
          commit('setGrantTypesStatus', status.LOADED)
        })  
      }
    },

    LOAD_TARIFF_LIST: ({ state, commit }) => {
      if (state.tariffList.status == status.INITIAL ) {
        commit('setTariffListStatus', status.LOADING)

        authUserService.listTariffList().then(data => {
          commit('setTariffList', data)
          commit('setTariffListStatus', status.LOADED)
        })  
      }
    },


    createUser: ({ dispatch }, user) => {
      return api.users.create(user).then(data => {
        dispatch('LOAD_USERS')
        return data
      } )
    },

    editUser: ({ dispatch }, user) => {
      return api.users.edit(user).then(() => {dispatch('LOAD_USERS')})
      // return api.users.edit(user).then(() => {dispatch('LOAD_USERS')})
    },

    deleteUser: ({ dispatch }, id) => {
     return api.users.delete(id).then(() => dispatch('LOAD_USERS'))
    },

    activateUser: ({ dispatch }, id) => {
      return api.users.activate(id).then(() => dispatch('LOAD_USERS'))
    },

    createTestUsers: ({ dispatch }) => {
      api.users.createTestUsers().then( () => {
        dispatch('LOAD_USERS')
      })
    },
    setTariff: ({ commit }, { profileUuid, tariffAlias }) => {
      return authUserService.changeTariff( profileUuid, tariffAlias).then(data => {
        commit("setUserInUsers", data)
        return data
      })
    },

    setAvatar: ({ commit }, { id, file }) => {
      return api.users.setAvatar(id, file).then(data => {
        commit("setUserInUsers", data)
        return data
      })
    },

    getStatisticXlsx: (obj, options) => {
      return api.users.getStatisticXlsx(options).then(data=>{
        let a = document.createElement("a")
        a.href = URL.createObjectURL(new Blob([data]))
        a.download = 'user-statistc.xlsx'
        a.click()
      })
    }
  }
}