import { defineStore } from 'pinia'
// import store from '@/store'
// import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
// import * as utils from './lightsUtils'

export const useLightsStore = defineStore('lights', {
  
  state: () => {
    return {
      lightSettings: []
    }
  },

  actions: {
    setLightSettings(lightSettings) { 
      this.lightSettings = lightSettings 
    },
  }
})