const getDefaultState = () => {
  return {
    addRulesTree: [],
    openedRuleTree: [],
    checkLogicMode: null,
    showPanelHightLightGroups: false,
    showWithValidRules: false,
    selectedWormNode: null
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    
  },

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setAddRulesTree: (state, uuid) => {
      state.addRulesTree = uuid
    },

    setOpenedRuleTree: (state, uuid) => {
      state.openedRuleTree = uuid
    },

    setCheckLogicMode: (state, mode) => {
      state.checkLogicMode = mode
    },

    setShowPanelHightLightGroups: (state, boolean) => {
      state.showPanelHightLightGroups = boolean
    },

    setShowWithValidRules: (state, boolean) => {
      state.showWithValidRules = boolean
    },

    setSelectedWormNode: (state, value) => { 
      state.selectedWormNode = value
    }
  },

  actions: {
    
  }
}
