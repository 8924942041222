import { telemetryService } from "@/_services/telemetry.service"

export default {
  namespaced: true,

  state: {
    list: [],
    selectedMnemoschema: null,
  },

  actions: {
    saveMnemoschema({ dispatch }, mnemoschema) {
      return telemetryService.saveMnemoschema(mnemoschema).then(() => {
        dispatch('updateList')
      })
    },

    updateList({ commit, rootGetters }) {
      const projectUuid = rootGetters['project/projectUuid']
      return telemetryService.getMnemoschemas(projectUuid).then(response => {
        response.data.sort((a, b) => a.title.localeCompare(b.title))
        commit('setList', response.data)
      })
    },

    deleteMnemoschema({ dispatch }, mnemoschemaUuid) {
      telemetryService.deleteMnemoschema(mnemoschemaUuid).then(() => {
        dispatch('updateList')
      })
    },

    editMnemoschema(state, mnemoschema) {
      return telemetryService.editMnemoschema(mnemoschema)
    },

    setSelectedMnemoschema({ commit }, mnemoschema) {
      if (!mnemoschema) return commit('SET_SELECTED_MNEMOSCHEMA', null), null
      
      return telemetryService.getMnemoschema(mnemoschema.uuid).then(freshMnemoschema => {
        commit('refreshMnemoschemaInList', freshMnemoschema.data)
        commit('SET_SELECTED_MNEMOSCHEMA', freshMnemoschema.data)

        return freshMnemoschema.data
      })
    },
  },

  mutations: {
    setList(state, list) {
      state.list = list
    },

    SET_SELECTED_MNEMOSCHEMA(state, mnemo) {
      state.selectedMnemoschema = mnemo
    },

    clear(state) {
      state.list = []
      state.selectedMnemoschema = null
    },

    refreshMnemoschemaInList(state, mnemoschema) {
      const oldMnemoIdx = state.list.findIndex(lmnemo => lmnemo.uuid === mnemoschema.uuid)
      if (oldMnemoIdx < 0) return
      
      state.list.splice(oldMnemoIdx, 1, mnemoschema)
    }
  },
}