const getDefaultState = () => ({
  channelsCache: new Map(),
})

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    getChannel: ({ channelsCache }) => (channelUuid) => channelsCache.get(channelUuid),
  },

  actions: {
    updateChannel({ commit, dispatch, rootGetters }, channelData) {
      commit('UPDATE_CHANNEL', channelData)

      const highlightedChannels = () => rootGetters['channel/table/highlightedChannels']

      dispatch('channel/table/setHighlightedChannels', highlightedChannels().concat([channelData.uuid]), { root: true })
      setTimeout(() => dispatch('channel/table/setHighlightedChannels', highlightedChannels().filter(highlightedChannelUuid => highlightedChannelUuid !== channelData.uuid), { root: true }), 600)
    },

    setChannel({ commit }, channel) {
      commit('SET_CHANNEL', channel)
    },

    resetState({ commit }) {
      commit('RESET_STATE')
    },
  },

  mutations: {
    SET_CHANNEL({ channelsCache }, channel) {
      channelsCache.set(channel.uuid, channel)
    },

    UPDATE_CHANNEL({ channelsCache }, { uuid, recvTime, value }) {
      const cachedChannel = channelsCache.get(uuid)

      if (cachedChannel) {
        cachedChannel.value = value
        cachedChannel.recvTime = recvTime
      }
      else {
        channelsCache.set(uuid, { uuid, value, recvTime })
      }
    },

    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
}