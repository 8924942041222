export { 
  AxisType,
  AxisDataType,
  Axis,
  FixedAxis,
  CustomAxis
}

// * Constants

let AxisType = Object.freeze({ FIXED: 'fixed', CUSTOM: 'custom', UNDEFINED: 'undefined' })
let AxisDataType = Object.freeze({ ELEMENTS: 'elements', COLLISIONS: 'collisions', COSTING: 'costing' })

// * Fabrics

let FixedAxis = (value, title) => new Axis(AxisType.FIXED, value, title)
let CustomAxis = (obj) => new Axis(AxisType.CUSTOM, obj.uuid, obj.title, obj)

// * Model

function Axis (type = AxisType.UNDEFINED, value = -1, title = '', data = null) {

  this.type = type
  this.value = value
  this.title = title
  this.data = data

  this.isEqual = (otherAxis) => {
    if (otherAxis && otherAxis instanceof Axis) {
      return this.type === otherAxis.type && this.value === otherAxis.value
    }
    return false
  }
}
