<template lang="pug">
  v-speed-dial( v-model="fab" fixed bottom right )
    template( v-slot:activator )
      v-btn.sud-fab__button(:class="{'mr-266': notificationFlagWithDashboadr  || actionProf }" :color="fab ? 'primary lighten-3' : 'accent'" fab dark fixed bottom right large test-id="new-project-btn")
        v-icon( v-if="fab" ) {{ $vuetify.icons.values.close }}
        v-icon( v-else ) {{ $vuetify.icons.values.add }}
    
    .sud-fab__card
      .sud-fab__card-header {{ title || $t('button.create') }}
      slot( name="body" )
        .sud-fab__card-body.sud-list
          app-list( :items="items" @select="handleItem")

</template>

<script>
import { mapState } from 'vuex'
import AppList from '@/components/project/common/List.vue'
export default {
  name: 'app-fab',
  components: {
    AppList
  },

  props: {
    items: Array,
    title: {
      type: String,
      // default: 'Создать'
    },
  },

  data () {
    return {
      fab: false
    }
  },

  computed: {
    ...mapState('notification', ['notificationFlagWithDashboadr']),
    ...mapState('users', ['actionProf']),
  },

  methods: {
    handleItem (item, index) {
      this.$emit('select', item, index)
    }
  }
}
</script>

<style>
 .sud-fab__button {
   outline: none !important;
 }

 .sud-fab__card {
  bottom: 18px;
  right: 232px;
  width: 400px;
  border-radius: 5px;
  position: relative;

  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.sud-fab__card-header {
  margin: 0px;
  padding: 24px 16px 32px;
  border-radius: 5px 5px 0px 0px;

  font-family: 'Roboto Slab', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  background: #959595;
  color: #FFFFFF;
}

.sud-fab__card-body {
  border-radius: 0px 0px 5px 5px;
}
.mr-266 {
  right: 266px !important;
}
</style>
