// import { $_user_role as Role } from '@/common/UserUtils'

export let DrawerItems = [
// Проект
  {
    name:'drawer.project',
    path: '/dashboard',
    icon: 'twotone-work-24px.png',
    iconName: '$dashboard-icon'
  },
// Админ панель
  {
    name:'section.structure.model.memberRole',
    path: '/admin/roles',
    icon: 'twotone_line_style_white_24dp.png',
    iconName: '$admin-roles-icon'
  },
  {
    name:'drawer.projects',
    path: '/admin/projects',
    icon: 'baseline_list_white_24dp.png',
    iconName: '$admin-projects-icon'
  },
  {
    name:'drawer.processes',
    path: '/admin/process',
    icon: 'twotone_history_white_24dp.png',
    iconName: '$admin-processes-icon'
  },
  {
    name:'drawer.users',
    path: '/admin/users',
    icon: 'twotone_people_white_24dp.png',
    iconName: '$admin-users-icon'
  },
  {
    name:'drawer.organizations',
    path: '/admin/orgs',
    icon: 'account-group-outline.svg',
    iconName: '$groups-icon'
  },
  {
    name:'drawer.devices',
    path: '/admin/deviceReestr',
    icon: 'outline_devices_other_white_24dp.png',
    iconName: '$admin-devices-icon'
  },
  {
    name:'drawer.units',
    path: '/admin/measureunits',
    icon: 'outline_square_foot_white_24dp.png',
    iconName: '$admin-units-icon'
  },

  {
    name:'drawer.language',
    path: '/admin/languages',
    icon: 'baseline_translate_white_24dp.png',
    iconName: '$admin-language-icon'
  },
  {
    name: 'drawer.plugins',
    path: '/admin/plugins',
    icon: 'new_project_plugins.svg',
    iconName: '$admin-plugins-icon'
  },
  {
    name:'drawer.smetadictionary',
    path: '/admin/smetadictionary',
    icon: 'outline_square_foot_white_24dp.png',
    iconName: '$admin-units-icon'
  },
]