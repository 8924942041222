import { SourcePath } from "@/assets/app/SourcePath"
import { XeokitMediator } from "./XeokitMediator"

class PointCloudLoader {

  static async load(cfg) {
    // const pointCloudArrBuffer = await fetch(SourcePath.loadPointCloud(cfg.id)).then(response => response.arrayBuffer())
    let conf = {
      id: cfg.id,
      src: SourcePath.loadPointCloud(cfg.id),
      // las: pointCloudArrBuffer,
      position: cfg.position,
      rotation: cfg.rotation
    }
    return new Promise((resolve) => { 
      let model =  XeokitMediator.lasLoader.load(conf)

      let success = () => {
        
        resolve(model)
        model.loaderType = "las" // нужно для проверки типа модели
        XeokitMediator.lasLoader.fire('countVisibleCloudModel')
        unbind()
      }

      const unbind = () => {
        model.off(pointCloudSubId)
      }
      let pointCloudSubId = model.on('loaded', success)
    })
  }

}

export { PointCloudLoader }