<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 19C21 20.11 20.11 21 19 21H17V19H19V17H21V19ZM15 19V21H12V19H15ZM10 19V21H8C6.89 21 6 20.11 6 19V17H8V19H10ZM21 12V15H19V12H21ZM21 8V10H19V8H17V6H19C20.11 6 21 6.89 21 8Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 15H8L2 15C0.89 15 0 14.11 0 13V2C0 0.89 0.89 0 2 0H13C14.11 0 15 0.89 15 2L15 8C15 8 15 12.5 15 13C15 14 13.8223 15 13 15ZM2 13H6L13 13L13 6V2H2V13Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>