import { VBOGeometry, buildSphereGeometry, PhongMaterial, Mesh } from "@xeokit/xeokit-sdk"
import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"

const BLACK = [0, 0, 0]
const WHITE = [1, 1, 1]

/*eslint-disable no-dupe-class-members*/
export class LightSource {
  constructor(cfg) {
    this._uuid = cfg.uuid
    this._scene = cfg.scene
    this._rootNode = null
    this._position = cfg.position
    this._color = cfg.color ?? null

    this.#createLightSource()
  }

  get uuid() { return this._uuid }
  get node() { return this._rootNode }
  get children() { return this._rootNode.children }

  #createLightSource() {
    this._rootNode = new XeokitNode(this._scene, {
      id: this._uuid, 
      position: this._position, 
      children: this.#createSphereMeshes(this._color),
      pickable: false,
      collidable: false,
      clippable: false,
    })
  }

  #createSphereMeshes(color = null) {
    const sphereMeshes = [
      this.#createSphereMesh(.3, .8, WHITE),
      this.#createSphereMesh(.6, .4, color),
      this.#createSphereMesh(1.2, .2, color),
      this.#createSphereMesh(2.4, .1, color),
    ]

    return sphereMeshes
  }

  #createSphereMesh(radius, opacity, color = null) {
    const sphereGeometry = new VBOGeometry(this._scene, buildSphereGeometry({ 
      radius: radius
    }))
    const sphereMaterial = new PhongMaterial(this._scene, {
      emissive: color ?? WHITE, 
      diffuse: BLACK
    })
    const sphereMesh = new Mesh(this._scene, {
      geometry: sphereGeometry,
      opacity: opacity,
      material: sphereMaterial,
      pickable: false,
      collidable: false,
      clippable: false,
    })

    return sphereMesh
  }

  /** Sets color for this LightSource */
  setColor(rgb = [1, 1, 1]) {
    this.node.colorize = rgb
  }

  /** Destroys this LightSource */
  destroy() {
    if (this._rootNode) {
      this._rootNode.destroy()
      this._rootNode = null
    }
  }
}