<template lang="pug">
v-btn.text-none( 
        v-bind="$attrs"
        :color="color" :small="small"
        :disabled="disabled" depressed
        :text="simple" @click="$emit('click')"
        :class="{ 'app-cancel-button' : cancel, 'app-action-button' : action }" )
  slot
</template>

<script>
export default {
  name: 'app-button',

  props: {
    color: String,
    cancel: Boolean,
    action: Boolean,
    disabled: Boolean,
    simple: Boolean,
    small: Boolean,
  }
}
</script>

<style scoped>
.app-cancel-button {
  background-color: var(--v-secondary-lighten5) !important;
  color: #303030 !important;

}

.app-action-button {
  background-color: var(--v-accent-base) !important;
  color: #FFFFFF !important;
}

.theme--dark.v-btn.v-btn--disabled,
.theme--light.v-btn.v-btn--disabled {
  background-color: rgba(59, 147, 175, 0.6) !important;
  color: #FFFFFF !important;
}
</style>


