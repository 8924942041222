import _ from 'lodash'

export default ({ Factory, Presets }) => ({

  /**
   * (Enum) список приоритетов задачи
   */
  priorityTypes: () => Factory.tasks.prioritytypes.get(),
  
  /**
   * (Enum) список статусов задачи
   */
  completeStatusTypes: () => Factory.tasks.completestatustypes.get(),

  /**
   *  Список задач авторизованного рользователя
   */
  tasksAll: () => Factory.tasks.get(),

  /**
   * Добавление новой задачи
   * @param {Object} task Задача
   */
  create: (task) => Factory.tasks.post( task ),

  /**
   * Изменение заголовка задачи
   * @param {String} id UUID задачи
   * @param {String} title Заголовок задачи
   */
  setTitle: (id, title) => Factory.tasks[id].patch( {title: title}, Presets.headers.MERGE_PATCH ),

  /**
   * Изменение описания задачи
   * @param {String} id UUID задачи
   * @param {String} desc Опиание задачи
   */
  setDescription: (id, desc) => Factory.tasks[id].patch( {description: desc}, Presets.headers.MERGE_PATCH ),

  /**
   * Изменение описания задачи
   * @param {String} id UUID задачи
   * @param {Array<String>} modelsUUID Список UUID связанных моделей
   */
  setModels: (id, modelsUUID) => Factory.tasks[id].patch( {models: modelsUUID}, Presets.headers.MERGE_PATCH ),

   
  /**
   * Изменение прогресса задачи
   * @param {String} taskUuid UUID задачи
   * @param {String} progress Прогресс
   */
  setProgress: (taskUuid, progress) => Factory.task[taskUuid].progress[progress].patch(),
   
  /**
   * Изменение даты начала задачи
   * @param {String} taskUuid UUID задачи
   * @param {String} date Дата начала задачи
   */
  setStartDate: (taskUuid, date) => Factory.task[taskUuid].startdate[date].patch(),
   
  /**
   * Изменение даты конца задачи
   * @param {String} taskUuid UUID задачи
   * @param {String} date Дата конца задачи
   */
  setEndDate: (taskUuid, date) => Factory.task[taskUuid].stopdate[date].patch(),
  
  /**
   * Изменение интервала задачи
   * @param {String} taskUuid UUID задачи
   * @param {String} start Дата начала задачи
   * @param {String} end Дата конца задачи
   */
  setDateInterval: (taskUuid, start, end) => Factory.task[taskUuid].taskduration[start][end].patch(),

  /**
   * Изменение интервала массива задач
   * @param {Array} tasks Массив задач
   */
   setDateIntervalOfTasks: (tasks) => Factory.tasks.duration.update.post(tasks),

  /**
   * Управление участниками в задаче
   * @param {String} id UUID задачи
   * @param {Array<String>} listIds UUID's список участников
   */
  setWorkers: (id, listIds) => {
    let uuids = listIds.map(item => { return {uuid: item} })
    uuids = _.uniqBy(uuids, 'uuid')
    return Factory.tasks[id]['workers'].patch( {workers: uuids}, Presets.headers.MERGE_PATCH )
  },

   /**
   * Управление группы участников в задаче
   * @param {String} id UUID задачи
   * @param {Array<String>} listIds UUID's группы участников
   */
   setGroupWorkers: (id, listIds) => {
    let uuids = listIds.map(item => { return {uuid: item} })
    uuids = _.uniqBy(uuids, 'uuid')
    return Factory.tasks[id]['groupWorkers'].patch( {groupWorkers: uuids}, Presets.headers.MERGE_PATCH )
  },

  /**
   * Назначение ответственного в задаче
   * @param {String} id UUID задачи
   * @param {String} responderId UUID профиля
   */
  setResponder: (id, responderId) => Factory.tasks[id]['responder'].patch( {responder: {uuid: responderId}}, Presets.headers.MERGE_PATCH ),

  /**
   * Назначение группы - ответственной в задаче
   * @param {String} id UUID задачи
   * @param {String} wGroup UUID группы
   */
  setGroupResponder: (id, wGroup) => Factory.tasks[id]['groupResponder'].patch( {groupResponder: {uuid: wGroup}}, Presets.headers.MERGE_PATCH ),

  /**
   * Изменение статуса задачи
   * @param {String} id UUID задачи
   * @param {String} name (En) Наименование статуса
   */
  setStatus: (id, name) => Factory.tasks[id]['status'].patch( {completeStatus: {value: name}}, Presets.headers.MERGE_PATCH ),

  /**
   * Изменение приоритета задачи
   * @param {String} id UUID задачи
   * @param {String} name (En) Наименование приоритета
   */
  setPriority: (id, name) => Factory.tasks[id]['priority'].patch( {priority: {value: name}}, Presets.headers.MERGE_PATCH ),

  /**
   * Удалить задачу
   * @param {String} id UUID задачи
   */
  archiving: (id) => Factory.tasks[id].patch(),

  /**
   * Добавление комментария к задаче
   * @param {String} id UUID задачи
   * @param {Text} text Комментарий
   * @param {UUID} quotedCommentUuid UUID цитируемого сообщения
   */
  createComment: (id, text, quotedCommentUuid = null) => {
    let query = quotedCommentUuid ? `?quotedCommentUuid=${quotedCommentUuid}` : ''
    let textform = new FormData()
    textform.append("text", text)
    return Factory.tasks[id].comment[query].post( textform )
  },

  /**
   * Редавтировать комментприй
   * @param {String} id UUID комментария
   * @param {Text} text Комментарий
   */
  editComment: (id, text) => {
    let textform = new FormData()
    textform.append("text", text)
    return Factory.comments[id].patch( textform )
  },
  /**
   * Удалить комментарий
   * @param {String} id UUID комментария
   */
  deleteComment: (taskUid, id) => Factory.tasks[taskUid].comments[id].delete(),

  /**
   * Добавление вложения к задаче
   * @param {String} id UUID задачи
   * @param {String} name Наименование файла
   * @param {File} file Файл
   */
  createAttachment: (id, name, file, sourceData, collision) => {
    let formData = new FormData()
      formData.append("name", name)
      formData.append("file", file)
      if (collision!== undefined && collision!== null) formData.append("collision", collision)
      if (sourceData!== undefined)
        formData.append("sourceData", sourceData)
      else
        formData.append("sourceData", '')
    return Factory.tasks[id].file.post( formData, Presets.headers.MULTIPART )
  },

  /**
   * Изменение комментария вложения
   * @param {UUID} attachmentUUID UUID вложения
   * @param {String} comment комментарий 
   */
   editSourceDataAttachment: (attachmentUUID, sourceData) => {
    return Factory.attachment[attachmentUUID].patch( sourceData )
  },

  /**
   * Удаление вложенние из задачи
   * @param {String} id UUID вложенного файла
   */
  deleteAttachment: (id) => Factory.attachment[id].delete(),

  /**
   * Добавление collision | rule | attribute в задачу
   * @param {String} id UUID задачи
   * @param {Array<String>} listIds UUID's добавлений
   * @param {String} type тип добавления - collision(default) | rule | attribute
   * @returns 
   */
  patchTask: (id, listIds, type = 'collision') => {
    let uuids = [...new Set(listIds)].map((item) => ({ uuid: item }))
    let obj
    switch (type) {
      case 'collision':
        obj = { collision: uuids }
        break
      case 'rule':
        obj = { rule: uuids }
        break
      case 'attribute':
        obj = { attribute: uuids }
        break
    }
    return Factory.tasks[id].collision.patch(obj, Presets.headers.MERGE_PATCH)
  },

  /**
   * Добавление collision  в задачу
   * @param {String} id UUID задачи
   * @param {Array<String>} listIds UUID's добавлений
   * @param {String} type тип добавления - collision(default)
   * @returns 
   */
  attachCollisionsToTask: (id, listIds, sourceData, type = 'collision') => {
    let uuids = [...new Set(listIds)]
    let obj
    switch (type) {
      case 'collision':
        obj = { collisions: uuids, sourceData: sourceData }
        break
    }
    return Factory.tasks[id].attach.collisions.post(obj)
  },

  /**
   * Создать задачу с коллизиями
   * @param {String} title Заголовок задачи
   * @param {Array<String>} listIds UUID's коллизий
   * @param {String} type тип добавления - collisions(default)
   */
    createTaskWithAttachCollisions: (title, listIds, sourceData, type = 'collisions') => {
      let uuids = [...new Set(listIds)]
      let obj
      switch (type) {
        case 'collisions':
          obj = {title: title, collisions: uuids, sourceData: sourceData}
          break
      }
      return Factory.tasks.attach.collisions.post( obj )
    },

  /**
   * Создать задачу с коллизиями/правилами/атрибутами
   * @param {String} title Заголовок задачи
   * @param {Array<String>} listIds UUID's коллизий
   * @param {String} type тип добавления - collisions(default) | rules | attributes
   */
  createTaskWithAttachments: (title, listIds, type = 'collisions') => {
    let uuids = [...new Set(listIds)]
    let obj
    switch (type) {
      case 'collisions':
        obj = {title: title, collisions: uuids}
        break
      case 'rules':
        obj = {title: title, rules: uuids}
        break
      case 'attributes':
        obj = {title: title, attributes: uuids}
        break
    }
    return Factory.tasks.collisions.post( obj ) 
  },


  /**
   * Поиск задач по заданному фильтру
   * @param {Object} filter фильтр поиска
   */
  search: (filter) => {
    return Factory.tasks.search.post( filter ) 
  },

  /**
   * Поиск задач по заданному фильтру
   * @param {Object} filter фильтр поиска
   */
  bindToSmeta: (bindData) => {
    return Factory.smetatask.addtask.post( bindData )   
  },

  // smetaList: (taskUuid) => {
  //   // console.log(taskUuid)
  //   return Factory.smetatask.listbytask[taskUuid].get()
  // },

  /**
   * Получение сметы на задачу
   * @param {UUID} taskUuid id задачи
   */
  getSmetaData: (taskUuid) => {
    
    return Factory.smetatask[taskUuid].gettasksmetanode.get()
  },

  // getSmetaVolumes: (classificatorNodeUuid) => {
  //   return Factory.smetatask[classificatorNodeUuid].volumes.get()
  // },

  /**
   * Получение объема
   * @param {UUID} taskUuid id задачи
   */
   getSmetaVolumes: (taskUuid) => {
    return Factory.smetatask[taskUuid].volumes.get()
  },

  getGlobalIds: (projectUuid,taskUuid) => {
    let query = `?projectUuid=${projectUuid}&taskUuid=${taskUuid}`
    return Factory.smetatask.getglobalids[query].get()
  },

  getTaskElementsHistory: (projectUuid,taskUuid) => {
    let query = `?projectUuid=${projectUuid}&taskUuid=${taskUuid}`
    return Factory.smetatask.getelementshistory[query].get()
  },

  getGlobalIdsByUuidsList: (projectUuid, uuids) => {
    let form = new FormData()
    form.append('projectUuid',projectUuid)
    form.append('taskUuids[]',uuids)
    return Factory.smetatask.globalids.post(form)
  },

  getTasksBySmetaNode: (nodeUuid) => {
    return Factory.smetatask.listbynode[nodeUuid].get()
  },


  /**
   * Получение списка единиц измерения
   */
  getMeasureUnitType: () => {
    return Factory.tasks.measureunitytype.get()
  },

  /**
   * Создание общего отчета объемов
   * @param {UUID} taskUid UUID задачи
   * @param {Object} taskReport Общий объем выполнения {estimation: Double, unit: MeasureUnitType}
   */
   createTaskReport: (taskUid, taskReport) => {
    return Factory.tasks[taskUid].report.post(taskReport)
  },

  /**
   * Редактирование отчета объемов
   * @param {UUID} taskUid UUID задачи
   * @param {Object} taskReport Общий объем выполнения {uuid: UUID, estimation: Double, unit: MeasureUnitType}
   */
   editTaskReport: (taskUid, taskReport) => {
    return Factory.tasks[taskUid].report.put(taskReport)
  },

  /**
   * Создание записи о работе (объема)
   * @param {UUID} taskUid UUID задачи
   * @param {Object} taskReportVolume Общий объем выполнения {reportDate: Timestamp, value: Double}
   */
   createTaskReportVolume: (taskUid, taskReportVolume) => {
    return Factory.tasks[taskUid].volume.post(taskReportVolume)
  },

  /**
   * Редактирование записи о работе (объема)
   * @param {UUID} taskUid UUID задачи
   * @param {Object} taskReportVolume Общий объем выполнения {uuid: UUID, reportDate: Timestamp, value: Double}
   */
   editTaskReportVolume: (taskUid, taskReportVolume) => {
    return Factory.tasks[taskUid].volume.put(taskReportVolume)
  },

  /**
   * Изменение значения в записе о работе (объеме)
   * @param {UUID} taskUid UUID задачи
   * @param {UUID} taskReportVolumeId UUID записb о работе (объеме)
   * @param {Double} value объём
   */
   setTaskReportVolumeValue: (taskUid, taskReportVolumeId, value) => {
    return Factory.tasks[taskUid].volume[taskReportVolumeId].patch( {value}, Presets.headers.MERGE_PATCH )
  },

  /**
   * Изменение даты в записе о работе (объеме)
   * @param {UUID} taskUid UUID задачи
   * @param {UUID} taskReportVolumeId UUID записb о работе (объеме)
   * @param {Timestamp} reportDate Дата отчета
   */
   setTaskReportVolumeReportDate: (taskUid, taskReportVolumeId, reportDate) => {
    return Factory.tasks[taskUid].volume[taskReportVolumeId].patch( {reportDate}, Presets.headers.MERGE_PATCH )
  },

  /**
   * Редактирование записи о работе (объема)
   * @param {UUID} taskUid UUID задачи
   * @param {Object} taskReportVolumeId UUID записb о работе (объеме)
   */
   deleteTaskReportVolume: (taskUid, taskReportVolumeId) => {
    return Factory.tasks[taskUid].volume[taskReportVolumeId].delete()
  },

  /**
   * Удаление всех записей о работе (объема)
   * @param {UUID} taskUid UUID задачи
   */
   deleteAllTaskReportVolume: (taskUid) => {
    return Factory.tasks[taskUid].volumes.delete()
  },

  importTask: (project, file, classificator) => {
    let formData = new FormData()
    formData.append("file", file)
    formData.append("project", project)
    if(classificator){
      formData.append("classificator", classificator)
    }
    return Factory.tasks.import.post(formData, Presets.headers.MULTIPART)
  },

  recalcTask: (project, start) => {
    let formData = new FormData()
    formData.append("start", start)
    formData.append("project", project)
    return Factory.tasks.recalc.post(formData)
  },

  changeOrder: (uuids) => {
    return Factory.tasks.order.post(uuids) 
  },

  changeWorkspace: (uuid, value) => {
    if(value != null){
      return Factory.task[uuid].workspace[value].patch()
    }
    else{
      return Factory.task[uuid].workspace.patch()
    }
    
  },

  changeParent: (uuid, value) => {
    return Factory.task[uuid].parent[value].patch()
  },

  taskOutOfParent: (uuid) => {
    return Factory.task[uuid].parent.patch()
  },

  reorderTask: (uuid, order, parent) => {
    return Factory.task[uuid].order[order].parent[parent].patch()
  },

  importBcf: (rq) => Factory.tasks.importBCF.post(rq),

  getPluginTaskStatus: (workspace) => Factory.tasks[workspace].plugin.status.get(),

  changePluginStatus: (taskUuid, status) => Factory.task[taskUuid].plugin.status[status].patch(),

  getTaskParam: (taskUuid) => Factory.task[taskUuid].plugin.params.get(),

})