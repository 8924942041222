<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 48.00 48.00"
  >
    <path
      fill="currentColor"
      d="
      M 5.99 12.78
      L 6.00 35.36
      A 0.62 0.62 0.0 0 0 6.62 35.98
      L 13.33 36.04
      Q 13.95 36.04 13.96 36.66
      L 14.00 39.20
      Q 14.01 39.77 13.44 39.83
      Q 7.90 40.38 5.21 39.83
      C 1.83 39.14 2.06 36.12 2.02 32.75
      Q 1.89 21.86 2.18 11.52
      A 3.62 3.62 0.0 0 1 5.81 7.99
      L 41.32 8.04
      A 0.64 0.63 -0.5 0 1 41.96 8.66
      L 42.02 11.18
      Q 42.04 11.90 41.32 11.91
      L 6.72 12.04
      A 0.73 0.73 0.0 0 0 5.99 12.78
      Z"
    />
    <path
      fill="currentColor"
      d="
      M 45.9943 37.8921
      A 2.05 2.05 0.0 0 1 43.9515 39.9493
      L 32.1116 39.9906
      A 2.05 2.05 0.0 0 1 30.0544 37.9478
      L 29.9857 18.2479
      A 2.05 2.05 0.0 0 1 32.0285 16.1907
      L 43.8684 16.1494
      A 2.05 2.05 0.0 0 1 45.9256 18.1922
      L 45.9943 37.8921
      Z
      M 41.9781 21.1730
      A 1.25 1.25 0.0 0 0 40.7259 19.9252
      L 35.2459 19.9348
      A 1.25 1.25 0.0 0 0 33.9981 21.1870
      L 34.0219 34.8270
      A 1.25 1.25 0.0 0 0 35.2741 36.0748
      L 40.7541 36.0652
      A 1.25 1.25 0.0 0 0 42.0019 34.8130
      L 41.9781 21.1730
      Z"
    />
    <path
      fill="currentColor"
      d="
      M 17.03 28.67
      C 17.97 27.00 18.07 26.54 18.09 24.71
      A 0.71 0.70 0.0 0 1 18.80 24.01
      L 24.93 24.03
      A 0.64 0.63 -8.7 0 1 25.54 24.47
      L 27.91 31.53
      A 1.25 1.20 46.2 0 1 27.91 32.28
      L 25.58 39.54
      Q 25.46 39.90 25.08 39.93
      Q 21.64 40.14 19.47 40.00
      Q 17.58 39.88 18.07 38.02
      A 1.30 1.29 38.6 0 0 17.96 37.08
      C 16.40 34.12 15.18 31.98 17.03 28.67
      Z
      M 24.97 32.00
      A 2.99 2.99 0.0 0 0 21.98 29.01
      A 2.99 2.99 0.0 0 0 18.99 32.00
      A 2.99 2.99 0.0 0 0 21.98 34.99
      A 2.99 2.99 0.0 0 0 24.97 32.00
      Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>