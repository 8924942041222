import { api } from '@/api'
import { defineStore } from 'pinia'
import vuexStore from '@/store'
import { SmetaLinkItem, SmetaLinkType, SmetaShareInfo, getPanelUnitByType } from './SmetaConnections.model'
import { getSmetaCacheItem } from '@/store/smeta/smeta.module'
import { useSmetaPanelStore } from './smetaPanel.store'
import { SmetaTabType } from '@/components/project/panel/left/smeta/components/utils/SmetaTabType'

export const useSmetaConnectionsStore = defineStore('smeta.connections', {
  state: () => ({
    organizationInfo: new SmetaShareInfo(),
    smetaLinks: {},
    selectedNode: null,
    selectedLink: null,
    filters: {
      onlyLinks: false,
      withClassificator: false,
      withWorm: false,
      withTask: false,
      withAttributeDictionary: false,
      withClassificatorNode: false
    },
    isSmetaConnectionsTreeLoading: false
  }),

  getters: {
    smetaLinksByParent: (state) => { 
      if (state.selectedNode) return state.smetaLinks[state.selectedNode]
      return []
    }
  },

  actions: {
    async openPanelUnit(unit = 'empty', link) {
      if (unit == 'wormpanel') {
        const projectWormUuid = findParentLinkUuid(link, 'ProjectWorm')
        vuexStore.commit('worm/setSelectedWormNode', link.uuid)
        vuexStore.commit('project/setProjectSettingsProjectWorm', projectWormUuid)
        vuexStore.commit('project/setActiveGlobalTab', unit)
      }
      if (unit == 'smeta') {
        const projectClassificatorUuid = findParentLinkUuid(link, 'ProjectClassificator')
        vuexStore.commit('project/setProjectSettingsProjectClassificator', projectClassificatorUuid)
        useSmetaPanelStore().setSmetaPanelClassificatorType()
        vuexStore.commit('smeta/setScrollTo', link.uuid)
        vuexStore.commit('project/setActiveGlobalTab', unit)
      }
      if (unit == 'universal_attribute') { 
        const projectAttributeDictionaryUuid = findParentLinkUuid(link, 'ProjectAttributeDictionary')
        vuexStore.dispatch('projectDict/setSelectedProjectDictAndAttribute', {
          projectAttributeUuid: projectAttributeDictionaryUuid,
          attributeUuid: link.uuid
        })
        useSmetaPanelStore().tab = SmetaTabType.ATTRIBUTES_DICT
      }
    },

    async openTopPanelUnit(unit = null, link) {
      const workspaceUuid = findParentLinkUuid(link, 'WorkSpace')
      await vuexStore.dispatch('workspace/changeWorkSpace', workspaceUuid)
      vuexStore.commit('task/setSelectedTaskByUuid', link.uuid)
      vuexStore.commit('project/setTopUnit', unit)
    },

    openUnit(link) {
      // TODO: переделать
      // сейчас в selectedLink хранится uuid compund элемента в классификаторе
      this.selectedLink = link.parent.uuid
      const unit = getPanelUnitByType(link.type)
      if (unit == 'task') {
        this.openTopPanelUnit(unit, link)
      } else {
        this.openPanelUnit(unit, link)
      } 
    },

    openLinkInWindow(link) {
      if (link.type == SmetaLinkType.TASK) {
        const workSpace = findParentLinkUuid(link, 'WorkSpace')
        goTo(link, "selectedTask", "workSpace", link.uuid, workSpace)
      }

      if (link.type == SmetaLinkType.WORM_NODE) {
        const wormProjectUuid = findParentLinkUuid(link, 'ProjectWorm')
        goTo(link, "wormProjectUuid", "wormNodeUuid", wormProjectUuid, link.uuid)
      }

      if (link.type == SmetaLinkType.COMPOUND) {
        const projectClassificatorUuid = findParentLinkUuid(link, 'ProjectClassificator')
        goTo(link, "classificatorUuid", "compoundUuid", projectClassificatorUuid, link?.parent?.uuid)
      }

      if (link.type == SmetaLinkType.CLASSIFICATOR_NODE) {
        const projectClassificatorUuid = findParentLinkUuid(link, 'ProjectClassificator')
        goTo(link, "classificatorUuid", "compoundUuid", projectClassificatorUuid, link?.uuid)
      }

      if (link.type == SmetaLinkType.ATTRIBUTE_DICTIONARY) {
        const projectAttributeDictionaryUuid = findParentLinkUuid(link, 'ProjectAttributeDictionary')
        goTo(link, "attributeDictUuid", "attributeUuid", projectAttributeDictionaryUuid, link?.uuid)
      }
    },

    openPanelBySmetaLink(queryString) {
      if (queryString["wormProjectUuid"] && queryString["wormNodeUuid"]) {
        vuexStore.commit('worm/setSelectedWormNode', queryString["wormNodeUuid"])
        vuexStore.commit('project/setProjectSettingsProjectWorm', queryString["wormProjectUuid"])
        vuexStore.commit('project/setActiveGlobalTab', 'wormpanel')
      }
      
      if (queryString["classificatorUuid"] && queryString["compoundUuid"]) {
        this.selectedLink = queryString["compoundUuid"]
        vuexStore.commit('project/setProjectSettingsProjectClassificator', queryString["classificatorUuid"])
        useSmetaPanelStore().setSmetaPanelClassificatorType()
        vuexStore.commit('smeta/setScrollTo', queryString["compoundUuid"])
        vuexStore.commit('project/setActiveGlobalTab', 'smeta')
      }

      if (queryString["attributeDictUuid"] && queryString["attributeUuid"]) {
        vuexStore.commit('project/setActiveGlobalTab', 'smeta')
        useSmetaPanelStore().tab = SmetaTabType.ATTRIBUTES_DICT
        vuexStore.dispatch('projectDict/setSelectedProjectDictAndAttribute', {
          projectAttributeUuid: queryString["attributeDictUuid"],
          attributeUuid: queryString["attributeUuid"]
        })
      }
    },

    fetchSmetaConnections() {
      const uuid = vuexStore.state.smeta.projectClassificatorUuid // Текущий выбранный классификатор
      const classificatorTitle = vuexStore.state.smeta.projectClassificators.find(item => item.uuid == uuid).title
      this.organizationInfo = new SmetaShareInfo()
      this.smetaLinks = {}
      this.selectedNode = null
      
      if (!uuid) return
      this.isSmetaConnectionsTreeLoading = true
      
      api.smeta.showConnections(uuid).then(data => {
        this.organizationInfo = new SmetaShareInfo(data?.shareInfo?.organization, classificatorTitle)    
        data?.linkingInfo?.forEach(item => {
          const cacheItem = getSmetaCacheItem(item.source)
          if (cacheItem.isElement || cacheItem?.hasRules || !cacheItem.isTopic) {
            if (!this.smetaLinks[item.source]) this.smetaLinks[item.source] = []
            this.smetaLinks[item.source].push(new SmetaLinkItem(item, cacheItem))
          }
          if (cacheItem.nodeType.name == "RULE") {
            const parentUuid = cacheItem.parent.uuid
            if (!this.smetaLinks[parentUuid]) this.smetaLinks[parentUuid] = []
            this.smetaLinks[parentUuid].push(new SmetaLinkItem(item, cacheItem))
          }
        })
        Object.keys(this.smetaLinks).forEach(link => {
          let linkTypes = []
          this.smetaLinks[link].forEach(item => { 
            linkTypes.push(...item.links.map(el => el.type))
          })
          vuexStore.commit('smeta/setTreeLinks', { nodeUuid: link, linkTypes: linkTypes })
        })
        this.isSmetaConnectionsTreeLoading = false
      })
    }
  }
})

export function findParentLinkUuid(link, type) { 
  let item = link
  let parentLinkUuid = null
  while (item) {
    if (item.type == type) {
      parentLinkUuid = item.uuid
      break
    }
    item = item.parent
  }
  return parentLinkUuid
}

function goTo(link, param1, param2, value1, value2) {
  window.open(
    `${window.location.origin}/project/${link.project.uuid}?${param1}=${value1}&${param2}=${value2 ?? ''}`,
    '_blank'
  );
}