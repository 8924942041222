import { defineStore } from 'pinia'

export const useDistanceMeasurementStore = defineStore('distanceMeasurement', {
  state: () => {
    return {
      collisionDetectActive: false,
      distanceMeasurements: {},
    }
  },

  actions: {
    setCollisionDetectActive(collisionDetectActive) {
      this.collisionDetectActive = collisionDetectActive
    },

    setDistanceMeasurements(distanceMeasurements) {
      this.distanceMeasurements = distanceMeasurements
    },

    setLengthsAndPointsForMeasurementById(id, lengths, points) {
      const measurement = this.distanceMeasurements[id]
      measurement.length = lengths.length
      measurement.xLength = lengths.xLength
      measurement.yLength = lengths.yLength
      measurement.zLength = lengths.zLength
      measurement.targetWorldPos = points.target
      measurement.originWorldPos = points.origin
    }
  }
})