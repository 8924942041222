import { Plugin, math } from '@xeokit/xeokit-sdk'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { Measurement } from './measurement/measurement';

export class MeasurementsPlugin extends Plugin {

  /**
   * Создать плагин {@link MeasurementsPlugin}
   */
  constructor() {
    super("RegimeMeasurements", XeokitMediator.viewer)
    this._measurements = {}
  }

  /**
   * Получает существующие {@link Measurement}ы, каждая из которых сопоставляется по своему {@link Measurement#id}.
   * 
   * @type {{String:Measurement}}
   */
  get measurements() {
    return this._measurements
  }

  /**
   * Создать {@link Measurement}
   * 
   * @param {String} params.id 
   * @param {Number[]} params.originWorldPos 
   * @param {Number[]} params.targetWorldPos 
   * @param {Boolean} params.needAxes Строить ли координатные оси (актуально для рулетки).
   * @param {Boolean} params.visible 
   * @param {Boolean} params.isAutoGenerated Создан ли автоматически (через зажатый шифт).
   * @param {String} params.createDate 
   * @param {String} params.mode Режим измерения. Нужен для сохранения в задачах.
   * @param {String} params.billboard Режим отрисовки текста. Значения - none и spherical.
   * 
   * @returns id
   */
  createMeasurement(params = {}) {
    if (this.viewer.scene.components[params.id]) {
      this.error("Viewer scene component with this ID already exists: " + params.id)
      delete params.id;
    }
    const measurement = new Measurement(this, {
      id: params.id ?? math.createUUID(),
      scene: XeokitMediator.viewer.scene,
      originWorldPos: params.originWorldPos,
      originEntity: null, 
      targetWorldPos: params.targetWorldPos,
      targetEntity: null, 
      needAxes: params.needAxes,
      visible: params.visible,
      isAutoGenerated: params.isAutoGenerated,
      createDate: params.createDate ?? Date.now(),
      mode: params.mode || XeokitMediator.RegimeMeasurement.rulerMode,
      billboard: params.billboard,
      numberSettings: XeokitMediator.DistanceMeasurement.distanceMeasurementNumberSettings
    })
    this._measurements[measurement.id] = measurement
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()

    measurement.on("destroyed", () => {
      delete this._measurements[measurement.id]
      XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
    });

    this.fire("measurementCreated", measurement)

    return measurement.id
  }

  /**
   * Установить единицы измерения и количество знаков после запятой для всех измерений.
   * 
   * @param {Object} settings 
   */
  setMeasurementSettings(settings) {
    const measurements = this._measurements
    Object.values(measurements).forEach(measurement => {
      measurement.setMeasurementSettings(settings.units.title, settings.decimalPlaces)
    })
  }

  /**
   * Удалить указанные измерения.
   * 
   * @param {Object[]} distanceMeasurements
   */
  removeDistanceMeasurements(distanceMeasurements) {
    distanceMeasurements.forEach(distanceMeasurement => {
      this._measurements[distanceMeasurement.id].destroy()
    })
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  /**
   * Установить видимость указанных измерений.
   * 
   * @param {Object[]} distanceMeasurements 
   * @param {Boolean} visible 
   */
  setMeasurementsVisible(distanceMeasurements, visible) {
    distanceMeasurements.forEach(distanceMeasurement => {
      this._measurements[distanceMeasurement.id].setVisible(visible)
    })
    XeokitMediator.DistanceMeasurement.setDistanceMeasurements()
  }

  /**
   * @deprecated
   * Уничтожить {@link DistanceMeasurement}.
   *
   * @param {String} id Идентификатор измерения.
   */
  destroyMeasurement(id) {
    const measurement = this._measurements[id]
    if (!measurement) {
      this.log("DistanceMeasurement not found: " + id)
      return
    }
    measurement.destroy()
    this.fire("measurementDestroyed", measurement)
  }

  /**
   * @deprecated
   * Уничтожить все {@link DistanceMeasurement}ы.
   */
  clear() {
    const ids = Object.keys(this._measurements);
    for (var i = 0, len = ids.length; i < len; i++) {
      this.destroyMeasurement(ids[i]);
    }
  }

  /**
   * Уничтожить текущий {@link MeasurementsPlugin}.
   * Сначала уничтожает {@link Measurement}ы.
   */
  destroy() {
    this.clear()
    super.destroy();
  }
}