<template lang='pug'>
div(:style='toolbarStyle')
  slot
</template>

<script>
export default {
  props: {
    horizontal: Boolean,
    gap: {
      type: String,
      default: '2px',
    },
  },

  computed: {
    toolbarStyle() {
      return {
        'display': 'flex',
        'gap': this.gap,
        'flex-direction': this.horizontal ? 'row' : 'column',
      }
    }
  }
}
</script>

<style>

</style>