import { defineStore } from "pinia"
import { isEditMode, cancelEdit, setEditedImageSettings, applyPosition, applyRotation, applyWidthSize, applyHeightSize, editedPlane, editedDrawing3DTitle, applyTitle, applyOpacity, isDrawingSettingMode } from '@/components/drawings/facades/imageSectionPlanes.facade.js'

export const useDrawingTransformSettingState = defineStore('drawing-transform-setting-state', {
  state: () => ({
    title: null,
    position: [null, null, null],
    rotation: [null, null, null],
    widthSize: null,
    heightSize: null,
    opacity: 20
  }),

  getters: {
    isEditMode: () => isEditMode(),
    isDrawingSettingMode: () => isDrawingSettingMode(),

    editedDrawing3DTitle: () => editedDrawing3DTitle(),
    editedDrawing3DPosition: () => editedPlane().position,
    editedDrawing3DRotation: () => editedPlane().rotation,
    editedDrawing3DWidthSize: () => editedPlane().widthSize,
    editedDrawing3DHeightSize: () => editedPlane().heightSize,
    editedDrawing3DOpacity: () => editedPlane().opacity
  },

  actions: {
    setEditedImageSectionPlaneState() {
      this.title = this.editedDrawing3DTitle
      this.position = this.editedDrawing3DPosition.map(coord => Number(coord).toFixed(3))
      this.rotation = this.editedDrawing3DRotation.map(coord => Number(coord).toFixed(3))
      this.widthSize = this.editedDrawing3DWidthSize.toFixed(3)
      this.heightSize = this.editedDrawing3DHeightSize.toFixed(3)
      this.opacity = 100 - this.editedDrawing3DOpacity * 100
    },

    applyTitle() {
      applyTitle(this.title)
    },

    applyPosition() {
      applyPosition(this.position)
    },

    applyRotation() {
      applyRotation(this.rotation)
    },

    applyWidthSize() {
      applyWidthSize(this.widthSize)
    },

    applyHeightSize() {
      applyHeightSize(this.heightSize)
    },

    applyOpacity() {
      applyOpacity(this.opacity)
    },

    cancelEdit() {
      cancelEdit()
    },

    saveSettings() {
      setEditedImageSettings()
    }
  }
})