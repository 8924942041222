import { math } from "@xeokit/xeokit-sdk"
import { geometry } from "./geometry"  
import { XeokitMediator } from "../../XeokitMediator"

export const frustumToggleElements = {

  createFrustum(cameraEye, worldPos, selectionSquare){
    // Находим начало и конец отрезка, соединяющего середины передней и задней грани фрустума
    let edge = {
      start: [...worldPos],
      end: []
    }

    XeokitMediator.viewer.scene.camera.ortho.far = 20000
    XeokitMediator.viewer.scene.camera.ortho.near = 0

    /**
     * Поиск направления и длины луча для каждой проекции.
     * Применять ВЕЗДЕ.
     */
    let camera = XeokitMediator.viewer.camera
    let canvas = XeokitMediator.viewer.scene.canvas
    let cameraDirection = []

    let tempVec3a = math.vec3()
    if (XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) {
      math.subVec3(cameraEye, worldPos, tempVec3a)
      cameraDirection = math.normalizeVec3(tempVec3a)
      const edgeLength = -geometry.math.distance(cameraEye, worldPos)
      edge.end = math.addVec3(worldPos, math.mulVec3Scalar(cameraDirection, edgeLength/4))
    }
    else {
      let viewMatrix = camera.viewMatrix
      cameraDirection = [viewMatrix[2], viewMatrix[6], viewMatrix[10]] //решение для Ортогональной проекции
      let length = camera._ortho._scale
      edge.end = math.addVec3(worldPos, math.mulVec3Scalar(cameraDirection, -length/7.4))
    }

    // Находим параметры frustumMat4
    const xUnit = 2.0 / canvas.canvas.clientWidth
    const yUnit = 2.0 / canvas.canvas.clientHeight
    const ratio = canvas.canvas.clientHeight / canvas.canvas.clientWidth
    const leftNorm = selectionSquare[0] * xUnit - 1
    const rightNorm = selectionSquare[1] * xUnit - 1
    const bottomNorm = (-selectionSquare[2]) * yUnit + 1
    const topNorm = (-selectionSquare[3]) * yUnit + 1
    math.subVec3(camera.eye, edge.end, tempVec3a)
    const far = Math.abs(math.lenVec3(tempVec3a))
    // Создаем фрустум
    let frustumMat
    if (XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) {
      frustumMat = math.frustumMat4(
          leftNorm,
          rightNorm,
          bottomNorm * ratio,
          topNorm * ratio,
          0.1 * ratio * 17,
          far,
          math.mat4(),
      )
    }
    else {
      frustumMat = math.orthoMat4c(
          leftNorm * camera.ortho.scale * 0.5,
          rightNorm * camera.ortho.scale * 0.5,
          bottomNorm * camera.ortho.scale * 0.5 * ratio,
          topNorm * camera.ortho.scale * 0.5 * ratio,
          0.01,
          20000,
          math.mat4(),
      )
    }
    let frustum = geometry.frustum.createXeokitFrustum()
    geometry.frustum.setFrustum(frustum, camera.viewMatrix, frustumMat)

    // Доводим переднюю и заднюю плоскости фрустума до нужных позиций
    frustum.planes[4].offset = -math.dotVec3(frustum.planes[4].normal, edge.end)
    frustum.planes[5].offset = -math.dotVec3(frustum.planes[5].normal, edge.start)

    return frustum
  },

  drawEdges(edges) {
    let meshes = []
    edges.forEach(edge => {
      let edgeMesh = XeokitMediator.SceneObjects.createSegmentMesh({
        segmentPositions: edge,
        segmentColor: [0.23137254901960785, 0.5764705882352941, 0.6862745098039216],
        clippable: false
      })
      meshes.push(edgeMesh)
    })

    return meshes
  },
}