import { defineStore } from 'pinia'
import { MouseNavigation } from "@/plugins/xeokit/mouseNavigation/mouseNavigation";

export const useMouseNavigationStore = defineStore('mouseNavigation', {
  state: () => {
    return {
      mouseNav: MouseNavigation.Navs.USER
    }
  },

  actions: {
    setMouseNavigation(navigation) {
      this.mouseNav = navigation
    },
  },
})