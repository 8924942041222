import { api } from '@/api'
import { App } from '@app/App'

const getDefaultState = () => {
  return {
    unitsOfMeasurement: [],
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    getDefaultRulerSettings: () => {return Object.assign({}, defaultRulerSettings)}
  },

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setUnitsOfMeasurements: (state, units) => {
      state.unitsOfMeasurement = units
    }
  },

  actions: {
    updateRulerSettings: ({ dispatch }, rulerSettings) => {
      if (!App.version.isCloud) {
        return api.usersSettings.updateRulerSettings(rulerSettings).then((data) => {
          dispatch('authUser/setRulerSetting', data, { root: true })
          return true
        })
      }
    },

    getUnitsOfMeasurement: ({ commit }) => {
      return api.usersSettings.getUnitsOfMeasurement().then(data => {
        commit('setUnitsOfMeasurements', data)
      })
    },
  }
}

let defaultRulerSettings = {
  units: {value: 1, title: "m"},
  decimalPlaces: 2,
}