<template lang="pug">
v-file-input.app-file-input( :value="value" ref="inputFile"
                             :accept="acceptFormats" :rules="rules"
                             :class="{ 'app-file-input_extra-padding': hasWarnings }"
                             :hide-details="hideDetails" show-size
                             dense background-color="white"
                             color="var(--v-accent-base)"
                             prepend-inner-icon="attach_file" prepend-icon=""
                             :hide-input="hideInput" :label="$t('section.structure.model.chooseMaterial')"
                             small-chips outlined light @change="handler" )
</template>

<script>
import { byteFormatter } from '@/utils';
const MAX_SIZE = 10737418240

export default {
  inheritAttrs: true,
  name:'app-file-input',

  props: {
    value: [File],
    formats: {
      type: [Array, String],
      default: function () { return ['.docx','.xlsx'] } },
    maxSize: { type: Number, default: MAX_SIZE },
    hideDetails: { type: Boolean, default: false },
    hideInput: Boolean
  },

  computed: {
    acceptFormats () {
      if (typeof this.formats != 'string') return this.formats.join(', ');
      return this.formats
    },

    sizeFile() {
      return byteFormatter(this.maxSize)
    },

    hasWarnings () {
      return this.value && this.value.size > this.maxSize
    },

    rules() {
      let external = this.value && this.value.name ? this.value.name.substring(this.value.name.lastIndexOf(".")) : null
      if (external && this.formats.length > 0 && !this.formats.includes(external))
        return [() => !this.value || this.$t('app.fileInput.rules.extension') + '(' + this.formats + ')'].concat(this.$attrs.rules || [])
      return [() => !this.value || this.value.size < this.maxSize || this.$t('app.fileInput.rules.size') + ' ' + this.sizeFile].concat(this.$attrs.rules || [])
    }
  },

  methods: {
    handler (file) {
      this.$emit('input', file)
    },

    openFileInput () {
      this.$refs.inputFile.$refs.input.click();
    },
  },
}
</script>

<style lang="scss" scoped>
.app-file-input_extra-padding {
  padding-bottom: 16px;
}
</style>

<style>
.app-file-input.v-file-input input[type=file] {
  position: absolute;
  z-index: -1;
}
</style>