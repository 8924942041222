<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="#D0D0D0"/>
    <path d="M13.5 13.5938V4.03125L21.5312 10.4062V19.9688L13.5 13.5938Z" stroke="#FF0000"/>
    <path d="M17.5156 14.0156V9.01562" stroke="#303030"/>
    <path d="M4.5 11.5156L18 11.5156" stroke="#303030"/>
    <circle cx="4.5" cy="11.5156" r="2" fill="#303030" stroke="#303030"/>
    <rect x="1.03125" y="18" width="5" height="5" fill="white"/>
    <path d="M1.03125 20L3.03125 22L6.03125 19" stroke="#303030"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>