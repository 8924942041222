<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="32" height="32"/>
  <path d="M14.5 15V19.5H9.5V9.5H19.5V14.5H15H14.5V15Z" stroke="currentColor"/>
  <rect x="14.5" y="14.5" width="8" height="8" fill="currentColor" stroke="currentColor"/>
  <rect x="4" y="4" width="2" height="2" fill="currentColor"/>
  <rect x="26" y="4" width="2" height="2" fill="currentColor"/>
  <rect x="15" y="4" width="2" height="2" fill="currentColor"/>
  <rect x="15" y="26" width="2" height="2" fill="currentColor"/>
  <rect x="4" y="15" width="2" height="2" fill="currentColor"/>
  <rect x="26" y="15" width="2" height="2" fill="currentColor"/>
  <rect x="4" y="26" width="2" height="2" fill="currentColor"/>
  <rect x="26" y="26" width="2" height="2" fill="currentColor"/>
</svg>
</template>

<script>
export default {}
</script>