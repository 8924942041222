<template lang="pug">
.surface.px-2.pb-2.mb-7
  .app-card_header
    .app-card_header-text.text-truncate( style="width: 100%; " v-html="header" )
  app-section
    slot
</template>

<script>
export default {
  name: "AppCard",
  
  props: { header: String },
}
</script>

<style scoped>
.app-card_header {
  height: 40px;
  padding: 8px;
  display: flex;
  overflow: hidden;
}
.app-card_header-text{
  color: var(--v-primary-darken2);
}
</style>

