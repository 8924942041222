import { defineStore } from 'pinia'

export const useTaskBimAnnotationsStore = defineStore('taskBimAnnotations', {
  state: () => {
    return {
      selectedTaskAnnotationUuid: null,
      visibleTaskAnnotations: true
    }
  },

  actions: {
    setSelectedBimTaskAnnotation(taskAnnotationUuid) {
      this.selectedTaskAnnotationUuid = taskAnnotationUuid
    },

    setVisibleTaskAnnotations(visibleTaskAnnotations) {
      this.visibleTaskAnnotations = visibleTaskAnnotations
    }
  },
})