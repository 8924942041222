import { SourcePath } from "@/assets/app/SourcePath"
import { XeokitMediator } from "./XeokitMediator"
import { PromiseQueue } from "./PromiseQueue"
import { useViewerIfcTypesStore } from "@/pinia"
import store from "@/store"

const MODEL_TRANSFORM_ID = 'modelTransformId'

class RevisionLoader {

  static loadRevisions(revisions, quality) {
    const queue = new PromiseQueue()
    
    revisions.forEach(revision => {
      queue.add(() => {
        return this.loadRevision(revision, quality)
      })
    })

    return queue
  }

  static async loadRevision(revision, quality) {
    const metaModelData = await fetch(SourcePath.file(revision.jsonFile)).then(response => response.json()).catch(() => {
      throw new Error("Принудительное завершение запроса модели с сервера")
    })

    metaModelData.metaObjects?.forEach(object => {
      XeokitMediator.viewer.metaScene.metaObjectsByType[object.type] ||= {}
      XeokitMediator.viewer.metaScene.metaObjectsByType[object.type][object.id] = object
    })

    return new Promise((resolve, reject) => {

      const objectDefaults = {
        IfcSpace: {
          pickable: false,
          opacity: 0.5,
        }
      }

      useViewerIfcTypesStore().hidden.forEach(type => {
        if (objectDefaults[type]) {
          objectDefaults[type].visible = false
        } 
        else {
          objectDefaults[type] = { visible: false }
        }
      })

      const sceneModel = XeokitMediator.xktLoader.load({
        id: revision.uuid,

        src: SourcePath.file(revision.xktFile),
        metaModelData,

        // position: [revision.showOffsetX, revision.showOffsetY, revision.showOffsetZ],
        // rotation: revision.xktversion !== 3 ? [revision.showEulerX, revision.showEulerY, revision.showEulerZ] : [90 + revision.showEulerX, revision.showEulerZ, revision.showEulerY],

        pbrEnabled: true,
        backfaces: true, // Отображать все / если false отображается только то что в зоне видимости (хуево с ортогональю)
        edges: true,
        excludeTypes: quality && quality.armature ? [] : ['IfcReinforcingBar', 'IfcReinforcingElement', 'IfcReinforcingMesh', 'IfcTendon', 'IfcTendonAnchor'],

        // origin: revision.xktversion !== 3 ? [revision.showOffsetX, revision.showOffsetY, revision.showOffsetZ] : [revision.showOffsetX, revision.showOffsetZ, revision.showOffsetY],

        excludeIds: store.state.project.patchExcludeEl,

        dtxEnabled: true,

        reuseGeometries: true,

        objectDefaults,

        transformId: MODEL_TRANSFORM_ID,
      })

      const rotation = revision.xktversion !== 3 ? [revision.showEulerX, revision.showEulerY, revision.showEulerZ] : [90 + revision.showEulerX, revision.showEulerZ, revision.showEulerY]
      const position = revision.xktversion !== 3 ? [revision.showOffsetX, revision.showOffsetY, revision.showOffsetZ] : [revision.showOffsetX, revision.showOffsetZ, revision.showOffsetY]

      sceneModel.createTransform({
        id: MODEL_TRANSFORM_ID,
        position: position,
        rotation: rotation,
      })
      
      const unbind = () => {
        sceneModel.off(modelLoadedSubId)
        sceneModel.off(modelErrorSubId)
        sceneModel.off(modelDestroyedSubId)
      }
      sceneModel.loaderType = "ifc"
      const success = () => {
        resolve(sceneModel)
        unbind()
      }
      const error = () => (reject(sceneModel), unbind())

      let modelLoadedSubId = sceneModel.on('loaded', success)
      let modelErrorSubId = sceneModel.on('error', error)
      let modelDestroyedSubId = sceneModel.on('destroyed', error)
    })
  }
}

export { 
  RevisionLoader,
  MODEL_TRANSFORM_ID,
}
