import { projectService } from '@/_services'
import project from '../project.module'

let type = {
  SMETA:1,
  SUB_TASKS:2,
  APPOINTED:3,
  MODELS:4,
  COLLISIONS:5,
  ATTACHMENTS:6,
  COMMENTS:7,
}

export default {
  namespaced: true,

  getters: {
    getSmetaPanel: () => project.state.projectSettings.taskSections.find(section => section.name.value == type.SMETA )?.opened,
    getSubTaskPanel: () => project.state.projectSettings.taskSections.find(section => section.name.value == type.SUB_TASKS )?.opened,
    getAppointedPanel: () => project.state.projectSettings.taskSections.find(section => section.name.value == type.APPOINTED )?.opened,
    getModelPanel: () => project.state.projectSettings.taskSections.find(section => section.name.value == type.MODELS )?.opened,
    getCollisionPanel: () => project.state.projectSettings.taskSections.find(section => section.name.value == type.COLLISIONS )?.opened,
    getAttachmentPanel: () => project.state.projectSettings.taskSections.find(section => section.name.value == type.ATTACHMENTS )?.opened,
    getCommentPanel: () => project.state.projectSettings.taskSections.find(section => section.name.value == type.COMMENTS )?.opened,
  },

  actions: {
    setSmetaPanel: ( { dispatch }, opened ) => dispatch( 'updateTaskSections', { sectionType: type.SMETA, opened: opened } ),
    setSubTaskPanel:( { dispatch }, opened ) => dispatch( 'updateTaskSections', { sectionType: type.SUB_TASKS, opened: opened } ),
    setAppointedPanel:( { dispatch }, opened ) => dispatch( 'updateTaskSections', { sectionType: type.APPOINTED, opened: opened } ),
    setModelPanel:( { dispatch }, opened ) => dispatch( 'updateTaskSections', { sectionType: type.MODELS, opened: opened } ),
    setCollisionPanel:( { dispatch }, opened ) => dispatch( 'updateTaskSections', { sectionType: type.COLLISIONS, opened: opened } ),
    setAttachmentPanel:( { dispatch }, opened ) => dispatch( 'updateTaskSections', { sectionType: type.ATTACHMENTS, opened: opened } ),
    setCommentPanel:( { dispatch }, opened) => dispatch( 'updateTaskSections', { sectionType: type.COMMENTS, opened: opened } ),

    updateTaskSections( { state, getters }, { sectionType, opened } ){
      state
      let taskSections = project.state.projectSettings.taskSections

      if( taskSections.length == 0 ) {
        taskSections.push( { name: { value:type.SMETA }, opened: getters.getSmetaPanel } )
        taskSections.push( { name: { value:type.SUB_TASKS }, opened: getters.getSubTaskPanel } )
        taskSections.push( { name: { value:type.APPOINTED }, opened: getters.getAppointedPanel } )
        taskSections.push( { name: { value:type.MODELS }, opened: getters.getModelPanel } )
        taskSections.push( { name: { value:type.COLLISIONS }, opened: getters.getCollisionPanel } )
        taskSections.push( { name: { value:type.ATTACHMENTS }, opened: getters.getAttachmentPanel } )
        taskSections.push( { name: { value:type.COMMENTS }, opened: getters.getCommentPanel } )
      }

      taskSections.find( section => section.name.value == sectionType ).opened = opened

      projectService.updateTaskSections(project.state.project.uuid, taskSections).then(() => {
        project.mutations.setProjectSettingsTaskSections(project.state, taskSections)
      })
    },

  }
}