import { buildCylinderGeometry, EmphasisMaterial, Mesh, PhongMaterial, VBOGeometry } from "@xeokit/xeokit-sdk";

const STATE_INHERIT = true
export class CoordinateArrow {

  #owner;
  #position; #rotation;
  #color;

  constructor(owner, cfg = {}) {
    this.#owner = owner

    this.#position = cfg.position ?? [0, 0, 0]
    this.#rotation = cfg.rotation ?? [0, 0, 0]
    this.#color = cfg.color ?? [1, 1, 1]

    this.#create()
  }

  #create() {
    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255,]
    const position = this.#position
    const rotation = this.#rotation

    const geometry = new VBOGeometry(this.#owner, buildCylinderGeometry({
      radiusTop: 0.01,
      radiusBottom: 0.04,
      height: 0.2,
      radialSegments: 3,
      heightSegments: 1,
      openEnded: false,
    }))

    const material = new PhongMaterial(this.#owner, {
      diffuse: color,
      emissive: [0, 0, 0],
      ambient: [0, 0, 0],
      specular: [0, 0, 0],
      shininess: 128,
      alpha: 1
    })

    const selectedMaterial = new EmphasisMaterial(this.#owner, {
      edges: false,
      fill: true,
      fillColor: color,
      fillAlpha: 1,
      backfaces: false,
      glowThrough: true
    })

    this.#owner.addChild(new Mesh(this.#owner, {
      geometry: geometry,
      material: material,
      selectedMaterial: selectedMaterial,
      position: position,
      rotation: rotation,
      origin: this.#owner.origin
    }), STATE_INHERIT)
  }
}