<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="#F0F0F0"/>
    <path d="M13.5 3.40625V12.5L21.5625 20.5312V11" stroke="#B5B5B5"/>
    <path d="M13.5625 12.5977H3L11 20.5315H22" stroke="#B5B5B5"/>
    <path d="M13.5312 3.71875L21.5 11.5313" stroke="#FF0000"/>
    <path d="M4.5 7.5L18.1606 7.5877" stroke="#303030"/>
    <rect x="16.5" y="6.5" width="2" height="2" fill="white" stroke="#303030"/>
    <circle cx="13.5" cy="3.54688" r="1.5" fill="#FF0000"/>
    <circle cx="3.5" cy="7.5" r="1" fill="#303030" stroke="#303030"/>
    <circle cx="21.5625" cy="11.5" r="1.5" fill="#FF0000"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>