/* eslint-disable */
import { SceneModel, SceneModelEntity } from "@xeokit/xeokit-sdk/dist/xeokit-sdk.es"
import { mergeAABBs, rotateAABB, shiftAABBCenter } from "./sectionCube/aabb.utils"
import { XeokitMediator } from "./XeokitMediator"

class ModelAABBCache {
  static #_idx = 0
  static #_modelsAABB = {}

  static getSceneAABB() {
    return this.#_idx ? mergeAABBs(Object.values(this.#_modelsAABB)) : [-100, -100, -100, 100, 100, 100]
  }
  
  static getModelAABB(modelId) {
    return this.#_modelsAABB[modelId]
  }

  static setModelAABB(modelId, aabb) {
    this.#_idx ++
    return this.#_modelsAABB[modelId] = aabb
  }
  
  static clear() {
    this.#_idx = 0
    this.#_modelsAABB = {}
  }
}

class ObjectAABBCache {
  static #_objectAABB = {}

  static getObjectAABB(objectId) {
    return this.#_objectAABB[objectId] ||= (() => {
      const object = XeokitMediator.viewer.scene.objects[objectId]
      const model = object.model

      return rotateAABB(shiftAABBCenter(object._aabb, model.position), model.rotation)
    })()
  }

  static setObjectAABB(objectId, aabb) {
    return this.#_objectAABB[objectId] = aabb
  }

  static clear() {
    this.#_objectAABB = {}
  }
}

Object.defineProperty(SceneModelEntity.prototype, 'aabb', {
  get() {
    return ObjectAABBCache.getObjectAABB(this.id) || this._aabb
  }
})

// Object.defineProperty(SceneModel.prototype, 'aabb', {
//   get() {
//     return Object.values(this.objects).length ? mergeAABBs(Object.values(this.objects).map(object => object.aabb)) : this._aabb
//   }
// })

export { ModelAABBCache, ObjectAABBCache }