import { Mesh } from "@xeokit/xeokit-sdk"
import { EmphasisMaterial, PhongMaterial, VBOGeometry } from "@xeokit/xeokit-sdk"

/*eslint-disable no-dupe-class-members*/
export class MeasurementSegment {

  #owner;                          // class instance
  #color; #visible; #isSimpleLine; // segment visual settings

  /**
   * Отрисовать на сцене отрезок измерения.
   * 
   * @param {Component} cfg Scene или Node. Создаваемая ось будет закреплена за этим владельцем, уничтожение владельца повлечет уничтожение и этого компонента. Наследует свойства трансформации.
   * @param {Object} cfg Конфиг.
   * @param {Boolean} cfg.visible Показать / скрыть при создании.
   * @param {Number[]} cfg.color Цвет отрезка в формате [r, g, b] от 0 до 1.
   * @param {Boolean} cfg.isSimpleLine Отрисовать только линию между точками / добавить стрелки на концах отрезка.
   */
  constructor(owner, cfg = {}) {
    this.#owner = owner
    
    this.#visible = cfg.visible ?? true
    this.#color = cfg.color ?? [1, 1, 1]
    this.#isSimpleLine = cfg.isSimpleLine ?? false

    this.#createSegment() 
  }
  
  /**
   * @private Создать отрезок измерения.
   */
  #createSegment() {
    const STATE_INHERIT = true

    const node = this.#owner
    const origin = node.origin
    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255]

    const linesPositions = [
      -0.1, 0.4, 0.0, 0.0, 0.5, 0.0, 0.1, 0.4, 0.0,     // top corner
      -0.1, -0.4, 0.0, 0.0, -0.5, 0.0, 0.1, -0.4, 0.0,  // bottom corner
      -0.1, 0.5, 0.0, 0.1, 0.5, 0.0,                    // top line
      -0.1, -0.5, 0.0, 0.1, -0.5, 0.0,                  // bottom line
      0.0, 0.5, 0.0, 0.0, -0.5, 0.0                     // middle line
    ]
    const simpleLinePositions = [0, -0.5, 0, 0, 0.5, 0]

    const linesIndices = [0, 1, 1, 2, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11]
    const simpleLineIndices = [0, 1]

    const materials = {
      yellowEmphasis: new EmphasisMaterial(node, {
        fill: false,
        edges: true,
        edgeColor: color,
        edgeAlpha: 1,
      }),

      yellowPhong: new PhongMaterial(node, {
        emissive: color,
        diffuse: [0, 0, 0],
        ambient: [0, 0, 0],
      })
    }

    const geometries = {
      linesGeometry: new VBOGeometry(node, {
        primitive: 'lines',
        positions: linesPositions,
        indices: linesIndices,
      }),

      simpleLineGeometry: new VBOGeometry(node, {
        primitive: 'lines',
        positions: simpleLinePositions,
        indices: simpleLineIndices,
      }),
    }

    if (this.#isSimpleLine) {
      node.addChild( new Mesh( node, {
        geometry: geometries.simpleLineGeometry,
        material: materials.yellowPhong,
        selectedMaterial: materials.yellowEmphasis,
        origin: origin,
        visible: this.#visible
      }), STATE_INHERIT)
    }
    else {
      node.addChild( new Mesh( node, {
        geometry: geometries.linesGeometry,
        material: materials.yellowPhong,
        selectedMaterial: materials.yellowEmphasis,
        origin: origin,
        visible: this.#visible
      }), STATE_INHERIT)
    }
  }
}