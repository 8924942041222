<template lang="pug">
v-select(
  ref="appSelect"
  v-bind="$attrs"
  :value="value"
  :items="items"
  :label="label"
  :item-text='translationItemText'
  :item-value="itemValue"
  :disabled="disabled"
  :return-object="!itemValue || returnObject"
  :multiple="multiple"
  :rules="rules"
  :prepend-icon="prependIcon"
  :append-outer-icon="appendOuterIcon"
  :clearable="clearable"
  :background-color="backgroundColor"
  color="var(--v-accent-base)"
  dense
  flat
  filled
  :hide-details="hideDetails"
  light
  :outlined="outlined"
  :menu-props="{ ...menuProps, maxWidth: maxWidth }"
  
  @change="handleChange" 
  @input="handleSelect"
  @focus="handleFocus"
  @blur="handleBlur"

  @click:append-outer="$emit('click:append-outer')"
)
  template(v-if="multiple && oneLine" v-slot:selection="{ item, index }")
    v-chip(v-if="index === 0" x-small)
      span {{ item[itemText] }}
    span(v-if="index === 1" class="grey--text text-caption")
      | (+{{ value.length - 1 }} )

  template(v-if="$scopedSlots.item" v-slot:item="item")
    slot(name="item" v-bind="item")
</template>

<script>
export default {
  name: "AppSelect",
  props: {
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    value: [String, Number, Object, Array],
    label:  String,
    items: Array,
    disabled: Boolean,
    itemText: [String, Function],
    itemValue: String,
    multiple: Boolean,
    prependIcon: String,
    appendOuterIcon: String,
    clearable: Boolean,
    rules: [Object, Array],
    returnObject:{
      type: Boolean,
      default: false,
    },
    outlined:{
      type: Boolean,
      default: true
    },
    menuProps: {
      type: [String, Object, Array],
      default: () => ({ 
        bottom: true, offsetY: true, contentClass: 'menu-scroll', light: true
      })
    },
    hideDetails: [Boolean, String],
    oneLine: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    translationItemText(item) {
      if (!this.itemText) return this.$t(item['title'])
      if (typeof this.itemText === 'function') return this.itemText(item)

      return this.$t(item[this.itemText])
    },

    handleChange (event) {
      this.$emit('change', event)
    },

    handleSelect (event) {
      this.$emit('input', event)
    },

    handleFocus () {
      this.focused = true
    },

    handleBlur () {
      this.focused = false
    }
  },

  data () {
    return {
      focused: false,
      maxWidth: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.maxWidth = this.$refs?.appSelect?.$el?.clientWidth ?? null
    });
  }
}
</script>

<style scoped>
.v-select__selections {
  overflow: hidden;
}

::v-deep.v-select__selections,
::v-deep.theme--light.v-select .v-select__selection--comma,
::v-deep.theme--dark.v-select .v-select__selection--comma {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

::v-deep.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0;
}
</style>



