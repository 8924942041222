export class SocialConnection {

  constructor (config = {}) {
    let conf = config.social ?? {}
    for(let item in conf) {
      this[item] = conf[item]
    }
    // this.redirect = window.location.origin + 'social/redirect'
    this.redirect = window.location.origin + this.redirect
    // console.log('SOCIAL', this.redirect)
  }
}