import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { AnnotationsPlugin } from '@xeokit/xeokit-sdk'

/*eslint-disable no-dupe-class-members*/
export class CollisionBimAnnotations {  

  static #_collisionBimAnnotationsPlugin = null

  static showCollisionBimAnnotation(collision, position) {
    this.clearCollisionBimAnnotation();

    this.#_createBimAnnotation({
      id: "collision_" + collision.uuid,
      worldPos: position,
      description: collision.info.description,
      title: collision.info.shortDescription,
      collisionUuid: collision.uuid
    });
  }

  static clearCollisionBimAnnotation() {
    this.#_collisionBimAnnotationsPlugin.clear()
  }

  static #_createBimAnnotation(cfg) {
    const id = cfg.id
    const title = cfg.title || ""
    const description = cfg.description || ""
    const worldPos = cfg.worldPos || [0, 0, 0]
    const collisionUuid = cfg.collisionUuid
    
    return this.#_collisionBimAnnotationsPlugin.createAnnotation({
      id: id,
      worldPos: worldPos ,
      occludable: false,
      markerShown: true,
      labelShown: false,
      values: {
          glyph: "",
          title: title,
          description: description,
          collisionUuid: collisionUuid,
          id: id
      },
    });
  }

  static activate() {
    this.#_collisionBimAnnotationsPlugin = new AnnotationsPlugin(XeokitMediator.viewer, {
      markerHTML: "<div id='mark_{{id}}' class='annotation-marker' style='background-color: {{markerBGColor}};'><div>{{glyph}}</div></div>",
      labelHTML: "<div id='{{id}}' class='annotation-label' style='background-color: {{labelBGColor}};'><div class='annotation-title'>{{title}}</div><div class='collision-annotation-desc'>{{description}}</div></div>",
      values: {
          markerBGColor: "purple",
          glyph: "x",
          title: "",
          description: ""
      },
      surfaceOffset: 0.3,
      container: document.body.getElementsByClassName("full")[0]
    });

    // this.#_collisionBimAnnotationsPlugin.on("markerClicked", (annotation) => {
    // });

    this.#_collisionBimAnnotationsPlugin.on("markerMouseEnter", (annotation) => {
      annotation.setLabelShown(true);
    });

    this.#_collisionBimAnnotationsPlugin.on("markerMouseLeave", (annotation) => {
      annotation.setLabelShown(false);
    });
  }
}

