export class AppVersion {

  #_version

  constructor (config = {}) {
    this.#_version = config.version && { ...config.version } || {}
  }
  
  #_tariff
  // #_ready = false;

  useTariff (tariffAlias) {
    this.#_tariff = tariffAlias
  }

  // isLite = !this.#_tariff || this.#_tariff != 'TariffPro'
  // isPro = this.#_tariff == 'TariffPro'

  get isLite () {
    // return this.#_version?.lite?.forcedOn ?? false
    return !this.#_tariff || this.#_tariff != 'TariffPro'
  }

  get isPro () {
    return this.#_tariff == 'TariffPro'
  }

  get isCloud () {
    return this.#_tariff == 'Cloud'
  }

  
  get isNotCloud () {
    return this.#_tariff != 'Cloud'
  }

  get isNotUndefined () {
    return this.#_tariff != undefined
  }

  // get getReady () {
  //   return this.#_ready
  // }

  // set ready (ready) {
  //   console.log(ready)
  //   this.#_ready = ready
  // }
}