class PromiseQueue {
  constructor() {
    this.done = Promise.resolve()
  }

  add(action) {
    return new Promise((resolve, reject) => {
      this.done = this.done.then(action).then(resolve).catch(reject)
    })
  }
}

export { PromiseQueue }