<template lang="pug">
  v-combobox(
    v-bind="$attrs"
    :value="value"
    :items="items"
    :label="label"
    :item-text="itemText" 
    :item-value="itemValue"
    :disabled="disabled"
    :return-object="!itemValue || returnObject"
    :multiple="multiple"
    :rules="rules"
    :prepend-icon="prependIcon"
    :append-outer-icon="appendOuterIcon"
    :clearable="clearable"
    :background-color="backgroundColor"
    color="var(--v-accent-base)"
    menu-props="auto"
    dense
    flat
    filled
    hide-details
    light
    :outlined="outlined"
    :menu-props="menuProps"

    @change="handleChange" 
    @input="handleSelect"
    @focus="handleFocus"
    @blur="handleBlur"

    @click:append-outer="$emit('click:append-outer')"
  )
</template>

<script>
export default {
  name: "AppCombobox",
  props: {
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    value: [String, Number, Object, Array],
    label:  String,
    items: Array,
    disabled: Boolean,
    itemText: [String, Function],
    itemValue: String,
    multiple: Boolean,
    prependIcon: String,
    appendOuterIcon: String,
    clearable: Boolean,
    rules: [Object, Array],
    returnObject:{
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true
    },
    menuProps: {
      type: [String, Object, Array],
      default: () => ({ 
        bottom: true, offsetY: true, contentClass: 'menu-scroll', light: true
      })
    }
  },

  methods: {
    handleChange (event) {
      this.$emit('change', event)
    },

    handleSelect (event) {
      this.$emit('input', event)
    },

    handleFocus () {
      this.focused = true
    },

    handleBlur () {
      this.focused = false
    }
  },

  data () {
    return {
      focused: false
    }
  },
}
</script>

<style>
  .v-select__selections {
    overflow: hidden;
  }

  .v-select__selections,
  .theme--light.v-select .v-select__selection--comma
  {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
  .v-select__slot input {
    font-size: 13px;
  }
</style>
