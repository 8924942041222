import { pluginService } from '@/_services'

const getDefaultState = () => {
  return {
    types:[],

    plugins:[],
    orgPlugins:[],
    repositoryPlugins:[],

    pluginTable:null,
    pluginTableFlag: false, 

    loadedPlugin:null,
    loadedPluginCode: null,
    loadedPluginLog: null,

    pluginConsole: false, 

    deniedPlugin: [],
    validatePlugin: [],

    hint: [],
    methods: [],
    apiModels: [],

    scriptIsLoaded: false,

    pluginsTemplate: [],
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    smetaPlugins: ({ plugins }) => plugins.filter(e => e.type.name == 'SMETA'),
    colisionPlugins: ({ plugins }) => plugins.filter(e => e.type.name == 'COLLISION'),
    reportPlugins: ({ plugins }) => plugins.filter(e => e.type.name == 'REPORT' || e.type.name == 'ANALYTICAL_REPORT'),
    reportOrgPlugins: ({ orgPlugins }) => orgPlugins.filter(e => e.type.name == 'REPORT' || e.type.name == 'ANALYTICAL_REPORT'),
    wormPlugins: ({ plugins }) => plugins.filter(e => e.type.name == 'WORM'),
    businessProccessPlugins: ({ plugins }) => plugins.filter(e => e.type.name == 'BUSINESS_PROCESS'),

    bpPluginsForWorkspace: ({ plugins }) => {
      let resultList = []
      plugins.forEach(plugin => {
        if (plugin.type.name == 'BUSINESS_PROCESS') {
          resultList.push({ pluginUuid: plugin.uuid, pluginName: plugin.name })
        }
      })
      return resultList
    },
  },

  mutations: {
    resetState (state) { 
      const types = state.types
      Object.assign(state, getDefaultState())
      state.types = types  
    },

    setTypes: (state, types) => {
      state.types = types
    },

    setPlugins: (state, plugins) => {
      state.plugins = plugins
    },

    setRepositoryPlugins: (state, repositoryPlugins) => {
      state.repositoryPlugins = repositoryPlugins
    },

    setLoadedPlugin: (state, plugin) => {
      state.loadedPlugin = plugin
    },

    setLoadedPluginCode: (state, pluginCode) => {
      state.loadedPluginCode = pluginCode
    },

    setLoadedPluginLog: (state, pluginLog) => {
      state.loadedPluginLog = pluginLog
    },

    setPluginTable: (state, pluginTable) => {
      state.pluginTable = pluginTable
    },

    setPluginTableFlag: (state, bool) => {
      state.pluginTableFlag = bool
    }, 

    setPluginConsole: (state, bool) => {
      state.pluginConsole = bool
    },

    setValidatePlugin: (state, plugins) => {
      state.validatePlugin = plugins
    },
    
    setDeniedPlugin: (state, plugins) => {
      state.deniedPlugin = plugins
    },

    setOrgPlugins: (state, plugins) => {
      state.orgPlugins = plugins
    },

    setHint: (state, hintList) => {
      state.hint = hintList
    },

    setMethods: (state, methods) => {
      state.methods = methods
    },

    setApiModels: (state, models) => {
      state.apiModels = models
    },

    setScriptIsLoaded: (state, isLoading) => {
      state.scriptIsLoaded = isLoading
    },

    setPluginsTemplate: (state, templates) => {
      state.pluginsTemplate = templates
    },
  },

  actions: {
    INIT: ({ state, dispatch }) => {
      if (state.types.length == 0) dispatch('LOAD_TYPES')
    },

    LOAD_TYPES: ({ state, commit }) => {
      if (state.types.length === 0){
        pluginService.types().then(data => {
          commit('setTypes', data)
        })  
      }
    },

    LOAD_PLUGINS: ({ commit }, projectUuid) => {
      pluginService.list(projectUuid).then(data => {
        commit('setPlugins', data)
      })
    }, 

    LOAD_PLUGINS_BY_PROFILE: ({ commit }) => {
      return pluginService.listByProfile().then(data => {
        commit('setOrgPlugins', data)
      })
    }, 

    LOAD_TABLE: ({ commit }, bool) => {
      commit('setPluginTableFlag', bool )
    },

    LOAD_REPOSITORY_PLUGINS: ({ commit }) => {
      return pluginService.listRepositoryPlugins().then(data => {
        commit('setRepositoryPlugins', data)
      })
    },

    downloadXLSX:(obj, pluginUuid )=>{
      pluginService.downloadXLSX(pluginUuid).then(data => {
        let a = document.createElement("a")
        a.href = URL.createObjectURL(new Blob([data]))
        a.download = 'table_' + pluginUuid + '.xlsx'
        a.click()
      })
    }, 

    getTablePage: ( { commit }, pagInfo ) => {
      return pluginService.getTablePage(pagInfo).then(data => {
        data.pluginUuid = pagInfo.pluginUuid
        commit('setPluginTable', data)
      })
    },

    clonePluginToProject: ( { commit }, obj ) => {
      return pluginService.clonePluginToProject(obj).then(data => {
        commit('setLoadedPlugin', data)
        return data
      })
    },

    addRepositoryPlugin: (obj, plugin) => {
      return pluginService.addRepositoryPlugin(plugin)
    },
    
    getDeniedPlugins: ( {commit }) => {
      return pluginService.getOnValidatePluginsByProfileAndType("DENIED").then(data => {
        commit('setDeniedPlugin', data)
      })
    },

    getValidatePlugins: ( {commit }) => {
      return pluginService.getOnValidatePluginsByProfileAndType("ON_VALIDATE").then(data => {
        commit('setValidatePlugin', data)
      })
    },

    sharePluginForCompany: ( {commit}, pluginUuid) => {
      return pluginService.sharePluginForCompany(pluginUuid).then(data => {
        commit('setLoadedPlugin', data)
      })
    },

    getMethodsByPluginType: ({ commit }, pluginType) => {
      return pluginService.getMethodsByPluginType(pluginType).then(data => {
        commit('setMethods', data)
      })
    },

    getHintForPlugin: ({ commit }) => {
      return pluginService.getHintForPlugin().then(data => {
        commit('setHint', data)
      })
    },

    getHintByPluginType: ({ commit }, pluginType) => {
      return pluginService.getHintByPluginType(pluginType).then(data => {
        commit('setHint', data)
      })
    },

    getApiModels: ({ commit, rootGetters }) => {
      const projectUuid = rootGetters["project/projectUuid"]
      return pluginService.getApiModelsAnnotations(projectUuid).then(data => {
        commit('setApiModels', data)
      })
    },

    getPluginsTemplate: ({ commit }) => {
      return pluginService.getPluginsTemplate().then(data => {
        commit('setPluginsTemplate', data)
        return true
      })
    },

    importPluginTemplate: ({ rootGetters }, pluginName) => {
      const projectUuid = rootGetters['project/projectUuid']
      return pluginService.importPluginTemplate(projectUuid, pluginName).then(data => {
        return data
      })
    }
  
  }
}