<template>
  <svg
    width="32"
    height="32"
    viewBox="-3 -2 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="6.5" r="5.5" stroke="currentColor" stroke-width="2" />
    <path
      d="M17 22.2857C17 25 20.73 30.6618 22.087 28C25 22.2857 31 19 31 19"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M11.1665 23H1C1 18.1975 6.09789 13 12.3865 13C17.2175 13 21.3458 16.0674 23 19.6667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>