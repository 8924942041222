
export default {
  namespaced: true,

  state: {
    selectedIndex: -1
  },

  mutations: {
    setSelectedIndex: (state, payload) => {
      state.selectedIndex = payload
    },
  },
}