<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" fill="transparent" />
    <path d="M19 2V21H2" stroke="currentColor" />
    <path d="M19 21L26 28" stroke="currentColor" />
    <path d="M6 16H26" stroke="currentColor" />
    <circle cx="28" cy="16" r="2.5" fill="transparent" stroke="currentColor" />
    <circle cx="4" cy="16" r="2.5" fill="currentColor" stroke="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>