export default ({ Factory }) => ({
 
    getAnchors: () => Factory.location_control.anchors.get(),
    getMaps: () => Factory.location_control.maps.get(),
    getEmployees: () => Factory.location_control.employees.get(),
    getReservedMap: () => Factory.location_control.map.reserved.get(),
    getReportsDangerZone: () => Factory.location_control.reports.danger_zones.get(),
    getKeepers: () => Factory.location_control.keepers.get(),
    getKeeperById: (id) => Factory.location_control.keepers[id].get(),
    getTagById: (id) => Factory.location_control.tags[id].get(),
    getAnchorsForMap: () => Factory.location_control.geoserver.anchors.get(),
    getAreasForMap: () => Factory.location_control.geoserver.areas.get(),
    
    
})