import { $_notification_server } from '@/_helpers'

export const webPushService = {
    getPublicKey,
    subscribe,
    unsubscribe,
}

function getPublicKey() {
    return $_notification_server.get(`/webpush/publicKey`).then(response => response.data)
}

function subscribe(subscription) {
    return $_notification_server.post(`/webpush/subscribe`, subscription)
}

function unsubscribe(subscription) {
    return $_notification_server.post(`/webpush/unsubscribe`, subscription)
}