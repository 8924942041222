export default ({ Factory }) => ({

  nodetypes: () => Factory.smeta.nodetypes.get(),
  unittypes: () => Factory.smeta.unittypes.get(),
  units: () => Factory.smeta.units.get(),
  dataexporttypes: () => Factory.smeta.dataexporttypes.get(),
  ruletypes: () => Factory.smeta.ruletypes.get(),

  /**
  * получение списка классификаторов проекта
  * @param {Object} projectUuid UUID проекта
  */

  projclasslist: (projectUuid) => Factory.smeta.projclass[projectUuid].list.get(),
  // Новый запрос на получение классификатора
  projClassListForChoice: (projectUuid) => Factory.projects.conditionparamcache[projectUuid].CLASSIFICATOR.get(),

  fullDelete: (form) => Factory.smeta.projclass.fulldelete.post(form),

  /**
  * Создание нового классификатора
  * @param {Object} pc Данные классификатора
  */
  projclasscreate: (pc) => Factory.smeta.projclass.create.post(pc),

  /**
  * Редактирование имени классификатора
  * @param {Object} classificatorUuid UUID классификатора
  * @param {Object} title Новое название классификатора
  */
  projclassEditTitle: (classificatorUuid, title) => Factory.smeta.projclass[classificatorUuid].edittitle.post({ title: title }),

  deleteProjectClassificator: (uuid) => Factory.smeta.projclass.fulldelete[uuid].delete(),

  /**
  * получение списка нод классификатора
  * @param {Object} projectClassUuid UUID классификатора
  */
  topnodes: (projectClassUuid) => Factory.smeta.projclass[projectClassUuid].childs.get(),

  loadFulltree: (projectClassUuid) => Factory.smeta.projclass[projectClassUuid].tree.get(),
  loadFulltreeWithRules: (projectClassUuid) => Factory.smeta.projclasscache[projectClassUuid].treewithrules.get(),

  /**
  * получение списка дочерних нод ноды
  * @param {Object} node UUID ноды
  */
  childrennodes: (node) => Factory.smeta.node[node].list.get(),

  childrennodesfast: (node, projectClassUuid) => Factory.smeta[projectClassUuid].node[node].list.get(),
  /**
  * Создание нового класса
  * @param {Object} cls Данные класса
  */
  classcreate: (cls) => Factory.smeta.node.save.post(cls),

  /**
  * Создание нового класса
  * @param {Object} cls Данные класса
  */
  createParentFor: (uuid, node) => Factory.smeta.node.createparentfor[uuid].post(node),

  /**
  * Поиск ноды во всём проекте
  * @param {Object} query поисковый текст
  */
  search: (query) => Factory.smeta.node.search[query].get(),

  /**
  * Поиск ноды только в выбранном классификаторе
  * @param {Object} query поисковый текст
  */

  searchInClassificator: (query) => Factory.smeta.node.searchinprojectclassificator[query].get(),

  /**
* Поиск ноды только в выбранном классификаторе и только по коду
* @param {Object} query поисковый текст
*/
  searchInClassificatorOnlyByPressmark: (query) => Factory.smeta.node.searchinprojectclassificator.pressmark[query].get(),

  /**
  * Удаление ноды
  * @param {Object} uuid ноды
  */
  delNode: (uuid) => Factory.smeta.node[uuid].delete(),

  /**
  * Удаление ноды
  * @param {Object} uuid ноды
  */
  delNodeOnly: (uuid) => Factory.smeta.node[uuid].only.delete(),

  /**
  * Загрузка ноды по uuid
  * @param {Object} uuid ноды
  */
  loadNode: (uuid, projectClassUuid) => Factory.smeta[projectClassUuid].node[uuid].get(),

  loadNodeLong: (uuid) => Factory.smeta.node[uuid].get(),
  /**
  * Загрузка ривязак ноды по uuid
  * @param {Object} uuid ноды
  */
  loadNodeCompounds: (uuid, projectclassificatorUuid) => Factory.smeta[projectclassificatorUuid].node[uuid].compounds.get(),

  searchNodesByPressmark: (projectUuid, searchText) => {
    let query = `?project=${projectUuid}&text=${searchText}`
    return Factory.smeta.node.searchnodesbypressmark[query].get()
  },

  /**
  * Поиск нод для привязки
  * @param {Object} projectUuid UUID проекта
  * @param {Object} searchText поисковый текст
  */
  searchForCompound: (projectUuid, searchText) => {
    let query = `?project=${projectUuid}&text=${searchText}`
    return Factory.smeta.node.searchforcompound[query].get()
  },

  /**
  * Поиск нод для привязки
  * @param {Object} projectUuid UUID проекта
  * @param {Object} compoundTypeValueName имя типа
  * @param {Object} searchText поисковый текст
  */
  searchForCompoundBytype: (projectUuid, compoundTypeValueName, searchText) => {
    let query = `?project=${projectUuid}&type=${compoundTypeValueName}&text=${searchText}`
    return Factory.smeta.node.searchforcompound[query].get()
  },


  /**
  * Создание привязки
  * @param {Object} compound Данные привязки
  */
  saveCompound: (compound) => Factory.smeta.compound.save.post(compound),

  /**
 * Удаление привязки
 * @param {Object} uuid привязки
 */
  delCompound: (uuid) => Factory.smeta.compound[uuid].delete(),

  /**
  * Загрузка правил ноды по uuid
  * @param {Object} uuid ноды
  */
  //loadNodeRules: (uuid) => Factory.smeta.node[uuid].rules.get(),

  /**
  * Новое правило ноды по uuid
  * @param {Object} uuid ноды
  * @param {Object} rule правило
  */
  addNodeRule: (uuid, rule, projectClassUuid) => Factory.smeta[projectClassUuid].node[uuid].rules.post(rule),

  /**
 * Редактирование правила ноды по uuid
 * @param {Object} uuid ноды
 * @param {Object} rule правило
 */
  editNodeRule: (uuid, rule, projectClassUuid) => Factory.smeta[projectClassUuid].node[uuid].rules.put(rule),

  /**
  * Удаление правила
  * @param {Object} uuid правила
  */
  delNodeRule: (uuid) => Factory.smeta.rule[uuid].delete(),

  /**
* Копирование правила ноды по uuid
* @param {Object} uuid ноды (куда копировать)
* @param {Object} ruleUuid правило для копирования
*/
  copyNodeRule: (uuid, ruleUuid, projectClassUuid) => Factory.smeta[projectClassUuid].node[uuid].rules.copy[ruleUuid].put(),



  loadHighLigthsGroup: (projectUuid, ruleUuid, nodeUuid, projectClassUuid, controller) => {
    let query = `?projectUuid=${projectUuid}&ruleUuid=${ruleUuid}`
    if (nodeUuid) query += `&nodeUuid=${nodeUuid}`
    if (projectClassUuid) query += `&projectClassUuid=${projectClassUuid}`
    return Factory.smeta.hightlightgroup[query].get({ signal: controller.signal })
  },


  loadNonDistributedElements: (projectUuid, projectClassUuid, selectedAxis) => {
    let query = `?projectUuid=${projectUuid}&projectClassUuid=${projectClassUuid}&group=${selectedAxis.value}`
    return Factory.smeta.nondistributedelementscache[query].get()
  },

  importGesn: (projectUuid, rq) => Factory.smeta.projclass.importgesn[projectUuid].post(rq),
  importArhicad: (projectUuid, formData) => Factory.smeta.projclass.importarhicad[projectUuid].post(formData),
  importJson: (projectUuid, formData) => Factory.smeta.projclass.importjson[projectUuid].post(formData),
  importExcel: (projectUuid, formData) => Factory.smeta.projclass.importtreefromexcel[projectUuid].post(formData),


  exportSmeta: (req) => Factory.smeta.node.export.put(req),

  moveNodeTo: (moveUuid, toUuid) => Factory.smeta.node[moveUuid].to[toUuid].patch(),


  loadGrandSmeta: (projectUuid, projectclassificatorUuid) => {
    let query = `?projectUuid=${projectUuid}&projectclassificatorUuid=${projectclassificatorUuid}`
    return Factory.grandsmeta.byparams[query].get()
  },
  uploadGrandSmeta: (formData) => Factory.grandsmeta.parse.post(formData),
  deleteGrandSmeta: (uuid) => Factory.grandsmeta[uuid].delete(),


  onlywithrulestree: (uuid) => {
    let query = '?project_uuid=' + uuid
    return Factory.smeta.projclass.onlywithrulestree[query].get()
  },

  sharetoorg: (projectClassUuid, orgUuid, selectedUuids) => Factory.smeta.projclass[projectClassUuid].sharetoorg[orgUuid].patch(selectedUuids),

  allCodes: (projectClassUuid) => Factory.smeta.projclass[projectClassUuid].allcodes.get(),
  existCode: (projectClassUuid, code) => Factory.smeta.projclass[projectClassUuid].exist[code].get(),

  importStandart: (data) => Factory.smeta.importstandart.post(data),

  checkrules: (uuid) => Factory.smeta.checkpaorjectclassificator[uuid].patch(),

  mergeClassificator: (newVersion) => Factory.smetamerge.showmerge.patch(newVersion),
  applyMergeClassificator: (newVersion) => Factory.smetamerge.applymerge.post(newVersion),

  findElementRules: (uuid, mode) => {
    let query = `?uuid=${uuid}&mode=${mode}`
    return Factory.smeta.findelementrules[query].get()
  },

  showConnections: (uuid) => Factory.smeta.projclass.showconnections[uuid].get()
})