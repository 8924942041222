import { math } from "@xeokit/xeokit-sdk"
import { geometry } from './geometry'

export const planeToPlane = {

  findPerpendicularSegmentBetweenTwoPlanes(face1Edges, face2Edges) {
    let face1 = this.faceByEdges(face1Edges)
    let face2 = this.faceByEdges(face2Edges)

    let face1O = face1.origin
    let face2O = face2.origin

    let face1N = face1.normal
    let face2N = face2.normal

    // Проверяем параллельность плоскостей, в которых лежат многоугольники
    // crossProduct примерно равен нулю
    let lenCrossVec = math.lenVec3(geometry.math.crossVec3(face1N, face2N))
    if (lenCrossVec >= -0.001 && lenCrossVec <= 0.001) {

      // Если плоскости параллельны, из начальной точки первой грани выпускаем луч в сторону плоскости второй грани и найти точку пересечения этого луча с плоскостью.
      let intersPoint = geometry.intersection.getRayPlaneIntersectionPoint(face1O, face1N, face2N, face2O)
      return [face1O, intersPoint]
    }
    else {

      // Плоскости не параллельны, расстояние нуль.
      return [face1O, face1O]
    }
  },

  faceByEdges(edges) {
    return {
      edges: edges,
      origin: edges[0][0],

      normalCache: null,
      verticesCache: null,

      get vertices() {
        if (!this.verticesCache) {
          let faceVertices = [edges[0][0], edges[0][1]]
          for (let i = 1; i < edges.length; i++) {
            if (!geometry.utils.contains(faceVertices, edges[i][0], 0.001)) {
              faceVertices.push(edges[i][0])
            }
            if (!geometry.utils.contains(faceVertices, edges[i][1], 0.001)) {
              faceVertices.push(edges[i][1])
            }
          }

          this.verticesCache = faceVertices
          return faceVertices
        }
        else return this.verticesCache
      },

      get normal() {
        if (!this.norm) {
          let faceN = math.vec3()
          let v1 = geometry.math.subVec3(this.vertices[0], this.vertices[1])
          let v2 = geometry.math.subVec3(this.vertices[0], this.vertices[2])
          let cross = geometry.math.crossVec3(v1, v2)
          if (cross[0] == 0 && cross[1] == 0 & cross[2] == 0){
            this.normalCache = cross
            return cross
          }
          else {
            math.normalizeVec3(cross, faceN)
            this.normalCache = faceN
            return faceN
          }
        }
        else return this.normalCache
      }
    }
  },

  vec3(vec) {
    return {
      v: vec,
      get length() {
        return Math.abs(math.lenVec3(vec))
      }
    }
  }
}