export class Drawing{
  constructor(obj) {
    this.uuid = obj.uuid
    this.name = obj.name
    this.model = obj.model
    this.archived = obj.archived
    this.currentVersion = obj.currentVersion
    this.projectUuid = obj.projectUuid
    this.versions = obj.versions ?? null,
    this.pages = []
  }
}