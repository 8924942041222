import { defineStore } from "pinia"
import { pageIsFittingSize, allComments, commentMode, pageIsLoading, commentImportMode, imageSizes, showContextMenuStatus, contextMenuPosition, showComments, panelCommentModeStyle, currentComments, dateFormat } from "@/components/drawings/facades/drawingComment.facade"
import { commentPosition, showContextMenu, hideContextMenu, showCommentCreateDialog, importAllComments, scrollPageToComment, importOneComment, showCommentChangeDialog, removeComment } from "@/components/drawings/facades/drawingComment.facade"

export const useDrawingCommentState = defineStore('drawing-comment-state', {

  getters: {
    pageIsFittingSize: () => pageIsFittingSize(),
    allComments: () => allComments(),
    commentMode: () => commentMode(),
    pageIsLoading: () => pageIsLoading(),
    commentImportMode: () => commentImportMode(),
    imageSizes: () => imageSizes(),
    showContextMenuStatus: () => showContextMenuStatus(), //show.contextMenu
    contextMenuPosition: () => contextMenuPosition(),
    showComments: () => showComments(),
    panelCommentModeStyle: () => panelCommentModeStyle(),
    currentComments: () => currentComments(),
  }, 

  actions: {
    commentPosition(comment) {
      return commentPosition(comment)
    },

    showContextMenu(event) {
      showContextMenu(event)
    },

    hideContextMenu(event) {
      hideContextMenu(event)
    },

    showCommentCreateDialog() {
      showCommentCreateDialog()
    },

    importAllComments() {
      importAllComments()
    },

    scrollPageToComment(comment) {
      scrollPageToComment(comment)
    },

    importOneComment(comment) {
      importOneComment(comment)
    },

    showCommentChangeDialog(comment) {
      showCommentChangeDialog(comment)
    },

    removeComment(comment) {
      removeComment(comment) 
    },

    dateFormat(date) { 
      return dateFormat(date) 
    }
  }
})