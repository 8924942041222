function arrayMin(array) {
  if (array.length === 0) return Infinity

  let min = array[0]

  for (let i = 1, l = array.length; i < l; ++i) {
    if (array[i] < min) min = array[i]
  }

  return min
}

function arrayMax(array) {
  if (array.length === 0) return -Infinity

  let max = array[0]

  for (let i = 1, l = array.length; i < l; ++i) {
    if (array[i] > max) max = array[i]
  }

  return max
}

function arrayNeedsUint32(array) {
  // assumes larger values usually on last

  for (let i = array.length - 1; i >= 0; --i) {
    if (array[i] >= 65535) return true // account for PRIMITIVE_RESTART_FIXED_INDEX, #24565
  }

  return false
}

const TYPED_ARRAYS = {
  Int8Array: Int8Array,
  Uint8Array: Uint8Array,
  Uint8ClampedArray: Uint8ClampedArray,
  Int16Array: Int16Array,
  Uint16Array: Uint16Array,
  Int32Array: Int32Array,
  Uint32Array: Uint32Array,
  Float32Array: Float32Array,
  Float64Array: Float64Array,
}

function getTypedArray(type, buffer) {
  return new TYPED_ARRAYS[type](buffer)
}

function createElementNS(name) {
  return document.createElementNS('http://www.w3.org/1999/xhtml', name)
}

function createCanvasElement() {
  const canvas = createElementNS('canvas')
  canvas.style.display = 'block'
  return canvas
}

const _cache = {}

function warnOnce(message) {
  if (message in _cache) return

  _cache[message] = true

  console.warn(message)
}

export { arrayMin, arrayMax, arrayNeedsUint32, getTypedArray, createElementNS, createCanvasElement, warnOnce }
