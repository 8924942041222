import { api } from "@/api"
import { EquipmentTree, EquipmentGroup, Equipment, EquipmentSpecification } from '@models/maintenance'
import { simpleMapper } from '@/utils'
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"

function findVisibleNodes (tree, disclosedNodes) {
  let array = []
  for (const node of tree) {
    array.push(node)
    if (disclosedNodes.has(node.uuid) && node.isNode) {
      array.push(...findVisibleNodes(node.children, disclosedNodes))
      if(node.equipments.length>0) array.push(...node.equipments)
    }
  }
  return array
}

function deepTreeDelete (node, id) {
  if(node.children.length == 0) return false
  let index = node.children.findIndex(item => item.uuid === id)
  if(index != -1){
    node.children.splice(index, 1)
    return true
  } else {
    node.children.some(item => { if(deepTreeDelete(item, id)) return true; else return false; })
  }
}

const getDefaultState = () => {
  return {
    tree: [],
    currentProjectUuid: null,
    wsFlag: false,
    loaded: false,
    selectedGroup: null,
    selectedEquipment: null,
    numberOfDisclosedNodes: 0,
    disclosedNodes: new Set(),

    equipmentTasks: []
  }
}

const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    areAllTreeNodesDisclosed: ({ numberOfDisclosedNodes, tree }) => numberOfDisclosedNodes == tree.onlyNodeIds.length,

    isTreeNodeDisclosed: ({ numberOfDisclosedNodes, disclosedNodes }, { areAllTreeNodesDisclosed }) => node => {
      if (numberOfDisclosedNodes == 0) return false
      else if (areAllTreeNodesDisclosed) return true
      return disclosedNodes.has(node.uuid)
    },

    treeAllVisibleNodes: ({ numberOfDisclosedNodes, disclosedNodes, tree }, { areAllTreeNodesDisclosed }) => {
      if (numberOfDisclosedNodes == 0) return tree.items
      else if (areAllTreeNodesDisclosed) return tree.flatlist
      let nodes = findVisibleNodes(tree.items, disclosedNodes)
      return nodes
    },

    treeFlatList: ({ tree }) => tree.flatlist,

    editedGroup: ({ selectedGroup }) => {
      let saveObj = EquipmentGroup.DTO()
      if(selectedGroup) simpleMapper(saveObj, selectedGroup)
      return saveObj
    },

    editedEquipment: ({ selectedEquipment }) => {
      let saveObj = new Equipment(null)
      if(selectedEquipment) simpleMapper(saveObj, selectedEquipment)
      return saveObj
    },

    editedEquipmentSpecification: ({ selectedEquipment }) => {
      let saveObj = new EquipmentSpecification(null)
      if(selectedEquipment) simpleMapper(saveObj, selectedEquipment.equipmentSpecification)
      return saveObj
    },


  },

  mutations: {
    setCurrentProjectUuid: (state, id) => { state.currentProjectUuid = id },
    setLoaded: (state, val) => { state.loaded = val },
    setTree: (state, tree) => { state.tree = tree },
    setSelectedGroup: (state, group) => { state.selectedGroup = group },
    setSelectedEquipment: (state, equipment) => { state.selectedEquipment = equipment },
    setWsFlag: (state, a) => { state.wsFlag = a },
    setEquipmentTasks: (state, tasks) => { state.equipmentTasks = tasks },

    updateGroup: (state, savedObj) => { 
      if(state.selectedGroup) {
        let index = state.tree.flatlist.findIndex(item => item.uuid === savedObj.uuid)
        if(index == -1) {
          if(savedObj.parent != null){
            state.tree.flatlist.find(item => item.uuid === savedObj.parent.uuid)?.children.push(savedObj)
          } else {
            state.tree.items.push(savedObj)
          }
        } else {
          if(state.selectedGroup.parent?.uuid != savedObj.parent?.uuid) {
            if(state.selectedGroup.parent == null) {
              index = state.tree.items.findIndex(item => item.uuid === state.selectedGroup.uuid)
              state.tree.items.splice(index, 1)
            } else {
              let parent = state.tree.flatlist.find(item => item.uuid === state.selectedGroup.parent.uuid)
              index = parent?.children.findIndex(item => item.uuid === state.selectedGroup.uuid)
              parent?.children.splice(index, 1)
            }
            if(savedObj.parent == null) {
              state.selectedGroup.level = 0
              state.tree.items.push(state.selectedGroup)
            } else {
              let parent = state.tree.flatlist.find(item => item.uuid === savedObj.parent.uuid)
              parent?.children.push(state.selectedGroup)
              state.selectedGroup.level = parent?.level + 1
            }
          }
        }

        Object.assign(state.selectedGroup, savedObj)
        state.tree.resetFlatlist()
      }
    },

    deleteGroup: (state, id) => { 
      let index = state.tree.items.findIndex(item => item.uuid === id)
      if(index != -1)
        state.tree.items.splice(index, 1)
      else {
        index = state.tree.flatlist.findIndex(item => item.uuid === id)
        if(index != -1) state.tree.items.some(item => {if(deepTreeDelete(item, id)) return true; else return false;})
      }
      state.tree.resetFlatlist()
    },

    addEquipment: (state, obj) => { 
      let node = state.selectedGroup
      if(node) {
        node.equipments.push(obj)
        state.tree.resetFlatlist()

        if(!state.disclosedNodes.has(node.uuid)) {
          state.disclosedNodes.add(node.uuid)
          state.numberOfDisclosedNodes++
        }

        state.selectedGroup = null
      }
    },

    changeEquipmentGroup: (state, group) => { 
      if(state.selectedEquipment && state.selectedEquipment.equipmentGroup.uuid != group.uuid) {
        let currGroup = state.selectedEquipment.equipmentGroup
        let index = currGroup.equipments.findIndex(item => item.uuid === state.selectedEquipment.uuid)
        currGroup.equipments.splice(index, 1)

        group.equipments.push(state.selectedEquipment)
        state.selectedEquipment.equipmentGroup = group
        state.selectedEquipment.level = group.level + 1

        if(!state.disclosedNodes.has(group.uuid) ) {
          state.disclosedNodes.add(group.uuid)
          state.numberOfDisclosedNodes++
        }

        state.tree.resetFlatlist()
      }
    },

    deleteEquipment: (state, id) => {
      let group = state.tree.flatlist.find(item => item.equipments?.length > 0 && item.equipments.some(eq => eq.uuid === id))
      if(group !== undefined) {
        let index = group.equipments.findIndex(item => item.uuid === id)
        group.equipments.splice(index, 1)
      }
      state.selectedEquipment = null
      state.tree.resetFlatlist()
    },

    updateEquipmentSpecification: (state, data) => { 
      state.tree.flatlist.filter(item => !item.isGroup && item.equipmentSpecification.uuid == data.uuid).forEach(eq => {
        Object.assign(eq.equipmentSpecification, data)
      })
    },

    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },

  actions: {
    openNode ({ state, getters, dispatch }, node) {
      if(!getters.isTreeNodeDisclosed(node) && node.children.length > 0 || node.equipments.length > 0) {
        state.disclosedNodes.add(node.uuid)
        state.numberOfDisclosedNodes++
      }
      if(node.parent != null) dispatch('openNode', node.parent)
    },

    toggleDisclosedNode ({ state, getters }, node) {
      if (getters.isTreeNodeDisclosed(node)) {
        state.disclosedNodes.delete(node.uuid)
        state.numberOfDisclosedNodes--
      }
      else {
        if(node.children.length > 0 || node.equipments.length > 0) {
          state.disclosedNodes.add(node.uuid)
          state.numberOfDisclosedNodes++
        }
      }
    },

    expandAllTreeNodes ({ state }) {
      state.disclosedNodes = new Set(state.tree.onlyNodeIds)
      state.numberOfDisclosedNodes = state.tree.onlyNodeIds.length

    },
    
    collapseAllTreeNodes ({ state }) {
      state.disclosedNodes = new Set()
      state.numberOfDisclosedNodes = 0
    },

    INIT: ({ dispatch }) => {
      return dispatch("LOAD_GROUPS")
    },

    LOAD_GROUPS: ({ commit, rootGetters, state }) => {
      let projectUuid = rootGetters['project/projectUuid']
      if (!state.loaded || state.currentProjectUuid != projectUuid) {
        commit('setCurrentProjectUuid', projectUuid)
        commit('setLoaded',false)
        return api.maintenance.groups(projectUuid).then(data => {
          commit('setTree', new EquipmentTree(data))
          commit('setWsFlag', !!data.length)
          commit('setLoaded', true)
        })
      }
    },

    addEquipmentGroup: ({ commit, rootGetters }, parent = null) => {
      let projectUuid = rootGetters['project/projectUuid']
      let newGroup = new EquipmentGroup(null)
      newGroup.project = projectUuid
      if(parent != null) { 
        newGroup.parent = parent
        newGroup.level = parent.level + 1
      }
      commit('setSelectedGroup', newGroup)
    },

    saveEquipmentGroup: ({ commit, state }, obj) => {
      let DTO = new EquipmentGroup(null)
      simpleMapper(DTO, obj)
      api.maintenance.saveGroup(DTO).then(data => {
        let level = 0
        if(obj.parent?.uuid != null) {
           let parent = state.tree.flatlist.find(item => item.uuid === obj.parent.uuid)
           if(parent !== undefined) {
             level = parent.level + 1
             obj.parent = parent
           }
        }
        commit('updateGroup', new EquipmentGroup(data, level, obj.parent))
      })
    },

    deleteEquipmentGroup: ({ commit }, id) => {
      api.maintenance.deleteGroup(id).then(() => {
        commit('deleteGroup', id)
      })
    },

    addEquipment: ({ commit, state, rootGetters }) => {
      if(state.selectedGroup){
        let projectUuid = rootGetters['project/projectUuid']
        let newEquipment = new Equipment(null)
        newEquipment.project = projectUuid
        newEquipment.level = state.selectedGroup.level + 1
        newEquipment.equipmentGroup = state.selectedGroup
        commit('setSelectedEquipment', newEquipment)
      }
    },

    saveEquipment: ({ commit, state, dispatch }, obj) => {
      let DTO = new Equipment(null)
      simpleMapper(DTO, obj)

      api.maintenance.saveEquipment(DTO).then(data => {
        if(obj.uuid != null) {
          commit('changeEquipmentGroup', obj.equipmentGroup)
        }
        
        if(DTO.uuid == null) {
          data.equipmentSpecification = obj.equipmentSpecification
        }

        Object.assign(state.selectedEquipment, new Equipment(data, state.selectedEquipment.level, state.selectedEquipment.equipmentGroup))
        dispatch('LOAD_GROUPS')  

        if(DTO.uuid == null)
          commit('addEquipment', state.selectedEquipment)
      })
    },

    deleteEquipment: ({ commit }, id) => {
      api.maintenance.deleteEquipment(id).then(() => {
        commit('deleteEquipment', id)
      })
    },

    linkEquipment: ({ state }) => {
      api.maintenance.linkEquipment(state.selectedEquipment.uuid, XeokitMediator.ElementsSelection.selectedElements).then(data => {
        Object.assign(state.selectedEquipment, new Equipment(data, state.selectedEquipment.level, state.selectedEquipment.equipmentGroup))
      })
    },

    saveEquipmentSpecification: ({ dispatch, commit }, obj) => {
      dispatch("corp/saveEquipment", obj, { root: true }).then(data => {
        commit('updateEquipmentSpecification', data)
      })
    },

    openNodeByUuid: ({ state, commit, dispatch }, equipmentUuid) => {
      let el = state.tree?.flatlist.find((el) => el.uuid == equipmentUuid)
      if (el) {
        commit('setSelectedEquipment', el)
        dispatch('openNode', el.equipmentGroup)
      }
    },
    
    resetState({ commit }) {
      commit('RESET_STATE')
    },
  }
}
