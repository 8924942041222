<template lang="pug">
  v-text-field.app-text-field__border( 
    :value="value"
    :label="label" 
    :type="type" 
    :placeholder="placeholder"
    :hint="hint"
    :rules="rules"
    :readonly="readonly"
    :counter="counter"
    :maxlength = "maxlength"
    light
    dense
    outlined
    flat
    :autofocus="focus"
    :disabled="disabled"
    :prepend-inner-icon="prependIcon"
    :prepend-icon="prependOuterIcon"
    :append-icon="appendIcon"
    :append-outer-icon="appendOuterIcon"
    :clearable="clearable"
    :loading="loading"
    :error-messages="errorText" 
    :suffix="suffix"
    :full-width="fullWidth"
    :hide-details="hideDetails"
    :persistent-hint="persistentHint"
    color="accent"
    v-bind="$attrs"
    :background-color="backgroundColor"

    :autocomplete="autocomplete"

    @change="handleChange" 
    @keyup.enter="handleKeyUp"
    @input="handleInput"
    @click:prepend="$emit('click:prepend-outer')" 
    @click:append-outer="$emit('click:append-outer')"
    @click:append="$emit('click:append')"
    @click:clear="$emit('click:clear')"

    @focus="handleFocus"
    @blur="handleBlur"
  )
    template(v-if="slotLoading" v-slot:append)
      v-progress-circular( size="24" indeterminate)
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'app-text-field',
  props: {
    value: [String, Number],
    label:  String,
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    type: {
      type: String,
      default: 'text'
    },
    focus: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    autocomplete: String, 
    errorText: String,
    prependIcon:  String,
    prependOuterIcon:  String,
    appendIcon: String,
    appendOuterIcon: String,
    readonly: Boolean,
    disabled: Boolean,
    rules: [Object, Array],
    counter: [Boolean, Number, String],
    maxlength: [Number, String],
    clearable: Boolean,
    loading: Boolean,
    suffix: String,
    color: {
      default: 'var(--v-surface-base)',
      type: String
    },
    slotLoading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleChange (event) {
      this.$emit('change', event)
    },

    handleKeyUp (event) {
      this.$emit('keyup', event)
    },

    handleInput (event) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.$emit('input', event)
      }, 100)
    },

    handleFocus () {
      this.focused = true
    },

    handleBlur () {
      this.focused = false
      //console.log("blur 1")
      this.$emit('blur', event)
    }
  },

  data () {
    return {
      timeout: null,
      focused: false
    }
  },
}
</script>

<style scoped>
.app-text-field__border {
  border-radius: 3px;
}

::v-deep.v-text-field .v-label,
::v-deep.v-text-field input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}

::v-deep.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0;
}
</style>