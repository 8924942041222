import { ApiFactory, ApiPresets, ApiServer } from '@/utils'

const scope = {
  Factory: ApiFactory, Presets: ApiPresets, server: ApiServer
}

import collisionRules from './collisionRules.api'
import collisionGroups from './collisionGroups.api'
import collisions from './collisions.api'
import axes from "./axes.api"
import tasks from "./tasks.api"
import users from "./users.api"
import usersSettings from "./usersSettings.api"
import roles from "./roles.api"
import tokens from "./tokens.api"
import projects from "./projects.api"
import adminProjects from "./adminProjects.api"
import adminProcess from "./adminProcess.api"
import classifications from "./classifications.api"
import collisionSettings from './collisionSettings.api'
import members from './members.api'
import cloud from './cloud.api'
import smeta from './smeta.api'
import projectDict from './projectDict.api'
import face from './face.api'
import measureunits from './measureunits.api'
import materials from "./materials.api"
import forms from "./forms.api"
import corp from "./corp.api"
import maintenance from "./maintenance.api"
import workspace from "./workspace.api"
import worm from "./worm.api"
import telemetry from './telemetry.api'
import nextCloud from "./nextCloud.api"
import locationControl from "./locationControl.api"
import grids from './grids.api'
import IFCAnnotations from './IFCAnnotations.api'
import sidePanels from './sidePanels.api'
import elements from './elements.api'
import modelVisualSetting from './modelVisualSetting.api'
import viewerTools from './viewerTools.api'
import folder from './folder.api'
import smetadictionary from './smetadictionary.api'
import pointCloud from './pointCloud.api'
import groupPalette from './groupPalette.api'
import ifcqlApi from './ifcql.api'

export const api = {
  tokens: tokens(scope),
  axes: axes(scope),
  collisions: collisions(scope),
  collisionGroups: collisionGroups(scope),
  collisionRules: collisionRules(scope),
  tasks: tasks(scope),
  users: users(scope),
  usersSettings: usersSettings(scope),
  roles: roles(scope),
  projects: projects(scope),
  adminProjects: adminProjects(scope),
  adminProcess: adminProcess(scope),
  classifications: classifications(scope),
  collisionSettings: collisionSettings(scope),
  members: members(scope),
  cloud: cloud(scope),
  smeta: smeta(scope),
  projectDict: projectDict(scope), 
  face: face(scope),
  measureunits: measureunits(scope),
  materials: materials(scope),
  forms: forms(scope),
  corp: corp(scope),
  maintenance: maintenance(scope),
  workspace: workspace(scope),
  worm: worm(scope),
  telemetry: telemetry(scope),
  nextCloud: nextCloud,
  locationControl: locationControl(scope),
  grids: grids(scope),
  IFCAnnotations: IFCAnnotations(scope),
  sidePanels: sidePanels(scope),
  elements: elements(scope),
  modelVisualSetting: modelVisualSetting(scope),
  viewerTools: viewerTools(scope),
  folder: folder(scope),
  smetadictionary: smetadictionary(scope),
  pointCloud: pointCloud(scope),
  groupPalette: groupPalette(scope),
  ifcql: ifcqlApi(scope),
}
