import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { CoordinateLabel } from "./coordinateLabel";
import { CoordinateShaft } from "./coordinateShaft";
import { CoordinateArrow } from "./coordinateArrow";

const STATE_INHERIT = true

/*eslint-disable no-dupe-class-members*/
export class CoordinateAxis {

  #owner; #node;
  #label; #shaft; #arrow;
  #axisType; #coordinate;
  #arrowPos; #arrowRot; #shaftPos; #shaftRot;
  #color;

  constructor(owner, cfg = {}) {
    this.#owner = owner
    this.#node = null
    
    this.#label = null
    this.#shaft = null
    this.#arrow = null

    this.#coordinate = cfg.coordinate ?? [0, 0, 0]
    this.#axisType = cfg.axisType ?? 'x'

    this.#create()
  }

  get label() { return this.#label }

  #create() {
    this.#node = new XeokitNode(this.#owner, {
      isMesh: true,
      selected: true,
      receivesShadow: false,
      castsShadow: false,
      clippable: false,
      collidable: false,
      pickable: false,
      origin: this.#owner.origin
    })

    const params = this.#createStatesByAxisType(this.#axisType)

    this.#label = new CoordinateLabel(this.#node, {
      color: params.color,
      text: params.text,
      position: params.labelPos
    })

    this.#shaft = new CoordinateShaft(this.#node, {
      color: params.color,
      position: params.shaftPos,
      rotation: params.shaftRot
    })

    this.#arrow = new CoordinateArrow(this.#node, {
      color: params.color,
      position: params.arrowPos,
      rotation: params.arrowRot
    })

    this.#owner.addChild(this.#node, STATE_INHERIT)
  }

  #createStatesByAxisType(type) {
    if (type == "x") { 
      return {
        color: [1, 0, 0],
        arrowPos: [0.75, 0, 0],
        arrowRot: [0, 0, -90],
        shaftPos: [0.4, 0, 0],
        shaftRot: [0, 0, -90],
        labelPos: [1, 0, 0],
        text: `X(${this.#coordinate[0].toFixed(3).toString()})`
      }
    }
    if (type == "y") { 
      return {
        color: [0, 1, 0],
        arrowPos: [0, 0.75, 0],
        arrowRot: [0, 0, 0],
        shaftPos: [0, 0.4, 0],
        shaftRot: [0, 0, 0],
        labelPos: [0, 1, 0],
        text: `Y(${this.#coordinate[1].toFixed(3).toString()})`
      }
    }
    if (type == "z") { 
      return {
        color: [0, 0, 1],
        arrowPos: [0, 0, 0.75],
        arrowRot: [90, 15, 0],
        shaftPos: [0, 0, 0.4],
        shaftRot: [90, 15, 0],
        labelPos: [0, 0, 1],
        text: `Z(${this.#coordinate[2].toFixed(3).toString()})`
      }
    }

    return {}
  }

  destroy() {
    this.#label.destroy()
  }
}