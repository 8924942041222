<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33268 3.99992V2.66659H10.666V3.99992H5.33268ZM8.66602 10.6733V5.33325H7.33268L7.33268 10.6733H5.33268L7.99935 13.3333L10.666 10.6733H8.66602Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>