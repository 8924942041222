import { templateService } from '@/_services'
import { AlertService } from '@app/AlertService'
import { config } from '@/_helpers'

function setErrorAlert(e, textError) {
  let resp = e.response
  if (resp && resp.data) {
    resp.data.error_description = textError
  }
  else {
    resp = {
      data: {
        error_description: textError
      }
    }
  }
  AlertService.error(e.response)
}

const getDefaultState = () => {
  return {
    content: null,
    folders: [],
    templates: [],
    projectUuid: '',
    searchText: '',
    suggests:[],
    firstLoad:true,
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    // folders: ({ folders, searchText }) => {
    //   if (searchText.length > 0) {
    //     // console.log(folders.map(el => { el.visible = true; return el }));
    //     // let buf=folders.filter(el => el.uuid!="a")
    //     //  buf=
    //     return folders.map(el => {return {name: el.name, uuid: el.uuid,visible:true}})
    //   }
    //   return folders
    // },

    folders: ({ folders, templates,searchText }) => {
      if (searchText.length > 0) {
        let list = templates.filter(el => el.name.toLowerCase().includes(searchText.toLowerCase()))

        return folders.filter(folder => list.find(temp => temp.folderUuid == folder.uuid))
          .map(el => {
            return {
              name: el.name, uuid: el.uuid,
              visible: true, templates: list.filter(temp => temp.folderUuid == el.uuid)
            }
          })
      }

      return folders.map(el => {
        return {
          name: el.name, uuid: el.uuid,
          visible: el.visible, templates: templates.filter(temp => temp.folderUuid == el.uuid)
        }
      })
    },
    // templates: ({ templates, searchText }) => {
    //   if (searchText.length > 0) {
    //     return templates.filter(el => el.name.toLowerCase().includes(searchText.toLowerCase()))
    //   }

    //   return templates
    // },
  },
  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setContent: (state, payload) => {
      state.content = payload
    },
    setProjectUuid: (state, payload) => {
      state.projectUuid = payload
    },
    setFolderVisible: (state, payload) => {
      // let index = state.folders.findIndex((el) => el.uuid == payload)
      // state.folders[index].visible = !state.folders[index].visible
      let folder = state.folders.find((el) => el.uuid == payload)
      folder.visible = !folder.visible
    },
    updatePreview: (state, payload) => {
      state.templates.find(el => el.uuid == payload).url=config.pluginsUrl
      +`plugins/template/${payload}/preview?access_token=${localStorage.getItem("access_token")}&s=${Math.floor(Math.random() * 50)}`
    },
    // setFolders: (state, payload) => {
    //   state.folders = []
    //   state.folders.push({ name: `Стандартная папка`, uuid: null })
    //   if (!state.foldersMap.find((el) => el.uuid == null))
    //     state.foldersMap.push({ uuid: null, visible: false })
    //   state.folders.push(...payload)
    // },
    setFolders: (state, payload) => {
      let newFolders = []
      newFolders.push({ name: `Стандартная папка`, uuid: null })
      newFolders.push(...payload)

      newFolders.forEach(el => {
        let oldFolder = state.folders.find(element => element.uuid == el.uuid)
        el.visible = oldFolder ?  oldFolder.visible : false
      })
      if(state.firstLoad){
        newFolders[0].visible=true;
        state.firstLoad=false
      }
      state.folders = newFolders
    },
    setTemplates: (state, payload) => {
      payload.forEach(el=>el.url= el.createDate==el.editDate? "/img/common/template_default_preview.png" 
      : config.pluginsUrl+`plugins/template/${el.uuid}/preview?access_token=${localStorage.getItem("access_token")}`)
      state.templates = payload;
    },

    setSuggests: (state, payload) => {
      payload.sort((a,b)=> a.title.localeCompare(b.title))
      const items = [
        { title:"Foreach",value: `#set( $i= 0) \n#foreach( $task in $tasks ) \n#set( $i=$i+1) \n\${task.title| ''} $i \n#foreach( $com in $coms[$task.uuid] ) \n$com  \n#end \n#end`, description:`#set( $i= 0) \n#foreach( $task in $tasks ) \n#set( $i=$i+1) \n\${task.title| ''} $i \n#foreach( $com in $coms[$task.uuid] ) \n$com  \n#end \n#end`},
        { title:"if",value: `#if($i == 1)\n#end`, description:`#if($j == 1)\nВыполняемое действие\n#end`},
        { title:"set",value: `#set( $i = 0 )`, description:`#set( $i = 0 ) - устанавливает значение переменной`},
        
      ]
      state.suggests.push(...payload,...items)
    },
    setSearchText: (state, payload) => {
      state.searchText = payload;
    }
  },
  actions: {
    INIT: ({ state, commit, dispatch }, projectUuid) => {
      commit('setProjectUuid', projectUuid)
      dispatch('loadFolders')
      dispatch('loadTemplates')
      if( state.suggests.length == 0)
        dispatch('loadSuggests')
    },
    initPlugin: ({ commit, dispatch }, projectUuid) => {
      commit('setProjectUuid', projectUuid)
      dispatch('loadTemplates')
    },
    loadFolders: ({ state, commit }) => {
      templateService.getFolders(state.projectUuid).then(data => {
        commit('setFolders', data)
      }).catch(e => {
        // e.response["data"]["error_description"] = "Ошибка при загрузке папок"
        // AlertService.error(e.response)
        setErrorAlert(e, "Ошибка при загрузке папок")
      })
    },
    loadTemplates: ({ state, commit },) => {
      templateService.getTemplates(state.projectUuid).then(data => {
        commit('setTemplates', data)
      }).catch(e => {
        // e.response["data"]["error_description"] = "Ошибка при загрузке шаблонов"
        // AlertService.error(e.response)
        setErrorAlert(e, "Ошибка при загрузке шаблонов")
      })
    },
    loadSuggests: ({ state, commit },) => {
      templateService.getSuggests(state.projectUuid).then(data => {
        commit('setSuggests', data)
      }).catch(e => {
        // e.response["data"]["error_description"] = "Ошибка при загрузке подсказок"
        // AlertService.error(e.response)

        setErrorAlert(e, "Ошибка при загрузке подсказок")
      })
    }
  }
}
