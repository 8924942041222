import { GlMem } from '@utils/mem/mem.js';
import { XeokitMediator } from "./XeokitMediator"

export class GlMemoryInfo {

  static glExt = null;

  static load() {
    GlMem.load()
  }

  static init() {
    this.glExt = XeokitMediator.viewer.scene.canvas.gl.getExtension('GMAN_webgl_memory')
  }

  static showGlInfo() {
    if (this.glExt) console.log(JSON.stringify(this.glExt.getMemoryInfo(), null, 2))
  }
}