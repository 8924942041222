import { defineStore } from "pinia"
import { activateCroppingRectangle,
         deactivateCroppingRectangle,
         cropDrawing,
         activateAnchorPoints,
         setImageSizesToStore,
         deactivateAnchorPoints,
         setCroppingMode,
         isAnchorPointAddingModeActive,
         isAnchorPointsAdded,
         isCroppingModeActive,
         getDrawingPageHeight,
         getDrawingPageWidth,
         firstPointSetted,
         secondPointSetted,
         incorrectAnchorPosition } from "@/components/drawings/facades/drawingCropping.facade"

export const useDrawingCroppingState = defineStore('drawing-cropping-state', {
  getters: {
    height: getDrawingPageHeight ,
    width: getDrawingPageWidth, 
    isCroppingModeActive: () => { return isCroppingModeActive() },
    isAnchorPointsAdded: () => { return isAnchorPointsAdded() },
    isAnchorPointAddingModeActive: () => { return isAnchorPointAddingModeActive() },
    firstPointSetted: () => { return firstPointSetted() },
    secondPointSetted: () => { return secondPointSetted() },
    incorrectAnchorPosition: () => incorrectAnchorPosition()
  }, 

  actions: {
    activateCroppingRectangle(rectangle, layout) {
      activateCroppingRectangle(rectangle, layout)
    },

    deactivateCroppingRectangle() {
      deactivateCroppingRectangle()
    },

    setCroppingMode(value) {
      setCroppingMode(value)
    }, 

    cropDrawing() {
      cropDrawing()
    },

    activateAnchorPoints() {
      activateAnchorPoints()
    },

    setImageSizesToStore() {
      setImageSizesToStore()
    }, 

    deactivateAnchorPoints() {
      deactivateAnchorPoints()
    }
  }
})