export default ({ Factory }) => ({
  
  /**
   * Список ролей
   */
  rolesAll: () => Factory.roles.get(),
  
  /**
   * Создание новой роли
   * @param {Object} role Данные роли
   */
  create: (role) => Factory.roles.post(role),
  
  /**
   * Редактирование данных роли
   * @param {Object} role Данные роли
   */
  edit: (role) => Factory.roles.put(role),
  
  /**
   * Удаление роли
   * @param {Object} id UUID роли
   */
  delete: (id) => Factory.roles[id].delete(),

  /** 
   * Принудительное удаление роли
   * @param {Object} id UUID роли
   */
   forcedDelete: (id) => Factory.roles.used[id].delete(),
  
  /**
   * Список функций роли (упрвление проектом)
   */
  roleFunctionsAll: () => Factory.functions.get(),

  /**
   * Список ролей пользоваля в проекте 
   */
  rolesByProject: (projectId) => Factory.roles[projectId].get(),
  roleTypesByProject: (projectId) => Factory.roles[projectId].types.get(),

  changeRoles: (roleId, toRoleId) => Factory.roles.changes[roleId][toRoleId].patch(),
})