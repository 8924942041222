export { api, RequestConfig } from './api_old'
export { assert } from './assets/utils/Assert'
import { AlertService } from './assets/app/AlertService'
import i18n from '@/plugins/i18n';
import { $_app_server, $_notification_server } from '@/_helpers'
export let ApiServer = $_app_server

export const PresetKey = Object.freeze({
  cloudy: '_cloudy',
  guest: '_guest',
  notify: '_notify',
})

export const ApiPresets = Object.freeze({
  headers: {
    MULTIPART: {
      headers: {'Content-Type': 'multipart/form-data'}
    },
    MERGE_PATCH: {
      headers: {'Content-Type': 'application/merge-patch+json'}
    }
  },
  CLOUDY: { [PresetKey.cloudy]: true },
  GUEST: { [PresetKey.guest]: true },
  NOTIFY: { [PresetKey.notify]: true },
})

let initServer = (config) => {
  let isNotifyRequest = config[PresetKey.notify]
  if (isNotifyRequest) ApiServer = $_notification_server
  else ApiServer = $_app_server
}

let get = (path) => (config = {}) => {
  initServer(config)
  return ApiServer.get(path, config).then(response => response.data)
}
let post = (path) => (data, config = {}) => {
  initServer(config)
  return ApiServer.post(path, data, config).then(response => response.data)
}
let put = (path) => (data, config = {}) => {
  initServer(config)
  return ApiServer.put(path, data, config).then(response => response.data)
}
let patch = (path) => (data, config = {}) => {
  initServer(config)
  return ApiServer.patch(path, data, config).then(response => response.data)
}
let del = (path) => (config = {}) => {
  initServer(config)
  return ApiServer.delete(path, config).then(response => response.data)
}

// $_app_server_noAuth

let factoryHandler = {
  get (target, prop) {
    let path = target.path || ''
    
    if (prop === 'path') return path
    else if (prop == 'get') return get(path)
    else if (prop == 'post') return post(path)
    else if (prop == 'put') return put(path)
    else if (prop == 'patch') return patch(path)
    else if (prop == 'delete') return del(path)

    let next = { path: `${path}/${prop}`}
    if (prop.startsWith('?')) next = { path: `${path}${prop}`}
    
    return new Proxy(next, factoryHandler)
  }
}

export const ApiFactory = new Proxy({}, factoryHandler)

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16)/255,
    parseInt(result[2], 16)/255,
    parseInt(result[3], 16)/255
   ] : [1,1,0];
}

export function byteFormatter(bytes) {
  bytes = bytes || 0
  // TODO Добавить переводы
  // const sizes = ["size.byte", "size.kb", "size.mb", "size.gb", "size.tb"];
  const sizes = ["Б", "КБ", "МБ", "ГБ", "ТБ"];
      
  if (bytes === 0) {
    return "0 Б";
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
}

export function simpleMapper(mappedObject, obj) {
  Object.keys(mappedObject).forEach( function( key ) {
    if(obj[key] !== undefined) {
      if ( typeof obj[key] === 'object' && obj[key]?.uuid) {
        mappedObject[key] = {'uuid' : obj[key].uuid }
      } else 
        mappedObject[key] = obj[key]
    }
  });
}

export class Telemetry {
  static _eventHandlers = {}
  static _socket = null
  
  static startTelemetry(projectUuid) {
    this._socket = new WebSocket(window.settings.telemetry.ws)

    this._socket.onopen = (event) => {
      this.sendMessage({projectUuid})
      this._fire('open', event)
    }

    this._bindSocketEvents()
  }

  static stopTelemetry() {
    this._socket?.close()
  }

  static sendMessage(msg) {
    this._socket?.send(typeof msg === 'object' ? JSON.stringify(msg) : msg)
  }
  
  static _bindSocketEvents() {
    this._socket.onmessage = (event) => {
      try {
        this._fire('update', JSON.parse(event.data))
      }
      catch {} // eslint-disable-line

      this._fire('message', event)
    }

    this._socket.onerror = (event) => {
      this._fire('error', event)
    }

    this._socket.onclose = (event) => {
      this._fire('close', event)
    }
  }

  /** @param {'update' | 'message' | 'error' | 'close' | 'open'} eventName  */
  static _fire(eventName, data) {
    for (let i = 0; i < (this._eventHandlers[eventName]?.length || 0); i++) {
      const handler = this._eventHandlers[eventName][i]
      
      try {
        handler(data)
      } 
      catch (error) {
        console.error(error)
        continue
      }
    }
  }

  /** @param {'update' | 'message' | 'error' | 'close' | 'open'} eventName  */
  static on(eventName, handler) {
    this._eventHandlers[eventName] ||= []
    this._eventHandlers[eventName].push(handler)
  }

  /** @param {'update' | 'message' | 'error' | 'close' | 'open'} eventName  */
  static off(eventName, handler) {
    const handlerIdx = this._eventHandlers[eventName]?.findIndex(h => h === handler) ?? -1
    return handlerIdx >= 0 ? !!this._eventHandlers[eventName]?.splice(handlerIdx, 1).length : false
  }

  static clear() {
    this._eventHandlers = {}
    this.stopTelemetry()
  }
}

export class TelemetrySubscribe {
  
  constructor(projectUuid) {
    this._events = {}
    this.projectUuid = projectUuid
  }

  on(eventName, callback) {
    if (!this._events[eventName]) {
      this._events[eventName] = [];
    }

    this._events[eventName].push(callback);
  }
  
  off(eventName, callback) {
    const eventCallbacks = this._events[eventName];

    if (eventCallbacks) {
      const index = eventCallbacks.indexOf(callback);
      if (index !== -1) {
        eventCallbacks.splice(index, 1);
      }
    }
  }

  #fire(eventName, data) {
    const eventCallbacks = this._events[eventName]

    if (eventCallbacks) {
      eventCallbacks.forEach(callback => {
        callback(data)
      })
    }
  }

  async startTelemetry() {
    this.socket = new WebSocket(window.settings.telemetry.ws)
    
    this.socket.onopen = (event) => {
      const bind = {}
      bind.projectUuid = this.projectUuid

      this.socket.send(JSON.stringify(bind))

      this.#fire('open', event)
    }

    this.socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        this.#fire('update', data)
      }
      catch {
        console.log('not json data')
      }
    }

    this.socket.onerror = (event) => {
      this.#fire('error', event)
    }

    this.socket.onclose = (event) => {
      console.log('socket close: ' + event.code)
      this.#fire('close', event)
    }
  }

  stopTelemetry() {
    this.socket?.close()
  }

  sendMessage(msg) {
    this.socket?.send(typeof msg === 'object' ? JSON.stringify(msg) : msg)
  }
}

export function isJSON(string) {
  try {
    JSON.parse(string)
    return true
  } 
  catch (error) {
    return false
  }
}

export function fileInput(cfg = {}) {
  return new Promise((resolve) => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'

    fileInput.multiple = cfg?.multiple ?? false
    fileInput.accept = cfg?.accept ?? '*/*'

    fileInput.onclick = () => {
      window.addEventListener('focus', () => {
        setTimeout(() => {
          const files = fileInput.files
          resolve(fileInput.multiple ? files : ((files && files[0]) || null))
        }, 100)
      }, { once: true })
    }

    fileInput.click()
  })
}

export function readFileAsText(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    
    fileReader.onloadend = () => resolve(fileReader.result)
    fileReader.onerror = reject
    
    fileReader.readAsText(file)
  })
}

export async function createFile (path, name, type) {
  let response = await fetch(path);
  let data = await response.blob();
  let metadata = {
      type: type
  };
  return new File([data], name, metadata);
}

export function copyTextToBuffer (text) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(function() {
        AlertService.success({ info: i18n.t("copyBuffer.success") });
    }, function() {
      AlertService.error({data: {error_description: i18n.t("copyBuffer.error") }});
    });
  } 
  else {
    AlertService.warning({ info: i18n.t("copyBuffer.warning")  });
  }
}
