<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.14 0C1.57244 0 1.02812 0.225464 0.626791 0.626791C0.225464 1.02812 0 1.57244 0 2.14V4H2V2H4V0H2.14ZM6 0V2H9V0H6ZM11 0V2H13V4H15V2.14C15 0.96 14.04 0 12.86 0H11ZM0 6V9H2V6H0ZM8 6C6.89 6 6 6.89 6 8C6 10.33 6 15 6 15C6 15 10.57 15 12.86 15C13.4276 15 13.9719 14.7745 14.3732 14.3732C14.7745 13.9719 15 13.4276 15 12.86C15 10.57 15 6 15 6C15 6 10.33 6 8 6ZM17 6V8H19V10H21V8C21 6.89 20.11 6 19 6H17ZM8 8H13V13H8V8ZM0 11V12.86C0 14.04 0.96 15 2.14 15H4V13H2V11H0ZM19 12V15H21V12H19ZM6 17V19C6 20.11 6.89 21 8 21H10V19H8V17H6ZM19 17V19H17V21H19C20.11 21 21 20.11 21 19V17H19ZM12 19V21H15V19H12Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>