import { buildSphereGeometry, EmphasisMaterial, Mesh, PhongMaterial, VBOGeometry, math } from "@xeokit/xeokit-sdk";

const NO_COLOR = [0, 0, 0]
export class SpherePin {

  #scene; #pin;
  #position; #scale; #quaternion; #matrix; #origin;
  #visible; #color;

  constructor(scene, cfg) {
    this.#scene = scene

    this.#pin = null
    this.#position = cfg.position ?? [0, 0, 0]
    this.#scale = cfg.scale ?? [1, 1, 1]
    this.#quaternion = [0, 0, 0, 1]
    this.#origin = cfg.origin ?? [0, 0, 0]

    this.#visible = cfg.visible ?? true
    this.#color = cfg.color ?? [1.0, 0.0, 1.0]

    this.#create()
  }

  #create() {
    const geometry = new VBOGeometry(this.#scene, buildSphereGeometry({
      radius: 0.12,
    }))

    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255]
    const material = new PhongMaterial(this.#scene, {
      emissive: NO_COLOR,
      diffuse: NO_COLOR,
      ambient: NO_COLOR,
      alpha: 0
    })

    const selectedMaterial = new EmphasisMaterial(this.#scene, {
      edges: false,
      fill: true,
      fillColor: color,
      fillAlpha: 1,
      backfaces: false,
      glowThrough: true
    })

    this.#pin = new Mesh(this.#scene, {
      geometry: geometry,
      material: material,
      selected: true,
      selectedMaterial: selectedMaterial,
      visible: this.#visible,
      origin: this.#origin
    })
  }

  setVisible(visible) {
    this.#visible = !!visible
    this.#pin.visible = this.#visible
  }

  update(transform) {
    const origin = transform.position ?? this.#origin
    const position = [0, 0, 0]
    const scale = transform.scale ?? this.#scale
    const quaternion = this.#quaternion

    this.#origin = origin
    this.#position = position
    this.#scale = scale

    this.#matrix = math.composeMat4(position, quaternion, scale)
    this.#pin.matrix = this.#matrix
    this.#pin.origin = this.#origin
  }

  destroy() {
    this.#pin?.destroy()
    this.#pin = null
  }
}