<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H13V2H9H0V0ZM0 4H13V6H0V4ZM0 8H9V10H0V8Z" fill="currentColor" />
    <path d="M11 14L16 11L11 8V14Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>