export class SmetaUtils {
  static flatlist (tree) {
    let array = []
    tree.forEach(node => {
      array = array.concat([node], SmetaUtils.flatlist(node.children))
      // array.push(node, ...SmetaUtils.flatlist(node.children))
    })
    return array
  }

  static flatListRules (tree) {
    let array = []
    tree.forEach(node => {
      // if (node.hasRules) array.push(node, ...SmetaUtils.flatListRules(node.children))
      if (node.hasRules) array = array.concat([node], SmetaUtils.flatListRules(node.children))
    })
    return array
  }

  static flatlistReducer (list, tree) {
    for (const node of tree) {
      list.push(node, ...SmetaUtils.flatlist(node.children))
    }
    return list
  }
  
  static onlyNodesReducer(list, node) {
    if (node.children.length) {
      list.push(node)
      node.children.reduce(SmetaUtils.onlyNodesReducer, list)
    }
    return list
  }
  
  static onlyEndpointsReducer(list, node) {
    if (node.children.length) node.children.reduce(SmetaUtils.onlyEndpointsReducer, list)
    else list.push(node)
    return list
  }
}