<template lang="pug">
  .themes-root
    v-btn(:class="{ 'selected': theme.name === 'light' }" icon small @click="setSelectedTheme('light')")
      v-icon(color='#757575') mdi-white-balance-sunny
    v-btn(:class="{ 'selected': theme.name === 'grey' }" icon small @click="setSelectedTheme('grey')")
      v-icon(color='#757575') mdi-theme-light-dark
    v-btn(:class="{ 'selected': theme.name === 'dark' }" icon small @click="setSelectedTheme('dark')")
      v-icon(color='#757575') mdi-weather-night
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: "app-themes",
  
  computed: {
    ...mapState('theme', ['theme']),
  },

  methods: {
    ...mapMutations('theme', ['setTheme']),

    setSelectedTheme(name) {
      this._setTheme(name)
    }
  }
}
</script>

<style scoped>
  .themes-root {
    display: flex;
    gap: 16px;
  }
  
  .selected {
    background: #DEDEDE;
  }
</style>