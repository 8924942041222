import { geometry } from "../../../xeokit/plugins/geometry/geometry";
import { XeokitMediator } from "../../../xeokit/XeokitMediator";
import { math } from "@xeokit/xeokit-sdk";
import { Mesh } from "@xeokit/xeokit-sdk";
import { ReadableGeometry } from "@xeokit/xeokit-sdk";
import { buildBoxGeometry } from "@xeokit/xeokit-sdk";
import { PhongMaterial } from "@xeokit/xeokit-sdk";
import { EdgeMaterial } from "@xeokit/xeokit-sdk";
import { buildSphereGeometry } from "@xeokit/xeokit-sdk";
import { HighlightFrame } from "./highlightFrame";
import store from '@/store';

/*eslint-disable no-dupe-class-members*/
export class SelectionFrame {

  static #_highlightFrame = null

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get #_camera() {
    return XeokitMediator.viewer.camera
  }

  static activate() {
    
    // this.debugElementsHighlightFrame()
    this.#_highlightFrame = new HighlightFrame((startCanvasPos, endCanvasPos, isShiftKeyPressed) =>
      this.#_selectElementsInFrame(startCanvasPos, endCanvasPos, isShiftKeyPressed))

    this.#_highlightFrame.activate()
  }

  static #_selectElementsInFrame(startCanvasPos, endCanvasPos, isShiftKeyPressed) {
    if (startCanvasPos == null || endCanvasPos == null) {
      // XeokitMediator.ElementsSelection.selectElements([])
      return
    }
    else if (startCanvasPos[0] === endCanvasPos[0] || startCanvasPos[1] === endCanvasPos[1]) {
      // XeokitMediator.ElementsSelection.selectElements([])
      return
    }

    let minMaxCoords = geometry.utils.getMinMaxCoords([startCanvasPos, endCanvasPos])
    startCanvasPos = minMaxCoords[0]
    endCanvasPos = minMaxCoords[1]

    const canvas = this.#_scene.canvas.canvas;
    const canvasWidth = canvas.clientWidth;
    const canvasHeight = canvas.clientHeight;
    const xCanvasToClip = 2.0 / canvasWidth;
    const yCanvasToClip = 2.0 / canvasHeight;
    let heightRatio = 0
    let widthRatio = 0
    let ratio = 0
    let near = 0
    let nearKoeff = 17.25
    let equation = 0
    ratio = canvas.clientHeight / canvas.clientWidth;
    heightRatio = canvas.clientHeight / canvas.clientWidth;
    widthRatio = (canvas.clientWidth / canvas.clientHeight);
    if (widthRatio > heightRatio) {
      widthRatio = 1
      ratio = heightRatio
      near = nearKoeff * this.#_camera._frustum.near * ratio
    }
    else {
      heightRatio = 1
      ratio = widthRatio
      if (ratio >= 0.5) {
        // log
        equation = 5 * Math.log10(widthRatio) - 1.9 * widthRatio + 3.624
        near = ratio * equation
      }
      else {
        // ax2 + bx + c
        equation = 11.73333333 * widthRatio * widthRatio - 13.46 * widthRatio + 4.95666667
        near = ratio * equation
      }
    }
    
    let marquee = []
    marquee[0] = Math.min(startCanvasPos[0], endCanvasPos[0]);
    marquee[1] = Math.min(startCanvasPos[1], endCanvasPos[1]);
    marquee[2] = Math.max(startCanvasPos[0], endCanvasPos[0]);
    marquee[3] = Math.max(startCanvasPos[1], endCanvasPos[1]);

    const left = marquee[0] * xCanvasToClip -1;
    const right = marquee[2] * xCanvasToClip -1;
    const bottom = -marquee[3] * yCanvasToClip + 1;
    const top = -marquee[1] * yCanvasToClip + 1;

    let subFrustumMat

    if (XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.ORTHO) {
      subFrustumMat = math.orthoMat4c(
        left * this.#_camera.ortho.scale * 0.5 * widthRatio,
        right * this.#_camera.ortho.scale * 0.5 * widthRatio,
        bottom * this.#_camera.ortho.scale * 0.5 * heightRatio,
        top * this.#_camera.ortho.scale * 0.5 * heightRatio,
        1,
        20000,
        math.mat4()
      )
    }
    else {
      subFrustumMat = geometry.math.frustumMat4(
        left * widthRatio,
        right * widthRatio,
        bottom * heightRatio,
        top * heightRatio,
        near,
        20000,
        math.mat4(),
      )
    }
    let mSubFrustum = geometry.frustum.createXeokitFrustum()
    geometry.frustum.setFrustum(mSubFrustum, this.#_camera.viewMatrix, subFrustumMat)

    let selectedEntityId = []
    
    if (isShiftKeyPressed) {
      for (let entity of Object.values(this.#_scene.objects)) {
        if (entity._numTriangles != 0 && entity.pickable && entity.visible) {
          let res = geometry.frustum.frustumIntersectsAABB3(mSubFrustum, entity.aabb)
          if (res == geometry.frustum.INSIDE) {
            selectedEntityId.push(entity.id)
          }
          else if (res == geometry.frustum.INTERSECT && geometry.frustum.isEntityInsideFrustum(entity, mSubFrustum)) {
            selectedEntityId.push(entity.id)
          }
        }
      }
    }
    else {
      for (let entity of Object.values(this.#_scene.objects)) {
        if (entity._numTriangles != 0 && entity.pickable && entity.visible) {
          let res = geometry.frustum.frustumIntersectsAABB3(mSubFrustum, entity.aabb)
          if (res == geometry.frustum.INSIDE) {
            selectedEntityId.push(entity.id)
          }
          else if (res == geometry.frustum.INTERSECT && geometry.frustum.isEntityIntersectFrustum(entity, startCanvasPos, endCanvasPos, mSubFrustum)) {
            selectedEntityId.push(entity.id)
          }
        }
      }
    }

    XeokitMediator.ElementsSelection.selectElements(selectedEntityId)

    if(XeokitMediator.ElementsSelection.selectedElements < 1) {
      store.dispatch('axis/tree/deselectAll')
      // MARK: [RightElementTree]
      // TODO: Требуется рефактор
      if (window?.settings?.rework?.elementTree || false) {
        store.dispatch('right-axis/tree/deselectAll')
      }
    }
    else {
      store.dispatch('axis/tree/selectNodesBySelectionFrame', selectedEntityId)
      // MARK: [RightElementTree]
      // TODO: Требуется рефактор
      if (window?.settings?.rework?.elementTree || false) {
        store.dispatch('right-axis/tree/selectNodesBySelectionFrame', selectedEntityId)
      }
    }
  }

  static deactivate() {
    this.#_highlightFrame.deactivate()
  }

  //-------------------------------- DEBUG methods -----------------------------------------------------

  static debugElementsHighlightFrame() {
    // Отобразить AABB всех элементов
    this.viewAABBs(Object.values(XeokitMediator.viewer.scene.objects).map(o => o.aabb))

    // Отобразить треугольники 
    Object.values(XeokitMediator.viewer.scene.objects).forEach(obj => {
      obj.meshes.forEach(mesh => {
        let trianglesCoords = geometry.extraction.getFacesVerticeByEntity(mesh)
        for (let i = 0; i < trianglesCoords.length - 2; i+=3) {
          let t1 = trianglesCoords[i]
          let t2 = trianglesCoords[(i + 1) % trianglesCoords.length] 
          let t3 = trianglesCoords[(i + 2) % trianglesCoords.length]

          var rayHelper = new (function () {

            var ray = new Mesh(XeokitMediator.viewer.scene, {
              geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
                primitive: "lines",
                positions: [0, 0, 0, 0, 0, 0],
                indices: [0, 1]
              }),
              material: new PhongMaterial(XeokitMediator.viewer.scene, {
                emissive: [1, 0.3, 0.3],
                diffuse: [0, 0, 0],
                ambient: [0, 0, 0],
                lineWidth: 2
              }),
              pickable: false
            });

            var rayPositions = new Float32Array(6);

            this.setRay = function (pos, dir) {

              rayPositions[0] = pos[0]; // Origin
              rayPositions[1] = pos[1];
              rayPositions[2] = pos[2];
              rayPositions[3] = dir[0]; // Direction
              rayPositions[4] = dir[1];
              rayPositions[5] = dir[2];

              ray.geometry.positions = rayPositions;
            };

            this.setHighlighted = function (highlighted) {
              ray.material.emissive = highlighted ? [0.5, 1.0, 0.5] : [0.5, 0.3, 0.3];
              ray.material.lineWidth = highlighted ? 6 : 2;
            };
          })();
          var rayHelper1 = new (function () {

            var ray = new Mesh(XeokitMediator.viewer.scene, {
              geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
                primitive: "lines",
                positions: [0, 0, 0, 0, 0, 0],
                indices: [0, 1]
              }),
              material: new PhongMaterial(XeokitMediator.viewer.scene, {
                emissive: [1, 0.3, 0.3],
                diffuse: [0, 0, 0],
                ambient: [0, 0, 0],
                lineWidth: 2
              }),
              pickable: false
            });

            var rayPositions = new Float32Array(6);

            this.setRay = function (pos, dir) {

              rayPositions[0] = pos[0]; // Origin
              rayPositions[1] = pos[1];
              rayPositions[2] = pos[2];
              rayPositions[3] = dir[0]; // Direction
              rayPositions[4] = dir[1];
              rayPositions[5] = dir[2];

              ray.geometry.positions = rayPositions;
            };

            this.setHighlighted = function (highlighted) {
              ray.material.emissive = highlighted ? [0.5, 1.0, 0.5] : [0.5, 0.3, 0.3];
              ray.material.lineWidth = highlighted ? 6 : 2;
            };
          })();
          var rayHelper2 = new (function () {

            var ray = new Mesh(XeokitMediator.viewer.scene, {
              geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
                primitive: "lines",
                positions: [0, 0, 0, 0, 0, 0],
                indices: [0, 1]
              }),
              material: new PhongMaterial(XeokitMediator.viewer.scene, {
                emissive: [1, 0.3, 0.3],
                diffuse: [0, 0, 0],
                ambient: [0, 0, 0],
                lineWidth: 2
              }),
              pickable: false
            });

            var rayPositions = new Float32Array(6);

            this.setRay = function (pos, dir) {

              rayPositions[0] = pos[0]; // Origin
              rayPositions[1] = pos[1];
              rayPositions[2] = pos[2];
              rayPositions[3] = dir[0]; // Direction
              rayPositions[4] = dir[1];
              rayPositions[5] = dir[2];

              ray.geometry.positions = rayPositions;
            };

            this.setHighlighted = function (highlighted) {
              ray.material.emissive = highlighted ? [0.5, 1.0, 0.5] : [0.5, 0.3, 0.3];
              ray.material.lineWidth = highlighted ? 6 : 2;
            };
          })();

          //------------------------------------------------------------------------------------------------------------------
          // Dynamically pick surfaces of model with the ray, show ray with helper, highlight picked meshes
          //------------------------------------------------------------------------------------------------------------------

          var rayOrigin = new Float32Array([t1[0], t1[1], t1[2]]);
          var rayDirection = new Float32Array([t2[0], t2[1], t2[2]]);
          rayHelper.setRay(rayOrigin, rayDirection);
          rayHelper.setHighlighted(true);
          rayOrigin = new Float32Array([t2[0], t2[1], t2[2]]);
          rayDirection = new Float32Array([t3[0], t3[1], t3[2]]);
          rayHelper1.setRay(rayOrigin, rayDirection);
          rayHelper1.setHighlighted(true);
          rayOrigin = new Float32Array([t3[0], t3[1], t3[2]]);
          rayDirection = new Float32Array([t1[0], t1[1], t1[2]]);
          rayHelper2.setRay(rayOrigin, rayDirection);
          rayHelper2.setHighlighted(true);
        }
      })
    })

  }

  static viewAABBs(aabbs) {
    const _getLengthByCoords = (min, max) => {
      return Math.sqrt(Math.pow(max - min, 2))
    }

    for (let i = 0; i < aabbs.length; i++) {
      const [xmin, ymin, zmin, xmax, ymax, zmax] = aabbs[i]

      let DA = _getLengthByCoords(xmin, xmax) / 2 || 0.005
      let DB = _getLengthByCoords(ymin, ymax) / 2 || 0.005
      let DC = _getLengthByCoords(zmin, zmax) / 2 || 0.005

      new Mesh(XeokitMediator.viewer.scene, {
        geometry: new ReadableGeometry(XeokitMediator.viewer.scene, buildBoxGeometry({
          xSize: DA, ySize: DB, zSize: DC,
          center: math.getAABB3Center(aabbs[i]),
        })),

        clippable: false,

        material: new PhongMaterial(XeokitMediator.viewer.scene, {
          diffuse: [0.4, 0.4, 1.0],
          ambient: [0.9, 0.3, 0.9],
          shininess: 30,
          alpha: .2,
          alphaMode: "blend",
        }),

        edgeMaterial: new EdgeMaterial(XeokitMediator.viewer.scene, {
          edgeColor: [0.23137254901960785, 0.5764705882352941, 0.6862745098039216],
          edgeAlpha: 1.0,
          edgeWidth: 1,
        }),

        edges: true,
        pickable: false,
        collidable: false
      })
    }
  }

  drawPoint(xyz) {
    new Mesh(XeokitMediator.viewer.scene, {
      geometry: new ReadableGeometry(XeokitMediator.viewer.scene, buildSphereGeometry({
        center: xyz || [0, 0, 0],
        // radius: this._cube ? math.getAABB3Diag(this._cube.aabb) / 100 : .2,
        radius: 0.1 || math.getAABB3Diag([...XeokitMediator.viewer.scene.camera.eye, ...math.getAABB3Center(XeokitMediator.viewer.scene.aabb)]) / 100,
        heightSegments: 60,
        widthSegments: 60
      })),

      material: new PhongMaterial(XeokitMediator.viewer.scene, {
        diffuse: [1, 0, 1]
      }),

      clippable: false,
      collidable: false
    })
  }

}