export class TokenDto {
  constructor (obj = {}) {
    this.uuid = obj.uuid
    this.project = obj.project
    this.discribe = obj.discribe
    this.token = obj.token
    this.expiredDate = obj.expiredDate
    this.level = obj.level
  }

  expiredToken () {
    let has = false
    
    return has
  }

  clone () {
    let json = JSON.parse(JSON.stringify(this))
    return new TokenDto(json)
  }

  static compareByExpired (a, b) {
    return b.expiredDate - a.expiredDate
  }
}

// * Вспомогательные функции

/*function _parseStringToDate (str) {
  if (!str || typeof str !== 'string') return null

  let [dateStr, timeStr] = str.split(' ')
  let date = dateStr && new Date(dateStr)

  if (date && timeStr) {
    let [hours, minutes] = timeStr.split(':')
    date.setHours(+hours, +minutes)
  }

  return date
}*/

