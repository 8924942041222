//import { authUserService } from "../_services"
import { api } from '@/api'

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

export default {
  namespaced: true,
  state: {
    listProjects:  [],
    
    searchText: '',

    tariffList: {
      list: [],
      status: status.INITIAL
    },

    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 10,
      sortBy: 'title',
      itemsPerPage: [10, 25, 50, 100],
      totalItems: 0,
    },

    selectedFilter: {title: "активные", value: 0}
  },

  getters: {
    projects: state => state.listProjects.sort((a, b) => a.title.localeCompare(b.title)),
    totalItems: state => state.pagination.totalItems,
    itemsPerPage: state => state.pagination.itemsPerPage,
    rowsPerPage: state => state.pagination.rowsPerPage,

    tariffList: state => {
      return state.tariffList.list
    },

    isTariffListLoaded: ({ tariffList }) => tariffList.status == status.LOADED,

    filterProjects: state => {
      let res = { 
        search: state.searchText, 
        filtered: state.selectedFilter ? state.selectedFilter.value : null,  
        page: state.pagination.page, 
        pageSize: state.pagination.rowsPerPage }
      return res
    },
  },

  mutations: {
    SET_ITEMS_PER_PAGE: (state, number) => { 
      state.pagination.rowsPerPage = number 
    },
    SET_PAGE: (state, number) => { 
      state.pagination.page= number 
    },

    SET_TOTAL_ELEMENTS_COUNT: (state, number) => { 
      state.pagination.totalItems = number 
    },

    SET_PROJECTS: (state, projects) => {
      state.listProjects = projects
    },

    SET_FILTER: (state, filter) => {
      state.selectedFilter = filter
    },
    SET_SEARCH_TEXT: (state, text) => {
      state.searchText = text
    },
    
  },
  
  actions: {
    INIT: ({ dispatch }) => {
      dispatch('LOAD_PROJECTS')
    },

    LOAD_PROJECTS: ({ commit, getters }) => {
      api.adminProjects.searchProjects(getters.filterProjects).then(data => {
        commit('SET_PROJECTS', data.content)
        commit('SET_TOTAL_ELEMENTS_COUNT', data.totalElements)
      })  
    },

    loadNextPage: ({ commit, dispatch }, {pageNumber, itemsPerPage}) => {
      commit('SET_ITEMS_PER_PAGE', itemsPerPage);
      commit('SET_PAGE', pageNumber);
      dispatch('LOAD_PROJECTS')
    },

    changeFilter: ({ commit, dispatch }, filter) => {
      commit('SET_FILTER', filter)
      commit('SET_PAGE', 1);
      dispatch('LOAD_PROJECTS')
    },

    refreshList:({ dispatch }) => {
      dispatch('LOAD_PROJECTS')
    },

    searchProjects:({ commit, dispatch }, searchText) => {
      commit('SET_PAGE', 1);
      commit('SET_SEARCH_TEXT', searchText);
      dispatch('LOAD_PROJECTS')
    },

    clearRevisions:({dispatch}) => {
      api.adminProjects.clearRevisions().then(() => {
        dispatch('LOAD_PROJECTS')
      })
    },

    clearCache: () => {
      api.adminProjects.clearCache()
    },
  }
}