<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21 16L25.3301 18.5V23.5L21 26L16.6699 23.5V18.5L21 16Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13 18V12H19V13H14.0105V18H13Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0002 29H13.0002V23H14.0002V27.9895H19.0002V29Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12H29V18H28V13.0105H23V12Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M28.9709 22.9711L29.0288 28.9709L23.0291 29.0288L23.0195 28.0289L28.0088 27.9807L27.9604 22.9809L28.9709 22.9711Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3H27V11H26V4H4V20H12V24H8V23H11V21H3V3Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {}
</script>