<template>
  <svg
    width="38"
    height="37"
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.2318 6.20976C29.2318 6.20976 28.0492 30.1748 24.8281 27.8033L17.3743 22.3157L9.52746 16.5387C6.30621 14.1671 29.2318 6.20976 29.2318 6.20976Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0533 17.5736C18.8675 19.1841 15.9032 23.2106 14.7174 24.8212C14.7174 24.8212 8.49289 22.1013 6.41779 24.9198C5.23202 26.5303 3.45345 28.9463 2.56411 30.1542C0.467915 33.0013 4.51552 35.9371 6.59057 33.1185C8.66561 30.3 10.2096 23.9861 10.2096 23.9861"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>