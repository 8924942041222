<template>
  <svg
    width="28"
    height="28"
    viewBox="2 2 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6715_75)">
      <path
        d="M6.1077 10.2887L16 4.57735L25.8923 10.2887V21.7113L16 27.4227L6.1077 21.7113V10.2887Z"
        stroke="currentColor"
      />
      <path d="M6 10.5L16 16M16 16V27.5M16 16L26 10.5" stroke="currentColor" />
      <path d="M6 21.5V10.5L16 16V27.5L6 21.5Z" fill="currentColor" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>