export default ({ Factory }) => ({

  list:() => Factory.smetadictionary.list.get(),
  types:() => Factory.smetadictionary.types.get(),

  createSmetaDictionary(smetaDictionary) {
    return Factory.smetadictionary.create.post(smetaDictionary)
  },

  createSmetaDictionaryVersion(smetaDictionaryUuid, smetaDictionaryVersion) {
    return Factory.smetadictionary[smetaDictionaryUuid].addversion.post(smetaDictionaryVersion)
  },

  addSmetaDictionaryVersionFiles(smetaDictionaryVersionUuid, files) {
    return Factory.smetadictionary.version[smetaDictionaryVersionUuid].addfile.post(files)
  }
})