/* eslint-disable */
import { XeokitMediator } from "./XeokitMediator"
import { FastNavPlugin } from "./plugins/fastNavPlugin"

const defaultFastNavPluginCfg = {
  hideEdges: true,
  hideSAO: true,
  hideColorTexture: true,
  hidePBR: true,
  hideTransparentObjects: true,

  scaleCanvasResolution: true,
  scaleCanvasResolutionFactor: 0.5,
  
  delayBeforeRestore: true,
  delayBeforeRestoreSeconds: 0.5,
}

class ModelQuality {
  /**
   * @type {FastNavPlugin}
   */
  static #_fastNavPlugin = null

  static #_antialias
  static #_saoEnabled
  static #_edgesEnabled

  static #_switchToHighQualitySubId

  /**
   * @param {Object} quality 
   * @param {Boolean} [quality.edges] 
   * @param {Boolean} [quality.sao]
   * @param {Boolean} [quality.fastNav]
   * @param {Boolean} [quality.antialias]
   */
  static setModelQuality(quality) {
    this.#_saoEnabled = quality.sao
    this.#_edgesEnabled = quality.edges
    this.#_antialias = quality.antialias

    const currentAntialias = this.#_switchAntialias()
    this.#_switchToCurrentQuality()

    quality.fastNav ? this.#_activateFastNavPlugin() : this.#_deactivateFastNavPlugin()
    
    if (!this.#_switchToHighQualitySubId && this.#_fastNavPlugin) {
      this.#_switchToHighQualitySubId = this.#_fastNavPlugin.on('switchToHighQuality', () => {
        this.#_switchToCurrentQuality()
      })
    }

    return {
      ...quality,
      antialias: currentAntialias,
    }
  }

  static #_activateFastNavPlugin() {
    !this.#_fastNavPlugin && (this.#_fastNavPlugin = new FastNavPlugin(XeokitMediator.viewer, defaultFastNavPluginCfg))
  }

  static #_deactivateFastNavPlugin() {
    this.#_fastNavPlugin && this.#_fastNavPlugin.destroy()
    this.#_fastNavPlugin = null
    this.#_switchToHighQualitySubId = null
    this.#_switchToCurrentQuality()
  }

  static #_switchToCurrentQuality() {
    this.#_viewer.scene.canvas.resolutionScale = 1
    
    this.#_viewer.scene._renderer.setSAOEnabled(this.#_saoEnabled)
    this.#_viewer.scene._renderer.setEdgesEnabled(this.#_edgesEnabled)
  }
  
  static #_switchAntialias() {
    const glContextAttributes = this.#_viewer.scene.canvas.gl.getContextAttributes()
    glContextAttributes.antialias = this.#_antialias
    this.#_viewer.scene.canvas.gl = this.#_viewer.scene.canvas.canvas.getContext('webgl2', glContextAttributes)
    return this.#_viewer.scene.canvas.gl.getContextAttributes().antialias
  }

  static get #_viewer() {
    return XeokitMediator.viewer
  }
}

export { ModelQuality }

