import { defineStore } from 'pinia'

export const useSelectionFrameStore = defineStore('elementsHighlightFrame', {
    state: () => {
        return {
            isSelectionFrameActive: false
        }
    },

    actions: {
        setSelectionFrameStatus(activityStatus) {
            this.isSelectionFrameActive = activityStatus
        }
    },
})