import { $_telemetry_server } from "@/_helpers";

export const telemetryService = {
  saveMnemoschema,
  getMnemoschemas,
  deleteMnemoschema,
  editMnemoschema,
  getReceivers,
  getReceiversWithChannels,
  createReceiver,
  deleteReceiver,
  getChannels,
  createChannel,
  deleteChannel,
  createChannelData,
  getTData,
  getReceiverClassList,
  getRoundTypeList,
  getConvertTypeList,

  initHome,
  refreshDevices,
  getMnemoschema,
  getConverterList,
  getEquipmentChannels,
}

function saveMnemoschema(schema) {
  return $_telemetry_server.post('mnemoschemes', schema)
}

function getMnemoschemas(projectUuid) {
  return $_telemetry_server.get(`projects/${projectUuid}/mnemoschemes`)
}

function deleteMnemoschema(schemaUuid) {
  return $_telemetry_server.delete(`mnemoschemes/${schemaUuid}`)
}

function editMnemoschema(schema) {
  return $_telemetry_server.put('mnemoschemes', schema)
}

function getReceivers(projectUuid) {
  return $_telemetry_server.get(`projects/${projectUuid}/receivers`)
}

function getReceiversWithChannels(projectUuid) {
  return $_telemetry_server.get(`projects/${projectUuid}/channels`)
}

function createReceiver(receiver) {
  return $_telemetry_server.post('receivers', receiver)
}

function deleteReceiver(receiverUuid) {
  return $_telemetry_server.delete(`receivers/${receiverUuid}`)
}

function getChannels(receiverUuid) {
  return $_telemetry_server.get(`receivers/${receiverUuid}/channels`)
}

function createChannel(channel) {
  return $_telemetry_server.post(`channels`, channel)
}

function deleteChannel(channelUuid) {
  return $_telemetry_server.delete(`channels/${channelUuid}`)
}

/** @deprecated */
function createChannelData(TData) {
  return $_telemetry_server.post('tdata', TData)
}

/** @deprecated */
function getTData(channelUuid) {
  return $_telemetry_server.get(`channels/${channelUuid}/telemetry`)
}

function getRoundTypeList () {
  return $_telemetry_server.get('roundtype').then(({data})=> data)
}

function getConvertTypeList () {
  return $_telemetry_server.get('converttype').then(({data})=> data)
}

function getReceiverClassList() {
  return $_telemetry_server.get('receiverclass')
}

/** @deprecated */
function initHome(projectUuid) {
  return $_telemetry_server.get(`init/${projectUuid}`)
}

/** @deprecated */
function refreshDevices(projectUuid) {
  return $_telemetry_server.get(`refresh/${projectUuid}`)
}

function getMnemoschema(mnemoschemaUuid) {
  return $_telemetry_server.get(`mnemoschemes/${mnemoschemaUuid}`)
}

function getConverterList() {
  return $_telemetry_server.get('receiverconverter').then(({data}) => data)
}

function getEquipmentChannels(equipmentUuid) {
  return $_telemetry_server.get(`/equipment/${equipmentUuid}/channels`).then(({data}) => data)
}