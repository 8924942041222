export default ({Factory, Presets}) => ({
  
  addMaterial: (material) => {
    let formData = new FormData()
    let params = { title: material.title, discribe: material.discribe } 
    formData.append("file", material.file)
    formData.append("title", material.title)
    formData.append("discribe", material.discribe)
    return Factory.materials[material.modelUuid].post(formData, params, Presets.headers.MULTIPART)
  },

  getModelMaterials: (modelUuid) => {
    return Factory.materials[modelUuid].get()
  },

  deleteMaterial: (modelUuid, materialUuid) => {
    return Factory.materials[modelUuid][materialUuid].delete()
  },

  downloadMaterial: (materialUuid) => {
    return Factory.materials.materialFile[materialUuid].get()
  }
  
})
