import { SectionPlanesPlugin } from "@xeokit/xeokit-sdk";
import { XeokitControl } from "./XeokitControl"
//import {Control} from "@xeokit/xeokit-sdk/src/plugins/SectionPlanesPlugin/Control";

class XeokitSectionPlanesPlugin extends SectionPlanesPlugin {

    constructor (viewer, cfg = {}) {
        super(viewer, cfg)
    }

    _sectionPlaneCreated(sectionPlane) {
        sectionPlane.isSectionPlane = true
        const control = new XeokitControl(this);
        control._setSectionPlane(sectionPlane);
        control.setVisible(false);
        this._controls[sectionPlane.id] = control;
        if (this._overview) {
            this._overview.addSectionPlane(sectionPlane);
        }
        sectionPlane.once("destroyed", () => {
            this._sectionPlaneDestroyed(sectionPlane);
        });
    }

    _sectionPlaneDestroyed(sectionPlane) {
        if (this._overview) {
            this._overview.removeSectionPlane(sectionPlane);
        }
        const control = this._controls[sectionPlane.id];
        if (!control) {
            return;
        }
        control.setVisible(false);
        control._setSectionPlane(null);
        this._controls[sectionPlane.id]._destroy()
        // this._freeControls.push(control);
    }
}
export { XeokitSectionPlanesPlugin }