import { math } from "@xeokit/xeokit-sdk"
import { geometry } from "./geometry" 

export const edgeToEdgeMeasurement = {

  findShortestSegmentBetweenSegments(segment11, segment22) {
    const segment1 = new Array(...segment11)
    const segment2 = new Array(...segment22)

    let tempVec3a = math.vec3()
    let tempVec3b = math.vec3()
    let tempVec3c = math.vec3()
    let tempVec3d = math.vec3()

    // Расчет знаменателя
    let A = math.vec3()
    let B = math.vec3()
    math.subVec3(segment1[1], segment1[0], A)
    math.subVec3(segment2[1], segment2[0], B)
    let magA = math.lenVec3(A)
    let magB = math.lenVec3(B)

    let _A = math.vec3()
    let _B = math.vec3()
    math.mulVec3Scalar(A, 1 / magA, _A)
    math.mulVec3Scalar(B, 1 / magB, _B)

    let cross = math.vec3()
    math.cross3Vec3(_A, _B, cross)
    let denom = math.lenVec3(cross)
    denom *= denom
    // Если линии параллельны (знаменатель 0), проверить лежал ли линии друг на друге
    // Если это не так, то решение существует
    // Если это так, то решение 0
    if (denom < 0.00000001) {
      math.subVec3(segment2[0], segment1[0], tempVec3a)
      math.subVec3(segment2[1], segment1[0], tempVec3b)
      math.subVec3(segment2[0], segment1[1], tempVec3c)
      math.subVec3(segment2[1], segment1[1], tempVec3d)
      let d00 = math.dotVec3(_A, tempVec3a)
      let d01 = math.dotVec3(_A, tempVec3b)
      let d10 = math.dotVec3(_A, tempVec3c)
      let d11 = math.dotVec3(_A, tempVec3d)

      let x1 = segment1[0][0]
      let x2 = segment1[1][0]
      let px1 = segment2[0][0]
      let px2 = segment2[1][0]

      let y1 = segment1[0][1]
      let y2 = segment1[1][1]
      let py1 = segment2[0][1]
      let py2 = segment2[1][1]

      let z1 = segment1[0][2]
      let z2 = segment1[1][2]
      let pz1 = segment2[0][2]
      let pz2 = segment2[1][2]

      let u1 = ((px1 - x1) * (x2 - x1) + (py1 - y1) * (y2 - y1) + (pz1 - z1) * (z2 - z1)) / (magA * magA)
      let u2 = ((px2 - x1) * (x2 - x1) + (py2 - y1) * (y2 - y1) + (pz2 - z1) * (z2 - z1)) / (magA * magA)
      let u3 = ((x1 - px1) * (px2 - px1) + (y1 - py1) * (py2 - py1) + (z1 - pz1) * (pz2 - pz1)) / (magB * magB)
      let u4 = ((x2 - px1) * (px2 - px1) + (y2 - py1) * (py2 - py1) + (z2 - pz1) * (pz2 - pz1)) / (magB * magB)

      if (u1 >= -0.00001 && u1 <= 1.00001) {
        let x = x1 + u1 * (x2 - x1)
        let y = y1 + u1 * (y2 - y1)
        let z = z1 + u1 * (z2 - z1)
        return [
          [x, y, z],
          [px1, py1, pz1],
        ]
      } 
      else if (u2 >= -0.00001 && u2 <= 1.00001) {
        let x = x1 + u2 * (x2 - x1)
        let y = y1 + u2 * (y2 - y1)
        let z = z1 + u2 * (z2 - z1)
        return [
          [x, y, z],
          [px2, py2, pz2],
        ]
      } 
      else if (u3 >= -0.00001 && u3 <= 1.00001) {
        let x = px1 + u3 * (px2 - px1)
        let y = py1 + u3 * (py2 - py1)
        let z = pz1 + u3 * (pz2 - pz1)
        return [
          [x, y, z],
          [x1, y1, z1],
        ]
      } 
      else if (u4 >= -0.00001 && u4 <= 1.00001) {
        let x = px1 + u4 * (px2 - px1)
        let y = py1 + u4 * (py2 - py1)
        let z = pz1 + u4 * (pz2 - pz1)
        return [
          [x, y, z],
          [x2, y2, z2],
        ]
      } 
      else {
        let absD00 = Math.abs(d00)
        let absD01 = Math.abs(d01)
        let absD10 = Math.abs(d10)
        let absD11 = Math.abs(d11)
        if (absD00 < absD01 && absD00 < absD10 && absD00 < absD11) {
          return [segment2[0], segment1[0]]
        } 
        else if (absD01 < absD00 && absD01 < absD10 && absD01 < absD11) {
          return [segment2[1], segment1[0]]
        } 
        else if (absD10 < absD00 && absD10 < absD01 && absD10 < absD11) {
          return [segment2[0], segment1[1]]
        } 
        else return [segment2[1], segment1[1]]
      }
    }

    // Линии лежат крест-накрест: расчет ближайших точек по проекциям
    let t = math.vec3()
    math.subVec3(segment2[0], segment1[0], t)

    let detA = geometry.math.determinantMat3([...t, ..._B, ...cross])
    let detB = geometry.math.determinantMat3([...t, ..._A, ...cross])

    let t0 = detA / denom
    let t1 = detB / denom

    let pA = math.vec3()
    let pB = math.vec3()
    math.mulVec3Scalar(_A, t0, tempVec3a)
    math.mulVec3Scalar(_B, t1, tempVec3b)
    math.addVec3(segment1[0], tempVec3a, pA) // Ближайшая точка к отрезку А в проекции
    math.addVec3(segment2[0], tempVec3b, pB) // Ближайшая точка к отрезку В в проекции

    // Обрезание проекций

    if (t0 < 0) {
      pA = new Array(...segment1[0])
    } 
    else if (t0 > magA) {
      pA = new Array(...segment1[1])
    }

    if (t1 < 0) {
      pB = new Array(...segment2[0])
    } 
    else if (t1 > magB) {
      pB = new Array(...segment2[1])
    }

    // Обрезание проекции А
    if (t0 < 0 || t0 > magA) {
      math.subVec3(pA, segment2[0], tempVec3a)
      let dot = math.dotVec3(_B, tempVec3a)
      if (dot < 0) {
        dot = 0
      } 
      else if (dot > magB) {
        dot = magB
      }
      math.mulVec3Scalar(_B, dot, tempVec3a)
      math.addVec3(segment2[0], tempVec3a, pB)
    }

    // Обрезание проекции Б
    if (t1 < 0 || t1 > magB) {
      math.subVec3(pB, segment1[0], tempVec3a)
      let dot = math.dotVec3(_A, tempVec3a)
      if (dot < 0) {
        dot = 0
      } 
      else if (dot > magA) {
        dot = magA
      }
      math.mulVec3Scalar(_A, dot, tempVec3a)
      math.addVec3(segment1[0], tempVec3a, pA)
    }
    return [pA, pB]
  }
}