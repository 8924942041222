import { useDrawingCroppingStore } from "@/pinia"
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import { NormalPin } from "../pins/normalPin"

const MOUSE_CANVAS_CLICK_TOLERANCE = 5

export class ImagePlaneNormalPicker {

  static #normal = null

  static mouseDownListenerId = null

  static mouseDownCanvasX = null
  static mouseDownCanvasY = null
  static mouseDownLeft = null

  static activate() {
    const input = XeokitMediator.viewer.scene.input

    this.mouseDownListenerId = input.on('mousedown', (coords) => {
      this.mouseDownCanvasX = coords[0]
      this.mouseDownCanvasY = coords[1]
      this.mouseDownLeft = input.mouseDownLeft
    })

    this.mouseUpListenerId = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.mouseDownLeft = false 
        return
      }

      if (this.mouseDownLeft) {

        if (this.#normal) { this.clearNormal() }

        const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
        })

        this.#normal = new NormalPin(XeokitMediator.viewer.scene, {})
        this.#normal.setCoordinate(pickResult.worldPos)
        this.#normal.setNormal(pickResult.worldNormal)

        useDrawingCroppingStore().isNormalOnSceneAdded = true
        useDrawingCroppingStore().setSceneNormal(pickResult.worldNormal)
      }
      
      this.mouseDownLeft = false
    })
  }

  static clearNormal() {
    this.#normal?.destroy()
    this.#normal = null
    useDrawingCroppingStore().isNormalOnSceneAdded = false
  }

  static deactivate() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.mouseDownListenerId)
  }
}