<template lang="pug">
div
  v-btn.pa-0.ma-0( v-on='$listeners' tile block elevation="0" :color="_buttonColor" :height="_buttonSize" :width="_buttonSize" :disabled="disabled" :light="light" )
    v-icon( :color="_iconColor" :size="_iconSize" ) {{ icon }}
</template>

<script>
export default {
  name: 'AppIconButton', 

  props: {
    light: {
      type: Boolean, 
      default: false
    },

    iconColor: {
      type: String, 
      default: 'var(--v-primary-darken2)'
    },

    iconSize: {
      type: String,
      default: '24px'
    },

    buttonColor: {
      type: String, 
      default: 'transparent'
    },

    buttonSize: {
      type: [String, Number], 
      default: '28'
    }, 

    icon: String,

    disabled: {
      type: Boolean, 
      default: false
    }
  },

  computed: {
    _buttonColor() {
      return this.buttonColor
    }, 

    _iconColor() {
      return this.iconColor
    }, 

    _buttonSize() {
      if (this.buttonSize == 'small') return '20'
      return this.buttonSize
    },

    _iconSize() {
      if (this.buttonSize == 'small') return '18'
      return this.iconSize
    }
  }
}
</script>

<style scoped>
.theme--dark.v-btn.v-btn--disabled .v-icon,
.theme--dark.v-btn.v-btn--disabled .v-icon {
  color: #DBDBDB !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg,
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg  {
  background-color: transparent !important;
}
</style>