<template lang="pug">
  v-menu(offset-y light)
    template(v-slot:activator="{ on, attrs }")
      v-btn(:color="color" v-bind="attrs" v-on="on" @click.native="showMenuLang = !showMenuLang" :small="small" text ) 
        v-icon.mr-3(v-if="!text") language
        span(:class="classStyle") {{ text || showLang.title }}
        v-icon(v-if="!text && showMenuLang") expand_less 
        v-icon(v-else-if="!text") expand_more

    v-list.list(light)
      v-list-item-group(v-model="selectedLang" )
        v-list-item(v-for="(lang, index) in langList" :key="index" @click="handleSelect(index, lang)" :selectable="index == selectedLang" )
          v-list-item-title.item-title {{ lang.title }}
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  inheritAttrs: false,
  name: 'app-language',

  props: {
    color: String,
    text: String,
    classStyle: String,
    small: Boolean
  },

  methods: {
    handleSelect(index, lang) {
      this.showMenuLang = false
      this.showLang =  this.langList.find(l => l.locale==lang.locale)
      this.selectedLang =  index
      i18n.locale = lang.locale;
      localStorage.setItem('locale', lang.locale)

      this.$emit('select', lang.locale)
    },


  },

  mounted() {
    this.selectedLang = 1
    this.showLang = this.langList[1]

    let locale = localStorage.getItem('locale')
    if ( (!locale && navigator.language === "ru-RU") || (locale && locale.indexOf('ru')!= -1) ) {
      this.selectedLang = 0
      this.showLang = this.langList[0]
    }
  },

  data () {
    return {
      langList: [
        { id: 0, locale: 'ru', title: 'Рус'},
        { id: 1, locale: 'en', title: 'Eng'},
      ],
      showMenuLang: false,
      showLang: { id: 0, locale: 'ru', title: 'Рус'},
      selectedLang: 1,
      show: false,
    }
  },
}
</script>

<style scoped>
.list{
  background-color: #f0f0f0;
}

.item-title{
  color: var(--v-primary-base);
}
</style>