const getDefaultState = () => {
  return {
    sectionList: []
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },
    
    setSectionList: (state, list) => { 
      state.sectionList = list 
    },
    clearSectionList: (state) => { 
      state.sectionList = []
    },
    setItemSectionList: (state, item) => { 
      state.sectionList.push(item) 
    },
  }
}