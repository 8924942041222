import { SceneModel, TrianglesDataTextureLayer } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es'

SceneModel.prototype._getDTXLayer = function (cfg) {
  const origin = cfg.origin;
  // const layerId = `${Math.round(origin[0])}.${Math.round(origin[1])}.${Math.round(origin[2])}`;
  const layerId = `${origin[0]}.${origin[1]}.${origin[2]}`;
  
  let dtxLayer = this._dtxLayers[layerId];
  
  if (dtxLayer) {
    if (!dtxLayer.canCreatePortion(cfg)) {
      dtxLayer.finalize();
      delete this._dtxLayers[layerId];
      dtxLayer = null;
    } 
    else {
      return dtxLayer;
    }
  }
  
  dtxLayer = new TrianglesDataTextureLayer(this, { layerIndex: 0, origin }); // layerIndex is set in #finalize()
  
  this._dtxLayers[layerId] = dtxLayer;
  this.layerList.push(dtxLayer);
  
  return dtxLayer;
}