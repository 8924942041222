<template lang="pug">
v-btn( v-bind="$attrs" :class="classes" :color="color" dense text small v-on="$listeners" )
  slot
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'accent',
    },
    variant: {
      type: String
    },
  },

  computed: {
    classes() {
      if (this.variant == 'fit') {
        return ['app-text-button app-text-button--fit pa-1']
      }
      return ['app-text-button px-1']
    }
  },
}
</script>

<style>
.app-text-button {
  font-size: 10px;
  text-transform: none;
}
.app-text-button--fit {
  min-width: fit-content !important;
  height: fit-content !important;
}
.app-text-button.v-btn--disabled .v-btn__content {
  color: var(--v-primary-lighten2) !important;
}
</style>