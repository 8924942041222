import { math } from "@xeokit/xeokit-sdk";
import { XeokitNode } from "../XeokitNode/XeokitNode";
import { PivotAxis } from "./pivotAxis"; 
import { geometry } from "../plugins/geometry/geometry";

const PERSPECTIVE_SCALE_MULTIPLIER = 0.085
const ORTHO_SCALE_MULTIPLIER = 0.05
export class PivotPin {

  #scene; 
  #pin; #xAxis; #yAxis; #zAxis;
  #position; #scale; #quaternion; #matrix; #origin;
  #visible;

  constructor(scene) {
    this.#scene = scene

    this.#pin = null
    this.#xAxis = null
    this.#yAxis = null
    this.#zAxis = null

    this.#position = [0, 0, 0]
    this.#scale = [1, 1, 1]
    this.#quaternion = [0, 0, 0, 1]
    this.#origin = [0, 0, 0]
    this.#matrix = null

    this.#visible = true

    this.#create()
  }

  #create() {
    this.#pin = new XeokitNode(this.#scene, {
      visible: this.#visible,
      pickable: false,
      clippable: false,
      castsShadow: false,
      recievesShadow: false,
      selected: true,
      origin: this.#origin
    })

    this.#xAxis = new PivotAxis(this.#pin, {
      position: [0.12, 0.0, 0.0],
      rotation: [0, 0, -90],
      color: [1, 0, 0]
    })

    this.#yAxis = new PivotAxis(this.#pin, {
      position: [0.0, 0.12, 0.0],
      color: [0, 1, 0]
    })

    this.#zAxis = new PivotAxis(this.#pin, {
      position: [0.0, 0.0, 0.12],
      rotation: [90, 15, 0],
      color: [0, 0, 1]
    })

    let lastDistance = 0
    const camera = this.#scene.camera
    
    this.#scene.on('tick', () => {
      const distance = geometry.math.distance(camera.eye, this.#origin)

      if (distance != lastDistance) {
        if (camera.projection === "perspective") {
          const axisScale = distance * PERSPECTIVE_SCALE_MULTIPLIER
          const scale = [axisScale, axisScale, axisScale]
          this.#pin.scale = scale
          
          lastDistance = distance;
        }
        else if (camera.projection === "ortho") {
          const size = camera.ortho.scale * ORTHO_SCALE_MULTIPLIER
          const scale = [size, size, size]
          this.#pin.scale = scale
          
          lastDistance = distance;
        }
      }
    })
  }

  setVisible(visible) {
    this.#visible = !!visible
    this.#pin.visible = this.#visible
  }

  updatePosition(pos) {
    const origin = pos ?? this.#origin
    const position = [0, 0, 0]

    this.#origin = origin
    this.#position = position

    this.#pin.origin = this.#origin
  }

  updatePositionAndScale(position, distance) {
    const scaleAxis = distance * PERSPECTIVE_SCALE_MULTIPLIER
    const scale = [scaleAxis, scaleAxis, scaleAxis]

    const transform = {
      position: position,
      scale: scale
    }
    this.update(transform)
  }

  updateScale(scale, projectionType) {
    if (projectionType == "perspective") {
      const scaleAxis = scale * PERSPECTIVE_SCALE_MULTIPLIER
      const fullScale = [scaleAxis, scaleAxis, scaleAxis]
      this.#pin.scale = fullScale
    }
    else if (projectionType == "ortho") {
      const scaleAxis = scale * ORTHO_SCALE_MULTIPLIER
      const fullScale = [scaleAxis, scaleAxis, scaleAxis]
      this.#pin.scale = fullScale
    }
  }

  update(transform) {
    const origin = transform.position ?? this.#origin
    const position = [0, 0, 0]
    const scale = transform.scale ?? this.#scale
    const quaternion = this.#quaternion

    this.#origin = origin
    this.#position = position
    this.#scale = scale

    this.#matrix = math.composeMat4(position, quaternion, scale)
    this.#pin.matrix = this.#matrix
    this.#pin.origin = this.#origin
  }
}