<template lang="pug">
.app-avatar( :style="styles" @click="click" )
  .app-avatar__wrapper( :style="wrapperStyles" )
    v-tooltip( :bottom="tooltipBottom" :left="tooltipLeft" :top="tooltipTop" :right="tooltipRight" open-delay=300 )
      template( v-slot:activator="{ on, attrs }" )
        div(style='width: 100%; height: 100%' v-on="tooltipOn ? on :  false" )
          v-img( v-if="!failed_image && url" :src="url" width="100%" height="100%" v-on:error="onImgError" )
          v-icon( v-else-if="text==null || text==''" ) person
          .app-avatar__wrapper_letters( v-else :style="letterStyles" ) {{ initials }}
      span {{ text }}
</template>

<script>
export default {
  name: 'app-avatar',

  props: {
    size: { type: Number, default: 24 },
    text: String,
    url: String,
    bordered: Boolean,
    tooltipLeft: Boolean,
    tooltipRight: Boolean,
    tooltipTop: Boolean,
    tooltipBottom: Boolean,
    disabled:  Boolean,
    colorObj: Object,
  },

  data () {
    return {
      millis: new Date().getTime(),
      failed_image: false
    }
  },

  computed: {
    initials() {
      return this.text?.split(' ').map(word => word.charAt(0).toUpperCase()).join('').substring(0, 2) || '??'
    },

    styles() {
      let colorAndBg = {}
      if (this.colorObj) {
        colorAndBg = {
          background: this.colorObj.color,
          color: this.colorObj.isLight ? '#505254' : '#fff'
        }
      }

      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: `${this.size/2}px`,
        border: this.bordered ? '2px solid #1B93AD' : this.disabled ? '2px solid red' : 'none',
        fontSize: `${this.size <= 24 && this.bordered ? 10 : this.size * 0.5}px`,
        ...colorAndBg
      }
    },

    wrapperStyles() {
      return {
        borderRadius: `${this.size/2}px`,
        border: this.bordered ? '2px solid #FFFFFF' : this.disabled ? '2px solid #FFFFFF' : 'none',
        overflow: 'hidden',
        opacity: this.disabled ? 0.5 : 1
      }
    },

    letterStyles() {
      const size = this.bordered || this.disabled ? this.size - 4 - 4 : this.size - 2
      return {
        lineHeight: `${size}px`,
        padding: this.size <= 24 ? '0 0px' : '0',
      }
    },

    tooltipOn() {
      if (this.tooltipLeft || this.tooltipRight || this.tooltipTop || this.tooltipBottom) return true
      return false
    },
  },

  methods: {
    onImgError: function() {
      this.failed_image = true;
    },

    click(event) {
      this.$emit('click', event)
    },
  }
}
</script>

<style scoped>
.app-avatar {
  display: inline-block;
  background: #C4C4C4;
  text-align: center;
  border: 1px solid #757575 !important
}
.app-avatar__wrapper {
  width: 100%;
  height: 100%;
}
.app-avatar__wrapper_letters {
  vertical-align: middle;
  text-shadow: 0 0 0.5px #636363;  
}
</style>