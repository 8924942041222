<template>
  <svg 
    class="svg-icon" 
    width="16" 
    height="16" 
    viewBox="0 0 16 16"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_866_92)">
      <path
        d="M0.757489 2.33982H0.295477V2.22293H0.757489C0.853973 2.22293 0.932274 2.20734 0.992391 2.17617C1.05325 2.14426 1.09741 2.10121 1.12487 2.04703C1.15308 1.99285 1.16718 1.93199 1.16718 1.86445C1.16718 1.7984 1.15308 1.73754 1.12487 1.68188C1.09741 1.62621 1.05325 1.58168 0.992391 1.54828C0.932274 1.51414 0.853973 1.49707 0.757489 1.49707H0.343349V3H0.206415V1.37906H0.757489C0.876239 1.37906 0.976063 1.39947 1.05696 1.44029C1.1386 1.48111 1.2002 1.53789 1.24177 1.61063C1.28333 1.68336 1.30411 1.76723 1.30411 1.86223C1.30411 1.96168 1.28333 2.0474 1.24177 2.11939C1.2002 2.19064 1.13897 2.2452 1.05808 2.28305C0.977177 2.3209 0.876981 2.33982 0.757489 2.33982ZM1.96326 3H1.59588L1.59811 2.88311H1.96326C2.07979 2.88311 2.17998 2.85713 2.26385 2.80518C2.34846 2.75248 2.41377 2.67975 2.45979 2.58697C2.5058 2.4942 2.52881 2.38658 2.52881 2.26412V2.11271C2.52881 2.0192 2.51545 1.93459 2.48873 1.85889C2.46275 1.78318 2.42527 1.71861 2.37629 1.66518C2.3273 1.611 2.26867 1.56943 2.20039 1.54049C2.13285 1.51154 2.05715 1.49707 1.97328 1.49707H1.5892V1.37906H1.97328C2.07422 1.37906 2.16662 1.3965 2.25049 1.43139C2.3351 1.46553 2.4082 1.51525 2.4698 1.58057C2.53215 1.64514 2.58002 1.7227 2.61342 1.81324C2.64756 1.90379 2.66463 2.00473 2.66463 2.11605V2.26412C2.66463 2.37545 2.64756 2.47639 2.61342 2.56693C2.58002 2.65748 2.53215 2.73504 2.4698 2.79961C2.40746 2.86418 2.33361 2.91391 2.24826 2.94879C2.16291 2.98293 2.06791 3 1.96326 3ZM1.65711 1.37906V3H1.52018V1.37906H1.65711ZM3.05993 1.37906V3H2.923V1.37906H3.05993ZM3.81474 2.12496V2.24297H3.01429V2.12496H3.81474ZM3.92718 1.37906V1.49707H3.01429V1.37906H3.92718Z"
        transform="scale(4)"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_866_92">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
  export default {
    name: "PdfIcon"
  }
</script>

<style scoped>
  .svg-icon {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>>