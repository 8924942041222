<template lang="pug">
.app-icon-action--position
  v-icon.pointer( v-bind="$attrs" v-on="$listeners" :size="size" :color="color" ) {{ icon }}
</template>

<script>
export default {
  props: {
    icon: String,
    size: {
      type: [Number, String],
      default: 24
    },
    color: {
      type: String,
      default: "var(--v-primary-darken2)"
    }
  }
}
</script>

<style scoped>
.app-icon-action--position {
  position: relative;
}
</style>