<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask fill="currentColor">
      <rect x="2" y="2" width="20" height="20" rx="1" />
    </mask>
    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      rx="1"
      stroke="currentColor"
      stroke-width="2"
      mask="url(#path-1-inside-1_3321_464)"
    />
    <line x1="4" y1="9" x2="21" y2="9" stroke="currentColor" stroke-width="2" />
    <line x1="9" y1="20" x2="9" y2="3" stroke="currentColor" stroke-width="2" />
    <line x1="4" y1="15" x2="21" y2="15" stroke="currentColor" stroke-width="2" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>