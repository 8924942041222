<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="#F0F0F0"/>
    <path d="M13.5 13.5938V4.03125L21.5312 10.4062V19.9688L13.5 13.5938Z" stroke="#FF0000"/>
    <path d="M2.53125 13.5938V4.03125L10.5 10.4062V19.9688L2.53125 13.5938Z" stroke="#FF0000"/>
    <path d="M17.5156 14.0156V9.01562" stroke="#303030"/>
    <path d="M6.54688 14.0156V9.01562" stroke="#303030"/>
    <path d="M6.25 11.5156L10 11.5156M17.5156 11.5156L11 11.5156" stroke="#303030"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>