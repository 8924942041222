import { VBOGeometry, Mesh, PhongMaterial, EmphasisMaterial } from "@xeokit/xeokit-sdk"
import { BuildDashDottedLineGeometry } from "../xeokit/buildDashDottedLineGeometry"
import { geometry } from '../xeokit/plugins/geometry/geometry'

const STATE_INHERIT = true
export class DashDottedSegment {
  constructor(owner, cfg = {}) {
    this.mesh = null
    this.owner = owner

    this.vertices = cfg.vertices ?? null
    this.color = cfg.color ?? [1, 1, 1]
    this.unitNumber = cfg.unitNumber ?? 10
    this.glowThrough = cfg.glowThrough ?? false

    this.#create()
  }

  #create() {
    if (!this.vertices) return
    if (!this.vertices.length) return
    
    const origin = this.vertices[0]

    const vertex1 = geometry.math.subVec3(this.vertices[0], origin)
    const vertex2 = geometry.math.subVec3(this.vertices[1], origin)
    
    const meshGeometry = new VBOGeometry(this.owner, BuildDashDottedLineGeometry.buildDashDottedLineGeom({ 
      positions: [vertex1, vertex2], 
      unitNumber: this.unitNumber 
    }))

    const material = new PhongMaterial(this.owner, {
      emissive: this.color,
      diffuse: [0, 0, 0],
      ambient: [0, 0, 0],
    })

    const selectedMaterial = new EmphasisMaterial(this.owner, {
      fill: false,
      edges: true,
      edgeColor: this.color,
      edgeAlpha: 1,
      glowThrough: true
    })

    this.mesh = new Mesh(this.owner, {
      geometry: meshGeometry,
      material: material,
      selectedMaterial: selectedMaterial,
      origin: origin,
    })

    this.owner.addChild(this.mesh, STATE_INHERIT)

    if (this.glowThrough) {
      setTimeout(() => {
        this.mesh.selected = true // обход состояния родителя
      })
    } 
  }

  setGlowThrough(value) {
    this.mesh.selected = value
  }
}