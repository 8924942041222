import i18n from '@/plugins/i18n';

export class NotificationService{
    static NotificationReplace(message) {
        if(!message){
            return ""
        }
        if (message.includes("{")) 
            return 'notify.' + message.replace("{", "").replace("}","");

        return message
    }
    
    static NotificationRebuild(translateText, uniqKey = null) {
        if (!translateText) return ''
        if (!uniqKey) return ''

        let locale = localStorage.getItem("locale")
        
        let currentString

        if(/^@:\(/.test(translateText) && translateText.includes('. ')) {
            const regexNotType = /@.*?\. /
            const matchesNotType = translateText.match(regexNotType)[0]
            
            const regexNotTitle = /\).(?<name>.*)/
            const matchesNotTitle = translateText.match(regexNotTitle).groups.name

            currentString = "<span style='color:#757575'>" + matchesNotType + "</span>" + "<span style='color:#262626'>" + ' ' + matchesNotTitle + "</span>"
        }
        else currentString = "<span class='notification-title' style='color:#757575'>" + translateText + "</span>"
        
        let message = i18n.getLocaleMessage(locale)
        message[uniqKey] = currentString
        i18n.setLocaleMessage( locale, message )

        return i18n.t(uniqKey, locale)
    }

    static NotificationWithValue(message) {
        if (!message)
            return ""

        if (message.includes('messageValue:')) {
            // let alias = message.match(new RegExp(/(?:{).*?(?=})/gm))[0] // Временное решение, есть подозрение на несовместимость Safari до 16.3 версии
            // let value = message.match(new RegExp(/(?:messageValue:).*/gm))[0] // Временное решение, есть подозрение на несовместимость Safari до 16.3 версии
            
            let alias = message.match(/{(?<name>.*?)}|/).groups.name
            let value = message.match(/messageValue:'(?<name>.*?)'$/).groups.name

            if (alias && value)
                if(value.includes('model.name'))
                    return i18n.t('notify.' + alias, { name: "'"+ i18n.t('notify.' + value.replace("{", "").replace("}","")) + "'" })  
                else  
                    return i18n.t('notify.' + alias, { name: "'" + value + "'"})

            return i18n.t("notify." + alias, {name: null})
        }
        
        return i18n.t("notify." + message.replace("{", "").replace("}", ""), {name: null})
    }

    static NotificationTranslateString(message) {
        if (!message)
            return ""

        let words = message.split(', ')
        let translateString = words.map(word => {
            if(word.includes('model.name')) return i18n.t('notify.' + word)
            return word
        }).join(", ")

        return translateString
    }
}