export default ({ Factory }) => ({

  // ------ PROJECTS
  /**
   * Выбрать правила, по которым должно отфильтроваться дерево осей
   * @param {String} projectUuid UUID проекта
   * @param {Array<String>} uuids Список UUID правил, по которым должно отсортироваться дерево осей
   */
  selectRules: (projectUuid, uuids) => Factory.projects[projectUuid].rules.patch(uuids),

  /**
   * Выбрать выбранных атрибутных правил
   * @param {String} projectUuid UUID проекта
   * @param {Array<String>} uuids Список UUID правил
   */
  selectAttrRules: (projectUuid, uuids) => Factory.projects[projectUuid].attr.patch(uuids),

  /**
   * Сохранить в настройках проекта выбранные пользователем узлы для показа коллизий
   * @param {String} projectUuid UUID проекта
   * @param {String} treeUuid UUID дерева либо число от 1 до 4 для фиксированного дерева
   * @param {Array<String>} nodes Список extraId узлов, которые были отмечены галочками
   */
  selectAxisNodes: (projectUuid, treeUuid, nodes) => Factory.projects[projectUuid].axis[treeUuid].nodes.patch(nodes),

  // ------ PROFILES
  /**
   * Получить текущие значения настроек таблицы
   */
  getTableSettings: () => Factory.profiles.settings.COLLTABLE.get(),
  
  /**
   * Отметить поля, которые пользователь выбрал как видимые
   * @param {Array<String>} fields поля, которые должны быть сохранены как видимые
   */
  selectColumns: (fields) => Factory.profiles.settings.fields.patch(fields),

  /**
   * Перезаписать настройки всех полей таблицы
   * @param {Array<Object>} columns полный набор настроек всех полей таблицы
   */
  updateColumns: (columns) => Factory.profiles.settings.put(columns),

  /**
   * Изменить ширину указанной колонки в таблице CollisionList
   * @deprecated
   * @param {String} header alias заголовка колонки в таблице
   * @param {Number} width новое значение ширины колонки
   */
  setTableHeaderWidth: (header, width) => Factory.profiles.settings[header].width[width].patch(),

  /**
   * Изменить видимость колонки в таблице CollisionList
   * @deprecated
   * @param {String} header alias заголовка колонки в таблице
   * @param {Boolean} visible скрыть/показать колонку
   */
  setTableHeaderVisibility: (header, visible) => Factory.profiles.settings[header].view[visible].patch(),

  /**
   * Указать колонку, по которой необходимо провести сортировку
   * @param {String} header 
   * @param {String} dir направление сортировки по возрастанию/убыванию (ASC/DESC)
   */
  setTableSorter: (header, dir) => Factory.profiles.settings[header].sort[dir].patch(),

  /**
   * Сохранить изменения в параметрах указанной колонки в таблице CollisionList
   * @param {Object} header объект, описывающий колонку таблицы
   */
  updateTableHeader: (header) => Factory.profiles.settings.patch(header),
  
  /**
   * Сохранение порядка группировки коллизий по указанным полям
   * @param {Array<String>} fields Список псевдонимов полей (например, displayedStatus)
   */
  setGroupingFields: (fields) => Factory.profiles.settings.groups.patch(fields),

  /**
   * Установка ширины всех стобцов таблицы коллизий
   * @returns {CollisionTableSettings} tableSettings Настройки таблицы
   */
  resetColumnWidth: () => Factory.profiles.settings.table.width.patch(),

})