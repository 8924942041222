import { useImageSectionPlanesStore } from "@/pinia"

/*eslint-disable no-dupe-class-members*/
export class ImageCropper {

  static get #_imagePlaneStore() {
    return useImageSectionPlanesStore()
  }

  static setImageSrc(imageSrc) {
    this.#_imagePlaneStore.imageSrc = imageSrc
  }

  static cropImage(cfg) {

    const imgSrc = cfg.imgSrc
    // const img = cfg.img
    const rect = cfg.rectangle
    const x1 = rect.left
    const x2 = rect.right
    const y1 = rect.top
    const y2 = rect.bottom

    const callbackFunc = cfg.callbackFunc

    if (x1 == x2 && y1 == y2) {
      this.setImageSrc("")
    }

    const canvas = document.createElement('canvas')

    const finalImgScale = 5

    // Дополнительная защита от перепутанных размеров
    const minX = Math.min(x1, x2) * finalImgScale
    const minY = Math.min(y1, y2) * finalImgScale
    const maxX = Math.max(x1, x2) * finalImgScale
    const maxY = Math.max(y1, y2) * finalImgScale

    const width = maxX - minX
    const height = maxY - minY

    canvas.width = width
    canvas.height = height

    const context = canvas.getContext('2d')
    
    const sourceX = minX;
    const sourceY = minY;
    const sourceWidth = width;
    const sourceHeight = height;
    const destWidth = sourceWidth;
    const destHeight = sourceHeight;
    const destX = 0;
    const destY = 0;
    
    const decodedSrc = Buffer.from(imgSrc.slice(26), 'base64').toString('ascii')
    
    const parser = new DOMParser()
    const htmlObject = parser.parseFromString(decodedSrc, 'text/html')

    const svgHtml = htmlObject.getElementsByTagName('svg')[0]

    const lastWidth = parseFloat(svgHtml.getAttribute("width"))
    const lastHeight = parseFloat(svgHtml.getAttribute("height"))

    const newHeight = (lastHeight * finalImgScale).toString()
    const newWidth = (lastWidth * finalImgScale).toString()
    const newViewBox = "0 0 " + lastWidth.toString() + " " + lastHeight.toString()

    svgHtml.setAttribute("viewBox", newViewBox)
    svgHtml.setAttribute("width", newWidth)
    svgHtml.setAttribute("height", newHeight)
    
    const xml = new XMLSerializer().serializeToString(svgHtml);
    const encodedXml = Buffer.from(xml, 'ascii').toString('base64')

    const imgElement = document.createElement('img')
    imgElement.src = "data:image/svg+xml;base64," + encodedXml

    imgElement.onload = () => {
      context.drawImage(imgElement, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);

      const croppedPng = canvas.toDataURL('image/png');
      
      if (croppedPng) {
        this.setImageSrc(croppedPng)
      }
      else {
        this.setImageSrc(imgSrc)
      }

      canvas.remove();
      imgElement.remove();

      callbackFunc()
    }
  }
}