/*eslint-disable no-dupe-class-members*/
import { math } from "@xeokit/xeokit-sdk"
import { XeokitMediator } from "./XeokitMediator" 
import { geometry } from "./plugins/geometry/geometry"

export class HighPrecisionPick {

  static pick(params) {
    let tempVec3a = math.vec3()
    let tempVec3b = math.vec3()
    let tempVec3c = math.vec3()

    let viewMatrix = XeokitMediator.viewer.scene.camera.viewMatrix
    let projMatrix = XeokitMediator.viewer.scene.camera.projMatrix

    let pickResult = XeokitMediator.ScenePick.pickResult({
      canvasPos: params.canvasPos,
      pickSurface: false,
      pickSurfaceNormal: false
    })
    
    if (pickResult == null) {
      return null
    }

    if (!pickResult.entity._isObject) { // Временное решение проблемы, когда пик попадает в какой-то ломанный объект
      if (pickResult.entity.isSectionControl) { return { isSectionControl: true } }
      else pickResult.entity.pickable = false
      return this.pick(params)
    }
    
    if (pickResult?.entity?.meshes[0]?.id?.toString().includes("pointsMesh")) {
      return XeokitMediator.ScenePick.pickResult({
        canvasPos: params.canvasPos,
        pickSurface: true,
        pickSurfaceNormal: false
      })
    }

    let ray = this.#_getRay(viewMatrix, projMatrix, pickResult)

    if (params.needPickedFace) {
      if (pickResult.entity.model.isCollisionIntersection) {
        return {entity: pickResult.entity}
      }
      const objectFace = geometry.highPrecisionPick.getObjectFaceByRay(pickResult.entity, ray)
      return objectFace
    }

    //Собрать полигоны объекта
    let entityPolygons = []
    for(let i = 0; i < pickResult.entity.meshes.length; i++) {
      entityPolygons.push(...geometry.extraction.getFacesVerticeByEntity(pickResult.entity.meshes[i], params.needCompPolygons))
    }
    
    //В найденных полигонах найти точки пересечения с лучом
    //Для каждого полигона найти rayO, rayV, planeN, planeO
    let destIntersPoints = []
    let destNormals = []
    let destCompCoords = []
    if(params.needCompPolygons) {
      for(let i = 0; i < entityPolygons.length; i+=3) {

        let polygonN = math.vec3()
        math.subVec3(entityPolygons[i].coords, entityPolygons[i + 1].coords, tempVec3a)
        math.subVec3(entityPolygons[i].coords, entityPolygons[i + 2].coords, tempVec3b)
        math.cross3Vec3(tempVec3a, tempVec3b, tempVec3c)
        math.normalizeVec3(tempVec3c, polygonN)
  
        let polygonO = entityPolygons[i].coords
        let polygonEdges = [[entityPolygons[i].coords, entityPolygons[i + 1].coords], [entityPolygons[i + 1].coords, entityPolygons[i + 2].coords], [entityPolygons[i + 2].coords, entityPolygons[i].coords]]
        let intersPoint = geometry.intersection.getRayPlaneIntersectionPoint(ray.o, ray.dir, polygonN, polygonO)
        
        // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
        if (geometry.intersection.isPointOnPolygon(polygonN, polygonEdges, intersPoint)) {
          destIntersPoints.push(intersPoint)
          destNormals.push(polygonN)
          destCompCoords.push([entityPolygons[i].comp, entityPolygons[i + 1].comp, entityPolygons[i + 2].comp])
        }
      }
    }
    else {
      for(let i = 0; i < entityPolygons.length; i+=3) {

        let polygonN = math.vec3()
        math.subVec3(entityPolygons[i], entityPolygons[i + 1], tempVec3a)
        math.subVec3(entityPolygons[i], entityPolygons[i + 2], tempVec3b)
        math.cross3Vec3(tempVec3a, tempVec3b, tempVec3c)
        math.normalizeVec3(tempVec3c, polygonN)

        let polygonO = entityPolygons[i]
        let polygonEdges = [[entityPolygons[i], entityPolygons[i + 1]], [entityPolygons[i + 1], entityPolygons[i + 2]], [entityPolygons[i + 2], entityPolygons[i]]]

        let intersPoint = geometry.intersection.getRayPlaneIntersectionPoint(ray.o, ray.dir, polygonN, polygonO)

        // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
        if (geometry.intersection.isPointOnPolygon(polygonN, polygonEdges, intersPoint)) {
          destIntersPoints.push(intersPoint)
          destNormals.push(polygonN)
        }
      }
    }
    
    //Вернуть ближайшую из них
    let nearestIntersPoint = destIntersPoints[0]
    let nearestPointNormal = destNormals[0]
    let nearestCompCoords = destCompCoords[0]

    if (!destIntersPoints[0]) {
      return null
    }
    
    math.subVec3(destIntersPoints[0], ray.o, tempVec3a)
    let dist1 = Math.abs(math.lenVec3(tempVec3a))

    for(let i = 1; i < destIntersPoints.length; i++) {
      math.subVec3(destIntersPoints[i], ray.o, tempVec3a)
      let dist2 = Math.abs(math.lenVec3(tempVec3a))
      if(dist2 < dist1) {
        nearestIntersPoint = destIntersPoints[i]
        nearestPointNormal = destNormals[i]
        nearestCompCoords = destCompCoords[i]
      }
    }

    return new HighPrecisionPickResult({
      entity: pickResult.entity,
      worldPos: nearestIntersPoint, 
      worldNormal: nearestPointNormal,
      compPolygon: nearestCompCoords,
      canvasPos: params.canvasPos
    })
  }

  static #_getRay(viewMatrix, projMatrix, pickResult) {
    let tempMat4b = math.mat4()
    let tempMat4c = math.mat4()
    let tempVec4a = math.vec4()
    let tempVec4b = math.vec4()
    let tempVec4c = math.vec4()

    const canvasPos = new Float64Array(pickResult.canvasPos)
    const canvas = XeokitMediator.viewer.scene.canvas.canvas

    // Calculate clip space coordinates, which will be in range of x=[-1..1] and y=[-1..1], with y=(+1) at top
    let x = (canvasPos[0] - canvas.clientWidth / 2) / (canvas.clientWidth / 2);
    let y = -(canvasPos[1] - canvas.clientHeight / 2) / (canvas.clientHeight / 2);

    //const origin = entity.meshes[0].origin;
    let pvMat;

    // if (!origin) {
    //   const rtcPickViewMat = this.#_createRTCViewMat(viewMatrix, origin, tempMat4a);
    //   pvMat = math.mulMat4(projMatrix, rtcPickViewMat, tempMat4b);

    // } else {
      pvMat = math.mulMat4(projMatrix, viewMatrix, tempMat4b);
    //}

    const pvMatInverse = math.inverseMat4(pvMat, tempMat4c);

    tempVec4a[0] = x;
    tempVec4a[1] = y;
    tempVec4a[2] = -1;
    tempVec4a[3] = 1;

    let world1 = math.transformVec4(pvMatInverse, tempVec4a);
    world1 = math.mulVec4Scalar(world1, 1 / world1[3]);

    tempVec4b[0] = x;
    tempVec4b[1] = y;
    tempVec4b[2] = 1;
    tempVec4b[3] = 1;

    let world2 = math.transformVec4(pvMatInverse, tempVec4b);
    world2 = math.mulVec4Scalar(world2, 1 / world2[3]);

    const dir = math.subVec4(world2, world1, tempVec4c);

    return {
      dir: dir,
      o: world1
    }
  }

  static #_createRTCViewMat(viewMat, rtcCenter, rtcViewMat) {
    const tempMat = new Float64Array(16);
    const rtcCenterWorld = new Float64Array(4);
    const rtcCenterView = new Float64Array(4);

    rtcViewMat = rtcViewMat || tempMat;
    rtcCenterWorld[0] = rtcCenter[0];
    rtcCenterWorld[1] = rtcCenter[1];
    rtcCenterWorld[2] = rtcCenter[2];
    rtcCenterWorld[3] = 1;
    math.transformVec4(viewMat, rtcCenterWorld, rtcCenterView);
    math.setMat4Translation(viewMat, rtcCenterView, rtcViewMat);
    
    return rtcViewMat.slice();
  }
}

class HighPrecisionPickResult {
  _entity = null
  _worldPos = null
  _worldNormal = null
  _canvasPos = null
  _compPolygon = null

  constructor(cfg) {
    this._entity = cfg.entity
    this._worldPos = cfg.worldPos
    this._worldNormal = cfg.worldNormal
    this._canvasPos = cfg.canvasPos
    this._compPolygon = cfg.compPolygon
  }

  get entity() {
    return this._entity
  }

  get worldPos() {
    return this._worldPos
  }

  get worldNormal() {
    return this._worldNormal
  }

  get canvasPos() {
    return this._canvasPos
  }

  get compPolygon() {
    return this._compPolygon
  }
}