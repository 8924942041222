import { geometry } from "./geometry";

export const utils = {

  contains(arr, elem, epsilon = 0.0000001) {
    for (var i = 0; i < arr.length; i++) {
      if (this.arePointsEqual(arr[i], elem, epsilon)) {
        return true;
      }
    }
    return false;
  },

  isPointsEqual(point1, point2) {
    if (point1[0] == point2[0] && point1[1] == point2[1] && point1[2] == point2[2]) return true
    else return false
  },

  arePointsEqual(point1, point2, epsilon) {
    return (
      Math.abs(point1[0] - point2[0]) < epsilon && Math.abs(point1[1] - point2[1]) < epsilon && Math.abs(point1[2] - point2[2]) < epsilon
    )
  },

  isCloseEqual(a, b) {
    return a >= b - 0.0001 && a <= b + 0.0001
  },

  arePointsClose(point1, point2, epsilon) {
    const distance = Math.sqrt((point1[0] - point2[0]) ** 2 + (point1[1] - point2[1]) ** 2 + (point1[2] - point2[2]) ** 2)
    return distance < epsilon
  },

  indexOfElementInArray(element, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] == element) {
        return i
      }
    }
    return null
  },

  edgeObjectsToEdgesArray(edgeObjs) {
    let edges = []

    edgeObjs.forEach((edge) => {
      edges.push([edge.originWorldPos, edge.targetWorldPos])
    })

    return edges
  },

  removeDuplicateSegments(segments, epsilon) {
    const uniqueSegments = []

    function areCoordinatesEqual(coord1, coord2) {
      return Math.abs(coord1 - coord2) < epsilon
    }

    function areSegmentsEqual(segment1, segment2) {
      const [start1, end1] = segment1
      const [start2, end2] = segment2

      const areStartPointsEqual =
        areCoordinatesEqual(start1[0], start2[0]) && areCoordinatesEqual(start1[1], start2[1]) && areCoordinatesEqual(start1[2], start2[2])

      const areEndPointsEqual =
        areCoordinatesEqual(end1[0], end2[0]) && areCoordinatesEqual(end1[1], end2[1]) && areCoordinatesEqual(end1[2], end2[2])

      const arePointsSwapped =
        areCoordinatesEqual(start1[0], end2[0]) &&
        areCoordinatesEqual(start1[1], end2[1]) &&
        areCoordinatesEqual(start1[2], end2[2]) &&
        areCoordinatesEqual(end1[0], start2[0]) &&
        areCoordinatesEqual(end1[1], start2[1]) &&
        areCoordinatesEqual(end1[2], start2[2])

      return (areStartPointsEqual && areEndPointsEqual) || arePointsSwapped
    }

    for (const segment of segments) {
      let isDuplicate = false

      for (const uniqueSegment of uniqueSegments) {
        if (areSegmentsEqual(segment, uniqueSegment)) {
          isDuplicate = true
          break
        }
      }

      if (!isDuplicate && !(segment[0][0] === segment[1][0] && segment[0][1] === segment[1][1] && segment[0][2] === segment[1][2])) {
        uniqueSegments.push(segment)
      }
    }

    return uniqueSegments
  },

  quickSortEntitiesByDistances(entities, distances) {
    const Compare = {
      LESS_THAN: -1,
      BIGGER_THAN: 1,
    }

    function defaultCompare(a, b) {
      if (a === b) {
        return 0
      }
      return a < b ? Compare.LESS_THAN : Compare.BIGGER_THAN
    }

    function swap(arr, a, b) {
      let temp = arr[a]
      arr[a] = arr[b]
      arr[b] = temp
    }

    function quick(distances, left, right, compare, entities) {
      let i
      if (distances.length > 1) {
        i = partition(distances, left, right, compare, entities)
        if (left < i - 1) {
          quick(distances, left, i - 1, compare, entities)
        }
        if (i < right) {
          quick(distances, i, right, compare, entities)
        }
      }
      return distances
    }

    function partition(distances, left, right, compare, entities) {
      const pivot = distances[Math.floor((right + left) / 2)]
      let i = left
      let j = right
      while (i <= j) {
        while (compare(distances[i], pivot) === Compare.LESS_THAN) {
          i++
        }
        while (compare(distances[j], pivot) === Compare.BIGGER_THAN) {
          j--
        }
        if (i <= j) {
          swap(distances, i, j)
          swap(entities, i, j)
          i++
          j--
        }
      }
      return i
    }

    function quickSort(arr) {
      return quick(arr, 0, arr.length - 1, defaultCompare, entities)
    }

    if (entities.length !== distances.length) return entities
    return quickSort(distances)
  },

  getMinMaxCoords(coordsArray) {
    let rectangleMin = [coordsArray[0][0], coordsArray[0][1]];
    let rectangleMax = [coordsArray[0][0], coordsArray[0][1]];
    
    for (let i = 1; i < coordsArray.length; i++) {
      if (coordsArray[i][0] < rectangleMin[0]) {
        rectangleMin[0] = coordsArray[i][0];
      }
      if (coordsArray[i][1] < rectangleMin[1]) {
        rectangleMin[1] = coordsArray[i][1];
      }
      
      if (coordsArray[i][0] > rectangleMax[0]) {
        rectangleMax[0] = coordsArray[i][0];
      }
      if (coordsArray[i][1] > rectangleMax[1]) {
        rectangleMax[1] = coordsArray[i][1];
      }
    }
    return [rectangleMin, rectangleMax];
  },

  

  

  getTriangleMin(triangle) {
    const min = [Infinity, Infinity, Infinity]
    for (const point of triangle) {
      for (let i = 0; i < 3; i++) {
        if (point[i] < min[i]) {
          min[i] = point[i]
        }
      }
    }
    return min
  },

  getTriangleMax(triangle) {
    const max = [-Infinity, -Infinity, -Infinity]
    for (const point of triangle) {
      for (let i = 0; i < 3; i++) {
        if (point[i] > max[i]) {
          max[i] = point[i]
        }
      }
    }
    return max
  },

  areTrianglesOnSamePlane(triangle1, triangle2) {
    const normal1 = this.computeTriangleNormal(triangle1)
    const normal2 = this.computeTriangleNormal(triangle2)

    const crossProduct = geometry.math.crossVec3(normal1, normal2)
    const crossProductMagnitude = Math.sqrt(crossProduct[0] ** 2 + crossProduct[1] ** 2 + crossProduct[2] ** 2)

    return crossProductMagnitude < Number.EPSILON
  },

  getOrientation(p, q, r) {
    const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1])

    if (val === 0) {
      return 0
    }

    return val > 0 ? 1 : 2
  },

  computeTriangleNormal(triangle) {
    const [p1, p2, p3] = triangle

    const v1 = geometry.math.subVec3(p2, p1)
    const v2 = geometry.math.subVec3(p3, p1)

    return geometry.math.crossVec3(v1, v2)
  },
}