<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.4 26.3333C4.02222 26.3333 3.69444 26.1944 3.41667 25.9167C3.13889 25.6389 3 25.3111 3 24.9333C3 24.7556 3.05 24.55 3.15 24.3167C3.25 24.0833 3.36667 23.9 3.5 23.7667L12.1667 15.6667L3.5 7.56667C3.36667 7.43333 3.25 7.25556 3.15 7.03333C3.05 6.81111 3 6.6 3 6.4C3 6.02222 3.13889 5.69444 3.41667 5.41667C3.69444 5.13889 4.02222 5 4.4 5C9.78799 5.98818 12.6096 5.95869 17.3333 5C17.8 5 18.1944 5.16111 18.5167 5.48333C18.8389 5.80556 19 6.2 19 6.66667C19 7.13333 18.8389 7.52778 18.5167 7.85C18.1944 8.17222 17.4667 8.16663 17 8.16663L8.2 8.33333L14.4667 14.2C14.6889 14.4 14.85 14.6278 14.95 14.8833C15.05 15.1389 15.1 15.4 15.1 15.6667C15.1 15.9333 15.05 16.1944 14.95 16.45C14.85 16.7056 14.6889 16.9333 14.4667 17.1333L8.2 23H17.3333C17.8 23 18.1944 23.1611 18.5167 23.4833C18.8389 23.8056 19 24.2 19 24.6667C19 25.1333 18.8389 25.5278 18.5167 25.85C18.1944 26.1722 17.8 26.3333 17.3333 26.3333C12.1939 25.2917 9.37276 25.2633 4.4 26.3333Z"
      fill="currentColor"
    />
    <g clip-path="url(#clip0_6528_182)">
      <path
        d="M30 16C30 16.3945 29.6802 16.7143 29.2857 16.7143H26.7143C26.162 16.7143 25.7143 17.162 25.7143 17.7143V20.2857C25.7143 20.6802 25.3945 21 25 21V21C24.6055 21 24.2857 20.6802 24.2857 20.2857V17.7143C24.2857 17.162 23.838 16.7143 23.2857 16.7143H20.7143C20.3198 16.7143 20 16.3945 20 16V16C20 15.6055 20.3198 15.2857 20.7143 15.2857H23.2857C23.838 15.2857 24.2857 14.838 24.2857 14.2857V11.7143C24.2857 11.3198 24.6055 11 25 11V11C25.3945 11 25.7143 11.3198 25.7143 11.7143V14.2857C25.7143 14.838 26.162 15.2857 26.7143 15.2857H29.2857C29.6802 15.2857 30 15.6055 30 16V16Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6528_182">
        <rect
          width="12"
          height="12"
          fill="currentColor"
          transform="translate(19 10)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>