import { math } from "@xeokit/xeokit-sdk";
import { geometry } from "./geometry"; 
import { XeokitMediator } from "../../XeokitMediator"; 

class FrustumPlane {

  constructor() {
      this.normal = math.vec3();
      this.offset = 0;
      this.testVertex = math.vec3();
  }

  set(nx, ny, nz, offset) {
      const s = 1.0 / Math.sqrt(nx * nx + ny * ny + nz * nz);
      this.normal[0] = nx * s;
      this.normal[1] = ny * s;
      this.normal[2] = nz * s;
      this.offset = offset * s;
      this.testVertex[0] = (this.normal[0] >= 0.0) ? 1 : 0;
      this.testVertex[1] = (this.normal[1] >= 0.0) ? 1 : 0;
      this.testVertex[2] = (this.normal[2] >= 0.0) ? 1 : 0;
  }
}

/**
* @private
*/
class XeokitFrustum {
  constructor() {
      this.planes = [
          new FrustumPlane(), new FrustumPlane(), new FrustumPlane(),
          new FrustumPlane(), new FrustumPlane(), new FrustumPlane()
      ];
  }
}

export const frustum = {

  INSIDE: 0,
  INTERSECT: 1,
  OUTSIDE: 2,

  createXeokitFrustum() {
    return new XeokitFrustum()
  },

  setFrustum(frustum, viewMat, projMat) {
    const m = math.mat4();
    math.mulMat4(projMat, viewMat, m);

    const m0 = m[0];
    const m1 = m[1];
    const m2 = m[2];
    const m3 = m[3];
    const m4 = m[4];
    const m5 = m[5];
    const m6 = m[6];
    const m7 = m[7];
    const m8 = m[8];
    const m9 = m[9];
    const m10 = m[10];
    const m11 = m[11];
    const m12 = m[12];
    const m13 = m[13];
    const m14 = m[14];
    const m15 = m[15];

    frustum.planes[0].set(m3 - m0, m7 - m4, m11 - m8, m15 - m12);
    frustum.planes[1].set(m3 + m0, m7 + m4, m11 + m8, m15 + m12);
    frustum.planes[2].set(m3 - m1, m7 - m5, m11 - m9, m15 - m13);
    frustum.planes[3].set(m3 + m1, m7 + m5, m11 + m9, m15 + m13);
    frustum.planes[4].set(m3 - m2, m7 - m6, m11 - m10, m15 - m14);
    frustum.planes[5].set(m3 + m2, m7 + m6, m11 + m10, m15 + m14);
  },

  frustumIntersectsAABB3(frustum, aabb) {

    let ret = this.INSIDE;
    const tempVec3a = math.vec3()
    const tempVec3b = math.vec3()

    const min = tempVec3a;
    const max = tempVec3b;

    min[0] = aabb[0];
    min[1] = aabb[1];
    min[2] = aabb[2];
    max[0] = aabb[3];
    max[1] = aabb[4];
    max[2] = aabb[5];

    const bminmax = [min, max];

    for (let i = 0; i < 6; ++i) {
      const plane = frustum.planes[i];
      if (((plane.normal[0] * bminmax[plane.testVertex[0]][0]) +
        (plane.normal[1] * bminmax[plane.testVertex[1]][1]) +
        (plane.normal[2] * bminmax[plane.testVertex[2]][2]) +
        (plane.offset)) < 0.0) {
        return this.OUTSIDE;
      }

      if (((plane.normal[0] * bminmax[1 - plane.testVertex[0]][0]) +
        (plane.normal[1] * bminmax[1 - plane.testVertex[1]][1]) +
        (plane.normal[2] * bminmax[1 - plane.testVertex[2]][2]) +
        (plane.offset)) < 0.0) {
        ret = this.INTERSECT;
      }
    }

    return ret;
  },

  pointInFrustum(frustum, point) {
    let result = true;

    for (let i = 0; i < 6; ++i) {
      const plane = frustum.planes[i];
      const distance = plane.normal[0] * point[0] + plane.normal[1] * point[1] + plane.normal[2] * point[2] + plane.offset;

      if (distance < 0) {
        result = false;
        break;
      }
    }

    return result;
  },

  findAllEntitiesIntersectsFrustum(frustum, selectionSquare, centerPos){
    let selectedEntities = []
    let distances = []
    for (let entity of Object.values(XeokitMediator.viewer.scene.objects)) {
      if (!entity.meshes[0].edgeIndices) continue
      let res = this.frustumIntersectsAABB3(frustum, entity.aabb)

      if (res === this.INSIDE) {
        if (entity.pickable && entity.visible) {
          selectedEntities.push(entity)
          distances.push(getDistanceBetweenFrustumVertexAndMeshEdges(entity.meshes[0], centerPos))
        }
      }
      else if (res === this.INTERSECT && this.isEntityIntersectFrustum(entity, [selectionSquare[0], selectionSquare[3]], [selectionSquare[1], selectionSquare[2]], frustum)) {
        if (entity.pickable && entity.visible) {
          selectedEntities.push(entity)
          distances.push(getDistanceBetweenFrustumVertexAndMeshEdges(entity.meshes[0], centerPos))
        }
      }
    }
    geometry.utils.quickSortEntitiesByDistances(selectedEntities, distances)
    return selectedEntities

    function getDistanceBetweenFrustumVertexAndMeshEdges(mesh, centerPos){
      // Находим расстояние от точки фрустума до точки, ближайшей к ней на ребрах меша
      const edges = geometry.extraction.getEdgesByMesh(mesh)
      const nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(centerPos, edges) || [0, 0, 0]
      return geometry.math.distanceBetweenByCoordinates(centerPos, nearestCorner)
    }
  },

  isEntityIntersectFrustum(entity, startCanvasPos, endCanvasPos) {
    for (let meshIndex = 0; meshIndex < entity.meshes.length; meshIndex++) {
      if(this.checkTrianglesIntersectionByEntity(entity.meshes[meshIndex], startCanvasPos, endCanvasPos)){
        return true
      }
    }
    return false
  },

  checkTrianglesIntersectionByEntity(entity, startCanvasPos, endCanvasPos) {
    const uniquePositions = [];
    const indicesLookup = [];
    const weldedIndices = [];
    const compa = new Uint16Array(3);
    const compb = new Uint16Array(3);
    const compc = new Uint16Array(3);
    const a = math.vec3();
    const b = math.vec3();
    const c = math.vec3();

    weldVertices(entity.positions, entity.indices);
    return buildFaces(entity.indices.length, entity.positionsDecodeMatrix)

    function weldVertices(positions, indices) {
      const positionsMap = {};
      let vx;
      let vy;
      let vz;
      let key;
      const precision = 10000;
      let i;
      let len;
      let lenUniquePositions = 0;

      for (i = 0, len = positions.length; i < len; i += 3) {
        vx = positions[i];
        vy = positions[i + 1];
        vz = positions[i + 2];
        key = vx * precision + '_' + vy * precision + '_' + vz * precision;
        if (positionsMap[key] === undefined) {
          positionsMap[key] = lenUniquePositions / 3;
          uniquePositions[lenUniquePositions++] = vx;
          uniquePositions[lenUniquePositions++] = vy;
          uniquePositions[lenUniquePositions++] = vz;
        }
        indicesLookup[i / 3] = positionsMap[key];
      }
      for (i = 0, len = indices.length; i < len; i++) {
        weldedIndices[i] = indicesLookup[indices[i]];
      }
    }

    function buildFaces(numIndices, positionsDecodeMatrix) {
      for (let i = 0, len = numIndices; i < len; i += 3) {
        const ia = ((weldedIndices[i]) * 3);
        const ib = ((weldedIndices[i + 1]) * 3);
        const ic = ((weldedIndices[i + 2]) * 3);

        if (positionsDecodeMatrix) {
          compa[0] = uniquePositions[ia];
          compa[1] = uniquePositions[ia + 1];
          compa[2] = uniquePositions[ia + 2];
          compb[0] = uniquePositions[ib];
          compb[1] = uniquePositions[ib + 1];
          compb[2] = uniquePositions[ib + 2];
          compc[0] = uniquePositions[ic];
          compc[1] = uniquePositions[ic + 1];
          compc[2] = uniquePositions[ic + 2];
          // Decode
          if(entity.entityMatrix) {
            math.decompressPosition(compa, positionsDecodeMatrix, a);
            geometry.math.mulMat4v3(entity.entityMatrix, a, a);
            math.decompressPosition(compb, positionsDecodeMatrix, b);
            geometry.math.mulMat4v3(entity.entityMatrix, b, b);
            math.decompressPosition(compc, positionsDecodeMatrix, c);
            geometry.math.mulMat4v3(entity.entityMatrix, c, c);
          }
          else {
            math.decompressPosition(compa, positionsDecodeMatrix, a);
            math.decompressPosition(compb, positionsDecodeMatrix, b);
            math.decompressPosition(compc, positionsDecodeMatrix, c);
          }
        }
        else {
          a[0] = uniquePositions[ia];
          a[1] = uniquePositions[ia + 1];
          a[2] = uniquePositions[ia + 2];
          b[0] = uniquePositions[ib];
          b[1] = uniquePositions[ib + 1];
          b[2] = uniquePositions[ib + 2];
          c[0] = uniquePositions[ic];
          c[1] = uniquePositions[ic + 1];
          c[2] = uniquePositions[ic + 2];
        }

        const tileCenter = entity.layer._state.origin
        let t1 = geometry.transform.worldPosToCanvasPos(geometry.transform.transformByModelSettings([a[0] + tileCenter[0], a[1] + tileCenter[1], a[2] + tileCenter[2]], entity), XeokitMediator.viewer)
        let t2 = geometry.transform.worldPosToCanvasPos(geometry.transform.transformByModelSettings([b[0] + tileCenter[0], b[1] + tileCenter[1], b[2] + tileCenter[2]], entity), XeokitMediator.viewer)
        let t3 = geometry.transform.worldPosToCanvasPos(geometry.transform.transformByModelSettings([c[0] + tileCenter[0], c[1] + tileCenter[1], c[2] + tileCenter[2]], entity), XeokitMediator.viewer)

        if (geometry.intersection.intersectionTriangleAndRectangleOnCanvas(t1, t2, t3, startCanvasPos, endCanvasPos)) {
          return true
        }
      }
      return false
    }
  },

  isEntityInsideFrustum(entity, frustum) {
    let inside = true
    for (let meshIndex = 0; meshIndex < entity.meshes.length; meshIndex++) {
      if (geometry.extraction.getFacesVerticeByEntity(entity.meshes[meshIndex]).some(points => geometry.frustum.pointInFrustum(frustum, points) == false)) {
        inside = false
        break
      }
    }
    return inside
  },

  getFrustumEdges(frustum) {
    // Находим все точки пересечения плоскостей
    let planes = [...frustum.planes]
    let intersectionPoints = []

    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[0], planes[2], planes[4]))
    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[0], planes[2], planes[5]))
    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[0], planes[3], planes[4]))
    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[0], planes[3], planes[5]))

    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[1], planes[2], planes[4]))
    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[1], planes[2], planes[5]))
    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[1], planes[3], planes[4]))
    intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[1], planes[3], planes[5]))

    // Определяем рёбра фрустума
    const edges = [
      [0, 1],
      [1, 3],
      [3, 2],
      [2, 0],
      [4, 5],
      [5, 7],
      [7, 6],
      [6, 4],
      [0, 4],
      [1, 5],
      [2, 6],
      [3, 7],
    ]

    // Преобразуем индексы вершин в координаты рёбер
    return edges.map(([a, b]) => [intersectionPoints[a], intersectionPoints[b]])
  },
}