export class EquipmentUtils {
  static flatlist (tree, onlyNodeIds) {
    let array = []
    tree.forEach(node => {
      array.push(node)
      if(node.isNode) onlyNodeIds.push(node.uuid)
      EquipmentUtils.flatlist(node.children, onlyNodeIds).forEach(childNode => {
        array.push(childNode)
      })
      if(node.equipments.length>0) array.push(...node.equipments)
    })
    return array
  }
}