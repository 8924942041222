import { SectionCubePlugin } from "./sectionCubePlugin"
import { useSectionCubeStore } from '@/pinia'
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"

/*eslint-disable no-dupe-class-members*/
export class SectionCube {
  
  /**@type {SectionCubePlugin} */
  static #_sectionCubePlugin = null

  static get sectionCubePlugin() {
    return this.#_sectionCubePlugin
  }

  static get #_sectionCubeStore() {
    return useSectionCubeStore()
  }

  static get isFloorsLoading() {
    return this.#_sectionCubeStore.isFloorsLoading
  }

  static get floors() {
    return this.#_sectionCubeStore.floors
  }

  static get sectionCubes() {
    return this.#_sectionCubeStore.sectionCubes
  }

  static get selectedFloor() {
    return this.#_sectionCubeStore.selectedFloor
  }

  static get visible() {
    return this.#_sectionCubeStore.visible
  }

  static get active() {
    return this.#_sectionCubeStore.active
  }

  static get aabb() {
    return this.#_sectionCubePlugin.aabb
  }

  static get sectionCubeEdited() {
    return this.#_sectionCubeStore.edited
  }

  static init() {
    this.#_sectionCubePlugin = new SectionCubePlugin(XeokitMediator.viewer)

    this.#_sectionCubePlugin.on('update', (data) => {
      if (this.visible && data.visible && !(data.aabb instanceof Float64Array) && !this.sectionCubeEdited) {
        this.setSectionCubeEdited(true)
      }
    })

    XeokitMediator.xktLoader.on('loadingDone', () => {
      if (this.visible && this.active) this.setSectionCubeEdited(true)
    })
  }

  static setActive(active) {
    this.#_sectionCubePlugin.active = active
    this.#_sectionCubeStore.setActive(active)

    if (active) {
      this.setVisible(true)
    }
    else {
      XeokitMediator.SectionCube.setSelectedFloor(null)
      XeokitMediator.ElementsSettings.setElementsVisible(XeokitMediator.viewer.scene.objectIds, true)

      XeokitMediator.ElementsSelection.selectElements(XeokitMediator.ElementsSelection.selectedElements)
    }
  }

  static setVisible(visible) {
    this.#_sectionCubePlugin.visible = visible
    this.#_sectionCubeStore.setVisible(visible)
  }

  static setAabb(aabb) {
    this.#_sectionCubePlugin.aabb = aabb
  }

  static setSectionCubeDefaultView() {
    const aabb = XeokitMediator.viewer.scene.getAABB(XeokitMediator.viewer.scene.objectIds)
    XeokitMediator.SectionCube.setAabb(aabb)
    XeokitMediator.SectionCube.setSelectedFloor(null)
    XeokitMediator.ElementsSettings.setElementsVisible(XeokitMediator.viewer.scene.objectIds, true)
    this.setSectionCubeEdited(false)
  }

  static loadFloors() {
    return this.#_sectionCubeStore.loadFloors()
  }

  static createSectionCube(sectionCube) {
    return this.#_sectionCubeStore.createSectionCube(sectionCube)
  }

  static setSelectedFloor(floorUuid) {
    if (floorUuid != null) this.setSectionCubeEdited(true)
    return this.#_sectionCubeStore.setSelectedFloor(floorUuid)
  }

  static deleteSectionCube(id) {
    return this.#_sectionCubeStore.deleteSectionCube(id)
  }

  static patchSectionCube(data) {
    return this.#_sectionCubeStore.patchSectionCube(data)
  }

  static setSectionCubeEdited(edited) {
    return this.#_sectionCubeStore.setSectionCubeEdited(edited)
  }
}