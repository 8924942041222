export default ({ Factory }) => ({

  setTReceiver: (schema) => Factory.receiveres.put(schema),

  createTReceiver: (schema) => Factory.receiveres.post(schema),

  setMnemoschema: (mnemoschema) => Factory.mnemoschemes.put(mnemoschema),

  createMnemoschema: (mnemoschema) => Factory.telemetry.mnemoschemes.post(mnemoschema),

  setTChannel: (channel) => Factory.channels.put(channel),

  createTChannel: (channel) => Factory.channels.post(channel)


})