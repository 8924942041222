import { api } from '@/api'
import i18n from '@/plugins/i18n'
// import { AlertService } from '@app/AlertService'
import { useDB } from '@/api/idb/db'
import { projectService } from '@/_services'
import { defineStore } from 'pinia'
import { AxisTreeStateCache } from '@models/axis'
import { TableElement } from '@models/elements/TableElement'

import vuex from '@/store'

const defaultHeaders = () => [
  { name: 'section.groupingTable.column.structure', value: 'oname', order: 1, view: true, width: 250 },
  { name: 'section.groupingTable.column.quantity', value: 'elementsCount', order: 2, view: true, width: 250 },
  { name: 'section.groupingTable.column.marker', value: 'color', order: 3, view: true, width: 250 },
]

const stateCache = new AxisTreeStateCache()

export const useAxisGroupTableStore = defineStore('axisGroupTable', {
  state: () => ({
    headers: defaultHeaders(),
    groupingTableAxis: null,
    groupsTableActive: false,
    groupingTableAxisMeasure: null,
    numberOfDisclosedNodes: 0,
    isLoadingGrouping: false,

    axisUuid: null,
    selectedAxisGroupTable: null,
  }),

  getters: {
    projectUuid() {
      return vuex.getters['project/projectUuid']
    },

    visibleHeaders() {
      return this.headers.filter((o) => o.view).sort((a, b) => a.order - b.order)
    },

    treeAllVisibleGroupingNodes() {
      const nodeCount = this.numberOfDisclosedNodes
      if (nodeCount == 0) {
        return this.groupingTableAxis ?? []
      }

      if (nodeCount == stateCache.totalNodes) {
        return stateCache.tree.flatlist
      }

      return findVisibleNodes(this.groupingTableAxis)
    },
  },

  actions: {
    loadAxisDataList(uuid) {
      return vuex.dispatch('axis/loadAxisDataList', uuid)
    },

    addColumnToTable(column) {
      const name = column.value.value
      const attr = column.attr.attr
      const attrName = column.attr.name
      const existingColumn = this.headers.find((col) => col.value === name)

      const newColumn = { ...column.value }

      if (!existingColumn) {
        if (name === 'geometry') {
          newColumn.name = attrName
          if (attr == 'area' || attr == 'volume') {
            newColumn.value = 'geometryInfo.' + attr
          } else {
            newColumn.value = 'geometryInfo.additionalData.' + geometryAdditionalData[attr]
          }
        }

        if (name === 'identification') {
          newColumn.name = attrName
          newColumn.value = attr
        }

        if (name == 'attribute') {
          // state.selectedAttribute = column.attr
          newColumn.name = column.attr.title || attrName
          newColumn.value = 'attribute.' + attrName
        }

        const el = this.headers.find((col) => col.value == newColumn.value)
        if (!el) this.headers.push({ ...newColumn, view: true, order: this.headers.length, width: 250 })
      }
    },

    isTreeNodeDisclosed(node) {
      if (this.numberOfDisclosedNodes == 0) return false
      if (this.numberOfDisclosedNodes == stateCache.totalNodes) return true
      return stateCache.disclosedNodes.has(node)
    },

    getItemSelectedAttribute(item, columnAttr) {
      let result = '-'
      const findSelectedAttribute = (nodes) => {
        for (const node of nodes) {
          if (node.attribute) {
            // Можно сравнивать по UUID, но нужно ли???
            // if (node?.elementClassName === state.selectedAttribute?.name || node?.elementClass === state.selectedAttribute?.uuid) {
            //   return node.stringValue
            // }
            const attrName = columnAttr.toLowerCase() || ''
            if (
              node?.oname?.toLowerCase() === attrName ||
              node?.elementClassName?.toLowerCase() === attrName ||
              node?.name?.toLowerCase() === attrName
            ) {
              return node.stringValue
            }
          }

          if (node.children && node.children.length > 0) {
            const found = findSelectedAttribute(node.children)
            if (found && found !== '-') return found
          }
        }
        return result
      }

      return findSelectedAttribute(item.attributeList)
    },

    getAxisTreeFromTable(axisUuid) {
      return useDB()
        .getOrCreate('groupTable', axisUuid, defaultHeaders())
        .then((headers) => {
          this.headers = headers
          this.isLoadingGrouping = true
          this.axisUuid = axisUuid
        })
        .then(() => projectService.loadGroupTableAxis(this.projectUuid, axisUuid))
        .then((axis) => {
          this.groupingTableAxis = axis.tree.map((el) => new TableElement(el))
          this.groupingTableAxisMeasure = axis.axis
        })
        .finally(() => (this.isLoadingGrouping = false))
    },

    loadParamForCondition(paramName) {
      return projectService.loadParamForCondition(this.projectUuid, paramName, '', vuex.getters['project/hashProject'])
    },

    async loadElementAttrs(elemtUuidList) {
      const data = await projectService.loadElementsAttribute(elemtUuidList)
      return data
    },

    toggleDisclosedNode(node) {
      if (this.isTreeNodeDisclosed(node)) {
        stateCache.disclosedNodes.delete(node)
        stateCache.numberOfDisclosedNodes -= 1
        this.numberOfDisclosedNodes = stateCache.numberOfDisclosedNodes
      } else if (node.children.length > 0) {
        stateCache.disclosedNodes.add(node)
        stateCache.numberOfDisclosedNodes += 1
        this.numberOfDisclosedNodes = stateCache.numberOfDisclosedNodes
      }
    },

    //fetchVisibleHeaders
    async saveHeaders() {
      const key = this.selectedAxisGroupTable?.value
      if (key) return useDB().put('groupTable', this.headers, key)
    },

    createReport() {
      let headers = this.visibleHeaders.map(el => {
        return {...el, name:i18n.t(el.name)}
      })
      return api.collisions.initCollisionsReportProcess(this.projectUuid, 'ELEMENTS', {
        groupUuid: this.axisUuid,
        headers: headers,
      })
    },
  },
})

function findVisibleNodes(tree) {
  let array = []
  for (const node of tree) {
    array.push(node)
    if (stateCache.disclosedNodes.has(node)) {
      if (node.isPenult) array = array.concat(node.children)
      else array = array.concat(findVisibleNodes(node.children))
    }
  }
  return array
}

const geometryAdditionalData = {
  areax: 'SURFACE_AREA_ALONG_X',
  areay: 'SURFACE_AREA_ALONG_Y',
  areaz: 'SURFACE_AREA_ALONG_Z',
  boundx: 'BOUNDING_BOX_SIZE_ALONG_X',
  boundy: 'BOUNDING_BOX_SIZE_ALONG_Y',
  boundz: 'BOUNDING_BOX_SIZE_ALONG_Z',
  largeFaceArea: 'LARGEST_FACE_AREA',
}
