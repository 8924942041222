import { fetchProjectUuid } from "@/providers/data/project.dataProvider"
import { api } from "@/api"

export async function fetchProjectGrids() {
  const projectUuid = fetchProjectUuid()
  return await api.grids.fetchProjectGrids(projectUuid)
}

export async function fetchGridsIfcTree() {
  const projectUuid = fetchProjectUuid()
  return await api.grids.fetchGridsIfcTree(projectUuid)
}

export async function toggleModelGridsVisibility(revisionUuid) {
  return await api.grids.toggleGridsVisibility(revisionUuid)
}