import RobotoBlack from '@/plugins/threeJs/plugins/fonts/Roboto_Black.json'
import RobotoBold from '@/plugins/threeJs/plugins/fonts/Roboto_Bold.json'
import RobotoRegular from '@/plugins/threeJs/plugins/fonts/Roboto_Regular.json'
import RobotoMediumRegular from '@/plugins/threeJs/plugins/fonts/Roboto_Medium_Regular.json'

export const fonts = {
  RobotoBold: RobotoBold,
  RobotoRegular: RobotoRegular,
  RobotoMediumRegular: RobotoMediumRegular,
  RobotoBlack: RobotoBlack
}