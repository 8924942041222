import { api } from '@/api'
import { AxisDataType, AxisType, AxisTreeStateCache, AxisUtils, AxisNode } from '@models/axis'
import { projectService } from '@/_services'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { CollisionBimAnnotations } from '@/components/project/panel/left/components/collision/collisionBimAnnotations'
import selected from '../../selected.module'
import { hexToRgb } from '@/utils'


const childPriority = false       // True  - приоритет подсветки дочерних элеметнов, False - приоритет подсветки родителей

const geometryAttr = {
  TOTAL_SURFACE_AREA: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c201", notCreate: false },
  TOTAL_SHAPE_VOLUME: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c202", notCreate: false },
  SURFACE_AREA_ALONG_X: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c204", notCreate: false },
  SURFACE_AREA_ALONG_Y: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c205", notCreate: false },
  SURFACE_AREA_ALONG_Z: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c206", notCreate: false },
  BOUNDING_BOX_SIZE_ALONG_X: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c207", notCreate: false },
  BOUNDING_BOX_SIZE_ALONG_Y: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c208", notCreate: false },
  BOUNDING_BOX_SIZE_ALONG_Z: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c209", notCreate: false },
  LARGEST_FACE_DIRECTION: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c203", notCreate: true },
  LARGEST_FACE_AREA: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c210", notCreate: true }
}
  
const getDefaultState = () => {
  return {
    dataType: null,

    // items: [], // отключил реактивность - оптимизация памяти
    accumulator: 0,

    inPreparation: false,
    numberOfDisclosedNodes: 0,
    numberOfSelectedEndpoints: 0,

    element: null,
    node: null,
    clickOnRow: false
  }
}
const state = getDefaultState()

function idForColorize(tree, parent, defaultColorize, ids = new Set()) {
  console.log('defaultColorize')
  console.log(defaultColorize)
  tree.forEach(node => {
    if( node.globalId != null && !node.globalId.startsWith("S") &&
          ( defaultColorize.findIndex(el => el.id == node.globalId) == -1 || 
            ( defaultColorize.findIndex(el => el.id == node.globalId) != -1 && 
              ( parent == null || parent.startsWith("S") )
            ) 
          ) 
    ) ids.add(node.globalId)

    if (node.children.length > 0) {
      idForColorize(node.children, node.globalId, defaultColorize, ids)
    }
  })
  return ids
}

function idForColorizeByChild (data, defaultColorize) {
  let items = data.map(i => new AxisNode(i))
  let flatlist = AxisUtils.flatlist(items)
  let ids = new Set()
  flatlist.forEach(node => {
    if( node.globalId != null && !node.globalId.startsWith("S") &&
          ( defaultColorize.findIndex(el => el.id == node.globalId) == -1 || 
            ( defaultColorize.findIndex(el => el.id == node.globalId) != -1 && 
              ( node.parent.globalId == null || node.parent.globalId.startsWith("S") ) &&
              ( flatlist.findIndex(el => el.globalId == node.globalId && el.parent.globalId != null && !el.parent.globalId.startsWith("S")) == -1 ) 
            ) 
          ) 
    ) ids.add(node.globalId)
  })
  return ids
}


export default {
  namespaced: true,

  modules: {
    selected
  },

  state,

  getters: {
    totalEndpoints: ( { items } ) => items?.reduce((acc, it) => acc+it.numberOfEndpoints, 0) ?? 0,
    isTreeInPreparation: ({ inPreparation }) => inPreparation,
    areAllTreeNodesDisclosed: ({ numberOfDisclosedNodes }) => numberOfDisclosedNodes == stateCache.totalNodes,
    areAllEndpointsSelected: ({ numberOfSelectedEndpoints }) => numberOfSelectedEndpoints == stateCache.totalEndpoints,
    
    isTreeNodeDisclosed: ({ numberOfDisclosedNodes }) => node => {
      if (numberOfDisclosedNodes == 0) return false
      else if (numberOfDisclosedNodes == stateCache.totalNodes) return true
      return stateCache.disclosedNodes.has(node)
    },

    isTreeEndpointSelected: ({ numberOfSelectedEndpoints }) => node => {
      if (numberOfSelectedEndpoints == 0) return false
      else if (numberOfSelectedEndpoints == stateCache.totalEndpoints) return true
      return stateCache.selectedEndpointExtraIDs.has(node.extraId)
    },
    
    treeAllVisibleNodes: ({ numberOfDisclosedNodes, items, accumulator }) => {
      accumulator
      if (numberOfDisclosedNodes == 0) return items ?? []
      else if (numberOfDisclosedNodes == stateCache.totalNodes) return stateCache.tree.flatlist
      let nodes = findVisibleNodes(items)
      return nodes
    },

    numberOfSelectedEndpointsForNode: ({ numberOfSelectedEndpoints }) => node => {
      if (numberOfSelectedEndpoints == 0) return 0
      let nodeData = stateCache.nodesDataMap.get(node)
      let num = nodeData?.numberOfSelected ?? 0
      return num
    },
    
    // !

    element: ({ element }) => element,
    highlightedNode: ({ node }) => node,

    selectedNodes: ({ numberOfSelectedEndpoints }) => numberOfSelectedEndpoints > 0 ? stateCache.tree.selectedNodes : [],
  },

  // *
  // * Mutations

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setClickOnRow: (state, val) => { state.clickOnRow = val },

    prepareForDataType: (state, dataType) => { 
      state.dataType = dataType 
      state.inPreparation = true 
      state.numberOfDisclosedNodes = 0
      state.numberOfSelectedEndpoints = 0
      state.items = []
    },

    setDataType: (state, type) => { state.dataType = type },
    
    startPreparation (state) { 
      state.inPreparation = true 
      state.numberOfDisclosedNodes = 0
      state.numberOfSelectedEndpoints = 0
      state.items = []
    },

    finishPreparation (state) { 
      state.inPreparation = false 
    },

    setItems (state, items) {
      state.accumulator += 1
      state.items = items
    },

    updateNumberOfDisclosedNodes (state, num) {
      state.numberOfDisclosedNodes = num
    },

    updateNumberOfSelectedEndpoints (state, num) {
      state.numberOfSelectedEndpoints = num
    },

    // !

    SELECT_NODE: (state, node) => {
      state.node = node
    },
    
    SET_ELEMENT: (state, data) => {
      if (data) {
        if (data.geometryInfo && data.geometryInfo.additionalData) {
          let json_data = JSON.parse(data.geometryInfo.additionalData)
          data.geometry = Object.keys(json_data).map(key => ({
            "name": key,
            "operand": "GEOMETRY",
            "uuid" : geometryAttr[key]?.uuid,
            "topLevel": true,
            "notCreateGroup": geometryAttr[key]?.notCreate,
            "doubleValue": json_data[key], 
            "stringValue": "" + json_data[key], 
            "unit": key.indexOf("_AREA") > 0 ? "{unit.shortName.square_metre}" : key.indexOf("_SIZE") > 0 ? "{unit.shortName.metre}" : key.indexOf("_VOLUME") > 0 ?  "{unit.shortName.cubic_metre}" : ""
          }))
        }
        data.details = data.children.sort((a, b) => (a.children.length > 0 ? 1 : 0) - (b.children.length > 0 ? 1 : 0) || a.name.localeCompare(b.name))
        data.details.forEach(el => { if(el.children.length > 0) el.children.sort((a, b) => a.name.trim().localeCompare(b.name.trim())) })
      }
      
      state.element = data
    },
  },

  // *
  // * Actions

  actions: {
    prepareForDataType ({ commit }, dataType) {
      let isValidDataType = Object.values(AxisDataType).includes(dataType)
      if (!isValidDataType) {
        throw new Error('[Axis/Tree] Type cannot be undefined')
      }
      commit('setDataType', dataType)
      commit('startPreparation')
    },

    commitTree ({ commit }, rawData) {
      stateCache.saveTree(rawData)

      commit('setItems', stateCache.tree.items)
      commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)
      commit('finishPreparation')
    },

    init ({ dispatch, commit }, type) {
      if (type !== AxisDataType.ELEMENTS && type !== AxisDataType.COLLISIONS) {
        throw new Error('[Axis/Tree] Type cannot be undefined')
      }
      commit('setDataType', type)
      dispatch('fetchTree')
    },

    async fetchTree ({ state, commit, dispatch, rootGetters, rootState }, group = null) {
      const initTab = rootState.project.activeGlobalTab
      if (state.dataType == AxisDataType.COSTING) return

      const projectUuid = rootGetters['project/projectUuid']
      const hashProject = rootGetters['project/hashProject']
      const axis = group || rootGetters['right-axis/selectedAxis']

      if (axis.type == AxisType.UNDEFINED) throw new Error('[Axis/Tree] Type cannot be undefined')
      
      commit('startPreparation')

      let fetch = state.dataType == AxisDataType.COLLISIONS ? 'fetchCollisionTree' : 'fetchElementTree'
      let selectedFromServer = state.dataType == AxisDataType.COLLISIONS ? true : false
      let data = await dispatch(fetch, { axis, projectUuid, hashProject })

      if(data) {
        data.forEach(node => {
          if (node.classTitle === 'Project') {
            node.name = rootGetters['project/flatlist'].find(model => model.uuid === node.model)?.title || node.name
          }
        })

        if(initTab == rootState.project.activeGlobalTab && axis.value == (group || rootGetters['right-axis/selectedAxis']).value ){
          stateCache.saveTree(data, selectedFromServer)
          
          commit('setItems', stateCache.tree.items)
          
          if(selectedFromServer) {
            let selectedNodes = stateCache.tree.selectedNodes
            commit('collision/search/RESET_WITH_FILTER', { selectedNodes }, { root: true })
            dispatch('collision/search/selectAxisNodes', { node: selectedNodes, send: false }, { root: true })
          }
          
          commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)
          commit('finishPreparation')
        }
      }
    },

    async fetchElementTree (context, { axis, projectUuid, hashProject }) {
      let custom = axis.type == AxisType.CUSTOM
      let data = await api.axes.fetchElementTree(projectUuid, axis.value, custom, hashProject)
      return data
    },

    // async fetchCollisionTree ({ commit }, { axis, projectUuid }) {
    async fetchCollisionTree ({dispatch}, { axis, projectUuid }) {
      let data = await api.axes.fetchCollisionTree(projectUuid, axis.value)
      dispatch('stub')
      // if (data instanceof Array) {
      //   let axisNodes = data.reduce(onlyEndpoints, []).filter(el => el.selected)
      //   commit('collision/search/RESET_WITH_FILTER', { axisNodes }, { root: true })
      // }
      return data
    },

    toggleDisclosedNode ({ commit, getters }, node) {
      if (node.children.length) {
        if (getters.isTreeNodeDisclosed(node)) {
          stateCache.disclosedNodes.delete(node)
          stateCache.numberOfDisclosedNodes--
          commit('updateNumberOfDisclosedNodes', stateCache.numberOfDisclosedNodes)
        }
        else {
          if(node.children.length > 0) {
            stateCache.disclosedNodes.add(node)
            stateCache.numberOfDisclosedNodes++
            commit('updateNumberOfDisclosedNodes', stateCache.numberOfDisclosedNodes)
          }
        }
      }
    },

    expandAllTreeNodes ({ commit }) {
      stateCache.disclosedNodes = new WeakSet(stateCache.tree.onlyNodes)
      stateCache.numberOfDisclosedNodes = stateCache.totalNodes
      commit('updateNumberOfDisclosedNodes', stateCache.totalNodes)
    },
    
    collapseAllTreeNodes ({ commit }) {
      stateCache.disclosedNodes = new WeakSet()
      stateCache.numberOfDisclosedNodes = 0
      commit('updateNumberOfDisclosedNodes', 0)
    },

    selectAllTreeEndpoints ({ commit, rootState, dispatch, state }) {
      stateCache.selectAll()
      commit('updateNumberOfSelectedEndpoints', stateCache.totalEndpoints)

      if (state.dataType !== AxisDataType.COLLISIONS) {
        let ids = Array.from(stateCache.selectedEndpointGlobalIDs)
        // XeokitMediator.viewer.scene.setObjectsHighlighted(ids, true)
        XeokitMediator.ElementsSelection.selectElements(ids)
      }
      if (rootState.project.activeGlobalTab == 'collision') {
         let selectedNodes = stateCache.tree.flatlist.filter(node => node.children.length == 0)
         commit('collision/search/RESET_WITH_FILTER', { selectedNodes }, { root: true })
         dispatch('collision/search/selectAxisNodes', { node: selectedNodes }, { root: true })
      }
    },
    
    deselectAllTreeEndpoints ({ commit, rootState, dispatch }) {
      // let ids = Array.from(stateCache.selectedEndpointGlobalIDs)
      // XeokitMediator.viewer.scene.setObjectsHighlighted(ids, false)
      XeokitMediator.ElementsSelection.selectElements([])
      
      stateCache.deselectAll()
      commit('updateNumberOfSelectedEndpoints', 0)

      if (rootState.project.activeGlobalTab == 'collision') {
        commit('collision/search/RESET_WITH_FILTER', { }, { root: true })
        dispatch('collision/search/selectAxisNodes', { node: [] }, { root: true })
        if (!rootState.collision.table.taskCollision.isActive) {
          commit('collision/table/SET_TABLE_ITEMS', [], {root: true})
        }
        commit('collision/table/SET_TABLE_ITEMS_BUFFER', [], {root: true})
      }
    },

    // selectNodesByAxisMounted({ commit }, globalUuids) {
    //   let uuids = Array.isArray(globalUuids) ? globalUuids : [globalUuids]
    //   uuids = new Set(uuids)
    //   let allNodes = stateCache.tree.flatlist
    //   let nodesArr = []
    //   for (let i = 0; i < allNodes.length; i++) {
    //     if (uuids.has(allNodes[i].uuid)) {
    //       nodesArr.push(allNodes[i])
    //     }
    //   }
    //   for (let i = 0; i < nodesArr.length; i++) {
    //     //stateCache.setNodeSelected(nodesArr[i])
    //     stateCache.toggleNodeSelection(nodesArr[i])
    //   }
      
    //   commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)
    // },

    deselectAll() {
      stateCache.deselectAll()

      // Если потребуется вызов снаружи
      //commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)
    },

    selectNodesBySelectionFrame({ commit, rootState, getters }, globalUuids){
      if (stateCache.tree.flatlist.length > 0) {
        stateCache.deselectAll()
        let uuids = Array.isArray(globalUuids) ? globalUuids : [globalUuids]
        uuids = new Set(uuids)
        let allNodes = stateCache.tree.flatlist
        let nodesArr = []
        for (let i = 0; i < allNodes.length; i++) {
          if (uuids.has(allNodes[i].uuid)) {
            nodesArr.push(allNodes[i])
          }
        }
        for (let i = 0; i < nodesArr.length; i++) {
          //stateCache.setNodeSelected(nodesArr[i])
          stateCache.toggleNodeSelection(nodesArr[i])
        }
        if(rootState.project.activeGlobalTab != "collision" && rootState.project.activeGlobalTab != "smeta" && rootState.project.activeGlobalTab != "wormpanel") { // фикс ошибок в консоли при выделении рамкой с открытой таблицей коллизий
          for (const parent of nodesArr[0].nodePath) {
            if (!getters.isTreeNodeDisclosed(parent) && parent.children.length > 0) {
              stateCache.disclosedNodes.add(parent)
              stateCache.numberOfDisclosedNodes++
              commit('updateNumberOfDisclosedNodes', stateCache.numberOfDisclosedNodes)
            }
          }
        }
        
        commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)
      }
    },
    
    toggleNodeSelectionByNodeUuid({ commit, getters }, globalUuid) {
      if (stateCache.tree.flatlist.length > 0) {
        let node
        let allNodes = stateCache.tree.flatlist
        for(let i = 0; i < allNodes.length; i++) {
          if (globalUuid == allNodes[i].uuid) {
            node = allNodes[i]
            break
          }
        }

        if (!node) return // Срочный FIX, элементы не выделяются через shift, после выделения группировки или правила коллизий

        for (const parent of node.nodePath) {
          if (!getters.isTreeNodeDisclosed(parent) && parent.children.length > 0) {
            stateCache.disclosedNodes.add(parent)
            stateCache.numberOfDisclosedNodes++
            commit('updateNumberOfDisclosedNodes', stateCache.numberOfDisclosedNodes)
          }
        }
        stateCache.toggleNodeSelection(node)
        commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)
      }
    },

    toggleNodeSelection ({ commit, dispatch, state }, node) {
      stateCache.toggleNodeSelection(node)
      // let { endpoints, highlighted } = stateCache.toggleNodeSelection(node)
      // let ids = endpoints.map(({ globalId }) => globalId)
      // XeokitMediator.viewer.scene.setObjectsHighlighted(ids, highlighted)
      if (node.countFullCollisions > 0) {
        if(node.numberOfEndpoints > 0 ) {
          let ids = Array.from(stateCache.selectedEndpointExtraIDs)
          let selectedNodes = stateCache.tree.flatlist.filter(node => ids.indexOf(node.extraId) != -1)
          if(!selectedNodes.length) {
            dispatch('collision/table/selectCollisions', [], { root: true })
            XeokitMediator.Models.restoreModels()
            CollisionBimAnnotations.clearCollisionBimAnnotation()
            XeokitMediator.CameraFlight.flyToDefaultView()
          }
          commit('collision/search/RESET_WITH_FILTER', { selectedNodes }, { root: true })
          dispatch('collision/search/selectAxisNodes', { node: selectedNodes }, { root: true })
        } 
        else {
          dispatch('collision/search/selectAxisNodes', { node: node }, { root: true })
        }
      }

      commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)
      if (state.dataType !== AxisDataType.COLLISIONS) {
        dispatch('test')
      }
    },

    stub () {},

    // async test (context, { node, wasSelected }) {
    async test ({ commit }) {
      commit
      console.time('😰 setSelectedElements')
      // XeokitMediator.viewer.scene.setObjectsHighlighted(node.globalId, wasSelected ? false : true)
      let ids = Array.from(stateCache.selectedEndpointGlobalIDs)
      XeokitMediator.ElementsSelection.selectElements(ids)
      console.timeEnd('😰 setSelectedElements')
    },
    
    // !
    
    // TODO: need refactor
    async selectNode ({ commit, rootGetters }, node) {
      node.selected = !node.selected
      const projectUuid = rootGetters['project/projectUuid']

      if (node.globalId != null && !node.globalId.startsWith("S")){
        projectService.loadElementByGlobal(node.uuid, projectUuid).then(data => {
          commit('SET_ELEMENT', data)
        })
      } else {
        commit('SET_ELEMENT', null)
      }
    },

    fetchElementByNode ({ commit, dispatch, rootGetters }, node) {
      const projectUuid = rootGetters['project/projectUuid']
      commit('setClickOnRow', true)
      
      dispatch('toggleDisclosedNode', node)
      if (node.globalId != null && !node.globalId.startsWith("S")){
        commit('SELECT_NODE', node)

        projectService.loadElementByGlobal(node.uuid, projectUuid).then(data => {
          commit('SET_ELEMENT', data)
          
          let element = XeokitMediator.viewer.scene.objects[data.globalId]
          if (element) {
            XeokitMediator.ElementsSelection.pickElement(element.id)
            // XeokitMediator.CameraFlight.cameraFlyTo(element)
          }
          else {
            XeokitMediator.ElementsSelection.pickElement(null)
          }
          // if (!XeokitMediator.ElementsSelection.pickedElement) {
          //   commit('SELECT_NODE', null)
          //   commit('SET_ELEMENT', null)
          // }
        })
      } else {
        commit('SELECT_NODE', null)
        commit('SET_ELEMENT', null)
      }
    },

    // TODO: need refactor
    fetchElementByGlobalId ({ state, commit, getters, rootGetters, rootState, dispatch }, globalId) {
      const projectUuid = rootGetters['project/projectUuid']
      const hashProject = rootGetters['project/hashProject']
      if (globalId && globalId[0] != "_" && globalId[1] != "_" && state.element?.globalId != globalId) {
        return projectService.loadElementByGlobal(globalId, projectUuid, hashProject).then(data => {
          commit('SET_ELEMENT', data)
          if (rootState.project.activeGlobalTab == 'collision') 
            dispatch('collision/search/setChoosenElementGlobalId', data, { root: true })

          let node = stateCache.tree.flatlist.find(i => i.globalId == globalId)

          if (node !== undefined){
            commit('SELECT_NODE', node)
            // stateCache.selectEndpointNode(node)
            // commit('updateNumberOfSelectedEndpoints', stateCache.numberOfSelectedEndpoints)

            for (const parent of node.nodePath) {
              if (!getters.isTreeNodeDisclosed(parent) && parent.children.length > 0) {
                stateCache.disclosedNodes.add(parent)
                stateCache.numberOfDisclosedNodes++
                commit('updateNumberOfDisclosedNodes', stateCache.numberOfDisclosedNodes)
              }
            }
          }
        })
      } 
      else {
        commit('SET_ELEMENT', null)
        commit('SELECT_NODE', null)
        if (rootState.project.activeGlobalTab == 'collision') 
          dispatch('collision/search/setChoosenElementGlobalId', null, { root: true })
      }
    },

    fetchElementUpdateDetails ({ state, commit, rootGetters }, globalId) {
      const projectUuid = rootGetters['project/projectUuid']
      const hashProject = rootGetters['project/hashProject']
      if (globalId && globalId[0] != "_" && globalId[1] != "_" && state.element?.globalId != globalId) {
        return projectService.loadElementByGlobal(globalId, projectUuid, hashProject).then(data => {
          commit('SET_ELEMENT', data)
        })
      } else {
        commit('SET_ELEMENT', null)
      }
    },

    updateFetchElementByGlobalId({ commit, getters, rootGetters, rootState, dispatch }, globalId) {
      const projectUuid = rootGetters['project/projectUuid']
      const hashProject = rootGetters['project/hashProject']
      return projectService.loadElementByGlobal(globalId, projectUuid, hashProject).then(data => {
        commit('SET_ELEMENT', data)
        if (rootState.project.activeGlobalTab == 'collision')
          dispatch('collision/search/setChoosenElementGlobalId', data, { root: true })

        let node = stateCache.tree.flatlist.find(i => i.globalId == globalId)

        if (node !== undefined) {
          commit('SELECT_NODE', node)

          for (const parent of node.nodePath) {
            if (!getters.isTreeNodeDisclosed(parent) && parent.children.length > 0) {
              stateCache.disclosedNodes.add(parent)
              stateCache.numberOfDisclosedNodes++
              commit('updateNumberOfDisclosedNodes', stateCache.numberOfDisclosedNodes)
            }
          }
        }
      })
    }, 

    fetchElementByGlobalIdWithoutConditions ({ commit, getters, rootGetters, rootState, dispatch }, globalId) {
      const projectUuid = rootGetters['project/projectUuid']
      const hashProject = rootGetters['project/hashProject']

      if (rootState.project.activeGlobalTab == 'collision') 
        dispatch('collision/search/setChoosenElementGlobalId', null, { root: true })

      
      return projectService.loadElementByGlobal(globalId, projectUuid, hashProject).then(data => {
        commit('SET_ELEMENT', data)
        
        if (rootState.project.activeGlobalTab == 'collision') 
          dispatch('collision/search/setChoosenElementGlobalId', data, { root: true })

        let node = stateCache.tree.flatlist.find(i => i.globalId == globalId)

        if (node !== undefined){
          commit('SELECT_NODE', node)

          for (const parent of node.nodePath) {
            if (!getters.isTreeNodeDisclosed(parent) && parent.children.length > 0) {
              stateCache.disclosedNodes.add(parent)
              stateCache.numberOfDisclosedNodes++
              commit('updateNumberOfDisclosedNodes', stateCache.numberOfDisclosedNodes)
            }
          }
        }
      })
    },

    fetchElementByUuid ({ commit, rootState, dispatch, rootGetters }, uuid) {
      const projectUuid = rootGetters['project/projectUuid']
      if (uuid) {
        return projectService.loadElementByGlobal(uuid, projectUuid).then(data => {
          commit('SET_ELEMENT', data)
          if (rootState.project.activeGlobalTab == 'collision') 
            dispatch('collision/search/setChoosenElement', data, { root: true })
        })
      } else {
        commit('SET_ELEMENT', null)
        commit('SELECT_NODE', null)
        if (rootState.project.activeGlobalTab == 'collision') 
          dispatch('collision/search/setChoosenElementGlobalId', null, { root: true })
      }
    },

    unselectNode ({ dispatch, rootState }, globalId) {
      let node = stateCache.tree.flatlist.find(i => i.globalId == globalId)

      if (node && rootState.project.activeGlobalTab == 'show') {
        dispatch("toggleNodeSelection", node)
      }
    },

    async colorizeAxis ({ dispatch, rootGetters, rootState }, { axis, clearColorize }) {
      const defaultColorize = rootState.selected.defaultColorize
      const projectUuid = rootGetters['project/projectUuid']
      const hashProject = rootGetters['project/hashProject']
      let data = await dispatch('fetchElementTree', { axis, projectUuid, hashProject })
      
      let ids = Array.from(childPriority ? idForColorizeByChild(data, defaultColorize) : idForColorize(data, null, defaultColorize))
            
      if(axis.data.color && axis.data.colorize && rootState.project.activeGlobalTab === "show" ) {
        XeokitMediator.ElementsSettings.setElementsColorized(ids, clearColorize ? null : hexToRgb(axis.data.color))
      }

    },

  }
}

// #
// # Cache

let stateCache = new AxisTreeStateCache

function findVisibleNodes (tree) {
  let array = []
  for (const node of tree) {
    array.push(node)
    if (stateCache.disclosedNodes.has(node)) {
      if (node.isPenult) array = array.concat(node.children)
      else array = array.concat(findVisibleNodes(node.children))
    }
  }
  return array
}