import { positionService } from '@/_services'

let status = {
    INITIAL:0,
    LOADING: 1,
    LOADED: 2
}

export default {

    namespaced: true,


    state:{
        dataPositions: {
            list:[],
            status: status.INITIAL
        },
    },

    getters: {
        dataPositions: ({ dataPositions }) => dataPositions.list
    },

    mutations: {
        setDataPositions: ({ dataPositions }, payload) => {
            dataPositions.list = payload
        },
        saveDataPosition: ({ dataPositions }, newDataPosition) => {
            let exist = false
            if(dataPositions.list.length > 0) { 
              let curr = dataPositions.list.find(item => item.uuid == newDataPosition.uuid)
              if (curr !== undefined) {
                  exist = true
                  Object.assign(curr, newDataPosition)
              }
            } 
            if(!exist) dataPositions.list.push(newDataPosition)
        },
        
        deleteDataPosition: ({ dataPositions }, positionUuid) => {
            let index = dataPositions.list.findIndex(item => item.uuid === positionUuid)
            dataPositions.list.splice(index, 1)
        },

        setDataPositionsStatus: ({dataPositions}, status) => {
            dataPositions.status = status
        }
    },

    actions: {
        getDataPositions({commit}, element){
            commit('setDataPositionsStatus', status.LOADING)
            positionService.getDataPositions(element.globalId, element.project).then(data =>{
                commit('setDataPositions', data)
                commit('setDataPositionsStatus', status.LOADED)
            })
        },
        deleteDataPosition({commit}, positionUuid) {
            positionService.deleteDataPosition(positionUuid).then(data => {
                commit('deleteDataPosition', positionUuid)
                return data
            })
        },

        saveDataPosition({commit}, dataPosition) {
            return  positionService.saveDataPosition(dataPosition).then(data => {
                commit('saveDataPosition', data)
                return data
            })
        },

        editDataPosition({commit}, dataPosition) {
            return positionService.editDataPosition(dataPosition).then(data => {
                commit('saveDataPosition', data)
                return data
            })
        }
    }
}