export class NetworkConnection {
  #_apiPath = '/'
  #_host = ''

  constructor (config = {}) {
    if (config.server?.url) {
      const host = config.server?.url
      this.#_host = host.endsWith('/') ? host.slice(0, -1) : host
    }

    if (config.server?.path) {
      const { api = '/' } = config.server?.path

      const prefix = api.startsWith('/') ? '' : '/'
      const suffix = api.endsWith('/') ? '' : '/'

      this.#_apiPath = `${prefix}${api}${suffix}`
    }
  }

  get apiURL () {
    return this.#_host + this.#_apiPath
  }

  get host () {
    return this.#_host
  }
}