export default ({ Factory }) => ({

  /**
   * Получить все сетки целиком по проекту
   * @param {String} projectUuid
   */
  fetchProjectGrids: (projectUuid) => Factory.grids[projectUuid].get(),

  // TODO
  /**
   * Получить сетки для указанной ревизии
   * @param {String} revisionUuid
   */
  fetchGridsForRevision: (revisionUuid) => Factory.grids[revisionUuid].get(),

  // TODO
  /**
   * Получить сетки по их идентификаторам
   * @param {String[]} gridUuids
   */
  fetchGrids: (gridUuids) => Factory.grids[gridUuids].get(),

  // TODO
  /**
   * Получить оси по их идентификаторам
   * @param {String[]} axesUuids
   */
  fetchAxes: (axesUuids) => Factory.grids[axesUuids].get(),

  /**
   * Получить дерево проекта, где включены только модели с сетками
   * @param {String} projectUuid
   */
  fetchGridsIfcTree: (projectUuid) => Factory.grids.models[projectUuid].get(),

  // Показывать/Не показывать сетку для конкретной модели
  /**
   * Переключить отображение сеток для указанной ревизии
   * @param {String} revisionUuid
   */
  toggleGridsVisibility: (revisionUuid) => Factory.grids.button[revisionUuid].patch(),

  /** 
  * @deprecated 
  */
  fetchLabelSize: (projectUuid) => Factory.grids.labelSize[projectUuid].get(),
})