<template>
  <svg
    width="32"
    height="32"
    viewBox="-3 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.99991V14H4C6.5 14 6.5 19.9999 4 19.9999H1V24.9999C1 26.9999 2 27.9999 4 27.9999H9V24.9999C9 22.5 15 22.5 15 24.9999V27.9999H20C21.9999 27.9999 23 26.4999 23 24.9999V19.9999H26C28.5 19.9999 28.5 14.0001 26 14C23.5 13.9999 23 14 23 14V8.99991C23 6.9999 21.9999 5.99994 20 5.99994H15V2.99993C15 0.5 9 0.50002 9 2.99995V5.99994H4C2 5.99994 1 7.4999 1 8.99991Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>