<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" fill="transparent"/>
    <path d="M18 6V18H14V26H30V13.7778" stroke="currentColor"/>
    <path d="M18 18L30 26" stroke="currentColor"/>
    <path d="M2 6V18L14 26V13.7778" stroke="currentColor"/>
    <path d="M18 6.00001L29.9112 14.0218" stroke="currentColor"/>
    <path d="M2 6.00001L13.9112 14.0218" stroke="currentColor"/>
    <path d="M6 10H25" stroke="currentColor"/>
    <circle cx="2" cy="6" r="1" fill="currentColor"/>
    <circle cx="18" cy="6" r="1" fill="currentColor"/>
    <circle cx="14" cy="14" r="1" fill="currentColor"/>
    <circle cx="30" cy="14" r="1" fill="currentColor"/>
    <rect x="6.5" y="8.5" width="3" height="3" fill="transparent" stroke="currentColor"/>
    <rect x="22.5" y="8.5" width="3" height="3" fill="transparent" stroke="currentColor"/>
  </svg>

</template>

<script>
export default {

}
</script>

<style>

</style>