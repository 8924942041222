import { $_app_server, $_plugin_server } from '@/_helpers'

export const pluginService = {
  types,
  list,
  listByProfile,
  downloadXLSX,
  getTablePage,
  listByType,
  load,
  scripts,
  logs,
  createPlugin,
  updatePlugin,
  deletePlugin,
  validate,
  execute,
  executeWithParam,
  executeWithParamToFile,
  clearConsole,

  sharePluginForCompany,

  getMethodsByPluginType,
  getHintByPluginType,
  getHintForPlugin,
  getApiModelsAnnotations,

  listRepositoryPlugins,
  clonePluginToProject,
  addRepositoryPlugin,
  getOnValidatePluginsByProfileAndType,
  setRepositoryPluginAvatar,

  getOnValidatePluginList,
  validateRepositoryPlugin,

  getPluginsTemplate,
  importPluginTemplate,
  exportPlugin,
  deletePluginTemplate
}

function types () {
  return $_plugin_server.get(`/plugins/types`).then(response => response.data)
}
function list (projectUuid) {
  return $_plugin_server.get(`/plugins/${projectUuid}/list`).then(response => response.data)
}
function listByProfile () {
  return $_plugin_server.get(`/plugins/${getProfileUuid()}/org/list`).then(response => response.data)
}
function listByType (projectUuid,type) {
  return $_plugin_server.get(`/plugins/${projectUuid}/list/${type}`).then(response => response.data)
}
function load (pluginUuid) {
  return $_plugin_server.get(`/plugins/${pluginUuid}`).then(response => response.data)
}
function scripts (filename) {
  return $_plugin_server.get(`/plugins/scripts/${filename}`).then(response => response.data)
}
function logs (filename) {
  return $_plugin_server.get(`/plugins/logs/${filename}`).then(response => response.data)
}
function createPlugin (plugin) {
  return $_plugin_server.post(`/plugins/`,plugin).then(response => response.data)
}
function updatePlugin (plugin) {
  return $_plugin_server.put(`/plugins/`,plugin).then(response => response.data)
}
function deletePlugin (pluginUuid) {
  return $_plugin_server.delete(`/plugins/${pluginUuid}`).then(response => response.data)
}
function downloadXLSX (pluginUuid) {
  return $_plugin_server.get(`/plugins/${pluginUuid}/table`,{ responseType: 'blob' }).then(response => response.data)
}
function getTablePage (pg) {
  let obj = {
    actions:pg.actions,
    rowsPositions:pg.rowsPositions
  }
  return $_plugin_server.post( `/plugins/${pg.pluginUuid}/table/page?page=${pg.page}&pageSize=${pg.pageSize}`,obj).then(response => response.data)
}
function validate (pluginUuid) {
  return $_plugin_server.patch(`/plugins/${pluginUuid}/validate`).then(response => response.data)
}
function clearConsole (pluginUuid) {
  return $_plugin_server.patch(`/plugins/${pluginUuid}/clear`).then(response => response.data)
}
function execute (projectUuid, pluginUuid) {
  return $_plugin_server.patch(`/plugins/${projectUuid}/${pluginUuid}/execute?${'id='+getProfileUuid()}`).then(response => response.data)
}
function executeWithParam (projectUuid, pluginUuid, param) {
  return $_plugin_server.post(`/plugins/${projectUuid}/${pluginUuid}/execute?${'id='+getProfileUuid()}`, param).then(response => response.data)
}
function executeWithParamToFile (projectUuid, pluginUuid, param) {
  return $_app_server.post(`/plugin/${projectUuid}/${pluginUuid}/executetofile`, param).then(response => response.data)
}
function sharePluginForCompany(uuid) {
  return $_plugin_server.get(`/plugins/${uuid}/org/share`).then(response => response.data)
}

function getMethodsByPluginType(pluginType) {
  return $_plugin_server.get(`/plugins/${pluginType}/methods/annotation`).then(response => response.data)
}
function getHintByPluginType(pluginType) {
  return $_plugin_server.get(`/plugins/${pluginType}/methods/name`).then(response => response.data)
}
function getHintForPlugin() {
  return $_plugin_server.get(`/plugins/methods/name/all`).then(response => response.data)
}
function getApiModelsAnnotations(projectUuid) {
  return $_plugin_server.get(`/plugins/class/api/${projectUuid}/${getProfileUuid()}`).then(response => response.data)
}

// FOR PUBLIC STORAGE (REPOSITORY)
function listRepositoryPlugins() {
  return $_plugin_server.get('/plugins/repository/list').then(response => response.data)
}
function clonePluginToProject(obj) {
  return $_plugin_server.get(`/plugins/repository/${obj.plugin}/${obj.project}/${getProfileUuid()}`).then(response => response.data)
}
function addRepositoryPlugin(pluginScript) {
  return $_plugin_server.post(`/plugins/repository/`, pluginScript).then(response => response.data)
}
function getOnValidatePluginsByProfileAndType(type){
  return $_plugin_server.get(`/plugins/${getProfileUuid()}/validate/list?type=${type}`).then(response => response.data)
}
function setRepositoryPluginAvatar(data) {
  let formData = new FormData()
  formData.append('file', data.file)
  return $_plugin_server.post(`/plugins/repository/${data.uuid}/avatar`, formData)
}

// FOR ADMIN PANEL
function getOnValidatePluginList(){
  return $_plugin_server.get(`/plugins/repository/validate/list`).then(response => response.data)
}
function validateRepositoryPlugin(pluginUuid, rejection){
  return $_plugin_server.post(`/plugins/repository/${pluginUuid}/validate`, rejection).then(response => response.data)
}


function getPluginsTemplate(){
  return $_app_server.get(`/template/plugin/list`).then(response => response.data)
}
function importPluginTemplate(projectUuid, pluginName) {
  return $_app_server.post(`/template/plugin/import/${pluginName}/${projectUuid}/${getProfileUuid()}`)
    .then(response => response.data)
}
function exportPlugin(pluginTemplate, needUpdate) {
  return $_app_server.post(`/template/plugin/export/${needUpdate}`, pluginTemplate).then(response => response.data)
}
function deletePluginTemplate(pluginName) {
  return $_app_server.delete(`/template/plugin/${pluginName}`).then(response => response.data)
}


function getProfileUuid() {
  return JSON.parse(localStorage.getItem('profile')).uuid
}