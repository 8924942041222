<template lang="pug">
v-tooltip( :top="top" :right="right" :left="left" :bottom="bottom" :open-delay="openDelay" )
  template( v-slot:activator="{ on }" )
    div( v-on="on" )
      slot
  span {{ title }}
</template>

<script>
export default {
  name: 'app-tooltip',

  props: {
    openDelay: { type: [String, Number], default: 300 },
    title: { type: String, default: '' },
    top: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
  },
}
</script>