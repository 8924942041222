import { math } from "@xeokit/xeokit-sdk"
import { geometry } from "./geometry"

export const pointToFaceMeasurement = {
  findShortestSegmentBetweenPointAndFace(firstPoint, faceEdges) {
    let tempVec3a = math.vec3()
    let tempVec3b = math.vec3()
    let tempVec3c = math.vec3()
    //let tempPoint = []
    
    let point = firstPoint
    let faceN = math.vec3()
    let edges = faceEdges
    let faceVertices = [edges[0][0], edges[0][1]]

    for (let i = 1; i < edges.length; i++) {
      if (!geometry.utils.contains(faceVertices, edges[i][0])) {
        faceVertices.push(edges[i][0])
      }
      if (!geometry.utils.contains(faceVertices, edges[i][1])) {
        faceVertices.push(edges[i][1])
      }
    }

    let faceO = faceVertices[0]
    
    math.subVec3(faceVertices[0], faceVertices[1], tempVec3a)
    math.subVec3(faceVertices[0], faceVertices[2], tempVec3b)
    math.cross3Vec3(tempVec3a, tempVec3b, tempVec3c)
    math.normalizeVec3(tempVec3c, faceN)

    // Выпускаем луч из точки в направлении плоскости многоугольника
    math.subVec3(point, faceO, tempVec3a)
    let rayV = []

    if (math.dotVec3(tempVec3a, faceN) > 0) {
      rayV = faceN
    } 
    else {
      math.mulVec3Scalar(faceN, -1, tempVec3a)
      rayV = tempVec3a
    }
    let intersPoint = geometry.intersection.getRayPlaneIntersectionPoint(point, rayV, faceN, faceO) // РЕЗУЛЬТАТ для перпендикулярного режима

    // Проецируем точку пересечения и многоугольник на произвольную плоскость (возможно необязательно)

    // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
    let voluntaryVec = math.vec3([1, 1, 1]) // надо проверять на коллинеарность с faceN
    math.cross3Vec3(faceN, voluntaryVec, tempVec3a)
    let lenCrossVec = math.lenVec3(tempVec3a)
    if (lenCrossVec >= -0.01 && lenCrossVec <= 0.01) {
      voluntaryVec = math.vec3([-0.2, -0.8, -0])
    }
    let crossVec = math.vec3()
    math.cross3Vec3(voluntaryVec, faceN, crossVec)

    let intersCount = 0
    for (let i = 0; i < edges.length; i++) {
      if (geometry.intersection.isRayIntersectsSegment(intersPoint, crossVec, edges[i][0], edges[i][1])) {
        intersCount++
      }
    }

    if (intersCount & 1) {
      // Нечет
      return [intersPoint, point]
    } 
    else {
      // Искать расстояния до ребер от исходной точки
      let pointsArray = []
      let lengthsArray = []

      for (let i = 0; i < edges.length; i++) {
        let A = math.vec3()
        math.subVec3(edges[i][0], edges[i][1], A)
        let magA = math.lenVec3(A)
        let _A = math.vec3()
        math.mulVec3Scalar(A, 1 / magA, _A)

        math.subVec3(edges[i][0], point, tempVec3a)
        math.subVec3(edges[i][1], point, tempVec3b)
        let d0 = math.dotVec3(_A, tempVec3a)
        let d1 = math.dotVec3(_A, tempVec3b)

        let x1 = edges[i][0][0]
        let x2 = edges[i][1][0]
        let px1 = point[0]

        let y1 = edges[i][0][1]
        let y2 = edges[i][1][1]
        let py1 = point[1]

        let z1 = edges[i][0][2]
        let z2 = edges[i][1][2]
        let pz1 = point[2]

        let u1 = ((px1 - x1) * (x2 - x1) + (py1 - y1) * (y2 - y1) + (pz1 - z1) * (z2 - z1)) / (magA * magA)
        if (u1 >= -0.00001 && u1 <= 1.00001) {
          let x = x1 + u1 * (x2 - x1)
          let y = y1 + u1 * (y2 - y1)
          let z = z1 + u1 * (z2 - z1)

          pointsArray.push([
            [x, y, z],
            [px1, py1, pz1],
          ])
          math.subVec3([x, y, z], [px1, py1, pz1], tempVec3a)
          lengthsArray.push(Math.abs(math.lenVec3(tempVec3a)))
        } 
        else {
          let absD0 = Math.abs(d0)
          let absD1 = Math.abs(d1)

          if (absD0 < absD1) {
            pointsArray.push([edges[i][0], point])
            math.subVec3(edges[i][0], point, tempVec3a)
            lengthsArray.push(Math.abs(math.lenVec3(tempVec3a)))
          } 
          else {
            pointsArray.push([edges[i][1], point])
            math.subVec3(edges[i][1], point, tempVec3a)
            lengthsArray.push(Math.abs(math.lenVec3(tempVec3a)))
          }
        }
      }

      // Поиск кратчайшего расстояния
      let minLength = Number.MAX_VALUE
      for (let i = 0; i < pointsArray.length; i++) {
        if (lengthsArray[i] < minLength) {
          minLength = lengthsArray[i]
        }
      }

      let destIndex = geometry.utils.indexOfElementInArray(minLength, lengthsArray)
      return pointsArray[destIndex]
    }
  },
}