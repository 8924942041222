import { Plugin } from "@xeokit/xeokit-sdk";
import { ModelGrids } from "@/plugins/IFCGrids/modelGrids";
import { events } from "@/plugins/xeokit/eventDispatchers/aabbEventDispatcher";
import { EventDispatcher } from "../xeokit/eventDispatchers/eventDispatcher";
import { getFilteredSceneAABB } from "../xeokit/cameraFlight/cameraFlight.utils";
import { geometry } from "../xeokit/plugins/geometry/geometry";

/*eslint-disable no-dupe-class-members*/
export class GridsPlugin extends Plugin {
  constructor(viewer, cfg = {}) {
    super('GridsPlugin', viewer)

    this._viewer = viewer
    
    this._projectGrids = {}
    this._projectGridsActive = cfg.projectGridsActive ?? false
    this._needDebounce = cfg.needDebounce ?? true

    this._sectionPlaneUpdatedSubId = null
    this._sectionPlaneDestroyedSubId = null
    this._sectionPlaneActiveChangedSubId = null
    this._sectionPlaneUpdatedDebounceTimeout = null
    this._sectionCubeUpdatedSubId = null
  }

  get projectGrids() {
    return this._projectGrids
  }

  get projectGridsActive() {
    return this._projectGridsActive
  }

  get needDebounce() {
    return this._needDebounce
  }

  /**
   * @param {Boolean} value
   */
  set projectGridsActive(value) {
    this._projectGridsActive = value
  }

  /**
   * Создать сетки для всех моделей проекта
   * 
   * @param {Object[]} gridsData 
   */
  createProjectGrids(gridsData, cfg = {}) {
    for (let i = 0; i < gridsData.length; i++) {
      const grids = gridsData[i]

      // Отбраковка всех сеток, для которых на сцене нет моделей
      let aabb = null
      if (this._viewer.scene.models[grids.modelRevision]) {
        aabb = this._viewer.scene.models[grids.modelRevision]._aabb
      }
      else continue

      const config = {
        font: cfg.font, // MUST BE
        revisionAabb: aabb, // MUST BE
        color: cfg.color ?? [0.5, 0.5, 0.5],
        glowThrough: cfg.glowThrough ?? false,
        visible: cfg.visible ?? true,
      }

      const modelGrids = new ModelGrids(this, Object.assign(grids, config))

      const uuid = grids.uuid
      this._projectGrids[uuid] = modelGrids

      modelGrids.on("destroyed", () => {
        delete this._projectGrids[modelGrids.uuid]
      });
  
      this.fire("ModelGridsCreated", modelGrids)
    }

    this.subscribeToSceneAabbChange()
    this.subscribeToSectionPlaneUpdated()

    this.#fireSceneAabbChanged()

    this.fire("ProjectGridsCreated", this._projectGrids)
  }

  setDebounce(value) {
    this._needDebounce = value
    Object.keys(this._projectGrids).forEach(uuid => {
      this._projectGrids[uuid].setDebounce(value)
    })
  }

  setProjectGridsActive(value) {
    this._projectGridsActive = value
  }

  setProjectGridsGlowThrough(value) {
    Object.keys(this._projectGrids).forEach(uuid => {
      this._projectGrids[uuid].setGlowThrough(value)
    })
  }

  setProjectGridsVisible(value) {
    Object.keys(this._projectGrids).forEach(uuid => {
      this._projectGrids[uuid].setVisible(value)
    })
  }

  setGridsCulledByAabb(aabb) {
    Object.keys(this._projectGrids).forEach(uuid => {
      const modelGrids = this._projectGrids[uuid]
      modelGrids.setCulled(false)
      modelGrids.setCulledByAabb(aabb)
    })

    Object.keys(this._projectGrids).forEach(uuid => {
      const modelGrids = this._projectGrids[uuid]
      const modelAabb = modelGrids.revisionAabb
      if (!geometry.intersection.isAabbIntersectAabb(aabb, modelAabb)) {
        modelGrids.setCulled(true)
      }
    })
  }

  subscribeToSceneAabbChange() {
    EventDispatcher.aabb.on(events.AABB_CHANGE, aabb => {
      this.setGridsCulledByAabb(aabb)

      if (this.projectGridsActive) {
        this.setProjectGridsVisible(true)
      }
    })
  }

  subscribeToSectionPlaneUpdated() {
    this._sectionPlaneUpdatedSubId = this._viewer.scene.on('sectionPlaneUpdated', () => this.#sectionPlaneUpdatedDebounce())
    this._sectionPlaneActiveChangedSubId = this._viewer.scene.on('sectionPlaneActiveChanged', () => this.#fireSceneAabbChanged())
    this._sectionPlaneDestroyedSubId = this._viewer.scene.on('sectionPlaneDestroyed', () => this.#fireSceneAabbChanged())
    this._sectionCubeUpdatedSubId = this._viewer.scene.on('sectionCubeUpdated', () => this.#sectionPlaneUpdatedDebounce())
  }

  #sectionPlaneUpdatedDebounce() {
    if (this.projectGridsActive) {
      this._sectionPlaneUpdatedDebounceTimeout && clearTimeout(this._sectionPlaneUpdatedDebounceTimeout)

      this._sectionPlaneUpdatedDebounceTimeout = setTimeout(() => {
        this.#fireSceneAabbChanged()
      }, 300)
    }
  }

  #fireSceneAabbChanged(sceneAabb) {
    const aabb = sceneAabb ?? getFilteredSceneAABB(this._viewer.scene)
    EventDispatcher.aabb.fireSceneAabbChanged(aabb)
  }

  // /**
  //  * Удалить меши указанной сетки. После этого по событию destroyed произойдет удаление элемента из коллекции.
  //  * 
  //  * @param {String} id 
  //  */
  // destroyModelGridsByRevisionUuid(revisionUuid) {
  //   Object.keys(this._projectGrids).forEach(id => {
  //     const modelGrids = this._projectGrids[id]
  //     if (modelGrids.revisionUuid == revisionUuid) {
  //       modelGrids.destroy()
  //       this.fire("ModelGridsDestroyed", modelGrids)
  //       return
  //     }
      
  //     this.log("ModelGrids not found: " + id)
  //     return
  //   })
  // }

  /**
   * Удалить меши указанной сетки. После этого по событию destroyed произойдет удаление элемента из коллекции.
   * 
   * @param {String} id 
   */
  destroyModelGrids(id) {
    const modelGrids = this._projectGrids[id]
    if (!modelGrids) {
      this.log("ModelGrids not found: " + id)
      return
    }
    modelGrids.destroy()
    this.fire("ModelGridsDestroyed", modelGrids)
  }

  /**
   * Удалить меши все сеток по проекту
   */
  destroyProjectGrids() {
    const ids = Object.keys(this._projectGrids);
    for (var i = 0, len = ids.length; i < len; i++) {
      this.destroyModelGrids(ids[i]);
    }
  }

  /**
   * Удалить плагин
   */
  destroy() {
    this.destroyProjectGrids()
    super.destroy()
  }
}