import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { AnnotationsPlugin } from '@xeokit/xeokit-sdk'
import { useTaskBimAnnotationsStore } from '@/pinia';
import { math } from "@xeokit/xeokit-sdk";

import i18n from '@/plugins/i18n';
import _ from 'lodash';
import store from '@/store';


import "./taskAnnotationsStyle.scss"
import { MODEL_TRANSFORM_ID } from '@/plugins/xeokit/RevisionLoader';
import { taskBimAnnotationsVisibleMode } from '@/store/project.module';
import { projectService } from '@/_services';

/*eslint-disable no-dupe-class-members*/
export class TaskBimAnnotations {  

  static #_taskBimAnnotationsPlugin = null

  static get #_taskBimAnnotations() {
    return this.#_taskBimAnnotationsPlugin.annotations
  }

  static get isVisibleTaskAnnotations() {
    return this.#_taskBimAnnotationsStore.visibleTaskAnnotations
  }

  static get #_taskBimAnnotationsStore() {
    return useTaskBimAnnotationsStore()
  }

  static get selectedTaskAnnotation() {
    return this.getTaskBimAnnotationByUUID(this.#_taskBimAnnotationsStore.selectedTaskAnnotationUuid)
  }
  
  static get #_filteredTasks() {
    return store.getters['task/filteredTasks']
  }

  static get #_selectedTask() {
    return store.state.task.selectedTask
  }

  static get #_topUnit() {
    return store.state.project.topUnit
  }

  static getTaskBimAnnotationByUUID(uuid) {
    return this.#_taskBimAnnotationsPlugin.annotations[uuid] || null
  }

  static showTaskBimAnnotations() {
    this.clearTaskBimAnnotations()
    if (this.#_topUnit != 'task' || this.#_taskBimAnnotationsVisibleMode.value === taskBimAnnotationsVisibleMode.NONE.value) return
    let list = _.uniqBy(this.#_filteredTasks, "uuid").map(task => { 
      if (task.attachments) {
        // ADD Поддержка настроек отображения меток
        for (let i = 0; i < task.attachments.length; i++) {
          const attach = task.attachments[i]
          if (attach.sourceData?.annotation) {
            attach.annotation = this.#_createTaskBimAnnotation(attach, task)
          }
          else if (attach.sourceData?.worldPos && attach.sourceData?.entityId) {
            attach.sourceData.annotation = {worldPos: attach.sourceData.worldPos, entityId: attach.sourceData.entityId}
            attach.annotation = this.#_createTaskBimAnnotation(attach, task)
          }

          if (this.#_taskBimAnnotationsVisibleMode.value === taskBimAnnotationsVisibleMode.FIRST_ONLY.value) {
            break
          }
        }
        // 
        // Старый вариант
        // task.attachments = task.attachments.map(attach => {
        //   if (attach.sourceData?.annotation) {
        //     attach.annotation = this.#_createTaskBimAnnotation(attach, task)
        //   }
        //   else if (attach.sourceData?.worldPos && attach.sourceData?.entityId) {
        //     attach.sourceData.annotation = {worldPos: attach.sourceData.worldPos, entityId: attach.sourceData.entityId}
        //     attach.annotation = this.#_createTaskBimAnnotation(attach, task)
        //   }
        //   return attach
        // })
      }
      if (this.#_selectedTask && task.uuid === this.#_selectedTask.uuid) this.#_setSelectedTask(task)

      return task
    })

    this.#_viewSelectedTaskBimAnnotations()

    this.#_setTasks(list)
  }

  static setSelectedTaskBimAnnotationByAttachment(attach, shownLabel = false) {
    let annotation = this.getTaskBimAnnotationByUUID("task_annotation_" + attach.uuid)
    this.setSelectedBimTaskAnnotation(annotation, shownLabel)
  }

  static setSelectedBimTaskAnnotation(annotation, shownLabel = false) {
    let taskAnnotation = this.getTaskBimAnnotationByUUID(annotation.id)
    if (this.selectedTaskAnnotation) {
      if (taskAnnotation.id == this.selectedTaskAnnotation.id) {
        this.noSelectedTaskBimAnnotation()
        return
      }
      this.noSelectedTaskBimAnnotation()
    }
    
    if (taskAnnotation) {
      this.#_taskBimAnnotationsStore.setSelectedBimTaskAnnotation(taskAnnotation.id)

      this.selectedTaskAnnotation.getValues().markerBGColor = "#3b93af"
      this.selectedTaskAnnotation.setValues(taskAnnotation.getValues())
      this.selectedTaskAnnotation.setLabelShown(shownLabel);
      this.selectedTaskAnnotation.setMarkerShown(this.isVisibleTaskAnnotations)
      this.#_setSelectedTaskByUuid(this.selectedTaskAnnotation.getValues().taskUuid)

      // показывать только метки выбранной задачи
      this.#_viewSelectedTaskBimAnnotations()
      this.#_taskBimAnnotationsStore.setSelectedBimTaskAnnotation(taskAnnotation.id)
      if (this.selectedTaskAnnotation) {
        this.selectedTaskAnnotation.getValues().markerBGColor = "#3b93af"
        this.selectedTaskAnnotation.setLabelShown(shownLabel);
        this.selectedTaskAnnotation.setMarkerShown(this.isVisibleTaskAnnotations)
      }
    }
  }

  static noSelectedTaskBimAnnotation() {
    if (this.selectedTaskAnnotation && this.selectedTaskAnnotation.getValues()) {
      this.selectedTaskAnnotation.getValues().markerBGColor = "red"
      this.selectedTaskAnnotation.setValues(this.selectedTaskAnnotation.getValues())
      this.selectedTaskAnnotation.setLabelShown(false);

      this.#_taskBimAnnotationsStore.setSelectedBimTaskAnnotation(null)
    }
  }

  static setTaskBimAnnotationsVisible(show = true) {
    this.#_taskBimAnnotationsStore.visibleTaskAnnotations = show

    Object.entries(this.#_taskBimAnnotations).forEach(annotation => {
      annotation[1].setMarkerShown(show)
    })
  }

  static clearTaskBimAnnotations () {
    if (this.#_taskBimAnnotationsPlugin) this.#_taskBimAnnotationsPlugin.clear()
  }

  static async #_createTaskBimAnnotation(attach, task) {
    const sourceData = attach.sourceData
    const entityId = sourceData.annotation.entityId
    let worldPos = sourceData.annotation.worldPos

    const modelId = sourceData.annotation.model

    this.greyAnnots?.delete("task_annotation_" + attach.uuid)

    let comment = task.description

    if (!sourceData?.version) {
      if (worldPos && worldPos.length > 0) {
        worldPos = worldPos.split(",").map(Number)
      }
    }

    if (sourceData?.comment) 
      comment = i18n.t('module.task.comment') + ": " + sourceData.comment

    if (worldPos && entityId) {
      let entity = XeokitMediator?.viewer?.scene.objects[entityId]
      if (!entity || entity === undefined || modelId) {
        let modelNotFound = false
        const { model: modelUuid } = modelId ? { model: modelId } : await projectService.loadElementByGlobal(entityId, store.getters['project/projectUuid'])
        const model = this.#_getProjectModel(modelUuid) || (modelNotFound = true, {
          realEulerX: 0,
          realEulerY: 0,
          realEulerZ: 0,

          realOffsetX: 0,
          realOffsetY: 0,
          realOffsetZ: 0,
        })
        entity = {
          model: {
            transforms: {
              [MODEL_TRANSFORM_ID]: {
                position: [
                  model.realOffsetX,
                  model.realOffsetY,
                  model.realOffsetZ,
                ],
                rotation: [
                  model.realEulerX,
                  sourceData.version === 2 ? model.realEulerY : model.realEulerZ,
                  sourceData.version === 2 ? model.realEulerZ : model.realEulerY,
                ]
              }
            }
          }
        }

        this.greyAnnots ||= new Set()
        this.greyAnnots.add("task_annotation_" + attach.uuid)

        comment ??= ''
        modelNotFound ? comment += comment ? ' (model delete)' : 'model delete' : comment += comment ? ' (model is off)' + model.title : 'model is off' + model.title
      }

      let newWorldPos = math.vec3([0, 0, 0])

      if (sourceData?.version == 2) {
        const tempMat4 = math.mat4();
        const tempVec4 = math.vec4();
        const defaultScale = math.vec3([1, 1, 1]);
        const defaultPosition = math.vec3([0, 0, 0]);
        const defaultRotation = math.vec3([0, 0, 0]);
        const defaultQuaternion = math.identityQuaternion();

        const defaultTransform = {
          position: defaultPosition,
          rotation: defaultRotation,
          scale: defaultScale
        }

        const transform = entity?.model?.transforms[MODEL_TRANSFORM_ID] || defaultTransform
        const modelPosition = transform.position || defaultPosition;
        const modelRotation = transform.rotation || defaultRotation;
        const modelScale = transform.scale || defaultScale;

        math.eulerToQuaternion(modelRotation, "XYZ", defaultQuaternion);
        const meshMatrix = math.composeMat4(modelPosition, defaultQuaternion, modelScale, tempMat4);

        const oldWorldPos = math.vec3([worldPos[0], worldPos[1], worldPos[2], 1]);
        const newMeshPos = math.transformPoint4(meshMatrix, oldWorldPos, tempVec4);

        newWorldPos = math.vec3([newMeshPos[0], newMeshPos[1], newMeshPos[2]]);
      }
      else {
        // TODO: ДАСТ БОГ, УДАЛИМ. КОГДА НА БЕКЕ ПРИВЕДУТ ВСЕ МЕТКИ К ОДНОМУ ВИДУ!!!!
        newWorldPos = this.#_oldRestoreBimAnnotationWorldPos(entity, worldPos)
      }

      let annotationDescription = comment
      if( annotationDescription == null ) annotationDescription = ""

      let myAnnotation = this.#_taskBimAnnotationsPlugin.annotations["task_annotation_" + attach.uuid] || this.#_createBimAnnotations({
        id: "task_annotation_" + attach.uuid,
        glyph: task.name.charAt(0) + "-" + task.name.split("-").reverse()[0],
        title: task.title,
        description: annotationDescription,
        worldPos: newWorldPos,
        taskUuid: task.uuid
      })

      myAnnotation.on("visible", (visible) => {
        let annotation = document.getElementById(myAnnotation.id)
        let annotationMark = document.getElementById("mark_" + myAnnotation.id)

        if (annotation != undefined && annotationMark != undefined) {
          this.#_setAnnotationDefaultStyle(annotation)
          let display = visible ? 'block' : 'none'
          annotation.style.display = display;
          annotationMark.style.display = display;
        }
      });

      if(this.selectedTaskAnnotation){
        if(this.selectedTaskAnnotation.id == myAnnotation.id){
          this.setSelectedBimTaskAnnotation(myAnnotation)
        }
      }

      return myAnnotation
    } 
    return null
  }

  // Показать только метки выбранной задачи
  static #_viewSelectedTaskBimAnnotations() {
    if (this.#_selectedTask && this.#_selectedTask.uuid) {
      this.clearTaskBimAnnotations()
      
      if (this.#_selectedTask.attachments) {
        const mode = this.#_taskBimAnnotationsVisibleMode
        switch (mode.value) {
          case taskBimAnnotationsVisibleMode.NONE.value: {
            return
          }
          case taskBimAnnotationsVisibleMode.ALL.value: {
            this.#_selectedTask.attachments.forEach(attach => {
              if (attach.sourceData?.annotation) {
                this.#_createTaskBimAnnotation(attach, this.#_selectedTask)
              }
            })
            break
          }
          case taskBimAnnotationsVisibleMode.FIRST_ONLY.value: {
            for (let i = 0; i < this.#_selectedTask.attachments.length; i++) {
              const attach = this.#_selectedTask.attachments[i]
              if (attach.sourceData?.annotation) {
                this.#_createTaskBimAnnotation(attach, this.#_selectedTask)
                break
              }
            }
          }
        }
      }
    }
  }

  static get #_taskBimAnnotationsVisibleMode() {
    return store.getters['project/taskBimAnnotationsVisibleMode']
  }

  static #_createBimAnnotations(cfg) {
    const id = cfg.id
    const glyph = cfg.glyph || ""
    const title = cfg.title || ""
    const description = cfg.description || ""
    const worldPos = cfg.worldPos || [0, 0, 0]
    const taskUuid = cfg.taskUuid
    
    return this.#_taskBimAnnotationsPlugin.createAnnotation({
      id: id,
      worldPos: worldPos,
      occludable: false,
      markerShown: true,
      labelShown: false,  
      values: {
        id: id,
        glyph: glyph,
        title: title,
        description: description,
        taskUuid: taskUuid,
        markerBGColor: this.greyAnnots?.has(id) ? 'grey' : 'red'
      },
    });
  }

  static #_setSelectedTask(task) {
    store.commit('task/setSelectedTask', task)
  }

  static #_setSelectedTaskByUuid(taskUuid) {
    store.dispatch('task/setSelectedTaskByUuid', taskUuid)
  }

  static #_setTasks(tasks) {
    store.commit('task/setTasks', tasks)
  }

  static #_setAnnotationDefaultStyle(annotation) {
    let annotTitle = annotation.getElementsByClassName("annotation-title")[0]
    let annotDesc = annotation.getElementsByClassName("annotation-desc")[0]
    annotTitle.style.fontSize = "14px"
    annotTitle.style.color = "#303030"
    annotTitle.style.fontFamily = "Roboto, sans-serif"
    annotDesc.style.fontSize = "12px"
    annotDesc.style.color = "#303030"
    annotDesc.style.fontFamily = "Roboto, sans-serif"
  }

  static activate() {
    this.#_taskBimAnnotationsPlugin = new AnnotationsPlugin(XeokitMediator.viewer, {
      markerHTML: "<div id='mark_{{id}}' class='annotation-marker' style='background-color: {{markerBGColor}};'><div>{{glyph}}</div></div>",
      labelHTML: "<div id='{{id}}' class='annotation-label' style='background-color: {{labelBGColor}};'><div class='annotation-title'>{{title}}</div><div class='annotation-desc'>{{description}}</div></div>",
      values: {
          markerBGColor: "red",
          glyph: "X",
          title: "Untitled",
          description: "No description",
          obj: null
      },
      surfaceOffset: 0.3,
      container: document.body.getElementsByClassName("full")[0]
    });
    this.#_taskBimAnnotationsPlugin.on("markerClicked", (annotation) => {
      if (this.selectedTaskAnnotation && (this.selectedTaskAnnotation.id === annotation.id)){
        this.noSelectedTaskBimAnnotation()
      }
      else this.setSelectedBimTaskAnnotation(annotation)
    });

    this.#_taskBimAnnotationsPlugin.on("markerMouseEnter", (annotation) => {
      annotation.setLabelShown(true);
    });

    this.#_taskBimAnnotationsPlugin.on("markerMouseLeave", (annotation) => {
      annotation.setLabelShown(false);
    });
  }



  // TODO: ДАСТ БОГ, УДАЛИМ. КОГДА НА БЕКЕ ПРИВЕДУТ ВСЕ МЕТКИ К ОДНОМУ ВИДУ!!!!
  static #_oldRestoreBimAnnotationWorldPos(entity, worldPos) {
    const tempMat4 = math.mat4();
    const tempVec4 = math.vec4();
    const defaultScale = math.vec3([1, 1, 1]);
    const defaultPosition = math.vec3([0, 0, 0]);
    const defaultRotation = math.vec3([90, 0, 0]);

    const defaultTransform = {
      position: defaultPosition,
      rotation: defaultRotation,
      scale: defaultScale
    }

    const transform = entity?.model?.transforms[MODEL_TRANSFORM_ID] || defaultTransform
    const modelPosition = transform.position || defaultPosition;
    const modelRotation = transform.rotation || defaultRotation;
    const modelScale = transform.scale || defaultScale;

    const scale = modelScale || defaultScale;
    const position = modelPosition || defaultPosition;

    const rotation = math.vec3([modelRotation[0] - 90, -modelRotation[2], modelRotation[1]]);

    const quaternion = math.eulerToQuaternion(rotation, "XZY");
    const meshMatrix = math.composeMat4(position, quaternion, scale, tempMat4);

    const oldWorldPos = math.vec3([worldPos[0], worldPos[1], worldPos[2], 1]);
    const newMeshPos = math.transformPoint4(meshMatrix, oldWorldPos, tempVec4);

    return math.vec3([newMeshPos[0], newMeshPos[1], newMeshPos[2]]);
  }

  static #_modelHash = new Map()
  static #_getProjectModel(modelUuid) {
    return this.#_modelHash.get(modelUuid) || (this.#_modelHash.set(modelUuid, store.getters['project/flatlist'].find(model => model.uuid === modelUuid)), this.#_modelHash.get(modelUuid))
  }
}

