import { defineStore } from 'pinia'

export const useSelectedElementsStore = defineStore('selectedElements', {
  state: () => {
    return { 
      selectedElements: [],
      pickedElement: null,

      isHideSelectedElements: false,
      isShowOnlySelectedElements: false,

      isStatusFloatingPanel: false,

      selectedBoxElements: [],
      selectedKeeper: {
        head: [],
        body: [],
      }
    }
  },

  actions: {
    setSelectedElements(selectedElements) {
      this.selectedElements = selectedElements
    },

    setPickedElement(pickedElement) {
      this.pickedElement = pickedElement
    },

    setHideSelectedElements(hideSelectedElements) {
      this.isHideSelectedElements = hideSelectedElements
    },

    setShowOnlySelectedElements(showOnlySelectedElements) {
      this.isShowOnlySelectedElements = showOnlySelectedElements
    },

    setStatusFloatingPanel(status) {
      this.isStatusFloatingPanel = status
    },

    setSelectedBoxElements(pickedArea) {
      this.selectedBoxElements.push(pickedArea)
    },

    setPickedKeeperHead(pickedKeeperHead) {
      this.selectedKeeper.head.push(pickedKeeperHead)
    },

    setPickedKeeperBody(pickedKeeperBody) {
      this.selectedKeeper.body.push(pickedKeeperBody)
    }
  },
})