import BlankLayout from "@/layouts/Blank"
import { App } from '@app/App'

const projectRoutes = {
  path: '/',
  component: BlankLayout,
  children: [
    { 
      path: '', 
      component: () => {
        return App.device.isPortable ? import('@/pages/landingMobile.vue') : import('@/pages/landing.vue')
      },
      alias: ['/home', '/index'] 
    },
    {
      path: 'icon',
      component: () => import('@/pages/icon.vue')
    },
    {
      path: 'zoo',
      component: () => import('@/pages/zoo.vue')
    },
    { 
      path: 'login', 
      component: () => import('@/pages/login.vue')
    },
    { 
      path: 'registration', 
      component: () => import('@/pages/registration.vue')
    },
    { 
      path: 'forgot', 
      component: () => import('@/pages/forgot.vue')
    },
    { 
      path: 'invite/:email', 
      props: true, 
      component: () => import('@/pages/invite.vue')
    },
    { 
      path: 'purchase/:tarif', 
      props: true, 
      component: () => import('@/pages/purchase.vue')
    },
    { 
      path: 'feedback', 
      component: () => import('@/pages/feedback.vue')
    },
    { 
      path: 'dashboard', 
      component: () => import('@/pages/Dashboard.vue'),
      children: [
        {
          path: 'folder/:uuid',
          props: true, 
          component: () => import('@/pages/Dashboard.vue')
        }
      ] 
    },
    { 
      path: 'project/:uuid', 
      props: true,
      name: 'project',
      component: () => import('@/pages/Project.vue'),
      children: [
        { 
          path: ':logo', 
          props: true, 
          component: () => import('@/pages/Project.vue') 
        },
        { 
          path: '/glmem/:glmem', 
          props: true, 
          component: () => import('@/pages/Project.vue') 
        },
      ]
    },
    { 
      path: 'project/cloud/:uuid/:hashProject', 
      props: true, 
      component: () => import('@/pages/ProjectCloud.vue') 
    },
    { 
      path: 'social/redirect/:name', 
      props: true, 
      component: () => import('@/components/social/Redirect.vue') 
    },
    {
      path: 'qr/forprint/:name/:uuid/',
      props: true,
      component: () => import('@/components/project/panel/left/components/position/QrPrint.vue')
    },
    {
      path: 'corp/:orgId',
      component: () => import('@/pages/Corp.vue'),
      props: true
    },
    {
      path: 'corp/:orgId/maintenance/:specId',
      component: () => import('@/pages/Corp.vue'),
      //component: () => import('@/components/corp/views/equipment/Maintenance'),
      props: true
    },
    {
      path: 'employee/:uuid',
      component: () => import('@/components/corp/views/EmployeeView'),
      props: true
    },
    { 
      path: 'drawing/qrcode/:fileHash/:userHash/:cloudHash', 
      props: true, 
      component: () => import('@/pages/DrawingInfo.vue') 
    },
    { 
      path: 'project-light/:uuid', 
      props: true, 
      component: () => import('@/pages/ProjectLight.vue') 
    },
  ]
}

export default projectRoutes
export { projectRoutes }