<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" fill="white" stroke="#F0F0F0"/>
    <rect x="2.47552" y="8.5" width="19" height="6" stroke="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9755 13V14H14.9755V13H15.9755Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9755 13V14H18.9755V13H19.9755Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9755 13V14H10.9755V13H11.9755Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.97552 13V14H6.97552V13H7.97552Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9755 16V17H1.97552V16L21.9755 16Z" fill="#FF0000"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9755 11V14H16.9755V11H17.9755Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9755 11V14H12.9755V11H13.9755Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.97552 11V14H8.97552V11H9.97552Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97552 11V14H4.97552V11H5.97552Z" fill="#303030"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>