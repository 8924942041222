import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { defineStore } from 'pinia'

export const useRegimeMeasurementStore = defineStore('regimeMeasurement', {
  state: () => {
    return {
      regimeMeasurementActive: false,
      regimeMeasurementMode: XeokitMediator.RegimeMeasurement.defaultMode
    }
  },

  actions: {
    setRegimeMeasurementActive(regimeMeasurementActive) {
      this.regimeMeasurementActive = regimeMeasurementActive
    },

    setRegimeMeasurementMode(regimeMeasurementMode) {
      this.regimeMeasurementMode = regimeMeasurementMode
    }
  }
})