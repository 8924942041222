<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 2C0 0.89 0.89 0 2 0H4V2H2V4H0V2ZM6 2V0H9V2H6ZM11 2V0H13C14.11 0 15 0.89 15 2V4H13V2H11ZM0 9V6H2V9H0ZM0 13V11H2V13H4V15H2C0.89 15 0 14.11 0 13Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 6H13H19C20.11 6 21 6.89 21 8V19C21 20.11 20.11 21 19 21H8C6.89 21 6 20.11 6 19V13C6 13 6 8.5 6 8C6 7 7.17775 6 8 6ZM19 8H15H8V15V19H19V8Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>