import { defineStore } from "pinia";


export const useDrawingPageStore = defineStore('drawing-page-store', {
  state: () => ({
    imageWidth: 0,
    imageHeight: 0,

    originalWidth: 0,
    originalHeight: 0,

    imageSrc: null,

    pageIsLoading: false
  })
})