import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'

import './registerServiceWorker'
import './plugins/components'
import './plugins/configure-app'

import i18n from '@/plugins/i18n'

import VueColumnsResizable from './plugins/columns-resizable'

import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(VueColumnsResizable)

import AOS from "aos";
import "aos/dist/aos.css";

// TODO: Удалить этот блок и переделать все глобальные подключения такого типа
import GlobalComponents from './globalComponents'

import { themes } from '@/store/theme.module'

import { mapMutations, mapActions } from 'vuex'

const tempGlobal = {
  install(Vue) {
    Vue.use(GlobalComponents);
  }
}
Vue.use(tempGlobal)

let isHome = true
router.afterEach( (to) => {
  if (isHome && to.path!=="/" && to.path!=="/home" && to.path!=="/index") {
    isHome = false
    import('@mdi/font/css/materialdesignicons.css')
    import('./assets/styles/theme.css')
  }
})


import lineClamp from 'vue-line-clamp'
Vue.use(lineClamp, {})

Vue.config.productionTip = false

Vue.directive('app-scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.mixin({
  computed: {
    isPro () {
      if (this.$store.state.project.project.tariffAlias && this.$store.state.project.project.tariffAlias !== undefined) 
        this.$app.version.useTariff(this.$store.state.project.project.tariffAlias)
      return this.$store.state.project && this.$store.state.project.project.tariffAlias == 'TariffPro'
    }
  },

  methods: {
    ...mapMutations('theme', ['setTheme']),
    ...mapActions('authUser', ['setProfileSettingTheme']),

    log: (...args) => console.log(...args),
    dev (text = '') { this.$root.$emit('dev', text) },

    _setTheme(themeName, isAuth = true) {
      const selected = themes[themeName.toUpperCase()]
      this.$vuetify.theme.themes.dark.surface = selected.style
      this.$vuetify.theme.themes.dark.toolbar = selected.style.toolbar
      this.$vuetify.theme.themes.dark.scroll = selected.style.scroll
      this.$vuetify.theme.themes.light.surface = selected.style
      this.$vuetify.theme.themes.light.toolbar = selected.style.toolbar
      this.$vuetify.theme.themes.light.scroll = selected.style.scroll
      this.$vuetify.theme.dark = ['dark'].includes(themeName.toLowerCase())
      this.setTheme(selected)

      if(isAuth) {
        this.setProfileSettingTheme(selected)
      }
    },
  }
})

let yandexMetrika = window.settings.yandexMetrika
Vue.prototype.$metrika = Vue.$metrika = {
  reachGoal(name, params) {
    // console.log('reachGoal', name, params)
    if ('ym' in window && yandexMetrika && yandexMetrika.active)
      window.ym(yandexMetrika.id, 'reachGoal', name, params)
  }
}

if (yandexMetrika && yandexMetrika.active) {
  router.afterEach(function (to, from) {
    // console.log('hit', to.path, {referer: from.path})
    if ('ym' in window)
      window.ym(yandexMetrika.id, 'hit', to.path, {referer: from.path});
  }) 
}

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
  router,
  store,
  vuetify,
  created () {
    AOS.init({ offset: 20 })
  },
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')

// Синхронизация localStorage между вкладками (в основном для refresh/access_token) !!! ПОД СОМНЕНИЕМ ОБРАТИТЬ ВНИМАНИЕ
window.addEventListener('storage', ev => {
  localStorage.setItem(ev.key, ev.newValue)
})

// Работал с синхронизацией вкладок

// window.addEventListener('storage', (event) => {
//   if (event.key == null) {
//     router.push('/login')
//   }
//   else if (event.key == 'access_token' && event.newValue) {
//     router.push('/dashboard')
//   }
// });
