export class ProjectShortcut {
  constructor (obj = {}) {
    this.uuid = obj.uuid
    this.title = obj.title
    this.alias = obj.alias
    this.color = obj.color
    this.description = obj.description
    this.preview = obj.preview
    this.cloudRoot = obj.cloudRoot
    //this.offsetX = obj.offsetX
    //this.offsetY = obj.offsetY
    //this.offsetZ = obj.offsetZ

    this.org = obj.org

    this.membership = obj.membership?.map(_makeMember) || []
    this.membership.reverse()
    this.#_me = this.membership.find(oneMember => oneMember.me)
    this.#_myLastVisitTime = _parseStringToDate(this.#_me?.lastVizited)?.getTime()
  }

  #_me
  #_myLastVisitTime

  get me () { return this.#_me }
  get myLastVisitTime () { return this.#_myLastVisitTime }

  hasMatchesWith (text) {
    let has = false
    if (text) {
      let comparedText = text.toLowerCase()
      let strings = []
      strings.push(this.alias, this.title, this.description)
      // strings.push(...this.membership.map(({ profile }) => profile.name))
      strings = strings.map(str => str?.toLowerCase())
      has = strings.some(value => value?.includes(comparedText))
    }
    return has
  }

  clone () {
    let json = JSON.parse(JSON.stringify(this))
    return new ProjectShortcut(json)
  }

  static compareByVisits (a, b) {
    return b.myLastVisitTime - a.myLastVisitTime
  }
}

// * Вспомогательные функции

function _makeMember (obj) {
  return new ProjectShortcutMember(obj)
}

function _parseStringToDate (str) {
  if (!str || typeof str !== 'string') return null

  let [dateStr, timeStr] = str.split(' ')
  let date = dateStr && new Date(dateStr)

  if (date && timeStr) {
    let [hours, minutes] = timeStr.split(':')
    date.setHours(+hours, +minutes)
  }

  return date
}

// * Внутренние классы

class ProjectShortcutMember {
  constructor ({ uuid, owner, me, lastVizited, profile, orgGroup, memberReference } = {}) {
    this.uuid = uuid
    this.owner = owner
    this.me = me
    this.profile = new ProjectShortcutMemberProfile(profile)
    this.orgGroup = new ProjectShortcutOrgGroup(orgGroup)
    this.memberReference = memberReference
    
    this.lastVizited = lastVizited
  }
}
class ProjectShortcutMemberProfile {
  constructor (profile) {
    let { uuid, avatar, name } = profile || {}

    this.uuid = uuid
    this.name = name
    this.avatar = avatar
  }
}
class ProjectShortcutOrgGroup{
  constructor (orgGroup) {
    let { uuid, title} = orgGroup || {}

    this.uuid = uuid
    this.title = title
  }
}