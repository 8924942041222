<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0C0.89 0 0 0.89 0 2V13C0 14.11 0.89 15 2 15H13C14.11 15 15 14.11 15 13V10H13V13H2V2H13V4H15V2C15 0.89 14.11 0 13 0M8 6C6.89 6 6 6.89 6 8V11H8V8H19V19H8V17H6V19C6 20.11 6.89 21 8 21H19C20.11 21 21 20.11 21 19V8C21 6.89 20.11 6 19 6H8Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>