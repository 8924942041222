export class ClassificatorNodeRule {

  constructor (node, level = 0, parent = null) {
    this.uuid = node.uuid
    this.title = node.title
    this.nodeType = {name:'RULE'}
    this.classificatorNode = parent
    
    this.findRule = node.findRule
    this.unitExpression = node.unitExpression
    this.unitExpressionFormula = node.unitExpressionFormula
    this.titleAttribute = node.titleAttribute
    this.titleAttributeFormula = node.titleAttributeFormula
    this.notGetMaterials = node.notGetMaterials
    this.showVolume = node.showVolume
    this.dataExportType = node.dataExportType
    this.ruleType = node.ruleType
    this.elementName = node.elementName
    this.elementValue = node.elementValue
    this.logicValidy = node.logicValidy
    
    this.level = level
    this.parent = parent
    this.children = []

    this.hasChilds = false
    this.hasRules = false
    this.isPenult = false
    this.isNode = false

    this.isRule = true
    this.isEndpoint = true 
  }
}