export class SearchElementItem {
  constructor(json) {
    this.uuid = json?.uuid ?? null
    this.name = json?.name ?? null
    this.selected = json?.selected ?? false
  }

  setSelectedValue(value) {
    this.selected = value
  }

  setData(json) {
    this.uuid = json?.uuid ?? null
    this.name = json?.name ?? null
    this.selected = json?.selected ?? false
  }
}