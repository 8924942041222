export default ({ Factory }) => ({

  /**
   * Список Словарей
   */
  //dictionaryList: () => Factory.forms.dictionaries.get(),

  dictionaryListByBind: (bind) => Factory.forms.dictionaries[bind].get(),

  saveDictionary: (obj) => Factory.forms.dictionary.post(obj),

  deleteDictionary: (id) => Factory.forms.dictionary[id].delete(),

  dictionary: (id) => Factory.forms.dictionary[id].get(),

  saveWord: (obj) => Factory.forms.word.post(obj),

  deleteWord: (id) => Factory.forms.word[id].delete(),

  //formsList: () => Factory.forms.forms.get(),

  formsListByBind: (bind) => Factory.forms.forms[bind].get(),

  formTypes: () => Factory.forms.formtypes.get(),

  fieldTypes: () => Factory.forms.fieldtypes.get(),

  saveForm: (obj) => Factory.forms.form.post(obj),

  deleteForm: (id) => Factory.forms.form[id].delete(),

  form: (id) => Factory.forms.form[id].get(),

  saveField: (obj) => Factory.forms.field.post(obj),

  deleteField: (id) => Factory.forms.field[id].delete(),

  fieldPrepare: (field) => Factory.forms.prepare.post(field),

  fieldPocess: (fieldId) => Factory.forms.process[fieldId].get(),

  fill: (data) => Factory.formdata.fill.post(data),

  dataList: (bind) => Factory.formdata.list[bind].get(),
  
  deleteData: (id) => Factory.formdata.data[id].delete(),

})