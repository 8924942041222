import BlankLayout from "@/layouts/Blank"

const accessRoutes = {
  path: '/',
  redirect: '/',
  component: BlankLayout,
  children: [
    {
      path: 'confirm/:userId/:authCode',
      component: () => import('@/components/access/ConfirmRegistration.vue'),
      props: true
    },

    {
      path: 'email/:userId/:authCode',
      component: () => import('@/components/access/ConfirmEmail.vue'),
      props: true
    },

    {
      path: 'restore/:userId/:authCode',
      component: () => import('@/components/access/ConfirmRestore.vue'),
      props: true
    },


    {
      path: 'treetest',
      component: () => import('@/Treetest.vue'),
      props: true
    },
  ]
}

export default accessRoutes
export { accessRoutes }