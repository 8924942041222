export default ({ Factory }) => ({
  unitTypes: () => {
    return Factory.elements.unittypes.get()
  },

  powerTypes: () => {
    return Factory.elements.powertypes.get()
  },

  addAttributeElement: (obj) => {
    return Factory.elements.attribute.post(obj)
  },

  similarElements: (selectedElements) => {
    return Factory.elements.similar.post(selectedElements)
  }
})
