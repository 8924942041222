import { defineStore } from 'pinia'

export const useTaskScreenshotStore = defineStore('taskScreenshot', {
  state: () => {
    return {
      createTaskWithTaskScreenshotDialogVisible: false,
      createTaskScreenshotDialogVisible: false,
      snapshotImageData: null,
      pickedEntityId: null,
      pickedWorldPos: [],
      screenshotComment: null,
      taskUuid: null,
      createScreenshotStatus: false,
    }
  },

  actions: {
    setCreateTaskWithTaskScreenshotDialogVisible(createTaskWithTaskScreenshotDialogVisible) {
      this.createTaskWithTaskScreenshotDialogVisible = createTaskWithTaskScreenshotDialogVisible
    },

    setCreateTaskScreenshotDialogVisible(createTaskScreenshotDialogVisible) {
      this.createTaskScreenshotDialogVisible = createTaskScreenshotDialogVisible
    },

    setSnapshotImageData(snapshotImageData) {
      this.snapshotImageData = snapshotImageData
    },

    setPickedEntityId(pickedEntityId) {
      this.pickedEntityId = pickedEntityId
    },

    setPickedWorldPos(pickedWorldPos) {
      this.pickedWorldPos = pickedWorldPos
    },

    setScreenshotComment(screenshotComment) {
      this.screenshotComment = screenshotComment
    },

    setTaskUuid(taskUuid) {
      this.taskUuid = taskUuid
    },

    setCreateScreenshotStatus(screenshotStatus) {
      this.createScreenshotStatus = screenshotStatus
    },
  },
})