export default ({ Factory}) => ({

  /**
   * Создать новый токен для проекта
   */
  addToken: (tokenDto) => {
    return Factory.token.post(tokenDto)
  },
  /**
   * Удаление токена проекта
   */
  deleteToken: (token) => {
    return Factory.token[token.uuid][token.project].delete()
  },

  /**
   *  Получение списка токенов проекта
   */
  listTokens: (projectUuid) => {
    return Factory.token[projectUuid].list.get()
  },

  /**
   *  Получение списка прав доступа
   */
  listScopes: () => {
    return Factory.token.scopes.get()
  }

})  