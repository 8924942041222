import { api } from "@/api"
import { WarningProfile, WarningFeature } from '@models/maintenance'
import { simpleMapper } from '@/utils'


let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

const getDefaultState = () => {
  return {
    profiles: [],
    events: {
      list: [],
      status: status.INITIAL,
      totalPages: 1
    },
    loaded: false,
    selectedProfile: null,
    selectedFeature: null,
    selectedEvent: null,
    warningLevels: [],
    warningFeatureType: [
      {key: "WarningChannelValue", title: "Телеметрия", message: "section.journal.types.telemetry"},
      {key: "WarningMaintenance", title: "Обслуживание", message: "section.journal.types.service"},
    ],
  }
}

function deleteFeatureFromProfile (node, id) {
  if(node.warningFeatures.length == 0) return false
  let index = node.warningFeatures.findIndex(item => item.uuid === id)
  if(index != -1){
    node.warningFeatures.splice(index, 1)
    return true
  } else {
    return false
  }
}


const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    warningFeatureType: ({warningFeatureType}) => warningFeatureType,

    events: ({ events }) => events.list,
    isEventsLoaded: ({ events }) => events.status == status.LOADED,

    editedProfile: ({ selectedProfile }) => {
      let saveObj = WarningProfile.DTO()
      if(selectedProfile) simpleMapper(saveObj, selectedProfile)
      return saveObj
    },

    editedFeature: ({ selectedFeature, selectedProfile }) => {
      let saveObj = new WarningFeature(null, selectedProfile, selectedFeature?.warningFeatureType)
      if(selectedFeature) simpleMapper(saveObj, selectedFeature)
      return saveObj
    },
  },

  mutations: {
    setLoaded: (state, val) => { state.loaded = val },
    setLevels: (state, levels) => { state.warningLevels = levels },
    setProfiles: (state, profiles) => { state.profiles = profiles },
    setEvents: (state, data) => { 
        state.events.list = data.content
        state.events.totalPages = data.totalPages
        state.events.status = status.LOADED
    },
    setEventsStatus: (state, status) => { state.events.status = status },
    setSelectedProfile: (state, profile) => { state.selectedProfile = profile },
    setSelectedFeature: (state, feature) => { state.selectedFeature = feature },
    setSelectedEvent: (state, event) => { state.selectedEvent = event },

    updateProfile: (state, data) => { 
      if(state.selectedProfile && state.selectedProfile.uuid == null) state.profiles.push(state.selectedProfile)
      Object.assign(state.selectedProfile, data)
    },

    deleteProfile: (state, id) => { 
      let index = state.profiles.findIndex(item => item.uuid === id)
      if(index != -1) state.profiles.splice(index, 1)
    },

    updateFeature: (state, data) => { 
      if(state.selectedFeature && state.selectedFeature.uuid == null) {
         state.profiles.find(item => item.uuid === data.warningProfile.uuid)?.warningFeatures.push(state.selectedFeature)
      }
      Object.assign(state.selectedFeature, data)
    },

    deleteFeature: (state, id) => { 
      state.profiles.some(item => {if(deleteFeatureFromProfile(item, id)) return true; else return false;})
    },

  },

  actions: {

    INIT: ({ dispatch }) => {
      dispatch("LOAD_WARNING_LEVELS")
      dispatch("LOAD_PROFILES")
      dispatch("LOAD_EVENTS")
    },

    LOAD_WARNING_LEVELS: ({ commit, state }) => {
      if( state.warningLevels.length == 0) {
        api.maintenance.getWarningLevels().then(data => {
          commit('setLevels', data)
        })
      }
    },

    LOAD_PROFILES: ({ commit, rootGetters }) => {
      let projectUuid = rootGetters['project/projectUuid']
      commit('setLoaded',false)
      api.maintenance.getWarningProfiles(projectUuid).then(data => {
        commit('setProfiles', data.map(item => new WarningProfile(item)))
        commit('setLoaded', true)
      })
    },

    LOAD_EVENTS: ({ commit, rootGetters }) => {
      commit('setEventsStatus', status.LOADING)
      let projectUuid = rootGetters['project/projectUuid']
      api.maintenance.getWarningEvents(projectUuid).then(data => {
        commit('setEvents', data)
      })
    },

    LOAD_EVENTS_BY_FILTER: ({ commit, rootGetters }, filter) => {
      commit('setEventsStatus', status.LOADING)
      filter.project = rootGetters['project/projectUuid']
      api.maintenance.getWarningEventsByFilter(filter).then(data => {
        commit('setEvents', data)
      })
    },

    addWarningProfile: ({ commit, rootGetters }) => {
      let projectUuid = rootGetters['project/projectUuid']
      let newWarningProfile= new WarningProfile(null)
      newWarningProfile.project = projectUuid
      commit('setSelectedProfile', newWarningProfile)
    },

    saveWarningProfile: ({ commit }, obj) => {
      let DTO = new WarningProfile(null)
      simpleMapper(DTO, obj)
      api.maintenance.saveWarningProfile(DTO).then(data => {
        commit('updateProfile', new WarningProfile(data))
      })
    },

    deleteWarningProfile: ({ commit }, id) => {
      api.maintenance.deleteWarningProfile(id).then(() => {
        commit('deleteProfile', id)
        commit('setSelectedProfile', null)
      })
    },

    addWarningFeature: ({ state, commit }, type = state.warningFeatureType[0].key) => {
      let newWarningFeature = new WarningFeature(null, state.selectedProfile, type)
      commit('setSelectedFeature', newWarningFeature)
    },

    saveWarningFeature: ({ commit, state }, obj) => {
      let DTO = new WarningFeature(null, null, obj.warningFeatureType)
      simpleMapper(DTO, obj)
      api.maintenance.saveWarningFeature(DTO, obj.warningFeatureType.toLowerCase()).then(data => {
        commit('updateFeature', new WarningFeature(data, state.selectedProfile))
      })
    },

    deleteWarningFeature: ({ commit }, id) => {
      api.maintenance.deleteWarningFeature(id).then(() => {
        commit('deleteFeature', id)
        commit('setSelectedFeature', null)
      })
    },

  }
}
