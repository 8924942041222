export default ({ Factory }) => ({
  /**
   * Загрузить все группы коллизий, созданные пользователем для указанного проекта
   * @param {String} projectUuid UUID проекта
   */
  getAllForProject: (projectUuid) => Factory.projects[projectUuid].groups.get(),

  /**
   * Загрузить все группы коллизий, которые соотвествуют выбранным элементам дерева
   * @param {*} filter 
   */
  getAllForProjectWithFilter: (filter) => Factory.projects.collisions.groups.post(filter),

  /**
   * Создание новой пользовательской группы коллизий в указанном проекте
   * @param {String} projectUuid UUID проекта
   * @param {String} title Название создаваемой группы
   * @param {String[]} uuids Список UUID's коллизий, которые надо добавить в группу
   */
  createWithCollisions: (projectUuid, title, uuids, camera) => Factory.projects[projectUuid].collisions.group.post({ title, uuids, camera }),

  /**
   * Копирование Axis (Оси) в новую пользовательскую группу коллизий в указанном проекте
   * @param {String} projectUuid UUID проекта
   * @param {String} title Название создаваемой группы
   * @param {String | Number} axisId ID Оси (Axis): если это FixedAxis, то от 1 до 4, иначе UUID пользовательской Оси CustomAxis 
   * @param {String[][]} axisNodesCompositeUUIDs Массив стэков путей до выбранных конечных узлов
   */
  createWithAxisNodes: (projectUuid, title, axisId, axisNodesCompositeUUIDs) => (
    Factory.projects[projectUuid].axis.group.post({ title, fixGroupId: axisId, elementClassPath: axisNodesCompositeUUIDs })
  ),

  /**
   * Удалить пользовательскую группу коллизий (только группу, не сами коллизии)
   * @param {String} collisionGroupUuid UUID группы коллизий
   */
  delete: (collisionGroupUuid) => Factory.groups[collisionGroupUuid].delete(),

  addCollisionsToGroup: (collisionGroupUuid, collisionList) => Factory.groups[collisionGroupUuid].put(collisionList),

  /**
   * Изменить название группы коллизий
   * @param {String} collisionGroupUuid UUID группы коллизий
   * @param {String} title Новое название
   */
  edit: (collisionGroupUuid, title) => Factory.groups[collisionGroupUuid].title.patch(title, {responseType: 'text', 'headers': {'Content-Type': 'text/plain'}}),

  /**
   * Пометить сразу все коллизии указанной группы как допустимые
   * @param {String} collisionGroupUuid UUID группы коллизий
   */
  setAllowed: (collisionGroupUuid, note) => Factory.groups[collisionGroupUuid].allowed.patch(note),

  setGroupCamera: (collisionGroupUuid, camera) => Factory.collisions.group[collisionGroupUuid].camera.patch(camera),

  getGroupCollisionView: (collisionGroupUuid) => Factory.collisions.group[collisionGroupUuid].geometry.get(),
})