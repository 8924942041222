import { XeokitMediator } from "../XeokitMediator"

class FloorBoundsCache {
  /**
   * @private
   * @type {{[key: string]: FloorBounds}}
   * @typedef {[number[], string[]]} FloorBounds
   */
  static #_cache = {}

  static getFloorBounds(floor) {
    return this.#_cache[floor.uuid] ||= (() => {
      const floorObjectIds = floorEndpointIds(floor)
      const aabb = XeokitMediator.viewer.scene.getAABB(floorObjectIds)

      return [aabb, floorObjectIds]
    })()
  }

  static clear() {
    this.#_cache = {}
  }
}

/**
 * @private
 * @returns {string[]} 
 */
function floorEndpointIds(floor) {
  let ids = []

  floor.children.forEach(child => {
    ids = ids.concat(floorEndpointIds(child))
  })

  if (!floor.children.length) {
    ids.push(floor.globalId)
  }

  return ids
}

export { FloorBoundsCache }