import { Equipment } from '.'

export class EquipmentGroup {

  static DTO () {
    return {
      uuid: null,
      title: '',
      project: null,
      parent: null
    }
  }

  constructor (group, level = 0, parent = null) {
    this.uuid = group?.uuid || null
    this.title = group?.title || ''
    this.project = group?.project || null
    
    this.level = level
    this.parent = parent
    
    this.children = group && group.children ? group.children.map(item => new EquipmentGroup(item, level + 1, this)) : []
    this.equipments = group && group.equipments ? group.equipments.map(item => new Equipment(item, level + 1, this)) : []
  }

  get isNode () {
    return this.children.length > 0 || this.equipments.length > 0
  }

  get isGroup () {
    return true
  }

  get isEndpoint () {
    return !this.isNode
  }

}
