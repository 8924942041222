import { utils } from "@xeokit/xeokit-sdk/src/viewer/scene/utils.js";
import { LASLoaderPlugin, SceneModel } from "@xeokit/xeokit-sdk";
import { loadLASHeader } from "./bimLoadLasHeader";
import { parse } from "@loaders.gl/core";
import { LASLoader } from "@loaders.gl/las/dist/esm/las-loader.js";
import { math } from "@xeokit/xeokit-sdk/src/viewer/index";
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { getLAS } from '../LasLoaderPlugin/LASDataSource.js'

const MAX_VERTICES = 500000;
let responseGlobalLink = null
//Этот файл игнорируется линтером
export class BimLASLoaderPlugin extends LASLoaderPlugin {
  load(params = {}) {
    if (params.id && this.viewer.scene.components[params.id]) {
      this.error(
        "Component with this ID already exists in viewer: " +
          params.id +
          " - will autogenerate this ID"
      );
      delete params.id;
    }

    const sceneModel = new SceneModel(
      this.viewer.scene,
      utils.apply(params, {
        isModel: true,
        dtxEnabled: true,
      })
    );

    sceneModel.isLas = true

    if (!params.src && !params.las) {
      this.error("load() param expected: src or las");
      return sceneModel; // Return new empty model
    }

    const options = {
      las: {
        skip: this._skip,
        fp64: this._fp64,
        colorDepth: this._colorDepth,
      },
    };

    if (params.src) {
      this._loadModel(params.src, params, options, sceneModel);
    } else {
      this._parseModel(params.las, params, options, sceneModel).then(
        () => {},
        (errMsg) => {
          this.error(errMsg);
          sceneModel.fire("error", errMsg);
        }
      );
    }

    return sceneModel;
  }

  async _loadModel(src, params, options, sceneModel) {
    await getLAS(
      params.src,
      (arrayBuffer) => {
        this._parseModel(arrayBuffer, params, options, sceneModel).then(
          () => {},
          () => {
            console.log('Принудительное прерывание загрузки модели');
            sceneModel.fire("error");
          }
        );
      },
      () => {
        console.log('Принудительное прерывание загрузки модели');
        sceneModel.fire("error");
      },
      sceneModel
    );
  }

  async _parseModel(arrayBuffer, params, options, sceneModel) {
    XeokitMediator.lasLoader.fire('renderCloudStart')
    function readPositions(attributesPosition) {
      const positionsValue = attributesPosition.value;
      if (params.rotateX) {
        if (positionsValue) {
          for (let i = 0, len = positionsValue.length; i < len; i += 3) {
            const temp = positionsValue[i + 1];
            positionsValue[i + 1] = positionsValue[i + 2];
            positionsValue[i + 2] = temp;
          }
        }
      }
      return positionsValue;
    }

    XeokitMediator.lasLoader.fire('progressRenderCloud')

    function readColorsAndIntensities(attributesColor, attributesIntensity) {
      const colors = attributesColor.value;
      const colorSize = attributesColor.size;
      const intensities = attributesIntensity.value;
      const colorsCompressedSize = intensities.length * 4;
      const colorsCompressed = new Uint16Array(colorsCompressedSize);
      for (
        let i = 0, j = 0, k = 0, len = intensities.length;
        i < len;
        i++, k += colorSize, j += 4
      ) {
        colorsCompressed[j + 0] = colors[k + 0];
        colorsCompressed[j + 1] = colors[k + 1];
        colorsCompressed[j + 2] = colors[k + 2];
        colorsCompressed[j + 3] = Math.round((intensities[i] / 65536) * 255);
      }
      return colorsCompressed;
    }

    function readIntensities(attributesIntensity) {
      const intensities = attributesIntensity.intensity;
      const colorsCompressedSize = intensities.length * 4;
      const colorsCompressed = new Uint16Array(colorsCompressedSize);
      for (
        let i = 0, j = 0, k = 0, len = intensities.length;
        i < len;
        i++, k += 3, j += 4
      ) {
        colorsCompressed[j + 0] = 0;
        colorsCompressed[j + 1] = 0;
        colorsCompressed[j + 2] = 0;
        colorsCompressed[j + 3] = Math.round((intensities[i] / 65536) * 255);
      }
      return colorsCompressed;
    }

    XeokitMediator.lasLoader.fire('progressRenderCloud')
    return new Promise((resolve, reject) => {
      if (sceneModel.destroyed) {
        reject();
        return;
      }

      const stats = params.stats || {};
      stats.sourceFormat = "LAS";
      stats.schemaVersion = "";
      stats.title = "";
      stats.author = "";
      stats.created = "";
      stats.numMetaObjects = 0;
      stats.numPropertySets = 0;
      stats.numObjects = 0;
      stats.numGeometries = 0;
      stats.numTriangles = 0;
      stats.numVertices = 0;
      try {
        const lasHeader = loadLASHeader(arrayBuffer);
        parse(arrayBuffer, LASLoader, options).then((parsedData) => {
          XeokitMediator.lasLoader.fire('progressRenderCloud')

          const attributes = parsedData.attributes;
          const loaderData = parsedData.loaderData;
          const pointsFormatId =
            loaderData.pointsFormatId !== undefined
              ? loaderData.pointsFormatId
              : -1;

          if (!attributes.POSITION) {
            sceneModel.finalize();
            reject("No positions found in file");
            return;
          }
          let positionsValue;
          let colorsCompressed;

          switch (pointsFormatId) {
            case 0:
              positionsValue = readPositions(attributes.POSITION);
              colorsCompressed = readIntensities(attributes.intensity);
              break;
            case 1:
              if (!attributes.intensity) {
                sceneModel.finalize();
                reject("No positions found in file");
                return;
              }
              positionsValue = readPositions(attributes.POSITION);
              colorsCompressed = readIntensities(attributes.intensity);
              break;
            case 2:
              if (!attributes.intensity) {
                sceneModel.finalize();
                reject("No positions found in file");
                return;
              }
              positionsValue = readPositions(attributes.POSITION);
              colorsCompressed = readColorsAndIntensities(
                attributes.COLOR_0,
                attributes.intensity
              );
              break;
            case 3:
              if (!attributes.intensity) {
                sceneModel.finalize();
                reject("No positions found in file");
                return;
              }
              positionsValue = readPositions(attributes.POSITION);
              colorsCompressed = readColorsAndIntensities(
                attributes.COLOR_0,
                attributes.intensity
              );
              break;
          }

          XeokitMediator.lasLoader.fire('progressRenderCloud')

          const pointsChunks = chunkArray(positionsValue, MAX_VERTICES * 3);
          const colorsChunks = chunkArray(colorsCompressed, MAX_VERTICES * 4);
          const meshIds = [];
          for (let i = 0, len = pointsChunks.length; i < len; i++) {
            const meshId = `pointsMesh${i}`;
            meshIds.push(meshId);
            try {
              sceneModel.createMesh({
                id: meshId,
                primitive: "points",
                positions: pointsChunks[i],
                colorsCompressed:
                  i < colorsChunks.length ? colorsChunks[i] : null,
              });
            } catch (ex) {
              console.log(ex);
            }
          }

          XeokitMediator.lasLoader.fire('progressRenderCloud')
          XeokitMediator.lasLoader.fire('renderCloudDown')
          /*
                              const pointsChunks = chunkArray(positionsValue, MAX_VERTICES * 3);
                  const colorsChunks = chunkArray(colorsCompressed, MAX_VERTICES * 4);
                  const meshIds = [];

                  for (let i = 0, len = pointsChunks.length; i < len; i++) {

                      const geometryId = `geometryMesh${i}`;
                      const meshId = `pointsMesh${i}`;
                      meshIds.push(meshId);

                      sceneModel.createGeometry({
                          id: geometryId,
                          primitive: "points",
                          positions: pointsChunks[i],
                          colorsCompressed: (i < colorsChunks.length) ? colorsChunks[i] : null
                      });

                      sceneModel.createMesh({
                          id: meshId,
                          geometryId
                      });
                  }
                    */

          const pointsObjectId = math.createUUID();

          sceneModel.createEntity({
            id: pointsObjectId,
            meshIds,
            isObject: true,
          });

          sceneModel.finalize();

          if (params.loadMetadata !== false) {
            const rootMetaObjectId = math.createUUID();
            const metadata = {
              projectId: "",
              author: "",
              createdAt: "",
              schema: "",
              creatingApplication: "",
              metaObjects: [
                {
                  id: rootMetaObjectId,
                  name: "Model",
                  type: "Model",
                },
                {
                  id: pointsObjectId,
                  name: "PointCloud (LAS)",
                  type: "PointCloud",
                  parent: rootMetaObjectId,
                  attributes: lasHeader || {},
                },
              ],
              propertySets: [],
            };
            const metaModelId = sceneModel.id;
            this.viewer.metaScene.createMetaModel(
              metaModelId,
              metadata,
              options
            );
          }

          sceneModel.scene.once("tick", () => {
            if (sceneModel.destroyed) {
              return;
            }
            sceneModel.scene.fire("modelLoaded", sceneModel.id); // FIXME: Assumes listeners know order of these two events
            sceneModel.fire("loaded", true, false); // Don't forget the event, for late subscribers
          });

          resolve();
        });
      } catch (e) {
        sceneModel.finalize();
        reject(e);
      }
    });
  }
}

function chunkArray(array, chunkSize) {
  if (chunkSize >= array.length) {
    return array;
  }
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}
