import { useGridStore } from "@/stores/viewerTools/grid.store"
import { fetchProjectGrids, fetchGridsIfcTree, toggleModelGridsVisibility } from "@/providers/data/grids.dataProvider"
import { ModelGrids } from "@/plugins/xeokit/modelGrids"
import { createFont, getContrastBlackWhiteColor } from "@/plugins/IFCGrids/grids.utils"
import { AlertService } from "@/assets/app/AlertService"
import i18n from "@/plugins/i18n"

// const gridStore = useGridStore()

export const gridsGlowingThrough = () => useGridStore().gridsGlowingThrough
export const gridsIfcTree = () => useGridStore().gridsIfcTree
export const isGridsAvailable = () => useGridStore().isGridsAvailable
export const isGridsActive = () => useGridStore().isGridsActive
export const isGridsCreated = () => !!Object.keys(ModelGrids.plugin.projectGrids).length
export const needDebounce = () => useGridStore().needDebounce

export function prepareGridsCache() {
  // TODO
}

export function resetGridsCache() {
  useGridStore().$reset()
}

export function initGrids(viewer) {
  ModelGrids.init(viewer)

  fetchProjectGrids().then(data => {
    ModelGrids.plugin.createProjectGrids(data, gridCfg())
  }).catch(() => {
    throw new Error("Grids not created!")
  })
}

export function loadGrids() {
  fetchProjectGrids().then(gridsData => {
    if (gridsData.length) {
      ModelGrids.plugin.createProjectGrids(gridsData, gridCfg())

      setProjectGridsActive(true)
    }
    else AlertService.info({info: i18n.t('viewer.tools.grids.noActiveModel')})
  })
}

export function toggleDisplayOfGrids() {
  if (!useGridStore().isGridsActive) {
    if (!isGridsCreated()) { 
      loadGrids()
    }
    else {
      ModelGrids.plugin.setProjectGridsVisible(true)
      setProjectGridsActive(true)
    }
  }
  else {
    ModelGrids.plugin.setProjectGridsVisible(false) 
    setProjectGridsActive(false)
  }
}

export function drawGridsBySceneAabb(aabb) {
  ModelGrids.plugin.drawGridsBySceneAabb(aabb)
}

export function destroyProjectGrids() {
  ModelGrids.plugin?.destroyProjectGrids()
}

export function setGridsGlowingThrough(value) {
  useGridStore().gridsGlowingThrough = value
  ModelGrids.plugin.setProjectGridsGlowThrough(value)
}

export function setGridsDebounce(value) {
  useGridStore().needDebounce = value
  ModelGrids.plugin.setDebounce(value)
}

export function setProjectGridsActive(value) {
  useGridStore().isGridsActive = value
  ModelGrids.plugin.projectGridsActive = value
}

export function requestGridsIfcTree() {
  fetchGridsIfcTree().then(data => {
    useGridStore().gridsIfcTree = data.model
  })
}

export function toggleModelGridsVisible(revisionUuid) {
  toggleModelGridsVisibility(revisionUuid).then(() => {
    // TODO: заменить на перерисовку из кеша
    ModelGrids.plugin.destroyProjectGrids()
    fetchProjectGrids().then(gridsData => {
      if (useGridStore().isGridsActive) {
        ModelGrids.plugin.createProjectGrids(gridsData, gridCfg())
      }
    })
  })
}

//#region Конфиги

export function gridCfg() {
  return {
    glowThrough: gridsGlowingThrough(),
    color: getContrastBlackWhiteColor(),
    font: createFont(),
    visible: isGridsActive(),
  }
}

export function pluginCfg() {
  return {
    projectGridsActive: isGridsActive(),
    needDebounce: needDebounce()
  }
}

//#endregion