import { defineStore } from 'pinia'

export const ViewerTool = Object.freeze({
  NONE: 'none',
  GROUPS: 'marksAndGroups',
  PLUGINS: 'measurementAndSections',
  SCENE: 'displayAndNavigation',
  COLORS: 'colors',
})

export const useViewerToolsStore = defineStore('viewerTools', {
  state: () => {
    return {
      activeTool: ViewerTool.NONE,
    }
  },
})
