import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"
import { CoordinateAxis } from "./coordinateAxis";
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";
import { Component } from "@xeokit/xeokit-sdk";

const PERSPECTIVE_SCALE_MULTIPLIER = 0.085
const ORTHO_SCALE_MULTIPLIER = 0.05

/*eslint-disable no-dupe-class-members*/
export class CoordinatePin extends Component{

  #scene; #node; 
  #xCoordAxis; #yCoordAxis; #zCoordAxis;
  #coordinate; #xktVersion; #visible; #creationDate;
  #sceneTickListenerId;

  constructor(plugin, cfg = {}) {
    super(plugin.viewer.scene, cfg);

    this.#scene = plugin.viewer.scene,
    this.#node = null

    this.#xCoordAxis = null
    this.#yCoordAxis = null
    this.#zCoordAxis = null

    this.#coordinate = cfg.coordinate ?? [0, 0, 0]
    this.#xktVersion = cfg.xktVersion ?? 10
    this.#visible = cfg.visible ?? true
    this.#creationDate = cfg.creationDate

    this.#sceneTickListenerId = null
    
    this.#create()
  }

  get coordinate() { return this.#coordinate }
  get xktVersion() { return this.#xktVersion }
  get visible() { return this.#visible }
  get creationDate() { return this.#creationDate }

  #create() {
    this.#node = new XeokitNode(this.#scene, {
      isNode: true,
      selected: false,
      receivesShadow: false,
      castsShadow: false,
      clippable: false,
      collidable: false,
      pickable: false,
      visible: this.#visible,
      origin: this.#coordinate
    })

    this.#xCoordAxis = new CoordinateAxis(this.#node, { axisType: "x", coordinate: this.#coordinate })
    this.#yCoordAxis = new CoordinateAxis(this.#node, { axisType: "y", coordinate: this.#coordinate })  
    this.#zCoordAxis = new CoordinateAxis(this.#node, { axisType: "z", coordinate: this.#coordinate })
    
    let lastDistance = 0
    const camera = this.#scene.camera

    this.#sceneTickListenerId = this.#scene.on("tick", () => {
      const distance = geometry.math.distance(camera.eye, this.#coordinate)

      if (distance != lastDistance) {
        if (camera.projection === "perspective") {
          const axisScale = distance * PERSPECTIVE_SCALE_MULTIPLIER
          const scale = [axisScale, axisScale, axisScale]
          this.#node.scale = scale
          this.#labelsLookAtCamera()
          
          lastDistance = distance;
        }
        else if (camera.projection === "ortho") {
          const size = camera.ortho.scale * ORTHO_SCALE_MULTIPLIER
          const scale = [size, size, size]
          this.#node.scale = scale
          this.#labelsLookAtCamera()
          
          lastDistance = distance;
        }
      }
    })
  }

  setCoordinate(coord) {
    this.#coordinate = coord ?? this.#coordinate
    this.#node.origin = coord ?? this.#coordinate
  }

  setVisible(value) {
    this.#visible = !!value
    this.#node.visible = !!value
  }

  showLabelPlaceholder() {
    this.#xCoordAxis.label.showPlaceholder()
    this.#yCoordAxis.label.showPlaceholder()
    this.#zCoordAxis.label.showPlaceholder()
  }

  destroy() {
    this.#node?.destroy()
    this.#node = null
    
    this.#xCoordAxis.destroy()
    this.#yCoordAxis.destroy()
    this.#zCoordAxis.destroy()

    this.#scene.off(this.#sceneTickListenerId)
  }

  #labelsLookAtCamera() {
    const labelUp = [0, 0, 1]
    const labelForward = [0, 1, 0]
    const quaternion = geometry.math.getLookAtCameraQuaternion(this.#scene.camera, labelUp, labelForward)

    this.#xCoordAxis.label.mesh.quaternion = quaternion
    this.#yCoordAxis.label.mesh.quaternion = quaternion
    this.#zCoordAxis.label.mesh.quaternion = quaternion
  }
}