import { XeokitMediator } from "../../../xeokit/XeokitMediator";
import './highlightFrameStyle.scss'

class HighlightFrame {
  // eslint-disable-next-line
  constructor(onSelect = (isShiftKeyPressed) => { }) {
    this._selectionRectangle = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }

    this._divElement = null
    this._isMouseDown = false
    this._isCtrlKeyPressed = false
    this._isShiftKeyPressed = false

    this._startCanvasPos = null
    this._endCanvasPos = null

    this._ctrlKeyDown = () => { }
    this._ctrlKeyUp = () => { }
    this._shiftKeyDown = () => { }
    this._shiftKeyUp = () => { }

    this._onSelect = onSelect
  }

  activate() {
    this._divElement = document.createElement('div')
    this._divElement.classList.add('bim-highlight-frame')
    const divContainer = document.getElementById('measurementScrim')
    divContainer.appendChild(this._divElement)

    this.onKeysListeners()
    this.onViewerListeners()
  }

  onKeysListeners() {
    document.addEventListener("keydown", this._ctrlKeyDown = (event) => {
      if (event.ctrlKey) {
        this._isCtrlKeyPressed = true
        // XeokitMediator.uninstallInput()
      }
    })

    document.addEventListener("keyup", this._ctrlKeyUp = (event) => {
      if (!event.ctrlKey) {
        XeokitMediator.MouseNavigation.setMouseNavigation(XeokitMediator.MouseNavigation.mouseNav)
        this._isCtrlKeyPressed = false
      }
    })

    document.addEventListener("keydown", this._shiftKeyDown = (event) => {
      if (event.shiftKey) this._isShiftKeyPressed = true
    })

    document.addEventListener("keyup", this._shiftKeyUp = (event) => {
      if (!event.shiftKey) this._isShiftKeyPressed = false
    })
  }

  onViewerListeners() {
    this._onInputMouseDown = XeokitMediator.viewer.scene.input.on("mousedown", (canvasPosOrigin) => {
      if (this._isCtrlKeyPressed) {
        this._isMouseDown = true;
        
        let canvasPos = [...canvasPosOrigin]
        this._startCanvasPosOrigin = [...canvasPosOrigin]

        const canvasRepid = document.querySelector('#myCanvas')
        const canvasHighlight = canvasRepid.getBoundingClientRect(canvasRepid)
        canvasPos[0] += canvasHighlight.left
        canvasPos[1]+= canvasHighlight.top

        this._startCanvasPos = Object.assign([], canvasPos)
        this._selectionRectangle.left = canvasPos[0]
        this._selectionRectangle.top = canvasPos[1] 
      
      }
    })

    this._onInputMouseMove = XeokitMediator.viewer.scene.input.on("mousemove", (canvasPosOrigin) => {
      this._endCanvasPosOrigin = [...canvasPosOrigin]
      let canvasPos = [...canvasPosOrigin]

      const canvasRepid = document.querySelector('#myCanvas')
      const canvasHighlight = canvasRepid.getBoundingClientRect(canvasRepid)      
      canvasPos[0] += canvasHighlight.left
      canvasPos[1] += canvasHighlight.top
      
      this._endCanvasPos = Object.assign([], canvasPos)
      if (this._isMouseDown && this._isCtrlKeyPressed) {
        if (this._startCanvasPos[0] > canvasPos[0]) {
          this._selectionRectangle.left = canvasPos[0]
          this._selectionRectangle.right = this._startCanvasPos[0] 
        }
        else {
          this._selectionRectangle.right = canvasPos[0]
          this._selectionRectangle.left = this._startCanvasPos[0]
        }
        if (this._startCanvasPos[1] > canvasPos[1]) {
          this._selectionRectangle.top = canvasPos[1]
          this._selectionRectangle.bottom = this._startCanvasPos[1]
        }
        else {
          this._selectionRectangle.bottom = canvasPos[1]
          this._selectionRectangle.top = this._startCanvasPos[1]
        }

        this.showSelectionRectangle(this._selectionRectangle)
      }
      else {
        this._isMouseDown = false

        this.hideSelectionRectangle()
      }
    })

    this._onInputMouseUp = XeokitMediator.viewer.scene.input.on("mouseup", () => {
      if (this._isMouseDown && this._isCtrlKeyPressed && this._startCanvasPos != null) {
        this._isMouseDown = false

        if (this._startCanvasPos !== this._endCanvasPos) {
          this._onSelect(this._startCanvasPosOrigin, this._endCanvasPosOrigin, this._isShiftKeyPressed)
        }

        this.hideSelectionRectangle()
      }
    })
  }

  getSelectionRectNode() {
    return document.querySelector(".bim-highlight-frame")
  }

  showSelectionRectangle(selection) {
    let rect = this.getSelectionRectNode()
    rect.style.left = `${selection.left}px`
    rect.style.top = `${selection.top + window.scrollY}px`
    rect.style.width = `${selection.right - selection.left}px`
    rect.style.height = `${selection.bottom - selection.top}px`
    rect.style.opacity = 0.5
  }

  hideSelectionRectangle() {
    this._endCanvasPos = null
    this._startCanvasPos = null
    this._endCanvasPosOrigin = null
    this._startCanvasPosOrigin = null

    this._selectionRectangle = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
    this.showSelectionRectangle(this._selectionRectangle)

    let rect = this.getSelectionRectNode()
    rect.style.opacity = 0
  }

  removeKeysPressedListeners() {
    document.removeEventListener("keydown", this._ctrlKeyDown)
    document.removeEventListener("keyup", this._ctrlKeyUp)

    document.removeEventListener("keydown", this._shiftKeyDown)
    document.removeEventListener("keyup", this._shiftKeyUp)
  }
  // TODO УБРАТЬ СЛУШАТЕЛИ ПРИ ВЫХОДЕ ИЗ ПРОЕКТА, желательно вообще все возможные про проекту
  removeMouseListeners() {
    const input = XeokitMediator.viewer.scene.input
    input.off(this._onInputMouseDown)
    input.off(this._onInputMouseUp)
    input.off(this._onInputMouseMove)
  }

  deactivate() {
    this.removeMouseListeners()
    this.removeKeysPressedListeners()
    this._divElement.remove()
  }
}

export { HighlightFrame }