<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.27969 15.75H5.51728L13.5943 7.729L12.9861 7.104L12.3568 6.5L4.27969 14.521V15.75ZM2.51746 17.5V13.771L13.5732 2.792C13.923 2.44466 14.3392 2.271 14.8219 2.271C15.3045 2.271 15.7204 2.44466 16.0695 2.792L17.3283 4.042C17.6639 4.37533 17.8318 4.78833 17.8318 5.281C17.8318 5.77433 17.6639 6.18766 17.3283 6.521L6.27252 17.5H2.51746ZM16.0695 5.271L14.8319 4.042L16.0695 5.271ZM13.5943 7.729L12.9861 7.104L12.3568 6.5V6.479L13.5943 7.729Z"
      fill="#505254"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>