<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="24" rx="2" fill="none" />
    <path
      d="M20.5 17C24.6075 16.2071 26.9053 14.4126 26.9053 12.3194C26.9053 9.47086 22.0011 7.1597 15.9545 7.1597C9.90417 7.1597 5 9.47086 5 12.3194C5 14.1909 7.71997 16.1826 11.1923 17.0921"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="22.9256"
      stroke-linecap="round"
    />
    <path
      d="M20.5957 7.35889C19.69 4.06313 17.905 2 15.9546 2C13.0609 2 10.7159 6.47576 10.7159 12C10.7159 17.5242 13.0609 22 15.9546 22C17.9012 22 19.5944 20.2958 20.5001 17"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="22.9256"
      stroke-linecap="round"
    />
    <path d="M20 14H19V15H20V14Z" fill="currentColor" />
    <path d="M19 13H18V14H19V13Z" fill="currentColor" />
    <path d="M18 12H17V13H18V12Z" fill="currentColor" />
    <path d="M17 9H16V12H17V9Z" fill="currentColor" />
    <path d="M14 14H13V15H14V14Z" fill="currentColor" />
    <path d="M15 13H14V14H15V13Z" fill="currentColor" />
    <path d="M16 12H15V13H16V12Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>