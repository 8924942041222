import { api } from '@/api'
// import { App } from '@app/App'
import { ProjectShortcut } from '@models/Project'

export default {
  namespaced: true,

  state: {
    foldersList: [],
    selectedFolder: null,
    status: {
      isFoldersLoading: false,
    },
    folderProjects: []
  },

  getters: {
    folderList: ({ foldersList }) => foldersList,
    projectsFromFolder: ({ folderProjects }) => folderProjects
  },
  
  mutations: {
    SET_LOADED_FOLDERS(state, list) {
      state.foldersList = list
    },
    SET_LOADED_PROJECTS_FROM_FOLDER(state, list) {
      state.folderProjects = list.map(item => new ProjectShortcut(item))
    },
    setSelectedFolder(state, folder) {
      state.selectedFolder = folder
    }
  },

  actions: {
    loadMyFolders({ commit }){
      return api.folder.getFolders().then(data => {
        commit('SET_LOADED_FOLDERS', data)
        return data
      })
    },

    addFolder( { dispatch }, folderName ){
      return api.folder.saveFolder(folderName).then(data => {
        dispatch('loadMyFolders')
        return data
      })
    },

    addProjectToFolder({ dispatch }, { folderUuid, projectUuid }){
      return api.folder.patchFolderProject(folderUuid, projectUuid).then(() => {
        dispatch('loadMyFolders')
      })
    },

    loadProjectFromFolder({ commit }, folderUuid){
      return api.folder.getFolderProjects(folderUuid).then(data => {
        commit('SET_LOADED_PROJECTS_FROM_FOLDER', data)
      })
    },

    editFolder({ dispatch }, folder){
      return api.folder.editFolder(folder).then(() => {
        dispatch('loadMyFolders')
      })
    },

    removeProjectFromFolder({ dispatch }, { folderUuid, projectUuid }){
      return api.folder.deleteProjectFromFolder(folderUuid, projectUuid).then(() => {
        dispatch('loadProjectFromFolder', folderUuid)
      })
    },

    removeFolder({ dispatch }, folderUuid){
     return api.folder.delFolder(folderUuid).then((data) => {
        dispatch('loadMyFolders')
        return data
     })
    },

    changeProjectFolder({ dispatch }, { fromFolderUuid, projectUuid, toFolderUuid }){
      return api.folder.changeProjectFolder(fromFolderUuid, projectUuid, toFolderUuid).then(() => {
        dispatch('loadProjectFromFolder', fromFolderUuid)
      })
    }
    
  }
}