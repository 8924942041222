<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7.33333V6.25H6V7.33333H0ZM0 4.20833V3.125H9V4.20833H0ZM0 1.08333V0H9V1.08333H0ZM6.67958 11.1667V9.17313L10.9744 4.89896C11.0538 4.81743 11.1388 4.76049 11.2294 4.72813C11.3199 4.69576 11.4097 4.67958 11.4988 4.67958C11.5958 4.67958 11.69 4.69715 11.7812 4.73229C11.8726 4.76757 11.9557 4.82049 12.0304 4.89104L12.9471 5.83646C13.0172 5.9159 13.0713 6.00125 13.1094 6.0925C13.1476 6.18361 13.1667 6.27396 13.1667 6.36354C13.1667 6.45313 13.1491 6.54417 13.114 6.63667C13.079 6.72917 13.0233 6.81444 12.9471 6.8925L8.67313 11.1667H6.67958ZM7.42313 10.4231H8.36063L10.9406 7.85083L10.4744 7.37167L10.024 6.8975L7.42313 9.48563V10.4231ZM10.4744 7.37167L10.024 6.8975L10.9406 7.85083L10.4744 7.37167Z" fill="#B5B5B5"/>
  </svg>

</template>

<script>
export default {

}
</script>

<style>

</style>