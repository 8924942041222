import table from './channel.table.module'
import cache from './channel.cache.module'

export default {
  namespaced: true,

  modules: {
    table,
    cache,
  },

  actions: {
    resetState({ dispatch }) {
      dispatch('table/resetState')
      dispatch('cache/resetState')
    }
  }
}