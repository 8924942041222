<template>
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="14" r="13" stroke="currentColor" stroke-width="2" />
    <path
      d="M16.4375 18.1675C15.7077 21.5906 10.8176 20.5481 11.5473 17.125L16.5863 6.56862L16.4375 18.1675Z"
      fill="currentColor"
    />
    <path
      d="M1 31L29 31"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="square"
    />
    <line
      x1="12.5448"
      y1="30.6362"
      x2="13.5448"
      y2="26.6362"
      stroke="currentColor"
      stroke-width="3"
    />
    <path
      d="M24 14C24 9.02944 19.9706 5 15 5C10.0294 5 6 9.02944 6 14"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-dasharray="2 2"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>