import { math } from "@xeokit/xeokit-sdk"
import { MeasurementLabel } from "./measurementLabel"
import { MeasurementSegment } from "./measurementSegment"
import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"

/*eslint-disable no-dupe-class-members*/
export class MeasurementAxis {

  #owner; #axisNode; #axisLabel; #axisSegment; // class instances
  #decimalPlaces; #units; #length; #color;     // axis visual settings                   
  #coordinateAxis;                             // type of coordinate axis
  #position; #rotation; #scale;                // transformation parameters
  #matrix; #quaternion;                        // utils transformation parameters

  /**
   * Создать ось измерения. Отражает длину между точками измерения по оси, описанной через матрицу трансформации.
   * 
   * @param {Component} owner Scene или Node. Создаваемая ось будет закреплена за этим владельцем, уничтожение владельца повлечет уничтожение и этого компонента. Наследует свойства трансформации.
   * @param {Object} cfg Конфиг.
   * @param {Number} cfg.decimalPlaces Отображаемое количество знаков после запятой.
   * @param {String} cfg.units Единица измерения. Допускаются значения "mm", "m", "сm", "km".
   * @param {Number} cfg.length Величина измерения. Возможна установка через setLength().
   * @param {Number[]} cfg.color Цвет оси в формате [r, g, b] от 0 до 1.
   * @param {String} cfg.coordinateAxis Ось координат. Допускаются значения "X", "Y", "Z" и "custom". Значения "X", "Y" и "Z" переопределяют свойство rotation на соответствующую ось. Если указано "custom", необходимо указать свойство rotation.
   * @param {Number[]} cfg.position Смещение оси в формате [x, y, z] относительно начала координат.
   * @param {Numner[]} cfg.rotation Поворот относительно вектора [0, 1, 0] в формате [x, y, z]. Переопределяется значениями "X", "Y", "Z" в свойстве coordinateAxis.
   * @param {Number[]} cfg.scale Масштабирование оси в формате [x, y, z].
   */
  constructor(owner, cfg) {
    this.#owner = owner

    this.#axisNode = null
    this.#axisLabel = null
    this.#axisSegment = null

    this.#decimalPlaces = cfg.decimalPlaces
    this.#units = cfg.units
    this.#length = cfg.length // TODO TODO TODO
    this.#color = cfg.color

    this.#coordinateAxis = cfg.coordinateAxis ?? "Y"

    this.#position = cfg.position ?? [0, 0, 0]
    this.#rotation = cfg.rotation ?? null
    this.#scale = cfg.scale ?? [1, 1, 1]

    this.#quaternion = [0, 0, 0, 1]
    this.#matrix = math.identityMat4()

    this.#createAxis()
  }

  get axisNode() { return this.#axisNode }
  get label() { return this.#axisLabel }

  /**
   * @private Создать компоненты оси.
   */
  #createAxis() {
    const NO_STATE_INHERIT = false
    const origin = this.#owner.origin

    if (this.#coordinateAxis == "X") { this.#rotation = [0, 0, 90] }
    else if (this.#coordinateAxis == "Y") { this.#rotation = [0, 0, 0] }
    else if (this.#coordinateAxis == "Z") { this.#rotation = [90, 0, 180] }
    else if (this.#coordinateAxis == "custom") { this.#rotation ?? console.error("Custom coordinate axis was chosen but axisRotation property is undefined") }
    else { this.#rotation = [0, 0, 0] }

    this.#axisNode = new XeokitNode(this.#owner, {
      isNode: true,
      selected: true,
      receivesShadow: false,
      castsShadow: false,
      clippable: false,
      collidable: false,
      pickable: false,
      visible: true,
      origin: origin
    })

    this.#axisLabel = new MeasurementLabel(this.#axisNode, {
      length: this.#length,
      units: this.#units,
      decimalPlaces: this.#decimalPlaces,
      color: this.#color,
      visible: true
    })
    
    this.#axisSegment = new MeasurementSegment(this.#axisNode, {
      isSimpleLine: true,
      visible: true,
      color: this.#color
    })

    this.#owner.addChild(this.#axisNode, NO_STATE_INHERIT)

    math.eulerToQuaternion(this.#rotation, "XYZ", this.#quaternion)
    this.setTransform({})
  }

  /**
   * @public Установить матрицу преобразования через отдельные компоненты матрицы.
   * 
   * @param {Object} transform Параметры трансформации
   * @param {Number[]} transform.position Новая позиция в формате [x, y, z]
   * @param {Number[]} transform.quaternion Новый поворот в формате [i, j, k, w]
   * @param {Number[]} transform.scale Новый масштаб в формате [x, y, z]
   */
  setTransform(transform) {
    this.#position = transform.position ?? this.#position
    this.#quaternion = transform.quaternion ?? this.#quaternion
    this.#scale = transform.scale ?? this.#scale

    this.setMatrix(math.composeMat4(this.#position, this.#quaternion, this.#scale))
  }

  /**
   * @public Установить матрицу преобразования.
   * 
   * @param {Number[]} [mat = null] Матрица преобразования вида Float64Array(16)
   */
  setMatrix(mat = null) {
    this.#matrix = mat ?? math.identityMat4()
    this.#axisNode.matrix = this.#matrix
  }

  /**
   * @public Установить видимость оси.
   */
  setVisible(value) {
    this.#axisNode.visible = value
  }

  /**
   * @public Установить значение величины измерения.
   * 
   * @param {Number} value 
   */
  setLength(value) {
    this.#length = value
    this.#axisLabel.setLength(value)
  }

  /**
   * @public Уничтожить эту ось.
   */
  destroy() {
    this.#axisNode.destroy()
    this.#axisNode = null
  }
}