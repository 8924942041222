import { api } from '@/api'

/**
 * МОДУЛЬ В РАЗРАБОТКЕ
 * ...mapGetters("projectPermissions", [ 'hasTaskStatusPriority']),
 */
export default {
    namespaced: true,

    state: {
        meOwner: false,
        roleTypes: [],
    },

    getters: {
        meOwner: (state) => state.meOwner,
        hasRoleType: ( state ) => name => {
          if (state.meOwner)  return true

          let projectPermission = window.settings.projectPermission

          let project = projectPermission ? projectPermission.project : false
          let model = projectPermission ? projectPermission.model : false
          let team = projectPermission ? projectPermission.team : false
          let collision = projectPermission ? projectPermission.collision : false
          let task = projectPermission ? projectPermission.task : false
          let smeta = projectPermission ? projectPermission.smeta : false
          let maintaince = projectPermission ? projectPermission.maintaince : false

          if (name.startsWith("PROJECT") && !project) return true
          if (name.startsWith("MODEL") && !model) return true
          if (name.startsWith("TEAM") && !team) return true
          if (name.startsWith("COLLISION") && !collision) return true
          if (name.startsWith("TASK") && !task) return true
          if (name.startsWith("SMETA_CLASSIFICATOR") && !smeta) return true
          if (name.startsWith("MAINTENANCE") && !maintaince) return true


          return state.roleTypes.find(t => t && t.name == name) ? true : false
        },

        hasRoleTypeGroup: ( state ) => groupName => {
          if (state.meOwner)  return true
          return state.roleTypes.find(t => t && t.group == groupName) ? true : false
        },
/**
 * TASK_APPOINTMENT(30001, "Назначение исполнителей", "TASK", "PRO"), 
 * TASK_CREATE(30002, "Создание", "TASK", "PRO"),
 * TASK_CLOSED(30003, "Закрытие", "TASK", "PRO"),
 * TASK_DELETE(30004, "Удаление", "TASK", "PRO"),
 * TASK_STATUS_PRIORITY(30005, "Управление статусом/приоритетом", "TASK", "PRO"),
 * TASK_DATES(30006, "Управление датами", "TASK", "PRO"),
 * TASK_RESPONDER(30007, "Назначение ответственных", "TASK", "PRO"),
 * TASK_CREATE_COMMENT_ATTACH(30008, "Добавление комментариев/видов", "TASK", "PRO"),
 * TASK_DOWNLOAD_FILE(30009, "Загрузка файлов", "TASK", "PRO"),
 * TASK_DELETE_COMMENT_ATTACH(30010, "Удаление комментариев/видов", "TASK", "PRO"),
 * TASK_EXPORT_IMPORT_BCF(30011, "Экспорт и импорт bcf", "TASK", "PRO"),
 * TASK_VOLUMES(30012, "Управление объемами", "TASK", "PRO"),
 */
        hasTaskAppointment(state, getters) {
            return getters.hasRoleType('TASK_APPOINTMENT')
        },
    
        hasTaskCreate(state, getters) {
          return getters.hasRoleType('TASK_CREATE')
        },
     
        hasTaskClosed(state, getters) {
            return getters.hasRoleType('TASK_CLOSED')
        },
        
        hasTaskDelete(state, getters) {
          return getters.hasRoleType('TASK_DELETE')
        },
    
        hasTaskStatusPriority(state, getters) {
            return getters.hasRoleType('TASK_STATUS_PRIORITY')
        },
    
        hasTaskDates(state, getters) {
          return getters.hasRoleType('TASK_DATES')
        },
    
        hasTaskResponder(state, getters) {
          return getters.hasRoleType('TASK_RESPONDER')
        },
    
        hasTaskCreateCommentAttach(state, getters) {
          return getters.hasRoleType('TASK_CREATE_COMMENT_ATTACH')
        },
    
        hasTaskDownloadFile(state, getters) {
          return getters.hasRoleType('TASK_DOWNLOAD_FILE')
        },
    
        hasTaskDeleteCommentAttach(state, getters) {
          return getters.hasRoleType('TASK_DELETE_COMMENT_ATTACH')
        },
    
        hasTaskExportImportBCF(state, getters) {
          return getters.hasRoleType('TASK_EXPORT_IMPORT_BCF')
        },

        hasTaskVolumes(state, getters) {
          return getters.hasRoleType('TASK_VOLUMES')
        },

/**
* MAINTENANCE_EQUIPMENT(70002, "Управление оборудованием", "MAINTENANCE", "PRO"),
* MAINTENANCE_BINDING_ELEMENTS(70003, "Привязка элементов", "MAINTENANCE", "PRO"),
* MAINTENANCE_CHECKLIST_TO(70004, "Редактирование чек-листа графика ТО", "MAINTENANCE", "PRO"),
* MAINTENANCE_DATE_TO(70005, "Редактирование плановой даты ТО", "MAINTENANCE", "PRO"),
* MAINTENANCE_SETTING_CHART_TO(70006, "Управление настройками графика ТО", "MAINTENANCE", "PRO"),
* MAINTENANCE_MNEMOSCHEMES(70007, "Управление мнемосхемами", "MAINTENANCE", "PRO"),
* MAINTENANCE_IMPORT_EXPORT_MNEMOSCHEMES(70008, "Импорт и экспорт мнемосхем", "MAINTENANCE", "PRO"),
* MAINTENANCE_CALCULATE_TO(70009, "Рассчитать график ТО", "MAINTENANCE", "PRO"),
 * */ 

        hasMaintainceEquipment(state, getters) {
          return getters.hasRoleType('MAINTENANCE_EQUIPMENT')
        },

        hasMaintainceBindingElements(state, getters) {
          return getters.hasRoleType('MAINTENANCE_BINDING_ELEMENTS')
        },

        hasMaintainceChecklistTO(state, getters) {
          let data = getters.hasRoleType('MAINTENANCE_CHECKLIST_TO')
          return data
        },

        hasMaintainceDateTO(state, getters) {
          return getters.hasRoleType('MAINTENANCE_DATE_TO')
        },

        hasMaintainceSettingChartTO(state, getters) {
          return getters.hasRoleType('MAINTENANCE_SETTING_CHART_TO')
        },

        hasMaintainceMnemoschemes(state, getters) {
          return getters.hasRoleType('MAINTENANCE_MNEMOSCHEMES')
        },

        hasMaintainceImportExportMnemoschemes(state, getters) {
          return getters.hasRoleType('MAINTENANCE_IMPORT_EXPORT_MNEMOSCHEMES')
        },

        hasMaintainceCalculateTO(state, getters) {
          return getters.hasRoleType('MAINTENANCE_CALCULATE_TO')
        },

        hasMaintaince(state, getters) {
          return getters.hasRoleTypeGroup('MAINTENANCE')
        },
        
/**
* SMETA_CLASSIFICATOR_VIEW(60002, "Просмотр классификатора", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR(60001, "Добавление классификаторов", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_DELETE(60101, "Удаление классификаторов", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_CREATE_RULE(60102, "Создание правил", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_DELETE_RULE(60103, "Удаление правил", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_EDIT_PRICE(60104, "Редактирование расценки(класса)", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_DELETE_PRICE(60105, "Удаление расценки(класса)", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_DOWNLOAD_REPORT(60106, "Скачивание отчетов", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_IMPORT_EXPORT(60107, "Импорт/экспорт классификаторов", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_SHARE(60108, "Шарить на организацию", "SMETA", "LITE"),
* SMETA_CLASSIFICATOR_VIEW_UNIVERSAL_ATTR(60109, "Просмотр универсальных атрибутов", "SMETA", "LITE"),
*	SMETA_CLASSIFICATOR_CONTROL_UNIVERSAL_ATTR(60110, "Управление универсальными атрибутами", "SMETA", "LITE"),
*	SMETA_WORM(60003, "Управление ВОРМ", "SMETA", "LITE"),
* SMETA_WORM_VIEW(60004, "Просмотр ВОРМ", "SMETA", "LITE"),
 * */ 

        hasClassificatorView(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_VIEW')
        },

        hasClassificatorCreate(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR')
        },

        hasClassificatorDelete(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_DELETE')
        },

        hasClassificatorCreateRule(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_CREATE_RULE')
        },

        hasClassificatorDeleteRule(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_DELETE_RULE')
        },

        hasClassificatorEditPrice(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_EDIT_PRICE')
        },

        hasClassificatorDeletePrice(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_DELETE_PRICE')
        },

        hasClassificatorDownloadReport(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_DOWNLOAD_REPORT')
        },

        hasClassificatorImportExport(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_IMPORT_EXPORT')
        },

        hasClassificatorShare(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_SHARE')
        },

        hasClassificatorViewUniversalAttr(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_VIEW_UNIVERSAL_ATTR')
        },

        hasClassificatorControlUniversalAttr(state, getters) {
          return getters.hasRoleType('SMETA_CLASSIFICATOR_CONTROL_UNIVERSAL_ATTR')
        },

        hasWorm(state, getters) {
          return getters.hasRoleType('SMETA_WORM')
        },
        
        hasWormView(state, getters) {
          return getters.hasRoleType('SMETA_WORM_VIEW')
        },

        hasClassificator(state, getters) {
          return getters.hasRoleTypeGroup('SMETA')
        },

        // TEAM_ADD(100021, "Добавление", "TEAM", "LITE"),
        // TEAM_EDIT(100022, "Изменение", "TEAM", "LITE"),
        // TEAM_DELETE(100023, "Удаление", "TEAM", "LITE"),

        hasTeamAdd(state, getters) {
          return getters.hasRoleType('TEAM_ADD')
        },

        hasTeamEdit(state, getters) {
          return getters.hasRoleType('TEAM_EDIT')
        },

        hasTeamDelete(state, getters) {
          return getters.hasModelPermission('TEAM_DELETE')
        },

        // MODEL_DOWNLOAD_REVISION(10001, "Скачивание ревизии", "MODEL", "LITE"),
        // MODEL_LOAD_REVISION(1000101, "Загрузка ревизии", "MODEL", "LITE"),
        // MODEL_DELETE_REVISION(1000102, "Удаление ревизий", "MODEL", "LITE"),
        // MODEL_CHECK(10003, "Управление проверками", "MODEL", "LITE"),
        // MODEL_QUALITY(10004, "Управление качеством", "MODEL", "PRO"),
        // MODEL_STAGE(10005, "Управление стадиями", "MODEL", "LITE"),
        // MODEL_BLOCKING(10006, "Блокировка смещений", "MODEL", "LITE"),
        // MODEL_CONTROL_OFFSET(1000601, "Управление смещением", "MODEL", "LITE"),
        // MODEL_COMPARE_REVISION(10009, "Сравнение ревизий", "MODEL", "LITE"),
        // MODEL_EDIT_ANNOTATION(10011, "Редактирование аннотации", "MODEL", "LITE"),
        // MODEL_DOWNLOAD_ATTACHMENT(10021, "Загрузка вложений", "MODEL", "LITE"),
        // MODEL_LOAD_ATTACHMENT(10022, "Скачивание вложений", "MODEL", "LITE"),
        // MODEL_DELETE_ATTACHMENT(10023, "Удаление вложений", "MODEL", "LITE"),

        hasModelDownloadRevision(state, getters) {
          return getters.hasModelPermission('MODEL_DOWNLOAD_REVISION')
        },
        
        hasModelLoadRevision(state, getters) {
          return getters.hasModelPermission('MODEL_LOAD_REVISION')
        },

        hasModelDeleteRevision(state, getters) {
          return getters.hasModelPermission('MODEL_DELETE_REVISION')
        },

        hasModelCheck(state, getters) {
          return getters.hasRoleType('MODEL_CHECK')
        },
        
        hasModelQuality(state, getters) {
          return getters.hasRoleType('MODEL_QUALITY')
        },
        
        hasModelStage(state, getters) {
          return getters.hasRoleType('MODEL_STAGE')
        },

        hasModelBlocking(state, getters) {
          return getters.hasModelPermission('MODEL_BLOCKING')
        },

        hasModelControlOffset(state, getters) {
          return getters.hasModelPermission('MODEL_CONTROL_OFFSET')
        },

        hasModelCompareRevision(state, getters) {
          return getters.hasModelPermission('MODEL_COMPARE_REVISION')
        },

        hasModelEditAnnotation(state, getters) {
          return getters.hasModelPermission('MODEL_EDIT_ANNOTATION')
        },

        hasModelDownloadAttachment(state, getters) {
          return getters.hasModelPermission('MODEL_DOWNLOAD_ATTACHMENT')
        },

        hasModelLoadAttachment(state, getters) {
          return getters.hasModelPermission('MODEL_LOAD_ATTACHMENT')
        },

        hasModelDeleteAttachment(state, getters) {
          return getters.hasModelPermission('MODEL_DELETE_ATTACHMENT')
        },

        hasModelPermission: (state, getters, rootState, rootGetters) => (permission) => {
          if(rootState.project.selectedModel != null){
            if (state.meOwner)  return true
            const meOfSelectedModel = rootGetters['project/meOfSelectedModel']
            return meOfSelectedModel?.roles?.find(role => role.controlFn?.find(control => control?.functionType?.name === permission)) || 
                   meOfSelectedModel?.orgRoles?.find(role => role.controlFn?.find(control => control?.functionType?.name === permission))
          }
        },

        //	PROJECT
        // PROJECT_STRUCT(50001, "Управление структурой", "PROJECT", "LITE"),
        // PROJECT_WORKSPACES(50003, "Рабочие пространства", "PROJECT", "LITE"),
        // PROJECT_DELETE_MODEL(50004, "Удаление моделей", "PROJECT", "LITE"),
        // PROJECT_CONTROL_ARCHIVE(50005, "Управление архивом", "PROJECT", "LITE"),
        // PROJECT_ADD_ATTRIBUTION(50006, "Добавление атрибутов (в дереве элементов)", "PROJECT", "LITE"),
        // PROJECT_RECONSTRUCTION(50007, "Управление Реконструкциями", "PROJECT", "LITE"),
        // PROJECT_SEND_TO_ARCHIVE(50008, "Отправка в архив", "PROJECT", "LITE"),
        // PROJECT_GROUPS_SHARED_ACCESS(50009, "Группировки (общий доступ)", "PROJECT", "LITE"),

        hasProjectStruct(state, getters) {
          return getters.hasRoleType('PROJECT_STRUCT')
        },

        hasProjectWorkspace(state, getters) {
          return getters.hasRoleType('PROJECT_WORKSPACES')
        },

        hasProjectDeleteModel(state, getters) {
          return getters.hasRoleType('PROJECT_DELETE_MODEL')
        },

        hasProjectControlArchive(state, getters) {
          return getters.hasRoleType('PROJECT_CONTROL_ARCHIVE')
        },

        hasProjectAddAttribution(state, getters) {
          return getters.hasRoleType('PROJECT_ADD_ATTRIBUTION')
        },

        hasProjectReconstruction(state, getters) {
          return getters.hasRoleType('PROJECT_RECONSTRUCTION')
        },
        
        hasProjectSendToArchive(state, getters) {
          return getters.hasRoleType('PROJECT_SEND_TO_ARCHIVE')
        },
        
        hasProjectGroupsSharedAccess(state, getters) {
          return getters.hasRoleType('PROJECT_GROUPS_SHARED_ACCESS')
        },

        // COLLISION_CALC(20001, "Расчет", "COLLISION", "PRO"),
        // COLLISION_REASSIGNMENT(20002, "Переназначение", "COLLISION", "PRO"),
        // COLLISION_RESOLUTION(20003, "Разрешение", "COLLISION", "PRO"), //статус разрешение коллизии(допустима)
        // COLLISION_CREATE_RULE(20004, "Создание правила", "COLLISION", "PRO"), 
        // COLLISION_STATUS(20005, "Управление статусом", "COLLISION", "PRO"), 
        // COLLISION_VIEW(20006, "Просмотр коллизий", "COLLISION", "PRO"),
        // COLLISION_ADD_TO_TASK(20007, "Прикрепление коллизии к задаче", "COLLISION", "PRO"), 
        // COLLISION_CONTROL_GROUP_VIEW(20008, "Управление группами и видами", "COLLISION", "PRO"), 
        // COLLISION_CREATE_REPORT(20009, "формирование отчетов", "COLLISION", "PRO"), 
        // COLLISION_NOTES(200010, "Управление заметками", "COLLISION", "PRO"),
        // COLLISOIN_EXPORT_IMPORT_RULE(200011, "Экспорт/Импорт правил", "COLLISION", "PRO"),
        
        hasCollisionCalc(state, getters) {
          return getters.hasRoleType('COLLISION_CALC')
        },

        // пока не используется
        hasCollisionReassignment(state, getters) {
          return getters.hasRoleType('COLLISION_REASSIGNMENT')
        },

        hasCollisionResolution(state, getters) {
          return getters.hasRoleType('COLLISION_RESOLUTION')
        },

        hasCollisionCreateRule(state, getters) {
          return getters.hasRoleType('COLLISION_CREATE_RULE')
        },

        hasCollisionStatus(state, getters) {
          return getters.hasRoleType('COLLISION_STATUS')
        },

        hasCollisionView(state, getters) {
          return getters.hasRoleType('COLLISION_VIEW')
        },

        hasCollisionAddToTask(state, getters) {
          return getters.hasRoleType('COLLISION_ADD_TO_TASK')
        },

        hasCollisionControlGroupView(state, getters) {
          return getters.hasRoleType('COLLISION_CONTROL_GROUP_VIEW')
        },

        hasCollisionCreateReport(state, getters) {
          return getters.hasRoleType('COLLISION_CREATE_REPORT')
        },

        hasCollisionNotes(state, getters) {
          return getters.hasRoleType('COLLISION_NOTES')
        },

        hasCollisionEmportImport(state, getters) {
          return getters.hasRoleType('COLLISOIN_EXPORT_IMPORT_RULE')
        },

        hasCollision(state, getters) {
          return getters.hasRoleTypeGroup('COLLISION')
        },

    },

    mutations: {
        setProjectRoleTypes: (state, roleTypes) => { state.roleTypes = roleTypes },

        setMeOwner: (state, meOwner) => { state.meOwner = meOwner}
    },

    actions: {
        loadUserRoleTypesByProject ({ commit }, projectUuid) {
            return api.roles.roleTypesByProject(projectUuid).then(data => { 
              commit('setProjectRoleTypes', data.filter(el => el != null))
            })
        },

        addMeOwner ({commit} , meOwner) {
          commit('setMeOwner', meOwner)
        },
    }

}