import { Viewer } from '@xeokit/xeokit-sdk'

import { MousePanRotateDollyHandler } from "@xeokit/xeokit-sdk";
import { KeyboardAxisViewHandler } from "@xeokit/xeokit-sdk";
import { MousePickHandler } from "@xeokit/xeokit-sdk";
import { KeyboardPanRotateDollyHandler } from "@xeokit/xeokit-sdk";
import { MouseMiscHandler } from "@xeokit/xeokit-sdk";
import { TouchPanRotateAndDollyHandler } from "@xeokit/xeokit-sdk";
import { TouchPickHandler } from "@xeokit/xeokit-sdk";
import html2canvas from 'html2canvas';

const defaultConfig = {
  canvasId: "myCanvas",
  transparent: true,
  saoEnabled: false,
  pbrEnabled: true,
  spinnerElementId: 'spinner_placeholder'
}

export class XeokitViewer extends Viewer {

  constructor(cfg = {}) {
    super(Object.assign(defaultConfig, cfg))

    this.camera.eye = [35.94, 36.2, 21.08]
    this.camera.look = [3.55, 7.34, 5.88]
    this.camera.up = [-0.25, -0.22, 0.94]

    // Основной вариант
    this.camera.worldAxis = [
      1, 0, 0, // Right
      0, 0, 1, // Up
      0, -1, 0  // Forward
    ]

    // this.viewer.camera.worldAxis = [
    //   0, 1, 0, // Right
    //   0, 0, 1, // Up
    //   1, 0, 0  // Forward
    // ]

    // this.viewer.camera.worldAxis = [
    //   1, 0, 0, // Right
    //   0, 1, 0, // Up
    //   0, 0, -1  // Forward
    // ]

    this.corrections = {
      eye: [0, 0, 0],
      look: [0, 0, 0],
      up: [0, 0, 1],
      correction: [0, 0, 0]
    }
  }

  destroyMousePanRotateDollyHandler() {
    let cameraControl = this.cameraControl
    cameraControl._destroyHandlers()
    cameraControl._handlers = [
      new MouseMiscHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new TouchPanRotateAndDollyHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new KeyboardAxisViewHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new MousePickHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new TouchPickHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new KeyboardPanRotateDollyHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates)
    ]
  }


  restoreDefaultCameraControlHandlers() {
    let cameraControl = this.cameraControl
    cameraControl.navMode = "orbit"
    cameraControl._handlers = [
      new MouseMiscHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new TouchPanRotateAndDollyHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new MousePanRotateDollyHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new KeyboardAxisViewHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new MousePickHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new TouchPickHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates),
      new KeyboardPanRotateDollyHandler(cameraControl.scene, cameraControl._controllers, cameraControl._configs, cameraControl._states, cameraControl._updates)
    ]
  }

  get pivotController() {
    return this.cameraControl._controllers.pivotController
  }

  get pickController() {
    return this.cameraControl._controllers.pickController
  }

  get panController() {
    return this.cameraControl._controllers.panController
  }

  async getSnapshotWithPlugins(cfg = {}) {
    const snapshot = this.getSnapshot(cfg)
    const snapshotImg = new Image()
    snapshotImg.src = snapshot

    await new Promise(resolve => snapshotImg.onload = resolve)

    const snapshotCanvas = document.createElement('canvas')
    snapshotCanvas.width = this.scene.canvas.canvas.width
    snapshotCanvas.height = this.scene.canvas.canvas.height

    const ctx = snapshotCanvas.getContext('2d')
    const plugins = this._plugins
    const hash = []

    ctx.drawImage(snapshotImg, 0, 0)
    await Promise.all(plugins.map(async plugin => {

      if (!plugin.getContainerElement) return

      const containerElement = plugin.getContainerElement()

      if(!hash.includes(containerElement)) {
        hash.push(containerElement)
        
        if (!containerElement) return
        
        await html2canvas(containerElement, {
          canvas: snapshotCanvas,
          backgroundColor: null,
          scale: snapshotCanvas.width / containerElement.clientWidth
        })
      }
    }))
    return snapshotCanvas.toDataURL()
  }

  /**
   * Gets a snapshot of this Viewer's {@link Scene} as a Base64-encoded image.
   *
   * #### Usage:
   *
   * ````javascript
   * const imageData = viewer.getSnapshot({
   *    width: 500,
   *    height: 500,
   *    format: "png"
   * });
   * ````
   * @param {*} [params] Capture options.
   * @param {Number} [params.width] Desired width of result in pixels - defaults to width of canvas.
   * @param {Number} [params.height] Desired height of result in pixels - defaults to height of canvas.
   * @param {String} [params.format="jpeg"] Desired format; "jpeg", "png" or "bmp".
   * @param {Boolean} [params.includeGizmos=false] When true, will include gizmos like {@link SectionPlane} in the snapshot.
   * @returns {String} String-encoded image data URI.
  */
  getSnapshot(params = {}) {

    const needFinishSnapshot = (!this._snapshotBegun);
    const resize = (params.width !== undefined && params.height !== undefined);
    const canvas = this.scene.canvas.canvas;
    const saveWidth = canvas.clientWidth;
    const saveHeight = canvas.clientHeight;
    const width = params.width ? Math.floor(params.width) : canvas.width;
    const height = params.height ? Math.floor(params.height) : canvas.height;

    if (resize) {
      canvas.width = width;
      canvas.height = height;
    }

    if (!this._snapshotBegun) {
      this.beginSnapshot();
    }

    if (!params.includeGizmos) {
      this.sendToPlugins("snapshotStarting"); // Tells plugins to hide things that shouldn't be in snapshot
    }

    const captured = {};
    for (let i = 0, len = this._plugins.length; i < len; i++) {
      const plugin = this._plugins[i];
      if (plugin.getContainerElement) {
        const container = plugin.getContainerElement();
        if (container !== document.body) {
          if (!captured[container.id]) {
            captured[container.id] = true;
          }
        }
      }
    }

    this.scene._renderer.renderSnapshot();

    const imageDataURI = this.scene._renderer.readSnapshot(params);

    if (resize) {
      canvas.width = saveWidth;
      canvas.height = saveHeight;

      this.scene.glRedraw();
    }

    if (!params.includeGizmos) {
      this.sendToPlugins("snapshotFinished");
    }

    if (needFinishSnapshot) {
      this.endSnapshot();
    }

    return imageDataURI;
  }
}