import { $_app_server } from '@/_helpers'

export const reportService = {
    downloadCollisions
}

function downloadCollisions (fileName) {
    return $_app_server.get(`/report/download/${fileName}`, { responseType: 'blob' }).then(response => response.data)
}

