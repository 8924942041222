import { useRegimeMeasurementStore } from '@/pinia'
import { useRulerStore } from '@/pinia/viewer/ruler.store'
import { VTF } from '@/plugins/viewerTools/VTF'

/*eslint-disable no-dupe-class-members*/
export class RegimeMeasurement {
  
  static defaultMode = "Ruler"

  static get #_regimeMeasurementStore() {
    return useRegimeMeasurementStore()
  }

  // TODO: Вынести в обычный стор
  static get #_rulerStore() {
    return useRulerStore()
  }

  /** Состояние ВКЛ/ВЫКЛ режимной линейки */
  static get isRegimeMeasurementActive() {
    return this.#_regimeMeasurementStore.regimeMeasurementActive
  }

  /** Текущий режим режимной линейки */
  static get RegimeMeasurementModeClass() {
    return VTF.RegimeMeasurement.modeClasses[this.#_regimeMeasurementStore.regimeMeasurementMode]
  }

  /** Название активного режима режимной линейки */
  static get rulerMode() {
    return this.#_rulerStore.mode
  }

  static get measurementsDirty() {
    return VTF.RegimeMeasurement.measurementsDirty
  }

  static get modeClasses() {
    return VTF.RegimeMeasurement.modeClasses
  }

  static setRegimeMeasurementMode(mode) {
    const modeClasses = VTF.RegimeMeasurement.modeClasses
    const modeClassName = Object.keys(modeClasses).find(name => modeClasses[name] === mode);

    VTF.RegimeMeasurement.deactivate(false)
    this.#_regimeMeasurementStore.setRegimeMeasurementMode(modeClassName)
    VTF.RegimeMeasurement.activate()
  }

  /** Активация режима линейки.
   * @param {Object} cfg Настройки визуализации модели.
   * @param {String} [cfg.mode] Режим модели.
  */
  static activate(cfg) {
    const mode = cfg?.mode || this.RegimeMeasurementModeClass
    if (!this.isRegimeMeasurementActive) {
      this.#_regimeMeasurementStore.setRegimeMeasurementActive(true)
      this.setRegimeMeasurementMode(mode)
    }
  }
  
  /**
   * Выключение режима линейки.
   */
  static deactivate() {
    if (this.isRegimeMeasurementActive) {
      this.#_regimeMeasurementStore.setRegimeMeasurementActive(false)
      VTF.RegimeMeasurement.deactivate()
    }
  }
}