import { TaskReportVolume } from "./TaskReportVolume"
import { EnumType } from "@models/EnumType"
export class TaskReport {

  constructor( { uuid, estimation, unitType, volumes } = {} ) {
    this.uuid = uuid
    this.estimation = numberToFixed(estimation)
    this.unitType = new MeasureUnitType(unitType)
    this.volumes = volumes?.map(volume => new TaskReportVolume(volume))
  }
}

class MeasureUnitType extends EnumType {
  constructor(obj) {
    super(obj);
  }
}

function numberToFixed ( number, maxFixed = 4 ) {
  if (number == undefined) return number
  let cntFixed = 0
  if (number.toString().indexOf(".") != -1 && maxFixed > 0) {
    cntFixed = number.toString().split(".")[1].length 
    cntFixed = cntFixed < maxFixed ? cntFixed : maxFixed     
  }
  return cntFixed > 0 ? Number(number.toFixed(cntFixed)) : Number(number) 
}