import '@/components/drawings/cropping/anchorPoints/anchorPoint.scss'
import '@/components/drawings/cropping/anchorPoints/anchorPointVerticalLine.scss'
import '@/components/drawings/cropping/anchorPoints/anchorPointGorizontalLine.scss'
import '@/components/drawings/cropping/anchorPoints/anchorPointText.scss'
import { useDrawingCroppingStore, useDrawingPageStore, useDrawingsStore } from '@/pinia'

/*eslint-disable no-dupe-class-members*/
export class AnchorPointsController {
  constructor(cfg = {}) {
    this.layout = cfg.layout
    this.layoutSizes = this.layout.getBoundingClientRect()

    this.TOP_MARGIN = this.layoutSizes.top
    this.LEFT_MARGIN = this.layoutSizes.left

    this.anchorPoint1 = {
      element: null,
      mouseDownListener: null,
      pressed: false
    }

    this.anchorPoint2 = {
      element: null,
      mouseDownListener: null,
      pressed: false
    }

    this.croppingRectangleRelative = {}
    this.croppingRectangle = {}

    this.layoutMouseMoveListener = null
    this.layoutMouseUpListener = null
  }

  activate() {
    this.#activateLayoutListeners()
  }

  deactivate() {
    this.#deactivateLayoutListeners()
    useDrawingCroppingStore().firstPointSetted = false
    useDrawingCroppingStore().secondPointSetted = false
    useDrawingCroppingStore().isAnchorPointsAdded = false
  }

  #activateAnchorPointListeners(anchorPoint) {
    anchorPoint.element.addEventListener('mousedown', anchorPoint.mouseDownListener = () => {
      useDrawingCroppingStore().anchorPointGrabbing = true
      anchorPoint.pressed = true
    })
  }

  #activateLayoutListeners() {
    this.layout.addEventListener('mousedown', this.layoutMouseDownListener = () => {
      this.layoutSizes = this.layout.getBoundingClientRect()
      this.TOP_MARGIN = this.layoutSizes.top
      this.LEFT_MARGIN = this.layoutSizes.left
      
      this.croppingRectangleRelative = useDrawingCroppingStore().croppingRectangleRelative
      this.croppingRectangle = this.#computeCroppingRectangle(this.croppingRectangleRelative)
    })

    this.layout.addEventListener('mousemove', this.layoutMouseMoveListener = (e) => {
      const layoutHeight = this.layoutSizes.height
      const layoutWidth = this.layoutSizes.width

      const rectangleHeight = this.croppingRectangle.bottom - this.croppingRectangle.top
      const rectangleWidth = this.croppingRectangle.right - this.croppingRectangle.left

      if (this.anchorPoint1.pressed) {
        const left = e.clientX - this.LEFT_MARGIN
        const top = e.clientY - this.TOP_MARGIN

        const leftRelation = left / layoutWidth
        const topRelation = top / layoutHeight
        
        if (this.#checkRectangleBoundaries(leftRelation, topRelation)) {

          const leftByRectangle = left - this.croppingRectangle.left
          const topByRectangle = top - this.croppingRectangle.top

          const leftByRectRelation = leftByRectangle / rectangleWidth
          const topByRectRelation = topByRectangle / rectangleHeight

          this.anchorPoint1.element.style.left = `calc(${leftByRectRelation * 100}% - 10px)`
          this.anchorPoint1.element.style.top = `calc(${topByRectRelation * 100}% - 10px)`

          useDrawingCroppingStore().setFirstAnchorPoint([leftByRectRelation, topByRectRelation])

          if (useDrawingCroppingStore().drawingAnchorPoints.first[0]) {
            const first = useDrawingCroppingStore().drawingAnchorPoints.first
            const second = useDrawingCroppingStore().drawingAnchorPoints.second

            const deltaX = Math.abs(first[0] - second[0])
            const deltaY = Math.abs(first[1] - second[1])

            if (deltaX < 0.02 || deltaY < 0.02) useDrawingCroppingStore().incorrectAnchorPosition = true
            else useDrawingCroppingStore().incorrectAnchorPosition = false
          }
        }
      }
      if (this.anchorPoint2.pressed) {
        const left = e.clientX - this.LEFT_MARGIN
        const top = e.clientY - this.TOP_MARGIN

        const leftRelation = left / layoutWidth
        const topRelation = top / layoutHeight

        if (this.#checkRectangleBoundaries(leftRelation, topRelation)) {

          const leftByRectangle = left - this.croppingRectangle.left
          const topByRectangle = top - this.croppingRectangle.top

          const leftByRectRelation = leftByRectangle / rectangleWidth
          const topByRectRelation = topByRectangle / rectangleHeight

          this.anchorPoint2.element.style.left = `calc(${leftByRectRelation * 100}% - 10px)`
          this.anchorPoint2.element.style.top = `calc(${topByRectRelation * 100}% - 10px)`

          useDrawingCroppingStore().setSecondAnchorPoint([leftByRectRelation, topByRectRelation])

          const first = useDrawingCroppingStore().drawingAnchorPoints.first
          const second = useDrawingCroppingStore().drawingAnchorPoints.second

          const deltaX = Math.abs(first[0] - second[0])
          const deltaY = Math.abs(first[1] - second[1])

          if (deltaX < 0.02 || deltaY < 0.02) useDrawingCroppingStore().incorrectAnchorPosition = true
          else useDrawingCroppingStore().incorrectAnchorPosition = false
        }
      }
    })

    this.layout.addEventListener('mouseup', this.layoutMouseUpListener = (e) => {
      const rectangleHeight = this.croppingRectangle.bottom - this.croppingRectangle.top
      const rectangleWidth = this.croppingRectangle.right - this.croppingRectangle.left

      const layoutHeight = this.layoutSizes.height
      const layoutWidth = this.layoutSizes.width

      if (!this.anchorPoint1.element && !useDrawingsStore().drawingIsGrabbing) {
        const left = e.clientX - this.LEFT_MARGIN
        const top = e.clientY - this.TOP_MARGIN

        const leftRelation = left / layoutWidth
        const topRelation = top / layoutHeight

        if (this.#checkRectangleBoundaries(leftRelation, topRelation)) {
          useDrawingCroppingStore().firstPointSetted = true

          const leftByRectangle = left - this.croppingRectangle.left
          const topByRectangle = top - this.croppingRectangle.top

          const leftByRectRelation = leftByRectangle / rectangleWidth
          const topByRectRelation = topByRectangle / rectangleHeight

          this.anchorPoint1.element = document.getElementById('drawingAnchorPoint1')
          this.anchorPoint1.element.style.left = `calc(${leftByRectRelation * 100}% - 10px)`
          this.anchorPoint1.element.style.top = `calc(${topByRectRelation * 100}% - 10px)`

          useDrawingCroppingStore().setFirstAnchorPoint([leftByRectRelation, topByRectRelation])
          this.#activateAnchorPointListeners(this.anchorPoint1)
        }
      }
      else if (!this.anchorPoint2.element && !this.anchorPoint1.pressed && !useDrawingsStore().drawingIsGrabbing) {
        const left = e.clientX - this.LEFT_MARGIN
        const top = e.clientY - this.TOP_MARGIN

        const leftRelation = left / layoutWidth
        const topRelation = top / layoutHeight

        if (this.#checkRectangleBoundaries(leftRelation, topRelation)) {
          useDrawingCroppingStore().secondPointSetted = true

          const leftByRectangle = left - this.croppingRectangle.left
          const topByRectangle = top - this.croppingRectangle.top

          const leftByRectRelation = leftByRectangle / rectangleWidth
          const topByRectRelation = topByRectangle / rectangleHeight

          this.anchorPoint2.element = document.getElementById('drawingAnchorPoint2')
          this.anchorPoint2.element.style.left = `calc(${leftByRectRelation * 100}% - 10px)`
          this.anchorPoint2.element.style.top = `calc(${topByRectRelation * 100}% - 10px)`

          useDrawingCroppingStore().setSecondAnchorPoint([leftByRectRelation, topByRectRelation])
          
          const first = useDrawingCroppingStore().drawingAnchorPoints.first
          const second = useDrawingCroppingStore().drawingAnchorPoints.second

          const deltaX = Math.abs(first[0] - second[0])
          const deltaY = Math.abs(first[1] - second[1])

          if (deltaX < 0.02 || deltaY < 0.02) useDrawingCroppingStore().incorrectAnchorPosition = true
          else useDrawingCroppingStore().incorrectAnchorPosition = false

          this.#activateAnchorPointListeners(this.anchorPoint2)
        }

        useDrawingCroppingStore().setAnchorPointsAdded(true)
      }

      useDrawingCroppingStore().anchorPointGrabbing = false
      this.anchorPoint1.pressed = false
      this.anchorPoint2.pressed = false
    })
  }

  #deactivateLayoutListeners() {
    this.layout.removeEventListener('mousedown', this.layoutMouseDownListener)
    this.layout.removeEventListener('mousemove', this.layoutMouseMoveListener)
    this.layout.removeEventListener('mouseup', this.layoutMouseUpListener)
  }

  #computeCroppingRectangle(rectRel) {
    const currentHeight = useDrawingPageStore().imageHeight
    const currentWidth = useDrawingPageStore().imageWidth
    const topAbs = rectRel.top * currentHeight
    const leftAbs = rectRel.left * currentWidth
    const rightAbs = (rectRel.width + rectRel.left) * currentWidth
    const bottomAbs = (rectRel.height + rectRel.top) * currentHeight

    return {
      left: leftAbs,
      top: topAbs,
      right: rightAbs,
      bottom: bottomAbs
    }
  }

  #createAnchorPoint(id) {
    const anchorPoint = document.createElement('div')
    const anchorPointVerticalLine = document.createElement('div')
    const anchorPointGorizontalLine = document.createElement('div')
    const anchorPointText = document.createElement('div')
    anchorPoint.classList.add('anchor-point')
    anchorPointVerticalLine.classList.add('anchor-point__vertical-line')
    anchorPointGorizontalLine.classList.add('anchor-point__gorizontal-line')
    anchorPointText.classList.add('anchor-point__text')
    anchorPoint.appendChild(anchorPointVerticalLine)
    anchorPoint.appendChild(anchorPointGorizontalLine)
    anchorPoint.appendChild(anchorPointText)
    anchorPoint.setAttribute('id', id)
    this.layout.appendChild(anchorPoint)
    
    return anchorPoint
  }

  #checkRectangleBoundaries(left, top) {
    const rectLeft = this.croppingRectangleRelative.left
    const rectTop = this.croppingRectangleRelative.top
    const rectRight = rectLeft + this.croppingRectangleRelative.width
    const rectBottom = rectTop + this.croppingRectangleRelative.height
  
    return ( left > rectLeft && top > rectTop && left < rectRight && top < rectBottom )
  }
}