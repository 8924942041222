export default {
  namespaced: true,

  state: {
    copiedElementAttribute: null,

    copiedFindRuleTitle: null,
    copiedFindRule: null,
    
    copiedUnitExpressionTitle: null,
    copiedUnitExpression: null,

    copiedTitleAttributeTitle: null,
    copiedTitleAttribute: null,

    copiedFindRuleConditions:[],
    copiedUnitExpressionConditions:[],
    copiedTitleAttributeConditions:[],
    countCopiedElementAttribute: 0,
  },

  getters: {
    
  },

  mutations: {
    setCopiedElementAttribute: (state, attr) => {
      state.countCopiedElementAttribute++
      state.copiedElementAttribute = attr
    },

    setCopiedFindRule: (state, findRule) => {
      let cp = findRule == null ? null : cloneLogicGroup(findRule)

      state.copiedFindRuleTitle = cp == null ? null : makeLogicGroupText(cp, "", "", null)
      state.copiedFindRule = cp
    },

    setCopiedUnitExpression: (state, unitExpression) => {
      let cp = unitExpression == null ? null : cloneLogicGroup(unitExpression)

      state.copiedUnitExpressionTitle = cp == null ? null : makeCalcLogicGroupText(cp, "", "", null)
      state.copiedUnitExpression = cp
    },

    setCopiedTitleAttribute: (state, titleAttribute) => {
      let cp = titleAttribute == null ? null : cloneLogicGroup(titleAttribute)

      state.copiedTitleAttributeTitle = cp == null ? null : makeCalcLogicGroupText(cp, "", "", null)
      state.copiedTitleAttribute = cp
    },

    setCopiedFindRuleConditions: (state, findRuleConditions) => {
      if (!findRuleConditions) state.copiedFindRuleConditions = []
      else state.copiedFindRuleConditions = findRuleConditions.map(e => cloneCondition(e))
    },

    addFindRuleConditionToCopy: (state, findRuleCondition) => {
      if(!state.copiedFindRuleConditions.find(copiedCondition => copiedCondition.old_uuid === findRuleCondition.uuid)) {
        state.copiedFindRuleConditions.push(cloneCondition(findRuleCondition))
      }
    },

    setCopiedUnitExpressionConditions: (state, unitExpressionConditions) => {
      if (!unitExpressionConditions) state.copiedUnitExpressionConditions = []
      else state.copiedUnitExpressionConditions = unitExpressionConditions.map(e => cloneCondition(e))
    },

    addUnitExpressionConditionToCopy: (state, unitExpressionCondition) => {
      if(!state.copiedUnitExpressionConditions.find(copiedCondition => copiedCondition.old_uuid === unitExpressionCondition.uuid)) {
        state.copiedUnitExpressionConditions.push(cloneCondition(unitExpressionCondition))
      }
    },

    setCopiedTitleAttributeConditions: (state, titleAttributeConditions) => {
      if (!titleAttributeConditions) state.copiedTitleAttributeConditions = []
      else state.copiedTitleAttributeConditions = titleAttributeConditions.map(e => cloneCondition(e))
    },

    addTitleAttributeConditionToCopy: (state, titleAttributeCondition) => {
      if(!state.copiedTitleAttributeConditions.find(copiedCondition => copiedCondition.old_uuid === titleAttributeCondition.uuid)) {
        state.copiedTitleAttributeConditions.push(cloneCondition(titleAttributeCondition))
      }
    },
  },
  
  actions: {

  }
}

function cloneLogicGroup(logicGroup){
  let lc = JSON.parse(JSON.stringify(logicGroup))
  dropUuid(lc)
  return lc
}

function cloneCondition(condition){
  let cc = JSON.parse(JSON.stringify(condition))
  dropUuid(cc)
  return cc
}

function dropUuid(item){
  item.old_uuid = item.uuid
  item.uuid = null
  if (item.children) item.children.forEach(e => dropUuid(e))
  if (item.condition) item.condition.forEach(e => dropUuid(e))
}

let lo = {
  'AND':'И',
  'OR':'ИЛИ',
  'NOT':'НЕ'
}

function makeLogicGroupText(logicGroup, start, end, prevOperatorType) {
  let text = ''
  let rows = []
  logicGroup.condition.forEach(cond => rows.push(cond))
  if (logicGroup.children) {
    logicGroup.children.forEach(lg => rows.push(lg))
  }
  rows.sort((a, b) => a.sortOrder - b.sortOrder)

  rows.forEach((row, index) => {
    if(row.logicOperatorType !== undefined) {
      if(rows.length==1) text += makeLogicGroupText(row, start+"(", end+")", prevOperatorType)
      else if(index==0) text += makeLogicGroupText(row, start+"(", ")", logicGroup.logicOperatorType)
      else if(index<rows.length-1) text += makeLogicGroupText(row, "(", ")", logicGroup.logicOperatorType)
      else text += makeLogicGroupText(row, "(", end+")", prevOperatorType)
    } else {
      
      if(index==0){
        text += ' '+start
        start=''
      }
      
      let op = row.operator.title.toLowerCase()
      let t = row.title.substring(row.title.indexOf(op)+op.length+1)
      t = t.replace(row.operator.title.toLowerCase(),replaceOperator(row.operator))
      text += t

      if(index==rows.length-1) {
        text += end
        end=''
      }

      if((index==0 || index<rows.length-1) && logicGroup.logicOperatorType!=null) {
        if (!logicGroup.logicOperatorType.title) logicGroup.logicOperatorType.title = lo[logicGroup.logicOperatorType.value]
        text += ' '+logicGroup.logicOperatorType.title.toLowerCase()
      } else if(prevOperatorType!=null && prevOperatorType.title) text +=  ' '+prevOperatorType.title.toLowerCase()
    }
  })

  return text
}

function replaceOperator(o) {
  switch(o.name){
    case 'EQ': return '='
    case 'NOTEQ': return '!='
    case 'GT': return '>'
    case 'LT': return '<'
    case 'GTEQ': return '>='
    case 'LTEQ': return '<='
    default: return o.title
  }
}

function makeCalcLogicGroupText(logicGroup, start, end, prevOperatorType) {
  let text = ''
  let rows = []
  logicGroup.condition.forEach(cond => rows.push(cond))
  if (logicGroup.children) {
    logicGroup.children.forEach(lg => rows.push(lg))
  }
  rows.sort((a, b) => a.sortOrder - b.sortOrder)

  
  rows.forEach((row, index) => {
    if(row.calcOperatorType !== undefined) {
      if(rows.length==1) text += makeCalcLogicGroupText(row, start+"(", end+")", prevOperatorType)
      else if(index==0) text += makeCalcLogicGroupText(row, start+"(", ")", logicGroup.calcOperatorType)
      else if(index<rows.length-1) text += makeCalcLogicGroupText(row, "(", ")", logicGroup.calcOperatorType)
      else text += makeCalcLogicGroupText(row, "(", end+")", prevOperatorType)
    } else {
      
      if(index==0){
        text += ' '+start
        start=''
      }

      text += row.title

      if(index==rows.length-1) {
          text += ' '+end
          end=''
      }

      if((index==0 || index<rows.length-1) && logicGroup.calcOperatorType!=null) text += ' '+logicGroup.calcOperatorType.title
      else if(prevOperatorType!=null && prevOperatorType.title) text +=  ' '+prevOperatorType.title
    }
  })

  return text
}