import { Mesh, EmphasisMaterial, PhongMaterial, ReadableGeometry } from "@xeokit/xeokit-sdk"
import { ThreeMediator } from "@/plugins/threeJs/ThreeMediator"
import { ShapeGeometry } from "@/plugins/threeJs/fork/src/geometries/shapeGeometry"
import { fonts } from "@/plugins/threeJs/plugins/fonts/fonts"

const DEFAULT_ROTATION = [0, 0, 90] // Параллельное положение label относительно segment.
const DEFAULT_POSITION = [-0.025, -0.18, 0.0] // Небольшой отступ label относительно segment, чтобы они не пересекались.
const DEFAULT_SCALE = 0.06 // Масштабирование label относительно segment такое, чтобы label был аккуратно вписан в длину и ширину segment.

/*eslint-disable no-dupe-class-members*/
export class MeasurementLabel {

  #owner;                                                         // class instance
  #decimalPlaces; #units; #length; #color; #visible; #billboard   // axis visual settings 
  #position; #rotation; #scale;                                   // transformation parameters
  #font;                                                          // font object and mesh cfgs

  /**
   * Отрисовать на сцене величину измерения.
   * 
   * @param {Component} owner Scene или Node. Создаваемая ось будет закреплена за этим владельцем, уничтожение владельца повлечет уничтожение и этого компонента. Наследует свойства трансформации.
   * @param {Object} cfg Конфиг.
   * @param {Number} cfg.length Величина измерения. Возможна установка через setLength().
   * @param {Number} cfg.decimalPlaces Отображаемое количество знаков после запятой.
   * @param {String} cfg.units Единица измерения. Допускаются значения "mm", "m", "сm", "km".
   * @param {Number[]} cfg.color Цвет текста в формате [r, g, b] от 0 до 1.
   * @param {Number[]} cfg.position Смещение оси в формате [x, y, z] относительно начала координат.
   * @param {Numner[]} cfg.rotation Поворот относительно вектора [0, 1, 0] в формате [x, y, z]. Переопределяется значениями "X", "Y", "Z" в свойстве coordinateAxis.
   * @param {Number[]} cfg.scale Масштабирование оси в формате [x, y, z].
   */
  constructor(owner, cfg = {}) {
    this.#owner = owner
    
    this.#length = cfg.length
    this.#decimalPlaces = cfg.decimalPlaces
    this.#units = cfg.units
    this.#color = cfg.color
    this.#visible = cfg.visible ?? true
    this.#billboard = cfg.billboard ?? 'none'
    this.#rotation = cfg.rotation ?? DEFAULT_ROTATION
    this.#position = cfg.position ?? DEFAULT_POSITION
    this.#scale = cfg.scale ?? DEFAULT_SCALE

    this.#font = ThreeMediator.fontLoader.parse(fonts.RobotoMediumRegular)

    this.#createLabel()
  }

  /**
   * @private Создать текст, отражающий величину измерения.
   * 
   * @param {Boolean} needPlaceholder Следует ли инициализировать с использованием текстового заполнителя.
   */
  #createLabel(needPlaceholder = false) {
    const STATE_INHERIT = true
    const node = this.#owner
    const origin = node.origin // TODO Исправить дрожание при billboard spherical
    
    const lengthCorrected = this.#correctLengthByUnits(this.#length, this.#units)
    const text = needPlaceholder ? "<>" + " " + this.#units : lengthCorrected.toFixed(this.#decimalPlaces) + " " + this.#units
    const scale = this.#billboard == 'none' ? this.#scale : 1

    const shapes = this.#font.generateShapes( text, scale )
    const shapeGeometry = new ShapeGeometry( shapes, 4 ) 
    
    const normals = Array.from(shapeGeometry.attributes.normal.array)
    const positions = Array.from(shapeGeometry.attributes.position.array)
    const indices = Array.from(shapeGeometry.index.array)

    const xktGeometry = {
      primitive: "triangles",
      positions: positions,
      indices: indices,
      normals: normals,
      compressGeometry: true,
    }
    
    const labelGeometry = new ReadableGeometry(node, xktGeometry)

    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255] // Остаются ярко желтыми при любом освещении, кроме полностью отсутствующего

    const yellowHighlight = new EmphasisMaterial(node, {
      edges: false,
      fill: true,
      fillColor: color,
      fillAlpha: 1,
      backfaces: false,
      glowThrough: true
    })

    const yellowPhong = new PhongMaterial(node, {
      diffuse: color,
      emissive: [0, 0, 0],
      ambient: [0, 0, 0],
      specular: [0, 0, 0],
      shininess: 128,
      alpha: this.#billboard == 'spherical' ? 1 : 0,
    })
    
    const rot = this.#rotation
    const pos = this.#position
    
    const frontMeshCfg = {
      geometry: labelGeometry,
      material: yellowPhong,
      selectedMaterial: yellowHighlight,
      rotation: rot,
      position: this.#billboard == 'spherical' ? [0, 0, 0] : pos, // TODO Исправить дрожание при billboard spherical
      origin: this.#billboard == 'spherical' ? pos : origin, // TODO Исправить дрожание при billboard spherical
      visible: this.#visible,
      billboard: this.#billboard,
    }

    const behindMeshCfg = {
      geometry: labelGeometry,
      material: yellowPhong,
      selectedMaterial: yellowHighlight,
      rotation: this.#billboard == 'none' ? [rot[0], rot[1] + 180, rot[2]] : rot,
      position: this.#billboard == 'none' ? [pos[0] * -1, pos[1], pos[2] * -1] : [0, 0, 0], // TODO Исправить дрожание при billboard spherical
      origin: this.#billboard == 'none' ? origin : [pos[0] * -1, pos[1], pos[2] * -1],
      visible: this.#visible,
      billboard: this.#billboard,
    }

    this.frontMesh = new Mesh(node, frontMeshCfg)
    this.behindMesh = new Mesh(node, behindMeshCfg)
    
    node.addChild(this.frontMesh, STATE_INHERIT)
    node.addChild(this.behindMesh, STATE_INHERIT)
  }

  #correctLengthByUnits(length, units) {
    switch (units) {
      case 'mm': return length * 1000; 
      case 'cm': return length * 100;
      case 'm': return length;
      case 'km': return length / 1000;
      default: return length
    }
  }

  /**
   * @public Отобразить вместо величины измерения текстовый заполнитель (Пример - "<> mm").
   */
  showPlaceholder() {
    const needPlaceholder = true
    this.#owner.removeChild(this.frontMesh)  
    this.frontMesh.destroy()  
    this.frontMesh = null
    this.#owner.removeChild(this.behindMesh)  
    this.behindMesh.destroy()  
    this.behindMesh = null
    this.#createLabel(needPlaceholder)
  }

  /**
   * @public Отобразить величину измерения.
   */
  showLength() {
    this.#owner.removeChild(this.frontMesh)  
    this.frontMesh.destroy()  
    this.frontMesh = null
    this.#owner.removeChild(this.behindMesh)  
    this.behindMesh.destroy()  
    this.behindMesh = null
    this.#createLabel()
  }

  /**
   * @public Установить значение величины измерения.
   * 
   * @param {Number} length 
   */
  setLength(length, needShow = false) {
    this.#length = length
    if (needShow) this.showLength()
  }

  /**
   * @public Установить настройки измерения.
   * 
   * @param {String} units Единица измерения. Допускаются значения "mm", "m", "сm", "km". 
   * @param {Number} decimalPlaces Отображаемое количество знаков после запятой.
   */
  setMeasurementSettings(units, decimalPlaces, needShow = false) {
    this.#units = units
    this.#decimalPlaces = decimalPlaces
    if (needShow) this.showLength()
  }
}
