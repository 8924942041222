export class TaskTreeCache {
  constructor() {
    this.tree = {
      tasks: null,
      map: Map
    }
    this.opened = new Set()

    this.sort_option = {
      name: "",
      reverse: false
    }

    this.beforeSortTasks = []
  }

  setTasks(tasks, opened = null) {
    let supreme = this.getSupremeTasks(tasks)
    let map_tasks = this.declouseTree(supreme)
    // this.opened.clear()
    this.opened = new Set(map_tasks.map(item => item.uuid))
    this.tree.tasks = map_tasks
    
    this.tree.map = new Map(map_tasks.map(
      (item, index) => [item.uuid, {item, index}]
    ))

    if(opened) {
      this.opened = new Set(Array.from(opened))
      this.tree.tasks = this.getSupremeTasks(tasks)
      this.getSupremeTasks(tasks).map(task => {
        if(this.isOpened(task)) {
          this.opened.delete(task.uuid)
          this.open(task)
        }
      })
    }
  }

  open(task) {
    if(this.isOpened(task)) return
    this.opened.add(task.uuid)

    let parentIndex = this.tree.tasks.findIndex(visible => visible.uuid === task.uuid)
    task.children.map((child) => {
      parentIndex ++
      this.tree.tasks.splice(parentIndex, 0, child)
      
      if(this.isOpened(child)) {
        this.opened.delete(child.uuid)

        let lastChildIndex = this.open(child)
        parentIndex = lastChildIndex
      }
    })

    return parentIndex
  }

  close(task, last_child_index = null, closen_flag = true) {
    let parentIndex = last_child_index || this.tree.tasks.findIndex(visible => visible.uuid === task.uuid) + 1
    
    if(closen_flag) {
      this.opened.delete(task.uuid)
    }

    task.children.map(child => {
      this.tree.tasks.splice(parentIndex, 1)
      
      if(this.isOpened(child)) {
        this.close(child, parentIndex, false)
      }
    })
  }

  sort(sort_option, reverse = false) {
    let sorted = []

    if(this.beforeSortTasks.length === 0) {
      this.beforeSortTasks = this.beforeSortTasks.concat(this.tree.tasks)
    }

    switch(sort_option){
      case "DEFAULT":
        this.tree.tasks = this.beforeSortTasks
        this.sort_option = {
          name: "",
          reverse: false
        }
        this.beforeSortTasks = []
        return
      case "STATUS":
        sorted = this.tree.tasks.sort((task, t) => task.completeStatus.value - t.completeStatus.value)
        sorted = reverse ? sorted.reverse() : sorted
        break
      case "PROGRESS":
        sorted = this.tree.tasks.sort((task, t) => {
          let compare = task.progress - t.progress
          if (compare === 0) {
            compare = task.title?.localeCompare(t.title)
          }
          return compare
        })
        sorted = reverse ? sorted.reverse() : sorted
        break
      case "START":
        sorted = this.tree.tasks.sort((task, t) => {
          let compare = new Date(t.startDate).getTime() - new Date(task.startDate).getTime()
          // if (compare === 0) {
          //   compare = task.title?.localeCompare(t.title)
          // }
          return compare
        })
        sorted = reverse ? sorted.reverse() : sorted
        break
      case "END":
        sorted = this.tree.tasks.sort((task, t) => {
          let compare = new Date(t.endDate).getTime() - new Date(task.endDate).getTime()
          // if (compare === 0) {
          //   compare = t.title?.localeCompare(task.title)
          // }
          return compare
        })
        sorted = reverse ? sorted.reverse() : sorted
        break
    }

    this.sort_option.name = sort_option
    this.sort_option.reverse = reverse
    this.tree.tasks = sorted
  }

  declouseTree(items, level = 0, parent = null, child_property = "children") {    
    let decloused = []

    items.map(item => {
      level
      parent

      decloused.push(item)
      
      if(item[child_property]) {
        decloused = decloused.concat(
          this.declouseTree(item[child_property], item.level + 1, item)
        )
      }
    })

    return decloused
  }

  isOpened(task) {
    return this.opened.has(task.uuid)
  }

  getSupremeTasks(tasks) {
    return tasks.filter(task => task.level == 0)
  }
}