<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="2" fill="transparent" />
    <path
      d="M10.4378 12.7887L16 9.57735L21.5622 12.7887V19.2113L16 22.4226L10.4378 19.2113V12.7887Z"
      stroke="currentColor"
    />
    <rect
      x="4.5"
      y="4.5"
      width="23"
      height="23"
      stroke="currentColor"
      stroke-dasharray="2 2"
    />
    <g clip-path="url(#clip0_6945_35)">
      <path
        d="M10.5 13L16 15.5M16 15.5V22.5M16 15.5L21.5 13"
        stroke="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6945_35">
        <rect width="16" height="16" fill="currentColor" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>