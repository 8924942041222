import { defineStore } from 'pinia'

export const usePickCoordinateViewerStore = defineStore('pickCoordinateViewer', {
  state: () => {
    return {
      isPickCoordinateActive: false,
      coordinatePins: {},
      selectedId: ''
    }
  },

  getters: {
    coordinatePinsDTO: (state) => Object.values(state.coordinatePins).map(coordPoint => {
      let [x, y, z] = coordPoint.coordinate.map(axis => Math.round((axis + Number.EPSILON) * 1000) / 1000)
      return { 
        coord: `x: ${x}, y: ${y}, z: ${z}`, 
        id: coordPoint.id ,
        visible: coordPoint.visible
      }
    })
  },

  actions: {
    setPickCoordinateActive(value) {
      this.isPickCoordinateActive = value
    },

    setCoordinatePins(coordinatePins) {
      const coordinatePinsToSave = {}
      for (const key in coordinatePins) {
        const coordinatePin = coordinatePins[key]
        coordinatePinsToSave[key] = {
          id: coordinatePin.id,
          coordinate: coordinatePin.coordinate,
          visible: coordinatePin.visible,
          creationDate: coordinatePin.creationDate,
          xktVersion: coordinatePin.xktVersion
        }
      }

      this.coordinatePins = coordinatePinsToSave
    },

    setSelectedId(id) {
      this.selectedId = id
    }
  }
})