export const pointToEdgeMeasurement = {

  findPerpendicularPointToSegment(point, segment) {
    const [x0, y0, z0] = point
    const [x1, y1, z1] = segment[0]
    const [x2, y2, z2] = segment[1]

    const ab = [x2 - x1, y2 - y1, z2 - z1]
    const ab_norm = ab.map((component) => component / Math.sqrt(ab.reduce((sum, value) => sum + value ** 2, 0)))

    const ap = [x0 - x1, y0 - y1, z0 - z1]
    const ap_proj = ab_norm.map((component) => component * (ap[0] * ab_norm[0] + ap[1] * ab_norm[1] + ap[2] * ab_norm[2]))

    const c = [x1 + ap_proj[0], y1 + ap_proj[1], z1 + ap_proj[2]]

    return c
  },
}