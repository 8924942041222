<template>
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8442 20L14.8442 15.5L19.8442 11"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.60526 23.9846C4.04507 24.6556 -0.0780207 3.71043 1.25711 1.92107C2.14718 0.728241 9.71283 1.02636 11.4929 1.47365C11.4929 1.47365 12.5716 4.86463 14.6083 5.5H24.8442C26.6244 5.94736 31.599 23.9846 28.9672 23.9846H7.60526Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M16.8442 15C19.3442 15 29.8442 15 31.3442 15C34.8442 15 34.8442 15.7826 34.8442 16.5652C34.8442 17.3478 32.3442 21.8478 29.3442 24"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>