<template>
  <svg
    width="32"
    height="32"
    viewBox="0 3 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="30" width="24" height="2" rx="1" fill="currentColor" />
    <path
      d="M5 10.8618C5 10.8618 8.57778 6.63915 10.6222 6.11132C12.6667 5.58349 15.2222 12.4453 17.2667 12.9731C19.3111 13.5009 22.3778 6.11132 28 4"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M4.41207 19.3221C4.41207 19.3221 5.49178 17.3217 7.60132 17.4122C9.71086 17.5028 11.2993 22.3118 13.1013 23.4122C14.9034 24.5126 18.6013 19.3221 27.1013 19.322"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>