/* eslint-disable */
import pako from 'pako'
import { math } from '@xeokit/xeokit-sdk';
import { utils, geometryCompressionUtils } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es';
import { XeokitMediator } from '../XeokitMediator';
import { mergeAABBs, rotateAABB, setAABBCenter, shiftAABBCenter, transformAABB } from '../sectionCube/aabb.utils';
import { ModelAABBCache, ObjectAABBCache } from '../modelAABB.temp';
import { geometry } from '../plugins/geometry/geometry';

const JPEGMediaType = 10001;
const PNGMediaType = 10002;

const tempVec4a = math.vec4();
const tempVec4b = math.vec4();

const NUM_TEXTURE_ATTRIBUTES = 9;

const chunkSize = 1000
const chunkTimeout = 100

const cachingMesh = true

function extract(elements) {

  let i = 0;

  return {
    metadata: elements[i++],
    textureData: elements[i++],
    eachTextureDataPortion: elements[i++],
    eachTextureAttributes: elements[i++],
    positions: elements[i++],
    normals: elements[i++],
    colors: elements[i++],
    uvs: elements[i++],
    indices: elements[i++],
    edgeIndices: elements[i++],
    eachTextureSetTextures: elements[i++],
    matrices: elements[i++],
    reusedGeometriesDecodeMatrix: elements[i++],
    eachGeometryPrimitiveType: elements[i++],
    eachGeometryPositionsPortion: elements[i++],
    eachGeometryNormalsPortion: elements[i++],
    eachGeometryColorsPortion: elements[i++],
    eachGeometryUVsPortion: elements[i++],
    eachGeometryIndicesPortion: elements[i++],
    eachGeometryEdgeIndicesPortion: elements[i++],
    eachMeshGeometriesPortion: elements[i++],
    eachMeshMatricesPortion: elements[i++],
    eachMeshTextureSet: elements[i++],
    eachMeshMaterialAttributes: elements[i++],
    eachEntityId: elements[i++],
    eachEntityMeshesPortion: elements[i++],
    eachTileAABB: elements[i++],
    eachTileEntitiesPortion: elements[i++]
  };
}

async function inflate(deflatedData) {
  XeokitMediator.xktLoader.fire('processing', true)

  function inflate(array, options) {
    return (array.length === 0) ? [] : pako.inflate(array, options).buffer;
  }

  const inflated = await new Promise(resolve => {
    setTimeout(() => {
      const inflated = {
        metadata: JSON.parse(pako.inflate(deflatedData.metadata, { to: 'string' })),
        textureData: new Uint8Array(inflate(deflatedData.textureData)),  // <<----------------------------- ??? ZIPPing to blame?
        eachTextureDataPortion: new Uint32Array(inflate(deflatedData.eachTextureDataPortion)),
        eachTextureAttributes: new Uint16Array(inflate(deflatedData.eachTextureAttributes)),
        positions: new Uint16Array(inflate(deflatedData.positions)),
        normals: new Int8Array(inflate(deflatedData.normals)),
        colors: new Uint8Array(inflate(deflatedData.colors)),
        uvs: new Float32Array(inflate(deflatedData.uvs)),
        indices: new Uint32Array(inflate(deflatedData.indices)),
        edgeIndices: new Uint32Array(inflate(deflatedData.edgeIndices)),
        eachTextureSetTextures: new Int32Array(inflate(deflatedData.eachTextureSetTextures)),
        matrices: new Float32Array(inflate(deflatedData.matrices)),
        reusedGeometriesDecodeMatrix: new Float32Array(inflate(deflatedData.reusedGeometriesDecodeMatrix)),
        eachGeometryPrimitiveType: new Uint8Array(inflate(deflatedData.eachGeometryPrimitiveType)),
        eachGeometryPositionsPortion: new Uint32Array(inflate(deflatedData.eachGeometryPositionsPortion)),
        eachGeometryNormalsPortion: new Uint32Array(inflate(deflatedData.eachGeometryNormalsPortion)),
        eachGeometryColorsPortion: new Uint32Array(inflate(deflatedData.eachGeometryColorsPortion)),
        eachGeometryUVsPortion: new Uint32Array(inflate(deflatedData.eachGeometryUVsPortion)),
        eachGeometryIndicesPortion: new Uint32Array(inflate(deflatedData.eachGeometryIndicesPortion)),
        eachGeometryEdgeIndicesPortion: new Uint32Array(inflate(deflatedData.eachGeometryEdgeIndicesPortion)),
        eachMeshGeometriesPortion: new Uint32Array(inflate(deflatedData.eachMeshGeometriesPortion)),
        eachMeshMatricesPortion: new Uint32Array(inflate(deflatedData.eachMeshMatricesPortion)),
        eachMeshTextureSet: new Int32Array(inflate(deflatedData.eachMeshTextureSet)), // Can be -1
        eachMeshMaterialAttributes: new Uint8Array(inflate(deflatedData.eachMeshMaterialAttributes)),
        eachEntityId: JSON.parse(pako.inflate(deflatedData.eachEntityId, { to: 'string' })),
        eachEntityMeshesPortion: new Uint32Array(inflate(deflatedData.eachEntityMeshesPortion)),
        eachTileAABB: new Float64Array(inflate(deflatedData.eachTileAABB)),
        eachTileEntitiesPortion: new Uint32Array(inflate(deflatedData.eachTileEntitiesPortion)),
      }

      resolve(inflated)
    }, 10)
  })

  XeokitMediator.xktLoader.fire('processing', false)

  return inflated
}

const decompressColor = (function () {
  const floatColor = new Float32Array(3);
  return function (intColor) {
    floatColor[0] = intColor[0] / 255.0;
    floatColor[1] = intColor[1] / 255.0;
    floatColor[2] = intColor[2] / 255.0;
    return floatColor;
  };
})();

async function load(viewer, options, inflatedData, sceneModel) {
  const metadata = inflatedData.metadata;
  const textureData = inflatedData.textureData;
  const eachTextureDataPortion = inflatedData.eachTextureDataPortion;
  const eachTextureAttributes = inflatedData.eachTextureAttributes;
  const positions = inflatedData.positions;
  const normals = inflatedData.normals;
  const colors = inflatedData.colors;
  const uvs = inflatedData.uvs;
  const indices = inflatedData.indices;
  const edgeIndices = inflatedData.edgeIndices;
  const eachTextureSetTextures = inflatedData.eachTextureSetTextures;
  const matrices = inflatedData.matrices;
  const reusedGeometriesDecodeMatrix = inflatedData.reusedGeometriesDecodeMatrix;
  const eachGeometryPrimitiveType = inflatedData.eachGeometryPrimitiveType;
  const eachGeometryPositionsPortion = inflatedData.eachGeometryPositionsPortion;
  const eachGeometryNormalsPortion = inflatedData.eachGeometryNormalsPortion;
  const eachGeometryColorsPortion = inflatedData.eachGeometryColorsPortion;
  const eachGeometryUVsPortion = inflatedData.eachGeometryUVsPortion;
  const eachGeometryIndicesPortion = inflatedData.eachGeometryIndicesPortion;
  const eachGeometryEdgeIndicesPortion = inflatedData.eachGeometryEdgeIndicesPortion;
  const eachMeshGeometriesPortion = inflatedData.eachMeshGeometriesPortion;
  const eachMeshMatricesPortion = inflatedData.eachMeshMatricesPortion;
  const eachMeshTextureSet = inflatedData.eachMeshTextureSet;
  const eachMeshMaterialAttributes = inflatedData.eachMeshMaterialAttributes;
  const eachEntityId = inflatedData.eachEntityId;
  const eachEntityMeshesPortion = inflatedData.eachEntityMeshesPortion;
  const eachTileAABB = inflatedData.eachTileAABB;
  const eachTileEntitiesPortion = inflatedData.eachTileEntitiesPortion;

  const numTextures = eachTextureDataPortion.length;
  const numTextureSets = eachTextureSetTextures.length / 5;
  const numGeometries = eachGeometryPositionsPortion.length;
  const numMeshes = eachMeshGeometriesPortion.length;
  const numEntities = eachEntityMeshesPortion.length;
  const numTiles = eachTileEntitiesPortion.length;

  const modelTransform = sceneModel._transforms[options.transformId] || sceneModel.createTransform({
    id: '__default',
  })

  let nextMeshId = 0;

  // Create metamodel, unless already loaded from external JSON file by XKTLoaderPlugin

  const metaModelId = sceneModel.id;

  if (!viewer.metaScene.metaModels[metaModelId]) {

    viewer.metaScene.createMetaModel(metaModelId, metadata, {
      includeTypes: options.includeTypes,
      excludeTypes: options.excludeTypes,
      globalizeObjectIds: options.globalizeObjectIds
    });

    sceneModel.once("destroyed", () => {
      viewer.metaScene.destroyMetaModel(metaModelId);
    });
  }

  // Create textures

  for (let textureIndex = 0; textureIndex < numTextures; textureIndex++) {
    const atLastTexture = (textureIndex === (numTextures - 1));
    const textureDataPortionStart = eachTextureDataPortion[textureIndex];
    const textureDataPortionEnd = atLastTexture ? textureData.length : (eachTextureDataPortion[textureIndex + 1]);

    const textureDataPortionSize = textureDataPortionEnd - textureDataPortionStart;
    const textureDataPortionExists = (textureDataPortionSize > 0);

    const textureAttrBaseIdx = (textureIndex * NUM_TEXTURE_ATTRIBUTES);

    const compressed = (eachTextureAttributes[textureAttrBaseIdx + 0] === 1);
    const mediaType = eachTextureAttributes[textureAttrBaseIdx + 1];
    eachTextureAttributes[textureAttrBaseIdx + 2];
    eachTextureAttributes[textureAttrBaseIdx + 3];
    const minFilter = eachTextureAttributes[textureAttrBaseIdx + 4];
    const magFilter = eachTextureAttributes[textureAttrBaseIdx + 5]; // LinearFilter | NearestFilter
    const wrapS = eachTextureAttributes[textureAttrBaseIdx + 6]; // ClampToEdgeWrapping | MirroredRepeatWrapping | RepeatWrapping
    const wrapT = eachTextureAttributes[textureAttrBaseIdx + 7]; // ClampToEdgeWrapping | MirroredRepeatWrapping | RepeatWrapping
    const wrapR = eachTextureAttributes[textureAttrBaseIdx + 8]; // ClampToEdgeWrapping | MirroredRepeatWrapping | RepeatWrapping

    if (textureDataPortionExists) {

      const imageDataSubarray = new Uint8Array(textureData.subarray(textureDataPortionStart, textureDataPortionEnd));
      const arrayBuffer = imageDataSubarray.buffer;
      const textureId = `texture-${textureIndex}`;

      if (compressed) {

        sceneModel.createTexture({
          id: textureId,
          buffers: [arrayBuffer],
          minFilter,
          magFilter,
          wrapS,
          wrapT,
          wrapR
        });

      }
      else {

        const mimeType = mediaType === JPEGMediaType ? "image/jpeg" : (mediaType === PNGMediaType ? "image/png" : "image/gif");
        const blob = new Blob([arrayBuffer], { type: mimeType });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        const img = document.createElement('img');
        img.src = imageUrl;

        sceneModel.createTexture({
          id: textureId,
          image: img,
          //mediaType,
          minFilter,
          magFilter,
          wrapS,
          wrapT,
          wrapR
        });
      }
    }
  }

  // Create texture sets

  for (let textureSetIndex = 0; textureSetIndex < numTextureSets; textureSetIndex++) {
    const eachTextureSetTexturesIndex = textureSetIndex * 5;
    const textureSetId = `textureSet-${textureSetIndex}`;
    const colorTextureIndex = eachTextureSetTextures[eachTextureSetTexturesIndex + 0];
    const metallicRoughnessTextureIndex = eachTextureSetTextures[eachTextureSetTexturesIndex + 1];
    const normalsTextureIndex = eachTextureSetTextures[eachTextureSetTexturesIndex + 2];
    const emissiveTextureIndex = eachTextureSetTextures[eachTextureSetTexturesIndex + 3];
    const occlusionTextureIndex = eachTextureSetTextures[eachTextureSetTexturesIndex + 4];
    sceneModel.createTextureSet({
      id: textureSetId,
      colorTextureId: colorTextureIndex >= 0 ? `texture-${colorTextureIndex}` : null,
      normalsTextureId: normalsTextureIndex >= 0 ? `texture-${normalsTextureIndex}` : null,
      metallicRoughnessTextureId: metallicRoughnessTextureIndex >= 0 ? `texture-${metallicRoughnessTextureIndex}` : null,
      emissiveTextureId: emissiveTextureIndex >= 0 ? `texture-${emissiveTextureIndex}` : null,
      occlusionTextureId: occlusionTextureIndex >= 0 ? `texture-${occlusionTextureIndex}` : null
    });
  }

  // Count instances of each geometry

  const geometryReuseCounts = new Uint32Array(numGeometries);

  for (let meshIndex = 0; meshIndex < numMeshes; meshIndex++) {
    const geometryIndex = eachMeshGeometriesPortion[meshIndex];
    if (geometryReuseCounts[geometryIndex] !== undefined) {
      geometryReuseCounts[geometryIndex]++;
    }
    else {
      geometryReuseCounts[geometryIndex] = 1;
    }
  }

  // Iterate over tiles

  const tileCenter = math.vec3();
  const rtcAABB = math.AABB3();

  const geometryArraysCache = {};

  let currentEntity = 0
  const entitiesAABBs = []

  const tileCenters = []
  for (let i = 0; i < eachTileAABB.length; i += 6) {
    let aabb = [
      eachTileAABB[i + 0],
      eachTileAABB[i + 1],
      eachTileAABB[i + 2],
      eachTileAABB[i + 3],
      eachTileAABB[i + 4],
      eachTileAABB[i + 5],
    ]
    aabb = transformAABB(aabb, modelTransform.worldMatrix)
    const aabbCenter = math.vec3()
    math.getAABB3Center(aabb, aabbCenter)
    tileCenters.push(aabbCenter)
  }

  for (let tileIndex = 0; tileIndex < numTiles; tileIndex++) {

    const lastTileIndex = (numTiles - 1);

    const atLastTile = (tileIndex === lastTileIndex);

    const firstTileEntityIndex = eachTileEntitiesPortion[tileIndex];
    const lastTileEntityIndex = atLastTile ? (numEntities - 1) : (eachTileEntitiesPortion[tileIndex + 1] - 1);

    const tileAABBIndex = tileIndex * 6;
    let tileAABB = eachTileAABB.subarray(tileAABBIndex, tileAABBIndex + 6);
    tileAABB = setAABBCenter(tileAABB, tileCenters[tileIndex])

    math.getAABB3Center(tileAABB, tileCenter);

    rtcAABB[0] = tileAABB[0] - tileCenter[0];
    rtcAABB[1] = tileAABB[1] - tileCenter[1];
    rtcAABB[2] = tileAABB[2] - tileCenter[2];
    rtcAABB[3] = tileAABB[3] - tileCenter[0];
    rtcAABB[4] = tileAABB[4] - tileCenter[1];
    rtcAABB[5] = tileAABB[5] - tileCenter[2];

    const tileDecodeMatrix = geometryCompressionUtils.createPositionsDecodeMatrix(rtcAABB);

    const geometryCreatedInTile = {};

    // Iterate over each tile's entities

    for (let tileEntityIndex = firstTileEntityIndex; tileEntityIndex <= lastTileEntityIndex; tileEntityIndex++) {
      if (sceneModel.destroyed) throw Error("В процессе отображения модели - она была уничтожена")

      currentEntity++

      const xktEntityId = eachEntityId[tileEntityIndex];

      const entityId = options.globalizeObjectIds ? math.globalizeObjectId(sceneModel.id, xktEntityId) : xktEntityId;

      const finalTileEntityIndex = (numEntities - 1);
      const atLastTileEntity = (tileEntityIndex === finalTileEntityIndex);
      const firstMeshIndex = eachEntityMeshesPortion[tileEntityIndex];
      const lastMeshIndex = atLastTileEntity ? (eachMeshGeometriesPortion.length - 1) : (eachEntityMeshesPortion[tileEntityIndex + 1] - 1);

      const meshIds = [];

      const metaObject = viewer.metaScene.metaObjects[entityId];
      const entityDefaults = {};
      const meshDefaults = {};

      if (metaObject) {

        if (options.excludeIdsMap && entityId && options.excludeIdsMap[entityId]) {
          continue
        }

        // Mask loading of object types

        if (options.excludeTypesMap && metaObject.type && options.excludeTypesMap[metaObject.type]) {
          continue;
        }

        if (options.includeTypesMap && metaObject.type && (!options.includeTypesMap[metaObject.type])) {
          continue;
        }

        // Get initial property values for object types

        const props = options.objectDefaults ? options.objectDefaults[metaObject.type] || options.objectDefaults["DEFAULT"] : null;

        if (props) {
          if (props.visible === false) {
            entityDefaults.visible = false;
          }
          if (props.pickable === false) {
            entityDefaults.pickable = false;
          }
          if (props.colorize) {
            meshDefaults.color = props.colorize;
          }
          if (props.opacity !== undefined && props.opacity !== null) {
            meshDefaults.opacity = props.opacity;
          }
          if (props.metallic !== undefined && props.metallic !== null) {
            meshDefaults.metallic = props.metallic;
          }
          if (props.roughness !== undefined && props.roughness !== null) {
            meshDefaults.roughness = props.roughness;
          }
        }

      }
      else {
        if (options.excludeUnclassifiedObjects) {
          continue;
        }
      }

      // Iterate each entity's meshes

      for (let meshIndex = firstMeshIndex; meshIndex <= lastMeshIndex; meshIndex++) {

        const geometryIndex = eachMeshGeometriesPortion[meshIndex];
        const geometryReuseCount = geometryReuseCounts[geometryIndex];
        const isReusedGeometry = (geometryReuseCount > 1);

        const atLastGeometry = (geometryIndex === (numGeometries - 1));

        const textureSetIndex = eachMeshTextureSet[meshIndex];

        const textureSetId = (textureSetIndex >= 0) ? `textureSet-${textureSetIndex}` : null;

        const meshColor = decompressColor(eachMeshMaterialAttributes.subarray((meshIndex * 6), (meshIndex * 6) + 3));
        const meshOpacity = eachMeshMaterialAttributes[(meshIndex * 6) + 3] / 255.0;
        const meshMetallic = eachMeshMaterialAttributes[(meshIndex * 6) + 4] / 255.0;
        const meshRoughness = eachMeshMaterialAttributes[(meshIndex * 6) + 5] / 255.0;

        const meshId = nextMeshId++;

        if (isReusedGeometry) {

          // Create mesh for multi-use geometry - create (or reuse) geometry, create mesh using that geometry

          const meshMatrixIndex = eachMeshMatricesPortion[meshIndex];
          const meshMatrix = matrices.slice(meshMatrixIndex, meshMatrixIndex + 16);

          const geometryId = "geometry." + tileIndex + "." + geometryIndex; // These IDs are local to the VBOSceneModel

          let geometryArrays = geometryArraysCache[geometryId];

          if (!geometryArrays) {
            geometryArrays = {
              batchThisMesh: (!options.reuseGeometries)
            };
            const primitiveType = eachGeometryPrimitiveType[geometryIndex];
            let geometryValid = false;
            switch (primitiveType) {
              case 0:
                geometryArrays.primitiveName = "solid";
                geometryArrays.geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
                geometryArrays.geometryNormals = normals.subarray(eachGeometryNormalsPortion[geometryIndex], atLastGeometry ? normals.length : eachGeometryNormalsPortion[geometryIndex + 1]);
                geometryArrays.geometryUVs = uvs.subarray(eachGeometryUVsPortion[geometryIndex], atLastGeometry ? uvs.length : eachGeometryUVsPortion[geometryIndex + 1]);
                geometryArrays.geometryIndices = indices.subarray(eachGeometryIndicesPortion[geometryIndex], atLastGeometry ? indices.length : eachGeometryIndicesPortion[geometryIndex + 1]);
                geometryArrays.geometryEdgeIndices = edgeIndices.subarray(eachGeometryEdgeIndicesPortion[geometryIndex], atLastGeometry ? edgeIndices.length : eachGeometryEdgeIndicesPortion[geometryIndex + 1]);
                geometryValid = (geometryArrays.geometryPositions.length > 0 && geometryArrays.geometryIndices.length > 0);
                break;
              case 1:
                geometryArrays.primitiveName = "surface";
                geometryArrays.geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
                geometryArrays.geometryNormals = normals.subarray(eachGeometryNormalsPortion[geometryIndex], atLastGeometry ? normals.length : eachGeometryNormalsPortion[geometryIndex + 1]);
                geometryArrays.geometryUVs = uvs.subarray(eachGeometryUVsPortion[geometryIndex], atLastGeometry ? uvs.length : eachGeometryUVsPortion[geometryIndex + 1]);
                geometryArrays.geometryIndices = indices.subarray(eachGeometryIndicesPortion[geometryIndex], atLastGeometry ? indices.length : eachGeometryIndicesPortion[geometryIndex + 1]);
                geometryArrays.geometryEdgeIndices = edgeIndices.subarray(eachGeometryEdgeIndicesPortion[geometryIndex], atLastGeometry ? edgeIndices.length : eachGeometryEdgeIndicesPortion[geometryIndex + 1]);
                geometryValid = (geometryArrays.geometryPositions.length > 0 && geometryArrays.geometryIndices.length > 0);
                break;
              case 2:
                geometryArrays.primitiveName = "points";
                geometryArrays.geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
                geometryArrays.geometryColors = colors.subarray(eachGeometryColorsPortion[geometryIndex], atLastGeometry ? colors.length : eachGeometryColorsPortion[geometryIndex + 1]);
                geometryValid = (geometryArrays.geometryPositions.length > 0);
                break;
              case 3:
                geometryArrays.primitiveName = "lines";
                geometryArrays.geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
                geometryArrays.geometryIndices = indices.subarray(eachGeometryIndicesPortion[geometryIndex], atLastGeometry ? indices.length : eachGeometryIndicesPortion[geometryIndex + 1]);
                geometryValid = (geometryArrays.geometryPositions.length > 0 && geometryArrays.geometryIndices.length > 0);
                break;
              default:
                continue;
            }

            if (!geometryValid) {
              geometryArrays = null;
            }

            if (geometryArrays) {
              if (geometryArrays.geometryPositions.length > 1000);
              if (geometryArrays.batchThisMesh) {
                geometryArrays.decompressedPositions = new Float32Array(geometryArrays.geometryPositions.length);
                geometryArrays.transformedAndRecompressedPositions = new Uint16Array(geometryArrays.geometryPositions.length);
                const geometryPositions = geometryArrays.geometryPositions;
                const decompressedPositions = geometryArrays.decompressedPositions;
                for (let i = 0, len = geometryPositions.length; i < len; i += 3) {
                  decompressedPositions[i + 0] = geometryPositions[i + 0] * reusedGeometriesDecodeMatrix[0] + reusedGeometriesDecodeMatrix[12];
                  decompressedPositions[i + 1] = geometryPositions[i + 1] * reusedGeometriesDecodeMatrix[5] + reusedGeometriesDecodeMatrix[13];
                  decompressedPositions[i + 2] = geometryPositions[i + 2] * reusedGeometriesDecodeMatrix[10] + reusedGeometriesDecodeMatrix[14];
                }
                geometryArrays.geometryPositions = null;
                geometryArraysCache[geometryId] = geometryArrays;
              }
            }
          }

          if (geometryArrays) {
            if (geometryArrays.batchThisMesh) {

              const decompressedPositions = geometryArrays.decompressedPositions;
              const transformedAndRecompressedPositions = geometryArrays.transformedAndRecompressedPositions;

              for (let i = 0, len = decompressedPositions.length; i < len; i += 3) {
                tempVec4a[0] = decompressedPositions[i + 0];
                tempVec4a[1] = decompressedPositions[i + 1];
                tempVec4a[2] = decompressedPositions[i + 2];
                tempVec4a[3] = 1;
                math.transformVec4(meshMatrix, tempVec4a, tempVec4b);
                geometryCompressionUtils.compressPosition(tempVec4b, rtcAABB, tempVec4a);
                transformedAndRecompressedPositions[i + 0] = tempVec4a[0];
                transformedAndRecompressedPositions[i + 1] = tempVec4a[1];
                transformedAndRecompressedPositions[i + 2] = tempVec4a[2];
              }

              const decompressed = []
              geometryCompressionUtils.decompressPositions(transformedAndRecompressedPositions, tileDecodeMatrix, decompressed)



              try {
                sceneModel.createMesh(utils.apply(meshDefaults, {
                  id: meshId,
                  textureSetId: textureSetId,
                  origin: tileCenter,
                  primitive: geometryArrays.primitiveName,
  
                  positions: decompressed,
                  rotation: modelTransform.rotation,
  
                  // positionsCompressed: transformedAndRecompressedPositions,
                  normalsCompressed: geometryArrays.geometryNormals,
                  uv: geometryArrays.geometryUVs,
                  colorsCompressed: geometryArrays.geometryColors,
                  indices: geometryArrays.geometryIndices,
                  edgeIndices: geometryArrays.geometryEdgeIndices,
                  // positionsDecodeMatrix: tileDecodeMatrix,
                  color: meshColor,
                  metallic: meshMetallic,
                  roughness: meshRoughness,
                  opacity: meshOpacity
                }));
              } 
              catch (error) {
                throw new Error("Принудительное завершение создания меша")
              }

              if (cachingMesh)  {
                const mesh = sceneModel._meshes[meshId]
                mesh.positions = transformedAndRecompressedPositions
                mesh.normals = geometryArrays.geometryNormals
                mesh.indices = geometryArrays.geometryIndices
                mesh.edgeIndices = geometryArrays.geometryEdgeIndices
                mesh.positionsDecodeMatrix = tileDecodeMatrix
              }

              meshIds.push(meshId);

            } else {
              if (!geometryCreatedInTile[geometryId]) {
                try {
                  sceneModel.createGeometry({
                    id: geometryId,
                    primitive: geometryArrays.primitiveName,
                    positionsCompressed: geometryArrays.geometryPositions,
                    normalsCompressed: geometryArrays.geometryNormals,
                    uv: geometryArrays.geometryUVs,
                    colorsCompressed: geometryArrays.geometryColors,
                    indices: geometryArrays.geometryIndices,
                    edgeIndices: geometryArrays.geometryEdgeIndices,
                    positionsDecodeMatrix: reusedGeometriesDecodeMatrix
                  });
                } catch (error) {
                  throw new Error("Принудительное завершение создания геометрии меша")
                }

                geometryCreatedInTile[geometryId] = true;
              }

              const defaultScale = math.vec3([1, 1, 1]);
              const defaultQuaternion = math.identityQuaternion();

              const modelScale = modelTransform.scale || defaultScale;
              const modelQuaternion = modelTransform.quaternion || defaultQuaternion;

              const meshTransformMatrix = math.mat4()
              math.composeMat4(math.vec3(), modelQuaternion, modelScale, meshTransformMatrix)

              try {
                sceneModel.createMesh(utils.apply(meshDefaults, {
                  id: meshId,
                  geometryId: geometryId,
                  textureSetId: textureSetId,
                  matrix: math.mulMat4(meshTransformMatrix, meshMatrix, math.mat4()),
                  color: meshColor,
                  metallic: meshMetallic,
                  roughness: meshRoughness,
                  opacity: meshOpacity,
                  origin: tileCenter
                }));
              } catch (error) {
                throw new Error("Принудительное завершение создания меша")
              }
              

              if (cachingMesh)  {
                const mesh = sceneModel._meshes[meshId]
                mesh.positions = geometryArrays.geometryPositions
                mesh.normals = geometryArrays.geometryNormals
                mesh.indices = geometryArrays.geometryIndices
                mesh.edgeIndices = geometryArrays.geometryEdgeIndices
                mesh.positionsDecodeMatrix = reusedGeometriesDecodeMatrix
                mesh.entityMatrix = meshMatrix
              }

              meshIds.push(meshId);
            }
          }

        } else { // Do not reuse geometry

          const primitiveType = eachGeometryPrimitiveType[geometryIndex];

          let primitiveName;
          let geometryPositions;
          let geometryNormals;
          let geometryUVs;
          let geometryColors;
          let geometryIndices;
          let geometryEdgeIndices;
          let geometryValid = false;

          switch (primitiveType) {
            case 0:
              primitiveName = "solid";
              geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
              geometryNormals = normals.subarray(eachGeometryNormalsPortion[geometryIndex], atLastGeometry ? normals.length : eachGeometryNormalsPortion[geometryIndex + 1]);
              geometryUVs = uvs.subarray(eachGeometryUVsPortion[geometryIndex], atLastGeometry ? uvs.length : eachGeometryUVsPortion[geometryIndex + 1]);
              geometryIndices = indices.subarray(eachGeometryIndicesPortion[geometryIndex], atLastGeometry ? indices.length : eachGeometryIndicesPortion[geometryIndex + 1]);
              geometryEdgeIndices = edgeIndices.subarray(eachGeometryEdgeIndicesPortion[geometryIndex], atLastGeometry ? edgeIndices.length : eachGeometryEdgeIndicesPortion[geometryIndex + 1]);
              geometryValid = (geometryPositions.length > 0 && geometryIndices.length > 0);
              break;
            case 1:
              primitiveName = "surface";
              geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
              geometryNormals = normals.subarray(eachGeometryNormalsPortion[geometryIndex], atLastGeometry ? normals.length : eachGeometryNormalsPortion[geometryIndex + 1]);
              geometryUVs = uvs.subarray(eachGeometryUVsPortion[geometryIndex], atLastGeometry ? uvs.length : eachGeometryUVsPortion[geometryIndex + 1]);
              geometryIndices = indices.subarray(eachGeometryIndicesPortion[geometryIndex], atLastGeometry ? indices.length : eachGeometryIndicesPortion[geometryIndex + 1]);
              geometryEdgeIndices = edgeIndices.subarray(eachGeometryEdgeIndicesPortion[geometryIndex], atLastGeometry ? edgeIndices.length : eachGeometryEdgeIndicesPortion[geometryIndex + 1]);
              geometryValid = (geometryPositions.length > 0 && geometryIndices.length > 0);
              break;
            case 2:
              primitiveName = "points";
              geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
              geometryColors = colors.subarray(eachGeometryColorsPortion[geometryIndex], atLastGeometry ? colors.length : eachGeometryColorsPortion[geometryIndex + 1]);
              geometryValid = (geometryPositions.length > 0);
              break;
            case 3:
              primitiveName = "lines";
              geometryPositions = positions.subarray(eachGeometryPositionsPortion[geometryIndex], atLastGeometry ? positions.length : eachGeometryPositionsPortion[geometryIndex + 1]);
              geometryIndices = indices.subarray(eachGeometryIndicesPortion[geometryIndex], atLastGeometry ? indices.length : eachGeometryIndicesPortion[geometryIndex + 1]);
              geometryValid = (geometryPositions.length > 0 && geometryIndices.length > 0);
              break;
            default:
              continue;
          }

          if (geometryValid) {

            const decompressed = []
            geometryCompressionUtils.decompressPositions(geometryPositions, tileDecodeMatrix, decompressed)

            try {
              sceneModel.createMesh(utils.apply(meshDefaults, {
                id: meshId,
                textureSetId: textureSetId,
                origin: tileCenter,
                primitive: primitiveName,
                
                positions: decompressed,
                rotation: modelTransform.rotation,
  
                // positionsCompressed: geometryPositions,
                normalsCompressed: geometryNormals,
                uv: geometryUVs && geometryUVs.length > 0 ? geometryUVs : null,
                colorsCompressed: geometryColors,
                indices: geometryIndices,
                edgeIndices: geometryEdgeIndices,
                // positionsDecodeMatrix: tileDecodeMatrix,
                color: meshColor,
                metallic: meshMetallic,
                roughness: meshRoughness,
                opacity: meshOpacity
              }));
            } catch (error) {
              throw new Error("Принудительное завершение создания меша")
            }
            
            
            if (cachingMesh)  {
              const mesh = sceneModel._meshes[meshId]
              mesh.positions = geometryPositions
              mesh.normals = geometryNormals
              mesh.indices = geometryIndices
              mesh.edgeIndices = geometryEdgeIndices
              mesh.positionsDecodeMatrix = tileDecodeMatrix
            }

            meshIds.push(meshId);
          }
        }
      }

      if (meshIds.length > 0) {
        sceneModel.createEntity(utils.apply(entityDefaults, {
          id: entityId,
          isObject: true,
          meshIds: meshIds
        }));

        const entity = sceneModel.objects[entityId]
        entity.xktVersion = "10"
        
        // ObjectAABBCache.setObjectAABB(entityId, rotateAABB(shiftAABBCenter(entity.aabb, sceneModel.position), sceneModel.rotation))
        // entitiesAABBs.push(entity.aabb)

        // geometry.drawer.viewAABBs([entity.aabb])

        // if (atLastTile) {
          // geometry.drawer.viewAABBs([sceneModel.aabb])
          // ModelAABBCache.setModelAABB(sceneModel.id, mergeAABBs(entitiesAABBs))
        // }

        if (currentEntity % chunkSize === 0 || currentEntity === numEntities) {
          await new Promise(resolve => {
            XeokitMediator.xktLoader.fire('chunkParse', {
              current: currentEntity,
              total: numEntities,
            })

            setTimeout(resolve, chunkTimeout)
          })
        }
      }
    }
  }
}

const ParserV10 = {
  version: 10,
  parse: async function (viewer, options, elements, sceneModel) {
    const deflatedData = extract(elements);
    const inflatedData = await inflate(deflatedData);

    XeokitMediator.xktLoader.fire('renderStart')
    await load(viewer, options, inflatedData, sceneModel).catch(() => {
      console.log("Принудительное завершение отображения модели")
    });
    XeokitMediator.xktLoader.fire('renderDone')
  }
};

export { ParserV10 }