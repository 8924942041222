<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 13C22 16.31 19.31 19 16 19C12.69 19 10 16.31 10 13C10 9.69 12.69 7 16 7V11L21 6L16 1V5C11.58 5 8 8.58 8 13C8 17.42 11.58 21 16 21C20.42 21 24 17.42 24 13H22Z"
      fill="currentColor"
    />
    <path
      d="M14.86 15.94V11.67H14.77L13 12.3V12.99L14.01 12.68V15.94H14.86Z"
      fill="currentColor"
    />
    <path
      d="M16.25 13.44V14.18C16.25 16.08 17.56 16 17.69 16C17.83 16 19.13 16.09 19.13 14.18V13.44C19.13 11.54 17.82 11.62 17.69 11.62C17.55 11.62 16.25 11.53 16.25 13.44ZM18.29 13.32V14.29C18.29 15.06 18.08 15.32 17.7 15.32C17.32 15.32 17.1 15.06 17.1 14.29V13.32C17.1 12.57 17.32 12.31 17.69 12.31C18.07 12.3 18.29 12.57 18.29 13.32Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>