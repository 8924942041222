<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12_1271)">
    <path d="M7 9V23C7 24.1 7.89 25 9 25H23C24.1 25 25 24.1 25 23V9C25 7.9 24.1 7 23 7H9C7.89 7 7 7.9 7 9ZM19 13C19 14.66 17.66 16 16 16C14.34 16 13 14.66 13 13C13 11.34 14.34 10 16 10C17.66 10 19 11.34 19 13ZM10 21C10 19 14 17.9 16 17.9C18 17.9 22 19 22 21V22H10V21Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_12_1271">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {

}
</script>

<style>

</style>