import worm from '@/store/worm.module.js'

function sortNodes(el1, el2) {
  if (el1.nodeType.value < el2.nodeType.value) return -1
  else if (el1.nodeType.value > el2.nodeType.value) return 1
  else if (el1.srt < el2.srt) return -1
  else if (el1.srt > el2.srt) return 1 
  else if (el1.pressmark < el2.pressmark) return -1
  else if (el1.pressmark > el2.pressmark) return 1
  else if (el1.classificatorNode?.pressmark < el2.classificatorNode?.pressmark) return -1
  else if (el1.classificatorNode?.pressmark > el2.classificatorNode?.pressmark) return 1
  else if (el1.title < el2.title) return -1
  else if (el1.title > el2.title) return 1
}

export default {
    state: {
      count: 0,
      filteredNodeList: [],
    },
  
    getters: {
      allNodes: (state, getters) => selected => {
        return getters.query().where('projectWormUuid',selected).orderBy('srt').orderBy('pressmark').orderBy('title').get()
      },

      allTopic: (state, getters) => selected => {
        if (worm.state.showWithValidRules) {
          let curr = state.filteredNodeList.filter(
            el => el.projectWormUuid === selected && el.hasChildren === true && el.nodeType.name === "TOPIC" && el.hasValidRules === true
          )
          return curr.length
        }
        else {
          return getters.query().where(item => {
            return item.projectWormUuid === selected && item.hasChildren === true && item.nodeType.name === "TOPIC"
          }).orderBy('srt').orderBy('pressmark').orderBy('title').count()
        }
      },
    
      nodesTree: (state, getters) => selected => {
        let all = getters.allNodes(selected)
        let a = []

        let f = (parent, list) => {
          let nd = all.filter(el => el.parentUuid == parent?.uuid).sort((el1, el2) => {
            return sortNodes(el1, el2)
          })
          
          let validChildrens = []
          nd.forEach(node => {
            node.children = []

            const filters = f(node, node.children)

            if (filters.hasValidRules) {
              if (parent) parent.hasValidRules = true
              validChildrens.push(node)
            }

          });

          if (worm.state.showWithValidRules) {
            list.push(...validChildrens)
          }
          else list.push(...nd)

          return {
            hasValidRules: parent?.hasValidRules
          }
        }
        f(null, a)
        
        state.filteredNodeList = all.filter(item => item.hasValidRules)
        return a
      },
  
      isAllDefaultType:(state) => {
        return !(state.data && Object.values(state.data).some(el => el.dataExportType && el.dataExportType.name == 'SHORT'))
      }
    },

  }