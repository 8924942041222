import { Plugin, math } from "@xeokit/xeokit-sdk";
import { CoordinatePin } from "./coordinatePin/coordinatePin";
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { usePickCoordinateViewerStore } from '@/pinia'

export class CoordinatePinsPlugin extends Plugin{

  #coordinatePins;

  constructor() {
    super("CoordinatePinsPlugin", XeokitMediator.viewer)
    this.#coordinatePins = {}
  }

  get coordinatePins() { return this.#coordinatePins }

  createCoordinatePin(cfg = {}) {
    const coordinatePin = new CoordinatePin(this, {
      id: cfg.id ?? math.createUUID(),
      coordinate: cfg.coordinate ?? [0, 0, 0],
      xktVersion: cfg.xktVersion ?? 10,
      creationDate: cfg.creationDate ?? Date.now(),
      visible: cfg.visible ?? true
    })

    this.#coordinatePins[coordinatePin.id] = coordinatePin
    usePickCoordinateViewerStore().setCoordinatePins(this.#coordinatePins)

    this.fire("coordinatePinCreated", coordinatePin)

    return coordinatePin.id
  }

  /**
   * Создание CoordinatePin, который не регистрируется в списке координатных точек
   * 
   * @param {Object} cfg 
   * 
   * @returns CoordinatePin
   */
  createHoveringPin(cfg = {}) {
    return new CoordinatePin(this, {
      id: cfg.id ?? math.createUUID(),
      coordinate: cfg.coordinate ?? [0, 0, 0],
      xktVersion: cfg.xktVersion ?? 10,
      creationDate: cfg.creationDate ?? Date.now(),
      visible: cfg.visible ?? true
    })
  }

  destroyCoordinatePins(coordinatePins) {
    coordinatePins.forEach(coordinatePin => {
      this.#coordinatePins[coordinatePin.id].destroy()
      delete this.#coordinatePins[coordinatePin.id]
    })
    usePickCoordinateViewerStore().setCoordinatePins(this.#coordinatePins)
  }

  setCoordinatePinsVisible(coordinatePins, visible) {
    coordinatePins.forEach(coordinatePin => {
      this.#coordinatePins[coordinatePin.id].setVisible(visible)
    })
    usePickCoordinateViewerStore().setCoordinatePins(this.#coordinatePins)
  }

  destroy() {
    super.destroy()
  }
}