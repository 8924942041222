<template lang="pug">
.app-header
  .app-header-title {{ title }}
    slot
</template>

<script>
export default {
  props: {
    title: String,
  }
}
</script>

<style scoped>
.app-header {
  display: flex;
}

.app-header-title {
  flex: 1 1 auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--v-surface-lighten3);
  display: flex;
  align-items: center;
}
</style>