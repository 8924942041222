// import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { EmphasisMaterial, Mesh, PhongMaterial, VBOGeometry } from "@xeokit/xeokit-sdk";
import { geometry as geom } from "@/plugins/xeokit/plugins/geometry/geometry";

const NO_COLOR = [0, 0, 0]
export class FacePin {

  #scene; #pin;
  #trianglesPositions;
  #visible; #edges; #color;

  constructor(scene, cfg = {}) {
    this.#scene = scene

    this.#pin = null

    this.#trianglesPositions = cfg.trianglesPositions ?? null

    this.#visible = cfg.visible ?? true
    this.#edges = cfg.edges ?? true
    this.#color = cfg.color ?? [1.0, 0.5, 0.5]

    this.#create()
  }

  #create() {
    const origin = this.#trianglesPositions ? this.#trianglesPositions[0] : [0, 0, 0]

    const positions = []
    this.#trianglesPositions?.forEach(pos => {
      const newPos = geom.math.subVec3(pos, origin)
      positions.push(newPos[0], newPos[1], newPos[2])
    })
    const normals = new Array(positions.length).fill(1.0)
    const indices = []
    for (let i = 0; i < positions.length / 3; i++) { indices.push(i) }

    const geometry = new VBOGeometry(this.#scene, {
      primitive: 'triangles',
      positions: positions,
      normals: normals,
      indices: indices, 
      compressGeometry: true,
    })

    const color = [this.#color[0] * 2, this.#color[1] * 2, this.#color[2] * 2]
    const contrastingColor = [this.#color[0] * 2, this.#color[1] * 0, this.#color[2] * 0]
    const material = new PhongMaterial(this.#scene, {
      emissive: color,
      diffuse: NO_COLOR,
      ambient: NO_COLOR,
      alpha: 0
    })

    const selectedMaterial = new EmphasisMaterial(this.#scene, {
      fill: true,
      fillColor: color,
      fillAlpha: 0.6,
      edges: this.#edges,
      edgeColor: contrastingColor,
      edgeAlpha: 1,
      glowThrough: true,
      backfaces: true
    })

    this.#pin = new Mesh(this.#scene, {
      geometry: geometry,
      material: material,
      origin: origin,
      selected: true,
      selectedMaterial: selectedMaterial,
      visible: this.#visible,
    })
  }

  destroy() {
    this.#pin?.destroy()
    this.#pin = null
  }
}