export class SmetaOrganizationProject {
  constructor(json) {
    this.alias = json.alias
    this.cloudRoot = json.cloudRoot
    this.color = json.color
    this.description = json.description
    this.membership = json.membership
    this.org = json.org
    this.preview = json.preview
    this.tariffAlias = json.tariffAlias
    this.title = json.title
    this.uuid = json.uuid
    this.selected = json.selected ?? false
  }

  setSelectedValue(value) {
    this.selected = value
  }
}