<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" fill="transparent"/>
    <rect x="18" y="18" width="12" height="5" stroke="currentColor" />
    <path d="M2 13C2 10.2386 4.23858 8 7 8H13C15.7614 8 18 10.2386 18 13V24H7C4.23858 24 2 21.7614 2 19V13Z" stroke="currentColor"/>
    <path d="M30 23V16" stroke="currentColor"/>
    <path d="M27 23V20" stroke="currentColor"/>
    <path d="M24 23V20" stroke="currentColor"/>
    <path d="M21 23V20" stroke="currentColor"/>
    <circle cx="10" cy="16" r="4" fill="currentColor"/>
    <path d="M31 31L27 31L31 27V31Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {};
</script>

<style>
</style>