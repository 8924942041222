import { SmetaPanelType } from "@/components/project/panel/left/smeta/components/utils/SmetaPanelType";
import { SmetaTabType } from "@/components/project/panel/left/smeta/components/utils/SmetaTabType";
import { defineStore } from "pinia";

export const useSmetaPanelStore = defineStore('smeta.panel', { 
  state: () => ({ 
    currentSmetaPanelType: SmetaPanelType.CLASSIFICATOR,
    dialog: {
      classificatorControl: false,
      grandSmeta: false,
      shareToOrg: false,
      deleteDict: false,
      addProjectDict: false,
      mergeSmeta: false,
      exportDialog: false,
      importDialog: false
    },
    tab: SmetaTabType.CLASSIFICATOR
  }),

  getters: {
    classificatorSmetaPanel: (state) => state.currentSmetaPanelType == SmetaPanelType.CLASSIFICATOR,
    updateSmetaPanel: (state) => state.currentSmetaPanelType == SmetaPanelType.UPDATE,
    analyzeSmetaPanel: (state) => state.currentSmetaPanelType == SmetaPanelType.ANALYZE,
  },

  actions: {
    setSmetaPanelClassificatorType() { this.currentSmetaPanelType = SmetaPanelType.CLASSIFICATOR },
    setSmetaPanelUpdateType() { this.currentSmetaPanelType = SmetaPanelType.UPDATE },
    setSmetaPanelAnalyzeType() { this.currentSmetaPanelType = SmetaPanelType.ANALYZE }
  }
})