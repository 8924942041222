import { RenderState } from './renderState'
import { Component, math } from '@xeokit/xeokit-sdk'

/*eslint-disable no-dupe-class-members*/
export class SectionPlane extends Component {
  
  #state; #owner; #active; #pos; #dir;
  
  constructor(owner, cfg = {}) {
    super(owner, cfg)
    this.#owner = owner
    this.#state = new RenderState({
      active: this.#active,
      pos: math.vec3(),
      dir: math.vec3(),
      dist: 0,
    })

    this.#active = cfg.active ?? true
    this.#pos = cfg.position ? math.vec3(cfg.position) : math.vec3([0, 0, 0])
    this.#dir = cfg.normal ? math.vec3(cfg.normal) : math.vec3([0, 0, 1])

    this.#create()
  }

  // Необходимо для рендерера
  get _state() { return this.#state }
  get active() { return this.#state.active }
  get pos() { return this.#state.pos }
  get dir() { return this.#state.dir }
  get dist() { return this.#state.dist }

  // Необходимо БИМИТ
  get position() { return this.#state.pos }
  get normal() { return this.#flipDir(this.#state.dir) }

  #create() {
    this.setActive(this.#active)
    this.setDir(this.#dir)
    this.setPos(this.#pos)
    this.#owner.scene._sectionPlaneCreated(this) // Необходимо для рекомпиляции шейдера
  }

  #flipDir(dir) {
    return [-dir[0], -dir[1], -dir[2]]
  }

  setActive(value) {
    this.#state.active = value !== false
    this.glRedraw()
    this.fire('active', this._state.active)
  }

  setDir(value, needRedraw = true) {
    const dir = this.#flipDir(value)
    this.#state.dir.set(dir || [0, 0, -1])
    this.#state.dist = -math.dotVec3(this.#state.pos, this.#state.dir)
    if (needRedraw) this.glRedraw()
    this.fire('dir', this.dir)
    this.scene.fire('sectionPlaneUpdated', this)
  }

  setPos(value) {
    this.#state.pos.set(value || [0, 0, 0])
    this.#state.dist = -math.dotVec3(this.#state.pos, this.#state.dir)
    this.fire('pos', this.pos)
    this.scene.fire('sectionPlaneUpdated', this)
  }

  flipDir() {
    const dir = this.#state.dir
    dir[0] *= -1.0
    dir[1] *= -1.0
    dir[2] *= -1.0
    this.#state.dist = -math.dotVec3(this.#state.pos, this.#state.dir)
    this.fire('dir', this.dir)
    this.scene.fire('sectionPlaneUpdated', this)
    this.glRedraw()
  }

  destroy() {
    this.#state.destroy()
    this.#owner.scene._sectionPlaneDestroyed(this)
    super.destroy()
  }
}
