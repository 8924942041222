import { AxisNode, AxisUtils } from '.'

export class AxisTree {
  constructor (tree = []) {
    this.items = tree.map(i => new AxisNode(i))
    this.onlyNodes = this.items.reduce(AxisUtils.onlyNodesReducer, [])

    this.onlyEndpoints = this.items.reduce(AxisUtils.onlyEndpointsReducer, [])

    this.flatlist = AxisUtils.flatlist(this.items)

    this.extraMap = new Map(this.flatlist.map(node => [node.extraId, node]))

    this.selectedNodes = this.flatlist.filter(node => node.selected)

  }
}