import { $_drawing_server } from '@/_helpers'
import { $_drawing_server_noAuth } from '@/_helpers'

export const drawingService = {
  addNewDrawing,
  getAllDrawings,
  loadDrawingPage,
  getDrawingPreview,
  archiveDrawing,
  addNewComment,
  editComment,
  includePages,
  renamePage,
  newDrawingVersion,
  getPagesByVersion,
  getAllVersions,
  downloadVersion,
  changeCurrentVersionOfDrawing,
  importComment,
  changeDrawing,
  getVersionPagesByPageUuid,
  getCloudDrawingInfo,
  getCommentAttachment,
  importCommentToNewPage
}

function addNewDrawing(name, modelUuid, onUploadProgress) {
  return $_drawing_server.post(`/drawing/${name}/${modelUuid}`, {
    onUploadProgress
  }).then(response => response.data)
}

function getAllDrawings(projectUuid) {
  return $_drawing_server.get(`/${projectUuid}`).then(response => response.data)
}

function loadDrawingPage(drawingPageUuid) {
  return $_drawing_server.get( `/download/${drawingPageUuid}`).then(response => response.data)
}

function changeDrawing(drawingUuid, name, modelUuid) {
  return $_drawing_server.patch( `/drawing/${drawingUuid}/name/${name}/modelUuid/${modelUuid}`).then(response => response.data)
}

function getDrawingPreview(drawingUuid) {
  return $_drawing_server.get(`/converter/download/preview/${drawingUuid}`, { responseType: "blob" }).then(response => response.data)
}

function archiveDrawing(uuid) {
  return $_drawing_server.patch(`/drawing/archive/${uuid}`).then(response => response.data)
}

function renamePage(page) {
  return $_drawing_server.patch(`/drawing/page/${page.uuid}/name/${page.name ? page.name : "nullmarker"}/rename`).then(response => response.data)
}

function getPagesByVersion(drawingVersionUuid) {
  return $_drawing_server.get(`/versions/drawing/${drawingVersionUuid}/pages`).then(response => response.data)
}

function getVersionPagesByPageUuid(pageUuid) {
  return $_drawing_server.get(`/versions/drawing/version/pages/page/${pageUuid}`).then(response => response.data)
}

function downloadVersion(drawingVersionUuid) {
  return $_drawing_server.get(`/versions/download/drawing/version/${drawingVersionUuid}`, {responseType: 'blob'}).then(response => response.data)
}

function newDrawingVersion(data, onUploadProgress) {
  return $_drawing_server.post(`/versions/drawing/version`, data, {
    onUploadProgress
  }).then(response => response.data)
}

function changeCurrentVersionOfDrawing(drawingUUID, versionUUID, projectUUID) {
  return $_drawing_server.patch(`/versions/drawing/${drawingUUID}/version/${versionUUID}/${projectUUID}`).then(response => response.data)
}

function getAllVersions(drawing) {
  return $_drawing_server.get(`/versions/drawing/${drawing.uuid}/versions`).then(response => response.data)
}

function importComment(oldDrawingPageUUID, newDrawingPageUUID, commentUUID) {
  return $_drawing_server.patch(`/drawing/page/${oldDrawingPageUUID}/${newDrawingPageUUID}/comment/import/${commentUUID}`).then(response => response.data)
}

function importCommentToNewPage(oldDrawingPageUUID, newDrawingPageUUID) {
  return $_drawing_server.patch(`/drawing/page/${oldDrawingPageUUID}/${newDrawingPageUUID}`).then(response => response.data)
}

function getCloudDrawingInfo(infoHashes) {
  return $_drawing_server_noAuth.get(`/qrcode/getfileinfo/${infoHashes.fileHash}/${infoHashes.userHash}/${infoHashes.cloudHash}`).then(response => response.data)
}

function getCommentAttachment(attachUuid){
  return $_drawing_server.post(`/drawing/update/attachment/${attachUuid}`).then( response => response.data )
}

function includePages(pages, projectUuid, drawingUuid) {
  pages.map((page, index) => {
    let formPage = {}
    formPage = {
      uuid: page.uuid,
      excluded: !page.included,
      name: page.name,
      attachments: page.attachments,
      drawingVersion: page.drawingVersion,
      number: page.number
    }
    pages[index] = formPage
  })
  return $_drawing_server.patch(`/drawing/${drawingUuid}/page/including/project/${projectUuid}`, pages).then(response => response.data)
}

function addNewComment(comment) {
  let sourceData = {
    text: comment.text,
    x: comment.x,
    y: comment.y,
    scale: comment.scale,
    page: comment.page.uuid
  }

  let options = new FormData()
  options.append('sourceData', JSON.stringify(sourceData))
  options.append('profileUuid', comment.user.uuid)
  options.append('file', convert(comment.src), 'preview.png')
  options.append('pageUuid', comment.page.uuid)
  options.append('taskUuid', comment.task ? comment.task.uuid: "")
  // makeSnapshot(comment.src)
  return $_drawing_server.post(`/drawing/comment`, options).then( response => response.data )
}

function editComment(comment) {
  let sourceData = {
    text: comment.text ? comment.text : comment.cameraParse.text,
    x: comment.x ? comment.x : comment.cameraParse.x,
    y: comment.y ? comment.y : comment.cameraParse.y,
    scale: comment.scale ? comment.scale : comment.cameraParse.scale,
    page: comment.page ? comment.page : comment.cameraParse.page
  }
  let options = {
    sourceData: JSON.stringify(sourceData),
    uuid: comment.uuid,
    profileUuid: comment.user ? comment.user.uuid : comment.creator.uuid,
    file: comment.src ? comment.src : comment.file,
    task: comment.task ? comment.task.uuid : "",
    createDate: comment.createDate,
    contentType: comment.contentType,
    creator: comment.user ? comment.user : comment.creator,
    sourceType: comment.sourceType
  }
  return $_drawing_server.put(`/drawing/comment`, options).then( response => response.data )
}


// function makeSnapshot(src) {
//   // const canvas = XeokitMediator.viewer.scene.canvas
//   // const canvasElement = canvas.canvas
//   // const width = canvasElement.width
//   // const height = canvasElement.height //Math.floor(width * aspect)
//   let data = src
//   console.log(data)
//   // let index = data.indexOf(',')
//   // data = data.substring(index + 1)
//   let a = document.createElement("a");
//   a.href = URL.createObjectURL(convert(data, 'png'))
//   a.download = "example.png";
//   a.click();
 
// }
function convert(b64Data, contentType='', sliceSize=512) {
  let index = b64Data.indexOf(',')
  b64Data = b64Data.substring(index + 1)
  const byteCharacters = Buffer.from(b64Data, 'base64');
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteArray = new Uint8Array(slice);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
