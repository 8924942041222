import { defineStore } from "pinia";
import { projectService } from "@/_services";
import { SearchElementsFilter } from "@/assets/model/searchElements/SearchElementsFilter";
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { SearchElementsType } from "@/assets/model/searchElements/SearchElementsType";
import projectModule from "@/store/project.module";
import { SearchElementItem } from "@/assets/model/searchElements/SearchElementItem";
import _ from 'lodash'

export const useSearchElementsStore = defineStore('searchElements', {
  state: () => ({
    searchElementsFilter: new SearchElementsFilter(),
    currentElements: [],
    selectedElements: new Map(),
    currentElementsTypes: [],
    isElementsSelected: false,
    isFiltersSelected: false,
    currentElementsClassTypes: [],
    firstElementLoaded: false,
    pickedElementUuid: null
  }),

  getters: {
  },

  actions: {
    fillElements(data) {
      data.forEach((item) => {
        this.currentElements.find(el => el.uuid == item.uuid)?.setData({ ...item, selected: this.selectedElements.get(item.uuid) })
      })
    },

    fetchElements() {
      let uuids = []
      const from = this.searchElementsFilter.pageSize * this.searchElementsFilter.page
      let to = from + this.searchElementsFilter.pageSize
      if (to > this.currentElements.length) {
        to = this.currentElements.length
      }
      for (let i = from; i < to; i++) {
        if (!this.currentElements[i]?.name) {
          uuids.push(this.currentElements[i].uuid)
        }
      }
      if (uuids.length) {
        this.searchElementsFilter.elementUuids = uuids
        return projectService.getElementsByFilter(this.searchElementsFilter).then((data) => {
          this.fillElements(data)
          this.searchElementsFilter.elementUuids = null
        })
      } else {
        return Promise.resolve()
      }

    },

    fetchElementsByFilter(searchText) {
      if (searchText && searchText != this.searchElementsFilter.searchText) {
        this.resetFilterSettings()
        this.searchElementsFilter.searchText = searchText
        this.fetchElementsTypes()
        // XeokitMediator.ElementsSelection.selectElements([])
        this.isElementsSelected = false

        this.fetchElementsCount().then(() => {
          this.fetchElements().then(() => {
            this.firstElementLoaded = true
          })
          // projectService.getElementsByFilter(this.searchElementsFilter).then((data) => {
          //   data.forEach((el, index) => this.currentElements[index].setData(el))
          //   this.firstElementLoaded = true
          // })
        })
      }
    },

    getElementsByClassTypes(types) {
      this.searchElementsFilter.bclassUuids = types
      this.fetchElementsCount().then(() => {
        this.fetchElements().then(() => {
          this.firstElementLoaded = true
        })
        // return projectService.getElementsByFilter(this.searchElementsFilter).then((data) => {
        //   data.forEach((el, index) => this.currentElements[index].setData(el))
        //   this.firstElementLoaded = true
        // })
      })
    },

    fetchElementsCount() {
      this.firstElementLoaded = false
      this.isElementsSelected = false
      // XeokitMediator.ElementsSelection.selectElements([])
      this.selectedElements.clear()
      this.currentElements = []
      return projectService.getElementsCount(this.searchElementsFilter).then((data) => {
        let counter = 0
        data.forEach(el => {
          this.selectedElements.set(el, XeokitMediator.ElementsSelection.selectedElements.includes(el))
          if (XeokitMediator.ElementsSelection.selectedElements.includes(el)) counter++
        })

        if (counter == data.length) this.isElementsSelected = true
        this.currentElements = data.map((uuid) => new SearchElementItem({ uuid: uuid }))
        this.currentElements.filter(el => XeokitMediator.ElementsSelection.selectedElements.includes(el.uuid)).
          forEach(el => {
            el.selected = true
          })
      })
    },

    fetchElementsTypes() {
      projectService.getElementsTypes(this.searchElementsFilter).then((data) => {
        this.currentElementsTypes = data.map(item => new SearchElementsType(item))
      })
    },

    setSelectedElement(value) {
      this.currentElements.find(el => el.uuid == value.uuid).selected = value.selected
      this.selectedElements.set(value.uuid, value.selected)

      let selectedElementsUuids = [...XeokitMediator.ElementsSelection.selectedElements]

      if(!selectedElementsUuids.includes(value.uuid)) selectedElementsUuids.push(value.uuid)
      else {
        const index = selectedElementsUuids.indexOf(value.uuid)
        if (index > -1) {
          selectedElementsUuids.splice(index, 1)
        }
      }

      // const selectedElements = XeokitMediator.ElementsSelection.selectedElements.concat([...this.selectedElements.entries()].filter(el => el[1] == true).map(el => el[0]))
      XeokitMediator.ElementsSelection.selectElements(selectedElementsUuids)
    },

    setSelectedElements() {
      this.isElementsSelected = !this.isElementsSelected
      if (this.isElementsSelected) {
        this.currentElements.forEach((element) => element.selected = true)
        Array.from(this.selectedElements.keys()).forEach(key => this.selectedElements.set(key, true))
        XeokitMediator.ElementsSelection.selectElements([...XeokitMediator.ElementsSelection.selectedElements, ...this.selectedElements.keys()])
      } else {
        this.currentElements.forEach((element) => element.selected = false)
        Array.from(this.selectedElements.keys()).forEach(key => this.selectedElements.set(key, false))

        // console.log(_.without(XeokitMediator.ElementsSelection.selectedElements, ...[...this.selectedElements.keys()] ))
        // XeokitMediator.ElementsSelection.selectElements([])
        XeokitMediator.ElementsSelection.selectElements(_.without(XeokitMediator.ElementsSelection.selectedElements, ...[...this.selectedElements.keys()]))
      }
    },

    hideElementsSelection() {
      this.isElementsSelected = false
      this.currentElements.forEach((element) => element.selected = false)
      Array.from(this.selectedElements.keys()).forEach(key => this.selectedElements.set(key, false))
    },

    setFilterCurrentProject() {
      this.searchElementsFilter.setProjectUuid(projectModule.state.project.uuid)
    },

    resetFilterSettings() {
      this.searchElementsFilter.resetFilter()
      this.setFilterCurrentProject()
    },

    openPickedElementDetails(uuid) {
      if (this.pickedElementUuid == null || this.pickedElementUuid != uuid) {
        this.pickedElementUuid = uuid
        XeokitMediator.ElementsSelection.pickElement(uuid)
      } else {
        XeokitMediator.ElementsSelection.pickElement(null)
        this.pickedElementUuid = null
      }
    },

    pickElementByUuid(uuid) {
      if (this.selectedElements.has(uuid)) {
        this.pickedElementUuid = uuid
      } else {
        this.pickedElementUuid = null
      }
    }
  }
})