import { Map } from "./map";

const ids = new Map({})

/*eslint-disable no-prototype-builtins*/
export class RenderState {
  constructor(cfg) {
    this.id = ids.addItem({});
    for (const key in cfg) {
      if (cfg.hasOwnProperty(key)) {
        this[key] = cfg[key];
      }
    }
  }

  /**
   Destroys this RenderState.
  */
  destroy() {
    ids.removeItem(this.id);
  }
}