import { EmphasisMaterial, Mesh, PhongMaterial, ReadableGeometry } from "@xeokit/xeokit-sdk"
import { ThreeMediator } from "@/plugins/threeJs/ThreeMediator"
import { ShapeGeometry } from "@/plugins/threeJs/fork/src/geometries/shapeGeometry"
import { fonts } from "@/plugins/threeJs/plugins/fonts/fonts"

const STATE_INHERIT = true

/*eslint-disable no-dupe-class-members*/
export class TextLabel {

  #owner; #mesh;
  #text; #color;   // axis visual settings 
  #position; #scale; // transformation parameters
  #font;                        // font object and mesh cfgs

  /**
   * Отрисовать на сцене величину измерения.
   * 
   * @param {Component} owner Scene или Node. Создаваемая ось будет закреплена за этим владельцем, уничтожение владельца повлечет уничтожение и этого компонента. Наследует свойства трансформации.
   * @param {Object} cfg Конфиг.
   * @param {Number} cfg.length Величина измерения. Возможна установка через setLength().
   * @param {Number} cfg.decimalPlaces Отображаемое количество знаков после запятой.
   * @param {String} cfg.units Единица измерения. Допускаются значения "mm", "m", "сm", "km".
   * @param {Number[]} cfg.color Цвет текста в формате [r, g, b] от 0 до 1.
   * @param {Number[]} cfg.position Смещение оси в формате [x, y, z] относительно начала координат.
   * @param {Numner[]} cfg.rotation Поворот относительно вектора [0, 1, 0] в формате [x, y, z]. Переопределяется значениями "X", "Y", "Z" в свойстве coordinateAxis.
   * @param {Number[]} cfg.scale Масштабирование оси в формате [x, y, z].
   */
  constructor(owner, cfg = {}) {
    this.#owner = owner
    this.#mesh = null
    
    this.#text = cfg.text ?? '<>'
    this.#color = cfg.color ?? [1, 1, 1]
    this.#position = cfg.position ?? [0, 0, 0]
    this.#scale = cfg.scale ?? [1, 1, 1]

    this.#font = ThreeMediator.fontLoader.parse(fonts.RobotoMediumRegular)

    this.#createLabel()
  }

  get isLabel() { return true }
  get mesh() { return this.#mesh }

  /**
   * @private Создать текст, отражающий координату по оси.
   */
  #createLabel(needPlaceholder = false) {
    const text = needPlaceholder ? '<>' : this.#text
    const scale = 0.4

    const shapes = this.#font.generateShapes( text, scale )
    const shapeGeometry = new ShapeGeometry( shapes, 4 ) 
    
    const normals = Array.from(shapeGeometry.attributes.normal.array)
    const positions = Array.from(shapeGeometry.attributes.position.array)
    const indices = Array.from(shapeGeometry.index.array)

    const xktGeometry = {
      primitive: "triangles",
      positions: positions,
      indices: indices,
      normals: normals,
      compressGeometry: true,
    }
    
    const labelGeometry = new ReadableGeometry(this.#owner, xktGeometry)

    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255] // Остаются ярко желтыми при любом освещении, кроме полностью отсутствующего
    const yellowPhong = new PhongMaterial(this.#owner, {
      diffuse: color,
      emissive: [0, 0, 0],
      ambient: [0, 0, 0],
      specular: [0, 0, 0],
      shininess: 128,
      alpha: 1
    })

    const selectedMaterial = new EmphasisMaterial(this.#owner, {
      edges: false,
      fill: true,
      fillColor: color,
      fillAlpha: 1,
    })
    
    this.#mesh = new Mesh(this.#owner, {
      geometry: labelGeometry,
      material: yellowPhong,
      selectedMaterial: selectedMaterial,
      position: this.#position,
      origin: this.#owner.origin,
      clippable: false
    })
    
    this.#mesh.isLabel = true
    this.#owner.addChild(this.#mesh, STATE_INHERIT)
  }

  /**
   * @public Отобразить вместо величины измерения текстовый заполнитель (Пример - "<> mm").
   */
  showPlaceholder() {
    const needPlaceholder = true
    this.#owner.removeChild(this.#mesh)  
    this.#mesh.destroy()  
    this.#mesh = null
    this.#createLabel(needPlaceholder)
  }

  /**
   * @public Отобразить величину измерения.
   */
  showLength() {
    this.#owner.removeChild(this.#mesh)  
    this.#mesh.destroy()  
    this.#mesh = null
    this.#createLabel()
  }

  destroy() {
    this.#mesh = null
  }
}
