<template lang="pug">
v-menu( v-model="showMenu" :position-x="x" :position-y="y" :absolute="isAbsolute" transition="slide-x-transition" offset-y :close-on-click="closeOnClick" )
  template( v-slot:activator='activator' )
    template( v-if='$scopedSlots.activator' )
      slot( name='activator' v-bind='activator' )
    
    app-icon-button( v-else-if='basic' v-on='activator.on' icon="$task-menu-icon" iconSize="16px" )
  
  .m-content 
    .m-content-body
      template( v-for="(option, idx) in menu" )
        v-divider( v-if="option.divider" light )
        .m-content-text.px-4.py-2( v-else ripple @click="handleSelect($event, option, idx)" test-id="menu-string" )
          .m-content-text--disabled( v-if="option.disabled" )
            .m-content-priority.mr-2( v-if="option.color" :style="{ backgroundColor: option.color }" )
            template( v-if="option.icon" )
              v-icon( color="#505254" ).mr-2 {{ option.icon }}
            | {{ option.title }}
          .m-content-text--active.d-flex.align-center( v-else :class="{'justify-space-between' : isActive(option)}" )
            .m-content-priority.mr-2( v-if="option.color" )
              img( :src="`/img/project/tools/${checkPriority(option)}`" )
            template( v-if="option.icon || option.iconSrc" )
              div(v-if='option.iconSrc')
                v-img.mr-2( :src='option.iconSrc' width='24' height='24' contain)
              v-icon( v-else color="#505254" ).mr-2 {{ option.icon }}
            | {{ $t(option.title) }}
            v-checkbox( v-if="isActive(option)" v-model="option.checkbox.data" @click.stop @change="handleSelected($event, option, idx)" :ripple="false" light )        
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    menu: Array,
    basic: Boolean,
    icon: String,
    item: null,
    color: String,
  },

  data: () => ({
    showMenu: false,
    moving: false,
    x: 0,
    y: 0,
    closeOnClick: true,
  }),

  computed: {
    ...mapGetters('theme', ['themeName']),

    isAbsolute() {
      return !this.basic && !this.icon
    },
  },

  watch: {
    showMenu(newValue) {
      if (!newValue && !this.moving) this.$emit('close')
    },
  },
  
  methods: {
    isActive(option) {
      return option?.checkbox?.isActive
    },

    handleSelected(selected, option) {
      option.action(selected, false)
    },

    handleSelect(event, option, idx) {
      if (!option.disabled) {
        if (option.checkbox?.isActive) {
          event.preventDefault()
          event.stopPropagation()
          option.action(option.checkbox.data, true)
        } else if (option.action) {
          option.action(event, this.item)
        } else {
          this.$emit('menu:select', option, idx, this.item)
        }
      }
    },

    show(e) {
      if (e.type == 'click') this.closeOnClick = false
      e.preventDefault()
      this.moving = true
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.moving = false
        this.showMenu = true

        if (e.type == 'click') {
          setTimeout(() => {
            this.closeOnClick = true
          }, 100)
        }
      })
    },

    close() {
      this.showMenu = false
    },

    checkPriority(option) {
      switch(option.name){
        case "LOW":
          option.title = this.$t('module.task.priority.LOW')
          return "task_priority_light_1.svg"
        case "NORMAL":
          option.title = this.$t('module.task.priority.NORMAL')
          return "task_priority_light_2.svg"
        case "HIGH":
          option.title = this.$t('module.task.priority.HIGH')
          return "task_priority_light_3.svg"
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.m-content {
  border-radius: 0px;
  background-color: #D0D0D0;
  padding: 2px;
}
.m-content-body {
  // border-radius: 5px;
  background-color: #f0f0f0;
}
.m-content-priority {
  display: inline-block;
  width: 10px;
  height: 16px;
}
.m-content-text {
  font: normal 12px/32px $roboto;
  color: var(--v-primary-base);
}
.m-content-text--disabled {
  color: var(--v-primary-lighten4);
  cursor: not-allowed;
}
.m-content-text--active {
  cursor: pointer;
}
.m-content-text:hover {
  // background: rgba(0, 0, 0, 0.2);
  background: #D0D0D0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
  margin-left: 10px;
}
::v-deep .v-input__slot {
  margin-bottom: 0;
}
::v-deep .v-input--selection-controls__input {
  margin-right: 0;
}
</style>
