import { $_app_server } from '@/_helpers'


export const RequestConfig = {
  MULTIPART: {
    headers: {'Content-Type': 'multipart/form-data'}
  },
  MERGE_PATCH: {
    headers: {'Content-Type': 'application/merge-patch+json'}
  }
}

let get = (path) => () => $_app_server.get(path).then(response => response.data)
let post = (path) => (data, config = {}) => $_app_server.post(path, data, config).then(response => response.data)
let put = (path) => (data, config = {}) => $_app_server.put(path, data, config).then(response => response.data)
let patch = (path) => (data, config = {}) => $_app_server.patch(path, data, config).then(response => response.data)
let del = (path) => () => $_app_server.delete(path).then(response => response.data)

let handler = {
  get (target, prop) {
    let path = target.path || ''
    
    if (prop === 'path') return path
    else if (prop == 'get') {
      return get(path)
    }
    else if (prop == 'post') {
      return post(path)
    }
    else if (prop == 'put') {
      return put(path)
    }
    else if (prop == 'patch') {
      return patch(path)
    }
    else if (prop == 'delete') {
      return del(path)
    }
    else {
      let next = { path: `${path}/${prop}`}
      return new Proxy(next, handler)
    }
  }
}

export const api = new Proxy({}, handler)
