import { useDrawingsStore } from "@/pinia"
import { useDrawingPageStore } from "@/pinia"
import { scaleSelectedPage, setPageListeners } from "@/components/drawings/facades/drawingPageListeners.facade"
import "@/components/drawings/page/pageFit.scss"

let resizeObserver = null

export const imageHeight = () => useDrawingPageStore().imageHeight
export const imageWidth = () => useDrawingPageStore().imageWidth 

export function startPageLoading(page) {
  const drawingsStore = useDrawingsStore() // deprecated
  const store = useDrawingPageStore()

  const drawingPageImage = document.getElementById("drawingPageImage")

  if (drawingsStore.pageIsLoading) { return } // deprecated
  drawingsStore.pageIsLoading = true // deprecated
  
  return new Promise(resolve => {
    drawingsStore.loadPage(page.uuid).then(pageSrc => { // deprecated
      const rawSvg = new DOMParser().parseFromString(pageSrc, 'application/xml')
      const rawSvgWidth = page.width || rawSvg.children[0].children[1].children[1].children[0].width.animVal.value
      const rawSvgHeight = page.height || rawSvg.children[0].children[1].children[1].children[0].height.animVal.value
      const preparedSvg = pageSrc.replace('xmlns:xlink="http://www.w3.org/1999/xlink"', `xmlns:xlink="http://www.w3.org/1999/xlink" width="${rawSvgWidth}" height="${rawSvgHeight}"`)
      const preparedSrc = 'data:image/svg+xml;base64,' + btoa(preparedSvg)
      
      store.imageSrc = preparedSrc
      drawingsStore.preparedSrc = preparedSrc // deprecated
      drawingPageImage.src = preparedSrc

      drawingPageImage.onload = () => {
        store.originalHeight = rawSvgHeight
        store.originalWidth = rawSvgWidth
        store.imageHeight = rawSvgHeight
        store.imageWidth = rawSvgWidth
        drawingsStore.pageIsLoading = false

        fitPage()
        setPageListeners()

        resolve()
      }
    })
  })
}

export function setCroppingMode(value) {
	useDrawingsStore().isCroppingModeActive = value
}

export function toggleCroppingMode() {
  const toggled = !useDrawingsStore().isCroppingModeActive // deprecated
	useDrawingsStore().isCroppingModeActive = toggled // deprecated
}

export function toggleCommentMode() {
  const toggled = !useDrawingsStore().commentMode // deprecated
  useDrawingsStore().commentMode = toggled // deprecated
}

export function toggleFitMode() {
	if (useDrawingsStore().pageIsFittingSize) { // deprecated
    unfitPage()
  }
  else fitPage()
}

export function fitPage() {
  useDrawingsStore().pageIsFittingSize = true // deprecated

  const image = document.getElementById("drawingPageImage")
  const layout = document.getElementById("drawingImageLayout") // Так ли это обязательно? попробовать закинуть свойства по умолчанию
  
  setFitStyle(layout)
  setFitStyle(image)
  
  resizeObserver = new ResizeObserver(() => { // TODO: ускорить
    setTimeout(() => {
      const boundings = image.getBoundingClientRect()
      useDrawingPageStore().imageHeight = boundings.height
      useDrawingPageStore().imageWidth = boundings.width
    })
  })
  resizeObserver.observe(image)
}

export function unfitPage() {
  useDrawingsStore().pageIsFittingSize = false // deprecated
  resizeObserver.disconnect()
  
  const image = document.getElementById("drawingPageImage")
  const layout = document.getElementById("drawingImageLayout")

  setUnfitStyle(image)
  setUnfitStyle(layout)

  scaleSelectedPage()
}

function setFitStyle(el) {
  el.classList.add('page-fit')
  el.style.maxWidth = useDrawingPageStore().originalWidth + 'px'
  el.style.transformOrigin = '' 
  el.style.transform = ``
}

function setUnfitStyle(el) {
  el.classList.remove('page-fit')
  el.style.maxWidth = ''
}