import { defineStore } from 'pinia'

export const ProjectTool = Object.freeze({
  NONE: 'DEFAULT',
  TREE: 'elementTree',
  TASKS: 'task',
  PROCESSES: 'process',
  NOTIFICATIONS: 'notification',
  PROFILE: 'profileSettings',
})

export const useProjectToolsStore = defineStore('projectTools', {
  state: () => {
    return {
      activeProjectTool: ProjectTool.NONE,
    }
  },
})