import { api } from '@/api'

export default {
  namespaced: true,

  state: () => ({
    reservedMap: {},
    maps: [],
    dangerZones: [],

    e: [1, 1, 1],
    b: [],
  }),


  mutations: {
    getMaps(state, payload) {
      state.maps = payload
    },

    getReservedMap(state, payload) {
      state.reservedMap = payload
    },

    getReportsDangerZone(state, payload) {
      state.dangerZones = payload
    },
  },

  actions: {
    getMaps({ commit }) {
      api.locationControl.getMaps().then((maps) => {
        commit('getMaps', maps.items)
      })
    },

    getReservedMap({ commit }) {
      api.locationControl.getReservedMap().then((reservedMap) => {
        commit('getReservedMap', reservedMap)
      })
    },

    getReportsDangerZone({ commit }) {
      api.locationControl.getReportsDangerZone().then((dangerZones) => {
        commit('getReportsDangerZone', dangerZones)
      })
    },

    getAreasForMap() {
      return api.locationControl.getAreasForMap()
    },

    getAnchorsForMap() {
      return api.locationControl.getAnchorsForMap()
    },

    getKeepers() {
      return api.locationControl.getKeepers()
    },
  },
}
