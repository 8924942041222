import { math, Mesh, ReadableGeometry, PhongMaterial, EdgeMaterial, buildBoxGeometry, buildSphereGeometry, EmphasisMaterial } from "@xeokit/xeokit-sdk";
import { XeokitMediator } from "../../XeokitMediator";
import { geometry } from "./geometry";

export const drawer = {

  createSurfaceMesh(trianglesPositions, color) {
    const scene = XeokitMediator.viewer.scene
    const origin = trianglesPositions[0]

    const positions = []
    trianglesPositions?.forEach((pos) => {
      let newPos = geometry.math.subVec3(pos, origin)
      positions.push(newPos[0], newPos[1], newPos[2])
    })
    const normals = new Array(positions.length).fill(1.0)
    const indices = []
    for (let i = 0; i < positions.length / 3; i++) {
      indices.push(i)
    }
    for (let i = positions.length / 3 - 1; i >= 0; i--) {
      indices.push(i)
    }

    const geom = new ReadableGeometry(scene, {
      primitive: 'triangles',
      positions: positions,
      normals: normals,
      indices: indices, // Сзади и спереди
      compressGeometry: true,
    })

    const highlightMaterial = new EmphasisMaterial(scene, {
      edges: false,
      fill: true,
      fillColor: color,
      fillAlpha: 0.6,
    })

    const material = new PhongMaterial(scene)

    const surface = new (function () {
      const surfaceMesh = new Mesh(scene, {
        geometry: geom,
        material: material,
        visible: true,
        pickable: false,
        origin: origin,
        highlightMaterial: highlightMaterial,
        highlighted: true,
      })

      this.destroy = function () {
        surfaceMesh.destroy()
      }
    })()

    return surface
  },

  drawEdge(edge) {
    return XeokitMediator.SceneObjects.createSegmentMesh({
      segmentPositions: edge
    })
  },

  drawCubeEdges(cubeVertices) {
    const edges = [
      [cubeVertices[0], cubeVertices[1]],
      [cubeVertices[1], cubeVertices[2]],
      [cubeVertices[2], cubeVertices[3]],
      [cubeVertices[3], cubeVertices[0]],
      [cubeVertices[4], cubeVertices[5]],
      [cubeVertices[5], cubeVertices[6]],
      [cubeVertices[6], cubeVertices[7]],
      [cubeVertices[7], cubeVertices[4]],
      [cubeVertices[0], cubeVertices[4]],
      [cubeVertices[1], cubeVertices[5]],
      [cubeVertices[2], cubeVertices[6]],
      [cubeVertices[3], cubeVertices[7]]
    ];

    edges.forEach(edge => {
      this.drawEdge(edge[0], edge[1]);
    });
  },

  drawPoint(xyz, color = [1, 0, 1]) {
    new Mesh(XeokitMediator.viewer.scene, {
      geometry: new ReadableGeometry(XeokitMediator.viewer.scene, buildSphereGeometry({
        center: xyz || [0, 0, 0],
        radius: 0.05 || math.getAABB3Diag([...XeokitMediator.viewer.scene.camera.eye, ...math.getAABB3Center(XeokitMediator.viewer.scene.aabb)]) / 100,
        heightSegments: 20,
        widthSegments: 20
      })),

      material: new PhongMaterial(XeokitMediator.viewer.scene, {
        diffuse: color
      }),

      clippable: false,
      collidable: false
    })
  },

  drawMeshTriangles(mesh) {
    let trianglesCoords = geometry.extraction.getFacesVerticeByEntity(mesh)
    for (let i = 0; i < trianglesCoords.length - 2; i+=3) {
      let t1 = trianglesCoords[i]
      let t2 = trianglesCoords[(i + 1) % trianglesCoords.length] 
      let t3 = trianglesCoords[(i + 2) % trianglesCoords.length]

      var rayHelper = new (function () {

        var ray = new Mesh(XeokitMediator.viewer.scene, {
          geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
            primitive: "lines",
            positions: [0, 0, 0, 0, 0, 0],
            indices: [0, 1]
          }),
          material: new PhongMaterial(XeokitMediator.viewer.scene, {
            emissive: [1, 0.3, 0.3],
            diffuse: [0, 0, 0],
            ambient: [0, 0, 0],
            lineWidth: 2
          }),
          pickable: false
        });

        var rayPositions = new Float32Array(6);

        this.setRay = function (pos, dir) {

          rayPositions[0] = pos[0]; // Origin
          rayPositions[1] = pos[1];
          rayPositions[2] = pos[2];
          rayPositions[3] = dir[0]; // Direction
          rayPositions[4] = dir[1];
          rayPositions[5] = dir[2];

          ray.geometry.positions = rayPositions;
        };

        this.setHighlighted = function (highlighted) {
          ray.material.emissive = highlighted ? [0.5, 1.0, 0.5] : [0.5, 0.3, 0.3];
          ray.material.lineWidth = highlighted ? 6 : 2;
        };
      })();
      var rayHelper1 = new (function () {

        var ray = new Mesh(XeokitMediator.viewer.scene, {
          geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
            primitive: "lines",
            positions: [0, 0, 0, 0, 0, 0],
            indices: [0, 1]
          }),
          material: new PhongMaterial(XeokitMediator.viewer.scene, {
            emissive: [1, 0.3, 0.3],
            diffuse: [0, 0, 0],
            ambient: [0, 0, 0],
            lineWidth: 2
          }),
          pickable: false
        });

        var rayPositions = new Float32Array(6);

        this.setRay = function (pos, dir) {

          rayPositions[0] = pos[0]; // Origin
          rayPositions[1] = pos[1];
          rayPositions[2] = pos[2];
          rayPositions[3] = dir[0]; // Direction
          rayPositions[4] = dir[1];
          rayPositions[5] = dir[2];

          ray.geometry.positions = rayPositions;
        };

        this.setHighlighted = function (highlighted) {
          ray.material.emissive = highlighted ? [0.5, 1.0, 0.5] : [0.5, 0.3, 0.3];
          ray.material.lineWidth = highlighted ? 6 : 2;
        };
      })();
      var rayHelper2 = new (function () {

        var ray = new Mesh(XeokitMediator.viewer.scene, {
          geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
            primitive: "lines",
            positions: [0, 0, 0, 0, 0, 0],
            indices: [0, 1]
          }),
          material: new PhongMaterial(XeokitMediator.viewer.scene, {
            emissive: [1, 0.3, 0.3],
            diffuse: [0, 0, 0],
            ambient: [0, 0, 0],
            lineWidth: 2
          }),
          pickable: false
        });

        var rayPositions = new Float32Array(6);

        this.setRay = function (pos, dir) {

          rayPositions[0] = pos[0]; // Origin
          rayPositions[1] = pos[1];
          rayPositions[2] = pos[2];
          rayPositions[3] = dir[0]; // Direction
          rayPositions[4] = dir[1];
          rayPositions[5] = dir[2];

          ray.geometry.positions = rayPositions;
        };

        this.setHighlighted = function (highlighted) {
          ray.material.emissive = highlighted ? [0.5, 1.0, 0.5] : [0.5, 0.3, 0.3];
          ray.material.lineWidth = highlighted ? 6 : 2;
        };
      })();

      //------------------------------------------------------------------------------------------------------------------
      // Dynamically pick surfaces of model with the ray, show ray with helper, highlight picked meshes
      //------------------------------------------------------------------------------------------------------------------

      var rayOrigin = new Float32Array([t1[0], t1[1], t1[2]]);
      var rayDirection = new Float32Array([t2[0], t2[1], t2[2]]);
      rayHelper.setRay(rayOrigin, rayDirection);
      rayHelper.setHighlighted(true);
      rayOrigin = new Float32Array([t2[0], t2[1], t2[2]]);
      rayDirection = new Float32Array([t3[0], t3[1], t3[2]]);
      rayHelper1.setRay(rayOrigin, rayDirection);
      rayHelper1.setHighlighted(true);
      rayOrigin = new Float32Array([t3[0], t3[1], t3[2]]);
      rayDirection = new Float32Array([t1[0], t1[1], t1[2]]);
      rayHelper2.setRay(rayOrigin, rayDirection);
      rayHelper2.setHighlighted(true);
    }
  },

  viewAABBs(aabbs) {
    const _getLengthByCoords = (min, max) => {
      return Math.sqrt(Math.pow(max - min, 2))
    }

    for (let i = 0; i < aabbs.length; i++) {
      const [xmin, ymin, zmin, xmax, ymax, zmax] = aabbs[i]

      let DA = _getLengthByCoords(xmin, xmax) / 2 || 0.005
      let DB = _getLengthByCoords(ymin, ymax) / 2 || 0.005
      let DC = _getLengthByCoords(zmin, zmax) / 2 || 0.005

      new Mesh(XeokitMediator.viewer.scene, {
        geometry: new ReadableGeometry(XeokitMediator.viewer.scene, buildBoxGeometry({
          xSize: DA, ySize: DB, zSize: DC,
          center: math.getAABB3Center(aabbs[i]),
        })),

        clippable: false,

        material: new PhongMaterial(XeokitMediator.viewer.scene, {
          diffuse: [0.4, 0.4, 1.0],
          ambient: [0.9, 0.3, 0.9],
          shininess: 30,
          alpha: .2,
          alphaMode: "blend",
        }),

        edgeMaterial: new EdgeMaterial(XeokitMediator.viewer.scene, {
          edgeColor: [0.23137254901960785, 0.5764705882352941, 0.6862745098039216],
          edgeAlpha: 1.0,
          edgeWidth: 1,
        }),

        edges: true,
        pickable: false,
        collidable: false
      })
    }
  },

  fillTriangle(polygon, color) {
    const origin = polygon[0]

    const p1 = math.vec3()
    const p2 = math.vec3()
    const p3 = math.vec3()

    math.subVec3(polygon[0], origin, p1)
    math.subVec3(polygon[1], origin, p2)
    math.subVec3(polygon[2], origin, p3)

    const triangleMesh = new (function () {
      const triangle = new Mesh(XeokitMediator.viewer.scene, {
        geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
          primitive: 'triangles',
          positions: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          normals: [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0],
          indices: [2, 1, 0, 0, 1, 2], // Сзади и спереди
          compressGeometry: true,
        }),
        material: new PhongMaterial(XeokitMediator.viewer.scene),
        visible: true,
        pickable: false,
        origin: origin,
        //opacity: 0.5,
        highlightMaterial: new EmphasisMaterial(XeokitMediator.viewer.scene, {
          edges: false,
          fill: true,
          fillColor: color,
          fillAlpha: 0.6,
        }),
        highlighted: true,
      })

      let trianglePositions = new Float64Array(9)

      this.setTriangle = function (point1, point2, point3) {
        this.p1 = point1
        this.p2 = point2
        this.p3 = point3

        trianglePositions[0] = p1[0]
        trianglePositions[1] = p1[1]
        trianglePositions[2] = p1[2]
        trianglePositions[3] = p2[0]
        trianglePositions[4] = p2[1]
        trianglePositions[5] = p2[2]
        trianglePositions[6] = p3[0]
        trianglePositions[7] = p3[1]
        trianglePositions[8] = p3[2]

        triangle.geometry.positions = trianglePositions
      }

      this.destroy = function () {
        triangle.destroy()
      }
    })()

    const point1 = new Float64Array([p1[0], p1[1], p1[2]])
    const point2 = new Float64Array([p2[0], p2[1], p2[2]])
    const point3 = new Float64Array([p3[0], p3[1], p3[2]])

    triangleMesh.setTriangle(point1, point2, point3)

    return triangleMesh
  },
}