import { FontLoader } from "@/plugins/threeJs/fork/examples/jsm/loaders/fontLoader";
import { fonts } from "@/plugins/threeJs/plugins/fonts/fonts";
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";

export const quadrant = Object.freeze({
  rightTop: 'right-top',
  leftTop: 'left-top',
  leftBottom: 'left-bottom',
  rightBottom: 'right-bottom'
})

export function getContrastBlackWhiteColor() {
  const measurementScrim = document.getElementById("measurementScrim")
  const hexcolor = measurementScrim.style.backgroundColor
  const temp1 = hexcolor.substring(4)
  const temp2 = temp1.substring(0, temp1.length - 1)
  const colorArr = temp2.split(',')
  const r = parseInt(colorArr[0]);
  const g = parseInt(colorArr[1]);
  const b = parseInt(colorArr[2]);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return (yiq >= 128) ? [0.3, 0.3, 0.3] : [0.8, 0.8, 0.8];
}

export function createFont() {
  const fontLoader = new FontLoader()
  return fontLoader.parse(fonts.RobotoMediumRegular)
}

export function setGridsAndAxesCulledByAabb(aabb, grids) {
  const minZ = aabb[2]
  const maxZ = aabb[5]
  const minX = aabb[0]
  const maxX = aabb[3]
  const minY = aabb[1]
  const maxY = aabb[4]
  const centerZ = (minZ + maxZ) / 2
  const centerX = (minX + maxX) / 2
  const centerY = (minY + maxY) / 2
  const aabbCenter = [centerX, centerY, centerZ]

  // Сначала искать расстояния до аабб каждой сетки (по z)
  // Записать пересеченные и ближайшие
  // Затем искать расстояния до аабб каждой оси
  // Отрисовать пересеченные и ближайшие

  //#region Поиск пересеченных сеток и первых следующих за ними
  const gridsBuffer = {}
  const relevantGrids = {}

  Object.keys(grids).forEach(uuid => {
    const grid = grids[uuid]

    gridsBuffer[grid.uuid] = {
      intersected: grid.zCoord < maxZ && grid.zCoord > minZ,
      higher: grid.zCoord > maxZ,
      dist: Math.abs(grid.zCoord - centerZ)
    }
  })

  // let nearestTopDist = Number.MAX_VALUE
  // let nearestTopUuid = null
  // let nearestBottomDist = Number.MAX_VALUE
  // let nearestBottomUuid = null
  Object.keys(gridsBuffer).forEach(uuid => {
    if (gridsBuffer[uuid].intersected) {
      relevantGrids[uuid] = grids[uuid]
    }
  //   else {
  //     const grid = gridsBuffer[uuid]
  //     if (grid.higher && (grid.dist < nearestTopDist)) {
  //       nearestTopDist = grid.dist
  //       nearestTopUuid = uuid
  //     }
  //     else if (!grid.higher && (grid.dist < nearestBottomDist)) {
  //       nearestBottomDist = grid.dist
  //       nearestBottomUuid = uuid
  //     }
  //   }
  })

  // if (nearestTopUuid) relevantGrids[nearestTopUuid] = grids[nearestTopUuid]
  // if (nearestBottomUuid) relevantGrids[nearestBottomUuid] = grids[nearestBottomUuid]
  
  // Скрыть все сетки, затем показать подходящие
  Object.keys(grids).forEach(uuid => {
    grids[uuid].setCulled(true) 
  })
  Object.keys(relevantGrids).forEach(uuid => {
    relevantGrids[uuid].setCulled(false) 
  })

  //#endregion

  //#region Поиск осей, подходящих для отображения

  const axesOfRelevantGrids = {}
  const axesBuffer = {}
  const relevantAxes = {}

  Object.keys(relevantGrids).forEach(uuid => {
    Object.assign(axesOfRelevantGrids, relevantGrids[uuid].axes)
  })

  const extendedAabb = [...aabb]
  // extendedAabb[2] = -Number.MAX_VALUE
  // extendedAabb[5] = Number.MAX_VALUE

  Object.keys(axesOfRelevantGrids).forEach(uuid => {
    const axis = axesOfRelevantGrids[uuid]
    const p1 = axis.p1
    const rayDir = axis.rayDir

    const intersected = geometry.intersection.isLineIntersectsAabb(p1, rayDir, extendedAabb)
    const { segmentPoint, dist } = geometry.intersection.pointLineDistance3D(aabbCenter, p1, rayDir, true, true)
    
    const segmentX = segmentPoint[0]
    const segmentY = segmentPoint[1]

    const quadrant = (segmentX > centerX ? 'right-' : 'left-') + (segmentY > centerY ? 'top' : 'bottom')

    axesBuffer[axis.uuid] = {
      intersected: intersected,
      dist: dist,
      segmentPoint: segmentPoint,
      quadrant: quadrant
    }
  })


  Object.keys(relevantGrids).forEach(uuid => {
    let nearestRightTopDist = Number.MAX_VALUE
    let nearestLeftTopDist = Number.MAX_VALUE
    let nearestLeftBottomDist = Number.MAX_VALUE
    let nearestRightBottomDist = Number.MAX_VALUE

    let nearestRightTopUuid = null
    let nearestLeftTopUuid = null
    let nearestLeftBottomUuid = null
    let nearestRightBottomUuid = null

    Object.keys(relevantGrids[uuid].axes).forEach(uuid => {
      const axis = axesBuffer[uuid]
      if (axis.intersected) {
        relevantAxes[uuid] = axesOfRelevantGrids[uuid]
      }
      else {
        if (axis.quadrant == quadrant.rightTop && (axis.dist < nearestRightTopDist)) {
          nearestRightTopDist = axis.dist
          nearestRightTopUuid = uuid
        }
        else if (axis.quadrant == quadrant.leftTop && (axis.dist < nearestLeftTopDist)) {
          nearestLeftTopDist = axis.dist
          nearestLeftTopUuid = uuid
        }
        else if (axis.quadrant == quadrant.leftBottom && (axis.dist < nearestLeftBottomDist)) {
          nearestLeftBottomDist = axis.dist
          nearestLeftBottomUuid = uuid
        }
        else if (axis.quadrant == quadrant.rightBottom && (axis.dist < nearestRightBottomDist)) {
          nearestRightBottomDist = axis.dist
          nearestRightBottomUuid = uuid
        }
      }
    })

    if (nearestRightTopUuid) relevantAxes[nearestRightTopUuid] = axesOfRelevantGrids[nearestRightTopUuid]
    if (nearestLeftTopUuid) relevantAxes[nearestLeftTopUuid] = axesOfRelevantGrids[nearestLeftTopUuid]
    if (nearestLeftBottomUuid) relevantAxes[nearestLeftBottomUuid] = axesOfRelevantGrids[nearestLeftBottomUuid]
    if (nearestRightBottomUuid) relevantAxes[nearestRightBottomUuid] = axesOfRelevantGrids[nearestRightBottomUuid]
  })

  Object.keys(axesOfRelevantGrids).forEach(uuid => {
    axesOfRelevantGrids[uuid].setCulled(true)
  })
  Object.keys(relevantAxes).forEach(uuid => {
    relevantAxes[uuid].setCulled(false)
  })

  //#endregion
}