<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 48.00 48.00"
  >
    <rect
      fill="currentColor"
      x="-2.01"
      y="-1.99"
      transform="translate(8.02,16.01) rotate(1.5)"
      width="4.02"
      height="3.98"
      rx="1.18"
    />
    <rect
      fill="currentColor"
      x="-13.97"
      y="-1.89"
      transform="translate(27.98,15.97) rotate(0.1)"
      width="27.94"
      height="3.78"
      rx="0.89"
    />
    <rect
      fill="currentColor"
      x="-2.05"
      y="-1.99"
      transform="translate(8.05,24.01) rotate(1.1)"
      width="4.10"
      height="3.98"
      rx="1.26"
    />
    <rect
      fill="currentColor"
      x="14.03"
      y="22.13"
      width="27.94"
      height="3.78"
      rx="0.85"
    />
    <rect
      fill="currentColor"
      x="5.99"
      y="30.03"
      width="4.12"
      height="3.94"
      rx="1.21"
    />
    <rect
      fill="currentColor"
      x="14.00"
      y="30.09"
      width="27.98"
      height="3.86"
      rx="0.86"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>