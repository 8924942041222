import { $_app_server } from '@/_helpers'

export const longActionResultService = {
  getExportResultByProcess,
  downloadExportFile
}
function getExportResultByProcess (uuid) {
  return $_app_server.get(`/longactionresult/byprocess/${uuid}`).then(response => response.data)
}
function downloadExportFile(res){
  return $_app_server.get(`longactionresult/${res.uuid}/file`, { responseType: 'blob' }).then(response => response.data)
}