import { api } from "@/api"
import { AlertService } from '@/assets/app/AlertService'

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

const getDefaultState = () => {
  return {
    bind : null,
    forms: {
      list: [],
      status: status.INITIAL
    },
    form: {
      item: null,
      status: status.INITIAL
    },
    formTypes: {
      list: [],
      status: status.INITIAL
    },
    fieldTypes: {
      list: [],
      status: status.INITIAL
    },
    dictionaries: {
      list: [],
      status: status.INITIAL
    },
    dictionary: {
      item: null,
      status: status.INITIAL
    },
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    dictionaries: ({ dictionaries }) => dictionaries.list,
    isDictionariesLoaded: ({ dictionaries }) => dictionaries.status == status.LOADED,

    forms: ({ forms }) => forms.list,
    isFormsLoaded: ({ forms }) => forms.status == status.LOADED,

    form: ({ form }) => form.item,
    isFormLoaded: ({ form }) => form.status == status.LOADED,

    formTypes: ({ formTypes }) => formTypes.list,
    isFormTypesLoaded: ({ formTypes }) => formTypes.status == status.LOADED,

    fieldTypes: ({ fieldTypes }) => fieldTypes.list,
    isFieldTypesLoaded: ({ fieldTypes }) => fieldTypes.status == status.LOADED,

    dictionary: ({ dictionary }) => dictionary.item,
    isDictionaryLoaded: ({ dictionary }) => dictionary.status == status.LOADED,

  },

  mutations: {
    resetState (state) { 
      const formTypes = state.formTypes
      Object.assign(state, getDefaultState())
      state.formTypes = formTypes  
    },
    
    setBind: (state, bind) => {
      state.bind = bind
    },

    setDictionaries: (state, list) => {
      state.dictionaries.list = list
    },
    setDictionariesStatus: (state, status) => {
      state.dictionaries.status = status
    },

    updateDictionary: ({ dictionaries }, dict) => {
      let exist = false
      if(dictionaries.list.length > 0) { 
        let curr = dictionaries.list.find(item => item.uuid === dict.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, dict)
        }
      } 
      if(!exist) dictionaries.list.push(dict)
    },

    deleteDictionary: ({ dictionaries }, id) => {
      let index = dictionaries.list.findIndex(item => item.uuid === id)
      dictionaries.list.splice(index, 1)
    },

    setDictionary: (state, dict) => {
      state.dictionary.item = dict
    },
    setDictionaryStatus: (state, status) => {
      state.dictionary.status = status
    },

    updateWord: ({ dictionary }, word) => {
      let exist = false
      if(dictionary.item.words.length > 0) { 
        let curr = dictionary.item.words.find(item => (item.uuid === word.uuid || item.uuid === null))
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, word)
        }
      } 
      if(!exist) dictionary.item.words.push(word)
    },

    deleteWord: ({ dictionary }, id) => {
      let index = dictionary.item.words.findIndex(item => item.uuid === id)
      dictionary.item.words.splice(index, 1)
    },

    setForms: (state, list) => { state.forms.list = list },
    setFormsStatus: (state, status) => { state.forms.status = status },

    setFormTypes: (state, list) => { state.formTypes.list = list},
    setFormTypesStatus: (state, status) => { state.formTypes.status = status },

    setFieldTypes: (state, list) => { state.fieldTypes.list = list },
    setFieldTypesStatus: (state, status) => { state.fieldTypes.status = status },

    updateForm: ({ forms }, form ) => {
      let exist = false
      if(forms.list.length > 0) { 
        let curr = forms.list.find(item => item.uuid === form.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, form)
        }
      } 
      if(!exist) forms.list.push(form) 
    },

    deleteForm: ({ forms }, id) => {
      let index = forms.list.findIndex(item => item.uuid === id)
      forms.list.splice(index, 1)
    },

    setForm: (state, form) => {
      state.form.item = form
    },
    setFormStatus: (state, status) => {
      state.form.status = status
    },

    updateField: ({ form }, field) => {
      let exist = false
      if(form.item.fields.length > 0) { 
        let curr = form.item.fields.find(item => (item.uuid === field.uuid || item.uuid === null))
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, field)
        }
      } 
      if(!exist) form.item.fields.push(field)
    },

    deleteField: ({ form }, id) => {
      let index = form.item.fields.findIndex(item => item.uuid === id)
      form.item.fields.splice(index, 1)
    },

  },

  actions: {
    INIT: ({ dispatch }, { bind }) => {
      dispatch("LOAD_FORMS", bind)
      dispatch("LOAD_FORMTYPES")
      dispatch("LOAD_FIELDTYPES")
    },

    LOAD_FORMTYPES: ({ state, commit }) => {
      if (state.formTypes.list.length === 0){
        if (state.formTypes.status == status.INITIAL ) {
          commit('setFormTypesStatus', status.LOADING)
          api.forms.formTypes().then(data => {
            commit('setFormTypes', data)
            commit('setFormTypesStatus', status.LOADED)
          })
        } 
      }
    },

    LOAD_FIELDTYPES: ({ state, commit }) => {
      if (state.fieldTypes.status == status.INITIAL ) {
        commit('setFieldTypesStatus', status.LOADING)
        api.forms.fieldTypes().then(data => {
          commit('setFieldTypes', data)
          commit('setFieldTypesStatus', status.LOADED)
        })
      } 
    },

    LOAD_FORMS: ({ state, commit }, bind) => {
      if (state.forms.status == status.INITIAL || state.bind != bind) {
        commit('setFormsStatus', status.LOADING)
        api.forms.formsListByBind(bind).then(data => {
          commit('setBind', bind)
          commit('setForms', data)
          commit('setFormsStatus', status.LOADED)
        })
      } 
    },

    LOAD_DICTIONARIES: ({ commit }, { bind }) => {
      commit('setDictionariesStatus', status.LOADING)
      api.forms.dictionaryListByBind(bind).then(data => {
        commit('setDictionaries', data)
        commit('setDictionariesStatus', status.LOADED)
      })
    },

    LOAD_FORM: ({ dispatch, commit },  { formId }) => {
      dispatch("LOAD_FIELDTYPES")
      commit('setFormStatus', status.LOADING)
      api.forms.form(formId).then(data => {
        commit('setForm', data)  
        commit('updateForm',  data)      
        commit('setFormStatus', status.LOADED)
      })
    },

    LOAD_DICTIONARY: ({ commit },  { dictionaryId }) => {
      commit('setDictionaryStatus', status.LOADING)
      api.forms.dictionary(dictionaryId).then(data => {
        commit('setDictionary', data)
        commit('setDictionaryStatus', status.LOADED)
      })
    },

    gotoDictionary: ({ commit }) => {
        commit('setDictionaryStatus', status.INITIAL)
    },

    saveDictionary: ({ commit }, obj) => {
      api.forms.saveDictionary(obj).then(data => {
        commit('updateDictionary', data)
        if(obj.uuid == null)
          commit('setDictionary', data)
      })
    },

    deleteDictionary: ({ commit }, id) => {
      api.forms.deleteDictionary(id).then(() => {
        commit('deleteDictionary', id)
      })
    },

    saveWord: ({ commit }, obj) => {
      api.forms.saveWord(obj).then(data => {
        commit('updateWord', data)
      })
    },

    deleteWord: ({ commit }, id) => {
      api.forms.deleteWord(id).then(() => {
        commit('deleteWord', id)
      })
    },

    gotoForm: ({ commit }) => {
        commit('setFormStatus', status.INITIAL)
    },

    saveForm: ({ commit }, obj) => {
      if(obj.name){
        api.forms.saveForm(obj).then(data => {
          commit('updateForm',  data)
          if(obj.uuid == null)
            commit('setForm', data)
        })
      }
    },

    deleteForm: ({ commit }, id) => {
      api.forms.deleteForm(id).then(() => {
        commit('deleteForm', id)
      })
    },

    saveField: ({ commit }, obj) => {
      api.forms.saveField(obj).then(data => {
        commit('updateField', data)
      })
    },

    deleteField: ({ commit }, id) => {
        api.forms.deleteField(id).then(() => {
          commit('deleteField', id)
        }).catch(() => {
          AlertService.error({ data: { error_description: "Ошибка удаления поля" } })
        })

    }, 

    loadFormForPlugin: ({ commit }, projectId) => {
      api.forms.formsListByBind(projectId).then(data => {
        commit('setBind', projectId)
        commit('setForms', data)
      })
    }
  }
}