<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 27L5 21.25V9.75L15 4L25 9.75V15.5" stroke="currentColor" stroke-dasharray="2 2"/>
    <path d="M21.5272 27C18.7141 27 16.075 25.1552 15 22.4719C16.0831 19.7682 18.6785 18 21.5 18C24.3215 18 26.9169 19.7682 28 22.4719C26.9365 25.1266 24.3181 27 21.5272 27ZM21.5272 25.875C24.2469 25.875 26.0771 24.1796 26.9665 22.5C25.9343 20.5506 24.2469 19.125 21.5024 19.125C18.8075 19.125 17.0333 20.6856 16.0879 22.4711C16.9831 24.1617 18.8075 25.875 21.5272 25.875ZM21.4992 24.0609C21.9258 24.0609 22.2887 23.9065 22.5879 23.5977C22.887 23.2889 23.0366 22.9139 23.0366 22.4727C23.0366 22.0315 22.8873 21.6562 22.5887 21.3469C22.29 21.0375 21.9274 20.8828 21.5008 20.8828C21.0742 20.8828 20.7113 21.0372 20.4121 21.3461C20.113 21.6549 19.9634 22.0299 19.9634 22.4711C19.9634 22.9122 20.1127 23.2875 20.4113 23.5969C20.71 23.9062 21.0726 24.0609 21.4992 24.0609Z" fill="currentColor"/>
    <path d="M5 10L15 16L25 10" stroke="currentColor" stroke-dasharray="2 2"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>