export default {
  namespaced: true,

  state: {
    selectedElement: null,
    highlightedElementGlobalIDs: new Set(),
  },

  // # Getters

  getters: {
    isElementHighlighted: state => ({ globalId }) => state.highlightedElementGlobalIDs.has(globalId)
  },

  // # Mutations

  mutations: {
    setElementSelected (state, element) {
      if (state.selectedElement) state.selectedElement.selected = false
      if (element) element.selected = true
      state.selectedElement = element
    },

    highlightElement (state, { globalId }) {
      globalId && state.highlightedElementGlobalIDs.add(globalId)
    },
    
    unhighlightElement (state, { globalId }) {
      globalId && state.highlightedElementGlobalIDs.delete(globalId)
    },
  },

  // # Actions

  actions: {
    toggleHighlightingElement ({ getters, commit }, element) {
      commit({
        type: getters.isElementHighlighted(element) ? 'unhighlightElement' : 'highlightElement',
        globalId: element.globalId
      })
    },

    selectElement ({ commit }, element) {
      commit('setElementSelected', element)
    },
  }
}