<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" fill="white" stroke="#F0F0F0"/>
    <path d="M4.53128 7.28571L12.5156 2.58037L20.5 7.28571V16.7143L12.5156 21.4196L4.53128 16.7143V7.28571Z" stroke="#B5B5B5"/>
    <path d="M3.3125 6.375L12 11.6252" stroke="#303030"/>
    <path d="M20 7.5L12.5313 12L12.5313 21" stroke="#B5B5B5"/>
    <rect x="11.5" y="11.5" width="2" height="2" fill="#FF0000" stroke="#303030"/>
    <rect x="3.5" y="5.5" width="1.96875" height="2" fill="white" stroke="#303030"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>