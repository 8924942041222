import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { useDrawingCroppingStore, useImageSectionPlanesStore } from '@/pinia';
import store from '@/store';
import { FloorBoundsCache } from '../floors/floorBoundsCache';
import { AnchorPin } from './pins/anchorPin';

/*eslint-disable no-dupe-class-members*/
export class PickImagePlane {

  static #_onInputMouseClick = null
  static #firstAnchorPin = null
  static #secondAnchorPin = null

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get #_imagePlaneStore() {
    return useImageSectionPlanesStore()
  }

  static get isWaitImagePlanePick() {
    return this.#_imagePlaneStore.isWaitImagePlanePick
  }

  static get imageSrc() {
    return this.#_imagePlaneStore.imageSrc
  }

  static #_setImageSrc(imageSrc) {
    this.#_imagePlaneStore.imageSrc = imageSrc
  }

  static #_setImagePlaneTitle(title) {
    this.#_imagePlaneStore.setImagePlaneTitle(title)
  }

  static #_setWaitImagePlanePick(waitImagePlanePick) {
    this.#_imagePlaneStore.setWaitImagePlanePick(waitImagePlanePick)
  }

  static async waitImagePlanePick() {
    if (this.imageSrc == "") return

    store.commit('project/setActiveGlobalTab', null)

    await XeokitMediator.SectionCube.loadFloors()
    let floor = XeokitMediator.SectionCube.floors.find(floor => floor.uuid == XeokitMediator.SectionCube.selectedFloor)

    if (floor) {
      const [coordinates] = FloorBoundsCache.getFloorBounds(floor)

      let centerX = (coordinates[0] + coordinates[3]) / 2;
      let centerY = (coordinates[1] + coordinates[4]) / 2;
      let centerZ = coordinates[5];
      const coord = [centerX, centerY, centerZ]

      XeokitMediator.Drawings3D.createImageSectionPlane({
        imageSrc: this.imageSrc,
        position: coord,
        dir: [0, 0, 1],
        editable: true,
        flipDir: true
      })
      
      return
    }

    this.#_setWaitImagePlanePick(true)

    this.#_onInputMouseClick = XeokitMediator.viewer.scene.input.on("mouseclicked", (canvasCoords) => {
      
      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: canvasCoords,
        pickSurface: true
      })

      if (!this.#firstAnchorPin) {
        this.#firstAnchorPin = new AnchorPin(XeokitMediator.viewer.scene, { position: pickResult._worldPos })
        useDrawingCroppingStore().setSceneFirstAnchorPoint(pickResult.worldPos)
      }
      else if (!this.#secondAnchorPin) {
        this.#secondAnchorPin = new AnchorPin(XeokitMediator.viewer.scene, { position: pickResult._worldPos })
        useDrawingCroppingStore().setSceneSecondAnchorPoint(pickResult.worldPos)
      }
    })
  }

  static cancelWaitImagePlanePick() {
    this.#_setWaitImagePlanePick(false)

    XeokitMediator.viewer.scene.input.off(this.#_onInputMouseClick)
  }

  static pick() {
    
  }
}