import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import { AnchorPin } from "../pins/anchorPin"
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"
import { useDrawingCroppingStore } from "@/pinia"

const MOUSE_CANVAS_CLICK_TOLERANCE = 5
const NEAREST_DISTANCE = 0.02
const FIRST_PIN = "first"
const SECOND_PIN = "second"

/*eslint-disable no-dupe-class-members*/
export class AnchorPointsSceneController {

  static firstAnchorPin = null
  static secondAnchorPin = null
  static hoveringPin = null

  static mouseDownListenerId = null
  static mouseUpListenerId = null
  static keyDownListenerId = null
  static keyUpListenerId = null
  static mouseHoverListenerId = null

  static mouseDownCanvasX = null
  static mouseDownCanvasY = null
  static mouseDownLeft = null

  static nearestCoord = null
  static isShiftKeyPressed = false

  static activate() {
    this.#activateInputListeners()
    this.#activateHoverListeners()
  }

  static deactivate() {
    const viewer = XeokitMediator.viewer
    const cameraControl = viewer.cameraControl
    const input = viewer.scene.input

    cameraControl.off(this.mouseHoverListenerId)

    input.off(this.keyDownListenerId)
    input.off(this.keyUpListenerId)

    input.off(this.mouseDownListenerId)
    input.off(this.mouseUpListenerId)

    this.hoveringPin?.destroy()
    this.hoveringPin = null
  }

  static clearAnchorPoints() {
    this.firstAnchorPin?.destroy()
    this.firstAnchorPin = null
    this.secondAnchorPin?.destroy()
    this.secondAnchorPin = null
    useDrawingCroppingStore().isAnchorScenePointsAdded = false
  }

  static #activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input
    this.mouseDownListenerId = input.on('mousedown', (coords) => {
      this.mouseDownCanvasX = coords[0]
      this.mouseDownCanvasY = coords[1]
      this.mouseDownLeft = input.mouseDownLeft
    })

    this.mouseUpListenerId = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.mouseDownLeft = false 
        return
      }

      if (this.mouseDownLeft) {
        if (!this.firstAnchorPin) {
          this.firstAnchorPin = this.#createAnchorPin(FIRST_PIN, coords)
        }
        else if (!this.secondAnchorPin) {
          this.secondAnchorPin = this.#createAnchorPin(SECOND_PIN, coords)
          if (this.secondAnchorPin) useDrawingCroppingStore().isAnchorScenePointsAdded = true
        }
      }
      
      this.mouseDownLeft = false
    })

    this.keyDownListenerId = input.on("keydown", (code) => {
      if (code == 16) this.isShiftKeyPressed = true
    })

    this.keyUpListenerId = input.on("keyup", (code) => {
      if (code == 16) this.isShiftKeyPressed = false
    })
  }

  static #activateHoverListeners() {
    this.hoveringPin = new AnchorPin(XeokitMediator.viewer.scene, { visible: false, noText: true })
    const cameraControl = XeokitMediator.viewer.cameraControl

    this.mouseHoverListenerId = cameraControl.on('hover', (event) => {
      if (this.isShiftKeyPressed) {

        let pickResult = null
        if (!this.firstAnchorPin || !this.secondAnchorPin) {
          pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
            canvasPos: event.canvasPos,
          })
        }
        
        if (!pickResult) { return }
        if (pickResult.isSectionControl) return
        if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) { return } 

        this.nearestCoord = geometry.nearestCoordFinder.getNearestCoordOnEdgeOrVertexByCanvasPos({
          canvasPos: event.canvasPos,
          nearestDistance: NEAREST_DISTANCE,
          collisionDetect: true
        })
        
        if (this.nearestCoord) {
          this.hoveringPin.setCoordinate(this.nearestCoord.worldPos)
          this.hoveringPin.setVisible(true)
        }
      }
      else {
        this.hoveringPin?.setVisible(false)
        this.nearestCoord = null
      }
    })
  }

  static #createAnchorPin(index, coords) {
    let anchorPin
    if (this.nearestCoord) {
      const worldPos = this.nearestCoord?.worldPos // Иногда пропадает при быстром отжатии Shift и нажатии ЛКМ
      if (worldPos) { 
        let anchorNumber = ''
        if (index == FIRST_PIN) anchorNumber = '1'
        if (index == SECOND_PIN) anchorNumber = '2'
        anchorPin = new AnchorPin(XeokitMediator.viewer.scene, { anchorNumber: anchorNumber })
        anchorPin.setCoordinate(worldPos)
        if (index == FIRST_PIN) useDrawingCroppingStore().setSceneFirstAnchorPoint(worldPos)
        if (index == SECOND_PIN) useDrawingCroppingStore().setSceneSecondAnchorPoint(worldPos)
      }
    }
    else {
      let pickResult = null
      pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: coords,
        pickSurface: true,
      })
      if (!pickResult) { return }
      if (pickResult.isSectionControl) { return }
      if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) { return } 
      let anchorNumber = ''
      if (index == FIRST_PIN) anchorNumber = '1'
      if (index == SECOND_PIN) anchorNumber = '2'
      anchorPin = new AnchorPin(XeokitMediator.viewer.scene, { anchorNumber: anchorNumber })
      anchorPin.setCoordinate(pickResult.worldPos)
      if (index == FIRST_PIN) useDrawingCroppingStore().setSceneFirstAnchorPoint(pickResult.worldPos)
      if (index == SECOND_PIN) useDrawingCroppingStore().setSceneSecondAnchorPoint(pickResult.worldPos)
    }

    return anchorPin
  }
}