import { drawingService } from "@/_services"
import { DrawingComment } from "@/assets/model/drawing/DrawingComment"
import { DrawingPage } from "@/assets/model/drawing/DrawingPage"
import { api } from '@/api'
import { Drawing } from "@/assets/model/drawing/Drawing"
import _ from "lodash"
import { AlertService } from '@/assets/app/AlertService'

const getDefaultState = () => {
  return {
    drawingsPages: [],
    drawings: [],
    selectedDrawingPage: null,
    pageForRecovery: null,

    modelsWithGroups: [],

    scaleDrawing: 100,

    previewImages: [],

    drawingCommentMode: false,

    commentImportMode: false,
    commentsToImport: [],
    
    searchFilter: null,
    
    drawingsIsLoading: false,

    drawingsPanel: null,

    commentGrabbing: null,
    
    ignoreConsoleWarn: 0,

    cloudDrawingInfo: null,
    task: null,
    commentsToNewPage: null,
    flagCommentToImport: false,
    loadCommentsInPage: [],
    flagDrawingsPages: false,
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    getPagesByDrawingVersion: ({drawingsPages}) => (uuid) => {
      //изначально заполненный, потом пустой, потом опять полный
      return drawingsPages.filter(page => page.version.uuid == uuid).sort((page, b) => page.number - b.number)
    },
    getAttachmentsByPageUuid: ({drawingsPages}) => (uuid) => {
      return drawingsPages.find( page => page.uuid = uuid ).attachments
    },
    getSearchValidPages: ({modelsWithGroups, searchFilter}) => {
      let validModels = []
      modelsWithGroups.map(model => {
        let validModel = {
          name: model.name,
          visible: true,
          uuid: model.uuid,
          groups: []
        }
        model.groups.map(group => {
          let validPages = group.pages.filter(page => page.name?.toLowerCase().includes(searchFilter.toLowerCase()) || page.number == searchFilter)
          if(validPages.length > 0 || group.drawing.name.toLowerCase().includes(searchFilter.toLowerCase())){
            let validGroup = {
              drawing: null,
              pages: []
            }
            if(validPages.length > 0){
              validGroup = {
                drawing: group.drawing,
                pages: validPages
              }
            }
            else {
              validGroup = {
                drawing: group.drawing,
                pages: group.pages
              }
            }
            validModel.groups.push(validGroup)
          }
        })
        validModels.push(validModel)
      })
      return validModels
    },
    getSearchValidDrawings: ({modelsWithGroups, searchFilter}) => {
      let validModels = []
      modelsWithGroups.map(model => {
        let validModel = {
          name: model.name,
          uuid: model.uuid,
          visible: model.visible,
          groups: []
        }
        validModel.groups = model.groups.filter(group => group.drawing.name.toLowerCase().includes(searchFilter.toLowerCase()))
        validModels.push(validModel)
      })
      return validModels
    }
  },

  actions: {
    loadPage({state}, pageUuid) {
      // state.commentsToNewPage = pageUuid
      let page = state.drawingsPages.find(pages => pages?.uuid == pageUuid)
      state.commentsToNewPage = page?.uuid
      //страница на которую надо перенести комментарии
      return drawingService.loadDrawingPage(pageUuid).then(pageSource => {
        if(page.attachments){
          state.loadCommentsInPage = page.attachments.concat(state.commentsToImport)
        }
        else
          state.loadCommentsInPage = state.commentsToImport
        return pageSource
      })
    },

    loadDrawings({ state, commit, rootGetters }, projectUuid) {
      state.drawingsIsLoading = true
      return drawingService.getAllDrawings(projectUuid).then(data => {
        state.drawingsIsLoading = false

        if(rootGetters['project/flatlist'].length) commit('setModelsWithGroups', getModelsWithGroups(rootGetters['project/flatlist'], state.modelsWithGroups))
          
        getUniqModelsWithDrawings(data)
        if(!state.flagDrawingsPages) state.drawingsPages = []
        state.flagDrawingsPages = false

        data = data.filter(drawing => drawing.archived == false)
        data.map((drawing) => {
          if(drawing.currentVersion) {
            drawingService.getAllVersions(drawing).then(versions => {
              let currentVersionIndex = versions.findIndex(version => version.uuid == drawing.currentVersion)
              let currentVersion = versions.splice(currentVersionIndex, 1)
              versions = versions.sort((version, b) => version.versionNum - b.versionNum)
              versions.unshift(currentVersion[0])
              drawing.currentVersion = versions.find(version => version.uuid == drawing.currentVersion)
              drawing.versions = versions
              drawing = new Drawing(drawing)
              state.drawings.push(drawing)
              let groupFromDrawing = getGroupFromDrawing(drawing, state)
    
              commit('addGroupToModelsWithGroups', groupFromDrawing)
            })
          }
        })
      })
    },
    
    getDrawingPage({state}, pageUUID) {
      return drawingService.loadDrawingPage(pageUUID).then(pageSvg => {
        state.ignoreConsoleWarn
        return pageSvg
      })
    },

    getDrawingPagePreview({state}, pageUUID) {
      return drawingService.getDrawingPreview(pageUUID).then(preview => {
        state.ignoreConsoleWarn
        return preview
      })
    },

    addNewDrawing({state}, drawing) {
      return drawingService.addNewDrawing(drawing.name, drawing.model.uuid).then(drawing => {
        state.ignoreConsoleWarn
        return drawing
      })
    },

    changeDrawing({dispatch, state}, drawing) {
      drawing.currentVersion = drawing.currentVersion.uuid
      return drawingService.changeDrawing(drawing.uuid, drawing.name, drawing.model.uuid).then(changedDrawing => {
        state.modelsWithGroups.map(model => {
          let existingModelIndex = model.groups.find(group => group.drawing.uuid == changedDrawing.uuid)
          if(existingModelIndex) {
            model.groups.splice(existingModelIndex, 1)
          }
        })
        dispatch('loadDrawings', changedDrawing.projectUuid)
      })
    },

    archiveDrawing({dispatch, state}, drawing) {
      drawingService.archiveDrawing(drawing.uuid).then(() =>{
        let existingModel = state.modelsWithGroups.find(model => model.uuid == drawing.model.uuid)
        let existingGroupIndex = existingModel.groups.find(group => group.drawing.uuid == drawing.uuid)
        existingModel.groups.splice(existingGroupIndex, 1)
        dispatch('loadDrawings', drawing.projectUuid)
      })
    },

    includePages({dispatch, state, rootGetters}, pages) {
      drawingService.includePages(pages, rootGetters['project/projectUuid'], pages[0].drawing.uuid).then(includingPages => {
        let projectUuid = state.drawingsPages.find(page => page.uuid == includingPages[0].uuid)?.drawing.projectUuid
        if(!projectUuid) dispatch('loadDrawings', projectUuid)
      }) 
    },

    newComment( {state, dispatch}, comment ) {
      return drawingService.addNewComment(comment).then(comment => {
        comment = new DrawingComment(comment)
        // state.selectedDrawingPage.attachments.push(comment)
        state.drawingsPages.find(page => comment.page == page.uuid).attachments.push(comment)
        if(comment.task) {
          dispatch('task/loadTasks', {}, {root: true})
        }
      })
    },

    deleteComment({state, dispatch}, comment) {
      return api.tasks.deleteAttachment(comment.uuid).then(() => {
        dispatch('task/loadTasks', {}, {root: true})
        
        let selectedPage = state.drawingsPages.find(page => comment.page == page.uuid)
        let index = selectedPage?.attachments.findIndex(attach => attach.uuid == comment.uuid)
        if(index > -1) {
          // selectedPage.attachments.splice(index, 1)
          state.selectedDrawingPage.attachments = state.selectedDrawingPage.attachments.filter(attach => comment.uuid != attach.uuid)
        }
      })
    },

    importComment({state}, {comment, newPage}) {
      return drawingService.importComment(comment.page, newPage.uuid, comment.uuid).then(() => {
        let oldPage = state.drawingsPages.find(page => page.uuid == comment.page)
        oldPage.attachments.splice(oldPage.attachments.findIndex(attach => attach.uuid == comment.uuid), 1)
        newPage.attachments.push(comment)
      })
    },

    importCommentToNewPage({state, commit}, {newPage, comments}){
      if(newPage === comments[0].page){
        commit('setCommentImportMode', false)
        return 
      }
      else if(state.flagCommentToImport){
        return drawingService.importCommentToNewPage(comments[0].page, newPage.uuid).then(() => {
          let oldPage = state.drawingsPages.find(page => page.uuid == comments[0].page)
          oldPage.attachments.splice(0, comments.length)
          for(let i = 0; i < comments.length; i++){
            comments[i].page = newPage.uuid
            newPage.attachments.push(comments[i])
          }
          commit('setCommentImportMode', false)
        })
      }
      else {
        return drawingService.importComment(comments[0].page, newPage.uuid, comments[0].uuid).then(() => {
          let oldPage = state.drawingsPages.find(page => page.uuid == comments[0].page)
          oldPage.attachments.splice(oldPage.attachments.findIndex(attach => attach.uuid == comments[0].uuid), 1)
          comments[0].page = newPage.uuid
          newPage.attachments.push(comments[0])
          commit('setCommentImportMode', false)
        })
      }
    },

    editDrawingComment({state, dispatch, rootState}, comment) {
      return drawingService.editComment(comment).then(editing => {
        if(editing.task) {
          dispatch('task/loadTasks', {}, {root: true})
        }

        editing = new DrawingComment(editing)
        let selectedPage = state.drawingsPages.find(page => page.uuid == editing.page)

        let index = selectedPage?.attachments.findIndex(attach => attach.uuid == editing.uuid)
        if(index > -1) {
          selectedPage.attachments[index].text = editing.text
          // state.selectedDrawingPage.attachments.splice(index, 1, editing)
          state.selectedDrawingPage.attachments[index].task = rootState.task.tasks.find(task => task.uuid == editing.task)
        }
      })
    },

    getDrawingCommentAttachment({state}, comment){
      return drawingService.getCommentAttachment(comment).then(response => state.task = response)
    },

    renamePage( {state}, page ) {
      return drawingService.renamePage(page).then(renamingPage =>{
        state.drawingsPages.find(page => page.uuid == renamingPage.uuid).name = renamingPage.name
      })
    },

    newDrawingVersion(version) {
      let form = new FormData()
      form.append('name', version.name)
      form.append('file', version.name)
      form.append('drawingUuid', version.drawing.uuid)
      return drawingService.newDrawingVersion(form).then(data => {
        if(data === ''){
          AlertService.error( {data: { error_description: 'Не удалось обработать файл' } } )
          return;
      }
      })
    },

    getAllVersionsByDrawing({state}, drawing) {
      return drawingService.getAllVersions(drawing).then(versions => {
        state.ignoreConsoleWarn
        drawing.versions = versions
        drawing = new Drawing(drawing)
        return versions
      })
    },

    getAllVersionsPagesByPageUuid({state}, pageUuid) {
      return drawingService.getVersionPagesByPageUuid(pageUuid).then(pages => {
        let drawing = state.drawings.find(drawing => drawing.uuid == pages[0].drawingVersion.drawing.uuid)
        return pages.map(page => {
          page.version = page.drawingVersion
          page.drawing = drawing
          page = new DrawingPage(page)
          state.drawingsPages.push(page)
          return page
        })
      })
    },

    downloadVersion({state}, version) {
      state.ignoreConsoleWarn
      return drawingService.downloadVersion(version.uuid).then(version => {
        return version
      })
    },

    getCloudDrawingInfo({state}, infoHashes) {
      state.ignoreConsoleWarn
      return drawingService.getCloudDrawingInfo(infoHashes).then(fileInfo => {
        return fileInfo
      })
    }


  },

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },
    
    setSelectedDrawingPage: ( state, drawingPage ) => {
      if(drawingPage) {
        state.selectedDrawingPage = drawingPage
      } 
      else {
        state.commentImportMode = false
        state.commentsToImport = []
        state.selectedDrawingPage = null
      }
    },

    setDrawingCommentMode(state, mode) {
      state.drawingCommentMode = mode
    },

    setFlagComment(state, flagCommentToImport){
      state.flagCommentToImport = flagCommentToImport
    },

    setCommentImportMode(state, mode) {
      if(!mode) {
        state.commentsToImport = []
      }
      state.commentImportMode = mode
    },

    setCommentsToImport(state, comments) {
      state.commentsToImport = comments
    },

    addCommentToImportComments(state, comment) {
      let existingComment = state.commentsToImport.find(comm => comm.uuid == comment.uuid)
      if(!existingComment) {
        state.commentsToImport.push(comment)
      }
    },

    setSearchFilter(state, filter) {
      state.searchFilter = filter
    },

    setDrawingsPanel: (state, panel) => {
      state.drawingsPanel = panel
    },

    setFlagDrawingsPages: (state, flag) => {
      state.flagDrawingsPages = flag
    },

    setDrawingsPages(state, drawingsPages) {
      state.drawingsPages = drawingsPages
    },

    setPageForRecovery: (state, page) => {
      state.pageForRecovery = page
    },

    setScaleDrawing: (state, scale) => {
      if(scale >= 25 && scale <= 200)
        state.scaleDrawing = scale
    },

    addPreviewImage: (state, {uuid, url}) => {
      state.previewImages.push({uuid, url})
    },

    setModelsWithGroups: (state, models) => {
      state.modelsWithGroups = models
    },

    addGroupToModelsWithGroups: (state, group) => {
      let currentModel = state.modelsWithGroups.find(model => model.uuid == group.drawing.model.uuid)
      if(currentModel){
        if(currentModel.visible) {
          group.getPages(group.drawing.currentVersion)
        }
        currentModel.groups.push(group)
      }
    },

    setCommentGrabbing: (state, grabbing) => {
      state.commentGrabbing = grabbing
    },

    setDrawingsIsLoading: ( state, payload ) => {
      state.drawingsIsLoading = payload
    }
  }
}

function getModelsWithGroups(models, existingModels) {
  let modelsWithGroups = []
  models.map(model => {
    let existingModel = existingModels.find(existingModel => existingModel.uuid == model.uuid)
    let modelWithGroup = {
      uuid: model.uuid,
      name: model.name,
      groups: [],
      visible: existingModel ? existingModel.visible : false
    }
    modelsWithGroups.push(modelWithGroup)
  })
  return modelsWithGroups
}

function getGroupFromDrawing(drawing, state) {
  let group = {
    drawing: drawing,
    pages: [],

    getPages(version) {
      return drawingService.getPagesByVersion(version.uuid).then(pages => {
        pages = pages.map(page => {
          page.version = page.drawingVersion
          page.drawing = this.drawing
          page = new DrawingPage(page)
          
          if(!state.drawingsPages.find(existingPage => existingPage.uuid == page.uuid)) {
            state.drawingsPages.push(page)
          }
          
          return page
        })
        this.pages = pages.sort((a, b) => a.number - b.number)
      })
    }
  }
  return group
}

function getUniqModelsWithDrawings(drawings) {
  return _.uniqBy(drawings.map(drawing => drawing.model), 'uuid')
}