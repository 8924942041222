export default ({ Factory, Presets}) => ({

  /**
   * Получить административный список всех процессов системы
   */
  searchProcess: (processFilter) => {
    return Factory.process.search.post(processFilter, Presets.NOTIFY)
  },
  
  stopProcess: (uuid) => {
    return Factory.process[uuid].delete(Presets.NOTIFY)
  },

  /**
   * Получение списка типа процессов
   */
  getProcessType: () => Factory.process.types.get(Presets.NOTIFY),

  /**
   * Получение информации о сервисах и потоках сервера
   */
  getServiceList: () => Factory.services.info.get()
})  