<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.00012207" width="24" height="24" fill="transparent"/>
    <rect x="2.47565" y="8.5" width="19" height="6" stroke="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9756 13V14H14.9756V13H15.9756Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9756 13V14H18.9756V13H19.9756Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9756 13V14H10.9756V13H11.9756Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.97565 13V14H6.97565V13H7.97565Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9756 16V17H1.97565V16L21.9756 16Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9756 11V14H16.9756V11H17.9756Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9756 11V14H12.9756V11H13.9756Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.97565 11V14H8.97565V11H9.97565Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97565 11V14H4.97565V11H5.97565Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>