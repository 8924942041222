<template>
  <svg
    width="29"
    height="26"
    viewBox="0 0 29 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1118 18.3461C10.1748 21.0985 5.24305 23.8479 1.93337 25.7491L1.06625 25.251C4.3832 23.3456 9.32124 20.5927 14.2543 17.8426L14.2578 17.8406C19.1949 15.0882 24.1266 12.3388 27.4363 10.4376L28.3034 10.9357C24.9864 12.8411 20.0484 15.594 15.1153 18.3442L15.1118 18.3461Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9286 9.37612C9.83431 12.2371 4.74418 15.0958 1.43353 16.9975L0.566406 16.4994C3.88256 14.5945 8.97745 11.7332 14.0685 8.87399L14.0713 8.87243C19.1656 6.01143 24.2557 3.15274 27.5663 1.251L28.4335 1.7491C25.1173 3.654 20.0224 6.51535 14.9314 9.37455L14.9286 9.37612Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.3034 15.4994L26.4363 15.9975L9.46572 6.24907L10.3328 5.75097L27.3034 15.4994Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.404 21.186L18.5369 21.6841L1.5663 11.9357L2.43342 11.4376L19.404 21.186Z"
      fill="currentColor"
    />
    <path
      d="M2.87667 6.04972V9.68604H2.10785V6.77948H2.08655L1.2538 7.3015V6.61968L2.15402 6.04972H2.87667Z"
      fill="currentColor"
    />
    <path
      d="M10.2946 3.68607V3.1321L11.589 1.9336C11.6991 1.82706 11.7914 1.73118 11.866 1.64596C11.9418 1.56073 11.9992 1.47728 12.0382 1.3956C12.0773 1.31274 12.0968 1.22337 12.0968 1.12749C12.0968 1.02096 12.0726 0.929215 12.024 0.852275C11.9755 0.774145 11.9092 0.714375 11.8252 0.67294C11.7411 0.630327 11.6458 0.60902 11.5393 0.60902C11.428 0.60902 11.331 0.63151 11.2481 0.676491C11.1653 0.721475 11.1013 0.785985 11.0564 0.870025C11.0114 0.954075 10.9889 1.0541 10.9889 1.1701H10.2591C10.2591 0.932175 10.313 0.725615 10.4207 0.550426C10.5284 0.375236 10.6793 0.239701 10.8735 0.143821C11.0676 0.04794 11.2913 0 11.5446 0C11.8051 0 12.0317 0.046164 12.2247 0.138494C12.4188 0.22964 12.5697 0.356297 12.6775 0.518465C12.7852 0.680634 12.839 0.866475 12.839 1.076C12.839 1.21331 12.8118 1.34884 12.7574 1.4826C12.7041 1.61636 12.6088 1.76491 12.4715 1.92826C12.3342 2.09043 12.1406 2.28515 11.8909 2.51242L11.36 3.03268V3.05752H12.887V3.68607H10.2946Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>