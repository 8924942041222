<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="450px"
    height="101px"
    viewBox="0 0 450 101"
    enable-background="new 0 0 450 101"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="450"
      height="101"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcIAAABlCAYAAADeduOnAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
            CXBIWXMAAC4jAAAuIwF4pT92AAAb0klEQVR42u3dd3Qc1b3A8e/MrspYsiQbGyODXHHBFIsnbNND
            tQFjOuQRB9ENVkhCJ+QFVsuhJBBKgCg8CA6swPRQDPgtgTRKiIkDL/BI6MYOXvemspJ2d+b9ceWu
            stLOnTu7ez/n7LG1ku/87li7v5079/6uMWbM6AuAqUAhuSsOtABrgNXA152PL1QH1g+HABdKbP9d
            4MEsiXUlcL3E9nPRT4FdJbb/ALCou2+2NtkMHhbkqruGUjYowBkHvOX9GVhsTAdeBkLUOLdl0tTY
            sWM2//XkzoeWXTqARUFgGnAWYKmOSKI2oBVYD6wDlgBfAf8APgQ+UR1gH4wGviOxfRP3EqHsWFcB
            9UC7xGPkkmJgNjBU4jEW0kMi9IlLgAJgLouNn1LjOC60uQ9yf9c1OeKAEQTKgQrV0UhW1NnPys6v
            D93h+18A7wBPAq+qDrYXVmd/ZCnPolirgAbkXnXmkvuBPSQfo1h1J3u02LCA4zq/qgKm4E7iLkXu
            77omRxFQbiKulOKqo1FsLHAO8ApiyPQ2YJzqoLrRIbn91iyKFeACYLwHx8l24/DmA0NCdUd7cQIw
            YJuvT3Op3TbVHdP6JQ60mqqj8KERwI+AT4FHgUmqA9J6NV91AFngcdUB+MSOw5dnsdjI5fkRWhqC
            qgPwudrOx/3Adbh7taS5pwY4A3hWdSA+NRkxBJg/xISY6wFjm2fLgeodfnI08C8WG0t3eP4Fapx7
            VHdD84ZOhOm5DDGEMhd4SXUwWpd+jU6E3blJdQAKnA4ckebPju58bGswcI/qTmje0EOj6RsOvAjc
            oToQrUvlwM9UB+FDBwEnqQ5Cgf8Cov38t/9EzK7V8oROhH13NWINkuY/1yI+sGhbRVQHoESNswY4
            HvE70ZcJPA8DU6hxPlTdBc07OhH2z0zgPfTQsh81qg7ARy4B9lQdhDI1jkONcweisMPKXn46Bcym
            xrmIGqdFdeiat3Qi7L8DEGsPNX85CjhadRA+UATcqzoIX6hx3gN+18tPfU6No2cf5ymdCDMzBT15
            xo/yczhwe/eS22UT07fYMOn9w9EEFhsjVIeqqaETYeZmASHVQWjbGQ5cozoIhcYCc1QH4SPT2FpV
            qienqw5UU0MnQnfUI9ayaf5xO3KLS/vZY6oD8Jkzdvj6NcRGA7/c4Xm3qsxoWUYnQve8qDoAbSdX
            qQ5AgVnAgaqD8JmZnX8mgete21g4M7Km+NzImuIbETtGrOv8/iEsNgarDlbznk6E7tkdMVVb8485
            iF0G8sk81QH40OvA34BDqHFuX5EwfwZ8D3g2sqZ4IaLyzkLgz0CT6mA17+np/+66ATEkp/lDBaI6
            yPdUB+KRG4AhqoPwnRrnss1/jUSt84ArO788EriXGmcuohi3lqdMoIzc3ovQS6XA4aqD0LZTh5g8
            kusGk5+l1NIWiVqHsvNem5dGolad6tg0tYLAUuBLRDJ0Y4NKVezO/gxG7bTx6xFDLJp/NAIHqw5C
            sl+rDsDPIlFrJPAcXQ+V3xuJWp/Uzoi/oTpOTY0gYkLBVYgq7dmcCB0ggEiEuwH7A9MRdRYHehjH
            cYip2jHVJ0Tb4iDEJJIFqgORZApwquog/CoStUoR6327m0UcAJ6JRK1ptTPin/XzMF6+x2jusYCK
            IFuTXzYnwc1SwOrOx4eIhdXlwPcRSxwCHsXxHeBO1SdD2848YKjqICTJmuUSjg3BAoPiASZ2Sv7x
            IlHLQIwI7NfLjw4CFkSi1oG1M+Ib+nGopcASxMiUlh0MxMa8n+XDZJmNwM2IzVufxpv1fqeiE6Hf
            DEHcQ7tRdSAuOx8YrzqIdBVaBqu+SbJiaYJJNcVeHPIW4JQ0f3YC8EQkap1YOyPe1zR9L7qkXdbK
            p+UTX+JdfdCDEFeimr/cQG5dFQbZeVG4rxUWG2xcm+S1p5sIFBiZN9iDSNQ6G3HPvi+OA36u6vxo
            auRTItzsUHqvRJ8pE5EMNf95VHUALvoFWTbj27Fh192DvPtaK++/2SrtOJGoNZX+r6m8PBK1LlRy
            gjQl8jEROsCZHhxnf9Ud1bp0POLDULYbhVgaknUKCg062m1ef6ZZSvuRqLV5E+1Mxl5/FYlaeilU
            nsjHRAjwJvC25GNMUN1JrVtZM7mkB1m776Jtw657BPnrG+5fEUai1gDEDNHdMmyqAHg2ErVGeX+G
            NK/layIE+Te29ZYu/jUS+IHqIDIwgyy/qi0oMEgk3J1g2TlD9GHcmxA3FDGTVC+NyHH5nAj/gNyp
            zrrUlb/dDZSoDqKfHlEdQKZsG4bt7vqk9Z8A/+lym/sAj0WiVj6/V+a8fP7PXQ18JbH9MtUd1Hpk
            Av+tOoh++DGZD/v5QtDFWaORqHUa8krMnQTc6sU50dTI50QIsFxi2/l+brPBbGBf1UH0QQViXVxO
            sF0aj4lErf2Rf8/0ukjUmi37nGhq5PubtVeVZjT/mq86gD54SHUAfhOJWsOAF4ABHhzu4UjU0ns9
            5qB8T4SjJLbdrrpzWlr2Ab6rOog0VLPzTut5LRK1ioDn8W5iWhHwfCRq7aG675q78jkRjgGGS2x/
            XeZNaB55AP+PDjyuOgAfehDvC1fsBrzUuUxDyxH5nAhnSm7/G9Ud1NJWAtylOogenA1MUh2En0Si
            1rVAraLD7w/8pnO5hpYD8jkRXi65/c9Vd1Drkx8g1hf6UV/rZea0SNQ6EbhNcRhnASHV50JzR74m
            wjmIoVGZPlTdSa3P/Fit5Riya2arVJGotTdigpMf3rtCoYaYvm+bA/JhG6YdjcOb9WN/Ud1Rrc8O
            A04AXlUdyDZyqUh4RiJRaxfE5sp+qvQSCTXEvgzXVf5ddSB9UIFYhhPE/aIiBcDH+PtWw07yLRGO
            AxZ5cJwl6KHRbPUI3e9k7rVrkTuhK9vMB0arDmIHFvBiqCE2KVxX2aQ6mDRVILdg+1KyLBH6YXjB
            K2cjPqlUeHCsV1R3Vuu3ofhj894y4Geqg/CZq4A/qQ5iB/8ELsmiJAiQAPq68XBfyKzYJUU+JMLj
            gP9BfJr06gpYT3UXsnVWXRj1tWIfVH0S/KZ2Rvyj2hnxI4BpiPOzSlEoLcBziNJr+4brKv00lK71
            QxDYCzEUVIjcTwmyOZ1/lgJjEQuQD8f7oZSv0fcHN5NZ1Fy2eYg3OhX2A76t+gT4Ve2M+CJgUSRq
            zQUOAA5rb7cuMs3UxIKCDtePl0wWkEoWrCkqbr0feAt4u3ZGvA1g7FjZc+40LwQRN01PVR1IDrlD
            dQA+Imfn1e3bL5XU9izEVkdvSe5DV2Tul5gkR+YG1M6I28CiG+85aLK9sWgijoFh2hRabRQUtRMo
            6CBYkCRY2IFh2piBFOBgBlIYnWMVqaSoo2CnAji2SSpZQLKjkFQiSKK9iI64hZ0KAAwxSzsm3XT1
            22HV/dbcFwSayKEXhw88ojoAH5F9RTgfMUx1haT2H0KMmHhJZiHwJ4BW4EKP+yTNjfccdLDdVPQA
            jshsjm3S3jKA9hb3C7/YzYVn3Xj3wYtvuuKd21X3W3OXiRgOTagOJEe8inhj1gTZ9wiLgKuRN6Q/
            EW/X8JmIcm+yzCWHfj/DjdUDzdLEI2ZJh2kE5X7mMgpSmBVtmGXtPwk3Vk9Q3XfNXfkwWcZLc1UH
            kGfGIa46r5N4jHke9ud25A31/gLYSI6Uags3VgeA+UZRclxgaCvBkRsJVm0iMKQVc2AHRmGq/x/D
            TAejKIVZ1k5gaCvBERsJjthEYJc4RmFqIPBSuLF6kOpzoLlHD4e6w0FM7V6qOpA8s/ky4E7gR8iZ
            6XkAcCbwjOS+DEf8DsnQvk3b2TqTd0e3ASdu+4RRmBIJcJuNX5ykCUkTJ2VAyhSvVMfAsQ0M0wHD
            AVM8jIADQZs0ri7HA8+EG6uPR4+m5QR9ReiOGHC36iDykLPN3y+QeBwv9gGMSGz7CbYOH2fzTF4A
            wo3V5wPXpPOzRtDGKE5iliQwy9oxy9sxK9oIDI6Loc7ydsyBHZglCYziZDpJcLOjgftUnwvNHToR
            umMYomKNrgmpzgLkVQ0qRwxbynIk4o1Vlh9LbNtT4cbqw/GmRGI6LqmtL/u+6iC0zOlE6I4AMAX4
            B97vj6ZtJXOD3WuQV+5M5tVgPWLEIuuFG6tHA79F1LP0i3tq68umqw5Cy4xOhO57B72TuCqfAQ9L
            bF/G7hRXALJ2PF+LqJKT9cKN1WXAy8AuqmPZgQk8XVtfNlF1IFr/6UQoxzPAJaqDyFOXIdbFynAU
            7g5hlgI/l3guLpLYtmc6Z4g+hX9nvJYDr9TWl/ktSWtp0olQngcQi6M1b7UBV0ps362rQgPxgUnW
            a/A94AWJ58FLdyJqBvvZGOC52vqyQtWBaH2nE6FcjwHfUh1EHroPeUtZKnFv3aLMhdnnSGzbM+HG
            6jnAD1XHkaZvAferDkLrO50I5XsDsbWP5q1zJbb9UzLfzssBjpcU32+ATyT23xPhxuqjyL7EcnFt
            fdnlqoPQ+kYnQvkCwO9UB5GH/gj8QWL7v3ahjU8QSdVNCcR90qwWbqweBzyLv2aIpuvO2vqymaqD
            0NKnE6E3JpNDa7myiNtJZlunI6rOZOp63F3ecCWisHbWCjdWVyDWhWZrGTMTeKK2vmxv1YFo6dGJ
            0Du3IG8dmta114D/k9i+W9slnelSO1+RfUOJ2wk3VgeBp5F7/9QLA4GXa+vLVG/wrKXBRLw5W6oD
            yRN+qYiRT2Qusp+AO0sU3kYsD8iUzPuiXrkXOFZ1EC4ZBfxWzyT1vyCiUkMMsaVN1tchTEMKse5n
            BGKvuWIPj30iYi3Ux6pPQh75AHGFcZak9u9DLKloz7Cd84FTEK/D/ogCb0rqoyfCjdV15N4OLoch
            llLJrIWrZSgIPNj5yEeDgSOA7yDu+XjhFuBU1R3PM5cgLxEWI65iMi2gEEdsmNvf4dbzJfXPE+HG
            6mMQ5zEXnV9bX/avSP0mvaGvT+X7PcJ1iCviM4Bq4CMPjnkKejmF1zYgd7LSHMTeiJl6HPhLP/7d
            7WRxPdFwY/VExAzRgOpYJLqttr5sluogtK7leyLc1v8iZne+6sGx9DCJ924DVkts/3GX2unrxJmN
            yN2YWKpwY/VgxAzRctWxSGYC82vry/QONT6kE+H2bGAm8Lrk45ytuqN5Subw4RTcGfL+BrihDz9/
            qcQ+eWEi0KI6CI80obdq8yWdCLt2LKJyvyyT6f9SihLJfc/lwsGvAO9KbN+tnS9uBr5O4+f+ATwp
            sT/Shc754B1gf2AWsJDtN1vOFYsQH8JGReo3zVcdjLazoOoAfOwi4HmJ7U8HHunHv/s3YtapjB0W
            CoD3JfbZD74LfC6p7UHArbhzP/I0YHEafcl6oXM+cBBbLL0cbqyuQkxsOhOYpjq2DHyCKKr+VKR+
            kxdzD7QM6ETYvReAL4Cxkto/kP4lwpc6H1r/fAE8BFwsqf3rEUsqMp288ndgHt3fT34S+FBSH5QJ
            nfPBMsRuE3eGG6srgWOAY3E4DsPHk8wcoxXDeR1xWyUaqd/0qeqQtPTpRNizeYjlDjLoewXq/AA4
            D3l1LB9FXPFnag7i6qi0i+/l/H6XoXM+iAGNDzwzbMHapVXL2lsHUDCgjYCVwCmwSQUNnABiQysv
            pSCQdDASBk5bkERrMTiGNagydvd9t9p/VH3etL7TibBnMhcoj0Xco82HIgZ+04bYGV5WObJjgSPJ
            vOh3CjH8+cIOz18DbJJ4fnwjErXMDbHx78ebBpYCtDeViCknnQzDIVjUTqAogVGQgqDtOIbR7pgk
            nYBhOibFmI6ZdrK0DUgRN21Shu0ETNspclKm6SQDpNqDJNuKcGyTxM7/0ti0dsjCK8LfjLk7VJC1
            S1nylU6EPfsKcfNexmfOYZ0P/aJR45fA1YgyWDJEgCoX2nkRMSlmv86vv0Hurva+kuwovCtQkBgV
            CCZJJXd+u3Icg0RbMYm2LQWiDLqoFmUYDoYpPnOagdSW523bBMfAcUwce8vLvM8lJ4OFHZQOWl9s
            lTU9FWpoPypcVynjHr4miU6EPduImNpdmmlD3dgVnQhVOhf4k6S29wCuQtzvytT1iBmvIIZ080Ik
            ap0XLOSHw8Z8xbDRS4g3ldKyoYL4pjLamktxnPQ/nzqOgZMS6/XtVObr9g3TxhrYxIDyTZRUbKCo
            ZMsKkMMQFXLqVJ8/LX06EfasGLm1SAeo7mCe+zOiRucMSe3fgbhfuCbDdl4FliLqkMpe4+oLkah1
            KNuWfjQcrLImrDIxLurYJu0tA2hrKaGtpZSOVotEW3GXV42ZCgSTFFpxCgfEKS5poai0maIBrRhG
            tys95oYaYh+F6yobVJ9HLT06EfZsd+Seo17vD5rBQuLrY8Q3bCKg6H8rlYQBgwdRXL4rdrJDTRDy
            XIAYbpTBAH4CXO5CW8eSJ6/XSNQahSh92O1kJsO0KR7YTPHAZmDlludTyaBIiIkgyY5CUskCUong
            lgQprgYN7JSJGbABZ8tQaSCYJFCQJBBMECzsIFCYoKConUCwX6Oc94YaYp+H6ypfU30+td7lxQsr
            AzWS2++xoIFhBmhZ82+GjDuA3fefTnvzOiUnobCkgmWLFrBh6cdYg4bh2Dk1v2c5cBdiQ1sZLkAM
            kaYybCcvpuNHolYZYk1hv5ZKBIJJAqXNqrsBom7q06GG2IHhusp/qQ5G65lOhD37tuT2e/ioaZBs
            ayHVEaem9hbGHn4IzavVFN0YuJvBR8/vzRs3n0ZR2S4YRs4VJLoGsa5woIS2BwL3AN9X3Um/i0St
            APAEkCs7u5cDC0INsWnhuko1n2K1tOhE2L2RyN8gtNsyboZp0rw6xqRZl7Db3ocQ+/ALZSeieVWQ
            EVNnUTVtJssWvULZ8LG5dlVoIyY3NEpq/zLEpJklqjvqc7cDJ6gOwmV7As+GGmIzwnWViYxb06TI
            uY/2Lponuf1mupsxahi0bVpDyZBh7HvqNSRamnAcdYnHTnZgBINMmH4RhmFgp3Ly9fwYcrfhcmt3
            ipwUiVoXIm94WrUjkbdmVXOBToRduww4SvIxliA2Y+1S2/oN7HPKlVRUjaV1fUzpcKRhmjSvXELV
            1BOpmjaLphVLMcyc/NU5V2LbByN2NtF2EIlaRwC/Uh2HZHNCDbHLVQehdS0n380yNBtRK1K2j7t6
            0jADNK9aytAJ1Uw8YS4ta1b64p6cnUxgmAHGT78IM2hiJ3PyqvDviNmKsvxGdQf9JhK1xgLPIa/c
            nZ/cGWqIHa86CG1n6t9h/eUGxBCZF97r6slkeyvgsP/segoHlNLRuhEMr4sp7swwTZpXLaFqykxG
            TjuZppU5e1Uoqxg3iJmQIdUd9ItQQ6wcMUN0sOpYPGICT4YaYnupDkTbXk6+k/WRAZyIqAt5k4fH
            /X1XT7auXc7EEy5l9KEnsyn2JYaZeRUMt9ipJOAw4fhLKRpYQaqjTXVIMqyjbxvj9lU9MER1J1UL
            NcQCiB00JqqOxWNlwCuhhlje/w74SRAYj/ikGiT3C0Dbnf0ciqgDWQMcgVg476UViGG47aQSbViD
            hjHuqHPpaG3DcRyMna8GT0Juwl6IKOm1E8MwaV23nCF71rDLmP1Z/ekirIpdPT51nrgZsdxBVuce
            QXz4ymf3AMepDkKR0cBvQw2xY8J1lTlXoSIbBREbiZ6uOpA882xXTybbWinbYzzF5UPoaN7QVRIE
            UcNyssTYVvb0TcMwSHXEceyUH0ZsZboYUfBahpmIyTPvqO6kCqGG2KWICWn57DDEBKELVQeiiaHR
            VuTsdq5176Gunky2tVIyeDhFpbuQSnQ77NiCXL3UxTRwHLtzOUdOZ8KXgHcltu/VvWhfCTXEjsKb
            yWjZ4IJQQ+wq1UFoIhEmgZycAuhT7yO21dlJKpVk4LDRBItLcm3BeraaI7Ht0eTZVVGoITYeMRqi
            C3lsdXuoITZLdRD5Tk+W8d713X/LwRpUiREIkPu3a7PCh8AnEtu/mzzZgSTUEBsELAAGqY7FZ0xg
            fqghtq/qQPKZToTe+gix7c/OHIdAQQFFZUNw7BQ5PuyYTWReFQbJ/YXkhBpiQeAZxMQ8bWeliJqk
            OTnzLBvoROitbiuXpDpaGVCxG4NH7UuidZPqOLWtNu9ZKEstuVNkujv3AkerDsLnRiK3mIPWA50I
            vfMwXSyZEAzim9YyePRkKqr2oqNlo+pYte1dILn9XK9DuhDxJh/PtKEcZSPWFc8LNcT0UJAC+qa1
            N2LARd1+14BUR4rK/Y6ksLSU1vUruls6oamxHPg5cLWk9icDZyO2IMo54brKBYihPwuxdnA6ohD1
            BNWxKbQcUcTjDWBBuK5yTYbtaRnQidAbPRbwTrW3ULLLEHbd+1DaN23USdCfrkXcLyyT1P6DwFPk
            8CypcF1lHHi+80GoIbYbMLXzcQDwH/RzQ16fawY+QJRVfA/4W7iu8jPVQWlb6UQo36lAtztUG4ZJ
            y5qVjJ9+HkP3PICmlUtUx6t1zQEuBeZLar8UsWfhFao76pVwXeWKL7748iXEmk0AIlFrF2AS4mpx
            EmKCTRWikISfa5K2Ass6H58hXvMfA5+E6yqXqQ5O65lOhHJdDLzQ0w/YdgIzGGDUIaeDYeA4KV/s
            NqF16QnE8hdZU90vRyypWKq6o6rUzoivBd7sfGwnErUsYAQiKe6OqNk6aJtHxea/2wmGgTMIDMw+
            7Gthi3K6OI7TEig0lgPrgQ2df67f4et/b36E6yrXqz53Wv/pRCjPBfSy7Y5hmjQt/4qqKScwfPLR
            tKz+WidB/6tFFEWQpRH4lupO+lHtjHgcsa6z17WdN84xLkd8qNiOEQDD3ObWg+OI5LezZ2960Dmv
            t+OMHTtG9WnRXKDfdd3XAswgjb3n7GQCwzAZP+NCgkUWqYQu8JMFPqCbWrEuORzx+6NJ4KTATjhb
            H7q4pIZOhG77GrEm7LXeftAwTZpWLqVq6kxGTD2JppVLcnV/v1wkc89CgEdVd1DT8ol+53XPA8Be
            iGTYKzvZgRkQO74bZjBXd3zPVRuA/5LY/jDJ7Wuatg2dCDO3EDgQmEuaC4YN06RpxTJGTJtF1dQT
            aV71lb4azD63Aqsktn8z/p4lqWk5Q7/79k8CMY3+MOAE4K99+cepZAdmQZDx0y/EMAzspL5RkaVk
            V5yZp7qDmpYPTET1e0t1IFmgFXgVsXP5SGA28FZfGzFMk+YVyxh54ElUTZlJ88o+Xw0WS+7nQBfb
            6sPE9X4pldx+b14BFkls/2REFRY3yT5nsv/PtcyZQEBi+6pfl30WBDYi7nnkezJMAu2IWZ9tbF0n
            9AHize5dxLnK7CDtcayKIew183s4dgo7leprItwArEUs8HabiSj95Jb2zocs6yS2na7ZiGohMl4/
            AcQSgANwr07neuT+n7Rl3oQmWQrxwV5WMvTD67JPgohhPQcoVB2MYgnEG0QT4k1nDSIRfgQscesg
            drKD4vKhlAweTqKtuT/l1L5EFGmWUYrLRLypu2UZ8iqxAHwqse10fY74/5C1r6CJu3ty/R5YKfF8
            pDVZTFMqjigOIevWmMw9PKX4f/Owm6YJF34RAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTEyLTEz
            VDEzOjMxOjE4KzAxOjAwm0UgrwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0xMi0xM1QxMzozMTox
            OCswMTowMOoYmBMAAAAASUVORK5CYII="
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>