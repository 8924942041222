import { XeokitMediator } from "../XeokitMediator"

class ModelsManager {
  static setModelsXrayed(modelIds, xrayed) {
    XeokitMediator.viewer.scene.modelIds.forEach(modelId => {
      if (!modelIds.includes(modelId)) return
      XeokitMediator.viewer.scene.models[modelId].xrayed = !!xrayed

      XeokitMediator.viewer.scene.models[modelId].xrayMaterial.edgeAlpha = 0.1
      XeokitMediator.viewer.scene.models[modelId].xrayMaterial.fillAlpha = 0.1
    })
  }

  static setModelsVisible(modelIds, visible) {
    XeokitMediator.viewer.scene.modelIds.forEach(modelId => {
      if (!modelIds.includes(modelId)) return
      XeokitMediator.viewer.scene.models[modelId].visible = !!visible
    })
  }

  static setModelsPickable(modelIds, pickable) {
    XeokitMediator.viewer.scene.modelIds.forEach(modelId => {
      if (!modelIds.includes(modelId)) return
      XeokitMediator.viewer.scene.models[modelId].pickable = !!pickable
    })
  }

}

export { ModelsManager }