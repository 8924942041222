import axios from 'axios'

const instance = axios.create()

instance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
)

const updateCloudSettings = (cloudSettings) => {
  let obj = {
    baseURL: `${cloudSettings?.cloudUrl}/apps/ifcviewer/api/`,
    auth: {
      username: cloudSettings?.username,
      password: cloudSettings?.passwd,
    },
  }
  Object.assign(instance.defaults, obj)
}


const getAllSignsId = (formdata) => instance.post('sign/all', formdata)

const downloadFile = (formdata, progressEvent) => instance.post('sign/file', formdata, {
  responseType: 'blob',
  onDownloadProgress: progressEvent
})

const downloadSign = (formdata) => instance.post('sign/file', formdata)

const uploadKey = (formdata) => instance.post('sign/upload', formdata)




export default{
  getAllSignsId,
  downloadFile,
  downloadSign,
  uploadKey,
  updateCloudSettings
}