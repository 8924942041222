<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_777_535)">
    <path d="M0 0H32V32H0V0Z"/>
    <path d="M6.5353 7.40284L16.0001 5.5099L25.4647 7.40284L24.5199 18.74L16.0001 25.3666L7.48006 18.7399L6.5353 7.40284Z" stroke="currentColor"/>
    <path d="M6 7L16 10V26L7 19L6 7Z" fill="currentColor"/>
    <path d="M2 30L2 26L6 30L2 30Z" fill="currentColor"/>
  </g>
</svg>
</template>

<script>
export default {}
</script>