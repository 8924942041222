export const aabb = {

  buildAabbByCenterPoint(center, sideLength) {
    const halfSideLength = sideLength / 2

    return [
      center[0] - halfSideLength,
      center[1] - halfSideLength,
      center[2] - halfSideLength,
      center[0] + halfSideLength,
      center[1] + halfSideLength,
      center[2] + halfSideLength,
    ]
  },

  /** Поиск min-max координат для построения AABB
   *
   * @param {Array} points - массив точек формата WorldPos [ [x, y, z], [x, y, z] ]
   *
   * @returns {Array} Min-max точки AABB
   */
  buildAabbByPointsArray(points) {
    if (points.length < 2) {
      return console.log('Невозможно построить AABB по одной точке')
    }
    
    let minX = Number.MAX_VALUE
    let minY = Number.MAX_VALUE
    let minZ = Number.MAX_VALUE

    let maxX = -Number.MAX_VALUE
    let maxY = -Number.MAX_VALUE
    let maxZ = -Number.MAX_VALUE
    
    for (let i = 0; i < points.length; i++) {
      if (points[i][0] < minX) minX = points[i][0]
      if (points[i][1] < minY) minY = points[i][1]
      if (points[i][2] < minZ) minZ = points[i][2]

      if (points[i][0] > maxX) maxX = points[i][0]
      if (points[i][1] > maxY) maxY = points[i][1]
      if (points[i][2] > maxZ) maxZ = points[i][2]
    }
    let newAabb = []
    newAabb.push(minX, minY, minZ, maxX, maxY, maxZ)
    return newAabb
  },

  /** Рёбра прямоугольного параллельного мировым осям параллелепипеда (axis-aligned bounding box)
   *
   * @param box - Min-max вершины параллелепипеда в формате WorldPos: [[x, y, z],[x, y, z]]
   * @returns {*[][]} - Массив ребер параллелепипеда в формате WorldPos: [[x1, y1, z1, x2, y2, z2], [x1, y1, z1, x2, y2, z2]]
   */
  getAabbEdges(box) {
    return [
      [box[0], box[1], box[2], box[3], box[1], box[2]],
      [box[0], box[4], box[2], box[3], box[4], box[2]],
      [box[0], box[1], box[5], box[3], box[1], box[5]],
      [box[0], box[4], box[5], box[3], box[4], box[5]], //по x
      [box[0], box[1], box[2], box[0], box[4], box[2]],
      [box[3], box[1], box[2], box[3], box[4], box[2]],
      [box[0], box[1], box[5], box[0], box[4], box[5]],
      [box[3], box[1], box[5], box[3], box[4], box[5]], //по y
      [box[0], box[1], box[2], box[0], box[1], box[5]],
      [box[3], box[1], box[2], box[3], box[1], box[5]],
      [box[0], box[4], box[2], box[0], box[4], box[5]],
      [box[3], box[4], box[2], box[3], box[4], box[5]], //по z
    ]
  },

  /** Вершины прямоугольного параллельного мировым осям параллелепипеда (axis-aligned bounding box)
   *
   * @param aabb - Min-max вершины параллелепипеда в формате WorldPos: [[x, y, z],[x, y, z]]
   * @returns {*[][]} - Все вершины параллелепипеда
   */
  getAabbVertices(aabb) {
    return [
      [aabb[0], aabb[1], aabb[2]],
      [aabb[0], aabb[1], aabb[5]],
      [aabb[0], aabb[4], aabb[2]],
      [aabb[0], aabb[4], aabb[5]],
      [aabb[3], aabb[1], aabb[2]],
      [aabb[3], aabb[1], aabb[5]],
      [aabb[3], aabb[4], aabb[2]],
      [aabb[3], aabb[4], aabb[5]],
    ]
  },

  summaryAabb(aabbList) {
    let minX = Infinity
    let minY = Infinity
    let minZ = Infinity
    let maxX = -Infinity
    let maxY = -Infinity
    let maxZ = -Infinity

    for (const aabb of aabbList) {
      const [xmin, ymin, zmin, xmax, ymax, zmax] = aabb

      if (xmin < minX) minX = xmin
      if (ymin < minY) minY = ymin
      if (zmin < minZ) minZ = zmin

      if (xmax > maxX) maxX = xmax
      if (ymax > maxY) maxY = ymax
      if (zmax > maxZ) maxZ = zmax
    }

    return [minX, minY, minZ, maxX, maxY, maxZ]
  },

  getAabbCenter(aabb) {
    return [
      (aabb[0] + aabb[3]) / 2,
      (aabb[1] + aabb[4]) / 2,
      (aabb[2] + aabb[5]) / 2,
    ]
  }
}