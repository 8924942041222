<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V8C2 7.45 2.196 6.97933 2.588 6.588C2.97933 6.196 3.45 6 4 6H8V4C8 3.45 8.196 2.979 8.588 2.587C8.97933 2.19567 9.45 2 10 2H14C14.55 2 15.021 2.19567 15.413 2.587C15.8043 2.979 16 3.45 16 4V6H20C20.55 6 21.021 6.196 21.413 6.588C21.8043 6.97933 22 7.45 22 8V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM4 18H20V8H4V18ZM10 6H14V4H10V6Z" fill="currentColor"/>
    <rect x="10" y="11" width="4" height="4" rx="1" fill="currentColor"/>
    <rect x="3" y="11" width="17" height="2" fill="currentColor"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>