export default ({ Factory }) => ({
  saveHiddenIfcTypes: (projectUuid, types) => {
    return Factory.projects[projectUuid].hiddenifctypes.post(types)
  },
  
  /**
   * Получить список цветовых кодировок
   * @param {String} projectUuid 
   * @returns 
   */
  fetchColorCodings: (projectUuid) => {
    return Factory.projects[projectUuid].colorcodings.get()
  },

  /**
   * Добавить новую цветовую кодировку
   * @param {String} projectUuid 
   * @param {Object} colorCoding 
   * @returns 
   */
  addColorCoding: (projectUuid, colorCoding) => {
    return Factory.projects[projectUuid].colorcoding.post(colorCoding)
  },
  
  /**
   * Обновить цветовую кодировку
   * @param {Object} colorCoding 
   * @returns 
   */
  updateColorCoding: (colorCoding) => {
    return Factory.projects.colorcoding.patch(colorCoding)
  },
  
  /**
   * Удалить цветовую кодировку
   * @param {String} projectUuid 
   * @param {String} colorCodingUuid 
   * @returns 
   */
  removeColorCoding: (projectUuid, colorCodingUuid) => {
    return Factory.projects[projectUuid].colorcoding[colorCodingUuid].delete()
  },
  
  /**
   * Сохранение активных цветовых кодировок в настройках проекта
   * @param {String} projectUuid 
   * @param {List} colorCodings 
   * @returns 
   */
  saveActiveColorCodings: (projectUuid, colorCodings) => {
    return Factory.projects[projectUuid].active.colorcoding.patch(colorCodings)
  },
  
})