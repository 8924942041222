import { defineStore } from 'pinia'
import { ProjectionMode } from "@/plugins/xeokit/projectionMode/projectionMode";

export const useProjectionModeStore = defineStore('projectionMode', {
  state: () => {
    return {
      projectionMode: ProjectionMode.Modes.PERSPECTIVE
    }
  },

  actions: {
    setProjectionMode(mode) {
      this.projectionMode = mode
    }
  },
})