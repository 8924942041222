// import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { EmphasisMaterial, Mesh, PhongMaterial, VBOGeometry, math } from "@xeokit/xeokit-sdk";
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";

const NO_COLOR = [0, 0, 0]
const BASE_VEC = [0, 1, 0]
export class EdgePin {

  #scene; #pin;
  #position; #scale; #quaternion; #matrix; #origin;
  #visible; #color;

  constructor(scene, cfg = {}) {
    this.#scene = scene

    this.#pin = null
    this.#position = cfg.position ?? [0, 0, 0]
    this.#scale = cfg.scale ?? [1, 1, 1]
    this.#quaternion = cfg.quaternion ?? [0, 0, 0, 1]
    this.#origin = cfg.origin ?? [0, 0, 0]

    this.#visible = cfg.visible ?? true
    this.#color = cfg.color ?? [1.0, 0.0, 1.0]

    this.#create()
  }

  #create() {
    const geometry = new VBOGeometry(this.#scene, {
      primitive: 'lines',
      positions: [0, -0.5, 0, 0, 0.5, 0],
      indices: [0, 1]
    })

    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255]
    const material = new PhongMaterial(this.#scene, {
      emissive: color,
      diffuse: NO_COLOR,
      ambient: NO_COLOR,
    })

    const selectedMaterial = new EmphasisMaterial(this.#scene, {
      fill: false,
      edges: true,
      edgeColor: color,
      edgeAlpha: 1,
    })

    this.#pin = new Mesh(this.#scene, {
      geometry: geometry,
      material: material,
      selected: true,
      selectedMaterial: selectedMaterial,
      visible: this.#visible,
      origin: this.#origin
    })
  }

  setVisible(visible) {
    this.#visible = !!visible
    this.#pin.visible = this.#visible
  }

  createTransformByTwoPoints(point0, point1) {
    const p0 = point0
    const p1 = point1

    const origin = p0

    const distance = geometry.math.distance(p0, p1)

    const denormMeasurementVec = geometry.math.subVec3(p1, p0)
    const measurementVec = geometry.math.normalizeVec3(denormMeasurementVec)
    const halfDistanceVec = geometry.math.mulVec3Scalar(measurementVec, distance / 2)

    const quaternion = geometry.math.quaternionByTwoVectors(BASE_VEC, measurementVec)
    const scale = [distance, distance, distance]
    const position = halfDistanceVec

    return {
      quaternion: quaternion,
      position: position,
      scale: scale,
      origin: origin
    }
  }

  update(transform) {
    const position = transform.position ?? this.#position
    const scale = transform.scale ?? this.#scale
    const quaternion = transform.quaternion ?? this.#quaternion
    const origin = transform.origin ?? this.#origin

    this.#position = position
    this.#scale = scale
    this.#quaternion = quaternion
    this.#origin = origin

    this.#matrix = math.composeMat4(position, quaternion, scale)
    this.#pin.matrix = this.#matrix
    this.#pin.origin = this.#origin
  }

  destroy() {
    this.#pin?.destroy()
    this.#pin = null
  }
}