import vuexStore from "@/store"
import { useDrawingPageStore, useDrawingsStore } from "@/pinia"
import { useDrawingCroppingStore } from "@/pinia"
import { CroppingRectController } from "../cropping/croppingControllers/croppingRectController"
import { AnchorPointsController } from "../cropping/croppingControllers/anchorPointsController"
import { ImageCropper } from "../cropping/croppingControllers/ImageCropper"

let croppingRectangleController = null
let anchorPointsController = null

export const incorrectAnchorPosition = () => { return useDrawingCroppingStore().incorrectAnchorPosition }

export function activateCroppingRectangle(rectangle, layout) {
	croppingRectangleController = new CroppingRectController({
		rectangle: rectangle,
		layout: layout
	})
	croppingRectangleController.activate()
}

export function deactivateCroppingRectangle() {
	croppingRectangleController.deactivate()
	croppingRectangleController = null
}

export function setCroppingMode(value) {
	useDrawingsStore().setCroppingModeActive(value)
}

export function cropDrawing() {
  deactivateAnchorPoints()

  const rectRel = useDrawingCroppingStore().croppingRectangleRelative
  const aPoints = useDrawingCroppingStore().drawingAnchorPoints
  const originHeight = useDrawingPageStore().originalHeight
  const originWidth = useDrawingPageStore().originalWidth

  const topImg = rectRel.top * originHeight
  const leftImg = rectRel.left * originWidth
  const rightImg = (rectRel.width + rectRel.left) * originWidth
  const bottomImg = (rectRel.height + rectRel.top) * originHeight

  const croppingHeight = bottomImg - topImg
  const croppingWidth = rightImg - leftImg

  const aRelative_1 = aPoints.first
  const aRelative_2 = aPoints.second

  const aAbsX_1 = aRelative_1[0]
  const aAbsY_1 = aRelative_1[1]
  const aAbsX_2 = aRelative_2[0]
  const aAbsY_2 = aRelative_2[1]

  const p1 = [ aAbsX_1 - 0.5, -aAbsY_1 + 0.5, 0 ]
  const p2 = [ aAbsX_2 - 0.5, -aAbsY_2 + 0.5, 0 ]

  if (croppingHeight > croppingWidth) {
    const aspect = croppingHeight / croppingWidth
    p1[1] *= aspect
    p2[1] *= aspect
  }
  else {
    const aspect = croppingWidth / croppingHeight
    p1[0] *= aspect
    p2[0] *= aspect
  }

  useDrawingCroppingStore().setDrawing3DFirstAnchorPoint(p1)
  useDrawingCroppingStore().setDrawing3DSecondAnchorPoint(p2)

  const topAbs = rectRel.top * originHeight
  const leftAbs = rectRel.left * originWidth
  const rightAbs = (rectRel.width + rectRel.left) * originWidth
  const bottomAbs = (rectRel.height + rectRel.top) * originHeight

  const rect = {
    left: leftAbs,
    top: topAbs,
    right: rightAbs,
    bottom: bottomAbs
  }//useDrawingCroppingStore().croppingRectangle
  const imgHtml = document.getElementById('drawingPageImage')
  const imgSrc = useDrawingsStore().preparedSrc
  ImageCropper.cropImage({
    imgSrc: imgSrc,
    rectangle: rect,
    img: imgHtml,
    callbackFunc: () => {
      useDrawingCroppingStore().setDrawingSettingMode(true)
      vuexStore.commit('project/setActiveGlobalTab', null)
    }
  })
}

export function activateAnchorPoints() {
  const layout = document.getElementById('drawingCroppingLayout')
  anchorPointsController = new AnchorPointsController({ layout: layout })
  anchorPointsController.activate()
  useDrawingCroppingStore().setAnchorPointAddingModeActivity(true)
}

export function setImageSizesToStore() {
  // TODO перенести
  const imgElement = document.getElementById('drawingPageImage')
  const width = imgElement.width
  const height = imgElement.height
  const center = [width / 2, height / 2]

  useDrawingCroppingStore().setImageWidth(width)
  useDrawingCroppingStore().setImageHeight(height)
  useDrawingCroppingStore().setImageCenter(center)
}

export function deactivateAnchorPoints() {
  anchorPointsController?.deactivate()
  anchorPointsController = null
  useDrawingCroppingStore().setAnchorPointAddingModeActivity(false)
}

export const getDrawingPageHeight = () => useDrawingsStore().imageSize.height
export const getDrawingPageWidth = () => useDrawingsStore().imageSize.width

export const firstPointSetted = () => useDrawingCroppingStore().firstPointSetted
export const secondPointSetted = () => useDrawingCroppingStore().secondPointSetted

export function isCroppingModeActive() {
  return useDrawingsStore().isCroppingModeActive
}

export function isAnchorPointsAdded() {
  return useDrawingCroppingStore().isAnchorPointsAdded
}

export function isAnchorPointAddingModeActive() {
  return useDrawingCroppingStore().isAnchorPointAddingModeActive
}