<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 48.00 48.00"
  >
    <path
      fill="currentColor"
      d="
        M 17.73 21.14
        L 19.01 19.85
        Q 19.41 19.45 19.81 19.85
        L 22.89 22.96
        Q 23.28 23.35 22.88 23.73
        L 21.68 24.88
        Q 21.20 25.33 21.61 25.84
        L 22.09 26.42
        A 0.63 0.62 -41.6 0 0 23.02 26.47
        L 24.34 25.14
        A 0.53 0.52 -45.4 0 1 25.08 25.13
        L 28.17 28.19
        A 0.58 0.58 0.0 0 1 28.16 29.02
        L 26.95 30.14
        Q 26.39 30.67 26.92 31.24
        L 27.41 31.74
        A 0.66 0.66 0.0 0 0 28.36 31.75
        L 29.63 30.47
        Q 30.03 30.07 30.43 30.47
        L 33.49 33.55
        Q 33.86 33.91 33.49 34.27
        L 32.09 35.64
        Q 31.67 36.05 32.10 36.45
        L 32.76 37.07
        Q 33.20 37.50 33.64 37.06
        L 34.92 35.75
        Q 35.27 35.39 35.63 35.74
        L 39.48 39.47
        A 0.31 0.31 0.0 0 1 39.27 40.00
        L 12.00 40.00
        A 4.00 4.00 0.0 0 1 8.00 36.00
        L 8.00 8.73
        Q 8.00 7.99 8.51 8.52
        L 12.14 12.22
        Q 12.64 12.74 12.11 13.23
        L 10.93 14.32
        A 0.64 0.64 0.0 0 0 10.91 15.24
        L 11.57 15.92
        Q 11.99 16.34 12.40 15.91
        L 13.59 14.64
        Q 14.07 14.13 14.57 14.63
        L 17.55 17.60
        A 0.58 0.58 0.0 0 1 17.53 18.44
        L 16.30 19.57
        Q 15.67 20.14 16.33 20.69
        L 16.92 21.18
        Q 17.34 21.53 17.73 21.14
        Z
        M 24.86 34.00
        A 0.30 0.30 0.0 0 0 25.08 33.49
        L 14.51 22.92
        A 0.30 0.30 0.0 0 0 14.00 23.14
        L 13.99 33.71
        A 0.30 0.30 0.0 0 0 14.29 34.01
        L 24.86 34.00
        Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>