<template lang='pug'>
div( :class="{ 'app-toolbar-button_disabled' : disabled }" )
  v-tooltip( :disabled='!title || disabled' :[titleOrientation]="true" )
    template( v-slot:activator='{ on, attrs }' )
      div.relative( v-bind='attrs' v-on='on' :style='buttonStyle' )
        v-icon.corner-left( v-if="corner == 'left'" :size='_iconSize' :color="_iconColor" ) $toolbar-corner-left-icon
        v-icon.corner-right( v-if="corner == 'right'" :size='_iconSize' :color="_iconColor" ) $toolbar-corner-right-icon
        
        v-btn.pa-0( v-on='$listeners'  :height='pxSize' :disabled='disabled' color="transparent" tile block elevation="0" )
          v-icon( :size='_iconSize' :color="_iconColor" @mousedown.left="$emit('mousedownleft')" @mouseup.left="$emit('mouseupleft')" ) {{ icon }}
          span.ellipse( v-if="badge" )

    span( v-html="title" )
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-bolt',
    },
    disabled: {
      type: Boolean, 
      default: false
    },
    size: {
      type: String, // large | small | default
      default: 'default',
    },
    active: Boolean,
    titleOrientation: {
      type: String, // top | right | bottom | left
      default: "bottom"
    },
    iconSize: String,
    title: String,
    badge: Boolean,
    bright: { 
      type: Boolean, // for buttons on white bg,
      default: false
    }, 
    header: {
      type: Boolean, 
      default: false
    },
    corner: {
      type: String, // left | right
      default: '',
      validator(value) {
        return !value || ['left', 'right'].includes(value) 
      }
    }, 
    variant: {
      type: String,
      default: 'base',
      validator(value) {
        return ['base', 'white'].includes(value) 
      }
    }
  },

  computed: {
    _iconSize() {
      if (this.size == 'large') return this.iconSize ?? '32px'
      return this.iconSize ?? '24px'
    },

    _iconColor() {
      if (this.variant === 'white') return this.active ? 'black' : 'var(--v-primary-darken2)'
      if (this.bright) return "var(--v-primary-lighten1)"
      if (this.header) return "var(--v-primary-darken2)"
      return "var(--v-primary-darken2)"
    },

    pxSize() {
      if (this.size == 'small') return '24px'
      if (this.size == 'large') return '48px'
      return '32px'
    },

    background() {
      if (this.variant === 'white') return this.active ? 'white' : 'transparent'
      if (this.bright) return this.active ? 'var(--v-primary-lighten4)' : 'transparent'
      if (this.header) return 'transparent'
      return this.active ? 'var(--v-surface-darken3)' : 'var(--v-surface-base)'
    },

    buttonStyle() {
      return {
        width: this.pxSize,
        height: this.pxSize,
        backgroundColor: this.background,
      }
    }
  }
}
</script>

<style scoped>
.ellipse {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #ED0E51;
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-image: url(/img/red-circle.gif);
  background-size: cover;
}

.corner-left {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.corner-right {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
}

.app-toolbar-button_disabled {
  opacity: 0.17;
}
</style>