// import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/es5/locale/ru';
import { kebabCase } from 'lodash';

Vue.use(Vuetify);

let surface = {
  base: '#535353',      
  darken1: '#282828',
  darken2: '#dddddd',
  darken3: '#8c8c8c',  
  lighten1: '#666666',  
  lighten2: '#B5B5B5',
  lighten4: '#282828'
}

const vuetifyConfig = {
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: {
          base: '#303030',
          darken1: '#424242',
          darken2: '#dddddd',
          darken4: '#000000',
          lighten1: '#6B6B6B',
          lighten2: '#757575',
          lighten3: '#959595',
          lighten4: '#C4C4C4',
          lighten5: '#F0F0F0',
        },
        surface,
        toolbar: {
          base: '#434343',
          darken1: '#1E1E1E',
        },
        accent: {
          base: '#3B93AF',
          darken3: '#2F80ED',
        },
        alert: {
          base: '#D32F2F',
          darken1: '#B71C1C'
        },
        scroll: {
          base: '#666666'
          // darken1: "#4D4D4D",
        },
      },
      light: {
        primary: {
          base: '#303030',
          darken1: '#424242',
          darken2: '#303030',
          darken4: '#000000',
          lighten1: '#6B6B6B',
          lighten2: '#757575',
          lighten3: '#959595',
          lighten4: '#C4C4C4',
          lighten5: '#F0F0F0',
        },
        surface,
        toolbar: {
          base: '#434343',
          darken1: '#1E1E1E',
        },
        accent: {
          base: '#3B93AF',
          darken3: '#2F80ED',
        },
        alert: {
          base: '#D32F2F',
          darken1: '#B71C1C'
        },
        scroll: {
          base: '#e8e8e8'
          // darken1: "#778094",
        },
      }
    },
    options: {
      customProperties: true,
    }
  },
  icons: {
    iconfont: 'md',
    values: {
      search: 'mdi-magnify',
      add: 'mdi-plus',
      close: 'mdi-close',
    }
  },
}
// Подключаются иконки из папки icons
const requireIcons = require.context('@/components/icons', true, /\.vue$/)

for (const file of requireIcons.keys()) {
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')

  const iconName = kebabCase(name)

  vuetifyConfig.icons.values[iconName] = {
    component: () => import(`@/components/icons/${file.slice(2)}`)
  }
}

export default new Vuetify(vuetifyConfig)
  
