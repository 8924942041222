
export class TaskGantt{
  constructor (obj) {
    this.duration = this.duration(obj) > 0 ? this.duration(obj) : 123245 
    this.start = Date.parse(obj.startDate) > 0 ? Date.parse(obj.startDate) : 12345565
                
    this.parentId = obj.parent
    this.label = obj.title
    this.id = obj.uuid
    this.progress = obj.progress
    this.type = 'project'
    this.style =  {
            base: {
              fill: obj.completeStatus ? this.completeStatusChecker(obj.completeStatus.name) : this.completeStatusChecker('NEW'),
              stroke: obj.completeStatus ? this.completeStatusChecker(obj.completeStatus.name) : this.completeStatusChecker('NEW')
            }
            /*'tree-row-bar': {
              fill: '#1EBC61',
              stroke: '#0EAC51'
            },
            'tree-row-bar-polygon': {
              stroke: '#0EAC51'
            }*/
          }
  }

  completeStatusChecker(status){
    switch(status) {
      case 'NEW': 
        return '#EB5757'
      case 'READY': 
        return '#219653'
      case 'DISCUSS': 
        return '#F2994A'
      case 'CLOSED': 
        return '#C4C4C4'
    }
  }

  duration(obj) {
    if(obj.startDate && obj.endDate) return  Date.parse(obj.endDate) - Date.parse(obj.startDate) 
  }
}