import { Model } from '@vuex-orm/core'

export default class ProjectWorm extends Model {
  static entity = 'ProjectWorm'

  static primaryKey = 'uuid'

  static state ()  {
    return {
      fetching: false
    }
  }

  static fields () {
    return {
      uuid: this.attr(null),
      title: this.string(null).nullable(),
      projectUuid: this.attr(null).nullable(),
      actual: this.boolean(false)
    }
  }

  static apiConfig = {
    actions: {
      fetchByProject(uuid) {
        ProjectWorm.commit((state) => {state.fetching = true})
        let data = this.get(`/worm/listbyproject/${uuid}`)
        data.then(() => {ProjectWorm.commit((state) => {state.fetching = false})})
        return data
      }
    }
  }
}