export default ({ Factory, Presets }) => ({

  // addMaterial: (material) => {
  //   let formData = new FormData()
  //   let params = { title: material.title, discribe: material.discribe } 
  //   formData.append("file", material.file)
  //   formData.append("title", material.title)
  //   formData.append("discribe", material.discribe)
  //   return Factory.materials[material.modelUuid].post(formData, params, Presets.headers.MULTIPART)
  // },

    saveFolder: (name) => {
      let formData = new FormData()
      // let params = { title: material.title, discribe: material.discribe } 
      formData.append("folderName", name)
      return Factory.projects.folders.post(formData, Presets.headers.MULTIPART)
    },

    getFolders: () => {
      return Factory.projects.folders.get()
    },

    getFolderProjects: (uuid) => {
      return Factory.projects.folders[uuid].get()
    },

    delFolder: (uuid) => {
      return Factory.projects.folders[uuid].delete()
    },
  
    patchFolderProject: (folder, project) => {
      return Factory.projects.folders[folder].project[project].patch()
    },

    editFolder: (obj) => {
      return Factory.projects.folders.edit.put(obj)
    },

    deleteProjectFromFolder: (folder, project) => {
      return Factory.projects.folders[folder].remove.project[project].patch()
    },

    changeProjectFolder: (fromFolder, project, toFolder) => {
      return Factory.projects.folders[fromFolder].project[project][toFolder].patch()
    }

  })