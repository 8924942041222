export default ({ Factory, Presets }) => ({
   /**
   * Проверить изображение на наличие лиц
   * и добавить лицо в базу, если имеется
   * @param {File} file url изображения ввиде base64
   */
  checkScreenshot: (image, type) => {
    let formData = new FormData()
    formData.append("image", image)
    formData.append("type", type)
    return Factory.face.post(formData, Presets.headers.MULTIPART)
  }
})