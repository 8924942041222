import { Node } from "@xeokit/xeokit-sdk"
import { Component } from "@xeokit/xeokit-sdk";

class XeokitNode extends Node {
    constructor(owner, cfg = {}) {
        super(owner, cfg);
    }

    removeChild(child) {
        for (let i = 0, len = this._children.length; i < len; i++) {
            if (this._children[i].id === child.id) {
                child._parentNode = null;
                this._children.splice(i, 1); // Было this._children = this._children.splice(i, 1);
                child._setWorldMatrixDirty();
                child._setAABBDirty();
                this._setAABBDirty();
                this._numTriangles -= child.numTriangles;
                return;
            }
        }
    }
    /**
     * Destroys this Node.
     */
    destroy() {
        Component.prototype.destroy.call(this) // TODO: Обращение к прародителю - плохая практика
        //super.destroy() // Вызывает изначальный destroy(), ради отказа от которого все это создано
        if (this._parentNode) {
            this._parentNode.removeChild(this);
        }
        if (this._isObject) {
            this.scene._deregisterObject(this);
            if (this._visible) {
                this.scene._objectVisibilityUpdated(this, false);
            }
            if (this._xrayed) {
                this.scene._objectXRayedUpdated(this, false);
            }
            if (this._selected) {
                this.scene._objectSelectedUpdated(this, false);
            }
            if (this._highlighted) {
                this.scene._objectHighlightedUpdated(this, false);
            }
            this.scene._objectColorizeUpdated(this, false);
            this.scene._objectOpacityUpdated(this, false);
            this.scene._objectOffsetUpdated(this, false);
        }
        if (this._isModel) {
            this.scene._deregisterModel(this);
        }
        if (this._children.length) {
            // Clone the _children before iterating, so our children don't mess us up when calling removeChild().
            const tempChildList = this._children.slice(); // Было const tempChildList = this._children.splice();
            let child;
            for (let i = 0, len = tempChildList.length; i < len; i++) {
                child = tempChildList[i];
                child.destroy();
            }
        }
        this._children = [];
        this._setAABBDirty();
        this.scene._aabbDirty = true;
    }
}
export { XeokitNode };