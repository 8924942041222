<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4V2L13 2V4L11 4ZM4.5 5C4.22386 5 4 5.22386 4 5.5V18.5C4 18.7761 4.22386 19 4.5 19H11V20H4.5C3.67157 20 3 19.3284 3 18.5V5.5C3 4.67157 3.67157 4 4.5 4L11 4V5L4.5 5ZM19.5 5H13V4L19.5 4C20.3284 4 21 4.67157 21 5.5V18.5C21 19.3284 20.3284 20 19.5 20H13V19H19.5C19.7761 19 20 18.7761 20 18.5V5.5C20 5.22386 19.7761 5 19.5 5ZM11 19V5H13V19H11ZM11 22V20H13V22H11Z" fill="currentColor"/>
    <path d="M15 7H18V10H15V7Z" fill="currentColor"/>
    <path d="M6 14H9V17H6V14Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {};
</script>

<style>
</style>