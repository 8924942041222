export class TaskType {
  constructor(obj) {
    this.authUser = obj?.authUser
    this.bind = obj?.bind
    this.createdDate = obj?.createdDate
    this.fields = obj?.fields
    this.form = obj?.form
    this.modifiedDate = obj?.modifiedDate
    this.uuid = obj?.uuid
  }
}
