export class TableElement {

  constructor(node, level = 0) {
    Object.getOwnPropertyNames(node).forEach((key) => {
      if (!(key in this)) {
        this[key] = node[key];
      }
    });

    this.level = level

    if (node.geometryInfo && node.geometryInfo.additionalData) {
      this.geometryInfo.additionalData = JSON.parse(node.geometryInfo.additionalData);
    }

    this.elementsCount = node.elementsCount

    this.oname = node.name || node.longName || node.classTitle

    this.children = []
    this.attributeReceived = false
    this.attributeList = []
    if (this.attribute) {
      this.attributeList = node.children.map(child => new TableElement(child, level + 1))
    } else {
      node.children.forEach(child => {
        let newChild = new TableElement(child, level + 1)
        if (child.attribute) {
          this.attributeList.push(newChild)
        } else {
          this.children.push(newChild)
        }
      })
    }
    
  }

}