export class Authorization {
  #_accessToken = null
  #_refreshToken = null

  get accessToken () {
    let token = localStorage.getItem('access_token')!== undefined ? localStorage.getItem('access_token') : null
    return (this.#_accessToken = this.#_accessToken || token)
  }
  
  get refreshToken () {
    return (this.#_refreshToken = this.#_refreshToken || localStorage.getItem('refresh_token'))
  }

  get loginType () {
    return localStorage.getItem("bimit_login_type") ? localStorage.getItem("bimit_login_type") : "new"
  }

  use (data = {}) {
    let { access_token, refresh_token, login_type } = data

    this.#_accessToken = access_token
    localStorage.setItem('access_token', access_token)

    login_type = login_type ? login_type : "local"
    localStorage.setItem("bimit_login_type", login_type)

    this.#_refreshToken = refresh_token
    localStorage.setItem('refresh_token', refresh_token)

  }

  saveLocationHref () {
    if (location.href.indexOf("/project/") != -1) {
      localStorage.setItem("locationHref", location.href)
    }
  }

  clear () {
    this.#_accessToken = null
    this.#_refreshToken = null

    let loginName = localStorage.getItem("loginName")

    let nextcloud = localStorage.getItem("nextCloud")
    let locale = localStorage.getItem("locale")
    let login_type = localStorage.getItem("bimit_login_type")
    localStorage.clear()
    if (locale) localStorage.setItem("locale", locale)
    if (login_type) localStorage.setItem("bimit_login_type", login_type)
    if (loginName && loginName.indexOf("social") == -1) {
      localStorage.setItem("loginName", loginName)
      if (nextcloud) localStorage.setItem("nextCloud", nextcloud)
    }
  }

 }