import { $_plugin_server } from '@/_helpers'

export const documentService = {
  getProjectWorkJournals,
  saveJournal,
  createJournal,
  getWorkJournal,

  getWorkPersonList,
  saveNewWorkPerson,

  generateWorkJournal,

  testGenerateDocument
}

function getProjectWorkJournals(projectUuid) {
  return $_plugin_server.get(`/plugins/workjournal/${projectUuid}/list`).then(response => response.data)
}
function saveJournal(journal) {
  return $_plugin_server.post('/plugins/workjournal/save', journal).then(response => response.data)
}
function createJournal(projectUuid) {
  return $_plugin_server.get(`/plugins/workjournal/${projectUuid}/create`).then(response => response.data)
}
function getWorkJournal(workJournalUuid) {
  return $_plugin_server.get(`/plugins/workjournal/${workJournalUuid}`).then(response => response.data)
}

function getWorkPersonList(projectUuid) {
  return $_plugin_server.get(`/plugins/workjournal/workperson/${projectUuid}`).then(response => response.data)
}
function saveNewWorkPerson(workPerson) {
  return $_plugin_server.post(`/plugins/workjournal/workperson/add`, workPerson).then(response => response.data)
}

function generateWorkJournal(workJournalUuid) {
  return $_plugin_server.get(`/plugins/docgenerate/workjournal/${workJournalUuid}`,{ responseType: "blob" }).then(response => response.data)
}

function testGenerateDocument() {
  return $_plugin_server.get(`/plugins/test/generate/test`,{ responseType: "blob" }).then(response => response.data)
}