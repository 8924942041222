import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=2fef29bc&scoped=true&lang=pug&"
import script from "./Avatar.vue?vue&type=script&lang=js&"
export * from "./Avatar.vue?vue&type=script&lang=js&"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=2fef29bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fef29bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VImg,VTooltip})
