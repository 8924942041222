import { ClassificatorNode, SmetaUtils } from '.'

export class ClassificatorTree {
  constructor (tree = []) {
    this.items = tree.map(i => new ClassificatorNode(i))

    this.map = new Map(SmetaUtils.flatlist(this.items).map(node => [node.uuid, node]))

    this.onlyNodes = this.items.reduce(SmetaUtils.onlyNodesReducer, [])
    this.endpointUuids = this.items.reduce(SmetaUtils.onlyEndpointsReducer, []).map(node => node.uuid)
  }
}