import { api } from "@/api"

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

export default {
  namespaced: true,

  state: {
    bClassificators: {
      list: [],
      status: status.INITIAL
    },
    bClasses: {
      list: [],
      status: status.INITIAL
    },

    bAttributeTypes: {
      list: [],
      status: status.INITIAL
    },

    schemas: {
      list: [],
      status: status.INITIAL
    },
  },

  getters: {
    bClassificators: (state, getters) => {
      return state.bClassificators.list.sort((a, b) => a.title.localeCompare(b.title)).map(item => {
        item.schema = getters.schemas.find(s => s.headerName === item.schema.headerName)
        return item
      })
    },
    isBClassificatorsLoaded: ({ bClassificators }) => bClassificators.status == status.LOADED,

    bClasses: state => {
      return state.bClasses.list.length > 0 ? state.bClasses.list.sort((a, b) => a.title > b.title ? 1 : -1) : []
    },
    isBClassesLoaded: ({ bClasses }) => bClasses.status == status.LOADED,

    bAttributeTypes: state => {
      return state.bAttributeTypes.list.sort((a, b) => a.title.localeCompare(b.title))
    },
    isBAttributeTypesLoaded: ({ bAttributeTypes }) => bAttributeTypes.status == status.LOADED,

    schemas: ({ schemas }) => schemas.list.map((item, key) => {
      item.value = key
      item.title = item.headerName
      return item
    }),
    isSchemasLoaded: ({ schemas }) => schemas.status == status.LOADED,
  },

  mutations: {
    setBClassificatiors: (state, bClassificators) => {
      state.bClassificators.list = bClassificators
    },
    setBClassificatiorsStatus: (state, status) => {
      state.bClassificators.status = status
    },

    setBClasses: (state, bClasses) => {
      state.bClasses.list = bClasses
    },
    setBClassesStatus: (state, status) => {
      state.bClasses.status = status
    },

    setBAttributeTypes: (state, bAttributeTypes) => {
      state.bAttributeTypes.list = bAttributeTypes
    },
    setBAttributeTypesStatus: (state, status) => {
      state.bAttributeTypes.status = status
    },

    setSchemas: (state, schemas) => {
      state.schemas.list = schemas
    },
    setSchemasStatus: (state, status) => {
      state.schemas.status = status
    },
  },

  actions: {
    INIT: ({ dispatch }) => {
      dispatch("LOAD_BCLASSIFICATORS")
      dispatch("LOAD_SCHEMAS")
      dispatch("LOAD_BATTRIBUTE_TYPE")
    },

    LOAD_BCLASSIFICATORS: ({ state, commit }) => {
      if (state.bClassificators.status == status.INITIAL ) {
        commit('setBClassificatiorsStatus', status.LOADING)
        api.classifications.classificationAll().then(data => {
          commit('setBClassificatiors', data)
          commit('setBClassificatiorsStatus', status.LOADED)
        })  
      } 
    },

    LOAD_SCHEMAS: ({ state, commit }) => {
      if (state.schemas.status == status.INITIAL ) {
        commit('setSchemasStatus', status.LOADING)
        api.classifications.schemas().then(data => {
          commit('setSchemas', data)
          commit('setSchemasStatus', status.LOADED)
        })  
      } 
    },

    LOAD_BCLASSES: ({ commit }, { classificatorId }) => {
        commit('setBClassesStatus', status.LOADING)
        api.classifications.bClassesAll(classificatorId).then(data => {
          commit('setBClasses', data)
          commit('setBClassesStatus', status.LOADED)
        })  
    },

    LOAD_BATTRIBUTE_TYPE: ({  state, commit }) => {
      if (state.bAttributeTypes.status == status.INITIAL ) {
        commit('setBAttributeTypesStatus', status.LOADING)
        api.classifications.bAttributeTypes().then(data => {
          commit('setBAttributeTypes', data)
          commit('setBAttributeTypesStatus', status.LOADED)
        })  
      } 
    },

    createClassification: ({commit, dispatch}, obj) => {
      obj.schema = obj.schema.value
      api.classifications.createClassification(obj).then(() => {
        commit('setBClassificatiorsStatus', status.INITIAL)
        dispatch("LOAD_BCLASSIFICATORS")
      })
    },

    editClassification: ({commit, dispatch}, obj) => {
      obj.schema = obj.schema.value
      api.classifications.editClassification(obj).then(() => {
        commit('setBClassificatiorsStatus', status.INITIAL)
        dispatch("LOAD_BCLASSIFICATORS")
      })
    },

    deleteClassification: ({commit, dispatch}, uid) => {
      api.classifications.deleteClassification(uid).then(() => {
        commit('setBClassificatiorsStatus', status.INITIAL)
        dispatch("LOAD_BCLASSIFICATORS")
      })
    },

    createBClass: ({commit, dispatch}, obj) => {
      api.classifications.createBClass(obj.bclassificator, obj).then(() => {
        commit('setBClassesStatus', status.INITIAL)
        dispatch("LOAD_BCLASSES", { classificatorId: obj.bclassificator })
      })
    },

    editBClass: ({commit, dispatch}, obj) => {
      obj.elementType = obj.elementType.value
      api.classifications.editBClass(obj).then(() => {
        commit('setBClassesStatus', status.INITIAL)
        dispatch("LOAD_BCLASSES", { classificatorId: obj.bclassificator })
      })
    },

    deleteBClass: ({commit, dispatch}, obj) => {
      api.classifications.deleteBClass(obj.uuid).then(() => {
        commit('setBClassesStatus', status.INITIAL)
        dispatch("LOAD_BCLASSES", { classificatorId: obj.bclassificator })
      })
    }
    
  }
}