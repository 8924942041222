import { api } from "@/api"

export default {
  namespaced: true,

  modules: {
    
  },

  state: {
    
  },

  getters: {
    
  },

  mutations: {
    
  },

  actions: {
    exportRules ({ rootGetters }) {
      let project = rootGetters['project/projectUuid']
      api.collisionRules.exportRules(project)
      .then(download)
    },

    importRules ({ rootGetters }, body) {
      let project = rootGetters['project/projectUuid']
      return api.collisionRules.importRules(project, body)
    }
  }
}

function download(json) {
  let content = JSON.stringify(json, null, 4)
  var file = new Blob([content], { type: 'application/json' })
  var a = document.createElement("a")
  a.href = URL.createObjectURL(file)
  a.download = 'rules.json'
  a.click()
}