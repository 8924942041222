export class EquipmentSpecification {

  constructor (specification) {
    this.uuid = specification?.uuid || null
    this.name = specification?.name || ''
    this.title = specification?.title || ''
	this.manufacturer = specification?.manufacturer || ''
	this.resourceMonths = specification?.resourceMonths || ''
	this.warrantyMonths = specification?.warrantyMonths || ''
	this.workParams = specification?.workParams || ''
  }

}