import { EnumType } from "@models/EnumType"

export class Profile {
  constructor (obj) {
    if (obj) {
      let { uuid, email, name, phone, archive, grantType, avatar} = obj
      this.uuid = uuid ? uuid : 
      this.email = email
      this.name = name
      this.phone = phone
      this.archive = archive
      this.grantType = new GrantType(grantType)
      this.avatar = avatar
    }
  }
}

class GrantType extends EnumType {
  constructor(obj) {
    super(obj);
  }
}