<template>
  <svg
    width="32"
    height="32"
    viewBox="-2 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5 21.9999C24.489 21.9999 21.1154 22 20.5 21.9999C18.0385 21.9995 16.6 20.2857 15.7692 19.1428L9 8.85717C7.6 7.14287 5.8 6.00001 1 6.00001"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M28 6.00005C24.9322 6.00005 21.4949 5.99995 20.8679 6.00005C18.3599 6.00045 16.8943 7.71428 16.0479 8.85718L9.15094 19.1428C7.72453 20.8571 5.89057 22 0.999999 22"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M20 17C22.6667 20.0435 28 22.0311 28 22.0311C28 22.0311 22 23.9565 20 27"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 1C22.6667 4.04348 28 6.03106 28 6.03106C28 6.03106 22 7.95652 20 11"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>