import { Vector3 } from '../math/vector3.js'
import { Vector2 } from '../math/vector2.js'
import { denormalize, normalize } from '../math/mathUtils.js'
import { fromHalfFloat, toHalfFloat } from '../extras/dataUtils.js'
import { warnOnce } from '../utils.js'

const _vector = /*@__PURE__*/ new Vector3()
const _vector2 = /*@__PURE__*/ new Vector2()

const StaticDrawUsage = 35044;
const FloatType = 1015;

class BufferAttribute {
  constructor(array, itemSize, normalized = false) {
    if (Array.isArray(array)) {
      throw new TypeError('THREE.BufferAttribute: array should be a Typed Array.')
    }

    this.isBufferAttribute = true

    this.name = ''

    this.array = array
    this.itemSize = itemSize
    this.count = array !== undefined ? array.length / itemSize : 0
    this.normalized = normalized

    this.usage = StaticDrawUsage
    this._updateRange = { offset: 0, count: -1 }
    this.updateRanges = []
    this.gpuType = FloatType

    this.version = 0
  }

  onUploadCallback() {}

  set needsUpdate(value) {
    if (value === true) this.version++
  }

  get updateRange() {
    warnOnce('THREE.BufferAttribute: updateRange() is deprecated and will be removed in r169. Use addUpdateRange() instead.') // @deprecated, r159
    return this._updateRange
  }

  setUsage(value) {
    this.usage = value

    return this
  }

  addUpdateRange(start, count) {
    this.updateRanges.push({ start, count })
  }

  clearUpdateRanges() {
    this.updateRanges.length = 0
  }

  copy(source) {
    this.name = source.name
    this.array = new source.array.constructor(source.array)
    this.itemSize = source.itemSize
    this.count = source.count
    this.normalized = source.normalized

    this.usage = source.usage
    this.gpuType = source.gpuType

    return this
  }

  copyAt(index1, attribute, index2) {
    index1 *= this.itemSize
    index2 *= attribute.itemSize

    for (let i = 0, l = this.itemSize; i < l; i++) {
      this.array[index1 + i] = attribute.array[index2 + i]
    }

    return this
  }

  copyArray(array) {
    this.array.set(array)

    return this
  }

  applyMatrix3(m) {
    if (this.itemSize === 2) {
      for (let i = 0, l = this.count; i < l; i++) {
        _vector2.fromBufferAttribute(this, i)
        _vector2.applyMatrix3(m)

        this.setXY(i, _vector2.x, _vector2.y)
      }
    } else if (this.itemSize === 3) {
      for (let i = 0, l = this.count; i < l; i++) {
        _vector.fromBufferAttribute(this, i)
        _vector.applyMatrix3(m)

        this.setXYZ(i, _vector.x, _vector.y, _vector.z)
      }
    }

    return this
  }

  applyMatrix4(m) {
    for (let i = 0, l = this.count; i < l; i++) {
      _vector.fromBufferAttribute(this, i)

      _vector.applyMatrix4(m)

      this.setXYZ(i, _vector.x, _vector.y, _vector.z)
    }

    return this
  }

  applyNormalMatrix(m) {
    for (let i = 0, l = this.count; i < l; i++) {
      _vector.fromBufferAttribute(this, i)

      _vector.applyNormalMatrix(m)

      this.setXYZ(i, _vector.x, _vector.y, _vector.z)
    }

    return this
  }

  transformDirection(m) {
    for (let i = 0, l = this.count; i < l; i++) {
      _vector.fromBufferAttribute(this, i)

      _vector.transformDirection(m)

      this.setXYZ(i, _vector.x, _vector.y, _vector.z)
    }

    return this
  }

  set(value, offset = 0) {
    // Matching BufferAttribute constructor, do not normalize the array.
    this.array.set(value, offset)

    return this
  }

  getComponent(index, component) {
    let value = this.array[index * this.itemSize + component]

    if (this.normalized) value = denormalize(value, this.array)

    return value
  }

  setComponent(index, component, value) {
    if (this.normalized) value = normalize(value, this.array)

    this.array[index * this.itemSize + component] = value

    return this
  }

  getX(index) {
    let x = this.array[index * this.itemSize]

    if (this.normalized) x = denormalize(x, this.array)

    return x
  }

  setX(index, x) {
    if (this.normalized) x = normalize(x, this.array)

    this.array[index * this.itemSize] = x

    return this
  }

  getY(index) {
    let y = this.array[index * this.itemSize + 1]

    if (this.normalized) y = denormalize(y, this.array)

    return y
  }

  setY(index, y) {
    if (this.normalized) y = normalize(y, this.array)

    this.array[index * this.itemSize + 1] = y

    return this
  }

  getZ(index) {
    let z = this.array[index * this.itemSize + 2]

    if (this.normalized) z = denormalize(z, this.array)

    return z
  }

  setZ(index, z) {
    if (this.normalized) z = normalize(z, this.array)

    this.array[index * this.itemSize + 2] = z

    return this
  }

  getW(index) {
    let w = this.array[index * this.itemSize + 3]

    if (this.normalized) w = denormalize(w, this.array)

    return w
  }

  setW(index, w) {
    if (this.normalized) w = normalize(w, this.array)

    this.array[index * this.itemSize + 3] = w

    return this
  }

  setXY(index, x, y) {
    index *= this.itemSize

    if (this.normalized) {
      x = normalize(x, this.array)
      y = normalize(y, this.array)
    }

    this.array[index + 0] = x
    this.array[index + 1] = y

    return this
  }

  setXYZ(index, x, y, z) {
    index *= this.itemSize

    if (this.normalized) {
      x = normalize(x, this.array)
      y = normalize(y, this.array)
      z = normalize(z, this.array)
    }

    this.array[index + 0] = x
    this.array[index + 1] = y
    this.array[index + 2] = z

    return this
  }

  setXYZW(index, x, y, z, w) {
    index *= this.itemSize

    if (this.normalized) {
      x = normalize(x, this.array)
      y = normalize(y, this.array)
      z = normalize(z, this.array)
      w = normalize(w, this.array)
    }

    this.array[index + 0] = x
    this.array[index + 1] = y
    this.array[index + 2] = z
    this.array[index + 3] = w

    return this
  }

  onUpload(callback) {
    this.onUploadCallback = callback

    return this
  }

  clone() {
    return new this.constructor(this.array, this.itemSize).copy(this)
  }

  toJSON() {
    const data = {
      itemSize: this.itemSize,
      type: this.array.constructor.name,
      array: Array.from(this.array),
      normalized: this.normalized,
    }

    if (this.name !== '') data.name = this.name
    if (this.usage !== StaticDrawUsage) data.usage = this.usage

    return data
  }
}

//

class Int8BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Int8Array(array), itemSize, normalized)
  }
}

class Uint8BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Uint8Array(array), itemSize, normalized)
  }
}

class Uint8ClampedBufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Uint8ClampedArray(array), itemSize, normalized)
  }
}

class Int16BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Int16Array(array), itemSize, normalized)
  }
}

class Uint16BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Uint16Array(array), itemSize, normalized)
  }
}

class Int32BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Int32Array(array), itemSize, normalized)
  }
}

class Uint32BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Uint32Array(array), itemSize, normalized)
  }
}

class Float16BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Uint16Array(array), itemSize, normalized)

    this.isFloat16BufferAttribute = true
  }

  getX(index) {
    let x = fromHalfFloat(this.array[index * this.itemSize])

    if (this.normalized) x = denormalize(x, this.array)

    return x
  }

  setX(index, x) {
    if (this.normalized) x = normalize(x, this.array)

    this.array[index * this.itemSize] = toHalfFloat(x)

    return this
  }

  getY(index) {
    let y = fromHalfFloat(this.array[index * this.itemSize + 1])

    if (this.normalized) y = denormalize(y, this.array)

    return y
  }

  setY(index, y) {
    if (this.normalized) y = normalize(y, this.array)

    this.array[index * this.itemSize + 1] = toHalfFloat(y)

    return this
  }

  getZ(index) {
    let z = fromHalfFloat(this.array[index * this.itemSize + 2])

    if (this.normalized) z = denormalize(z, this.array)

    return z
  }

  setZ(index, z) {
    if (this.normalized) z = normalize(z, this.array)

    this.array[index * this.itemSize + 2] = toHalfFloat(z)

    return this
  }

  getW(index) {
    let w = fromHalfFloat(this.array[index * this.itemSize + 3])

    if (this.normalized) w = denormalize(w, this.array)

    return w
  }

  setW(index, w) {
    if (this.normalized) w = normalize(w, this.array)

    this.array[index * this.itemSize + 3] = toHalfFloat(w)

    return this
  }

  setXY(index, x, y) {
    index *= this.itemSize

    if (this.normalized) {
      x = normalize(x, this.array)
      y = normalize(y, this.array)
    }

    this.array[index + 0] = toHalfFloat(x)
    this.array[index + 1] = toHalfFloat(y)

    return this
  }

  setXYZ(index, x, y, z) {
    index *= this.itemSize

    if (this.normalized) {
      x = normalize(x, this.array)
      y = normalize(y, this.array)
      z = normalize(z, this.array)
    }

    this.array[index + 0] = toHalfFloat(x)
    this.array[index + 1] = toHalfFloat(y)
    this.array[index + 2] = toHalfFloat(z)

    return this
  }

  setXYZW(index, x, y, z, w) {
    index *= this.itemSize

    if (this.normalized) {
      x = normalize(x, this.array)
      y = normalize(y, this.array)
      z = normalize(z, this.array)
      w = normalize(w, this.array)
    }

    this.array[index + 0] = toHalfFloat(x)
    this.array[index + 1] = toHalfFloat(y)
    this.array[index + 2] = toHalfFloat(z)
    this.array[index + 3] = toHalfFloat(w)

    return this
  }
}

class Float32BufferAttribute extends BufferAttribute {
  constructor(array, itemSize, normalized) {
    super(new Float32Array(array), itemSize, normalized)
  }
}

//

export {
  Float32BufferAttribute,
  Float16BufferAttribute,
  Uint32BufferAttribute,
  Int32BufferAttribute,
  Uint16BufferAttribute,
  Int16BufferAttribute,
  Uint8ClampedBufferAttribute,
  Uint8BufferAttribute,
  Int8BufferAttribute,
  BufferAttribute,
}
