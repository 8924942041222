import { defineStore } from "pinia"

export const useDrawingCroppingStore = defineStore('drawingCropping', {
  state() {
    return {
      incorrectAnchorPosition: false,
      isDrawingSettingMode: false,
      isAnchorPointAddingModeActive: false,
      isAnchorPointsAdded: false,
      isWaitNormalPick: false,
      rectangleGrabbing: false,
      anchorPointGrabbing: false,
      isAnchorScenePointsAdded: false,
      isNormalOnSceneAdded: false,

      imageWidth: null,
      imageHeight: null,

      croppingRectangle: {
        left: null,
        width: null,
        top: null,
        height: null
      },

      croppingRectangleRelative: {
        left: 0,
        width: 1,
        top: 0,
        height: 1
      },

      drawingAnchorPoints: {
        first: { left: null, top: null },
        second: { left: null, top: null }
      },

      drawingAnchorVec: null,

      drawing3DAnchorPoints: {
        first: [null, null, null],
        second: [null, null, null],
      },

      sceneAnchorPoints: {
        first: [null, null, null],
        second: [null, null, null]
      },

      sceneNormal: [null, null, null],
      sizeCoeffs: [null, null],
      imageCenter: [null, null],

      firstPointSetted: false,
      secondPointSetted: false
    }
  },

  actions: {
    setCroppingRectangle(croppingRectangle) {
      this.croppingRectangle = croppingRectangle
    },

    setAnchorPoints(anchorPoints) {
      this.drawingAnchorPoints = anchorPoints
    },

    setFirstAnchorPoint(anchorPoint) {
      this.drawingAnchorPoints.first = anchorPoint
    },

    setSecondAnchorPoint(anchorPoint) {
      this.drawingAnchorPoints.second = anchorPoint
    },

    setAnchorPointAddingModeActivity(value) {
      this.isAnchorPointAddingModeActive = value
    },

    setDrawing3DFirstAnchorPoint(point) {
      this.drawing3DAnchorPoints.first = point
    },

    setDrawing3DSecondAnchorPoint(point) {
      this.drawing3DAnchorPoints.second = point
    },

    setSceneFirstAnchorPoint(point) {
      this.sceneAnchorPoints.first = point
    },

    setSceneSecondAnchorPoint(point) {
      this.sceneAnchorPoints.second = point
    },

    setDrawingAnchorVec(vec) {
      this.drawingAnchorVec = vec
    },

    setNormalPickWaiting(value) {
      this.isWaitNormalPick = value
    },

    setSceneNormal(normal) {
      this.sceneNormal = normal
    },
    
    setAnchorPointsAdded(value) {
      this.isAnchorPointsAdded = value
    },

    setDrawingSettingMode(value) {
      this.isDrawingSettingMode = value
    },

    setImageWidth(width) {
      this.imageWidth = width
    },

    setImageHeight(height) {
      this.imageHeight = height
    },

    setSizeCoeffs(coeffs) {
      this.sizeCoeffs = coeffs
    },

    setImageCenter(center) {
      this.imageCenter = center
    },

    setCroppingRectangleRelative(rectangle) {
      this.croppingRectangleRelative = rectangle
    }
  }
})