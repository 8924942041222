export default {
  namespaced: true,

  state: {
    // Объект с полями
    // type: "info",  - success, info, warning or error
    // status: obj.status , - 200,400,401...
    // info: ''
    alert: null,
  },

  getters: {
    alertInfo: ({ alert }) => alert?.['info'],
  },

  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload
    },

    RESET_ALERT: (state) => {
      state.alert = null
    }
  },

  actions: {
    clearAlert ({ commit }) {
      commit('RESET_ALERT')
    }
  }
}