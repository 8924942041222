<template>
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="transparent"/>
    <path d="M13.5 13.5938V4.03125L21.5312 10.4062V19.9688L13.5 13.5938Z" stroke="currentColor"/>
    <path d="M17.5156 14.0625V9.0625" stroke="currentColor"/>
    <path d="M6.0625 7.96875L17.5156 11.5156" stroke="currentColor"/>
    <circle cx="5.46875" cy="7.50781" r="2" fill="transparent" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>