import { useDistanceMeasurementStore } from '@/pinia'
import { VTF } from '@/plugins/viewerTools/VTF'
import store from '@/store'

/*eslint-disable no-dupe-class-members*/
export class DistanceMeasurement {

  static get #_distanceMeasurementStore() {
    return useDistanceMeasurementStore()
  }

  /**
   * Текущая активность обнаружения коллизий.
   * @returns {Boolean}
   */
  static get isCollisionDetectActive() {
    return this.#_distanceMeasurementStore.collisionDetectActive
  }

  /**
   * Текущие настройки измерений.
   * @returns {Object}
   */
  static get distanceMeasurementNumberSettings() {
    //TODO: Создать в store getNumberSettings - то есть разделить отдельные настройки ИЛИ везде в зависимостях указывать нужное поле настроек
    return store.getters['authUser/getRulerSettings']
  }

  /**
   * Текущие измерения.
   * @returns {Object[]}
   */
  static get distanceMeasurements() {
    return this.#_distanceMeasurementStore.distanceMeasurements
  }

  static get measurements() {
    return VTF.DistanceMeasurement.measurements
  }

  static init() {
    VTF.DistanceMeasurement.init()
  }

  /**
   * Установить активность функции обнаружения коллизий.
   * @param {Boolean} collisionDetectActive
   */
  static setCollisionDetectActive(collisionDetectActive) {
    this.#_distanceMeasurementStore.setCollisionDetectActive(collisionDetectActive)
  }

  /**
   * Сохранить измерения в pinia store.
   * @param {Object[]} distanceMeasurements
   */
  static setDistanceMeasurements() {
    const measurements = VTF.DistanceMeasurement.measurements
    const measurementsToSave = {}
    for (const key in measurements) {
      const measurement = measurements[key]
      measurementsToSave[key] = {
        id: measurement.id,
        mode: measurement.mode,
        xLength: measurement.xLength, 
        yLength: measurement.yLength, 
        zLength: measurement.zLength, 
        length: measurement.length,
        visible: measurement.visible,
        isAutoGenerated: measurement.isAutoGenerated,
        originWorldPos: measurement.originWorldPos,
        targetWorldPos: measurement.targetWorldPos,
        createDate: measurement.createDate
      }
    }
    this.#_distanceMeasurementStore.setDistanceMeasurements(measurementsToSave)
  }

  static updateStorePointsAndLengthsByMeasurementId(id) {
    const measurement = VTF.DistanceMeasurement.measurements[id]
    const lengths = {
      length: measurement.length,
      xLength: measurement.xLength,
      yLength: measurement.yLength,
      zLength: measurement.zLength
    }
    const points = {
      target: measurement.targetWorldPos,
      origin: measurement.originWorldPos
    }
    this.#_distanceMeasurementStore.setLengthsAndPointsForMeasurementById(id, lengths, points)
  }

  /**
   * Создать измерение.
   * @param {Number[]} originWorldPos Первая точка измерения в формате World-pos [x, y, z].
   * @param {Number[]} targetWorldPos Вторая точка измерения в формате World-pos [x, y, z].
   * @param {Boolean} needAxes Строить ли оси измерения.
   */
  static createDistanceMeasurement(cfg) {
    return VTF.DistanceMeasurement.createDistanceMeasurement(cfg)
  }

  /**
   * Установить настройки измерений.
   * 
   * @param {Object} settings Настройки измерений.
   * @param {Object} settings.units Единица измерений.
   * @param {Number} settings.units.value ENUM идентификатор
   * @param {String} settings.units.title Наименование единицы измерений.
   * @param {String} settings.units.name ENUM имя.
   * @param {Number} settings.decimalPlaces Количество знаков после запятой.
   */
  static setMeasurementSettings(settings) {
    VTF.DistanceMeasurement.setMeasurementSettings(settings)
  }

  /**
   * Удалить указанные измерения.
   * @param {Object[]} distanceMeasurements=[] Измерения.
   */
  static removeDistanceMeasurements(distanceMeasurements = []) {
    VTF.DistanceMeasurement.removeDistanceMeasurements(distanceMeasurements)
  }

  /**
   * Установить видимость указанных измерений.
   * @param {Object[]} distanceMeasurements=[] Измерения.
   * @param {Boolean} visible True/False
   */
  static setDistanceMeasurementsVisible(distanceMeasurements = [], visible) {
    VTF.DistanceMeasurement.setDistanceMeasurementsVisible(distanceMeasurements, visible)
  }

  static clearAllMeasurement() {
    VTF.DistanceMeasurement.clearAllMeasurement()
  }
}