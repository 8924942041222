<template lang="pug">
.app-btn-action( :style="styles" @click="$emit('click')" ) 
  slot( name="icon" )
  | {{ text || $t('button.add') }}
</template>

<script>
export default {
  props: {
    text: { type: String },
    width: { type: [String, Number], default: 400 }
  },

  computed: {
    styles () {
      return `width: ${this.width}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.app-btn-action {
  height: 32px;
  margin: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  font: normal 12px/18px $roboto;
  background-color: transparent;
  color: #3B93AF;
  text-align: center;
  border: 1px dashed #3B93AF;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
</style>