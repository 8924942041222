<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="transparent"/>
    <path d="M13.5 13.5938V4.03125L21.5312 10.4062V19.9688L13.5 13.5938Z" stroke="currentColor"/>
    <path d="M17.5156 14.0156V9.01562" stroke="currentColor"/>
    <path d="M4.5 11.5156L18 11.5156" stroke="currentColor"/>
    <circle cx="4.5" cy="11.5156" r="2" fill="transparent" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>