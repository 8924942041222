import { Model } from '@vuex-orm/core'

export default class WormVersion extends Model {
  static entity = 'WormVersion'

  static primaryKey = 'uuid'

  static state ()  {
    return {
      fetching: false
    }
  }

  static fields () {
    return {
      uuid: this.attr(null),
      title: this.string(null).nullable(),
      version: this.number(null).nullable(),
      projectWormUuid: this.attr(null).nullable(),
      dateTime: this.attr(null).nullable()
    }
  }

  static apiConfig = {
    // actions: {
    //   fetchByProject(uuid) {
    //     ProjectWorm.commit((state) => {state.fetching = true})
    //     let data = this.get(`/worm/listbyproject/${uuid}`)
    //     data.then(() => {ProjectWorm.commit((state) => {state.fetching = false})})
    //     return data
    //   }
    // }
  }
}