import { defineStore } from 'pinia'

export const usePivotPinStore = defineStore('pivotPin', {
  state: () => {
    return {
      pivotPinCoord: [0, 0, 0]
    }
  },

  actions: {
    setPivotPinCoord(pivotPinCoord) {
      this.pivotPinCoord = pivotPinCoord
    }
  }
})