import { math } from "@xeokit/xeokit-sdk"
import { geometry } from './geometry'

export const faceToFaceMeasurement = {

  findShortestSegmentBetweenFaceAndFace(face1Edges, face2Edges) {
    let face1 = this.faceByEdges(face1Edges)
    let face2 = this.faceByEdges(face2Edges)

    let edges1 = face1.edges
    let edges2 = face2.edges

    let face1Vertices = face1.vertices
    let face2Vertices = face2.vertices

    let face1O = face1.origin
    let face2O = face2.origin

    let face1N = face1.normal
    let face2N = face2.normal

    // Проверяем параллельность плоскостей, в которых лежат многоугольники
    // crossProduct примерно равен нулю
    let lenCrossVec = math.lenVec3(geometry.math.crossVec3(face1N, face2N))
    if (lenCrossVec >= -0.0001 && lenCrossVec <= 0.0001) {
      // Если плоскости параллельны, из каждой точки одного из многоугольников выпускаем лучи в сторону другого многоугольника до тех пор, 
      // пока не найдется точка, выпускаемые лучи из которой в плоскости второго многоугольника не дадут нечетное число пересечений с ребрами 
      // это многоугольника. Если таких не нашлось, повторить для другого многоугольника.

      // ------ чтобы проверить сонаправленность или противоположно направленность, существует скалярное произведение. Если +, то сонаправлены. ------- //
      // ------ для определения направления луча можно построить отрезок по двум случайным точкам каждого многоугольника и посмотреть его направление через сонаправленность с нормалями ------- //
      for (let i = 0; i < face1Vertices.length; i++) {
        let originsVec = geometry.math.subVec3(face1O, face2O)
        let rayV = []
        if (math.dotVec3(originsVec, face1N) > 0) {
          rayV = face1N
        }
        else {
          rayV = geometry.math.mulVec3Scalar(face1N, -1)
        }
        let intersPoint = geometry.intersection.getRayPlaneIntersectionPoint(face1Vertices[i], rayV, face2N, face2O)

        // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
        if (geometry.intersection.isPointOnPolygon(face2N, edges2, intersPoint)) {
          return [intersPoint, face1Vertices[i]]
        }
      }

      for (let i = 0; i < face2Vertices.length; i++) {
        let originsVec = geometry.math.subVec3(face1O, face2O)
        let rayV = []
        if (math.dotVec3(originsVec, face2N) > 0) {
          rayV = face2N
        }
        else {
          rayV = geometry.math.mulVec3Scalar(face2N, -1)
        }
        let intersPoint = geometry.intersection.getRayPlaneIntersectionPoint(face2Vertices[i], rayV, face1N, face1O)

        // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
        if (geometry.intersection.isPointOnPolygon(face1N, face1Vertices, intersPoint)) {
          return [face2Vertices[i], intersPoint]
        }
      }

      // Если таких точек не нашлось, значит многоугольники лежат со сдвигом на проекции одной из плоскостей многоугольников. Ищем кратчайший
      // отрезок путем перебора длин кратчайших отрезков между ребрами многоугольников (Edge to edge, уже готовый метод)
      let destSegments = []
      let segmentsLengths = []

      // Собираем все отрезки и их длины
      for (let i = 0; i < edges1.length; i++) {
        for (let j = 0; j < edges2.length; j++) {
          destSegments.push(geometry.edgeToEdgeMeasurement.findShortestSegmentBetweenSegments(edges1[i], edges2[j]))
        }
      }

      for (let i = 0; i < destSegments.length; i++) {
        let v = geometry.math.subVec3(destSegments[i][0], destSegments[i][1])
        segmentsLengths.push(Math.abs(math.lenVec3(v)))
      }

      // Выбираем наименьший
      let minLength = Number.MAX_VALUE
      for (let i = 0; i < destSegments.length; i++) {

        if (segmentsLengths[i] < minLength) {
          minLength = segmentsLengths[i]
        }
      }

      let destIndex = geometry.utils.indexOfElementInArray(minLength, segmentsLengths)
      return destSegments[destIndex]
    }
    else {
      // Ищем точки пересечения прямых, на которых лежат ребра первого многоугольника с плоскостью второго многоугольника. Проверяем их на принадлежность ребрам 
      // (сумма расстояний от концов ребра до точки равна длине ребра), проверяем их принадлежность многоугольнику (ray cast). Вернуть одну из точек дважды (расст 0).
      // Если таких точек нет, проделать то же самое для другого многоугольника.
      for (let i = 0; i < edges1.length; i++) {
        let intersPoint = null
        intersPoint = geometry.intersection.getLinePlaneIntersectionPoint(edges1[i][0], edges1[i][1], face2N, face2O)
        if (intersPoint == null) {
          continue
        }
        else {
          // Проверка на принадлежность ребру
          let abVec = this.vec3(edges1[i][0], edges1[i][1])
          let acVec = this.vec3(edges1[i][0], intersPoint)
          let bcVec = this.vec3(edges1[i][1], intersPoint)

          if (!(((acVec.length + bcVec.length) >= (abVec.length - 0.0001)) && ((acVec.length + bcVec.length) <= (abVec.length + 0.0001)))) {
            continue
          }

          // Проверка на принадлежность многоугольнику
          // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
          if (geometry.intersection.isPointOnPolygon(face2N, face2Vertices, intersPoint)) {
            return [intersPoint, intersPoint]
          }
        }
      }

      for (let i = 0; i < edges2.length; i++) {
        let intersPoint = null
        intersPoint = geometry.intersection.getLinePlaneIntersectionPoint(edges2[i][0], edges2[i][1], face1N, face1O)
        if (intersPoint == null) {
          continue
        }
        else {
          // Проверка на принадлежность ребру
          let abVec = this.vec3(edges2[i][0], edges2[i][1])
          let acVec = this.vec3(edges2[i][0], intersPoint)
          let bcVec = this.vec3(edges2[i][1], intersPoint)

          if (!(((acVec.length + bcVec.length) >= (abVec.length - 0.0001)) && ((acVec.length + bcVec.length) <= (abVec.length + 0.0001)))) {
            continue
          }

          // Проверка на принадлежность многоугольнику
          // Выпускаем луч из точки в произвольном 3Д направлении, коллинеарном плоскости
          if (geometry.intersection.isPointOnPolygon(face1N, face1Vertices, intersPoint)) {
            return [intersPoint, intersPoint]
          }
        }
      }

      // Если таких не нашлось, ищем кратчайший отрезок путем перебора длин отрезков между ребрами многоугольников (Edge to edge, уже готовый метод)
      let destSegments = []
      let segmentsLengths = []

      // Собираем все отрезки и их длины
      for (let i = 0; i < edges1.length; i++) {
        for (let j = 0; j < edges2.length; j++) {
          destSegments.push(geometry.edgeToEdgeMeasurement.findShortestSegmentBetweenSegments(edges1[i], edges2[j]))
        }
      }

      for (let i = 0; i < destSegments.length; i++) {
        let v = geometry.math.subVec3(destSegments[i][0], destSegments[i][1])
        segmentsLengths.push(Math.abs(math.lenVec3(v)))
      }

      // Выбираем наименьший
      let minLength = Number.MAX_VALUE
      for (let i = 0; i < destSegments.length; i++) {

        if (segmentsLengths[i] < minLength) {
          minLength = segmentsLengths[i]
        }
      }

      let destIndex = geometry.utils.indexOfElementInArray(minLength, segmentsLengths)
      return destSegments[destIndex]
    }
  },

  faceByEdges(edges) {
    return {
      edges: edges,
      origin: edges[0][0],

      normalCache: null,
      verticesCache: null,

      get vertices() {
        if (!this.verticesCache) {
          let faceVertices = [edges[0][0], edges[0][1]]
          for (let i = 1; i < edges.length; i++) {
            if (!geometry.utils.contains(faceVertices, edges[i][0], 0.001)) {
              faceVertices.push(edges[i][0])
            }
            if (!geometry.utils.contains(faceVertices, edges[i][1], 0.001)) {
              faceVertices.push(edges[i][1])
            }
          }

          this.verticesCache = faceVertices
          return faceVertices
        }
        else return this.verticesCache
      },

      get normal() {
        if (!this.norm) {
          let faceN = math.vec3()
          let v1 = geometry.math.subVec3(this.vertices[0], this.vertices[1])
          let v2 = geometry.math.subVec3(this.vertices[0], this.vertices[2])
          let cross = geometry.math.crossVec3(v1, v2)
          if (cross[0] == 0 && cross[1] == 0 & cross[2] == 0){
            this.normalCache = cross
            return cross
          }
          else {
            math.normalizeVec3(cross, faceN)
            this.normalCache = faceN
            return faceN
          }
        }
        else return this.normalCache
      }
    }
  },

  vec3(vec) {
    return {
      v: vec,
      get length() {
        return Math.abs(math.lenVec3(vec))
      }
    }
  }
}