import { iotService } from '@/_services'

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

export default {
  namespaced: true,

  state: {
    protocolType: null,

    devices: {
      project: null,
      list: [],
      status: status.INITIAL
    },
    
    widgetSize: {
      list: [],
      status: status.INITIAL
    },

    widgets: {
      project: null,
      list: [],
      status: status.INITIAL
    },

    deviceReestr: {
      list: [],
      status: status.INITIAL 
    },

    logicTypes: {
      list: [],
      status: status.INITIAL
    },

    deviceParam: {
      list:[],
      status: status.INITIAL
    },

    unitTypes: {
      list:[],
      status: status.INITIAL
    },

    mibList: {
      list: [],
      status: status.INITIAL
    },

    modbusRtuNetworkSettings: {
      list: null,
      status: status.INITIAL
    },

    modbusRtuVariableSettings: {
      list: null,
      status: status.INITIAL
    },

    transportProtocols: {
      list: null,
      status: status.INITIAL
    },

    snmpCommunities: {
      list: null,
      status: status.INITIAL
    },

    snmpRequestOperations: {
      list: null,
      status: status.INITIAL
    }
  },

  getters: {
    protocolType: ({ protocolType }) => protocolType,
    widgetSize: ({ widgetSize }) => widgetSize.list,
    devices: ({ devices }) => devices.list,
    widgets: ({ widgets }) => widgets.list,
    deviceReestr: ({ deviceReestr }) => deviceReestr.list,
    logicTypes: ({ logicTypes }) => logicTypes.list,
    deviceParam: ({ deviceParam }) => deviceParam.list,
    unitTypes: ({ unitTypes }) => unitTypes.list,
    isDeviceReestrLoaded: ({ deviceReestr }) => deviceReestr.status == status.LOADED,
    isDeviceParamLoaded: ({ deviceParam }) => deviceParam.status == status.LOADED,
    getDeviceReestrName: ({ deviceReestr }) =>  (deviceReestrId) => {
        let index = deviceReestr.list.findIndex(item => item.uuid === deviceReestrId)
        let reestr = deviceReestr.list[index]
        return reestr.name
    },
    mibList: ({ mibList }) => mibList.list,
    modbusRtuNetworkSettings: ({ modbusRtuNetworkSettings }) => modbusRtuNetworkSettings.list,
    modbusRtuVariableSettings: ({ modbusRtuVariableSettings }) => modbusRtuVariableSettings.list,
    transportProtocols: ({ transportProtocols }) => transportProtocols.list,
    snmpCommunities: ({ snmpCommunities }) => snmpCommunities.list,
    snmpRequestOperations: ({ snmpRequestOperations }) => snmpRequestOperations.list
  },

  mutations: {
    setProtocolType: (state, payload) => {
      state.protocolType = payload
    },

    setWidgetSize: ({ widgetSize }, payload) => {
      widgetSize.list = payload
    },

    setDataPositionStatus: ({dataPosition}, status) => {
      dataPosition.status = status
    },
    setWidgetSizeStatus: ({ widgetSize }, status) => {
      widgetSize.status = status
    },

    setDevices: ({ devices }, payload) => {
      devices.list = payload.data
      devices.project = payload.project
    },
    setDevicesStatus: ({ devices }, status) => {
      devices.status = status
    },
    updateDevice: ({ devices }, device) => {
      let exist = false
      if(devices.list.length > 0) { 
        let curr = devices.list.find(item => item.uuid === device.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, device)
        }
      } 
      if(!exist) devices.list.push(device)
    },
    deleteDevice: ({ devices }, deviceId) => {
      let index = devices.list.findIndex(item => item.uuid === deviceId)
      devices.list.splice(index, 1)
    },

    setDeviceReestr: ({ deviceReestr }, payload) => {
      deviceReestr.list = payload
    },
    setDeviceReestrStatus: ({ deviceReestr }, status) => {
      deviceReestr.status = status
    },

    setDeviceParam: ({ deviceParam }, payload) => {
      deviceParam.list = payload
    },
    setDeviceParamStatus: ({ deviceParam }, status) => {
      deviceParam.status = status
    },

    saveDeviceInReestr: ({ deviceReestr }, newDeviceReestr) => {
      let exist = false
      if(deviceReestr.list.length > 0) { 
        let curr = deviceReestr.list.find(item => item.uuid === newDeviceReestr.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, newDeviceReestr)
        }
      } 
      if(!exist) deviceReestr.list.push(newDeviceReestr)
    },
   
    deleteDeviceFromReestr: ({ deviceReestr }, deviceReestrId) => {
      let index = deviceReestr.list.findIndex(item => item.uuid === deviceReestrId)
      deviceReestr.list.splice(index, 1)
    },

    setLogicTypes: ({ logicTypes }, payload) => {
      logicTypes.list = payload
    },
    setLogicTypesStatus: ({ logicTypes }, status) => {
      logicTypes.status = status
    },

    deleteDeviceParam: ({ deviceParam }, deviceParamId) => {
      let index = deviceParam.list.findIndex(item => item.uuid === deviceParamId)
      deviceParam.list.splice(index, 1)
    },

    setUnitTypes: ({ unitTypes }, payload) => {
      unitTypes.list = payload
    },
    setUnitTypesStatus: ({ unitTypes }, status) => {
      unitTypes.status = status
    },

    setWidgets: ({ widgets }, payload) => {
      widgets.list = payload.data
      widgets.project = payload.project
    },
    setWidgetsStatus: ({ widgets }, status) => {
      widgets.status = status
    },

    saveWidget: ({ widgets }, widget) => {
      let exist = false
      if(widgets.list.length > 0) { 
        let curr = widgets.list.find(item => item.uuid === widget.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, widget)
        }
      } 
      if(!exist) widgets.list.push(widget)
    },

    deleteWidget: ({ widgets }, widgetId) => {
      let index = widgets.list.findIndex(item => item.uuid === widgetId)
      widgets.list.splice(index, 1)
    },

    saveDeviceParam: ({ deviceParam }, newDeviceParam) => {
      let exist = false
      if(deviceParam.list.length > 0) { 
        let curr = deviceParam.list.find(item => item.uuid === newDeviceParam.uuid)
        if (curr !== undefined) {
            exist = true
            Object.assign(curr, newDeviceParam)
        }
      } 
      if(!exist) deviceParam.list.push(newDeviceParam)
    },

    setMibList: ({ mibList }, newMibList) => {
      mibList.list = newMibList
    },

    setModbusRtuNetworkSettings: ({ modbusRtuNetworkSettings }, newModbusRtuNetworkSettings) => {
      modbusRtuNetworkSettings.list = newModbusRtuNetworkSettings
    },

    setModbusRtuVariableSettings: ({ modbusRtuVariableSettings }, newModbusRtuVariableSettings) => {
      modbusRtuVariableSettings.list = newModbusRtuVariableSettings
    },

    setTransportProtocols: ({ transportProtocols }, newTransportProtocols) => {
      transportProtocols.list = newTransportProtocols
    },

    setSnmpCommunities: ({ snmpCommunities }, newSnmpCommunities) => {
      snmpCommunities.list = newSnmpCommunities
    },

    setSnmpRequestOperations: ({ snmpRequestOperations }, newSnmpRequestOperations) => {
      snmpRequestOperations.list = newSnmpRequestOperations
    }
  },

  actions: {
    init: ({ dispatch }, projectUuid) => {
      dispatch('loadDevices', projectUuid)
      dispatch('loadWidgets', projectUuid)
      dispatch('loadWidgetSize')
      dispatch('loadDeviceReestr')
    },

    initDeviceReestr: ({ dispatch }) => {
      dispatch('loadDeviceReestr')
      dispatch('loadLogicTypes')
    },

    initDeviceParam: ({ dispatch }, deviceParam) => {
      dispatch('loadDeviceParam', deviceParam)
      dispatch('loadUnitTypes')
    },

    loadDevices ({ state, commit }, projectUuid) {
      if(state.devices.status == status.INITIAL || state.devices.project!=projectUuid ){
          commit('setDevicesStatus', status.LOADING)
          iotService.listDevices(projectUuid).then(data => { 
            commit('setDevices', {"data":data, "project":projectUuid}) 
            commit('setDevicesStatus', status.LOADED)
          })
      }
    },

    loadWidgets ({ state, commit }, projectUuid) {
      if(state.widgets.status == status.INITIAL || state.widgets.project!=projectUuid ){
          commit('setWidgetsStatus', status.LOADING)
          iotService.listWidgets(projectUuid).then(data => { 
            commit('setWidgets', {"data":data, "project":projectUuid}) 
            commit('setWidgetsStatus', status.LOADED)
          })
      }
    },
    
    loadWidgetSize ({ state,commit }) {
      if(state.widgetSize.status == status.INITIAL ){
          commit('setWidgetSizeStatus', status.LOADING)
          iotService.widgetSize().then(data => { 
            commit('setWidgetSize', data) 
            commit('setWidgetSizeStatus', status.LOADED)
          })
      }
    },

    saveWidget ({ commit }, widget) {
      return iotService.saveWidget(widget).then(data => {
        commit('saveWidget', data)
        return data
      })
    },


    delWidget: ({ commit }, { widgetId }) => {
        return iotService.delWidget( widgetId ).then(data => {
            commit('deleteWidget', widgetId)
            return data
        })
    },

    loadDeviceReestr ({ state, commit }) {
      if(state.deviceReestr.status == status.INITIAL){
          commit('setDeviceReestrStatus', status.LOADING)
          iotService.deviceReestr().then(data => { 
            commit('setDeviceReestr', data) 
            commit('setDeviceReestrStatus', status.LOADED)
          })
      }
    },

    loadLogicTypes({ state, commit }) {
      if( state.logicTypes.status == status.INITIAL ) {
        commit('setLogicTypesStatus', status.LOADING)
        iotService.logicTypes().then(data =>{
          commit('setLogicTypes', data)
          commit('setLogicTypesStatus', status.LOADED)
        })
      }
    },

    loadUnitTypes({ state, commit }) {
      if( state.unitTypes.status == status.INITIAL ) {
        commit('setUnitTypesStatus', status.LOADING)
        iotService.unitTypes().then(data => {
          commit('setUnitTypes', data)
          commit('setUnitTypesStatus', status.LOADED)
        })
      }
    },

    loadDeviceParam({ commit }, deviceParam) {
      commit('setDeviceParamStatus', status.LOADING)
      iotService.getDeviceParam(deviceParam.deviceReestr).then(data =>{
      commit('setDeviceParam', data)
      commit('setDeviceParamStatus', status.LOADED)
    })
    },

    linkDevice ({ commit }, device) {
      return iotService.linkDevice(device).then(data => {
        commit('updateDevice', data)
        return data
      })
    },
    unlinkDevice ({ commit }, device) {
      return iotService.unlinkDevice(device).then(data => {
        commit('updateDevice', data)
        return data
      })
    },
    relinkDevice ({ commit }, {device, element}) {
      return iotService.relinkDevice(device, element).then(data => {
        commit('updateDevice', data)
        return data
      })
    },
    deleteDevice ({ commit }, deviceId) {
      return iotService.deleteDevice(deviceId).then(data => {
        commit('deleteDevice', deviceId)
        return data
      })
    },

    createDeviceInReestr({commit}, deviceReestr) {
      return iotService.createDeviceInReestr(deviceReestr).then(data=> {
        commit('saveDeviceInReestr', data)
        return data
      })
    },
 
    deleteDeviceFromReestr({ commit }, deviceReestrId) {
      return iotService.deleteDeviceFromReestr(deviceReestrId).then(data => {
        commit('deleteDeviceFromReestr', deviceReestrId)
        return data
      })
    },
    
    editDeviceInReestr ({ commit }, deviceReestr)  {
      return iotService.editDeviceInReestr(deviceReestr).then(data => {
        commit('saveDeviceInReestr', data)
        return data
      })
    },

    createDeviceParam ({commit}, deviceParam) {
      return iotService.createDeviceParam(deviceParam, deviceParam.deviceReestr).then(data => {
        commit('saveDeviceParam', data)
        return data
      })
    },

    deleteDeviceParam ({commit}, deviceParamId) {
      return iotService.deleteDeviceParam(deviceParamId).then(data => {
        commit('deleteDeviceParam', data)
        return data
      })
    },

    editDeviceParam ({commit}, deviceParam) {
      return iotService.editDeviceParam(deviceParam).then(data => {
        commit('saveDeviceParam', data)
        return data
      })
    },

    loadMibNamesAndOids({commit}, file) {
      return iotService.parseMibFile(file).then(data => {
        commit('setMibList', data)
        return data
      })
    },

    loadModbusRtuNetworkSettings({state, commit}) {
      if(!state.modbusRtuNetworkSettings.list) {
        iotService.getListModbusRtuNetworkSettings().then(data => {
          commit('setModbusRtuNetworkSettings', data)
        })
      }
    },

    loadModbusRtuVariableSettings({state, commit}) {
      if(!state.modbusRtuVariableSettings.list) {
        iotService.getListModbusVariableSettings().then(data => {
          commit('setModbusRtuVariableSettings', data)
        })
      }
    },

    loadTransportProtocols({ state, commit }) {
      if(!state.transportProtocols.list) {
        iotService.getTransportProtocols().then(data => {
          commit('setTransportProtocols', data)
        })
      }
    },

    loadSnmpCommunities({ state, commit }) {
      if(!state.snmpCommunities.list) {
        iotService.getSnmpCommunities().then(data => {
          commit('setSnmpCommunities', data)
        })
      }
    },

    loadSnmpRequestOperations({ state, commit }) {
      if(!state.snmpRequestOperations.list) {
        iotService.getSnmpRequestOperations().then(data => {
          commit('setSnmpRequestOperations', data)
        })
      }
    }
  }
}