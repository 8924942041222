<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 48.00 48.00"
  >
    <rect
      fill="currentColor"
      x="6.06"
      y="8.05"
      width="35.92"
      height="7.90"
      rx="1.03"
    />
    <rect
      fill="currentColor"
      x="-8.12"
      y="-1.96"
      transform="translate(14.10,22.01) rotate(0.4)"
      width="16.24"
      height="3.92"
      rx="1.07"
    />
    <rect
      fill="currentColor"
      x="-8.07"
      y="-1.92"
      transform="translate(33.90,22.03) rotate(0.1)"
      width="16.14"
      height="3.84"
      rx="0.88"
    />
    <rect
      fill="currentColor"
      x="-5.00"
      y="-1.98"
      transform="translate(11.01,30.03) rotate(0.4)"
      width="10.00"
      height="3.96"
      rx="1.14"
    />
    <rect
      fill="currentColor"
      x="-5.05"
      y="-1.94"
      transform="translate(24.04,30.03) rotate(-0.1)"
      width="10.10"
      height="3.88"
      rx="0.99"
    />
    <rect
      fill="currentColor"
      x="-5.01"
      y="-1.99"
      transform="translate(36.99,30.03) rotate(-0.4)"
      width="10.02"
      height="3.98"
      rx="1.06"
    />
    <rect
      fill="currentColor"
      x="-2.02"
      y="-1.98"
      transform="translate(8.03,38.02) rotate(2.8)"
      width="4.04"
      height="3.96"
      rx="1.17"
    />
    <rect
      fill="currentColor"
      x="-2.01"
      y="-1.99"
      transform="translate(16.01,38.04) rotate(0.2)"
      width="4.02"
      height="3.98"
      rx="1.20"
    />
    <rect
      fill="currentColor"
      x="-2.03"
      y="-1.98"
      transform="translate(24.02,38.03) rotate(2.7)"
      width="4.06"
      height="3.96"
      rx="1.15"
    />
    <rect
      fill="currentColor"
      x="-2.01"
      y="-2.01"
      transform="translate(32.02,38.03) rotate(4.1)"
      width="4.02"
      height="4.02"
      rx="1.18"
    />
    <rect
      fill="currentColor"
      x="-2.00"
      y="-1.99"
      transform="translate(40.01,38.03) rotate(0.2)"
      width="4.00"
      height="3.98"
      rx="1.22"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>