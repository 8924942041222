import { geometry } from "./geometry"

export const segments = {
  isPointOnSegment(p, q, r) {
    return r[0] >= Math.min(p[0], q[0]) && r[0] <= Math.max(p[0], q[0]) && r[1] >= Math.min(p[1], q[1]) && r[1] <= Math.max(p[1], q[1])
  },

  isSegmentOnRay(segment1, segment2) {
    const rayStart = segment1[0]
    const rayEnd = segment1[1]
    const point1 = segment2[0]
    const point2 = segment2[1]

    const rayDirection = geometry.math.subVec3(rayEnd, rayStart)

    const point1Direction = geometry.math.subVec3(point1, rayStart)
    const point2Direction = geometry.math.subVec3(point2, rayStart)

    const dotProduct1 = geometry.math.dotVec3(rayDirection, point1Direction)
    const dotProduct2 = geometry.math.dotVec3(rayDirection, point2Direction)

    if ((dotProduct1 >= 0 && dotProduct2 >= 0) || (dotProduct1 < 0 && dotProduct2 < 0)) {
      return true
    }

    return false
  },

  mergeSegments(segment1, segment2) {
    const [start1, end1] = segment1
    const [start2, end2] = segment2
    end1
    start2

    if (this.isAdjacentSegments(segment1, segment2)) {
      const mergedSegment = [start1, end2]
      return mergedSegment
    }

    return null
  },

  mergeSegmentsOnRay(segments) {
    const mergedSegments = [...segments] // Создаем копию исходного массива отрезков

    for (let i = 0; i < mergedSegments.length; i++) {
      const segment1 = mergedSegments[i]

      for (let j = i + 1; j < mergedSegments.length; j++) {
        const segment2 = mergedSegments[j]

        if (this.areSegmentsCollinear(segment1, segment2) && this.areSegmentsAdjacent(segment1, segment2)) {
          // Объединяем отрезки, если они лежат на одном луче и являются смежными
          mergedSegments[i] = this.mergeAdjacentSegments(segment1, segment2) // Заменяем первый отрезок на объединенный
          mergedSegments.splice(j, 1) // Удаляем второй отрезок из массива
          j-- // Уменьшаем счетчик цикла, чтобы не пропустить следующий отрезок
        } 
        else if (this.areSegmentsCollinear(segment1, segment2) && this.isSegmentInside(segment2, segment1)) {
          // Объединяем отрезки, если второй отрезок вложен в первый и они лежат на одном луче
          mergedSegments[i] = this.mergeInsideSegments(segment1, segment2) // Заменяем первый отрезок на объединенный
          mergedSegments.splice(j, 1) // Удаляем второй отрезок из массива
          j-- // Уменьшаем счетчик цикла, чтобы не пропустить следующий отрезок
        }
      }
    }

    return mergedSegments
  },

  isAdjacentSegments(segment1, segment2) {
    const [start1, end1] = segment1
    const [start2, end2] = segment2

    return (
      (start1[0] === end2[0] && start1[1] === end2[1] && start1[2] === end2[2]) ||
      (end1[0] === start2[0] && end1[1] === start2[1] && end1[2] === start2[2])
    )
  },

  areSegmentsCollinear(segment1, segment2) {
    // Проверяем, лежат ли два отрезка на одной прямой с погрешностью
    // Можно использовать различные методы проверки коллинеарности,
    // например, сравнение углов или проверка совпадения векторов направления.
    // В данном примере используется простая проверка по координатам.

    const [start1, end1] = segment1
    const [start2, end2] = segment2

    const vector1 = [end1[0] - start1[0], end1[1] - start1[1], end1[2] - start1[2]]
    const vector2 = [end2[0] - start2[0], end2[1] - start2[1], end2[2] - start2[2]]

    const crossProduct = [
      vector1[1] * vector2[2] - vector1[2] * vector2[1],
      vector1[2] * vector2[0] - vector1[0] * vector2[2],
      vector1[0] * vector2[1] - vector1[1] * vector2[0],
    ]

    const epsilon = 0.000000000001 // Погрешность

    // Если векторы коллинеарны, их векторное произведение будет близко к нулю
    return Math.abs(crossProduct[0]) < epsilon && Math.abs(crossProduct[1]) < epsilon && Math.abs(crossProduct[2]) < epsilon
  },

  areSegmentsAdjacent(segment1, segment2) {
    // Проверяем, являются ли два отрезка смежными

    const [start1, end1] = segment1
    const [start2, end2] = segment2

    const epsilon = 0.000000000001 // Погрешность

    // Проверяем, является ли одна из точек началом или концом другого отрезка
    return (
      geometry.math.distance(start1, start2) < epsilon ||
      geometry.math.distance(start1, end2) < epsilon ||
      geometry.math.distance(end1, start2) < epsilon ||
      geometry.math.distance(end1, end2) < epsilon
    )
  },

  mergeAdjacentSegments(segment1, segment2) {
    // Объединяем два смежных отрезка в один, охватывающий все точки

    const [start1, end1] = segment1
    const [start2, end2] = segment2

    const minX = Math.min(start1[0], end1[0], start2[0], end2[0])
    const minY = Math.min(start1[1], end1[1], start2[1], end2[1])
    const minZ = Math.min(start1[2], end1[2], start2[2], end2[2])

    const maxX = Math.max(start1[0], end1[0], start2[0], end2[0])
    const maxY = Math.max(start1[1], end1[1], start2[1], end2[1])
    const maxZ = Math.max(start1[2], end1[2], start2[2], end2[2])

    return [
      [minX, minY, minZ],
      [maxX, maxY, maxZ],
    ]
  },

  mergeInsideSegments(segment1, segment2) {
    // Объединяем два отрезка, если один отрезок полностью содержится внутри другого

    const [start1, end1] = segment1
    const [start2, end2] = segment2

    const minX = Math.min(start1[0], end1[0], start2[0], end2[0])
    const minY = Math.min(start1[1], end1[1], start2[1], end2[1])
    const minZ = Math.min(start1[2], end1[2], start2[2], end2[2])

    const maxX = Math.max(start1[0], end1[0], start2[0], end2[0])
    const maxY = Math.max(start1[1], end1[1], start2[1], end2[1])
    const maxZ = Math.max(start1[2], end1[2], start2[2], end2[2])

    return [
      [minX, minY, minZ],
      [maxX, maxY, maxZ],
    ]
  },

  isSegmentInside(segment1, segment2) {
    // Проверяем, находится ли один отрезок внутри другого

    const [start1, end1] = segment1
    const [start2, end2] = segment2

    const epsilon = 0.000000000001 // Погрешность

    // Проверяем, находятся ли начало и конец одного отрезка внутри другого
    return this.isPointInside(start1, start2, end2, epsilon) && this.isPointInside(end1, start2, end2, epsilon)
  },

  isPointInside(point, start, end, epsilon) {
    // Проверяем, находится ли точка внутри отрезка

    const distanceFromStart = geometry.math.distance(start, point)
    const distanceFromEnd = geometry.math.distance(end, point)
    const segmentLength = geometry.math.distance(start, end)

    // Если сумма расстояний от точки до начала и конца отрезка
    // равна длине отрезка с погрешностью, то точка находится внутри отрезка
    return Math.abs(distanceFromStart + distanceFromEnd - segmentLength) < epsilon
  },
}