import { defineStore } from "pinia"

const useSectionPlanesStore = defineStore('sectionPlanes', {
  state: () => ({
    sectionPlanes: [],
    pickSectionPlanesActive: false,
  }),

  actions: {
    setPickSectionPlanesActive(active) {
      this.pickSectionPlanesActive = active
    },

    setSectionPlanes(sectionPlanes) {
      this.sectionPlanes = sectionPlanes
    },
  }
})

export { useSectionPlanesStore }