import { Model } from '@vuex-orm/core'

export default class ElementWormConditionValid extends Model {
  static entity = 'ElementWormConditionValid'

  static primaryKey = ['wormUuid', 'elementUuid']

  static fields () {
    return {
      wormUuid: this.attr(null),
      elementUuid: this.attr(null),
      conditionsValid: this.attr(null)
    }
  }

  static apiConfig = {
    actions: {
      check(elementUuid,wormUuid) {
        let rq = `?elementUuid=${elementUuid}&wormUuid=${wormUuid}`
        return this.get(`/worm/projectworm/checkelement${rq}`)
      }
    }
  }
}