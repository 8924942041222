import {useMouseNavigationStore} from "@/pinia";
import {XeokitMediator} from "@/plugins/xeokit/XeokitMediator";

export class MouseNavigation {

  /** Режимы управления камерой
   * @type {{USER: string, PROFESSIONAL: string}}
   */
  static Navs = { USER: 'user', PROFESSIONAL: 'professional' };

  static get #_mouseNavigationStore() {
    return useMouseNavigationStore()
  }

  /**
   * Получает текущий режим управления камерой
   */
  static get mouseNav() {
    return this.#_mouseNavigationStore.mouseNav
  }

  /** Устанавливает режим управления камерой
   *
   * @param {String} [nav='user'] Режим управления камерой. Допускаются значения 'user' (пользовательский) и 'professional' (профессиональный). Доступны константы MouseNavigation.**Navs**.
   */
  static setMouseNavigation(nav = this.Navs.USER) {
    if (nav === this.Navs.USER) {
      XeokitMediator.installInput("./modules/InputMouseUserController.js")
    }
    else if (nav === this.Navs.PROFESSIONAL) {
      XeokitMediator.installInput("./modules/InputMouseProfController.js")
    }
    this.#_mouseNavigationStore.setMouseNavigation(nav)
  }
}