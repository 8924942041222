<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" fill="white" stroke="#F0F0F0"/>
    <path d="M14.5313 2V15.5313H2" stroke="#B5B5B5"/>
    <path d="M14.4062 15.375L19.6562 20.625" stroke="#B5B5B5"/>
    <path d="M4.5 11.5156H19.5" stroke="#303030"/>
    <circle cx="19.5312" cy="11.5156" r="2" fill="#FF0000" stroke="#303030"/>
    <circle cx="4.5" cy="11.5156" r="2.5" fill="#303030"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>