import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { CrosshairLines } from "./crosshairLines";
import { TextLabel } from "./textLabel";
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";
import { math } from "@xeokit/xeokit-sdk";

const PERSPECTIVE_SCALE_MULTIPLIER = 0.085
const ORTHO_SCALE_MULTIPLIER = 0.05

/*eslint-disable no-dupe-class-members*/
export class AnchorPin {

  #scene; #node; #crosshairLines; #textlabel;
  #position; #scale; #quaternion; #matrix; #origin;
  #visible; #color; #anchorNumber; #noText;
  #sceneTickListenerId;

  constructor(scene, cfg = {}) {
    this.#scene = scene

    this.#node = null
    this.#crosshairLines = null
    this.#textlabel = null
    this.#position = cfg.position ?? [0, 0, 0]
    this.#scale = cfg.scale ?? [1, 1, 1]
    this.#quaternion = [0, 0, 0, 1]
    this.#origin = cfg.origin ?? [0, 0, 0]
    this.#anchorNumber = cfg.anchorNumber ?? '1'
    this.#noText = cfg.noText ?? false

    this.#visible = cfg.visible ?? true
    this.#color = cfg.color ?? [1.0, 0.0, 1.0]

    this.#create()
  }

  get pin() { return this.#node }

  #create() {
    this.#node = new XeokitNode(this.#scene, {
      selected: true,
      clippable: false,
      visible: this.#visible
    })

    this.#crosshairLines = new CrosshairLines(this.#node, {
      color: this.#color
    })

    if (!this.#noText) {
      this.#textlabel = new TextLabel(this.#node, {
        text: this.#anchorNumber,
        color: this.#color
      })
    }

    let lastDistance = 0
    const camera = this.#scene.camera

    this.#sceneTickListenerId = this.#scene.on("tick", () => {
      const distance = geometry.math.distance(camera.eye, this.#origin)

      if (distance != lastDistance) {
        if (camera.projection === "perspective") {
          const axisScale = distance * PERSPECTIVE_SCALE_MULTIPLIER
          const scale = [axisScale, axisScale, axisScale]
          this.#node.scale = scale
          if (this.#textlabel) this.#labelLookAtCamera()
          
          lastDistance = distance;
        }
        else if (camera.projection === "ortho") {
          const size = camera.ortho.scale * ORTHO_SCALE_MULTIPLIER
          const scale = [size, size, size]
          this.#node.scale = scale
          if (this.#textlabel) this.#labelLookAtCamera()
          
          lastDistance = distance;
        }
      }
    })
  }

  #labelLookAtCamera() {
    const labelUp = [0, 0, 1]
    const labelForward = [0, 1, 0]
    const quaternion = geometry.math.getLookAtCameraQuaternion(this.#scene.camera, labelUp, labelForward)

    this.#textlabel.mesh.quaternion = quaternion
  }

  setVisible(visible) {
    this.#visible = !!visible
    this.#node.visible = this.#visible
  }

  setCoordinate(position) {
    this.update({ position: position })
  }

  update(transform) {
    const origin = transform.position ?? this.#origin
    const position = [0, 0, 0]
    const scale = transform.scale ?? this.#scale
    const quaternion = this.#quaternion

    this.#origin = origin
    this.#position = position
    this.#scale = scale

    this.#matrix = math.composeMat4(position, quaternion, scale)
    this.#node.matrix = this.#matrix
    this.#node.origin = this.#origin
  }

  destroy() {
    this.#node?.destroy()
    this.#node = null

    this.#scene.off(this.#sceneTickListenerId)
  }
}