import { projectService } from '@/_services'

import project from './project.module'

const loadState = {
  LOADED: 0, 
  LOAD: 1
}

export default {
  namespaced: true,

  state: {
    notificationsList: [],
    notificationPageInfo: null,
    notificationFilterInfo: null,
    loadStateNotification:null,
    socketFlag: null,
    alerts: [],
    newNotificationStatus: false,
    notificationFlagWithDashboadr: false,
    readPaginationNotList: []
  },

  getters: {
    hasNotification(state) {
      return !!state.socketFlag
    },

    hasAlerts(state) {
      return state.alerts
    }
  },

  mutations: {

    setNotificationWithDashboard(state, flag) {
      state.notificationFlagWithDashboadr = flag
    },

    setLoadStateNotification(state, loadStatus) {
      state.loadStateNotification = Boolean(loadStatus)
    },

    setNotificationsList(state, notifications) {
      if(notifications.page == 0) state.notificationsList = notifications.not 
      else {
        state.notificationsList.push(...notifications.not)
      }
    },

    setNotificationsPageInfo(state, pageInfo) {
      state.notificationPageInfo = pageInfo
    },

    setNoficationInfo(state, notification) {
      state.notificationFilterInfo = notification
    },
    
    setNewNotificationStatus(state, flag) {
      if(project.state.topUnit === 'notification' && flag) state.newNotificationStatus = true
      else state.newNotificationStatus = false
    },

    setSocketFlag(state, flag) {
      state.socketFlag = flag
    },

    setAlerts(state, alerts) {
      if(typeof alerts === 'object') state.alerts.push(alerts)
      else if (typeof alerts === 'number' && state.alerts.length) state.alerts.splice(alerts, 1);
      else if (!alerts) state.alerts = []
    }
  },

  actions: {
    
    getNotificationsList({commit, state}, page) {
      // Получение и фильтрация уведомлений
      
      let notification = state.notificationFilterInfo

      let object = {}

      object.limit = 20
      
      object.page = page ? page : 0
    
      
      object.firstNotificationUuid = page ? state.notificationsList[0].uuid : null
      
      object.filterByType = notification.filterByType  
      
      object.notRead = notification.notRead 
      
      object.projectUuid = notification && notification.forThisProject  ? notification.projectUuid : null

      commit('setLoadStateNotification', loadState.LOADED)
      
      return projectService.getNotifications(object).then(data => {
        let obj = {page: page ? page : 0, not: data.data.content }
        commit('setNotificationsList', obj )
        delete data.data.content
        commit('setNotificationsPageInfo', data.data)

        commit('setLoadStateNotification', loadState.LOAD)
      })
    },

    // ЧТЕНИЕ УВЕДОМЛЕНИЙ

    readVisibleNotification({commit}, notList) {
      commit
      // Чтение если панель уведомлений открыта +- 5сек(читает только те уведомления которые получает)
      return projectService.readNotification(notList)
    },

    readAllNotificationWithProject({dispatch}, projectUuid) {
      dispatch
      // Чтение всех уведомлений у выбранного проекта
      return projectService.readAllNotificationWithProject(projectUuid).then(() => {
        // dispatch('getNotificationsList')
      })
    },

    readAllNotification({dispatch}) {
      dispatch
      // Чтение всех уведомлений у всех проектов
      return projectService.readAllNotification().then(() => {
        // dispatch('getNotificationsList')
      })
    },


    // УДАЛЕНИЕ УВЕДОМЛЕНИЙ

    deleteNotification({dispatch}, uuid) {
      // Удаление уведомления по UUID
      projectService.deleteNotification(uuid).then(() => {
        dispatch('getNotificationsList')
      })
    },

    deleteAllNotificationWithProject({dispatch}, projectUuid) {
      // Удаление всех уведомлений у выбранного проекта
      projectService.deleteAllNotificationsWithProject(projectUuid).then(() => {
        dispatch('getNotificationsList')
      })
    },
    
    deleteAllNotifications({dispatch}) {
      // Удаление всех уведомлений у проектов
      projectService.deleteAllNotifications().then(() => {
        dispatch('getNotificationsList')
      })
    }
  }
}