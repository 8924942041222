<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-0.000183105 0.000976562H23.9998V24.001H-0.000183105V0.000976562Z" fill="#D0D0D0"/>
    <rect x="1.03125" y="18.001" width="5" height="5" fill="white"/>
    <path d="M1.03125 20.001L3.03125 22.001L6.03125 19.001" stroke="#303030"/>
    <path d="M13.4998 13.5947V4.03223L21.5311 10.4072V19.9697L13.4998 13.5947Z" stroke="#FF0000"/>
    <path d="M2.53125 13.4385V3.53223H10.5V13.4385H2.53125Z" stroke="#FF0000"/>
    <path d="M16.5 14.0327V9.03271" stroke="#303030"/>
    <path d="M6.51563 11.0325L6.51562 6.03271" stroke="#303030"/>
    <path d="M16.8123 11.5327L6.5 8.50098" stroke="#303030"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>