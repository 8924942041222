import * as utils from './modelOffsetGroups.utils'
import { defineStore } from 'pinia'
import store from '@/store'

const NO_REVISION = 'noRevision'
const GRAY_COLOR = '#c8c8c8'
const COLORS = [
  "#ff7070", // red
  "#70ff70", // green
  "#7070ff", // blue
  "#ff70ff", // purple
  "#70ffff", // cian
  "#ffff70", // yellow
  "#bfff00",
  "#ffc0cb",
  "#ffa500",
  "#4b0082",
  "#895129",
]
const OFFSET_THRESHOLD = 50

export const useModelOffsetGroupsStore = defineStore('modelOffsetGroups', {
  state: () => {
    return {
      groups: {}
    }
  },

  actions: {

    /**
     * Сгруппировать модели по смещениям
     */
    calcModelOffsetGroups() {
      const modelList = store.getters['project/flatlist']
      const grouped = {}
      let groupColorIndex = 0
      for (const model of modelList) {
        let foundKey = null
        // Если нет ни ревизии, ни облака точек, используется уникальный ключ группировки
        if (!model.revision && !model.pointCloud) {
          if (!grouped[NO_REVISION]) { 
            grouped[NO_REVISION] = [] 
          }
          grouped[NO_REVISION].push({ 
            uuid: model.uuid, 
            color: GRAY_COLOR 
          })
          continue
        }
        // Расчет центров смещений
        const koeff = model.revision?.koeff || 1000
        const bimitOffset = [model.realOffsetX, model.realOffsetY, model.realOffsetZ]
        const ifcOffset = model.revision?.bounds?.center || [0, 0, 0]
        const realOffset = bimitOffset.map((coord, index) => coord + (ifcOffset[index] / koeff))
        
        for (const key in grouped) {
          const keyOffset = key.split(", ")

          // Сравнить по границе OFFSET_THRESHOLD
          if (utils.areOffsetsClose(realOffset, keyOffset, OFFSET_THRESHOLD)) {
            foundKey = key
            break
          }
        }

        // Если оффсет попадает в рамки оффсета другой группы, записать в нее, иначе создать новую группу
        if (foundKey) {
          grouped[foundKey].push({ 
            uuid: model.uuid, 
            color: grouped[foundKey][0].color 
          })
        }
        else {
          const key = utils.offsetToString(realOffset)

          if (!grouped[key]) {
            grouped[key] = []
          }

          grouped[key].push({ 
            uuid: model.uuid, 
            color: COLORS[groupColorIndex] 
          })

          groupColorIndex++
        }
      }

      // Grouped to map modelUuid: { groupOffset, groupModelUuids }
      this.groups = utils.offsetsGroupingToMap(grouped)
      
    }
  }
})