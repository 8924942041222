import { $_app_server } from '@/_helpers'
import { App } from '@app/App'
import { ApiPresets } from '@/utils'
import { AlertService } from '@app/AlertService'
import { subscribePush, unsubscribePush } from '@/plugins/webPush/webPush'

export const authUserService = {
  getToken,
  refreshToken,

  login,
  logout,

  restore,
  changePassword,
  changeTariff,
  confirmRegistration,

  listGrantTypes,
  listTariffList,

  redirectLoginAccount,
  redirectLoginError,
  redirectLogin,
}

function login(username, password) {
  localStorage.removeItem("access_token")
  
  let security = new FormData();
  security.append("grant_type", 'password')
  security.append("username", username.trim().toLowerCase())
  security.append("password", password.trim())

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  
  return auth('oauth/token', security, headers)
}

function auth(url, security, headers) {
  return $_app_server.post(url, security, { '_guest': true, headers })
    .then(({ data }) => {
      App.auth.use(data)
      $_app_server.defaults.headers.common.Authorization = 'Bearer ' + App.auth.accessToken
      subscribePush()
      return true
    })
    .catch(error => {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          logout()
          
          App.auth.use({access_token: null})
          location.reload()
          return false
        }
      }
    })
}

function logout(info, error) {
  unsubscribePush()
  $_app_server.defaults.headers.common.Authorization = null

  App.auth.clear()

  App.auth.saveLocationHref()
  
  if (info) {
    AlertService.info({info:  info })
  }
  if (error) {
    AlertService.error({info:  error })
  }

  window.location = App.social.authserver + "/logout?logout_uri=" + App.social.logout
}

function restore (username) {
  return $_app_server.post('oauth/restore', {login:username.trim().toLowerCase()}, ApiPresets.GUEST).then(response => response.data)
}

function confirmRegistration (obj) {
  return $_app_server.post('oauth/confirm', obj, ApiPresets.GUEST).then(response => response.data)
}

function changePassword (profileUuid, obj) {
  return $_app_server.put(`profiles/${profileUuid}/change/password`, obj).then(response => response.data)  
}

function changeTariff (profileUuid, tariff) {
  return $_app_server.patch(`profiles/${profileUuid}/tariff/${tariff}`).then(response => response.data)  
}

function listGrantTypes () {
  return $_app_server.get('oauth/granttypes').then(response => response.data)
}
function listTariffList () {
  return $_app_server.get('tarifflist').then(response => response.data)
}

function getToken(request) {
  let security = new FormData();
  security.append("grant_type", 'authorization_code')
  security.append("code", request.code)
  security.append("redirect_uri", request.redirect_uri)

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }

  return auth('oauth/token', security, headers)
}

function refreshToken () {
  let security = new FormData();
  security.append("grant_type", 'refresh_token')
  security.append("refresh_token", App.auth.refreshToken)

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Authorization': '',
  }

  return $_app_server.post('oauth/token', security, { '_guest': true, headers })
  .then(response => {
    App.auth.use(response.data)
  })
}

function redirectLoginAccount(accountType) {
  window.location = window.settings.social.authserver + "/login?account=" + accountType
}

function redirectLoginError (errorType) {
  window.location = window.settings.social.authserver + "/login?error=" + errorType
}

function redirectLogin () {
  // TODO: вырезаем fromext, при переходе на /login -> редиректим на /authserver/login
  // window.location = "/login?fromext"
  window.location = "/login"
}

