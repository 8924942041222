import { api } from '@/api'
import { ProjectShortcut } from '@models/Project'

const _makePorjectShortcut = obj => new ProjectShortcut(obj)

export default {
  namespaced: true,

  state: {
    projects: [],
    status: {
      isProjectsLoading: false,
    },

    selectedOwner: null,
    selectedMarkerColor: null,
    owners: []
  },

  getters: {
    isProjectsLoading: ({ status }) => !!status.isProjectsLoading,
    filteredProjects: ({ projects }) => projects,
    lastViewedProjects: ({ projects }) => {
      return projects
      .filter(oneProject => !!oneProject.myLastVisitTime)
      .sort(ProjectShortcut.compareByVisits)
      .slice(0, 4)
    }
  },

  mutations: {
    START_LOADING_PROJECTS (state) {
      state.status.isProjectsLoading = true
    },
    SET_LOADED_PROJECTS (state, list) {
      state.status.isProjectsLoading = false
      state.projects = list.map(_makePorjectShortcut)
    },

    selectOwner (state, owner) {
      state.selectedOwner = owner
    }, 

    selectMarkerColor (state, markerColor) {
      state.selectedMarkerColor = markerColor
    }, 

    setOwners (state, listOwners) {
      state.owners = listOwners
    }
  },

  actions: {
    init ({ dispatch }) {
      return dispatch('loadMyProjects')
    },

    async loadMyProjects ({ commit }) {
      commit('START_LOADING_PROJECTS')
      let list = await api.projects.getAvailableProjects()
      commit('SET_LOADED_PROJECTS', list)
    }
  }
}