/*jshint esversion: 6 */
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry';
import store from '@/store';

/*eslint-disable no-dupe-class-members*/
export class FrustumToggleElements {

  static #_isTabKeyPressed = false
  static #_canvasPos = []
  static #_currentWorldPos = []
  static #_lastWorldPos = []
  static #_entities = []
  static #_elNum = 0
  static #_drawedMeshes = []

  static #_onTabKeyDown = () => {}
  static #_onTabKeyUp = () => {}
  static #_onMouseMove = () => {}

  static get #_camera() {
    return XeokitMediator.viewer.camera
  }

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get #_canvas() {
    return XeokitMediator.viewer.scene.canvas
  }

  static #_toggleElements() {
    // Определяем выделяемую область
    this.#_canvasPos = this.#_scene.input.mouseCanvasPos
    const pickResult = XeokitMediator.ScenePick.pickResult({
      canvasPos: this.#_canvasPos,
      pickSurface: true
    });

    // Если координата существует
    if (pickResult) {
      this.#_currentWorldPos = [...pickResult.worldPos]
      if (this.#_currentWorldPos.toString() === this.#_lastWorldPos.toString()) {
        if (this.#_entities.length !== this.#_elNum + 1) {
          this.#_elNum += 1
        }
        else {
          this.#_elNum = 0
        }
        XeokitMediator.ElementsSelection.pickElement(this.#_entities[this.#_elNum].id)
        store.dispatch('axis/tree/fetchElementByGlobalId', this.#_entities[this.#_elNum].id) //TODO: УБРАТЬ
        XeokitMediator.ScenePick.pickElementByUuid(this.#_entities[this.#_elNum].id)
      }
      else {
        this.#_lastWorldPos = [...this.#_currentWorldPos]
        const scale = this.#_canvas.canvas.clientHeight / 715
        const selectionSquare = [
          this.#_canvasPos[0] - 80 * scale, // left
          this.#_canvasPos[0] + 80 * scale, // right
          this.#_canvasPos[1] + 80 * scale, // bottom
          this.#_canvasPos[1] - 80 * scale  // top
        ]

        // Создаем фрустум (переднюю плоскость доводим отдельно)
        let frustum = geometry.frustumToggleElements.createFrustum([...this.#_camera.eye], [...pickResult.worldPos], selectionSquare)

        // Находим все элементы, пересёкшие фрустум
        this.#_entities = geometry.frustum.findAllEntitiesIntersectsFrustum(frustum, selectionSquare, [...pickResult.worldPos])
        this.#_elNum = 0

        if (this.#_drawedMeshes.length === 0) {
          let planes = [...frustum.planes]
          let intersectionPoints = []
          intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[0], planes[2], planes[5]))
          intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[1], planes[2], planes[5]))
          intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[0], planes[3], planes[5]))
          intersectionPoints.push(geometry.intersection.threePlanesIntersectionPoint(planes[1], planes[3], planes[5]))

          let edges = [
            [intersectionPoints[0], intersectionPoints[1]],
            [intersectionPoints[1], intersectionPoints[3]],
            [intersectionPoints[3], intersectionPoints[2]],
            [intersectionPoints[2], intersectionPoints[0]]
          ]
          this.#_drawedMeshes = geometry.frustumToggleElements.drawEdges(edges)
        }
        this.#_scene.input.once("mousemove", this.#_onMouseMove = () => {
          this.#_clearDrawedMeshes()
        })

        XeokitMediator.ElementsSelection.pickElement(this.#_entities[this.#_elNum].id)
        store.dispatch('axis/tree/fetchElementByGlobalId', this.#_entities[this.#_elNum].id) //TODO: УБРАТЬ
        XeokitMediator.ScenePick.pickElementByUuid(this.#_entities[this.#_elNum].id)

        // -----------Отрисовка Frustum и выделение всей области------------ //

        // let frustumEdges = geometry.frustum.getFrustumEdges(frustum)
        // frustumEdges.forEach(coords => {
        //   geometry.drawEdge(coords)
        // })

        // let entitiesIds = []
        // for(let i = 0; i < this.entities.length; i++){
        //   entitiesIds.push(this.entities[i].id)
        // }
        //this.selectElements(entitiesIds)
      }
    }
  }

  static #_clearDrawedMeshes() {
    if (this.#_drawedMeshes) {
      this.#_drawedMeshes.forEach(edgeMesh => {
        edgeMesh?.destroy()
      })
    }

    this.#_drawedMeshes = [];
  }

  /**
   * Активирует прослушиватель нажатия клавиши Tab.
   * * Множественные нажатия Tab при неизменной позиции указателя мыши внутри {@link Canvas} приведут к переключению (подсветке) элементов
   * внутри небольшой усеченной пирамиды.
   * * Смена позиции указателя мыши и очередное нажатие Tab приведет к переключению элементов с начала.
   */
  static activate() {
    document.addEventListener("keydown", this.#_onTabKeyDown = (event) => {
      if (event.which === 9) {
        event.preventDefault();
        if(!this.#_isTabKeyPressed) {
          this.#_isTabKeyPressed = true
        }
      }
    })

    document.addEventListener("keyup", this.#_onTabKeyUp = () => {
      if (this.#_isTabKeyPressed) {
        this.#_toggleElements()
        this.#_isTabKeyPressed = false
      }
    })
  }

  /**
   * Отключение прослушивания нажатия Tab внутри {@link Canvas}
   */
  static deactivate() {
    document.removeEventListener("keydown", this.#_onTabKeyDown)
    document.removeEventListener("keyup", this.#_onTabKeyUp)
  }

  // ------------Для дальнейшего улучшения------------- //

  // static getDistanceBetweenFrustumVertexAndMeshEdges(frustum, mesh, centerPos){
  //   // Находим расстояние от точки фрустума до точки, ближайшей к ней на ребрах меша
  //   const triangles = this.getTrianglesByMesh(mesh)
  //
  //   let nearestCoords = this.getNearestTriangleCoord(triangles[0], centerPos)
  //   for(let i = 0; i < nearestCoords.length; i++){
  //
  //   }
  //
  // return geometry.distanceBetweenByCoordinates(centerPos, nearestCorner)
  // }

  // static getNearestTriangleCoord(triangle, vertex){
  //   triangle, vertex
  // }
  //
  // static getTrianglesByMesh(mesh) {
  //   const uniquePositions = [];
  //   const indicesLookup = [];
  //   const weldedIndices = [];
  //   const compa = new Uint16Array(3);
  //   const compb = new Uint16Array(3);
  //   const compc = new Uint16Array(3);
  //   const a = math.vec3();
  //   const b = math.vec3();
  //   const c = math.vec3();
  //
  //   weldVertices(mesh.positions, mesh.indices);
  //   return buildFaces(mesh.indices.length, mesh.positionsDecodeMatrix)
  //
  //   function weldVertices(positions, indices) {
  //     const positionsMap = {};
  //     let vx;
  //     let vy;
  //     let vz;
  //     let key;
  //     const precision = 10000;
  //     let i;
  //     let len;
  //     let lenUniquePositions = 0;
  //
  //     for (i = 0, len = positions.length; i < len; i += 3) {
  //       vx = positions[i];
  //       vy = positions[i + 1];
  //       vz = positions[i + 2];
  //       key = vx * precision + '_' + vy * precision + '_' + vz * precision;
  //       if (positionsMap[key] === undefined) {
  //         positionsMap[key] = lenUniquePositions / 3;
  //         uniquePositions[lenUniquePositions++] = vx;
  //         uniquePositions[lenUniquePositions++] = vy;
  //         uniquePositions[lenUniquePositions++] = vz;
  //       }
  //       indicesLookup[i / 3] = positionsMap[key];
  //     }
  //     for (i = 0, len = indices.length; i < len; i++) {
  //       weldedIndices[i] = indicesLookup[indices[i]];
  //     }
  //   }
  //
  //   function buildFaces(numIndices, positionsDecodeMatrix) {
  //     let triangles = []
  //     for (let i = 0, len = numIndices; i < len; i += 3) {
  //       const ia = ((weldedIndices[i]) * 3);
  //       const ib = ((weldedIndices[i + 1]) * 3);
  //       const ic = ((weldedIndices[i + 2]) * 3);
  //
  //       if (positionsDecodeMatrix) {
  //         compa[0] = uniquePositions[ia];
  //         compa[1] = uniquePositions[ia + 1];
  //         compa[2] = uniquePositions[ia + 2];
  //         compb[0] = uniquePositions[ib];
  //         compb[1] = uniquePositions[ib + 1];
  //         compb[2] = uniquePositions[ib + 2];
  //         compc[0] = uniquePositions[ic];
  //         compc[1] = uniquePositions[ic + 1];
  //         compc[2] = uniquePositions[ic + 2];
  //         // Decode
  //         if(mesh.entityMatrix) {
  //           math.decompressPosition(compa, positionsDecodeMatrix, a);
  //           geometry.mulMat4v3(mesh.entityMatrix, a, a);
  //           math.decompressPosition(compb, positionsDecodeMatrix, b);
  //           geometry.mulMat4v3(mesh.entityMatrix, b, b);
  //           math.decompressPosition(compc, positionsDecodeMatrix, c);
  //           geometry.mulMat4v3(mesh.entityMatrix, c, c);
  //         }
  //         else{
  //           math.decompressPosition(compa, positionsDecodeMatrix, a);
  //           math.decompressPosition(compb, positionsDecodeMatrix, b);
  //           math.decompressPosition(compc, positionsDecodeMatrix, c);
  //         }
  //       } else {
  //         a[0] = uniquePositions[ia];
  //         a[1] = uniquePositions[ia + 1];
  //         a[2] = uniquePositions[ia + 2];
  //         b[0] = uniquePositions[ib];
  //         b[1] = uniquePositions[ib + 1];
  //         b[2] = uniquePositions[ib + 2];
  //         c[0] = uniquePositions[ic];
  //         c[1] = uniquePositions[ic + 1];
  //         c[2] = uniquePositions[ic + 2];
  //       }
  //
  //       let t1 = geometry.worldPosToCanvasPos(geometry.transformByModelSettings([a[0], a[1], a[2]], mesh.model), XeokitMediator.viewer)
  //       let t2 = geometry.worldPosToCanvasPos(geometry.transformByModelSettings([b[0], b[1], b[2]], mesh.model), XeokitMediator.viewer)
  //       let t3 = geometry.worldPosToCanvasPos(geometry.transformByModelSettings([c[0], c[1], c[2]], mesh.model), XeokitMediator.viewer)
  //
  //       triangles.push([t1, t2, t3])
  //     }
  //     return triangles
  //   }
  // }

  // -------------------------------------------------- //

  // static selectElements(elementsIncludedIds) {
  //   store.commit('selected/setSelectedElements', [])
  //   store.commit('selected/setSelectedElements', elementsIncludedIds)
  // }


}