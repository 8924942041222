import BlankLayout from "@/layouts/Blank"

const taskRoutes = {
  path: '/task',
  component: BlankLayout,
  children: [
    {
      path: 'manager',
      component: () => import('@/pages/task/TaskManager.vue')
    }
  ]
}

export default taskRoutes
export { taskRoutes }