<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99806 20.159C8.82375 20.5993 7.99194 21.6921 7.99194 22.9806C7.99194 24.6442 9.37828 25.9979 11.0908 25.9979C12.7871 25.9979 14.1734 24.6442 14.1734 22.9806C14.1734 21.6269 13.26 20.4852 12.0042 20.11V14.32C12.3141 14.8746 12.6729 15.3965 13.0643 15.9021C14.4343 17.7288 16.1958 19.3761 18.365 20.7787C18.9359 21.5453 19.8492 22.0346 20.8768 22.0346C22.5893 22.0346 23.9756 20.6972 23.9756 19.0336C23.9756 17.37 22.5893 16.0163 20.8768 16.0163C19.523 16.0163 18.3813 16.8644 17.9573 18.0387C16.6525 17.0275 15.5597 15.9184 14.6464 14.7115C13.733 13.4719 12.9991 12.1345 12.4445 10.7155C13.4721 10.2262 14.1734 9.19872 14.1734 8.0081C14.1734 6.34449 12.7871 5.00708 11.0908 5.00708C9.37828 5.00708 7.99194 6.34449 7.99194 8.0081C7.99194 9.29658 8.82375 10.4057 9.99806 10.8297V20.159Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>