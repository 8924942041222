import { CoordinateArrow } from "@/plugins/viewerTools/tools/pickCoordinate/coordinatePin/coordinateArrow";
import { CoordinateShaft } from "@/plugins/viewerTools/tools/pickCoordinate/coordinatePin/coordinateShaft";
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";
import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { math } from "@xeokit/xeokit-sdk";

const PERSPECTIVE_SCALE_MULTIPLIER = 0.085 * 2
const ORTHO_SCALE_MULTIPLIER = 0.05 * 2

export class NormalPin {

  #scene; #node;
  #coordinateShaft; #coordinateArrow;
  #position; #origin; #quaternion; #scale; #matrix;
  #sceneTickListenerId

  constructor(scene, cfg = {}) {
    this.#scene = scene
    this.#node = null

    this.#coordinateShaft = null
    this.#coordinateArrow = null

    this.#position = cfg.position ?? [0, 0, 0]
    this.#origin = cfg.origin ?? [0, 0, 0]
    this.#scale = cfg.scale ?? [1, 1, 1]
    this.#quaternion = cfg.quaternion ?? [0, 0, 0, 0]
    this.#matrix = null

    this.#create()
  }

  #create() {
    this.#node = new XeokitNode(this.#scene)

    this.#coordinateArrow = new CoordinateArrow(this.#node, {
      color: [1, 0, 1],
      position: [0, 0, 0.75],
      rotation: [90, 0, 0],
      edges: true,
      fill: false
    })

    this.#coordinateShaft = new CoordinateShaft(this.#node, {
      color: [1, 0, 1],
      position: [0, 0, 0.4],
      rotation: [90, 0, 0],
      edges: true,
      fill: false
    })

    let lastDistance = 0
    const camera = this.#scene.camera

    this.#sceneTickListenerId = this.#scene.on("tick", () => {
      const distance = geometry.math.distance(camera.eye, this.#origin)

      if (distance != lastDistance) {
        if (camera.projection === "perspective") {
          const axisScale = distance * PERSPECTIVE_SCALE_MULTIPLIER
          const scale = [axisScale, axisScale, axisScale]
          this.#node.scale = scale
          
          lastDistance = distance;
        }
        else if (camera.projection === "ortho") {
          const size = camera.ortho.scale * ORTHO_SCALE_MULTIPLIER
          const scale = [size, size, size]
          this.#node.scale = scale
          
          lastDistance = distance;
        }
      }
    })
  }

  setCoordinate(position) {
    this.update({ position: position })
  }

  setNormal(normal) {
    const quaternion = geometry.math.quaternionByTwoVectors([0, 0, 1], normal)
    this.update({ quaternion: quaternion })
  }

  update(transform) {
    const origin = transform.position ?? this.#origin
    const position = [0, 0, 0]
    const scale = transform.scale ?? this.#scale
    const quaternion = transform.quaternion ?? this.#quaternion

    this.#origin = origin
    this.#position = position
    this.#scale = scale

    this.#matrix = math.composeMat4(position, quaternion, scale)
    this.#node.matrix = this.#matrix
    this.#node.origin = this.#origin
  }

  destroy() {
    this.#node?.destroy()
    this.#node = null

    this.#scene.off(this.#sceneTickListenerId)
  }
}