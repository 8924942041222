<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 21.928L11 30V19H21V21.928Z"
      fill="currentColor"
    />
    <path
      d="M28.5039 12.4997H9.5L2.4165 18.4962H21.4204L28.5039 12.4997Z"
      stroke="currentColor"
      stroke-width="0.67016"
      stroke-miterlimit="22.9256"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 10.136L21 2.504V18H11V10.136Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>