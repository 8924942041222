<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 24H24V0H0V24Z" fill="none" />
    <mask id="path-2-inside-1_3321_470" fill="#C4C4C4">
      <rect x="3" y="16" width="18" height="6" rx="1" />
    </mask>
    <rect
      x="3"
      y="16"
      width="18"
      height="6"
      rx="1"
      stroke="currentColor"
      stroke-width="4"
      mask="url(#path-2-inside-1_3321_470)"
    />
    <mask id="path-3-inside-2_3321_470" fill="#C4C4C4">
      <rect x="3" y="9" width="18" height="6" rx="1" />
    </mask>
    <rect
      x="3"
      y="9"
      width="18"
      height="6"
      rx="1"
      stroke="currentColor"
      stroke-width="4"
      mask="url(#path-3-inside-2_3321_470)"
    />
    <mask id="path-4-inside-3_3321_470" fill="#C4C4C4">
      <rect x="3" y="2" width="18" height="6" rx="1" />
    </mask>
    <rect
      x="3"
      y="2"
      width="18"
      height="6"
      rx="1"
      stroke="currentColor"
      stroke-width="4"
      mask="url(#path-4-inside-3_3321_470)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>