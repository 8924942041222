import { EquipmentGroup, EquipmentUtils } from '.'

export class EquipmentTree {
  constructor (tree = []) {
    this.onlyNodeIds = []
    this.items = tree.map(i => new EquipmentGroup(i))
    this.flatlist = EquipmentUtils.flatlist(this.items, this.onlyNodeIds)
  }

  resetFlatlist() {
    this.onlyNodeIds = []
    this.flatlist = EquipmentUtils.flatlist(this.items, this.onlyNodeIds)
  }
}