<template lang="pug">
  .app-group-item(:class="classes")
    slot
</template>

<script>
export default {
  name: 'app-group-item',
  props: {
    cols: {
      type: [Number, String],
      default: 0
    }
  },

  computed: {
    classes () {
      if (this.cols == 3) return this.cols > 0 ? `flex xs12 sm6 md4 lg3` : ''
      if (this.cols == 4) return this.cols > 0 ? `flex xs12 sm6 lg4` : ''
      return this.cols > 0 ? `xs${this.cols}` : ''
    }
  }
}
</script>

<style>
.app-group-item {
  padding-left: 16px;
  padding-right: 16px;
  transition: all 0.5s;
}
</style>