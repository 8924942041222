
import { api } from '@/api'
import i18n from '@/plugins/i18n';
import { AlertService } from '@app/AlertService'

let flatlistTreeModel = function (tree, level = 0) {
  let array = []
  tree.forEach(element => {
    element.level = level
    array.push(element, ...flatlistTreeModel(element.model, level + 1))
  })
  return array
}

let convertDateSize = function (item) {
    let i = Math.floor(Math.log(item.size) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    item.sizeText = (item.size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
    if (i18n.locale === 'ru' && item.lastModify) {
      let date = new Date(item.lastModify)
      let month = date.getMonth() + 1
      let minutes = date.getMinutes()

      month = month < 10 ? '0' + month : month 
      minutes = minutes< 10 ? '0' + minutes : minutes
      
      item.lastModify = date.getDate() + '.' + month + '.' + date.getFullYear() + ' ' + date.getHours() + ':' + minutes
    }
    return item
}

let cloudTreeChild = function (data) {
  return data.map( item1 => {
    if (item1.type == 1) item1.children = [] //type = 1 - folder
    item1 = convertDateSize(item1)
    return item1
  }) 
}

let cloudTree = function (tree, item, data) {
  if (tree) {
    tree = tree.map(parent => {
      if (parent.type == 1) {
        if (parent.path === item.path) parent.children = cloudTreeChild(data).sort((a, b) => b.type - a.type)
        else  parent.children = cloudTree(parent.children, item, data)
      }
      return parent
    })
  } 
  else tree = cloudTreeChild(data)

  return tree
}

const getDefaultState = () => {
  return {
    cloudProjectUuid: null,
    project: null,

    cloudWebDavTree: [],
    cloudWebDavError: null,
    loading: false,
    listProjects: [],

    cloudSettings:null
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  state,

  getters: {
    flatlistTreeModel: ({ project }) => project ? flatlistTreeModel(project.model) : [],
    cloudWebDavTree: ({ cloudWebDavTree }) => cloudWebDavTree.sort((a, b) => b.type - a.type),
    loading: ({ loading }) => loading,
    listProjects: ({ listProjects }) => listProjects,
    cloudSettings: ({ cloudSettings }) => cloudSettings
  },

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setProject: (state, payload) => {
      state.project = payload
    },
    setCloudProjectUuid: (state, payload) => {
      state.cloudProjectUuid = payload
    },

    setCloudWebDavTree: (state, payload) => {
      state.cloudWebDavTree = payload
    },

    setCloudWebDavError: (state, payload) => {
      state.cloudWebDavError = payload
    },
    setLoading: (state, payload) => {
      state.loading = payload
    },
    setListProjects: (state, payload) => {
      state.listProjects = payload
    },
    setCloudSettings:(state, payload) => {
      state.cloudSettings = payload
    }
  },

  actions: {
    getTreeModel: ({ commit }, projectUuid) => {
      return api.projects.getTreeModel(projectUuid).then(data => {
        commit('setProject', data)
      })
    },

    getCloudSettings: ({ commit }, project) => {
      commit
      if (project.organization) {
        return api.corp.orgCloud(project.organization.uuid).then(data => {
          if (data === 'not_access_to_cloud') {
            AlertService.error({ data: { error_description: "У вас нет доступа к облаку данной организации!" } })
          } 
          else {
            let {cloudUrl, cloudAuth} = data;
            if (cloudUrl && cloudAuth) {
              cloudAuth = atob(cloudAuth).split(':')
              if (cloudAuth.length == 2) {
                let settings = {
                  cloudUrl,
                  username:cloudAuth[0],
                  passwd:cloudAuth[1],
                  cloudProvider: 'nextCloud'
                }
                api.nextCloud.updateCloudSettings(settings)
                commit('setCloudSettings', settings)
                // return [cloudUrl, ...cloudAuth]//delete this later
                return settings
              }
            }

            AlertService.error({ data: { error_description: "Ошибка при получении настроек пользователя nextcloud" } })
          }
        })
        .catch(() => {
          AlertService.error({ data: { error_description: "Ошибка при получении настроек пользователя nextcloud" } })
        })
      } 
      else {
        AlertService.error({ data: { error_description: "Необходимо указать организацию в окне паспорта проекта!" }})
      }
    },

    convert: ({ commit, rootGetters }, cloudProjectUuid) => {
      let hashProject = rootGetters['project/hashProject']

      commit('setLoading', true)
      commit('setCloudProjectUuid', cloudProjectUuid)
      return api.projects.convert(cloudProjectUuid, hashProject).then( data => {
        let urlOpen = location.origin + "/project/" + data.uuid
        console.log("convert urlOpen", urlOpen);
        // window.open(urlOpen, '_blank');
        // window.history.go(-2);
        window.location.href = urlOpen
        commit('setLoading', false)
      })
    },

    addCloudModelToProject: ({ state, commit, rootGetters }, obj) => {
      let hashProject = rootGetters['project/hashProject']

      commit('setCloudProjectUuid', obj.projectUuid)
      commit('setLoading', true)
      return api.projects.addModelToProject(obj.modelUuid, obj.projectUuid, hashProject).then(() => {
        let urlOpen = location.origin + "/project/" + state.project.uuid
        console.log("ModelToProject urlOpen", urlOpen);
        // window.open(urlOpen, '_blank');
        // window.history.go(-2);
        window.location.href = urlOpen
        commit('setLoading', false)
      }).catch(e => {
        if (e.response.data.error === "project_has_author") {
          let urlOpen = location.origin + "/project/" + state.project.uuid
          console.log("catch ModelToProject urlOpen", urlOpen);
          // window.open(urlOpen, '_blank');
          // window.history.go(-2);
          window.location.href = urlOpen
        }
      })
    },

    cloudWebDavSearch: ({ commit, state }, obj) => {
      commit('setCloudWebDavTree', [])
      commit('setCloudWebDavError', null)
      return api.cloud.search(obj).then( data => {
        data = data.map(item => {
          if (item.type==1) item.children = []
          item = convertDateSize(item)
          return item
        }) || []
        commit('setCloudWebDavTree', data)
        return true
      })
      .catch(e => {
        commit('setCloudWebDavTree', [])
        commit('setCloudWebDavError', e.response.data)
        console.log('in vuex', state.cloudWebDavError)
        AlertService.error(e.response)
      })
    },

    cloudWebDavSearchByPath: ({ state, commit }, { item, cloudWebDav }) => {
      return new Promise((resolve, reject) => {
        commit('setCloudWebDavError', null)
        api.cloud.search(cloudWebDav).then(data => {
          let list = cloudTree(state.cloudWebDavTree, item, data)
          commit('setCloudWebDavTree', list)
          resolve()
        }).catch(e => {
          commit('setCloudWebDavError', e.response.data)
          AlertService.error(e.response)
          reject()
        })
      })
    },

    cloudWebDavDownloadFile: ({ commit }, cloudWebDav) => {
      commit('setCloudWebDavError', null)
      return api.cloud.simpleDownload(cloudWebDav).then(data => {
        let a = document.createElement("a")
        a.href = URL.createObjectURL(data)
        a.download = cloudWebDav.name
        a.click()
      })
      .catch(e => {
        commit('setCloudWevDavError', e.response.data)
        AlertService.error(e.response)
      })
    },

    cloudWebDavCreateFolder: ({ commit, dispatch }, { item, cloudWebDav }) => {
      commit('setCloudWebDavError', null)
      return api.cloud.createFolder(cloudWebDav).then(() => {
        if (item.path.includes("/remote.php/dav/files/")) {
          cloudWebDav.path = item.path
          dispatch("cloudWebDavSearchByPath", { item: item, cloudWebDav: cloudWebDav })
        } else {
          cloudWebDav.path = null
          dispatch("cloudWebDavSearch", cloudWebDav )
        }
        
      }).catch(e => {
        commit('setCloudWevDavError', e.response.data)
        AlertService.error(e.response)
      })
    },

    cloudWebDavDeleteItem: ({ commit, dispatch }, { item, cloudWebDav }) => {
      commit('setCloudWebDavError', null)
      return api.cloud.deleteItem(cloudWebDav).then(() => {
        if (item.type == 1) {
          item.path = item.path.replace(/[^/]+\/$/g, '') // Исправление на несовместимость Safari до 16.3 версии
        }
        else {
          item.path = item.path.replace(/[^/]+$/g, '') // Исправление на несовместимость Safari до 16.3 версии
        }
        if (item.path === `/remote.php/dav/files/${cloudWebDav.username}/`) { //TODO: в будущем учесть, что root папка может начинаться не с '/'
          cloudWebDav.path = null
          dispatch("cloudWebDavSearch", cloudWebDav)
        } else {
          cloudWebDav.path = item.path
          dispatch("cloudWebDavSearchByPath", { item: item, cloudWebDav: cloudWebDav })
        }
      }).catch(e => {
        commit('setCloudWevDavError', e.response.data)
        AlertService.error(e.response)
      })
    },

    cloudWebDavUploadFile: ({ commit, dispatch }, { item, cloudWebDav, formData }) => {
      commit('setCloudWebDavError', null)
      return api.cloud.upload(formData).then(() => {
        if (item.path.includes("/remote.php/dav/files/")) {
          cloudWebDav.path = item.path   
          dispatch("cloudWebDavSearchByPath", { item: item, cloudWebDav: cloudWebDav })
        } else {
          cloudWebDav.path = null
          dispatch("cloudWebDavSearch", cloudWebDav )
        }
      }).catch(e => {
        commit('setCloudWevDavError', e.response.data)
        AlertService.error(e.response)
      })
    },

    cloudWebDavUpdateTree: ({ dispatch }, { item, cloudWebDav }) => {
      item.path = item.path.replace(/[^/]+$/g, '') // Исправление на несовместимость Safari до 16.3 версии
      if (item.path === `/remote.php/dav/files/${cloudWebDav.username}/`) { //TODO: в будущем учесть, что root папка может начинаться не с '/'
        cloudWebDav.path = null
        dispatch("cloudWebDavSearch", cloudWebDav)
      } else {
        cloudWebDav.path = item.path
        dispatch("cloudWebDavSearchByPath", { item: item, cloudWebDav: cloudWebDav })
      }
    },

    cloudGoogleDownloadFile: ({ commit }, cloudWebDav) => {
      commit('setCloudWebDavError', null)
      return api.cloud.downloadGoogle(cloudWebDav).catch(e => {
        commit('setCloudWevDavError', e.response.data)
        AlertService.error(e.response)
      })
    },

    cloudListProjectsByHash: ({ commit, rootGetters }) => {
      commit('setCloudWebDavError', null)
      let hashProject = rootGetters['project/hashProject']
      return api.projects.cloudListProjectsByHash(hashProject).then( data => {
        commit('setListProjects', data)
      }).catch(e => {
        commit('setCloudWevDavError', e.response.data)
        AlertService.error(e.response)
      })
    },



  }
}