<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 17.5V15H5V17.5C5 17.7761 5.22386 18 5.5 18H18.5C18.7761 18 19 17.7761 19 17.5V15H20V17.5C20 18.3284 19.3284 19 18.5 19H5.5C4.67157 19 4 18.3284 4 17.5Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 12.0858V4H13V12.0858L15.2929 9.79289L16.7071 11.2071L12 15.9142L7.29289 11.2071L8.70711 9.79289L11 12.0858Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {};
</script>

<style>
</style>