import { PanelController } from "@/pages/panelController"
import { Map } from "core-js"
import { toNumber } from "lodash"


let styleConfigurator = function(width, height) {
  let unit = height != undefined && typeof height === 'string' && height.indexOf('px') != -1 ? "" : "px"
  let h = height != undefined ? height + unit : "100%"
  return `width: ${width}px; height: ${h};`
}

let status = {
  UNIT: "UNIT",
}

let orientationsConst = {
  RIGHT: "RIGHT",
  LEFT: "LEFT",
  TOP: "TOP",
  BOTTOM: "BOTTOM"
}

class Panel {
  constructor (panel) {
    this.panelDom = document.getElementById(panel.id)
    this.orientation = panel.orientation
    this.width = panel.width
    this.height = panel.height 
    this.exist = panel.exist
    this.minWidth = panel.minWidth
    this.minHeight = panel.minHeight
    this.hideControls = panel.hideControls;
  }
}

export default {

  namespaced: true,

  state: {

    layoutState: status.UNIT,

    editActive: false,

    startedMousePosition: {
      x: null,
      y: null
    },

    mouseOnDeckPosition: {
      x: null,
      y: null
    },

    panels: new Map(),

    editablePanel: String,

    layoutDeckSize: {
      height: null,
      width: null
    },

    orientations: {
      left: 0,
      right: 0
    },

    axis: null,
    detailsWidth: 0,

    layersLinks: {
      right: null,
      left: null,
      top: null,
      bottom: null
    },

    fromCollision: false,

    collisionTable: {
      height: 0,
      active: false
    }
  },

  getters: {
    panel: ({panels, editablePanel}) => { return panels.get(editablePanel).panelDom },
    panelDataById: ({panels}) => (id) => { return panels.get(id) },
    panelWidth: ({panels}) => (id) => { return panels.get(id)?.width},
    panelHeight: ({panels}) => (id) => { return panels.get(id)?.height},
  },

  mutations: {
    activate(state) {
      state.editActive = true
    },

    deactivate(state) {
      state.editActive = false
    },

    setLayoutDeckSize(state, size) {
      state.layoutDeckSize.height = size.height
      state.layoutDeckSize.width = state.width
    },
   
    removePanel(state, id) {
      id === 'collision' && (state.collisionTable.active = false)
      state.panels.get(id).exist = false
    },

    setMouseOnDeckPosition(state, {x, y}) {
      state.mouseOnDeckPosition.x = x
      state.mouseOnDeckPosition.y = y
    }, 

    setStartedMousePosition(state, {x, y}) {
      state.startedMousePosition.x = x
      state.startedMousePosition.y = y
    },

    setEditablePanel(state, id) {
      state.editablePanel = id
    },

    updateOrientationsPanels (state, {orientation, count}) {
      if (orientation == orientationsConst.LEFT) state.orientations.left += count
      else if (orientation == orientationsConst.RIGHT) state.orientations.right += count
    },

    setHorizontalAxis (state) {
      state.axis = 'horizontal'
    },

    setVerticalAxis (state) {
      state.axis = 'vertical'
    },

    setRightLayer (state, right) {
      state.layersLinks.right = right
    },

    setLeftLayer (state, left) {
      state.layersLinks.left = left
    },
    setTopLayer (state, top) {
      state.layersLinks.top = top
    },

    setBottomLayer (state, bottom) {
      state.layersLinks.bottom = bottom
    },

    setWidthData (state, obj) {
      if (!state.panels.get(obj.id)) return 
      state.panels.get(obj.id).width = obj.width
    },
    
    setFromCollision (state, payload) {
      state.fromCollision = payload
    }
  },  

  actions: {
    initLayers ({ commit }, {right, left, top, bottom}) { 
      commit('setRightLayer', right)
      commit('setLeftLayer', left)
      commit('setTopLayer', top)
      commit('setBottomLayer', bottom)
    },

    startEdit({ commit }, { id, position, axis }){
      commit('activate')
      commit('setEditablePanel', id)
      commit('setStartedMousePosition', {x: position?.x, y: position?.y})

      switch (axis) {
        case 'horizontal' : {
          commit('setHorizontalAxis')
          break
        }
        case 'vertical' : {
          commit('setVerticalAxis')
          break
        }
      }
    },

    stopEdit({ commit }) {      
      commit('deactivate')
      commit('setEditablePanel', null)
      commit('setStartedMousePosition', {x: null, y: null})
      commit('setMouseOnDeckPosition', {x: null, y: null})
      PanelController.setPanelPadding(true)
    },

    addPanel({state, commit, dispatch}, data) {
      let panel = state.panels.get(data.id)
      if(!panel){
        state.panels.set(data.id, new Panel(data)) 
      }
      else{
        state.panels.get(data.id).exist = true
        state.panels.get(data.id).panelDom = document.getElementById(data.id)
      }
      commit('updateOrientationsPanels', {orientation: data.orientation, count: 1})

      dispatch('setWidth', {id: data.id, width: state.panels.get(data.id).width})
      dispatch('setHeight', {id: data.id, height: state.panels.get(data.id).height})

      let exists = []

      for (let panel of state.panels.keys()) {
        if (state.panels.get(panel).exist) exists.push(panel)
      }
    },

    removePanel ({state, commit, getters }, id) {
      commit('removePanel', id)
      let exists = []
      for (let panel of state.panels.keys()) {
        if (state.panels.get(panel).exist) exists.push(panel)
      }
      commit('updateOrientationsPanels', {orientation: getters.panelDataById(id).orientation, count: -1})
    },

    changeSize({ state, rootState, getters, dispatch }) {
      let editablePanel = getters.panelDataById(state.editablePanel)

      let exists = []

      for (let panel of state.panels.keys()) {
        if (state.panels.get(panel).exist) exists.push(panel)
      }

      if (state.editablePanel == 'tasks' && rootState.task.taskDisplay == 'dashboard') return 

      if (!(state.mouseOnDeckPosition.x && state.startedMousePosition.x)) return

      let oldWidth = (toNumber(getters.panel.style.width.substring(0, getters.panel.style.width.indexOf('px'))))
      let newWidth = oldWidth 
      let maxWidth = window.innerWidth - 150

      switch (state.panels.get(state.editablePanel).orientation) {
        case "LEFT" : {
          newWidth += state.mouseOnDeckPosition.x - state.startedMousePosition.x
          break;
        }
        case "RIGHT" : { 
          newWidth += state.startedMousePosition.x - state.mouseOnDeckPosition.x 
          break;
        }
      }

      if (!(editablePanel.minWidth < newWidth)) return

      if (newWidth > maxWidth) return
      dispatch('setWidth', {id: state.editablePanel, width: newWidth})
    },

    changeVerticalSize ({ state, getters, dispatch }) {
      if (!(state.mouseOnDeckPosition.x && state.startedMousePosition.x)) return

      let oldHeight = (toNumber(getters.panel.style.height.substring(0, getters.panel.style.height.indexOf('px'))))
      let newHeight = oldHeight + state.startedMousePosition.y - state.mouseOnDeckPosition.y

      if (newHeight < getters.panelDataById(state.editablePanel).minHeight) return 

      dispatch('setHeight', {id:state.editablePanel, height: newHeight})
    },

    setWidth ({state}, {id, width}) {
      if (!state.panels.get(id)) return 

      state.panels.get(id).width = width
      state.panels.get(id).panelDom?.setAttribute('style',  styleConfigurator(state.panels.get(id).width))

      if (id == 'taskdetails') {
        state.detailsWidth = width
      }
    },

    setHeight ({state}, {id, height}) {
      if (!state.panels.get(id)) return

      if (id === 'collision') {
        state.collisionTable.height = height,
        state.collisionTable.active = true
      }
      
      state.panels.get(id).height = height
      state.panels.get(id).panelDom?.setAttribute('style',  styleConfigurator(state.panels.get(id).width, state.panels.get(id).height))
    },

    setWidthHeight({state}, {id, width, height}) {

      if (!state.panels.get(id)) return 
      
      state.panels.get(id).width = width
      state.panels.get(id).height = height
      state.panels.get(id).panelDom?.setAttribute('style',  styleConfigurator(state.panels.get(id).width, state.panels.get(id).height))
    },
  }
}