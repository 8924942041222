export class AxisUtils {
  static flatlist (tree) {
    let array = []
    tree.forEach(node => {
      array.push(node)
      AxisUtils.flatlist(node.children).forEach(childNode => {
        array.push(childNode)
      })
    })
    return array
  }

  // TODO: Удалить? Вроде нигде не используется
  static flatlistReducer (list, tree) {
    for (const node of tree) {
      list.push(node, ...AxisUtils.flatlist(node.children))
    }
    return list
  }
  
  static onlyNodesReducer(list, node) {
    if (node.children.length) {
      list.push(node)
      node.children.reduce(AxisUtils.onlyNodesReducer, list)
    }
    return list
  }
  
  static onlyEndpointsReducer(list, node) {
    if (node.children.length) node.children.reduce(AxisUtils.onlyEndpointsReducer, list)
    else list.push(node)
    return list
  }

  static findUUIDsStack (list, endpoint) {
    for (let idx = 0; idx < list.length; idx++) {
      const node = list[idx];
      
      if (node.uuid == endpoint.uuid && node.extraId == endpoint.extraId && node.globalId == endpoint.globalId) return [node.uuid]
      let stack = AxisUtils.findUUIDsStack(node.children, endpoint)
      if (stack) {
        stack.unshift(node.uuid)
        return stack
      }
    }
    return null
  }

  static pathParents(childArray, node) {
    if (node && node.uuid !== undefined) {
      childArray.unshift(node)
      AxisUtils.pathParents(childArray, node.parent)
    }
  }

  static pathChilds(arraysList, node) {
    if (node.children && node.children.length != 0) {
      node.children.forEach(child => {
        AxisUtils.pathChilds(arraysList, child)
      })
    } else {
      let array = []
      AxisUtils.pathParents(array, node)
      arraysList.push(array);
    }
  }
  static pathNodesList (nodes) {
    let arraysList = []
    nodes.forEach(node => {
      AxisUtils.pathChilds(arraysList, node);
    })
    return arraysList
  }
}