import { TaskAttachment } from "./TaskAttachment"
import { TaskWorker } from "./TaskWorker"
import { TaskSmeta } from "./TaskSmeta"
import { TaskReport } from "./TaskReport"
import { TaskType } from "./TaskType"

export class Task {
  constructor (obj, parent = null, level = 0, smeta = null) {
    this.attachments = getAttachmentsFromTask( obj.attachments, obj.uuid )
    this.collision = obj.collision
    this.rule = obj.rule
    this.attribute = obj.attribute
    this.comment = getCommentsFromTask( obj.comment )
    this.completeStatus = obj.completeStatus? new TaskCompleteStatus( obj.completeStatus ) : new TaskCompleteStatus( { name:'NEW' } )
    this.pluginStatus = obj.pluginStatus

    // this.createDate = obj.startDate
    // this.endDate = obj.stopDate

    // TODO: КОСТЫЛЬ, ТРЕБУЕТ ВНИМАНИЯ
    // this.createDate = obj.createDate
    // this.startDate = obj.startDate
    // this.stopDate = obj.stopDate
    // this.endDate = obj.stopDate

    this.createDate = obj.createDate
    this.startDate = obj.startDate ? obj.startDate : obj.createDate
    this.endDate = obj.stopDate ? obj.stopDate : obj.createDate

    this.sortOrder = obj.sortOrder

    
    this.creator = new TaskWorker( obj.creator )
    this.description = obj.description
    this.entityId = obj.entityId
    this.models = obj.models
    this.name = obj.name
    this.priority = new TaskPriority( obj.priority )
    this.responder = obj.responder
    this.taskType = obj?.objType || new TaskType( obj.objType )
    this.execType = obj.execType ? obj.execType : { name: "PAR" }
    this.title = obj.title
    this.uuid = obj.uuid
    this.workers = getWorkersFromTask( obj.workers )
    this.coordinates = new TaskСoordinates( obj.worldX, obj.worldY, obj.worldZ )
    
    this.parent = parent
    this.level = level
    this.children = obj.children ? this.childrenMapping(obj.children) : null
    this.smeta = new TaskSmeta(smeta)
    this.archived = obj.archived

    this.report = obj.report ? new TaskReport(obj.report) : new TaskReport()

    this.progress = obj.report ? this.progressСalculation( obj.report ) : 0

    this.classificatorNodeUuid = obj.classificatorNodeUuid

    this.group = obj.group || this.children?.length > 0
    this.groupWorkers = obj.groupWorkers
    this.groupResponder = obj.groupResponder
    this.maintenanceTaskUuid = obj.maintenanceTaskUuid
    this.maintenanceTask = obj.maintenanceTask
  }

  childrenMapping (children) {
    let filtered = children?.filter(task => task.archived == false )
    return filtered?.map(task => new Task(task, this.uuid, this.level + 1))
  }

  convertToFlatList () {
    let array = [this]
    if (this.children && this.children.length > 0) {
      this.children.forEach(child =>{
        if (!child.archived) array.push(...child.convertToFlatList())
      })
    }
    return array
  }

  progressСalculation( report ) {
    let volumeSum = 0
    if(report.volumes){
      report.volumes.forEach(volume => {
        volumeSum += volume.value
      })
      if(report.estimation==0){
        if(volumeSum>0) return 100
        else return 0
      }
      
      return  Number((parseFloat(volumeSum) / parseFloat(report.estimation) * 100).toFixed(4))
    }
    else{
      return 0
    }
    
  }
}

class TaskPriority {
  constructor( obj ) {
    if(obj) {
      this.color = obj.color
      this.name = obj.name
      this.title = obj.title
      this.value = obj.value
    }
  }
}

class TaskCompleteStatus {
  constructor( obj ) {
    this.name = obj.name
    this.title = obj.title
    this.value = obj.value
    this.message = obj.message
  }
}

class TaskСoordinates {
  constructor ( worldX, worldY, worldZ ) {
    this.worldX = worldX
    this.worldY = worldY
    this.worldZ = worldZ
  }
}

class TaskComment {
  constructor( obj ) {
    this.createDate = obj.createDate
    this.text = obj.text
    this.uuid = obj.uuid
    this.quotedCommentUuid = obj.quotedCommentUuid
    this.creator = new TaskWorker( obj.author )
  }
}

function getAttachmentsFromTask(attachments, taskUuid) {
  return attachments?.map(attachment => (new TaskAttachment(attachment, taskUuid)))
}

function getWorkersFromTask(workers) {
  return workers?.map(worker => (new TaskWorker(worker)))
}

function getCommentsFromTask(comments) {
  return comments?.map(comment => (new TaskComment(comment)))
}
