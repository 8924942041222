import { ObjectsMemento } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es'
// import { math } from '@xeokit/xeokit-sdk';
import { XeokitMediator } from '../XeokitMediator';

// const color$2 = math.vec3();

ObjectsMemento.prototype.restoreObjects = function (scene) {
    const mask = this._mask;

    const visible = (!mask || mask.visible);
    const edges = (!mask || mask.edges);
    const xrayed = (!mask || mask.xrayed);
    const highlighted = (!mask || mask.highlighted);
    const selected = (!mask || mask.selected);
    const clippable = (!mask || mask.clippable);
    const pickable = (!mask || mask.pickable);
    // const colorize = (!mask || mask.colorize);
    const opacity = (!mask || mask.opacity);

    var i = 0;

    const objects = scene.objects;

    for (let objectId in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, objectId)) {
            const object = objects[objectId];
            if (visible) {
                object.visible = this.objectsVisible[i];
            }
            if (edges) {
                object.edges = this.objectsEdges[i];
            }
            if (xrayed) {
                object.xrayed = this.objectsXrayed[i];
            }
            if (highlighted) {
                object.highlighted = this.objectsHighlighted[i];
            }
            if (selected) {
                object.selected = this.objectsSelected[i];
            }
            if (clippable) {
                object.clippable = this.objectsClippable[i];
            }
            if (pickable) {
                object.pickable = this.objectsPickable[i];
            }
            // if (colorize) {
            //     if (this.objectsHasColorize[i]) {
            //         color$2[0] = this.objectsColorize[i * 3 + 0];
            //         color$2[1] = this.objectsColorize[i * 3 + 1];
            //         color$2[2] = this.objectsColorize[i * 3 + 2];
            //         object.colorize = color$2;
            //     } else {
            //         object.colorize = null;
            //     }
            // }
            if (opacity) {
                object.opacity = this.objectsOpacity[i];
            }
            i++;
        }
    }

    XeokitMediator.clearColorizeModel()
}