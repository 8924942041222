import { $_notification_server } from '@/_helpers'

export const languagesService = {
  getAllAliases,
  getAllLocales,
  createLocale,
  createAlias,
  createTranslation,
  editTranslation,
  exportTranslation,
  importTranslation
}

function exportTranslation(localeText) {
  return $_notification_server.get(`languages/export/${localeText}`).then(response => response.data)
}

function importTranslation(importForm) {
  let formData = new FormData()
  formData.append("file", importForm.file)
  formData.append("locale", importForm.locale)
  return $_notification_server.post(`languages/import`, formData, { 
    headers: {'Content-Type': 'multipart/form-data '}}).then(response => response.data)
}


/*function importTranslation(localeText, file) {
  let formData = new FormData()
  formData.append("file", file)
  formData.append("locale", localeText)
  return $_notification_server.post(`languages/import`, formData).then(response => response.data)
}*/

function getAllAliases() {
  return $_notification_server.get(`languages/aliases`).then(response => response.data)
}

function getAllLocales() {
  return $_notification_server.get(`languages/locales`).then(response => response.data)
}

function createLocale(locale)  {
  return $_notification_server.post(`languages/locale`, locale).then(response => response.data)
}

function createAlias(alias) {
  return $_notification_server.post(`languages/alias`, alias).then(response => response.data)
}

function createTranslation(translation){
  return $_notification_server.post(`languages/translation`, translation).then(response => response.data)
}

function editTranslation(translation) {
  return $_notification_server.put(`languages/translation`, translation).then(response => response.data)
}

