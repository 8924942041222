import { api } from "@/api"

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

const group = {
  PROJECT:{title: "drawer.project", name: "PROJECT"},
  MODEL:{title: "section.structure.model.header", name: "MODEL"},
  COLLISION: {title: "module.notification.collision", name: "COLLISION"},
  TASK: {title: "module.notification.task", name: "TASK"},
  // DEFAULT: {title: "По умолчанию", name: "DEFAULT"}
  SMETA: {title: "module.task.estimate", name: "SMETA"},
  MAINTENANCE: {title: "section.maintenance.title", name: "MAINTENANCE"},
  TEAM: {title: "section.structure.model.team", name: "TEAM"}

}

const fnTeam = {
  TEAM_ADD:{ name: "TEAM_ADD" },
  TEAM_EDIT:{ name: "TEAM_EDIT" },
  TEAM_DELETE: { name: "TEAM_DELETE" },
}

export default {
  namespaced: true,

  state: {
    roles: {
      list: [],
      status: status.INITIAL
    },
    roleFunctions: {
      list: [],
      status: status.INITIAL
    },
    statusDelete: null
  },

  getters: {
    group: () => group,
    fnTeam: () => fnTeam,
    roles: state => {
      return state.roles.list.sort((a, b) => a.title.localeCompare(b.title))
    },
    isRolesLoaded: ({ roles }) => roles.status == status.LOADED,

    functionProject: state => {
      return state.roleFunctions.list.filter(item => item.group == 'PROJECT')
    },

    functionColission: state => {
      return state.roleFunctions.list.filter(item => item.group == 'COLLISION')
    },

    functionTask: state => {
      return state.roleFunctions.list.filter(item => item.group == 'TASK')
    },

    functionDefault: state => {
      return state.roleFunctions.list.filter(item => item.group == 'DEFAULT')
    },

    functionModel: state => {
      return state.roleFunctions.list.filter(item => item.group == 'MODEL')
    },

    functionSmeta: state => {
      return state.roleFunctions.list.filter(item => item.group == 'SMETA')
    },

    functionMaintenance: state => {
      return state.roleFunctions.list.filter(item => item.group == 'MAINTENANCE')
    },

    functionTeam: state => {
      return state.roleFunctions.list.filter(item => item.group == 'TEAM')
    },

  },

  mutations: {
    // setRoles: (state, roles, {rootGetters}) => {
    //   roles.forEach(role => {
    //     role.show = !!rootGetters['project/openedRoles']?.find(r => r === role.uuid)
    //   })
    //   state.roles.list = roles
    // },

    setRolesStatus: (state, status) => {
      state.roles.status = status
    },

    setRoleFunctions: (state, roleFunctions) => {
      state.roleFunctions.list = roleFunctions
    },

    setRoleFunctionsStatus: (state, status) => {
      state.roleFunctions.status = status
    },

    setRoleInRoles: (state, role) => {
      let curr = state.roles.list.filter(item => item.uuid == role.uuid)[0]
      if (curr !== undefined) Object.assign(curr, role)
    },

    addRoleInRoles: (state, role) => {
      role.show = false
      state.roles.list.push(role)
    },

    delRoleInRoles: (state, roleUUID) => {
      state.roles.list = state.roles.list.filter(role => {
        if (role.uuid !== roleUUID) {
          if ( role.rolefunction) {
            role.rolefunction = role.rolefunction.map(fn => {
              if (fn.roleUuids) {
                fn.roleUuids = fn.roleUuids.filter(uuid => uuid !== roleUUID)
              }
              return fn
            })
          }

          return role
        }
      })
    },

    setStatusDelete: (state, status) => {
      state.statusDelete = status
    },
  },

  actions: {
    INIT: ({ dispatch }) => {
      dispatch('LOAD_ROLES')
      dispatch('LOAD_FUNCTIONS')
    },

    LOAD_ROLES: ({  state, commit, dispatch }) => {
      if (state.roles.status == status.INITIAL ) {
        commit('setRolesStatus', status.LOADING)
        api.roles.rolesAll().then(data => {
          dispatch('setRoles', data)
          commit('setRolesStatus', status.LOADED)
        })  
    } 
    },

    LOAD_FUNCTIONS: ({ state, commit }) => {
      if (state.roleFunctions.list.length === 0){
        if (state.roleFunctions.status == status.INITIAL ) {
          commit('setRoleFunctionsStatus', status.LOADING)
          api.roles.roleFunctionsAll().then(data => {
            commit('setRoleFunctions', data)
            commit('setRoleFunctionsStatus', status.LOADED)
          })
        }
      }
    },

    saveRoleAction: ({ commit }, role) => {
      api.roles.create(role).then(data => { 
        if (data) commit('addRoleInRoles', data)
      })
    },
    
    editRoleAction: ({ commit }, role) => {
      console.log(role);

      api.roles.edit(role).then(data => commit('setRoleInRoles', data))
    },

    delRoleAction: ({ commit }, uuid) => {
      commit('setStatusDelete', null)
      return api.roles.delete(uuid).then(status => {
        if (status === "OK") commit('delRoleInRoles', uuid)
        else {
          commit('setStatusDelete', status)
          return true
        }
        return false
      })
    },

    forcedDelete: ({ commit }, uuid) => {
      commit('setStatusDelete', null)
      api.roles.forcedDelete(uuid).then(status => {
        if (status === "OK") commit('delRoleInRoles', uuid)
        else commit('setStatusDelete', status)
      })
    },

    setRoles: ({state,rootGetters}, roles) => {
      roles.forEach(role => {
        role.show = !!rootGetters['project/openedRoles']?.find(r => r === role.uuid)
      })
      state.roles.list = roles
    },
    
  }
}