export default ({ Factory }) => ({
    /**
     * Настройки floatingPanels
     * @param {Object} param Список настроек панелей [{ "floatingPanel": <enum>,"height": height,"opened": true/false}, ... ]
     */
    setFloatingPanelsSettings: (param) => Factory.profile.settings.floatingpanels.post(param),

    /**
     * Обновление настроек линейки
     * @param {Object} rulerSettings Информация о настройках линейки
     */
    updateRulerSettings: (rulerSettings) => Factory.profile.settings.ruler.post(rulerSettings),

    getUnitsOfMeasurement: () => Factory.profile.settings.ruler.units.get(),
  })