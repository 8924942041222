<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" fill="transparent" />
    <path d="M18 17V6L30 14V25L18 17Z" stroke="currentColor" />
    <path d="M18 17H4L15.5 25H30" stroke="currentColor" />
    <path d="M18 5.99998L30 14" stroke="currentColor" />
    <path d="M6 10L24.2141 10.1169" stroke="currentColor" />
    <rect
      x="22.5"
      y="8.5"
      width="3"
      height="3"
      fill="#535353"
      stroke="currentColor"
    />
    <circle cx="18" cy="6" r="1" fill="currentColor" />
    <circle cx="4" cy="10" r="3" fill="currentColor" />
    <circle cx="30" cy="14" r="1" fill="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>