import { WarningFeature } from '.'

export class WarningProfile {

  static DTO () {
    return {
      uuid: null,
      title: '',
      project: null,
      level: null
    }
  }

  constructor (wProfile) {
    this.uuid = wProfile?.uuid || null
    this.title = wProfile?.title || ''
    this.project = wProfile?.project || null
    this.level = wProfile?.level || null
    this.warningFeatures = wProfile && wProfile.warningFeatures ? wProfile.warningFeatures.map(item => new WarningFeature(item, this)) : []
  }

}