export default ({ Factory, Presets }) => ({

  /**
   * Получение дерева со всеми папками, подпапками и правилами для указанного проекта
   * @param {String} projectUuid UUID проекта
   * @returns {Promise} Массив объектов Folder: { uuid, title, children[Folder], rules[Rule], visibility }
   */
  tree: (projectUuid) => Factory.collisionrules.tree[projectUuid].get(),

  /**
   * Добавление папки
   * @param {String} project UUID проекта 
   * @param {String} title Имя папки
   * @param {String} [parent] UUID Родительской папки; если null, то добавится в корень
   * @returns {Promise} Без данных
   */
  addFolder: (project, title, parent) => Factory.collisionrules.folder.post({ project, title, parent }),

  /**
   * Редактирование папки
   * @param {Object} folderData CollisionRuleFolderFrontDTO
   * @param {String} folderData.uuid UUID редактируемой папки
   * @param {String} folderData.project UUID проекта 
   * @param {String} folderData.title Имя папки
   * @param {String} [folderData.parent] UUID Родительской папки; если null, то переместится в корень
   * @returns {Promise} Без данных
   */
  editFolder: (folderData) => Factory.collisionrules.folder.put(folderData),

  /**
   * Удаление указанной папки
   * @param {String} folderUuid UUID папки
   * @returns {Promise} Без данных
   */
  deleteFolder: (folderUuid) => Factory.collisionrules.folder[folderUuid].delete(),

  /**
   * Перенос указанной папки
   * @param {String} source UUID перетаскиваемой папки
   * @param {String} target UUID папки, на чьё место выполняется перенос
   * @param {String} parentUuid UUID папки родителя
   * @returns {Promise} Без данных
   */
  moveFolder: (source,target,parentUuid) => Factory.collisionrules.movefolder[source].to[target].patch(parentUuid),

  /**
   * Добавление правила ("рыба")
   * @param {Object} rule Объект, содержащий информацию о созданном правиле
   * @param {String} rule.title Название правила
   * @param {String} rule.folder UUID папки, где должно находиться правило 
   * @param {String} rule.finder Тип правила
   * @returns {Promise} Без данных
   */
  addRule: (rule) => Factory.collisionrules.rule.post(rule),

  /**
   * Редактирование правила (Упрощенное)
   * @param {Object} rule Объект, содержащий информацию об отредактированном правиле
   * @param {String} rule.uuid UUID редактируемого правила
   * @param {String} rule.title Название правила
   * @param {String} rule.folder UUID папки, где должно находиться правило 
   * @param {String} rule.finder Тип правила
   * @param {String} [rule.info] Информация
   * @returns {Promise} Без данных
   */
  editRule: (rule) => Factory.collisionrules.rule.put(rule),

  /**
   * Удаление правила 
   * @param {String} ruleUuid UUID правила
   * @returns {Promise} Без данных
   */
  deleteRule: (ruleUuid) => Factory.collisionrules.rule[ruleUuid].delete(),

  /**
   * Перемещение указанного правила
   * @param {String} source UUID перетаскиваемого правила
   * @param {String} target UUID папки/правила, на чьё место переносится правило
   * @param {String} folder UUID папки, в которой осущуствляется перенос
   * @returns {Promise} Без данных
   */
  moveRule: (source,target,folder) => Factory.collisionrules.moveRule[source].to[target].patch(folder),

  /**
   * Выбор/сброс выбора правил пользователем
   * @param {String} projectUuid UUID проекта; на случай, когда посылается пустой список, чтобы сервер знал, куда его применить
   * @param {String[]} rules Список UUID правил, которые пользователь отметил
   * @returns {Promise} Без данных
   */
  markRules: (projectUuid, rules) => Factory.collisionrules.selected[projectUuid].patch(rules),

  /**
   * Получение списка типов правил
   * @returns {Promise} Массив объектов Finder: { uuid, title }
   */
  ruleFinderEnum: () => Factory.collisionrules.finders.get(),

  /**
   * Получение полного правила со всеми условиями и пр.
   * @param {String} ruleUuid 
   * @returns {Promise} CollisionRule
   */
  ruleDetails: (ruleUuid) => Factory.collisionrules.rule[ruleUuid].get(),

  /**
   * 
   * @param {String} ruleUuid 
   */
  clearRule: (ruleUuid) => Factory.collisionrules.rule[ruleUuid].clear.patch(),

  /**
   * Редактирование полной структуры правила 
   * @param {Object} rule CollisionRule - правило со всем условиями, выборками и пр.
   */
  saveRuleDetails: (rule) => Factory.collisionrules.save.post(rule),

  /**
   * Запустить расчет коллизий по всем правилам для указанного проекта
   * @param {String} projectUuid UUID проекта
   */
  recalculateAll: (projectUuid) => Factory.collisionrules.calculate[projectUuid].get(),

  /**
   * Запустить перерасчет коллизий по указанным правилам в указанном проекте
   * @param {String} projectUuid UUID проекта
   * @param {String[]} rules массив UUID правил
   */
  recalculate: (projectUuid, rules) => Factory.collisionrules.calculate[projectUuid].post(rules),

  /**
   * Удалить условие из правила
   * @param {String} ruleConditionUuid UUID коллизии
   */
   removeRuleCondition: (ruleConditionUuid) => Factory.collisionrules.rulecondition[ruleConditionUuid].delete(),

  /**
   * 
   * @param {String} ruleUuid UUID правила
   */
   removeSampleA: (ruleUuid) => Factory.collisionrules.rule[ruleUuid].samplea.delete(),

     /**
   * Удалить условие из правила
   * @param {String} ruleExceptionConditionUuid UUID коллизии
   */

   removeExceptionRuleCondition: (ruleExceptionConditionUuid) => Factory.collisionrules.ruleexception[ruleExceptionConditionUuid].delete(),

   viewSelectModel: (ruleConditionUuid, projectUuid) => Factory.collisionrules[projectUuid].ruleconditionview[ruleConditionUuid].get(),

  // TODO: Move and Delete
  /**
   * Получить дерево правил, которые пользователь отметил для себя как активные/актуальные для расчета коллизий
   * @param {String} projectUuid 
   */
  getActiveRules: (projectUuid) => Factory.collisionrules.my[projectUuid].get(),

  /**
   * Скопировать в текущую папку указанное правило со всеми условиями, выборками и пр.
   * @param {String} ruleUuid UUID правила
   */
  copyRule: (ruleUuid) => Factory.collisionrules.rule[ruleUuid].copy.patch(),

  // Get /export/{projectUUID}    ->  List (CollisionRuleFolderExportDTO folder)
  exportRules: (projectUuid) => Factory.collisionrules.export[projectUuid].file.name.get(),

  // Post /import/{projectUUID}  body  ( CollisionRuleFolderExportDTO folder) -> null
  importRules: (projectUuid, file) => {
    let formData = new FormData()
    formData.append("file", file)
    return Factory.collisionrules.import[projectUuid].file.name.post(formData, Presets.headers.MULTIPART)
  },

  importAttrExcel: (rq) => Factory.collisionrules.importattrexcel.post(rq),
  importIDS: (rq) => Factory.collisionrules.importids.post(rq),
  deleteIDS: (id) => Factory.collisionrules.deleteids[id].delete(),

  setSamplePriority: (ruleUuid, bIntoA) => Factory.collisionrules.rule[ruleUuid].sample.priority[bIntoA].patch(),
})