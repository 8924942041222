import { Model } from '@vuex-orm/core'

export default class RuleConditionValid extends Model {
  static entity = 'RuleConditionValid'

  static primaryKey = 'ruleUuid'

  static fields () {
    return {
      ruleUuid: this.attr(null),
      conditionsValid: this.attr(null)
    }
  }

  static apiConfig = {
    actions: {
      checkByClassificatorRule(projectUuid, ruleUuid, nodeUuid, projectClassUuid) {
        let rq = `?projectUuid=${projectUuid}&ruleUuid=${ruleUuid}&nodeUuid=${nodeUuid}&projectClassUuid=${projectClassUuid}`
        
        return this.get(`/smeta/rule/checkconditions${rq}`)
      }
    }
  }
}