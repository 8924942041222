<template lang="pug">
  transition-group.app-group(name="app-group-item" tag="div" :class="[ { 'd-flex flex-wrap': !noFlex }]")
    slot
</template>

<script>
export default {
  name: 'app-group-animation',
  props: {
    noFlex: Boolean
  }
}
</script>

<style>
.app-group {
  margin-left: -16px;
  margin-right: -16px;
}
.app-group-item-enter, .app-group-item-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.app-group-item-leave-active {
  position: absolute;
}
</style>