import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"
import { Grid } from "./grid"
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"
import { Component } from "@xeokit/xeokit-sdk"
import { setGridsAndAxesCulledByAabb } from "./grids.utils"

/*eslint-disable no-dupe-class-members*/
export class ModelGrids extends Component {
  constructor(plugin, cfg = {}) {
    super(plugin.viewer.scene, cfg)

    this.plugin = plugin
    this.scene = plugin.viewer.scene

    this.uuid = cfg.uuid ?? null
    this.revisionUuid = cfg.modelRevision ?? null
    this.modelUuid = cfg.modelUuid ?? null

    this.grids = {}
    this.revisionAabb = cfg.revisionAabb ?? [0, 0, 0, 1, 1, 1]
    this.config = {
      font: cfg.font,
      color: cfg.color ?? [0.5, 0.5, 0.5],
      glowThrough: cfg.glowThrough ?? false,
      labelScale: this.#computeScale(),
      visible: cfg.visible ?? true,
      revisionAabb: this.revisionAabb
    }

    this.viewMatrixDebouncerId = null
    this.sectionPlaneUpdatedDebouncerId = null
    this.debounceTimeout = null

    this.#create(cfg.grids)
  }

  #create(grids) {
    this.node = new XeokitNode(this.scene)

    for (const grid of grids) {
      this.grids[grid.uuid] = new Grid(this.node, Object.assign(grid, this.config))
    }

    this.setDebounce(this.plugin.needDebounce)
  }

  #computeScale() {
    const modelAabb = this.revisionAabb
    const p1 = [modelAabb[0], modelAabb[1], modelAabb[2]]
    const p2 = [modelAabb[3], modelAabb[4], modelAabb[5]]
    const diagLength = geometry.math.distance(p1, p2)

    let labelScale
    if (diagLength == 1) labelScale = diagLength
    else labelScale = diagLength / 80

    return labelScale
  }

  #debounce() {
    if (this.plugin.projectGridsActive) {
      this.setVisible(false)
      this.debounceTimeout && clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        this.setVisible(true)
      }, 300)
    }
  }

  setDebounce(value) {
    if (value) {
      this.viewMatrixDebouncerId = this.scene.camera.on('viewMatrix', () => this.#debounce())
      this.sectionPlaneUpdatedDebouncerId = this.scene.on('sectionPlaneUpdated', () => this.#debounce())
    }
    else {
      this.scene.camera.off(this.viewMatrixDebouncerId)
      this.scene.off(this.sectionPlaneUpdatedDebouncerId)
    }
  }

  setCulled(value) {
    this.culled = value
  }

  setCulledByAabb(aabb) {
    setGridsAndAxesCulledByAabb(aabb, this.grids)
  }

  setVisible(value) {
    let flag
    if (this.culled) flag = false
    else flag = value

    Object.keys(this.grids).forEach(uuid => {
      this.grids[uuid].setVisible(flag)
    })
  }

  setGlowThrough(value) {
    Object.keys(this.grids).forEach(uuid => {
      this.grids[uuid].setGlowThrough(value)
    })
  }

  destroy() {
    this.scene.camera.off(this.viewMatrixDebouncerId)
    this.scene.off(this.sectionPlaneUpdatedDebouncerId)

    this.node?.destroy()
    this.node = null

    super.destroy()
  }
}