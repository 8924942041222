import { TaskWorker } from "./TaskWorker"

export class TaskAttachment {
  constructor( attachment, taskUuid ) {
    this.annotation = attachment.annotation
    this.sourceData = typeof attachment.sourceData === 'string' ? JSON.parse(attachment.sourceData) : attachment.sourceData
    this.collision = attachment.collision || null
    this.cameraParse =  this.setCameraParse(attachment.cameraParse)
    this.contentType = attachment.contentType
    this.count = attachment.count
    this.createDate = attachment.createDate
    this.creator = new TaskWorker( attachment.creator )
    this.file = attachment.file
    this.name = attachment.name
    this.taskUuid = taskUuid
    this.uuid = attachment.uuid
    if(attachment.sourceType){
      this.sourceType = new TaskAttachmentSourceType( attachment.sourceType )
    } else {
      this.sourceType = new TaskAttachmentSourceType({title: "FILE", value: 1})
    }
  }

  setCameraParse (cameraParse) {
    if (cameraParse && cameraParse.comment) return cameraParse
    if (cameraParse && !cameraParse.comment) {
      cameraParse.comment = ""
      return cameraParse
    }
    else return {comment: ""}

  }
}

class TaskAttachmentSourceType {
  constructor( obj ) {
    this.title = obj.title
    this.value = obj.value
  }
}