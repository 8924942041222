import { AabbEventDispatcher } from "./aabbEventDispatcher"

export class EventDispatcher {
  static _aabbEventDispatcher = null
  static _pickEventDispatcher = null
  static _selectEventDispatcher = null
  static _showEventDispatcher = null
  static _hideEventDispatcher = null

  static init(viewer) {
    this._aabbEventDispatcher = new AabbEventDispatcher(viewer)
    this._pickEventDispatcher = null // на будущее
    this._selectEventDispatcher = null // на будущее
    this._showEventDispatcher = null // на будущее
    this._hideEventDispatcher = null // на будущее
  }

  static get aabb() { return this._aabbEventDispatcher }
  static get pick() { return this._pickEventDispatcher }
  static get select() { return this._selectEventDispatcher }
  static get show() { return this._showEventDispatcher }
  static get hide() { return this._hideEventDispatcher }
}