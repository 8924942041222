<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="14" height="14" fill="none" />
    <g clip-path="url(#clip0_0_1)">
      <path
        d="M7 4.66668C7.61886 4.66668 8.21234 4.91251 8.6499 5.35009C9.08752 5.78768 9.33333 6.38115 9.33333 7.00001C9.33333 7.61887 9.08752 8.21235 8.6499 8.64991C8.21234 9.08753 7.61886 9.33334 7 9.33334C6.38114 9.33334 5.78767 9.08753 5.35008 8.64991C4.9125 8.21235 4.66667 7.61887 4.66667 7.00001C4.66667 6.38115 4.9125 5.78768 5.35008 5.35009C5.78767 4.91251 6.38114 4.66668 7 4.66668ZM1.77917 7.58334H0.583333V6.41668H1.77917C2.04167 3.98418 3.98417 2.04168 6.41667 1.77918V0.583344H7.58333V1.77918C10.0158 2.04168 11.9583 3.98418 12.2208 6.41668H13.4167V7.58334H12.2208C11.9583 10.0158 10.0158 11.9583 7.58333 12.2208V13.4167H6.41667V12.2208C3.98417 11.9583 2.04167 10.0158 1.77917 7.58334ZM7 2.91668C5.91704 2.91668 4.87842 3.34689 4.11265 4.11266C3.34687 4.87843 2.91667 5.91705 2.91667 7.00001C2.91667 8.08297 3.34687 9.12159 4.11265 9.88733C4.87842 10.6531 5.91704 11.0833 7 11.0833C8.08296 11.0833 9.12158 10.6531 9.88732 9.88733C10.6531 9.12159 11.0833 8.08297 11.0833 7.00001C11.0833 5.91705 10.6531 4.87843 9.88732 4.11266C9.12158 3.34689 8.08296 2.91668 7 2.91668Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect width="14" height="14" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>