import { XeokitMediator } from '../XeokitMediator';
import { useDrawingCroppingStore, useImageSectionPlanesStore, useDrawingPageStore } from '@/pinia';
import { createCfgByAnchorPointsData } from './imageSectionPlanesCreator';
import { AnchorPointsSceneController } from './controllers/anchorPointsSceneController';
import { ImagePlaneNormalPicker } from './controllers/imagePlaneNormalPicker';

/*eslint-disable no-dupe-class-members*/
export class Drawings3D {

  static get isEditMode() { return useImageSectionPlanesStore().isEditMode } // TODO 
  static get isAnchorPointsPicking() { return useImageSectionPlanesStore().isAnchorPointsPicking }
  static get isNormalPicking() { return useImageSectionPlanesStore().isNormalPicking }

  static createImageSectionPlane(cfg) {
    const id = XeokitMediator.ImageSectionPlanes.createImageSectionPlane(cfg)
    useImageSectionPlanesStore().editImageSectionPlaneId = id
  }

  static createByAnchorPoints() {
    const config = createCfgByAnchorPointsData({
      sceneAnchorPoints: useDrawingCroppingStore().sceneAnchorPoints,
      drawingAnchorPoints: useDrawingCroppingStore().drawingAnchorPoints,
      drawing3DAnchorPoints: useDrawingCroppingStore().drawing3DAnchorPoints,
      croppingRectangleRelative: useDrawingCroppingStore().croppingRectangleRelative,
      originalHeight: useDrawingPageStore().originalHeight,
      originalWidth: useDrawingPageStore().originalWidth,
      normal: useDrawingCroppingStore().sceneNormal
    })
    config.image = useImageSectionPlanesStore().imageSrc
    console.log(config)
    this.createImageSectionPlane(config)
  }

  static destroyImageSectionPlane(id) {
    const imageSectionPlane = XeokitMediator.ImageSectionPlanes.imageSectionPlanes[id]
    XeokitMediator.ImageSectionPlanes.removeImageSectionPlanes([imageSectionPlane])
  }

  static activateAnchorPointsSceneController() {
    AnchorPointsSceneController.activate()
  }

  static deactivateAnchorPointsSceneController() {
    AnchorPointsSceneController.deactivate()
  }

  static clearAnchorPoints() {
    AnchorPointsSceneController.clearAnchorPoints()
  }

  static activateNormalPicker() {
    ImagePlaneNormalPicker.activate()
  }

  static deactivateNormalPicker() {
    ImagePlaneNormalPicker.deactivate()
  }

  static clearDrawing3DNormal() {
    ImagePlaneNormalPicker.clearNormal()
  }

  static reset() {
    void(0)
    // XeokitMediator.ImageSectionPlanes.removeAll()
  }
}