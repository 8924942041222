import store from '@/store'

export class AlertService {
  static success (obj = null) {
    if (obj) {
      let suc = {
        type: "success",
        status: obj.status,
        info: obj.info || 'success'
      }
      store.commit('alert/SET_ALERT', suc)
    }
  }

  static info (obj = null) {
    if (obj) {
      let info = {
        type: "info",
        status: obj.status,
        info: obj.info || 'info'
      }
      store.commit('alert/SET_ALERT', info)
    }
  }

  static warning (obj = null) {
    if (obj) {
      let warn = {
        type: "warning",
        status: obj.status,
        info: obj.info || 'warning'
      }
      store.commit('alert/SET_ALERT', warn)
    }
  }

  static error (obj = null) {
    if (obj) {
      let err = {
        type: "error",
        status: obj.status,
        info: obj.data?.['error_description'] || 'error'
      }
      store.commit('alert/SET_ALERT', err)
    }
  }
}