import { $_iot_server } from '@/_helpers'

export const iotService = {
  listDevices,
  listWidgets,
  getWidgetData,
  getDataByProject,
  saveWidget,
  delWidget,
  getDeviceData,
  setDeviceData,
  widgetSize,
  checkDevice,
  linkDevice,
  unlinkDevice,
  relinkDevice,
  deleteDevice,
  deviceReestr,
  refreshDevices,
  initHome,
  logicTypes,
  createDeviceInReestr,
  deleteDeviceFromReestr,
  editDeviceInReestr,
  getDeviceParam,
  createDeviceParam,
  deleteDeviceParam,
  editDeviceParam,
  unitTypes,
  parseMibFile,
  getListModbusRtuNetworkSettings,
  getListModbusVariableSettings,
  getTransportProtocols,
  getSnmpCommunities,
  getSnmpRequestOperations
}

function initHome (projectuid) {
  return $_iot_server.get(`/iot/init/${projectuid}`).then(response => response.data)
}

function refreshDevices (projectuid) {
  return $_iot_server.get(`/iot/refresh/${projectuid}`).then(response => response.data)
}

function widgetSize () {
  return $_iot_server.get(`/iot/widgetsize`).then(response => response.data)
}

function listDevices (projectuid) {
  return $_iot_server.get(`/iot/devices/${projectuid}`).then(response => response.data)
}

function getDeviceData (devId, paramId) {
  return $_iot_server.get(`/iot/device/${devId}/${paramId}/data`).then(response => response.data)
}

function setDeviceData (devId, paramId, data) {
  return $_iot_server.put(`/iot/device/${devId}/${paramId}/set`, data).then(response => response.data)
}

function getDataByProject (projectuid) {
  return $_iot_server.get(`/iot/devices/${projectuid}/data`).then(response => response.data)
}

function listWidgets (projectuid) {
  return $_iot_server.get(`/iot/widgets/${projectuid}`).then(response => response.data)
}

function getWidgetData (widgetId) {
  return $_iot_server.get(`/iot/widget/${widgetId}`).then(response => response.data)
}

function saveWidget (widget) {
  return $_iot_server.post('/iot/widget', widget).then(response => response.data)
}

function delWidget (widgetId) {
  return $_iot_server.delete(`/iot/widget/${widgetId}`).then(response => response.data)
}

function linkDevice (device) {
  return $_iot_server.post(`/iot/device`, device).then(response => response.data)
}

function unlinkDevice (deviceId) {
  return $_iot_server.patch(`/iot/device/${deviceId}`).then(response => response.data)
}

function relinkDevice (deviceId, element) {
  return $_iot_server.patch(`/iot/device/${deviceId}/${element}`).then(response => response.data)
}

function deleteDevice (deviceId) {
  return $_iot_server.delete(`/iot/device/${deviceId}`).then(response => response.data)
}


function deviceReestr () {
  return $_iot_server.get(`/iot/devicereestr`).then(response => response.data)
}

function createDeviceInReestr (deviceReestr) {
  return $_iot_server.post(`iot/devicereestr`, deviceReestr).then(response => response.data)
}

function deleteDeviceFromReestr (deviceReestrId) {
  return $_iot_server.delete(`iot/devicereestr/${deviceReestrId}`).then(response => response.data)
}

function editDeviceInReestr (deviceReestr){
  return $_iot_server.put(`iot/devicereestr`, deviceReestr).then(response => response.data)

}

function logicTypes () {
  return $_iot_server.get(`/iot/logictypes`).then(response => response.data)
}

function checkDevice (project, element) {
  return $_iot_server.get(`/iot/device/${project}/${element}/check`).then(response => response.data)
}

function getDeviceParam(deviceReestrId) {
  return $_iot_server.get(`/iot/devicereestr/${deviceReestrId}/deviceparam`).then(response => response.data)
}

function createDeviceParam(deviceParam, deviceReestrId) {
  return $_iot_server.post(`iot/devicereestr/${deviceReestrId}/deviceparam`, deviceParam).then(response => response.data)
}

function deleteDeviceParam(deviceParamId) {
  return $_iot_server.delete(`iot/devicereestr/${deviceParamId}/deviceparam`).then(response => response.data)
}

function editDeviceParam(deviceParam) {
  return $_iot_server.put(`iot/devicereestr/deviceparam`, deviceParam).then(response => response.data)
}

function unitTypes () {
  return $_iot_server.get(`iot/dataunittypes`).then(response => response.data)
}

function parseMibFile(mibFile) {
  return $_iot_server.post(`iot/parsefile`, mibFile).then(response => response.data)
}

function getListModbusRtuNetworkSettings() {
  return $_iot_server.get(`/iot/device/modbus/rtu/settings`).then(response => response.data)
}

function getListModbusVariableSettings() {
  return $_iot_server.get(`/iot/variables/modbus/rtu/settings`).then(response => response.data)
}

function getTransportProtocols() {
  return $_iot_server.get(`/iot/transportprotocols`).then(response => response.data)
}

function getSnmpCommunities() {
  return $_iot_server.get(`/iot/snmpcommunities`).then(response => response.data)
}

function getSnmpRequestOperations() {
  return $_iot_server.get(`/iot/requestoperations`).then(response => response.data)
}


