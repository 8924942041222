import { api } from '@/api'
import project from './project.module'

const getDefaultState = () => {
  return {
    patchList: [],
    selectedPatch: null,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,

  state,

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setPatchList(state, patch) {
      state.patchList = patch
    },
    
    setSelectedPatch(state, patch) {
      state.selectedPatch = patch
    },

    setPatchAnnotation(state, annotation) {
      state.selectedPatch.annotation = annotation
    },

    setEditedElements(state, editedElements) {
      state.selectedPatch.elements = editedElements.elements
    },

    setActivatedPatch(state, activated) {
      state.selectedPatch.activated = activated
    }
  },
  
  actions: {
    loadPatchList({ state, commit }) {
      return api.projects.getPatchList(project.state.project.uuid).then(data => {
        commit("setPatchList", data)
        if (state.selectedPatch) {
          commit("setSelectedPatch", data.find(patch => patch.uuid == state.selectedPatch.uuid))
        }
      })
    },
    
    /** 
     * Добавить патч
     * @param {Object} patchData Данные патча
     * @param {File} patchData.ifcFile Файл IFC патча
     * @param {Object} patchData.params Параметры 
     * @param {String} patchData.params.annotation Аннотация
    */
    createPatch({ dispatch }, patchData) {
      return api.projects.createPatch(project.state.project.uuid, patchData).then(() => {
        dispatch('loadPatchList')
      })
    },

    saveEditedPatch({ state, dispatch, commit }, editedPatch) {
      api.projects.editPatch(editedPatch).then((data) => {
        if (editedPatch.uuid == state.selectedPatch?.uuid) {
          commit('setSelectedPatch', data)
        }
        dispatch('loadPatchList')
      })
    },

    deletePatch({ dispatch, commit }, patchUuid) {
      api.projects.deletePatch(patchUuid).then(() => {
        commit('setSelectedPatch', null)
        dispatch('loadPatchList')
      })
    },

    switchActivationPatch({ state, dispatch, commit }, patchUuid) {
      api.projects.switchActivatePatch(patchUuid).then((data) => {
        if (patchUuid == state.selectedPatch?.uuid) {
          commit('setSelectedPatch', data)
        }
        dispatch('loadPatchList')
      })
    }, 

    editPatchElements({ commit, dispatch }, {patchUuid, elements}) {
      api.projects.editPatchElements(patchUuid, elements).then((data) => {
        commit('setEditedElements', data)
      })
      dispatch('loadPatchList')
    },
    
    editPatchFile({dispatch, commit}, {patchUuid, file}) {
      api.projects.editPatchFile(patchUuid, file).then((data) => {
        dispatch('loadPatchList')
        commit('setSelectedPatch', data)
      })
    }, 

    deletePatchFile({ commit ,dispatch}, patchUuid) {
      return api.projects.deletePatchFile(patchUuid).then( (data) => {
        dispatch('loadPatchList')
        commit('setSelectedPatch', data)
      })
    }, 

    getPatch({ commit, dispatch }, patchUuid) {
      return api.projects.getPatch(patchUuid).then( (data) => {
        if(data.state.value == 1) {
          dispatch('loadPatchList')
          commit('setSelectedPatch', data)
        }
        return data
      })
    },

    editPatchOffset({ state }, form) {
      return api.projects.editPatchOffset(state.selectedPatch.uuid, form) 
    },
  }
}