import { $_iot_server } from '@/_helpers'

export const positionService = { 

    saveDataPosition,
    deleteDataPosition,
    getDataPosition,
    getDataPositions,
    editDataPosition

}

function saveDataPosition (dataPosition) {
    return $_iot_server.post(`/iot/positions`, dataPosition).then(response => response.data)
}
function getDataPosition (positionUuid) {
    return $_iot_server.get(`/iot/positions/${positionUuid}`).then(response => response.data)
}
function getDataPositions (globalElementId, projectUuid){
    return $_iot_server.get(`/iot/positions/${globalElementId}/${projectUuid}/getpositions`).then(response => response.data)
}
function deleteDataPosition (positionUuid){
    return $_iot_server.delete(`/iot/positions/${positionUuid}/dropposition`).then(response => response.data)
}
function editDataPosition (dataPosition) {
    return $_iot_server.put(`/iot/positions`, dataPosition).then(response => response.data)
}