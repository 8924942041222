<template lang="pug">
app-dialog( v-model="$_show" :header="$t('profile.action.items.notify')" width="500" :confirm-text="$t('button.save')" @confirm="saveNotify" @close="onClose" )
  app-section.notification-profile-details.base-scroll-body
    .header-text.mb-4 {{ $t('profile.action.items.notifyPanel.header') }}
    app-select( v-model="tZone" :items="timeZoneList" item-text="title" :label="$t('profile.action.items.notifyPanel.notificationTimezone')" )

    .label-text {{ $t('profile.action.items.notifyPanel.push.header') }}
    .d-flex.align-center.justify-space-between.mb-4
      .switch-text {{ $t('profile.action.items.notifyPanel.push.send') }}
      v-switch( v-model="notifyWebPush" @change="subscribeUnsubscride(notifyWebPush)" color="accent" dense light )    

    v-divider(light)

    .header-text.mt-4.mb-4 {{ $t('profile.action.items.notifyPanel.email.header') }}
    .label-text.mb-2 {{ $t('profile.action.items.notifyPanel.email.enable') }}
    .d-flex.align-center.justify-space-between.mb-4 
      app-text-field(v-model="notifyEmail.value" style="max-width:80%" :disabled="!notifyEmail.switcher" label="email" light hideDetails)
      v-switch( v-model="notifyEmail.switcher" hide-deails color="accent" dense light )

    .label-text.mb-2 {{ $t('profile.action.items.notifyPanel.notifyPeriod.add') }}
    app-select( v-model="interval" :items="intervalList" :item-text="option => $t(option.title)" :label="$t('profile.action.items.notifyPanel.notificationPeriod')" )

    .d-flex.align-center.justify-space-between
      .label-text.mb-2 {{ $t('profile.action.items.notifyPanel.doNotDisturbSelectedHours') }}
      v-switch( v-model="notifyNight.switcher" color="accent" dense light )

    .time-settings-block.mb-4(v-if="notifyNight.switcher")
      .d-flex.align-center.justify-space-between.switch-text
        div {{ $t('module.task.columns.start') }}
        time-picker.d-flex.align-center(v-model="startTime" v-slot:default="{ on, attrs, time }")
          div(v-on="on" v-bind="attrs")
            span {{ time }}
            v-icon(color="#535353") mdi-chevron-right
      .d-flex.align-center.justify-space-between.switch-text
        div {{ $t('module.task.columns.end') }}
        time-picker.d-flex.align-center(v-model="endTime" v-slot:default="{ on, attrs, time }")
          div(v-on="on" v-bind="attrs")
            span {{ time }}
            v-icon(color="#535353") mdi-chevron-right
      .d-flex.align-center.justify-space-between
        .switch-text {{ $t('profile.action.items.notifyPanel.doNotDisturbRepeat') }}
        v-menu(light offset-y :close-on-content-click="false" min-width="max-content" max-width="max-content" )
          template( v-slot:activator="{ on, attrs }")
            .switch-text(v-on="on" v-bind="attrs" ) {{ daysFormated }}
              v-icon(color="#535353") mdi-chevron-right
          v-list
            v-list-item(v-for="(item, index) in daysItemMenu" :key="index")
              v-list-item-title {{ item.title }}
              v-checkbox(v-model="selectedDays" :value="item")
        
    v-divider(light)

    .header-text.mt-4.mb-4 {{ $t('profile.action.items.notifyPanel.telegram.header') }}
    .d-flex.align-center.mb-2
      .label-text.mr-2 {{ $t('profile.action.items.notifyPanel.telegram.enable') }}
      v-tooltip(top)
        template(v-slot:activator="{ on, attrs }")
          a(href="https://t.me/BimitBot" target="_blank")
            v-icon( light small v-bind="attrs" v-on="on") mdi-help-circle-outline 
        div(style="max-width:160px") {{ $t('profile.action.items.notifyPanel.telegram.getID') }}
    .d-flex.align-center.justify-space-between.mb-4  
      app-text-field(v-model="notifyTelegram.value" style="max-width:80%" :disabled="!notifyTelegram.switcher" label="Telegram ID" light hideDetails)
      v-switch( v-model="notifyTelegram.switcher" color="accent" dense light )

    .header-text.mb-2 {{ $t('profile.action.items.notifyPanel.byProjects.header') }}

    v-tabs( v-model="tab" light show-arrows center-active height="35px")
      v-tabs-slider(color="var(--v-primary-darken2)")
      v-tab(v-for="item, index in availableNotifySettings" :key="index" ) {{ $t(item.group) }}

    v-tabs-items(v-model="tab" light)
      v-tab-item(v-for="item, index in availableNotifySettings" :key="index" style="min-height: 300px;")
        v-simple-table( light fixed-header )
          template( v-slot:default )
            thead
              tr
                th( v-for="header, index in headers" :style="{ 'text-align': index > 0 ? 'center' : 'left' }" ) {{ header }}

            tbody
              tr( v-if="item.group === 'notify.setting.group.collisions'")
                td.px-1(colspan = 4)
                  v-switch( v-model="notifyCollisionOnlyMyModels.switcher" :label="$t('profile.action.items.notifyPanel.collisionsOnlyMyModels')" color="accent" dense light )

              tr( v-for="setting in item.settings" :key="setting.notificationSettingFieldKey.value")
                td.td-item.px-1 {{ $t(setting.notificationSettingFieldKey.message) }}
                td.td-item.px-1
                  v-checkbox.pa-0.my-1( v-model="setting.email" :value="setting.email" light  hide-details )
                td.td-item.px-1
                  v-checkbox.pa-0.my-1( v-model="setting.telegram" :value="setting.telegram" light hide-details )
                td.td-item.px-1
                  v-checkbox.pa-0.my-1( v-model="setting.webPush" :value="setting.webPush" light hide-details )
       
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapActions, mapGetters, mapState } from 'vuex'
import { type } from '@/store/authUser.module'
import TimePicker from '../project/panel/left/documents/components/TimePicker.vue'
import { subscribePush, unsubscribePush } from '@/plugins/webPush/webPush'
import _ from 'lodash'
export default {
  name: 'access-notify-profile',

  mixins: [DialogToggable],
  components: { TimePicker },

  data() {
    return {
      interval: null,
      tZone: null,
      startTime: '21:00',
      endTime: '7:00',
      selectedDays: [],
      tab: null,
      notifyWebPush: false,
    }
  },

  computed: {
    ...mapState('authUser', ['user']),
    ...mapGetters('authUser', [
      'notifyEmail',
      'notifyTelegram',
      'timeZoneList',
      'intervalList',
      'notifyInterval',
      'timeZone',
    ]),
    ...mapGetters('authUser', [
      'notifyNight',
      'notifyCollisionOnlyMyModels', 
      'notifySettings',
    ]),

    availableNotifySettings() {
      const arr = []

      this.notifySettings?.map((setting) => {
        let item = arr.find((el) => el.group == setting.notificationSettingFieldKey?.group)
        if (!item && setting.notificationSettingFieldKey) {
          item = {
            group: setting.notificationSettingFieldKey.group,
            settings: [],
          }
          arr.push(item)
        }

        item.settings.push(setting)
      })

      return arr
    },

    daysItemMenu() {
      return [
        { title: this.$t('week.monday'), shortTitle: this.$t('week.mon'), value: '1' },
        { title: this.$t('week.tuesday'), shortTitle: this.$t('week.tue'), value: '2' },
        { title: this.$t('week.wednesday'), shortTitle: this.$t('week.wed'), value: '3' },
        { title: this.$t('week.thursday'), shortTitle: this.$t('week.thu'), value: '4' },
        { title: this.$t('week.friday'), shortTitle: this.$t('week.fri'), value: '5' },
        { title: this.$t('week.saturday'), shortTitle: this.$t('week.sat'), value: '6' },
        { title: this.$t('week.sunday'), shortTitle: this.$t('week.sun'), value: '7' },
      ]
    },

    daysFormated() {
      if (!this.selectedDays.length) return this.$t('profile.action.items.noData')
      return [...this.selectedDays]
        .sort((a, b) => a.value - b.value)
        .map((el) => el.shortTitle)
        .join(', ')
    },

    headers() {
      return [this.$t('module.notification.title'), 'Email', 'Telegram', 'Push']
    },
  },

  mounted() {
    this.notifyWebPush = JSON.parse(localStorage.getItem("WebPushSubscribe")) ?? false
    
    this.interval = this.notifyInterval
    this.tZone = this.timeZone

    if (this.notifyNight.value) {
      let doNotDistrurbSetting = JSON.parse(this.notifyNight.value)
      if (doNotDistrurbSetting.startTime) this.startTime = doNotDistrurbSetting.startTime
      if (doNotDistrurbSetting.endTime) this.endTime = doNotDistrurbSetting.endTime
      if (doNotDistrurbSetting.days) 
        doNotDistrurbSetting.days.map((el) => this.selectedDays.push(this.daysItemMenu[el - 1]))
    }

    if (!this.selectedDays.length) {
      this.selectedDays = [this.daysItemMenu[5], this.daysItemMenu[6]]
    }
  },

  watch: {
    notifyInterval() {
      this.interval = this.notifyInterval
    },
    timeZone() {
      this.tZone = this.timeZone
    },
  },

  methods: {
    ...mapActions('authUser', [
      'setProfileSettingList',
      'setNotificationSettings'
    ]),

    saveNotify() {
      const makeSetting = (type, value, switcher) => ({
        type: {
          value: type,
        },
        value,
        switcher,
      })

      const settingList = [
        makeSetting(type.NOTIFY_EMAIL, this.notifyEmail.value, this.notifyEmail.switcher),
        makeSetting(type.NOTIFY_TELEGRAM, this.notifyTelegram.value, this.notifyTelegram.switcher),

        makeSetting(type.NOTIFY_NIGHT, this.doNotDisturbSetting(), this.notifyNight.switcher),

        makeSetting(type.TIME_ZONE, this.tZone.value, false),
        makeSetting(type.NOTIFY_INTERVAL, this.interval.value, false),
        makeSetting(type.NOTIFY_COLLISION_ONLY_MY_MODELS, '', this.notifyCollisionOnlyMyModels.switcher),
      ]
      this.setNotificationSettings({ notifySettings: this.notifySettings, profileSettings: settingList })
    },

    doNotDisturbSetting() {
      const doNotDistrurb = (startTime, endTime, days) => ({
        startTime,
        endTime,
        days,
      })

      let days = !this.selectedDays.length ? '' : this.selectedDays.sort((a, b) => a.value - b.value).map((el) => el.value)

      return JSON.stringify(doNotDistrurb(this.startTime, this.endTime, days))
    },

    onClose() {},

    subscribeUnsubscride: _.debounce((switcher) => { 
      switcher ? subscribePush() : unsubscribePush()
    }, 1000),
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--checkbox > .v-input__control {
  align-items: center;

  .v-input__slot {
    width: fit-content;

    .v-input--selection-controls__input {
      margin: 0;
    }
  }
}

::v-deep .v-input--selection-controls {
  margin-top: 0;
}

::v-deep #date-select > .v-input__control > .v-input__slot {
  padding: 0;
}

::v-deep .v-tab {
  text-transform: capitalize;
}

.time-settings-block {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 16px ;
}

.header-text {
  text-transform: uppercase;
  color: #535353;
  font-size: 12px;
  font-weight: 500;
}

.label-text {
  color: #282828;
  font-size: 12px;
  font-weight: bold;
}

.switch-text {
  color: #535353;
  font-size: 14px;
  font-weight: 400;
}

.timezone-select.theme--dark.v-icon {
  color: black;
}

.notification-profile-details {
  padding-left: 8px;
  max-height: 75vh;
  overflow-x: hidden;
}
</style>
