<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 12.5L3.5 18.5H21.5L29 12.5H21.5" stroke="#FF0000"/>
    <path d="M11 19V30L21 22.0435V19M21 18V2L11 9.95652V18" stroke="#303030"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>