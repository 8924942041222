import vuetify from "@/plugins/vuetify"
import project from './project.module'
// import { projectService } from "@/_services"
import { api } from '@/api'

const defaultCondition = Object.freeze({
  uuid: null,
  operator: null,
  operand: {"name":""},
  operandUuid: null,
  value: null,
  title: null,
  manualOperand: null,
  elements: [],
})

const defaultGridCondition = Object.freeze({
  uuid: null,
  x: [],
  y: [],
  top: null,
  bottom: null,
  whole: false
})

export default {
  namespaced: true,

  state:{
    defaultCondition,
    form: Object.assign({}, defaultCondition),
    gridForm: Object.assign({}, defaultGridCondition),
    currentType: null,
    currentClassificator: null,
    searchClassificator: null,
    handleClassificator: null,
    handleClassification: null,
    copyManualOperand: null,
    copyValue: null,
    reducedСonditions : null,

    loaded: false,

    search: null,
    currentDelimeter: "_",

    tree:{
      loading:false,
      items:[],
      opened:[],
      active:[]
    },

    grid:{
      loaded:false,
      project:null,
      floors:[],
      x:[],
      y:[],
      z:[]
    },

    nodeSearch: null,
    searchMode: false,

    searchThisModelIfcTypes: true,
    allIfcTypes: null
  },

  mutations:{
    setForm: ( state, form ) => { state.form = form },
    setGridForm: ( state, form ) => { state.gridForm = form },
    setLoaded: ( state, loaded ) => { state.loaded = loaded },
    setSearch: ( state, search ) => { state.search = search },
    setCurrentType: ( state, currentType ) => { state.currentType = currentType },
    setCurrentClassificator: ( state, currentClassificator) => { state.currentClassificator = currentClassificator },
    setSearchClassificator: ( state, searchClassificator ) => { state.searchClassificator = searchClassificator },
    setHandleClassificator: ( state, handleClassificator ) => { state.handleClassificator = handleClassificator },
    setHandleClassification: ( state, handleClassification ) => { state.handleClassification = handleClassification },
    setManualOperand: ( state, copyManualOperand ) => { state.copyManualOperand = copyManualOperand },
    setValue: ( state, copyValue ) => { state.copyValue = copyValue },
    setReducedСonditions: ( state, reducedСonditions ) => { state.reducedСonditions = reducedСonditions },
    setNodeSearch: ( state, nodeSearch ) => { state.nodeSearch = nodeSearch },
    setSearchMode: ( state, searchMode ) => { state.searchMode = searchMode },
    setCurrentDelimeter: (state, currentDelimeter) => { state.currentDelimeter = currentDelimeter }, 

    setGridProject: ( state, project ) => { state.grid.project = project },
    setGridLoaded: ( state, loaded ) => { state.grid.loaded = loaded },
    setGrid: ( state, data ) => { 
        state.grid.x = data.u
        state.grid.y = data.v
        state.grid.z = data.grids
    },
    setGridFloor: ( state, data ) => { 
        state.grid.floors = data
    },

    cleanGridForm: (state) => { 
        state.gridForm = Object.assign({}, defaultGridCondition)
        state.gridForm.x = []
        state.gridForm.y = []
    },
  },

  getters:{
    activeClass() { return "font-weight: 500; color: " + vuetify.userPreset.theme.themes.dark.accent.base },
    isAttr( { form } ) { return form.operand.name == 'ATTRIBUTE' || form.operand.name == 'USERPROP' || form.operand.name == 'USERMANUALPROP' || form.operand.name == 'MASK' || form.operand.name == 'GEOMETRY' || form.operand.name == 'IDPROP' || form.operand.name == 'GRIDPROP' || form.operand.name === 'BASEATTR' || form.operand.name === 'MANUALINPUT' },
    isSearchable( { form } ) { return form.operand.name == 'ATTRIBUTE' || form.operand.name == 'USERPROP' || form.operand.name == 'USERMANUALPROP' },
    isMask( { form } ) { return form.operand.name == 'MASK' },
    isDivSelectable( { form } ) { return form.operand.name == 'FLOOR' || form.operand.name == 'ZONE' || form.operand.name == 'LAYER' || form.operand.name == 'MODEL' || form.operand.name === 'MATERIALS' || form.operand.name === 'SYSTEMS'},
    isConst( { form } ) { return form.operand.name == 'CONST' },
    isGeometry( { form } ) { return form.operand.name == 'GEOMETRY' },
    isIdProp( { form } ) { return form.operand.name == 'IDPROP' },
    isGridProp( { form } ) { return form.operand.name == 'GRIDPROP' },
    isGroup( { form } ) { return form.operand.name == 'GROUP' },
    isClassificator( { form } ) { return form.operand.name == 'CLASSIFICATOR' },

    condOperator ( state, getters ) {
      if( state.form.operand.name != '' ){
         if( getters.isGroup || getters.isGridProp )
           return project.state.conditionOperator.list.filter(a => a.name == 'EQ')
         if( getters.isIdProp)
           if( state.form.operandUuid != null ) 
             if ( state.form.operandUuid == 'a1a6881f-4698-40dc-baae-54ad1c98c103' )
               return project.state.conditionOperator.list.filter(a => a.name == 'EQ' || a.name == 'NOTEQ' || a.name == 'CONT' || a.name == 'NOTCONT' || a.name == 'IN')
             else
               return project.state.conditionOperator.list.filter(a => a.name == 'EQ' || a.name == 'NOTEQ' || a.name == 'CONT' || a.name == 'NOTCONT')
           else 
             return []
         if( getters.isAttr )
           if( state.form.operandUuid != null || state.form.manualOperand ){
              if( state.currentType  ==  "ENUM" || state.currentType  ==  "BOOL" ) {
                return project.state.conditionOperator.list.filter(a => a.name == 'EQ' || a.name == 'NOTEQ' || a.name == 'EX' || a.name == 'NOTEX')
              }
              if( state.currentType  ==  "STRING" ) {
                return project.state.conditionOperator.list.filter(a => a.name == 'EQ' || a.name == 'NOTEQ' || a.name == 'CONT' || a.name == 'NOTCONT' || a.name == 'EX' || a.name == 'NOTEX')
              }
              return project.state.conditionOperator.list.filter(a => a.name != 'CONT' && a.name != 'NOTCONT' && a.name != 'IN' && a.name != 'ISSTR' && a.name != 'ISNUM' && a.name != 'RANGE')
           } else 
              return []
         else {
           return project.state.conditionOperator.list.filter(a => a.name == 'EQ' || a.name == 'NOTEQ' || a.name == 'CONT' || a.name == 'NOTCONT')
         }
      } else return []
    },

    condOperatorAttrCheck ( state, getters ) {
      if( getters.isAttr )
        if( state.form.operandUuid != null ){
           if( state.currentType  ==  "ENUM" || state.currentType  ==  "BOOL" ) {
             return project.state.conditionOperator.list.filter(a => a.name == 'EQ' || a.name == 'EX')
           }
           if( state.currentType  ==  "STRING" ) {
             return project.state.conditionOperator.list.filter(a => a.name == 'EQ' || a.name == 'CONT' || a.name == 'EX' || a.name == 'IN' || a.name == 'ISSTR' || a.name == 'ISNUM')
           }
           if( state.currentType  ==  "INT" || state.currentType  ==  "LONG" || state.currentType  ==  "DOUBLE") {
             return project.state.conditionOperator.list.filter(a => a.name != 'CONT' && a.name != 'NOTEQ' && a.name != 'NOTEX' && a.name != 'NOTCONT')
           }
        } 
      return project.state.conditionOperator.list.filter(a => a.name != 'NOTEQ' && a.name != 'NOTEX' && a.name != 'NOTCONT')
    },

    gridZ (state) {
      let z = []
      state.grid.z.forEach(e => {
        if(!state.grid.floors.find(f => f.elevation == e.globalZ))
          z.push({'label':'Отметка ( ' + e.globalZ + ' )', 'elevation':e.globalZ})
      })
      state.grid.floors.forEach(e => z.push({'label':e.name + ' ( ' + e.elevation + ' )', 'elevation':e.elevation}))
      return z.sort((a, b) =>  a.elevation - b.elevation)
    },

  },

  actions: {
    selectClassificator( { dispatch, commit }, cl) {
      commit('setCurrentClassificator', cl)
      dispatch('selectOperator', project.state.conditionOperator.list.find(a => a.name=='EQ'))
      return dispatch('loadClassificatorNodes')
    },

    selectOperator( { state, getters }, oper) {
      state.form.operator = oper
      if( !getters.isAttr && (state.form.operator.name=='CONT' || state.form.operator.name=='NOTCONT') ) state.form.operandUuid = null
    },

    loadClassificatorNodes( { state, dispatch } ) {
      state.tree.loading = true
      state.tree.items = []
      return api.smeta.topnodes( state.currentClassificator.uuid ).then( ( data ) => {
        dispatch('bindNodesList', { parent: null, data: data } )
        state.tree.items = data
        state.tree.loading = false
        return data
      })
    },

    loadChildrens( { state, dispatch }, item) {
      return api.smeta.childrennodes(item.uuid).then((data) => {
        dispatch( 'bindNodesList', { parent: item, data: data } )
        item.children = data
        if (!state.tree.opened.includes(item.uuid)) state.tree.opened.push(item.uuid)
        return data
      })
    },

    bindNodesList( { state }, { parent, data } ) {
      state
      let lock = parent ? parent.locked : false
      data.forEach(item => {
        item.editMode = false
        item.locked = lock
        if (!item.hasChilds && !item.children) delete item.children
        else if (item.hasChilds && !item.children) item.children=[] 
      })
    },

    LOAD_GRID: ({ commit, rootGetters, state }) => {
      let projectUuid = rootGetters['project/projectUuid']
      if (!state.grid.loaded || state.grid.project != projectUuid) {
        commit('setGridProject', projectUuid)
        commit('setGridLoaded', false)
        api.axes.getGrid(projectUuid).then(data => {
          commit('setGrid', data)
          commit('setGridLoaded', true)
        })
        api.axes.getFloors(projectUuid).then( data => {
          commit('setGridFloor', data)
        })
      }
    },

    setSearchThisModelIfcTypes: ({ state }, data) => {
      // if (state.searchThisModelIfcTypes != data && !state.allIfcTypes) {
      //   dispatch('loadAllIfcTypes')
      // }
      state.searchThisModelIfcTypes = data
    },

    // loadAllIfcTypes: ({ state, rootGetters }) => {
    //   let projectUuid = rootGetters['project/projectUuid']
    //   let hashProject = rootGetters['project/hashProject']
    //   projectService.loadParamForCondition(projectUuid, 'CLASS', '', hashProject).then(data => {
    //     if (!data) { return }

    //     const length = data[0].children.length
    //     const allIfcTypes = Array(length)
    //     for (let i = 0; i < length; i++) {
    //       allIfcTypes[i] = data[0].children[i]
    //     }
    //     state.allIfcTypes = allIfcTypes
    //     console.log(state.allIfcTypes)
    //   })
    // }
  }
}