<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" fill="transparent"/>
    <path d="M3.5 8.78878L16 1.57724L28.5 8.78878V23.2112L16 30.4228L3.5 23.2112V8.78878Z" stroke="currentColor"/>
    <path d="M3.49993 9.00019L16.0002 15.5" stroke="currentColor"/>
    <path d="M28 9L16 15.5L16 30" stroke="currentColor"/>
    <rect x="14.5" y="14.5" width="3" height="3" fill="#A3A3A3" stroke="currentColor"/>
    <rect x="14.5" y="14.5" width="3" height="3" fill="#535353" stroke="currentColor"/>
    <rect x="2.5" y="7.5" width="3" height="3" fill="#A3A3A3" stroke="currentColor"/>
    <rect x="2.5" y="7.5" width="3" height="3" fill="#535353" stroke="currentColor"/>
  </svg>

</template>

<script>
export default {

}
</script>

<style>

</style>