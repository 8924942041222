<template lang="pug">
v-dialog.app-dialog-confirm( v-model="$_show" :width="width" )
  v-card.py-2( light )
    v-card-text.text-subtitle-1.pt-5.app-text-confirm
      slot
    v-divider.pb-2.mx-4
    v-card-actions
      v-spacer
      div( v-if="forced" )
        app-button( action :disabled="disabled" text @click="handleConfirm" test-id="dialog-confirm-button" ) {{ confirmButtonText }}
        app-button.ml-2( cancel @click="handleClose" ) {{ $t('button.cancel') }}
      div(v-else)
        app-button( cancel text @click="handleClose" ) {{ $t('button.cancel') }}
        app-button.ml-2( action @click="handleConfirm" :disabled="disabled" test-id="dialog-confirm-button" ) {{ confirmButtonText }}
</template>

<script>
import AppDialogToggable from './DialogToggable'

export default {
  mixins: [AppDialogToggable],
  name: 'app-dialog-confirm',

  props: {
    confirmButton: String,
    width: { type: [String, Number], default: 320 },
    delete: Boolean,
    forced: Boolean,
    disabled: { type: Boolean, default: false },
  },

  computed: {
    confirmButtonText () {
      return this.confirmButton || this.delete ? this.$t('button.delete') : this.$t('button.yes')
    },

    confirmButtonColor () {
      let theme = this.$vuetify.theme.themes.dark
      return this.delete ? theme.alert.base : theme.accent.base
    }
  },

  methods: {
    handleConfirm () {
      this.$_show = false
      this.$emit('confirm')
    },

    handleClose() {
      this.$_show = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.app-dialog-confirm{
  background-color: var(--v-surface-base);
}

.v-sheet.v-card {
  border-radius: 0px;
}

.app-text-confirm {
  text-align: center;
}

::v-deep.v-card__actions {
  padding: 0px 16px !important;
}
</style>