<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21C10.7594 21 9.59467 20.7657 8.50571 20.297C7.41675 19.8276 6.46141 19.1821 5.63967 18.3603C4.81793 17.5386 4.17238 16.5832 3.70301 15.4943C3.23434 14.4053 3 13.2406 3 12C3 10.7456 3.23434 9.5739 3.70301 8.48494C4.17238 7.39598 4.81793 6.44029 5.63967 5.61786C6.46141 4.79612 7.41675 4.15438 8.50571 3.69263C9.59467 3.23088 10.7594 3 12 3C12.9664 3 13.8858 3.14434 14.758 3.43302C15.631 3.7217 16.4351 4.12565 17.1703 4.64486L15.6345 6.15888C15.1153 5.81274 14.5455 5.55313 13.9252 5.38006C13.305 5.20699 12.6632 5.12046 12 5.12046C10.0817 5.12046 8.45552 5.78747 7.1215 7.1215C5.78747 8.45552 5.12046 10.0817 5.12046 12C5.12046 13.9183 5.78747 15.5445 7.1215 16.8785C8.45552 18.2125 10.0817 18.8795 12 18.8795C13.9183 18.8795 15.5445 18.2125 16.8785 16.8785C18.2125 15.5445 18.8795 13.9183 18.8795 12C18.8795 11.7404 18.8615 11.4808 18.8255 11.2212C18.7895 10.9616 18.7428 10.7092 18.6854 10.4642L20.3936 8.75493C20.5957 9.25961 20.7473 9.78228 20.8484 10.3229C20.9495 10.8643 21 11.4233 21 12C21 13.2406 20.7691 14.4053 20.3074 15.4943C19.8456 16.5832 19.2039 17.5386 18.3821 18.3603C17.5597 19.1821 16.604 19.8276 15.5151 20.297C14.4261 20.7657 13.2544 21 12 21V21ZM10.81 16.1755L6.89408 12.2596L8.40914 10.7456L10.8318 13.1682L19.5068 4.51506L21 6.00727L10.81 16.1755Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>