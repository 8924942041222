<template>
  <svg
    width="44"
    height="10"
    viewBox="0 0 44 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6099_93)">
      <path d="M33.8582 0H31.5505V2.3077H33.8582V0Z" fill="#ff0000" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.7042 10H40.0119V3.07681H37.7042V10ZM0 0H2.31976V10H0V0ZM3.07695 3.84624H7.31611C7.576 3.84624 7.74536 3.68168 7.82167 3.4715C7.86355 3.35595 7.8846 3.22046 7.8846 3.07699C7.8846 2.93352 7.86351 2.79803 7.82167 2.68248C7.74549 2.4723 7.576 2.30774 7.31611 2.30774H3.07695V0.000220175H7.31611C8.70929 0.000220175 9.59848 0.832878 9.98502 1.89839C10.123 2.27894 10.1922 2.68235 10.1922 3.07703C10.1922 3.47172 10.123 3.87508 9.98502 4.25567C9.93271 4.40002 9.87106 4.54001 9.80025 4.67431C10.123 4.9697 10.366 5.32542 10.5313 5.71134C10.7095 6.12765 10.7899 6.58006 10.7756 7.02878C10.7617 7.47565 10.6532 7.9193 10.4554 8.31993C9.9904 9.2606 9.05699 9.97666 7.70582 9.99555L7.6923 10H3.07695V7.69252H7.6923V7.69675C8.04814 7.68772 8.28307 7.52012 8.39122 7.30149C8.44428 7.19404 8.47348 7.07546 8.47713 6.95669C8.48079 6.83996 8.4597 6.72177 8.41302 6.6126C8.31579 6.38569 8.0688 6.19845 7.65042 6.15385H7.31606H3.0769V3.84632L3.07695 3.84624ZM12.3197 2.3077H14.6274V0H12.3197V2.3077ZM12.3197 10H14.6274V3.07695H12.3197V10ZM16.5504 0H18.8581V10H16.5504V0ZM26.5503 1.45135L23.0999 7.87755L19.6274 1.42872V5.71438L21.9351 10H21.9602H24.2428H24.2679L26.5503 5.74925V1.45135ZM29.6272 0H27.3195V10H29.6272V0ZM31.5504 10H33.8581V3.07681H31.5504V10ZM34.6272 2.3077H43.0887V0H34.6272V2.3077Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6099_93">
        <rect width="43.0887" height="10" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>