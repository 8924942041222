import { useDrawingPageStore, useDrawingsStore } from "@/pinia"
import { useDrawingCroppingStore } from "@/pinia"
import { unfitPage } from "@/components/drawings/facades/drawingPage.facade"

export function setPageListeners() {
  const store = useDrawingsStore()
  const croppingStore = useDrawingCroppingStore()

  const selectedPageBody = document.getElementById("drawingSelectedPageBody")
  selectedPageBody.addEventListener('contextmenu', (e) => e.preventDefault())
  selectedPageBody.onmousedown = (clickStartEvent) => {
    selectedPageBody.onmousemove = (moveEvent) => {
      if (store.commentGrabbing || croppingStore.rectangleGrabbing || croppingStore.anchorPointGrabbing) return
      
      if (!store.commentMode || clickStartEvent.which === 2) {
        store.drawingIsGrabbing = true

        selectedPageBody.scrollTop -= moveEvent.movementY
        selectedPageBody.scrollRight += moveEvent.movementX
        selectedPageBody.scrollLeft -= moveEvent.movementX
      }

      store.selectedDrawingPage.offsets = {
        scale: store.scaleDrawing,
        scroll: {
          x: selectedPageBody.scrollLeft,
          y: selectedPageBody.scrollTop
        }
      }
    }
    selectedPageBody.onmouseleave = () =>{ 
      selectedPageBody.onmousemove = null
      store.drawingIsGrabbing = false
    }
    selectedPageBody.onmouseup = () =>{ 
      selectedPageBody.onmousemove = null
      store.drawingIsGrabbing = false
    } 
  }

  selectedPageBody.ondragstart = () => false
  selectedPageBody.addEventListener('wheel', wheelEventHandler)
}

export function wheelEventHandler(event) {
  const store = useDrawingsStore()

	if (store.pageIsFittingSize) {
		unfitPage()
		return
	}
	
	if (event.wheelDelta > 0) {
		if (store.scaleDrawing >= 100) store.setScaleDrawing(store.scaleDrawing + 50)
		else store.setScaleDrawing(store.scaleDrawing + 25)
	}
	else {
		if (store.scaleDrawing > 100)  store.setScaleDrawing(store.scaleDrawing - 50)
		else store.setScaleDrawing(store.scaleDrawing - 25)
	}
	
  scaleSelectedPage()
}

export function scaleSelectedPage(returnIfFitting) {
  const store = useDrawingsStore()
  const drawingPageImage = document.getElementById("drawingPageImage")

  if (store.pageIsFittingSize) {
    unfitPage()
    if (returnIfFitting) return
  }

  drawingPageImage.style.transformOrigin = 'top left' 
  drawingPageImage.style.transform = `scale(${store.scaleDrawing / 100})`

  const boundings = drawingPageImage.getBoundingClientRect()
  useDrawingPageStore().imageHeight = boundings.height
  useDrawingPageStore().imageWidth = boundings.width
}
