import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";

const MOUSE_CANVAS_CLICK_TOLERANCE = 5
const NEAREST_DISTANCE = 0.02

/*eslint-disable no-dupe-class-members*/
export class CoordinatePinAddingMode {

  static #hoveringPin = null

  static #keyDownListenerId = null
  static #keyUpListenerId = null
  static #mouseMoveListenerId = null
  static #mouseDownListenerId = null
  static #mouseUpListenerId = null
  static #mouseHoverListenerId = null

  static #mouseDownCanvasX = null
  static #mouseDownCanvasY = null
  static #mouseDownLeft = null

  static #nearestCoord = null
  static #isShiftKeyPressed = false

  static activate() {
    this.#activateInputListeners()
    this.#activateHoverListeners()
  }

  static deactivate() {
    const viewer = XeokitMediator.viewer
    const cameraControl = viewer.cameraControl
    const input = viewer.scene.input

    cameraControl.off(this.#mouseHoverListenerId)

    input.off(this.#keyDownListenerId)
    input.off(this.#keyUpListenerId)

    input.off(this.#mouseMoveListenerId)
    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)

    this.#hoveringPin?.destroy()
    this.#hoveringPin = null
  }

  static #activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    this.#mouseDownListenerId = input.on("mousedown", (coords) => {
      this.#mouseDownCanvasX = coords[0]
      this.#mouseDownCanvasY = coords[1]
      this.#mouseDownLeft = input.mouseDownLeft
    })

    this.#mouseUpListenerId = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.#mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.#mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.#mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.#mouseDownLeft = false 
        return
      }

      if (this.#mouseDownLeft) {
        if (this.#nearestCoord) {
          const worldPos = this.#nearestCoord?.worldPos // Иногда пропадает при быстром отжатии Shift и нажатии ЛКМ
          if (worldPos) XeokitMediator.PickCoordinate.createCoordinatePin({ coordinate: worldPos })
        }
        else {
          const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
            canvasPos: coords,
            pickSurface: true,
          })

          if (!pickResult) { return }
          if (pickResult.isSectionControl) { return }
          if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) { return } 

          XeokitMediator.PickCoordinate.createCoordinatePin({ coordinate: pickResult.worldPos })
        }
      }
      
      this.#mouseDownLeft = false 
    })

    this.#keyDownListenerId = input.on("keydown", (code) => {
      if (code == 16) this.#isShiftKeyPressed = true
    })

    this.#keyUpListenerId = input.on("keyup", (code) => {
      if (code == 16) this.#isShiftKeyPressed = false
    })
  }

  static #activateHoverListeners() {
    this.#hoveringPin = XeokitMediator.PickCoordinate.createHoveringPin({ coordinate: [0, 0, 0], visible: false })
    this.#hoveringPin.showLabelPlaceholder()

    const cameraControl = XeokitMediator.viewer.cameraControl
    this.#mouseHoverListenerId = cameraControl.on('hover', (event) => {
      if (this.#isShiftKeyPressed) {

        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: event.canvasPos,
        })
        
        if (!pickResult) { return }
        if (pickResult.isSectionControl) return
        if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) { return } 

        this.#nearestCoord = geometry.nearestCoordFinder.getNearestCoordOnEdgeOrVertexByCanvasPos({
          canvasPos: event.canvasPos,
          nearestDistance: NEAREST_DISTANCE,
          collisionDetect: true
        })
        
        if (this.#nearestCoord) {
          this.#hoveringPin.setCoordinate(this.#nearestCoord.worldPos)
          this.#hoveringPin.setVisible(true)
        }
      }
      else {
        this.#hoveringPin.setVisible(false)
        this.#nearestCoord = null
      }
    })
  }
}