import { projectService } from '@/_services'
import project from '../project.module'
import task from '../task.module'

let columnType = new Map([
  ["THEME", 1],
  ["STATUS", 2],
  ["PROGRESS", 3],
  ["EXECUTOR", 4],
  ["START", 5],
  ["END", 6],
])

export default {
  namespaced: true,

  state:{
    columnType
  },

  actions: {
    setTaskColumns( { dispatch }, taskColumns ) {
      let columns = []
      taskColumns.map(( column, index ) => {
        columns.push({
          name: { value: columnType.get( column.name ) },
          position: index,
          excluded: !column.show
        })
      })
      task.mutations.setTaskTreeColumns( task.state, columns )
      dispatch( 'updateTaskColumns', columns )
    },

    updateTaskColumns( {state}, taskColumns ){
      state
      project.mutations.setProjectSettingsTaskColumns( project.state, taskColumns )
      projectService.updateTaskColumns( project.state.project.uuid, taskColumns )
    },

    getColumnsType() {
      return columnType
    }

  }
}