import { useDrawingsStore } from "@/pinia"
import { useDrawingPageStore } from "@/pinia"
import vuexStore from "@/store"

import { DrawingComment } from '@/assets/model/drawing/DrawingComment'
import { scaleSelectedPage } from "./drawingPageListeners.facade"
import { imageHeight, imageWidth } from "./drawingPage.facade"

export const pageIsFittingSize = () => useDrawingsStore().pageIsFittingSize
export const commentMode = () => useDrawingsStore().commentMode
export const pageIsLoading = () => useDrawingsStore().pageIsLoading 
export const commentImportMode = () => useDrawingsStore().commentImportMode
export const showContextMenuStatus = () => useDrawingsStore().show.contextMenu
export const contextMenuPosition = () => useDrawingsStore().contextMenuPosition

export function dateFormat(date) {
  return new Date(date).toISOString().split("T")[0].replace(/-/g, "/")
}

export function allComments() {
  return new Set(useDrawingsStore().selectedDrawingPage.attachments.concat(useDrawingsStore().loadCommentsInPage)) 
}

export function currentComments() {
  const store = useDrawingsStore()
  if (store.commentImportMode) return store.commentsToImport
  return store.selectedDrawingPage.attachments
}

export function imageSizes() {
  return { 
    width: useDrawingPageStore().imageWidth,
    height: useDrawingPageStore().imageHeight
  }
}

export function showComments() {
  return !pageIsLoading() && !commentImportMode()
}

export function commentPosition(comment) {
  return {
    left: comment.x + "%",
    top: `calc(${comment.y}% - 30px`,
    position: "absolute"
  }
}

export function panelCommentModeStyle() {
  const style = {}
  
  if (useDrawingsStore().commentMode) {
    if (useDrawingsStore().drawingIsGrabbing) style.cursor = "grabbing"
    else style.cursor = "url(/img/project/tools/comment-mode-cursor.svg) 4 18, auto"
  }
  return style
}

export function showContextMenu(event) {
  event.preventDefault()
  const targetBoundingClientRect = event.target.getBoundingClientRect()

  const left = ((event.clientX - targetBoundingClientRect.left) / targetBoundingClientRect.width ) * 100
  const top = ((event.clientY - targetBoundingClientRect.top) / targetBoundingClientRect.height ) * 100

  useDrawingsStore().contextMenuPosition = {
    top: `calc(${top}% - 34px)`,
    left: left + "%",
    position: "absolute",

    originalCoords: {
      xCoord: left,
      yCoord: top
    }
  }

  useDrawingsStore().show.contextMenu = true

  if (!useDrawingsStore().commentMode) {
    document.body.addEventListener('click', hideContextMenuFunc)
  }
}

function hideContextMenuFunc(event = null) {
	event?.preventDefault()
	document.body.removeEventListener('click', hideContextMenuFunc)

	useDrawingsStore().show.contextMenu = false
}

export function hideContextMenu(event = null) {
  hideContextMenuFunc(event)
}

export function showCommentCreateDialog() {
  hideContextMenu()

  useDrawingsStore().dialogVisible = true
  useDrawingsStore().newCommentForm.text = ''
  
  if (vuexStore.state.task.selectedTask) {
    useDrawingsStore().newCommentForm.task = vuexStore.state.task.selectedTask
    useDrawingsStore().showCommentTaskBinding = true
  }
  else {
    useDrawingsStore().newCommentForm.task = null
    useDrawingsStore().showCommentTaskBinding = false
  }
  
  if (vuexStore.state.task.tasks.length == 0) {
    useDrawingsStore().tasksIsLoading = true

    vuexStore.dispatch('task/loadTasks').then(() => {
      useDrawingsStore().tasksIsLoading = false
    })
  }
}

export function importAllComments() {
  const store = useDrawingsStore()
  store.setCommentImportMode(true)
  store.setFlagComment(true)
  store.setCommentsToImport(store.selectedDrawingPage.attachments)
}

export function scrollPageToComment(comment) {
  const store = useDrawingsStore()

  const pageBody = document.getElementById("drawingSelectedPageBody")
  const bodyBounds = pageBody.getBoundingClientRect()

  store.setScaleDrawing(comment.scale)
  scaleSelectedPage(false)

  setTimeout(() => {
    const xCoord = ((imageWidth() * (comment.x / 100))) - bodyBounds.width / 2
    const yCoord = ((imageHeight() * (comment.y / 100))) - bodyBounds.height / 2

    pageBody.scrollTo(xCoord, yCoord)
  })
}

export function importOneComment(comment) {
  const store = useDrawingsStore()
  store.setCommentImportMode(true)
  store.setFlagComment(false)
  store.addCommentToImportComments(comment)
}

export function showCommentChangeDialog(comment) {
  const store = useDrawingsStore()
  store.changeCommentDialog.show = true
  store.changeCommentDialog.comment = new DrawingComment(null, comment)
  store.getDrawingCommentAttachment(store.changeCommentDialog.comment.uuid).then(data => {
    store.changeCommentDialog.comment.task = data;
  })

  if (vuexStore.state.task.tasks.length == 0) {
    store.tasksIsLoading = true
    vuexStore.dispatch('task/loadTasks').then(() => {
      store.tasksIsLoading = false
    })
  }
}

export function removeComment(comment) {
  useDrawingsStore().deleteComment(comment)
}


