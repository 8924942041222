import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { useAttributeCheckingTableStore } from '@/pinia'
import { AlertService } from '@/assets/app/AlertService'

import _ from 'lodash'

/*eslint-disable no-dupe-class-members*/
export default class AttributeHighlightService {

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get #_settings() {
    return XeokitMediator.ElementsSettings
  }

  static get #_cameraFlight(){
    return XeokitMediator.CameraFlight
  }

  static get #_pinia() {
    return useAttributeCheckingTableStore()
  }

  static get #_selectedElementId() {
    return this.#_pinia.selectedElementId
  }

  static resetHighlight(){
    this.#_settings.setElementsHighlighted(this.#_scene.highlightedObjectIds, false)
    this.#_settings.setElementsXRayed(this.#_scene.xrayedObjectIds, false)
  }

  static #_restoreSelectedElements(elementUuids) {
    this.#_settings.setElementsSelected(elementUuids, false)
    this.#_settings.setElementsHighlighted(elementUuids, false)
    this.#_settings.setElementsXRayed(elementUuids, true)
  }

  static highlightSelectedAttributes(elementUuids) {
    this.resetHighlight()
    if(elementUuids.length){
      this.#_settings.setElementsHighlighted(elementUuids, true)
      this.#_xrayedElement()
    }
  }

  static selectElement(attribute) {
    let globalId = attribute.uuid
    this.#_restoreSelectedElements(this.#_scene.selectedObjectIds)
    
    if (this.#_scene.objects[globalId]) {
      this.#_pinia.loadSelectedElement(globalId)
      this.#_settings.setElementsSelected([globalId], true)
      this.#_cameraFlight.flyToElement(globalId)
    }
    else {
      AlertService.info({ info: "Элемент не имеет геометрии"})
      this.#_pinia.loadSelectedElement(globalId)
      this.#_cameraFlight.flyToDefaultView()
    }

    this.#_xrayedElement(globalId)
    this.#_pinia.selectedAtrrOrRuleUuid = null
  }

  static #_xrayedElement(globalId) {
    if (this.#_scene.highlightedObjectIds.length > 0 || globalId) {
      let list = [...this.#_scene.highlightedObjectIds, globalId]

      let xrayedList = _.difference(this.#_scene.objectIds, list)
      this.#_settings.setElementsXRayed(xrayedList, true)
      this.#_settings.setElementsVisible(this.#_scene.highlightedObjectIds, true)

      // ----- По просьбе от 21.10.2023 ------
    
      // Выставляем всем объектам "запрет" на выделение
      this.#_scene.setObjectsPickable(this.#_scene.objectIds, false)
      // Включаем возможность выделения объектов коллизии
      this.#_scene.setObjectsPickable(list, true)

      // ----- По просьбе от 21.10.2023 ------

      this.#_settings.setXrayMaterialEdgeAlpha(0.3)
      this.#_settings.setXrayMaterialFill(false)
      this.#_settings.setXrayMaterialEdgesWidth(5)
      this.#_settings.setXrayMaterialGlowingThrough(false)
      
      this.#_colorizeSelectedAttribute(globalId)
    }
  }

  static #_colorizeSelectedAttribute(globalId) {
    let element = globalId || this.#_selectedElementId

    this.#_settings.setElementsVisible([element], true)

    const pickedElement = XeokitMediator.ElementsSelection.pickedElement
    let selectElements = [...XeokitMediator.ElementsSelection.selectedElements]
    
    if (pickedElement) {
      selectElements = [pickedElement, ...selectElements]
    }

    this.#_settings.setElementsSelected(selectElements, false)
    this.#_settings.setElementsHighlighted(selectElements, false)
  }

  static restoreSelectedObjects() {
    this.#_pinia.selectedElement = null
    XeokitMediator.objectsMemento.restoreObjects(this.#_scene)
    setTimeout(() => this.#_cameraFlight.flyToDefaultView(), 0)
  }
} 
