<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="2" fill="transparent" />
    <path d="M4.5 26.2615L11.3566 21.5H27.5V21.7363L20.5409 26.5H4.5V26.2615Z" stroke="currentColor" />
    <path d="M4.5 10.2615L11.3566 5.5H27.5V5.73634L20.5409 10.5H4.5V10.2615Z" stroke="currentColor" />
    <rect x="11.5" y="5.5" width="16" height="16" stroke="currentColor" />
    <path d="M19 5V22L13 27V10L19 5Z" fill="currentColor" />
    <rect x="4.5" y="10.5" width="16" height="16" stroke="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>