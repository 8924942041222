export class WarningFeature {

  constructor (wFeature, wProfile = null, warningFeatureType = '') {
    this.warningProfile = wProfile
    this.warningFeatureType = wFeature?.warningFeatureType || warningFeatureType
    this.uuid = wFeature?.uuid || null

    switch (this.warningFeatureType) {
      case "WarningMaintenance": 
        this.delay = wFeature?.delay || null
        this.periodType = wFeature?.periodType || null
        this.equipmentSpecification = wFeature?.equipmentSpecification || null
        break;
      case "WarningChannelValue":
        this.min = wFeature?.min || null
        this.max = wFeature?.max || null
        this.channel = wFeature?.channel || null
        break;
      default:
        break; 
    }
  }

}