export default ({ Factory }) => ({

    /**
     * Получить список палитр групп
     */
    fetchGroupPaletes: () => {
        return Factory.projects.grouppalettes.get()
    },

    /**
     * Получить список палитр групп по id группы
     * @param {UUID} id группы (ProjectAxis.uuid)
     */
    fetchGroupPaletesByProjectAxisId: (projectAxisId) => {
        return Factory.projects.grouppalettes.projectaxis[projectAxisId].get()
    },

    /**
     * Добавить/Обновить палитру группы
     * @param {Object} Палитра группы (GroupPalette)
     */
    saveGroupPalette: (groupPalette) => {
        return Factory.projects.grouppalettes.post(groupPalette)
    },

    /**
     * Изменить элемент палитры группы
     * @param {Object} Элемент палитры группы (GroupPaletteItem)
     */
    editGroupPaletteItem: (groupPaletteItem) => {
     return Factory.projects.grouppaletteitem.put(groupPaletteItem)
    },

    /**
     * Удалить палитру группы
     * @param {UUID} id палитры группы (GroupPalette.uuid)
     */
    deleteGroupPalete: (groupPaletteId) => {
        return Factory.projects.grouppalettes[groupPaletteId].delete()
    },

})