import { $_plugin_server } from '@/_helpers'

export const templateService = {
  getTemplates,
  addTemplate,
  editTemplate,
  deleteTemplate,
  getTemplateContent,
  getFolders,
  addFolder,
  editFolderName,
  deleteFolder,
  changeTemplateFolder,
  editTemplateNameAndFolder,
  getSuggests
}



//template actions
function getTemplates (projectUuid) {
return $_plugin_server.get(`/plugins/template/${projectUuid}`).then(response => response.data)
}

function getSuggests (projectUuid) {
  return $_plugin_server.get(`/plugins/template/suggest/${projectUuid}`).then(response => response.data)
  }

function addTemplate (body) {
return $_plugin_server.post(`/plugins/template/`, body).then(response => response.data)
}

function editTemplate (templateUuid,content) {
return $_plugin_server.patch(`/plugins/template/${templateUuid}?access_token=${localStorage.getItem("access_token")}`, content).then(response => response.data)
}

function deleteTemplate (templateUuid) {
return $_plugin_server.delete(`/plugins/template/${templateUuid}`).then(response => response.data)
}

function getTemplateContent (templateUuid) {
return $_plugin_server.get(`/plugins/template/${templateUuid}/content`).then(response => response.data)
}

//folder actions
function getFolders (projectUuid) {
return $_plugin_server.get(`/plugins/template/folder/${projectUuid}`).then(response => response.data)
}
  
function addFolder (body) {
return $_plugin_server.post(`/plugins/template/folder/`, body).then(response => response.data)
}

function editFolderName (folderUuid,name) {
return $_plugin_server.patch(`/plugins/template/folder/${folderUuid}`, name).then(response => response.data)
}

function deleteFolder (folderUuid) {
return $_plugin_server.delete(`/plugins/template/folder/${folderUuid}`).then(response => response.data)
}

function changeTemplateFolder (folderUuid,templateUuid) {
return $_plugin_server.patch(`/plugins/template/folder/${folderUuid}/${templateUuid}`).then(response => response.data)
}

function editTemplateNameAndFolder(templateUuid,body) {
return $_plugin_server.patch(`/plugins/template/folder/${templateUuid}/edit`,body).then(response => response.data)
}