import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { EdgeRegime } from "./regimes/edgeRegime";
import { VertexToVertexRegime } from "./regimes/vertexToVertexRegime";
import { VertexToEdgeRegime } from "./regimes/vertexToEdgeRegime";
import { EdgeToEdgeRegime } from "./regimes/edgeToEdgeRegime";
import { VertexToFaceRegime } from "./regimes/vertexToFaceRegime";
import { VertexToPlaneRegime } from "./regimes/vertexToPlaneRegime";
import { FaceToFaceRegime } from "./regimes/faceToFaceRegime";
import { RulerRegime } from "./regimes/rulerRegime";
import { PlaneToPlaneRegime } from "./regimes/planeToPlaneRegime";

/*eslint-disable no-dupe-class-members*/
export class RegimeMeasurement {

  static modeClasses = { 
    PointToPointMeasurement: VertexToVertexRegime, 
    EdgeMeasurement: EdgeRegime, 
    PointToEdgeMeasurement: VertexToEdgeRegime, 
    EdgeToEdgeMeasurement: EdgeToEdgeRegime,
    PointToFaceMeasurement: VertexToFaceRegime,
    PointToPlaneMeasurement: VertexToPlaneRegime,
    FaceToFaceMeasurement: FaceToFaceRegime,
    PlaneToPlaneMeasurement: PlaneToPlaneRegime,
    Ruler: RulerRegime
  };

  static get RegimeMeasurementModeClass() {
    return XeokitMediator.RegimeMeasurement.RegimeMeasurementModeClass
  }

  /** Активация режима линейка.
  */
  static activate() {
    this.RegimeMeasurementModeClass.activate()
  }

  /**
   * Выключение режима линейки.
   */
  static deactivate() {
    this.RegimeMeasurementModeClass.deactivate()
  }
}