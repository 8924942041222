import { AxisDataType } from '@/assets/model/axis'
import { drawingService, projectService } from '@/_services'
import _ from 'lodash'
import { App } from '@app/App'
import { api } from '@/api'
import drawings from './drawing/drawings.module'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { AlertService } from '@/assets/app/AlertService'
import { useDrawingsStore } from '@/pinia'

let intervalId = null
let firstStart = true

const processState = {
  idle: 'IDLE',
  ready: 'FINISHED_SUCCESS',
  error: 'FINISHED_ERROR'
}
const baseProcess = { progress: 0, stateProcess: { title: 'Ожидание...', name: processState.idle }}

function getAndTransformProcessWithUUID (uuid, processes) {
  let process = processes.find(p => p.uuid === uuid) || baseProcess
  const complete = [processState.ready, processState.error].includes(process.stateProcess.name)
  const failed = processState.error === process.stateProcess.name
  return { progress: process.progress, name: process.name, state: process.stateProcess.title, complete, failed }
}

function transformUpload (upload) {
  const { progress = 0, name = '' } = upload || {}
  const state = progress == 0 ? 'Ожидание начала загрузки' : progress == 100 ? 'Загрузка успешно завершена' : 'Передача файла на сервер'
  return { progress, name, state, complete: (progress == 100), failed: false }
}


export default {
  namespaced: true,

  modules: {
    drawings
  },

  state: {
    processes: [],
    notifications: [],
    
    uploads: [],
    nextUploadIdx: 0,

    observableUpload: null,
    observableConvertUUID: null,
    observableTreeUUID: null,

    filter: {
      limit: 20, 
      page: 0
    },

    processSocketFlag: null,
    processList: [],

    onlyProjectProcessSocketFlag: null,
    onlyProjectProcessList: [],
  },

  getters: {
    areMonitoredProcessesRunning: ({ observableUpload }) => observableUpload ? true : false,
    monitoredProcesses: ({ observableUpload, observableConvertUUID, observableTreeUUID, processes }) => ({
      upload: transformUpload(observableUpload),
      convert: getAndTransformProcessWithUUID(observableConvertUUID, processes),
      tree: getAndTransformProcessWithUUID(observableTreeUUID, processes)
    }),

    uploadsForModel: ({ uploads }) => (uuid) => uploads.filter(p => p.uuid === uuid),
    processesByModel: ({ processes }) => (uuid) => processes.filter(p => p.modelRevision && p.modelRevision.modelUuid === uuid),

    // hasProcesses: ({ processes, uploads }) => processes.length > 0 || uploads.length > 0,
    hasProcesses: ({ processes, uploads }) => processes.length > 0 || uploads.length > 0,
    hasNotifications: ({ notifications }) => notifications.length > 0,
    hasActiveProcess: ({ processSocketFlag, onlyProjectProcessSocketFlag}) => processSocketFlag || onlyProjectProcessSocketFlag
  },

  mutations: {
    setProcesses: (state, payload = []) => { state.processes = _.uniqBy(payload, 'uuid') },

    setNotifications: (state, payload = []) => { state.notifications = payload },
    addNotifications: (state, payload = []) => { state.notifications = _.uniqBy([  ...state.notifications, ...payload ], 'uuid') },
    removeNotificationsWithUUIDs: (state, UUIDs = []) => { state.notifications = state.notifications.filter(n => !UUIDs.includes(n.uuid)) },

    removeNotificationsWithTypes: (state, notificationType) => { 
      if ( notificationType !== "ALL" ){
        if ( notificationType == "INFO" || notificationType == "COMMAND" ){
          state.notifications = state.notifications.filter(n => n.notificationType.name.includes(notificationType)) 
        }
        else {
          state.notifications = state.notifications.filter(n => n.notificationCommand.name.includes(notificationType)) 
        }
      }
      else{
        state.notifications = []
      }
    },

    setObservableProcessesUUIDs: (state, { convertUUID, treeUUID }) => {
      state.observableConvertUUID = convertUUID
      state.observableTreeUUID = treeUUID
    },
    
    resetMonitoredProcesses: (state) => {
      state.observableUpload = null
      state.observableConvertUUID = null
      state.observableTreeUUID = null
    },

    addUpload: (state, upload) => {
      state.nextUploadIdx = upload.idx + 1
      state.observableUpload = { ...upload }
      state.uploads = [...state.uploads, upload] 
    },
    updateUpload: (state, upload) => { 
      state.uploads = state.uploads.map(p => p.idx === upload.idx ? upload : p)
      if (state.observableUpload && upload.idx === state.observableUpload.idx) state.observableUpload = { ...upload }
    },
    removeUpload: (state, upload) => { 
      state.uploads = state.uploads.filter(p => p.idx !== upload.idx)
      if (state.observableUpload && upload.idx === state.observableUpload.idx) state.observableUpload = { ...upload, progress: 100 }
    },

    setPageFilter: (state, page) => {
      state.filter.page = page
    },

    setProcessSocketFlag: (state, flag) => {
      state.processSocketFlag = flag
    },

    setProcessList: (state, process) => {
      state.processList = process
    },

    setOnlyProjectProcessSocketFlag: (state, flag) => {
      state.onlyProjectProcessSocketFlag = flag
    },

    setOnlyProjectProcessList: (state, process) => {
      state.onlyProjectProcessList = process
    },

    resetProcessLists: (state) => {
      state.processList = []
      state.onlyProjectProcessList = []
    },
  },

  actions: {
    stopActiveProcess({state, commit}, process) {
      state
      console.log(process)
      return projectService.stopActiveProcess(process.uuid).then(() => {
        state.uploads.map(upload => {
          if(process.objectUuid == upload.uuid) {
            commit('removeUpload', upload)
          }
        })
        console.log(state.uploads)
      })
    },

    runMaterialUpload ({ commit, state, rootGetters }, material) {
      commit('resetMonitoredProcesses')
      let projectUuid = rootGetters['project/projectUuid']
      let upload = { idx: state.nextUploadIdx, projectUuid: projectUuid, uuid: material.modelUuid, progress: 0 }
      commit('addUpload', upload)

      return api.materials.addMaterial(material).then((data, { ProcessState, ProcessStateTree })=>{
        commit('removeUpload', upload)
        commit('setObservableProcessesUUIDs', { convertUUID: ProcessState.uuid, treeUUID: ProcessStateTree.uuid })
      })
      .catch(() => { commit('removeUpload', upload) })
    },
    runRevisionUpload ({ commit, state, rootGetters }, obj) {
      commit('resetMonitoredProcesses')
      
      let name = obj.file?.name || 'файл по ссылке'
      let projectUuid = rootGetters['project/projectUuid']
      let upload = { idx: state.nextUploadIdx, projectUuid: projectUuid, uuid: obj.modelUuid, progress: 0, name }
      commit('addUpload', upload)

       return projectService.addRevision(obj, function ({ lengthComputable, loaded = 0, total = 1}) {
        lengthComputable && commit('updateUpload', { ...upload, progress: Math.round((loaded * 100) / total) })
      })
      .then(({ ProcessState, ProcessStateTree }) => {
        commit('removeUpload', upload)
        commit('setObservableProcessesUUIDs', { convertUUID: ProcessState.uuid, treeUUID: ProcessStateTree.uuid })
      })
      .catch(() => { commit('removeUpload', upload) })
    },

    runPointCloudUpload ( { commit, rootGetters, state }, pointCloud ) {
      commit( 'resetMonitoredProcesses' )

      let projectUuid = rootGetters['project/projectUuid']
      let name = pointCloud.file?.name || 'файл по ссылке'
      let upload = { idx: state.nextUploadIdx, projectUuid: projectUuid, uuid: pointCloud.modelUuid, progress: 0, name }

      commit( 'addUpload', upload )

      let pointCloudFormData = new FormData();

      pointCloudFormData.append( "projectUuid", projectUuid )
      pointCloudFormData.append( "file", pointCloud.file )
      pointCloudFormData.append( "modelUuid", pointCloud.modelUuid )
      
      return projectService.addPointCloud( pointCloudFormData, function ( { lengthComputable, loaded = 0, total = 1} ) {
        lengthComputable && commit('updateUpload', { ...upload, progress: Math.round((loaded * 100) / total) } )
      }).then( () => {
        commit( 'removeUpload', upload )
      }).catch( () => { 
        commit('removeUpload', upload) 
      })
    },
    // runDrawingUpload ({ commit, state }, drawing) {
    //   commit('resetMonitoredProcesses')
    //   let name = drawing.name || 'файл по ссылке'
    //   let upload = { idx: state.nextUploadIdx, uuid: drawing.uuid, progress: 0, name }
    //   commit('addUpload', upload)

    //   drawingService.addNewDrawing(drawing, function ({ lengthComputable, loaded = 0, total = 1}) {
    //     lengthComputable && commit('updateUpload', { ...upload, progress: Math.round((loaded * 100) / total) })
    //   })
    //   .then(({ ProcessState, ProcessStateTree }) => {
    //     commit('removeUpload', upload)
    //     commit('setObservableProcessesUUIDs', { convertUUID: ProcessState.uuid, treeUUID: ProcessStateTree.uuid })
    //   })
    //   .catch(() => { commit('removeUpload', upload) })
    // },
    runDrawingVersionUpload ({commit, state, rootGetters}, version) {
      commit('resetMonitoredProcesses')
      let name = version.get('name') || 'файл по ссылке'
      let uuid = version.get('drawingUuid')
      let modelUuid = version.get('modelUuid') || ''
      let projectUuid = rootGetters['project/projectUuid']
      let upload = { idx: state.nextUploadIdx, uuid: uuid, progress: 0, projectUuid: projectUuid, name, modelUuid }
      commit('addUpload', upload)
      console.log(state.uploads);
      
      drawingService.newDrawingVersion(version, function ({ lengthComputable, loaded = 0, total = 1}) {
        lengthComputable && commit('updateUpload', { ...upload, progress: Math.round((loaded * 100) / total) })
      })
      .then((data) => {
        if(data === ''){
          AlertService.error( {data: { error_description: 'Не удалось обработать файл' } } )
          return;
      }
        useDrawingsStore().loadDrawings(data.drawing.projectUuid)
      })
      .then(({ ProcessState, ProcessStateTree},) => {
        commit('removeUpload', upload)
        commit('setObservableProcessesUUIDs', { convertUUID: ProcessState.uuid, treeUUID: ProcessStateTree.uuid })
      })
      .catch(() => { commit('removeUpload', upload) })
    },

    refreshServerEventsNextPage ({ commit, state, dispatch }) {
      let countNotify = state.filter.limit * (state.filter.page + 1)
      if (countNotify == state.notifications.length) { 
        commit("setPageFilter", state.filter.page + 1)
        return dispatch("refreshServerEvents", state.filter).then( () => {
          return { lastPage: false }
        })
      }
      return { lastPage: true }
    },

    refreshServerEvents ({ commit, state, dispatch }, filter = null) {
      const { observableConvertUUID, observableTreeUUID } = state
      const UUIDs = observableConvertUUID && observableTreeUUID ? [observableConvertUUID, observableTreeUUID] : []
      return projectService.allProcessesWithRequiredUUIDs(UUIDs, filter).then(({ processes, notifications }) => {
        commit('setProcesses', processes)
        dispatch('prepareNotifications', notifications)

        if (observableConvertUUID && observableTreeUUID) {
          const convert = getAndTransformProcessWithUUID(observableConvertUUID, processes)
          const tree = getAndTransformProcessWithUUID(observableTreeUUID, processes)

          if (convert.complete && tree.complete) commit('resetMonitoredProcesses')
        }
        
        clearInterval(intervalId)
        dispatch('startUpdating')
        return
      }).catch(err => {
          console.log("error", err);
          console.error(err);
          if (App.auth.refreshToken) {
            clearInterval(intervalId)
            dispatch('startUpdating')
          } else {
            dispatch('stopUpdating')
          }
          return
      }); 
    },

    startUpdating ({ state, dispatch }) {
      let second = 100
      if (!firstStart) {
        let timerProcess = window.settings.notification.timerProcess ? window.settings.notification.timerProcess : 5000 
        let timerNotification = window.settings.notification.timerNotification ? window.settings.notification.timerNotification : 30000 
        second = state.processes.length > 0 ? timerProcess : timerNotification
      }
      intervalId = setTimeout(() => {
        if (process.env.NODE_ENV === 'production' || window.settings.dev.autoUpdateProcesses) {
          let filter = { 
            limit: state.filter.limit, 
            page: 0 
          }
          dispatch('refreshServerEvents', filter)
          firstStart = false
        }
      }, second);
    },

    stopUpdating () {
      clearInterval(intervalId)
    },

    // markNotificationAsRead ({ commit }, UUIDs) {
    //   projectService.readNotificationsWithUUIDs(UUIDs).then(() => {
    //     commit('removeNotificationsWithUUIDs', UUIDs)
    //   })
    // },

    // markNotificationAsReadByType ( { commit }, notificationType ) {
    //   projectService.readNotificationsWithType(notificationType).then(() => {
    //     commit('removeNotificationsWithTypes')
    //   })
    // },

    /*applyNotification ({ dispatch, rootGetters }, uuid) {
      projectService.applyNotificationsWithUUIDs([uuid]).then(() => {
        dispatch('project/loadProject', rootGetters['project/projectUuid'], { root: true })
      })
    },*/

    //eslint-disable-next-line
    prepareNotifications ({ commit, dispatch, rootGetters, rootState }, notifications) {
      const listForUpdate = [
        'APPLY_NEW_REVISION',
        'APPLY_OFFSET_MODEL',
        'APPLY_OFFSET_MODEL_CHANGE_LOCKED',
        'APPLY_REVISION_DELETED',
        'APPLY_MODEL_ARCHIEVE',
        "APPLY_CHANGE_MODEL",
        "COLLISIONS_READY_COMMAND",
        "CONVEXHULL_APPLY",
        "PATCH_BUILD_START",
        "PATCH_BUILD_READY",
        "REVISION_READY"
        // "PATCH_READY"
      ]
      let isTechnicalNotice = n => ((n.sourceType === 'Model' || n.sourceType === 'ModelRevision' || n.sourceType === 'LongActionTask'|| n.sourceType === 'ProjectPatch') && listForUpdate.includes(n.notificationType.name))
      let prepared = notifications.filter(n => !isTechnicalNotice(n))
      commit('addNotifications', prepared)

      let techNotifiations = notifications.filter(isTechnicalNotice)
      if (techNotifiations.length) {
        let projectUuid = rootGetters['project/projectUuid']
//        let thisProjectNotifications = techNotifiations.filter(n => n.source?.projectUuid === projectUuid)
        let thisProjectNotifications = techNotifiations.filter(n => n.project === projectUuid)
        let UUIDs = techNotifiations.map(n => n.uuid)
        console.log(UUIDs);
        if (thisProjectNotifications.length) {
          if (techNotifiations.find(n => n.sourceType == "ProjectPatch")) {
            setTimeout( () => 
              projectService.loadProject(projectUuid).then(data => { 
                Object.keys(XeokitMediator.viewer.scene.models).forEach(modelID => {
                  XeokitMediator.viewer.scene.models[modelID]?.destroy()
                });
                dispatch('project/updateProject', data, { root: true })
                dispatch('patches/loadPatchList', null, { root: true })
            }))
          }
          else{
            dispatch('project/loadProject', projectUuid, { root: true })

            if (rootState.axis.tree.dataType == AxisDataType.COLLISIONS) {
              dispatch('rule/reloadTree', null, { root: true })
            }
            
            if (rootState.axis.tree.dataType == AxisDataType.ELEMENTS || rootState.axis.tree.dataType == AxisDataType.COLLISIONS) {
              dispatch('axis/tree/fetchTree', null, { root: true })
              // MARK: [RightElementTree]
              // TODO: Требуется рефактор
              if (window?.settings?.rework?.elementTree || false) {
                dispatch('right-axis/tree/fetchTree', null, { root: true })
              }
            }
          }
        }
      }
    },

    // Cloud

    refreshServerEventsByCloudProject ({ commit, state, dispatch }, projectUid) {
      const { observableConvertUUID, observableTreeUUID } = state
      projectService.getProcessByCloudProject(projectUid).then(({ processes, notifications }) => {
        commit('setProcesses', processes)
        dispatch('prepareNotifications', notifications)

        if (observableConvertUUID && observableTreeUUID) {
          const convert = getAndTransformProcessWithUUID(observableConvertUUID, processes)
          const tree = getAndTransformProcessWithUUID(observableTreeUUID, processes)

          if (convert.complete && tree.complete) commit('resetMonitoredProcesses')
        }
      })
    },

    startUpdatingByCloudProject ({ dispatch }, projectUid) {
      intervalId = setInterval(() => {
        if (process.env.NODE_ENV === 'production' || window.settings.dev.autoUpdateProcesses) {
          dispatch('refreshServerEventsByCloudProject', projectUid)
        }
      }, 3000);
    },

  }
}