<template lang="pug">
div
  //- TODO: Временное решение пока не перейдем на правую панель
  app-dialog(v-if="$route.path.includes('/admin/')" v-model="$_show" :header="$t('profile.action.title')")
    app-section
      app-language(color="#6b6b6b" @select="setLocale")
      app-themes.mt-3(style='padding-left: 14px')
      v-list(light)
        v-list-item(v-for="(item, index) in actionItems" :key="index" :disabled="item.disabled" ripple tag="div" @click="item.action")
          slot(name="list-item" :item="item" :index="index")
            v-icon.material-icons-outlined(:disabled="item.disabled" size="24") {{ item.icon }}
            span.pl-4.text-caption {{ item.title }}

  div(v-else)
    app-section
      v-hover(v-if="inProject" #default="{ hover }")  
        div(v-if="inProject").pl-4.mt-3.mb-3.user-info
          app-avatar(:url="filePath(user)" :colorObj="getBgColor(user.uuid)" :text="user.name")
          span.ml-1(@click="editProfile()").teammate {{ user.name }}
          v-btn.ml-1(v-if="hover" icon x-small color='#757575' @click="editProfile()")
            v-icon( small ) mdi-pencil
      app-language.mt-4(color="#6b6b6b" @select="setLocale")
      app-themes.mt-4(style='padding-left: 14px')
      v-list.mt-1(light)
        v-list-item(v-for="(item, index) in actionItems" :key="index" :disabled="item.disabled" ripple tag="div" @click="item.action")
          slot(name="list-item" :item="item" :index="index")
            router-link(v-if='item.icon == "settings"' :to='adminPath' style="text-decoration: none; color: inherit;")
              v-icon.material-icons-outlined(:disabled="item.disabled" size="24") {{ item.icon }}
              span.pl-4.text-caption {{ item.title }}
            div(v-else)
              v-icon.material-icons-outlined(:disabled="item.disabled" size="24") {{ item.icon }}
              span.pl-4.text-caption {{ item.title }}
  profile-form(v-model="dialog.editUser" :user="user" :listRoles="roles" @click="confirmEditUser()" @selectedFile="setUserAvatar")

  div(v-if="myUUID")
    access-change-password(v-model="dialog.changePassword" :profileUuid="myUUID")

  app-dialog-confirm(v-model="dialog.confirmClearCache"  @confirm="clearCache()")
    app-text {{this.$t('profile.action.items.confirmClearCache',{size: sizeCache})}}

  app-dialog(v-model="dialog.settingColor" width="300" :header="$t('profile.action.items.colorSetting')" @confirm="setPickerColor()" :confirmText="$t('button.save')" @close="closeSettingColor()")
    v-color-picker(v-model="pickerColor" ot-size="25" mode="hexa" swatches-max-height="200" light)

  access-notify-profile(v-model="dialog.notify")
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import ChangePassword from '@/components/access/ChangePassword'
import NotifyProfile from '@/components/access/NotifyProfile'
import ProfileForm from '@/components/admin/users/ProfileForm'
import AppThemes from '@/components/project/common/Themes.vue'
import AppLanguage from '@/components/project/common/Language.vue'
import uniqolor from 'uniqolor'

import { themes } from '@/store/theme.module'

import { App } from '@app/App'

import DialogToggable from '@/components/app/DialogToggable'
import { SourcePath } from '@app/SourcePath'

export default {
  name: "action-profile",

  props: {
    isPanel: Boolean
  },

  mixins: [DialogToggable],
 
  components: {
    [ChangePassword.name]: ChangePassword,
    [NotifyProfile.name]: NotifyProfile,
    [ProfileForm.name]: ProfileForm,
    AppThemes,
    AppLanguage
  },

  mounted () {
    document.addEventListener("visibilitychange", () => {
      const logoutChannel = new BroadcastChannel('logoutChannel');
      logoutChannel.onmessage = function (event) {
        if (event.data === 'logout' && document.hidden) {
          App.auth.clear()
        }
      }
      window.addEventListener('beforeunload', function () {
        logoutChannel.close()
      })
    })
  },

  computed: {
    ...mapGetters('authUser', ['myUUID', 'isAdmin', 'hasAutorizationEmail']),
    ...mapState('authUser', ['user']),
    ...mapState('project', ['project','topUnit']),
    ...mapGetters('roles', ['roles']),

    adminPath() {
      return `/admin/users`
    },

    themesNameList () {
      return Object.keys(themes).map(key => { return { key: key, title: themes[key].title } })
    },

    themesStyleList () {
      return Object.keys(themes).map(key => { return { name: themes[key].name, style: themes[key].style } })
    },

    actionItems () {
      let adminPanel = []
      if (this.isAdmin)
        adminPanel.push({ icon:'settings', title: this.$t('profile.action.items.adminPanel'), action: () => this.openAdminPanel(), hide: this.$route.path.includes('/admin/') == true})
      
      let changePassword = []
      if (this.hasAutorizationEmail)
        changePassword.push({ icon:'edit', title: this.$t('profile.action.items.changePassword'), action: () =>  this.changePassword()},)
      
      let profileSettings = []
      if(this.topUnit != "profileSettings"){
        profileSettings.push({ icon:'account_box', title: this.$t('profile.action.items.edit'), action: () =>  this.editProfile()},)
      }
      return [
        ...adminPanel,
        ...profileSettings,
        ...[
          
          { icon:'notifications', title: this.$t('profile.action.items.notify'), action: () =>  this.showDialogNotify()},
          { icon:'color_lens', title: this.$t('profile.action.items.colorSetting'), action: () =>  this.settingColor(), hide: this.$router.currentRoute.name != 'project' },
        ],
        ...changePassword,
        ...[
          { icon:'exit_to_app', title: this.$t('profile.action.items.logout'), action: () =>   this.logout()},
          { icon:'mdi-delete', title: this.$t('profile.action.items.clearCache'), action: () =>   this.showConfirmDialogClearCache()}
        ]
      ]
      .filter(item => !item.hide)
    },

    inProject(){
      return this.topUnit == "profileSettings"
    }
  },
  
  methods: {
    ...mapMutations('authUser', ['setUser']),
    ...mapActions('authUser', ['saveUser', 'setProfileSettingLocale', 'setProfileSettingModelBackground']),

    filePath(user){
      return SourcePath.avatar(user.avatar)
    },

    setUserAvatar (fileAvatar) {
      this.avatarFile = fileAvatar
    },

    setLocale (locale) {
      this.setProfileSettingLocale(locale)
    },

    openAdminPanel () {
      return this.$router.push('/admin/users')
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    changePassword () {
      this.dialog.changePassword = true
    },

    editProfile () {
      this.dialog.editUser = true
    },

    confirmEditUser () {
      if (this.avatarFile)
        this.user.avatarFile = this.avatarFile

      this.saveUser(this.user).then(() => {
        this.avatarFile = null
        this.dialog.editUser = false
      })
    },

    async showConfirmDialogClearCache() {
      this.sizeCache = await this.formatBytes()
      this.dialog.confirmClearCache = true
    },

    cachesSize() {
      return caches.keys().then(a => {
        return Promise.all(
          a.map(n => caches.open(n).then(c => this.cacheSize(c)))
        ).then(a => a.reduce((acc, n) => acc + n, 0));
      });
    },
    cacheSize(c) {
      return c.keys().then(a => {
        return Promise.all(
          a.map(req => c.match(req).then(res => res.clone().blob().then(b => b.size)))
        ).then(a => a.reduce((acc, n) => acc + n, 0));
      });
    },

    async formatBytes( decimals = 2) {
      let bytes = await this.cachesSize().then();
      if (bytes === 0) {
        return '0 МБ';
      } else {
        var k = 1024;
        var dm = decimals < 0 ? 0 : decimals;
        var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }
    },

    clearCache() {
      caches.keys().then(function(cacheNames) {
        cacheNames.map(function(cacheName) {
            console.log('Deleting out of date cache:', cacheName);
            return caches.delete(cacheName)
        })
      })
    },

    settingColor () {
      this.dialog.settingColor = true
    },

    setPickerColor () {
      let bgModel = this.pickerColor?.rgba ? `rgba(${this.pickerColor.rgba.r}, ${this.pickerColor.rgba.g}, ${this.pickerColor.rgba.b}, ${this.pickerColor.rgba.a})` : 'none'
      let measurementScrim = document.getElementById("measurementScrim")
      measurementScrim.style.backgroundColor = bgModel

      this.setProfileSettingModelBackground(bgModel)
    },

    showDialogNotify () {
      this.dialog.notify = true
    },
    logout () {
      const logoutChannel = new BroadcastChannel('logoutChannel');
      logoutChannel.postMessage('logout')
      logoutChannel.close()
      this.setUser({name: null})
      App.auth.clear()
      window.location = App.social.authserver+"/logout?logout_uri="+App.social.logout;
    },

    closeSettingColor () {
      this.dialog.settingColor = false
    },
    
  },
  data() {
    return {
      avatarFile: null,
      dialog: {
        changePassword: false,
        editUser: false,
        setting: false,
        notify: false,
        settingColor: false,
        confirmClearCache: false
      },
      pickerColor: null,
      isFocused: false,
      profileTheme: localStorage.getItem("themeItem") ? localStorage.getItem("themeItem") * 1 : 0,
      sizeCache: null

    };
  }
};
</script> 
<style lang="scss" scoped>
.teammate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-info{
  display: flex;
  align-items: center;
  font: normal 12px/24px $roboto;
  color: #212121;
}
</style>