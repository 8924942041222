import { telemetryService } from "@/_services/telemetry.service"

let timeoot = null

const status = {
  init: 'init',
  loaded: 'loaded',
  loading: 'loading',
}

const getDefaultState = () => ({
  channels: [],
  receiver: null,

  status: status.init,
  highlightedChannels: [],
})

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    searchChannels: ({ channels }) => (text) => channels.filter(channel => channel.title.toLowerCase().includes(text.toLowerCase())),

    isChannelsLoading: (state) => state.status === status.loading,
    isChannelHighlighted: ({ highlightedChannels }) => (channelUuid) => highlightedChannels.includes(channelUuid),

    highlightedChannels: ({ highlightedChannels }) => highlightedChannels,
  },

  actions: {
    async setReceiver({ commit, rootGetters, dispatch }, receiver) {
      if (!receiver) {
        commit('SET_CHANNELS', [])
        return commit('SET_RECEIVER', null)
      }

      commit('SET_STATUS', status.loading)

      const channels = await telemetryService.getChannels(receiver.uuid)
      channels.data.forEach(channel => {
        const cachedChannel = rootGetters['channel/cache/getChannel'](channel.uuid)
        
        channel.value = cachedChannel?.value ?? null
        channel.recvTime = cachedChannel?.recvTime ?? null
        
        dispatch('channel/cache/setChannel', channel, { root: true })
      })

      commit('SET_CHANNELS', channels.data)
      commit('SET_RECEIVER', receiver)

      commit('SET_STATUS', status.loaded)
    },

    setHighlightedChannels({commit}, highlightedChannels) {
      commit('SET_HIGHLIGHTED_CHANNELS', highlightedChannels)
    },

    resetState({ commit }) {
      commit('RESET_STATE')
    },

    addChannelToChannels ({state, dispatch}, channel) {
      if (!timeoot && state.receiver && state.receiver.uuid === channel.receiver) {
        if (state.channels && !state.channels.find(ch => ch.uuid === channel.uuid)) {
          timeoot = setTimeout( () => {
            dispatch("setReceiver", state.receiver)
            timeoot = null
          }, 1000)
        }
      }
    },

    deleteChannel ({state, commit}, channelUuid) {
      let channels = state.channels.filter(item => item.uuid !== channelUuid)
      commit('SET_CHANNELS', channels)
    }
  },

  mutations: {
    SET_RECEIVER(state, receiver) {
      state.receiver = receiver
    },

    SET_CHANNELS(state, channels) {
      state.channels = channels
    },

    SET_STATUS(state, status) {
      state.status = status
    },

    SET_HIGHLIGHTED_CHANNELS(state, highlightedChannels) {
      state.highlightedChannels = highlightedChannels
    },

    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  }
}