import Vue from 'vue'

import { App } from '@app/App'
Vue.prototype.$app = App

if (window.settings.performance?.log) {
  let observer = new PerformanceObserver(list => {
    list.getEntries().forEach(entry => {
      if (console) {
        let { name, entryType, startTime, duration } = entry
        console.log(`Name:  ${name}, Type: ${entryType}, Start: ${startTime}, Duration: ${duration}\n`)
      }
    })
  })

  observer.observe({ entryTypes: ['resource', 'mark', 'measure'] })
  performance.mark('registered-observer')

  // performance.mark('Action start')
  // performance.measure('Action time', 'Action start')
}