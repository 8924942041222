import { EquipmentSpecification, EquipmentAttachment } from '.'

export class Equipment {

  constructor (equipment, level = 0, group = null) {
    this.equipmentGroup = group
    this.level = level
    this.uuid = equipment?.uuid || null
    this.name = equipment?.name || ''
    this.project = equipment?.project || null
    this.model = equipment?.model || ''
    this.manufacturer = equipment?.manufacturer || ''
    this.elements = equipment?.elements || []
    this.reestrNumber = equipment?.reestrNumber || ''
    this.serialNumber = equipment?.serialNumber || ''
    this.productionYear = equipment?.productionYear || null
    this.location = equipment?.location || ''    
    this.warrantyStartDate = equipment?.warrantyStartDate || null
    this.operationStartDate = equipment?.operationStartDate || null
    this.equipmentSpecification = equipment && equipment.equipmentSpecification ? new EquipmentSpecification(equipment.equipmentSpecification) : null
    this.attachments = equipment && equipment.attachments ? equipment.attachments.map(item => new EquipmentAttachment(item)) : []
  }

}