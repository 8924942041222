import { math } from "@xeokit/xeokit-sdk"
import { MODEL_TRANSFORM_ID } from '@/plugins/xeokit/RevisionLoader.js'

export const transform = {
  
  /** Координаты по настройкам модели.
   *
   * @param {Array} worldPos Координаты для трансформации WorldPos [x, y, z]
   *
   * @param {PerformanceModel} model Модель, настройки которой устанавливаются
   *
   * @returns {Array} Трансформированные координаты WorldPos [x, y, z]
   */
  transformByModelSettings(worldPos, mesh) {
    const model = mesh.model
    const tempMat4 = math.mat4()
    const tempVec3 = math.vec3()
    const defaultScale = math.vec3([1, 1, 1])
    const defaultPosition = math.vec3([0, 0, 0])
    const defaultQuaternion = math.identityQuaternion()

    const transform = model.transforms[MODEL_TRANSFORM_ID]
    const modelOffset = [mesh.origin[0], mesh.origin[1], mesh.origin[2]] || defaultPosition
    const modelScale = transform?.scale || defaultScale
    const modelQuaternion = transform?.quaternion || defaultQuaternion

    const targetPoint = [worldPos[0] - modelOffset[0], worldPos[1] - modelOffset[1], worldPos[2] - modelOffset[2]]

    let meshOffset = [0, 0, 0]
    switch (mesh.parent.xktVersion) {
      case '10':
        meshOffset = modelOffset
        break

      case '3':
        meshOffset = transform?.position
        break

      default:
        break
    }

    const meshMatrix = math.composeMat4(meshOffset, modelQuaternion, modelScale, tempMat4)

    const correctTargetPoint = math.transformPoint3(meshMatrix, targetPoint, tempVec3)
    return correctTargetPoint
  },

  worldPosToCanvasPos(worldPos, viewer) {
    const tempVec4a = math.vec4()
    const tempVec4b = math.vec4()
    let viewPos = math.vec3()
    math.transformPoint3(viewer.scene.camera.viewMatrix, worldPos, viewPos)

    tempVec4a.set(viewPos)
    tempVec4a[3] = 1.0
    math.transformPoint4(viewer.scene.camera.projMatrix, tempVec4a, tempVec4b)
    const aabb = viewer.scene.canvas.boundary

    let canvasPos = []
    canvasPos[0] = Math.floor(((1 + tempVec4b[0] / tempVec4b[3]) * aabb[2]) / 2)
    canvasPos[1] = Math.floor(((1 - tempVec4b[1] / tempVec4b[3]) * aabb[3]) / 2)

    return canvasPos
  },

  decompressNormals(compressedNormals) {
    // Нет трансформации на настройки модели
    const decompressedNormals = []
    compressedNormals.forEach((normal) => {
      decompressedNormals.push((normal / 255) * 2)
    })

    return decompressedNormals
  },

  decompressNormal(normal) {
    // Нет трансформации на настройки модели
    return (normal / 255) * 2
  },
}