import { api } from '@/api'
import i18n from '@/plugins/i18n'

/*let status = {
  RUN: 0,
  END: 1,
  STOPED: 2
}*/

export default {
  namespaced: true,
  state: {
    listProcess:  [],
    listService: [],
    listType: [],

    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 10,
      sortBy: 'startDate',
      sortDesc: 'DESC',
      totalItems: 0,
    },

    selectedFilterStatus: {title:  i18n.t('module.process.filterTypes.progress'), value: 0},
    selectedFilterType: {title:  i18n.t('filters.all'), value: -1},
  },

  getters: {
    process: state => state.listProcess.sort((a, b) =>  a.startDate && b.startDate && b.startDate.localeCompare(a.startDate)),
    totalItems: state => state.pagination.totalItems,
    rowsPerPage: state => state.pagination.rowsPerPage,
    /*tariffList: state => {
      return state.tariffList.list
    },

    isTariffListLoaded: ({ tariffList }) => tariffList.status == status.LOADED,*/

    filterProcess: state => {
      let res = { 
        //search: state.searchText, 
        filteredStatus: state.selectedFilterStatus ? state.selectedFilterStatus.value : null,  
        filteredType: state.selectedFilterType && state.selectedFilterType.value !== -1 ? state.selectedFilterType.value : null,
        sortField: state.pagination.sortBy,
        sort: state.pagination.sortDesc,
        page: state.pagination.page, 
        pageSize: state.pagination.rowsPerPage }
      return res
    },
  },

  mutations: {
    SET_ITEMS_PER_PAGE: (state, number) => { 
      state.pagination.rowsPerPage = number 
    },
    SET_PAGE: (state, number) => { 
      state.pagination.page= number 
    },

    SET_SORT: (state, sort) => {
      state.pagination.sortBy = sort.value
      state.pagination.sortDesc = sort.sort
    },

    SET_TOTAL_ELEMENTS_COUNT: (state, number) => { 
      state.pagination.totalItems = number 
    },

    SET_PROCESS: (state, process) => {
      state.listProcess = process
    },

    SET_FILTER_STATUS: (state, filter) => {
      state.selectedFilterStatus = filter
    },

    SET_FILTER_TYPE: (state, type) => {
      state.selectedFilterType = type
    },

    SET_SERVICES: (state, list) => {
      state.listService = list
    },

    SET_TYPES: (state, types) => {
      state.listType = types
    }
    /*SET_SEARCH_TEXT: (state, text) => {
      state.searchText = text
    },*/
    
  },
  
  actions: {
    INIT: ({ dispatch }) => {
      dispatch('LOAD_PROCESS')
      dispatch('LOAD_SERVICES')
      dispatch('LOAD_TYPES')
    },

    refreshData: ({ dispatch }) => {
      dispatch('LOAD_PROCESS')
      dispatch('LOAD_SERVICES')
    },

    LOAD_PROCESS: ({ commit, getters }) => {
      return api.adminProcess.searchProcess(getters.filterProcess).then(data => {
        commit('SET_PROCESS', data.content)
        commit('SET_TOTAL_ELEMENTS_COUNT', data.totalElements)
      })
    },

    LOAD_SERVICES: ({ commit }) => {
      api.adminProcess.getServiceList().then(data => {
        commit('SET_SERVICES', data)
      })
    },

    LOAD_TYPES: ({ commit }) => {
      api.adminProcess.getProcessType().then(data => {
        let types = [{ title: i18n.t('filters.all'), value: -1 }, ...data]
        commit('SET_TYPES', types)
      })
    },

    loadNextPage: ({ commit, dispatch }, {pageNumber, itemsPerPage}) => {
      commit('SET_ITEMS_PER_PAGE', itemsPerPage);
      commit('SET_PAGE', pageNumber);
      dispatch('LOAD_PROCESS')
    },

    changeFilterStatus: ({ commit }, status) => {
      commit('SET_FILTER_STATUS', status)
    },

    changeFilterType: ({ commit }, type) => {
      commit('SET_FILTER_TYPE', type)
    },

    changeSort: ({ commit, dispatch }, sort) => {
      commit('SET_SORT', sort)
      commit('SET_PAGE', 1)
      dispatch('LOAD_PROCESS')
    },

    refreshList:({ dispatch }) => {
      dispatch('LOAD_PROCESS')
    },

    deleteProcess:({ dispatch }, stopedProcessUuid) => {
      api.adminProcess.stopProcess(stopedProcessUuid).then(() => {
        dispatch('LOAD_PROCESS')
      })
    }
  }
}