import { DevOptions } from '@app/DevOptions'
import { Authorization } from '@app/Authorization'
import { NetworkConnection } from '@app/NetworkConnection'
import { AppVersion } from '@app/AppVersion'
import { SocialConnection } from '@app/Social'
import { AppAssets } from '@app/AppAssets'
import { AppDevice } from '@app/Device'

let config = window.settings || {}
let networkConnection = new NetworkConnection(config)
let devOptions = new DevOptions(config)
let version = new AppVersion(config)
let social = new SocialConnection(config)
let assets = new AppAssets(config)
let device = new AppDevice(config)

class AppManager {
  
  #_version
  #_server
  #_dev
  #_social
  #_assets
  #_device
  
  constructor () {
    this.auth = new Authorization
    this.#_server = Object.freeze(networkConnection)
    this.#_dev = Object.freeze(devOptions)
    this.#_version = Object.freeze(version)
    this.#_social = Object.freeze(social)
    this.#_assets = Object.freeze(assets)
    this.#_device = Object.freeze(device)
  }
  
  get server () {
    return this.#_server
  }
  
  get dev () {
    return this.#_dev
  }
  
  get version () {
    return this.#_version
  }

  get social () {
    return this.#_social
  }
    
  get assets () {
    return this.#_assets
  }

  get device () {
    return this.#_device
  }
}

export const App = new AppManager
