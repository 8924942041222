/* eslint-disable no-dupe-class-members */
import { useSectionPlanesStore } from "@/pinia"
import { XeokitMediator } from "../XeokitMediator"
import { PickSectionPlanesPlugin } from "./pickSectionPlanesPlugin"

/**
 * @static
 */
class SectionPlanes {

  static #_initialized = false
  /**
   * @type {PickSectionPlanesPlugin}
   */
  static #_pickSectionPlanesPlugin

  /**
   * @type {{[key: string]: Date}}
   */
  static #_dates = {}

  static async init() {
    if (this.#_initialized) {
      return
    }

    this.#_initialized = true
    const viewer = XeokitMediator.viewer

    this.#_pickSectionPlanesPlugin = new PickSectionPlanesPlugin(viewer)
    this.#_pickSectionPlanesPlugin.on('update', () => this.#_updateStore())
    this.#_pickSectionPlanesPlugin.on('picked', () => this.active = false)
  }

  static get #_sectionPlanesStore() {
    return useSectionPlanesStore()
  }

  static get #_shownControlSectionPlaneId() {
    return this.#_pickSectionPlanesPlugin.shownControlSectionPlaneId
  }

  /**
   * Возвращает все существующие секущие плоскости.
   * @returns {Object[]}
   */
  static get sectionPlanes() {
    return this.#_sectionPlanesStore.sectionPlanes
  }

  static set active(val) {
    this.#_pickSectionPlanesPlugin.active = !!val
  }

  static get active() {
    return this.#_sectionPlanesStore.pickSectionPlanesActive
  }

  static get shownControlSectionPlane() {
    return this.#_pickSectionPlanesPlugin.shownControlSectionPlane
  }

  static async activateSectionPlane(sectionPlaneId) {
    this.#_pickSectionPlanesPlugin.activateSectionPlane(sectionPlaneId)
  }

  static deactivateSectionPlane(sectionPlaneId) {
    this.#_pickSectionPlanesPlugin.deactivateSectionPlane(sectionPlaneId)
  }

  static deleteSectionPlane(sectionPlaneId) {
    this.#_pickSectionPlanesPlugin.deleteSectionPlane(sectionPlaneId)
    delete this.#_dates[sectionPlaneId]
  }

  static createSectionPlane(pos, dir) {
    return this.#_pickSectionPlanesPlugin.createSectionPlane(pos, dir)
  }

  static showSectionPlaneControl(sectionPlaneId) {
    this.#_pickSectionPlanesPlugin.showSectionPlaneControl(sectionPlaneId)
  }

  static hideSectionPlaneControl(sectionPlaneId) {
    this.#_pickSectionPlanesPlugin.hideSectionPlaneControl(sectionPlaneId)
  }

  static flipSectionPlane(sectionPlaneId) {
    this.#_pickSectionPlanesPlugin.flipSectionPlane(sectionPlaneId)
  }

  static clearSectionPlanes() {
    this.#_pickSectionPlanesPlugin.clearSectionPlanes()
  }

  static reset() {
    this.#_initialized = false
    this.#_sectionPlanesStore.$reset()
  }

  static #_updateStore() {
    const active = this.#_pickSectionPlanesPlugin.active
    const sectionPlanes = this.#_pickSectionPlanesPlugin.sectionPlanes.map((xktSectionPlane) => this.#_sectionPlaneMapper(xktSectionPlane))

    this.#_sectionPlanesStore.setSectionPlanes(sectionPlanes)
    this.#_sectionPlanesStore.setPickSectionPlanesActive(active)
  }

  static #_sectionPlaneMapper(xktSectionPlane) {
    const sectionPlane = {
      id: xktSectionPlane.id,

      dir: xktSectionPlane.dir,
      pos: xktSectionPlane.pos,

      active: xktSectionPlane.active,
      shownControl: xktSectionPlane.id === this.#_shownControlSectionPlaneId,
      
      createDate: this.#_getSectionPlaneCreateDate(xktSectionPlane.id),
    }

    return sectionPlane
  }

  static #_getSectionPlaneCreateDate(sectionPlaneId) {
    return this.#_dates[sectionPlaneId] || (this.#_dates[sectionPlaneId] = new Date())
  }
}

export { SectionPlanes }