<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="7"
    viewBox="0 0 20 20"
    fill="none"
  > 
    <!-- transform="rotate(90)" -->
    <rect x="7" y="6" width="1" height="200" fill="currentColor"></rect>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>