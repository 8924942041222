import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { config } from '@/_helpers/config'

Vue.use(VueI18n);

// Испоьзуется только для тех переводов, которые нужны сразу при загрузке страницы
// Для других сценариев добавляй перевод на */admin/languages
const messages = {
  'ru': {'dashboard.title':'Проекты','app.page.defaultTitle':'Проект в объект'},
  'en': {'dashboard.title':'Projects','app.page.defaultTitle':'Project to object'}, 
};

const dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  'ru-RU': {
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    long: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  }
}


const i18n = new VueI18n({ 
  dateTimeFormats,
  locale: localStorage.getItem('locale') || 'ru', // set locale 
  fallbackLocale: 'en', // set fallback locale
  silentTranslationWarn: true, 
  messages : messages, // set locale messages

  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    'ru': function(choice, choicesLength) {
      // this === VueI18n экземпляра, так что свойство locale также существует здесь

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  }
});


i18n.updateLocale = (locale) => {
  fetch(`${config.notificationUrl}languages/locale/${locale}`)
  .then(data => data.json())
  .then(json => i18n.setLocaleMessage(locale, json))
}

i18n.updateLocales = (locales) => {
  return fetch(`${config.notificationUrl}languages/locale/`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(locales)
  })
  .then(data => data.json())
  .then(json => locales.forEach(lang=>i18n.setLocaleMessage(lang, json[lang])))
}

i18n.loadDefaultLocale = () => i18n.updateLocales(["ru","en"])

export default i18n