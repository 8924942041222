import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"
import { MeasurementAxis } from "./measurementAxis"

/*eslint-disable no-dupe-class-members*/
export class MeasurementAxes {

  #scene; #axesNode; #xAxis; #yAxis; #zAxis; // class instances
  #decimalPlaces; #units; #axesLengths;      // axes visual settings
  #origin;                                   // axes tranformation params

  /**
   * Создает координатные оси между двумя точками измерений с орисовкой величин по каждой координате
   * 
   * @param {Component} scene Сцена. Создаваемая ось будет закреплена за сценой, уничтожение сцены повлечет уничтожение и этого компонента.
   * @param {Object} cfg Конфиг.
   * @param {Number} cfg.axesLengths Величины измерений.
   * @param {Number} cfg.decimalPlaces Отображаемое количество знаков после запятой.
   * @param {String} cfg.units Единица измерения. Допускаются значения "mm", "m", "сm", "km".
   */
  constructor(scene, cfg) {
    this.#scene = scene

    this.#axesNode = null
    this.#xAxis = null
    this.#yAxis = null
    this.#zAxis = null

    this.#origin = cfg.origin ?? [0, 0, 0]

    this.#decimalPlaces = cfg.decimalPlaces
    this.#units = cfg.units
    this.#axesLengths = cfg.axesLengths

    this.#createAxes()
  }

  /**
   * @private Создать оси координат измерения.
   */
  #createAxes() {
    this.#axesNode = new XeokitNode(this.#scene, {
      visible: true,
      origin: this.#origin
    })

    this.#xAxis = new MeasurementAxis(this.#axesNode, {
      decimalPlaces: this.#decimalPlaces,
      units: this.#units,
      length: this.#axesLengths[0],
      color: [1, 0, 0],
      coordinateAxis: "X",
    })
    this.#yAxis = new MeasurementAxis(this.#axesNode, {
      decimalPlaces: this.#decimalPlaces,
      units: this.#units,
      length: this.#axesLengths[1],
      color: [0, 1, 0],
      coordinateAxis: "Y"
    })
    this.#zAxis = new MeasurementAxis(this.#axesNode, {
      decimalPlaces: this.#decimalPlaces,
      units: this.#units,
      length: this.#axesLengths[2],
      color: [0, 0, 1],
      coordinateAxis: "Z"
    })
  }

  /**
   * @public Установить новое положение координатных осей в измерении.
   * 
   * @param {Object} cfg Конфиг.
   * @param {Number[]} cfg.p0 Начальная точка измерения в формате [x, y, z].
   * @param {Number[]} cfg.p1 Конечная точка измерения в формате [x, y, z].
   * @param {Number[]} cfg.position Смещение всего измерения относительно начала координат в формате [x, y, z].
   * @param {Number} cfg.xLength Величина измерения по оси X.
   * @param {Number} cfg.yLength Величина измерения по оси Y.
   * @param {Number} cfg.zLength Величина измерения по оси Z.
   */
  update(cfg) {
    const p0 = cfg.p0
    const p1 = cfg.p1
    const position = cfg.position
    
    const xLength = cfg.xLength
    const yLength = cfg.yLength
    const zLength = cfg.zLength

    this.#xAxis.setLength(xLength)
    this.#yAxis.setLength(yLength)
    this.#zAxis.setLength(zLength)

    const halfLegth = this.#createOffsetBySearchTargetOctant(p0, p1, [xLength / 2, yLength / 2, zLength / 2])
    const halfXlength = halfLegth[0]
    const halfYlength = halfLegth[1]
    const halfZlength = halfLegth[2]
    
    const xAxisScale = [xLength, xLength, xLength]
    const yAxisScale = [yLength, yLength, yLength]
    const zAxisScale = [zLength, zLength, zLength]

    const xAxisPosition = [position[0], position[1] - halfYlength, position[2] - halfZlength]
    const yAxisPosition = [position[0] + halfXlength, position[1], position[2] - halfZlength]
    const zAxisPosition = [position[0] + halfXlength, position[1] + halfYlength, position[2]]

    this.#xAxis.setTransform({ position: xAxisPosition, scale: xAxisScale }) 
    this.#yAxis.setTransform({ position: yAxisPosition, scale: yAxisScale }) 
    this.#zAxis.setTransform({ position: zAxisPosition, scale: zAxisScale })
  }

  /**
   * @private Установить знаки координат x, y, z длины до конечной точки в зависимости от расположения начальной точки измерения (с помощью октантов).
   * 
   * @param {Number[]} origin Начальная точка измерения в формате [x, y, z].
   * @param {Number[]} target Конечная точка измерения в формате [x, y, z].
   * @param {Number[]} halfPos Вектор по каждой оси координат, отражающий половину длины каждой в формате [x, y, z].
   * 
   * @returns halfPos с установленными знаками 
   */
  #createOffsetBySearchTargetOctant(origin, target, halfPos) {
    const originX = origin[0]
    const originY = origin[1]
    const originZ = origin[2]

    const targetX = target[0]
    const targetY = target[1]
    const targetZ = target[2]

    if (targetX >= originX && targetY >= originY && targetZ >= originZ)
      return [halfPos[0], halfPos[1], halfPos[2]]
        
    else if (targetX < originX && targetY >= originY && targetZ >= originZ)
      return [-halfPos[0], halfPos[1], halfPos[2]]
        
    else if (targetX < originX && targetY < originY && targetZ >= originZ)
      return [-halfPos[0], -halfPos[1], halfPos[2]]
        
    else if (targetX >= originX && targetY < originY && targetZ >= originZ)
      return [halfPos[0], -halfPos[1], halfPos[2]]
        
    else if (targetX >= originX && targetY >= originY && targetZ < originZ)
      return [halfPos[0], halfPos[1], -halfPos[2]]
        
    else if (targetX < originX && targetY >= originY && targetZ < originZ)
      return [-halfPos[0], halfPos[1], -halfPos[2]]
        
    else if (targetX < originX && targetY < originY && targetZ < originZ)
      return [-halfPos[0], -halfPos[1], -halfPos[2]]
        
    else if (targetX >= originX && targetY < originY && targetZ < originZ)
      return [halfPos[0], -halfPos[1], -halfPos[2]]

    return [halfPos[0], halfPos[1], halfPos[2]]
  }

  /**
   * @public Отобразить вместо величины измерения текстовый заполнитель (Пример - "<> mm").
   */
  showPlaceholder() {
    this.#xAxis.label.showPlaceholder()
    this.#yAxis.label.showPlaceholder()
    this.#zAxis.label.showPlaceholder()
  }

  /**
   * @public Отобразить величину измерения.
   */
  showLength() {
    this.#xAxis.label.showLength()
    this.#yAxis.label.showLength()
    this.#zAxis.label.showLength()
  }

  /**
   * @public Установить настройки измерения.
   */
  setMeasurementSettings(units, decimalPlaces, needShow = false) {
    this.#xAxis.label.setMeasurementSettings(units, decimalPlaces, needShow)
    this.#yAxis.label.setMeasurementSettings(units, decimalPlaces, needShow)
    this.#zAxis.label.setMeasurementSettings(units, decimalPlaces, needShow)
  }

  /**
   * @public Установить видимость.
   */
  setVisible(value) {
    this.#axesNode.visible = value
  }

  /**
   * @public Установить видимость указанной оси.
   */
  setAxisVisible(axis, value) {
    switch (axis) {
      case "x": this.#xAxis.setVisible(value); break;
      case "y": this.#yAxis.setVisible(value); break;
      case "z": this.#zAxis.setVisible(value); break;
      default: return
    } 
  }

  /**
   * @public Уничтожить координатные оси в измерении.
   */
  destroy() {
    this.#axesNode.destroy()
  }
}