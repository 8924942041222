<template>
  <svg
    class="svg-icon2"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_685_116)">
      <path
        d="M1.3856 2.88208V3H0.465828V2.88208H1.3856ZM0.507381 1.36484V3H0.369246V1.36484H0.507381ZM1.27105 2.09819V2.21611H0.465828V2.09819H1.27105ZM1.37999 1.36484V1.48389H0.465828V1.36484H1.37999ZM1.73047 2.19141L1.6249 2.16108L1.70464 1.36484H2.52222V1.49063H1.81919L1.76191 2.04092C1.79036 2.0207 1.83154 2.00086 1.88545 1.9814C1.9401 1.96118 2.0015 1.95107 2.06963 1.95107C2.14225 1.95107 2.20814 1.96343 2.26729 1.98813C2.32718 2.01209 2.37847 2.04728 2.42114 2.0937C2.46382 2.13937 2.49676 2.19478 2.51997 2.25991C2.54318 2.3243 2.55479 2.3973 2.55479 2.47891C2.55479 2.55752 2.54505 2.62977 2.52559 2.69565C2.50612 2.76154 2.47655 2.81919 2.43687 2.8686C2.39718 2.91727 2.34665 2.95508 2.28525 2.98203C2.22386 3.00898 2.15086 3.02246 2.06626 3.02246C2.00112 3.02246 1.93973 3.01348 1.88208 2.99551C1.82518 2.97679 1.77427 2.94871 1.72935 2.91128C1.68442 2.87384 1.64774 2.82668 1.61929 2.76978C1.59159 2.71287 1.57437 2.64624 1.56763 2.56987H1.69678C1.70576 2.64325 1.7256 2.70501 1.7563 2.75518C1.787 2.80534 1.82817 2.84315 1.87983 2.8686C1.93224 2.89406 1.99438 2.90679 2.06626 2.90679C2.12466 2.90679 2.17557 2.89743 2.21899 2.87871C2.26317 2.85999 2.29985 2.83267 2.32905 2.79673C2.359 2.76004 2.38146 2.71549 2.39644 2.66309C2.41216 2.60993 2.42002 2.54928 2.42002 2.48115C2.42002 2.42275 2.41178 2.36922 2.39531 2.32056C2.37884 2.27114 2.35451 2.22809 2.32231 2.19141C2.29087 2.15397 2.25194 2.12515 2.20552 2.10493C2.1591 2.08472 2.10594 2.07461 2.04604 2.07461C1.96818 2.07461 1.90604 2.08509 1.85962 2.10605C1.81395 2.12702 1.7709 2.15547 1.73047 2.19141ZM3.72397 1.36484V1.44233L3.02543 3H2.88617L3.58022 1.48052H2.64921V1.36484H3.72397Z"
        transform="scale(4)"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_685_116">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.svg-icon2 {
  height: 16px;
  width: 16px;
  align-items: center;
}
</style>>