<template lang="pug">
div
  app-dialog(v-model="$_show" :header="$t('profile.action.items.changePassword')" width="350" :confirmText="$t('button.change')" :confirmDisabled="!acceptPassword" @confirm="changePassword")
    app-section
      v-form(ref="formPassword")
        app-text-field(
          v-model="form.passwd"
          :rules="[rules.required, rules.symbols]"
          :append-icon="showPSWD ? 'visibility' : 'visibility_off'"
          :type="showPSWD ? 'text' : 'password'"
          focus
          :label="$t('profile.form.password')"
          @click:append="showPSWD = !showPSWD"
        )

        app-text-field(
          v-model="form.passwdConfirm" 
          :rules="[rules.required, rules.symbols, passwordConfirmationRule]"
          :type="showPSWD ? 'text' : 'password'"
          :hint="passwordConfirmationRule != true ? passwordConfirmationRule : ''"
          :label="$t('profile.form.repeatPassword')"
        )
        //- v-btn.mt-3( :disabled='!acceptPassword' color='accent' elevation='0' light @click='changePassword()' ) {{ $t('button.change') }}
    app-dialog-loading(v-model="loading")

    
</template>

<script>
import { authUserService } from "@/_services";

import DialogToggable from '@/components/app/DialogToggable'

export default {
  name: "access-change-password",

  mixins: [DialogToggable],

  props: {
    profileUuid: String
  },

  data () {
    const defaultForm =  Object.freeze({
        passwd: null,
        passwdConfirm: null,
    })
    return {
      rules: {
        required: value => !!value || this.$t('error.require'),
        symbols: value => !!value?.trim() || this.$t('error.symbols'),
      },
      form: Object.assign({}, defaultForm),
      defaultForm,
      showPSWD: false,
      loading: false
    }
  },

  watch: {
    $_show () {
      this.form = Object.assign({}, this.defaultForm)
    }
  },
  computed: {
    passwordConfirmationRule () {
      return this.form.passwd === this.form.passwdConfirm || this.$t('error.password')
    },

    acceptPassword() {
      return this.form.passwd === this.form.passwdConfirm && this.form.passwd?.trim()
    }
  },

  methods: {
    changePassword () {
      if (this.$refs.formPassword.validate()) {
        this.$_show = false
        this.loading = true
        authUserService.changePassword( this.profileUuid, this.form).then(() => this.loading = false)
      }
    }
  }
}
</script>