import { Model } from '@vuex-orm/core'

export default class WormConditionValid extends Model {
  static entity = 'WormConditionValid'

  static primaryKey = 'wormUuid'

  static fields () {
    return {
      wormUuid: this.attr(null),
      conditionsValid: this.attr(null)
    }
  }

  static apiConfig = {
    actions: {
      checkByWormNode(uuid) {
        return this.get(`/worm/projectworm/node/${uuid}/checkconditions`)
      }
    }
  }
}