import { languagesService } from '@/_services/languages.service'
import i18n from '@/plugins/i18n'

export default {
  namespaced: true,

  state: {
    aliases: [],
    translations: [],
    locales: [],

    selectedAlias: null,
    exportLocale: null,
    currentLocale: null,
    selectedLocale: null,
  },

  getters: {
    selectedAlias: ({ state }) => {
      state.selectedAlias
    },

    aliasesList: () => {
      return i18n.messages
    },
  },

  mutations: {
    setAliases(state, payload) {
      state.aliases = payload
    },
    setTranslations(state, payload) {
      state.translations = payload
    },
    setLocales(state, payload) {
      state.locales = payload
    },
    setSelectedAlias(state, payload) {
      state.selectedAlias = payload
    },
    setCurrentLocale(state, payload) {
      state.selectedLocale = payload
    },
    setSelectedLocale(state, payload) {
      state.selectedLocale = payload
    },
  },

  actions: {
    init({ dispatch }) {
      dispatch('loadLocales')
    },

    loadLocales({ state, commit }) {
      return languagesService.getAllLocales().then((data) => {
        commit('setLocales', data)
        if (state.selectedLocale == null) commit('setSelectedLocale', data[0])
      })
    },

    createLocale({ dispatch }, locale) {
      languagesService.createLocale(locale).then(() => {
        i18n.updateLocale(locale)
        dispatch('loadLocales')
      })
    },

    createAlias(obj, alias) {
      return languagesService.createAlias(alias).then(() => {
        i18n.loadDefaultLocale()
        return true
      })
    },

    createTranslation(obj, translation) {
      return languagesService.createTranslation(translation).then(() => {
        i18n.updateLocale(translation.textLocale)
        return true
      })
    },

    exportTranslation({ state }, localeText) {
      state.exportLocale = localeText
      languagesService.exportTranslation(localeText).then(json => download(localeText, json))
    },

    importTranslation({ dispatch }, importForm) {
      languagesService.importTranslation(importForm).then(() => {
        dispatch('init')
        return true
      })
    },

    async importAlias(alias) {
      return await languagesService.createAlias(alias).then(() => {
        return true
      })
    },
  },
}

function download(locale, json) {
  let content = JSON.stringify(json, null, 4)
  let file = new Blob([content], { type: 'application/json' })
  let a = document.createElement('a')
  a.href = URL.createObjectURL(file)
  a.download = `translation-${locale}.json`
  a.click()
}
