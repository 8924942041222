<template lang="pug">
.app-floating-panels-section( ref="floatingPanelsSection" )
  draggable( ref="floatPanelGraggable" :disabled="dragDropDisabled" handle=".floating-panel-header-container" 
    @openPanel="openPanelOn" @registerPanel="registerPanel" @unregisterPanel="unregisterPanel"
    @closePanel="closePanelOn"
    @openBody="openBody" @end="draggedPanels()" )
    slot
</template>

<script>
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: "FloatingPanelsSection",

  components: { 
    draggable
  },

  props: {
    dragDropDisabled: { type: Boolean, default: true },
    saveSettings: { type: Boolean, default: true }
  },

  data(){
    return {
      panels: []
    }
  },

  provide() {
    return {
      floatingPanels: this.panels
    }
  },

  mounted() {
    const floatingPanelsSection = this.$refs['floatingPanelsSection']
    if (floatingPanelsSection != undefined) {
      this.pageResizeObserver = new ResizeObserver(() => {
        this.panels.map((panel) => {
          this.openPanel(panel.element, panel.elementBody)
        })
      })

      this.pageResizeObserver.observe(floatingPanelsSection)
    }
  },

  beforeDestroy() {
    let panelsSettings = []
    this.panels.map((panel) =>{
      panelsSettings.push({
        floatingPanel: panel.key,
        height: panel.startHeight == 0 ? 1 : panel.startHeight,
        opened: panel.openedPanel

      })
    })
    this.updateFloatingPanels(panelsSettings)
  },

  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
  },

  methods: {
    ...mapActions('floatingPanels', ['updateFloatingPanels']),

    draggedPanels(){
      let newTurn = []
      Array.from(this.$refs['floatPanelGraggable'].$el.children).forEach((element) => {
        let panel = this.panels.find((panel) => panel.element == element)
        if (panel) {
          this.unregisterPanel(panel.element)
          newTurn.push(panel)
        }
      })
      newTurn.forEach((panel) => {
        this.registerPanel(panel)
      })
    },

    calcPanelHeightByProcent(panel, panelHeight){
      let height = 0
      if (panelHeight > 0) {
        // height = ((panel.lastHeight / this.$refs.floatingPanelsSection?.offsetHeight) * 100) 
        height = panel.startHeight
      }
      else {
        height = ((panel.startHeight / this.$refs.floatingPanelsSection?.offsetHeight) * 100) 
      }
      return ~~height
    },

    openPanelOn(element, elementBody = null, elementResizer = null, firstOpen = false){
      let panelsSettings = []
      this.panels.map((panel) => {
        if (panel.openedPanel && (panel.elementBody?.offsetHeight > 0)) {
          panel.startHeight = this.calcPanelHeightByProcent(panel, panel.elementBody?.offsetHeight)
        }
        panelsSettings.push({
          floatingPanel: panel.key,
          height: panel.startHeight == 0 ? 1 : panel.startHeight,
          opened: panel.openedPanel
        })
      })

      this.openPanel(element, elementBody, elementResizer, firstOpen)
      this.updateFloatingPanelsSettings(panelsSettings)
    },

    closePanelOn(element, elementBody){
      let panelsSettings = []
      this.panels.map( panel => {
        if (panel.openedPanel && (panel.elementBody?.offsetHeight > 0)) {
          panel.startHeight = this.calcPanelHeightByProcent(panel, panel.elementBody?.offsetHeight)
        }
        panelsSettings.push({
          floatingPanel: panel.key,
          height: panel.startHeight == 0 ? 1 : panel.startHeight,
          opened: panel.openedPanel
        })
      })
      
      this.openPanel(element, elementBody)
      this.updateFloatingPanelsSettings(panelsSettings)
    },

    updateFloatingPanelsSettings: _.debounce(function(panelsSettings) {
      this.updateFloatingPanels(panelsSettings)
    }, 1000),

    openPanel(element, elementBody = null, elementResizer = null, firstOpen = false){
      if (elementBody != null && elementResizer != null) {
        this.panels.find((panel) => panel.element == element).elementBody = elementBody
        this.panels.find((panel) => panel.element == element).elementResizer = elementResizer
      }

      let openPanel = this.panels.find((panel) => panel.element === element)
      if (openPanel) {
        if (openPanel.openedPanel) {
          this.openAutoHeight(openPanel, element, firstOpen)
          this.onPanelResizer(openPanel, this.updateFloatingPanelsSettings)
        }
        else {
          this.closeAutoHeight()
        }
      }
    },

    openBody(element, openedPanel){
      this.panels.find((panel) => panel.element == element).openedPanel = openedPanel
    },

    registerPanel(panel){
      this.panels.push(panel)
      this.openPanelOn(panel.element, null, null, true)
      this.panels.filter((element) => (element != panel && element.elementResizer)).forEach(element=>{
        this.onPanelResizer(element, this.updateFloatingPanelsSettings)
      })
    },

    unregisterPanel(element){
      let panel = this.panels.findIndex(panel => panel.element == element)
      if (panel >= 0) {
        this.panels.splice(panel, 1)
        this.closeAutoHeight()
      }
    },

    closeAutoHeight(){
      let sortedPanels = this.panels.filter((panel) => panel.openedPanel == true && panel.elementBody)
        .sort((a, b) => ( a.elementBody.offsetHeight ) > ( b.elementBody.offsetHeight ) ? -1 : 1)

      let occupiedHeight = 0    
      this.panels.map((p)=> {
        occupiedHeight = occupiedHeight + p.element.offsetHeight + p.marginBottom
      })

      let freeHeight = this.$refs.floatingPanelsSection?.offsetHeight - occupiedHeight
      if (sortedPanels.length)
        sortedPanels[0].elementBody.style.height = `${ sortedPanels[0].elementBody.offsetHeight + freeHeight }px`
    },

    calcOpeningPanelHeightByProcent(openPanel){
      let height = ((this.$refs.floatingPanelsSection?.offsetHeight / 100) * openPanel.startHeight) 
      return ~~height
    },

    calcAllowHeight(otherPanels, openPanel){
      let allowHeight = openPanel.headerHeight + openPanel.headerBorderSize 
      otherPanels.forEach(panel => {
        if (panel.openedPanel) {
          allowHeight = allowHeight + panel.headerHeight + panel.headerBorderSize + panel.bodyMinHeight + panel.marginBottom
        }
        else {
          allowHeight = allowHeight + panel.headerHeight + panel.marginBottom
        }
      })
      allowHeight = this.$refs.floatingPanelsSection?.offsetHeight - allowHeight - openPanel.marginBottom
      return allowHeight
    },

    openAutoHeight(openPanel, element, firstOpen = false){
      let otherPanels = this.panels.filter( panel => panel.element != element )
      let occupiedHeight = openPanel.headerHeight + openPanel.headerBorderSize 
      otherPanels.map((p) => {
        occupiedHeight = occupiedHeight + p.element.offsetHeight + openPanel.marginBottom
      })

      if (firstOpen) {
        this.largerElement(otherPanels, openPanel.headerHeight, openPanel)
      }
      else {
        let startPanelHeight = this.calcOpeningPanelHeightByProcent(openPanel)
        if (startPanelHeight <= 0 ) {
          startPanelHeight = openPanel.bodyMinHeight
        }
        let needHeight = startPanelHeight - ( this.$refs.floatingPanelsSection?.offsetHeight - occupiedHeight ) 

        if (needHeight > 0) {
          if (startPanelHeight <= openPanel.bodyMinHeight) {
            this.largerElement(otherPanels, openPanel.bodyMinHeight, openPanel)
            if (openPanel.elementBody?.style) openPanel.elementBody.style.height = `${openPanel.bodyMinHeight + openPanel.marginBottom - openPanel.headerBorderSize}px`
          }
          else {
            let allowHeight = this.calcAllowHeight(otherPanels, openPanel)
            if (allowHeight < startPanelHeight) {
              this.largerElement(otherPanels, allowHeight, openPanel)
              if (openPanel.elementBody?.style) openPanel.elementBody.style.height = `${allowHeight}px`
            }
            else {
              this.largerElement(otherPanels, needHeight, openPanel)
              if (openPanel.elementBody?.style) openPanel.elementBody.style.height = `${startPanelHeight}px`
            }
          }
        }
        else {
          if (openPanel.elementBody?.style) 
            openPanel.elementBody.style.height = `${( this.$refs.floatingPanelsSection?.offsetHeight - occupiedHeight ) - openPanel.marginBottom}px`
        }
      }
    },

    largerElement(otherPanels, needHeight, openPanel){
      let sortedPanels = otherPanels.filter( panel => panel.openedPanel == true && panel.elementBody )
        .sort((a, b) => ( a.elementBody.offsetHeight ) > ( b.elementBody.offsetHeight ) ? -1 : 1)

      let usedNeedHeight = needHeight + openPanel.marginBottom
      sortedPanels.forEach((panel) => {
        if (usedNeedHeight > 0) {
          let allowHeight = panel.elementBody.offsetHeight - panel.bodyMinHeight
          if (allowHeight <= usedNeedHeight) {
            panel.elementBody.style.height = `${panel.bodyMinHeight}px`
            usedNeedHeight = usedNeedHeight - allowHeight
          }
          else {
            panel.elementBody.style.height = `${panel.elementBody.offsetHeight - usedNeedHeight}px`
            usedNeedHeight = 0
          }
        }
      })
    },

    onPanelResizer(openPanel, updateFloatingPanelsSettings){
      let y = 0
      let h = 0
      let allowUpDY = 0
      let allowDownDY = 0
      let freeDownHeight = 0

      let panels = this.panels
      let afterPanels = panels.slice(panels.findIndex((panel) => panel == openPanel) + 1)
      
      let largerPanels = []
      let largerBeforePanels = []

      let floatingPanelsSectionHeight = this.$refs.floatingPanelsSection?.offsetHeight
      

      const calcAllowDownDY = function (afterDownSize){
        let occupiedHeight = 0
        if (afterPanels.length > 0) {  
          afterPanels.forEach((panel) => {
            if (panel.openedPanel) {
              occupiedHeight = occupiedHeight + panel.headerHeight + panel.headerBorderSize + panel.bodyMinHeight + panel.marginBottom  
            }
            else {
              occupiedHeight = occupiedHeight + panel.headerHeight + panel.marginBottom  
            }
          })
        }
        return ( afterDownSize - occupiedHeight )
      }
      
      const calcAllowUpDY = function (beforePanels){
        let allowUp = 0
        if (beforePanels.length > 0) {  
          beforePanels.forEach((panel) => {
            if (panel.openedPanel)
              allowUp = allowUp + (panel.elementBody.offsetHeight - panel.bodyMinHeight) 
          })
        }
        return allowUp * -1
      }

      const largerElement = function (needHeight){
        let usedNeedHeight = needHeight
        for (let i = 0; i < largerPanels.length; i++) {
          let allowLargeElement = largerPanels[i][1] - largerPanels[i][0].bodyMinHeight
          let afterPanel = []
          if (largerPanels[i+1]) afterPanel = largerPanels.slice(i + 1, largerPanels.length)

          if (usedNeedHeight <= allowLargeElement){
            afterPanel.forEach((panel) => {
              panel[0].elementBody.style.height = `${panel[1]}px`
            })
            largerPanels[i][0].elementBody.style.height = `${(largerPanels[i][1] - usedNeedHeight)}px`
            break
          }
          else {
            largerPanels[i][0].elementBody.style.height = `${(largerPanels[i][0].bodyMinHeight)}px`
            usedNeedHeight = usedNeedHeight - allowLargeElement
          }
        }
      }

      const largerUpElement = function (needHeight){
        let usedNeedHeight = -1 * needHeight
        for (let i = 0; i < largerBeforePanels.length; i++) {
          let allowLargeElement =  largerBeforePanels[i][1] - largerBeforePanels[i][0].bodyMinHeight
          let beforepanel = []
          if (largerBeforePanels[i+1]) beforepanel = largerBeforePanels.slice(i+1, largerBeforePanels.length)

          if (usedNeedHeight <= allowLargeElement) {
            beforepanel.forEach((panel) => {
              panel[0].elementBody.style.height = `${panel[1]}px`
            })
            largerBeforePanels[i][0].elementBody.style.height = `${(largerBeforePanels[i][1] - usedNeedHeight)}px`
            largerPanels[0][0].elementBody.style.height = `${(largerPanels[0][1] - needHeight)}px`
            break
          }
          else {
            largerBeforePanels[i][0].elementBody.style.height = `${(largerBeforePanels[i][0].bodyMinHeight)}px`
            usedNeedHeight = usedNeedHeight - allowLargeElement
          }
        }
      }

      const calcFreeDownHeight = function (afterDownSize){
        let occupiedHeight = 0
        if (afterPanels.length > 0) {  
          afterPanels.map((panel) => {
            occupiedHeight = occupiedHeight + panel.element.offsetHeight + panel.marginBottom 
          })
        }
        return ( afterDownSize - occupiedHeight )
      }

      const mouseDownHandler = function (e) {
        y = e.clientY
        largerPanels = []
        largerBeforePanels = []
        
        let afterDownSize = 0
        let beforePanels = panels.slice(0, panels.findIndex((panel) => panel == openPanel) + 1)
        beforePanels.forEach((panel) => {
          afterDownSize = afterDownSize + panel.element.offsetHeight + panel.marginBottom
        })
        
        afterPanels.filter((panel) => panel.openedPanel == true ).forEach((panel) => {
          largerPanels.push([panel, parseInt(window.getComputedStyle(panel.elementBody).height, 10)])
        })
        largerPanels = largerPanels.filter( panel=> !isNaN(panel[1]) )

        beforePanels.filter((panel) => panel.openedPanel == true ).forEach((panel) => {
          largerBeforePanels.push([panel, parseInt(window.getComputedStyle(panel.elementBody).height, 10)])
        })
        largerBeforePanels.reverse()

        panels.forEach((panel) => {
          panel.lastHeight = panel?.elementBody?.offsetHeight ?? 0
        })

        afterDownSize = floatingPanelsSectionHeight - afterDownSize
        allowUpDY = calcAllowUpDY(beforePanels)
        allowDownDY = calcAllowDownDY(afterDownSize)
        freeDownHeight = calcFreeDownHeight(afterDownSize)

        const styles = window.getComputedStyle(openPanel.elementBody)
        h = parseInt(styles.height, 10)

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
      }

      const mouseMoveHandler = function (e) {
        const dy = e.clientY - y
        if (dy < freeDownHeight && dy > allowUpDY) {
          if (dy > 0) {
            openPanel.elementBody.style.height = `${h + dy}px`
          }
          else {
            panels.forEach(panel => {
              if (panel.elementBody)
                panel.elementBody.style.height = `${panel.lastHeight}px`
            })
            openPanel.elementBody.style.height = `${h + dy}px`
            largerUpElement(dy)
          }
        }
        else if (dy < allowDownDY && dy > allowUpDY) {
          panels.forEach(panel => {
            if (panel.elementBody)
              panel.elementBody.style.height = `${panel.lastHeight}px`
          })
          largerElement(dy)
          openPanel.elementBody.style.height = `${h + dy}px`
        }
      }
      
      const calcHeightByProcent = function (panel, panelHeight) {
        let height = 0
        if (panelHeight > 0) {
          height = ((panelHeight / floatingPanelsSectionHeight) * 100) 
        }
        else {
          height = ((panel.startHeight / floatingPanelsSectionHeight) * 100) 
        }
        height = ~~height 
        return height == 0 ? 1 : height
      }

      const mouseUpHandler = function () {
        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)

        let panelsSettings = []
        panels.forEach(panel => {
          panel.startHeight = calcHeightByProcent(panel, panel.elementBody?.offsetHeight)
          panelsSettings.push({
            floatingPanel: panel.key,
            height: panel.startHeight,
            opened: panel.openedPanel
          })
        })
        
        updateFloatingPanelsSettings(panelsSettings)
      }

      if(openPanel.panelResizerFunc){
        openPanel.elementResizer.removeEventListener('mousedown', openPanel.panelResizerFunc)
      }
      if (openPanel.elementResizer) {
        openPanel.elementResizer.addEventListener('mousedown', mouseDownHandler)
        openPanel.panelResizerFunc = mouseDownHandler
      }
    },
    
  }
  
}
</script>

<style>
.app-floating-panels-section{
  width: 100%;
  height: 100%;
  max-height: auto;
}
</style>