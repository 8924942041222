import { useLightsStore } from '@/pinia';
import { VTF } from "@/plugins/viewerTools/VTF";
import store from '@/store';

export class BimLights {

  static get #_lightsStore() {
    return useLightsStore()
  }

  /** Возвращает настройки освещения.
   * @returns {Object}
   */
  static get lightSettings() {
    return this.#_lightsStore.lightSettings
  }

  static setLightSettings(lightSettings) {
    this.#_lightsStore.setLightSettings(lightSettings)
    store.dispatch("project/updateSettingsProjectLights", lightSettings)
  }

  /**
   * Загружает освещение на сцене.
   */
  static loadLights() {
    VTF.BimLights.loadLights()
  }

  /**
   * Устанавливает цвет источника света.
   * @param {Object} light Источник света.
   * @param {Number[]} [color = [255, 255, 255]] Цвет элементов [r, g, b].
   */
  static setColorLight(light, color) {
    VTF.BimLights.setColorLight(light, color)
  }

  /**
   * Создает новый источник света.
   * @param {Number[]} [pos = null] Позиция источника света. По умолчанию создает источник света в позиции {@link Camera.eye}.
   */
  static addDirLight(pos = null) {
    VTF.BimLights.addDirLight(pos)
  }

  /**
   * Удаляет источник света
   * @param {Object} light Удаляемый источник света.
   */
  static destroyDirLight(light) {
    VTF.BimLights.destroyDirLight(light)
  }

  /**
   * Обновляет источник света.
   * @param {Object} light
   */
  static updateSettings(light) {
    VTF.BimLights.updateSetting(light)
  }

  static deleteLightSettingById(id) {
    this.setLightSettings(this.lightSettings.filter(l => l.id !== id))
  }
}