<template> 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H24V24H0V0Z" fill="#F0F0F0"/>
    <path d="M13.5 13.5938V4.03125L21.5312 10.4062V19.9688L13.5 13.5938Z" stroke="#FF0000"/>
    <path d="M2.53143 13.4375V3.53125H10.5002V13.4375H2.53143Z" stroke="#FF0000"/>
    <path d="M16.5002 14.0317V9.03174" stroke="#303030"/>
    <path d="M6.51581 11.0315L6.51581 6.03174" stroke="#303030"/>
    <path d="M16.8125 11.5317L6.50018 8.5" stroke="#303030"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>