<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.67331 4.02L7.67998 6.16667L2.66665 5.5L2.67331 4.02ZM7.67331 9.83333L2.66665 11.98V10.5L7.67331 9.83333ZM1.33998 2L1.33331 6.66667L11.3333 8L1.33331 9.33333L1.33998 14L15.3333 8L1.33998 2Z"
      fill="#757575"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>