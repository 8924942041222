<template>
  <svg
    width="48"
    height="15"
    viewBox="0 0 48 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="15" fill="white" />
    <path d="M35 3H33V5H35V3Z" fill="#FF0000" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.993 12H42.1389V5.76912H39.993V12ZM4 3H6.15715V12H4V3ZM6.86125 6.4616H10.8033C11.0449 6.4616 11.2024 6.3135 11.2734 6.12434C11.3123 6.02034 11.3319 5.8984 11.3319 5.76928C11.3319 5.64016 11.3123 5.51821 11.2734 5.41422C11.2025 5.22506 11.0449 5.07696 10.8033 5.07696H6.86125V3.0002H10.8033C12.0988 3.0002 12.9256 3.74959 13.2851 4.70855C13.4134 5.05104 13.4777 5.4141 13.4777 5.76932C13.4777 6.12453 13.4134 6.48756 13.2851 6.83009C13.2364 6.96 13.1791 7.08599 13.1133 7.20687C13.4134 7.47271 13.6394 7.79286 13.793 8.14018C13.9588 8.51486 14.0335 8.92203 14.0203 9.32588C14.0073 9.72805 13.9064 10.1273 13.7225 10.4879C13.2901 11.3345 12.4221 11.979 11.1656 11.996L11.1531 12H6.86125V9.92324H11.1531V9.92704C11.484 9.91892 11.7024 9.76808 11.803 9.57131C11.8523 9.47461 11.8795 9.36789 11.8829 9.261C11.8863 9.15594 11.8667 9.04957 11.8233 8.95132C11.7329 8.7471 11.5032 8.57859 11.1141 8.53844H10.8032H6.86121L6.86125 6.4616ZM15.4561 5.07692H17.602V3H15.4561V5.07692ZM15.4561 12H17.602V5.76924H15.4561V12ZM19.3902 3H21.5362V12H19.3902V3ZM28.6891 4.30621L25.4806 10.0898L22.2515 4.28584V8.14292L24.3974 12H24.4208H26.5434H26.5667L28.6891 8.17431V4.30621ZM31.5504 3H29.4044V12H31.5504V3ZM33.3387 12H35.4847V5.76912H33.3387V12ZM37.1317 5.07692H45V3H37.1317V5.07692Z"
      fill="#303030"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>