<template lang="pug">
v-dialog( v-model="visible" hide-overlay persistent :width="width" )
  v-card( color="var(--v-surface-base)" dark )
    v-card-text.app-dialog-loading_color
      slot {{ $t('app.dialogLoading.text') }}
      v-progress-linear.mb-0( indeterminate color="var(--v-surface-lighten2)" )
</template>

<script>
export default {
  name: 'app-dialog-loading',

  props: {
    show: Boolean,
    width: { type: [Number, String], default: 300 }
  },

  model: {
    prop: 'show',
    event: 'updateShow'
  },

  computed: {
    visible: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('updateShow', value)
      }
    }
  }
}
</script>

<style scoped>
.app-dialog-loading_color {
  color: var(--v-primary-darken2) !important;
}
</style>
