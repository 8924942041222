<template lang='pug' functional>
div
  app-section-external-header(:class="{'header-ellipse' : props.headerEllipsis}") {{ props.title }}
  app-section( :rounded="false" ).h-100
    slot
</template>


<script>
export default {
  name: "AppPanelSection",
  props:{ 
    title: String,
    headerEllipsis: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style scoped>
.header-ellipse{
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>