<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="19" y="19" width="9" height="4" stroke="currentColor"/>
  <path d="M4 13C4 10.2386 6.23858 8 9 8H14C16.7614 8 19 10.2386 19 13V23H9C6.23858 23 4 20.7614 4 18V13Z" stroke="currentColor"/>
  <path d="M28 23V16" stroke="currentColor"/>
  <path d="M25 23V21" stroke="currentColor"/>
  <path d="M22 23V21" stroke="currentColor"/>
  <circle cx="11.5" cy="15.5" r="3" fill="currentColor" stroke="currentColor"/>
</svg>

</template>

<script>
export default {}
</script>