<template lang="pug">
v-menu(v-model="menu" ref="menu" :return-value.sync="time" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto")
  template(v-slot:activator="{ on, attrs }")
    slot(:time="time" :on="on" :attrs="attrs" )
  v-time-picker(v-model="time" format="24hr" light scrollable @input="$emit('input', $event)")
    v-spacer
    v-btn(text @click="menu = false") {{ $t('button.cancel') }}
    v-btn(text @click="$refs.menu.save(time)") {{ $t('button.save') }}
</template>

<script>
export default {
  name: 'TimePicker',

  props: {
    value: null,
  },

  data() {
    return {
      menu: false,
      time: this.value,
    }
  },
}
</script>
