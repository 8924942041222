<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.5 10V12.5H6.5V6.5H12.5V9.5H10H9.5V10Z" stroke="#303030"/>
  <rect x="9.5" y="9.5" width="4" height="4" fill="#303030" stroke="#303030"/>
  <rect x="2" y="2" width="2" height="2" fill="#303030"/>
  <rect x="16" y="2" width="2" height="2" fill="#303030"/>
  <rect x="9" y="2" width="2" height="2" fill="#303030"/>
  <rect x="9" y="16" width="2" height="2" fill="#303030"/>
  <rect x="2" y="9" width="2" height="2" fill="#303030"/>
  <rect x="16" y="9" width="2" height="2" fill="#303030"/>
  <rect x="2" y="16" width="2" height="2" fill="#303030"/>
  <rect x="16" y="16" width="2" height="2" fill="#303030"/>
</svg>
</template>

<script>
export default {}
</script>