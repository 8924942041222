<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0022 9.99915C12.1952 9.99915 8.94164 12.4877 7.62312 16.0022C8.94164 19.5123 12.1952 22.0008 16.0022 22.0008C19.8048 22.0008 23.0584 19.5123 24.3769 16.0022C23.0584 12.4877 19.8048 9.99915 16.0022 9.99915ZM16.0022 19.9983C13.9007 19.9983 12.1952 18.2073 12.1952 16.0022C12.1952 13.7927 13.9007 12.0017 16.0022 12.0017C18.0993 12.0017 19.8048 13.7927 19.8048 16.0022C19.8048 18.2073 18.0993 19.9983 16.0022 19.9983ZM16.0022 13.5992C14.7377 13.5992 13.7162 14.6702 13.7162 16.0022C13.7162 17.3298 14.7377 18.4008 16.0022 18.4008C17.2668 18.4008 18.2838 17.3298 18.2838 16.0022C18.2838 14.6702 17.2668 13.5992 16.0022 13.5992Z"
      fill="currentColor"
    />
    <path
      d="M3.50106 5.99861V3.50107H5.99859M3.50106 8.00114V12.0017M3.50106 26.0014V28.499H5.99859M3.50106 13.9907V17.9913M3.50106 19.9938V23.9944M28.4764 5.99861V3.50107H25.9744M28.4764 8.00114V12.0017M23.9989 3.50107H20.0028M28.4764 26.0014V28.499H25.9744M28.4764 13.9907V17.9913M18.0093 3.50107H14.0087M28.4764 19.9938V23.9944M12.0062 3.50107H8.00563M23.9989 28.4855H20.0028M18.0093 28.4855H14.0087M12.0062 28.4855H8.00563"
      stroke="currentColor"
      stroke-width="0.567009"
      stroke-miterlimit="22.9256"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>