import { buildCylinderGeometry, EmphasisMaterial, Mesh, PhongMaterial, VBOGeometry } from "@xeokit/xeokit-sdk";

const STATE_INHERIT = true
const NO_COLOR = [0, 0, 0]

export class PivotAxis {

  #owner; #axis; 
  #position; #scale; #quaternion; #origin; #rotation;
  #visible; #color;

  constructor(owner, cfg) {
    this.#owner = owner
    this.#axis = null

    this.#position = cfg.position ?? [0, 0, 0]
    this.#scale = cfg.scale ?? [1, 1, 1]
    this.#rotation = cfg.rotation ?? [0, 0, 0]
    this.#quaternion = [0, 0, 0, 1]
    this.#origin = cfg.origin ?? [0, 0, 0]

    this.#color = cfg.color ?? [1, 1, 1]
    this.#visible = cfg.visible ?? true

    this.#create()
  }

  #create() {
    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255]

    const geometry = new VBOGeometry(this.#owner, buildCylinderGeometry({
      radiusTop: 0.015,
      radiusBottom: 0.015,
      height: 0.24,
      radialSegments: 3,
      heightSegments: 1
    }))

    const material = new PhongMaterial(this.#owner, {
      emissive: color,
      diffuse: NO_COLOR,
      ambient: NO_COLOR,
    })

    const selectedMaterial = new EmphasisMaterial(this.#owner, {
      fill: true,
      edges: false,
      fillColor: color,
      fillAlpha: 1,
    })

    this.#axis = new Mesh(this.#owner, {
      geometry: geometry,
      material: material,
      selectedMaterial: selectedMaterial,
      origin: this.#origin,
      position: this.#position,
      rotation: this.#rotation
    })

    this.#owner.addChild(this.#axis, STATE_INHERIT)
  }
}