<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2297 6L20.3862 10H12V6H26.2297ZM11 10V6.35637L5.75317 10H11ZM4 10L11 5.13889V5H28V22L21 26.7917V27H4V10ZM5.75317 26H20V22H11.5132L5.75317 26ZM21 22V25.5798L26.2297 22H21ZM21 21V10.7917L27 6.68452V21H21ZM20 11H12V21H20V11ZM11 11H5V25.3056L11 21.1389V11Z" fill="#303030"/>
    <path d="M19 11V21L13 26V10.5L19 6V10" stroke="#FF0000"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>