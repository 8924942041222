import { api } from '@/api'
import { defineStore } from 'pinia'
import { useMaintenanceScheduleStore as sheduleStore } from './maintenanceSchedule.store'
import vuexStore from '@/store'

export const useMaintenanceDialogStore = defineStore('maintenanceDialogs', {
  state: () => ({}),

  getters: {
    maintenanceTaskSettings() {
      return vuexStore.state.project.maintenanceTaskSettings
    },

    maintenanceRules() {
      return vuexStore.getters['corp/equipments']
    },

    selectedEquipment() {
      return vuexStore.state.maintenance.selectedEquipment
    },

    specificationUuid() {
      return vuexStore.state.maintenance?.selectedEquipment?.equipmentSpecification?.uuid
    },
  },

  actions: {
    async addMaintenanceTask(obj) {
      return api.maintenance.addMaintenanceTask(obj).then(sheduleStore().updateData)
    },

    async loadEquipmentRules(specificationUuid) {
      return api.corp.orgMaintenanceRules(specificationUuid)
    },

    async recalcTaskPlanWithParam(equipmentUuid, params) {
      return api.maintenance
        .recalcTaskPlanWithParam(equipmentUuid, params)
        .then(() => {
          return api.maintenance.loadTasks(equipmentUuid)
        })
        .then((mTasks) => {
          vuexStore.commit('maintenance/setEquipmentTasks', mTasks)
        })
    },
  },
})
