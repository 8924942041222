import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { math } from '@xeokit/xeokit-sdk'
import { VertexPin } from '../../distanceMeasurement/hoveringPins/vertexPin'
import { EdgePin } from '../../distanceMeasurement/hoveringPins/edgePin'
import { SpherePin } from '../../distanceMeasurement/hoveringPins/spherePin'

const MOUSE_CANVAS_CLICK_TOLERANCE = 5
const DISTANCE_COEFF = 0.05
const MAGNET_DISTANCE = 0.02

/*eslint-disable no-dupe-class-members*/
export class RulerRegime {

  static #scene = null
  static #activeMeasurementId = null

  static #firstPoint = null
  static #nearestCoordAndEdges = null

  static #mouseWorldPos = math.vec3()

  static #spherePin = null
  static #edgePin = null
  static #vertexPin = null

  static #keyUpListenerId = null
  static #mouseHoverListenerId = null
  static #mouseUpListenerId = null
  static #mouseDownListenerId = null

  static #mouseDownCanvasX
  static #mouseDownCanvasY
  static #mouseDownLeft = false
  static #mouseDownRight = false

  /**
   * Включить режим измерения рулеткой.
   */
  static activate() {
    this.#scene = XeokitMediator.viewer.scene
    this.#activateInputListeners()
    this.#activateHoverListener()
  }

  /**
   * Отключить режим измерения рулеткой.
   */
  static deactivate() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#keyUpListenerId)
    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)
    cameraControl.off(this.#mouseHoverListenerId)
    
    this.#firstPoint = null
  }

  static #pick(pickResult) {
    const pickedEntity = pickResult.entity
    const isPointMesh = pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')

    if (pickResult.isSectionControl) return

    if (!isPointMesh) {
      if (!this.#activeMeasurementId) {
        this.#firstPoint = this.#mouseWorldPos.slice()
  
        const targetWorldPos = this.#firstPoint.slice()
        this.#activeMeasurementId = XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
          originWorldPos: this.#firstPoint, 
          targetWorldPos: targetWorldPos, 
          needAxes: true
        })

        const activeMeasurement = XeokitMediator.DistanceMeasurement.measurements[this.#activeMeasurementId]
        activeMeasurement?.showLabelPlaceholder()
      } 
      else {
        const activeMeasurement = XeokitMediator.DistanceMeasurement.measurements[this.#activeMeasurementId]
        activeMeasurement?.showLabelLength()
        this.#activeMeasurementId = null
        this.#firstPoint = null
      }
    }
  }

  static #updateActiveMeasurement() {
    const activeMeasurement = XeokitMediator.DistanceMeasurement.measurements[this.#activeMeasurementId]
    if (!activeMeasurement) return

    const transform = activeMeasurement.createTransformByTwoPoints(this.#firstPoint, this.#mouseWorldPos)
    activeMeasurement.setTransform(transform)
    activeMeasurement.setTarget(this.#mouseWorldPos)
    XeokitMediator.DistanceMeasurement.updateStorePointsAndLengthsByMeasurementId(this.#activeMeasurementId)
  }

  static #activateInputListeners() {
    const input = this.#scene.input

    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)

    this.#mouseDownListenerId = input.on('mousedown', (coords) => {
      this.#mouseDownCanvasX = coords[0]
      this.#mouseDownCanvasY = coords[1]
      this.#mouseDownLeft = input.mouseDownLeft
      this.#mouseDownRight = input.mouseDownRight
    })

    this.#mouseUpListenerId = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.#mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.#mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.#mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.#mouseDownLeft = false
        this.#mouseDownRight = false
        return
      }

      if (this.#mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
        })

        if (pickResult) this.#pick(pickResult)
      }

      this.#mouseDownLeft = false
      this.#mouseDownRight = false
    })

    this.#keyUpListenerId = input.on("keyup", (keycode) => {
      if (keycode != 16) { return }
      if (!this.#nearestCoordAndEdges) { return }

      const edges = this.#nearestCoordAndEdges.edges
      edges.forEach(edge => {
        XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
          originWorldPos: edge[0], 
          targetWorldPos: edge[1], 
          needAxes: true
        })
      })
    })
  }

  static #activateHoverListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    this.#vertexPin = new VertexPin( this.#scene, { visible: false } )
    this.#edgePin = new EdgePin( this.#scene, { visible: false } )
    this.#spherePin = new SpherePin( this.#scene, { visible: false } )

    this.#mouseHoverListenerId = cameraControl.on('hover', (event) => {
      if (this.#mouseDownLeft || this.#mouseDownRight) {
        this.#vertexPin.setVisible(false)
        this.#edgePin.setVisible(false)
        return
      }

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
      })
      
      if (!pickResult) return
      if (pickResult.isSectionControl) return
      if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) return 

      this.#nearestCoordAndEdges = geometry.nearestCoordFinder.getNearestCoordAndEdgesOnEdgeOrVertexByWorldPos({
        worldPos: pickResult._worldPos,
        nearestDistance: MAGNET_DISTANCE,
        collisionDetect: XeokitMediator.DistanceMeasurement.isCollisionDetectActive,
      })

      const magnetPoint = this.#nearestCoordAndEdges.nearestCoord // появляется на ребрах и вершине. Какое именно ребро определяется количеством ребер

      if (magnetPoint) {
        this.#mouseWorldPos.set(magnetPoint)

        if (magnetPoint && this.#nearestCoordAndEdges.edges.length == 1) {
          this.#vertexPin.setVisible(false)
          const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, magnetPoint) * DISTANCE_COEFF
          this.#spherePin.update({
            position: magnetPoint,
            scale: [scale, scale, scale]
          })
          this.#spherePin.setVisible(true)

          const p0 = this.#nearestCoordAndEdges.edges[0][0]
          const p1 = this.#nearestCoordAndEdges.edges[0][1]
          const transform = this.#edgePin.createTransformByTwoPoints(p0, p1)
          this.#edgePin.update(transform)
          this.#edgePin.setVisible(true)
        }
        else if (magnetPoint && this.#nearestCoordAndEdges.edges.length > 1) {
          this.#edgePin.setVisible(false)
          this.#spherePin.setVisible(false)

          const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, magnetPoint) * DISTANCE_COEFF
          this.#vertexPin.update({
            position: magnetPoint,
            scale: [scale, scale, scale]
          })
          this.#vertexPin.setVisible(true)
        }
      }
      else {
        this.#mouseWorldPos.set(pickResult._worldPos)

        this.#vertexPin.setVisible(false)
        this.#edgePin.setVisible(false)
        this.#spherePin.setVisible(false)
      }

      if (this.#activeMeasurementId) this.#updateActiveMeasurement()
    })
  }
}