import { XeokitMediator } from "../../XeokitMediator"
import { geometry } from "./geometry"

export const nearestCoordFinder = {

  /** Ближайшая точка к WorldPos координате, по WorldPos ребрам Entity
   *
   * @param {Object} edges Ребра Entity
   * @param {Array} point Точка поиска
   * @param {Number} [magnetDistance = Infinity] Максимальный радиус поиска ближайшей точки
   *
   * @returns {Array} Ближайшая точка в формате WorldPos [x, y, z]
   */
  findNearestPointOnEdgeToPoint(edges, point, minDistance = Infinity) {
    let nearestDistance = Infinity
    let nearestPointOnEdge = null
    let nearestEdge = null

    function findNearestPointOnEdge(point, edgeStart, edgeEnd) {
      // Вычисляем вектор от начала отрезка к концу отрезка
      const edgeVector = [edgeEnd[0] - edgeStart[0], edgeEnd[1] - edgeStart[1], edgeEnd[2] - edgeStart[2]]

      // Вычисляем вектор от начала отрезка к заданной точке
      const pointVector = [point[0] - edgeStart[0], point[1] - edgeStart[1], point[2] - edgeStart[2]]

      // Вычисляем проекцию вектора точки на вектор отрезка
      const projection = dotProduct(pointVector, edgeVector) / dotProduct(edgeVector, edgeVector)

      // Если проекция находится за пределами отрезка, то берем ближайшую точку на отрезке
      if (projection < 0) {
        return edgeStart
      } 
      else if (projection > 1) {
        return edgeEnd
      }

      // Иначе находим точку на отрезке, ближайшую к заданной точке
      return [
        edgeStart[0] + projection * edgeVector[0],
        edgeStart[1] + projection * edgeVector[1],
        edgeStart[2] + projection * edgeVector[2],
      ]
    }

    function distanceBetweenPoints(point1, point2) {
      const dx = point2[0] - point1[0]
      const dy = point2[1] - point1[1]
      const dz = point2[2] - point1[2]

      return Math.sqrt(dx * dx + dy * dy + dz * dz)
    }

    function dotProduct(vector1, vector2) {
      //TODO: чем не устроил math.dotVec()?
      let result = 0

      for (let i = 0; i < vector1.length; i++) {
        result += vector1[i] * vector2[i]
      }

      return result
    }

    for (let i = 0; i < edges.length; i++) {
      const edge = edges[i]

      const edgeStart = edge[0]
      const edgeEnd = edge[1]

      const projectedPoint = findNearestPointOnEdge(point, edgeStart, edgeEnd)

      const distance = distanceBetweenPoints(projectedPoint, point)

      if (distance < nearestDistance) {
        nearestDistance = distance
        nearestPointOnEdge = projectedPoint
        nearestEdge = edge
      }
    }

    let nearest = {
      edge: nearestEdge,
      point: nearestPointOnEdge,
    }
    return nearestDistance <= minDistance ? nearest : null
  },

  /** Получить ближайшую координату на грани или вершине элемента  
   * 
   * @param {Object} cfg Настройки поиска
   * @param {Array<Number>} [cfg.canvasPos = [0, 0]] Координаты стартовой точки поиска на Canvas
   * @param {Number} [cfg.nearestDistance = 0.02] Диапазон поиска ближайшей точки ( в сантиметрах )
   * @param {Boolean} [cfg.collisionDetect = false] Искать точки на коллизиях элементов
   * 
   * @returns {Object} Массив WorldPos координаты формата [x, y, z] и Entity
  */
  getNearestCoordOnEdgeOrVertexByCanvasPos(cfg) {
    let pickResult = XeokitMediator.viewer.scene.pick({
      canvasPos: cfg.canvasPos,
      pickSurface: true
    });
    if (!pickResult) return null

    let worldPos = pickResult.worldPos || [0, 0, 0]
    let nearestDistance = cfg.nearestDistance || 0.02
    let collisionDetect = cfg.collisionDetect || false

    let rangeAABB = geometry.aabb.buildAabbByCenterPoint(worldPos, nearestDistance * 2)

    let nearestMeshes = geometry.extraction.getMeshesInAABB(rangeAABB)
    let edgesOfMeshes = geometry.intersection.getEdgesOfMeshesInAABB({
      aabb: rangeAABB,
      meshes: nearestMeshes,
      collisionDetect: collisionDetect
    })

    let nearestCoord = this.findNearestCornerByEdges(worldPos, edgesOfMeshes, nearestDistance);

    if (nearestCoord == null && edgesOfMeshes.length > 0) {
      let nearestEdgeAndPoint = this.findNearestPointOnEdgeToPoint(edgesOfMeshes, worldPos, nearestDistance);
      if (nearestEdgeAndPoint) {
        nearestCoord = nearestEdgeAndPoint.point;
      }
    }
    if (!nearestCoord) return null

    return {
      worldPos: nearestCoord,
      entity: pickResult.entity
    }
  },

  /** Получить ближайшую координату на грани или вершине элемента, и грани на которых лежит координата
   * 
   * @param {Object} cfg Настройки поиска
   * @param {Array<Number>} [cfg.worldPos = [0, 0, 0]] Координаты стартовой точки поиска WorldPos
   * @param {Number} [cfg.nearestDistance = 0.02] Диапазон поиска ближайшей точки ( в сантиметрах )
   * @param {Boolean} [cfg.collisionDetect = false] Искать точки на коллизиях элементов
   * 
   * @returns {{nearestCoord: Array<Number>, edges: Array<Array<Array<Number>>>}} 
   * Массив WorldPos координаты формата [x, y, z], Массив граней в формате [[[x, y, z], [x, y, z]]] 
   * 
  */
  getNearestCoordAndEdgesOnEdgeOrVertexByWorldPos(cfg) {
    let worldPos = cfg.worldPos || [0, 0, 0]
    let nearestDistance = cfg.nearestDistance || 0.02
    let collisionDetect = cfg.collisionDetect || false

    let rangeAABB = geometry.aabb.buildAabbByCenterPoint(worldPos, nearestDistance * 2)

    let nearestMeshes = geometry.extraction.getMeshesInAABB(rangeAABB)
    let edgesOfMeshes = geometry.intersection.getEdgesOfMeshesInAABB({
      aabb: rangeAABB,
      meshes: nearestMeshes,
      collisionDetect: collisionDetect
    })

    let nearestCoord = this.findNearestCornerByEdges(worldPos, edgesOfMeshes, nearestDistance);
    let edges = []

    if (nearestCoord == null && edgesOfMeshes.length > 0) {
      let nearestEdgeAndPoint = this.findNearestPointOnEdgeToPoint(edgesOfMeshes, worldPos, nearestDistance);
      if (nearestEdgeAndPoint) {
        nearestCoord = nearestEdgeAndPoint.point;
        edges = [nearestEdgeAndPoint.edge]
      }
    }
    else if (nearestCoord) {
      edges = this.findEdgesByPoint(nearestCoord, edgesOfMeshes, 0.001)
    }

    return {
      nearestCoord: nearestCoord,
      edges: edges
    }
  },

  findEdgesByPoint(point, segments, epsilon) {
    const matchingSegments = []
    for (const segment of segments) {
      const [start, end] = segment

      if (geometry.utils.arePointsEqual(start, point, epsilon) || geometry.utils.arePointsEqual(end, point, epsilon)) {
        matchingSegments.push(segment)
      } 
      else {
        // Check if the point lies on the segment with tolerance epsilon
        const vector1 = [end[0] - start[0], end[1] - start[1], end[2] - start[2]]
        const vector2 = [point[0] - start[0], point[1] - start[1], point[2] - start[2]]

        const dotProduct1 = vector1[0] * vector2[0] + vector1[1] * vector2[1] + vector1[2] * vector2[2]
        const dotProduct2 = vector1[0] * vector1[0] + vector1[1] * vector1[1] + vector1[2] * vector1[2]

        const t = dotProduct1 / dotProduct2

        if (
          t >= 0 &&
          t <= 1 &&
          geometry.utils.arePointsClose(point, [start[0] + t * vector1[0], start[1] + t * vector1[1], start[2] + t * vector1[2]], epsilon)
        ) {
          // Point lies on the segment
          // Divide the segment into two based on the given point
          const midPoint = [start[0] + t * vector1[0], start[1] + t * vector1[1], start[2] + t * vector1[2]]

          const segment1 = [start, midPoint]
          const segment2 = [midPoint, end]

          matchingSegments.push(segment1)
          matchingSegments.push(segment2)
        }
      }
    }

    return matchingSegments
  },

  /** Ближайший угол к pickResult, по ребрам Entity
   *
   * @param {Object} pickResult Точка поиска
   * @param {Object} entityEdges Ребра Entity
   * @param {Number} [magnetDistance = Infinity] Максимальный радиус поиска ближайшей точки
   *
   * @returns {Array} Ближайший угол в формате WorldPos [x, y, z]
   */
  findNearestCornerByEdges(worldPos, entityEdges, magnetDistance = Infinity) {
    let nearestCord = null
    let minDistance = Infinity
    for (const edge of entityEdges) {
      for (const coord of edge) {
        // this.drawPoint( coord )
        const distance = Math.abs(
          Math.sqrt(Math.pow(worldPos[0] - coord[0], 2) + Math.pow(worldPos[1] - coord[1], 2) + Math.pow(worldPos[2] - coord[2], 2))
        )
        if (distance < minDistance && distance < magnetDistance) {
          minDistance = distance
          nearestCord = coord
        }
      }
    }

    if (nearestCord) {
      if (magnetDistance < minDistance) nearestCord = null
    }

    // Уменьшение радиуса поиска при приближении
    // if (nearestCord) {
    //   let fieldOfView = 180
    //   if (((this.distanceApproachPointFromCamera(nearestCord, viewer) * Math.tan(fieldOfView / 2 * (Math.PI / 180))) / Math.pow(10, 17)) / 2 < minDistance)
    //     nearestCord = null
    // }

    return nearestCord
  },

  distanceApproachPointFromCamera(coords, viewer) {
    return this.distanceBetweenByCoordinates(viewer.camera._look, coords) //TODO: Почему look, когда должно быть eye?
  },

  isAngleBetweenTwoPlanesLessThanValue(p1Normal, p2Normal, destAngle) {
    let d = geometry.math.dotVec3(p1Normal, p2Normal)
    let e1 = geometry.math.lenVec3(p1Normal)
    let e2 = geometry.math.lenVec3(p2Normal)
    d = parseFloat(d / (e1 * e2));

    let angle = (180 / Math.PI) * Math.acos(d);

    return angle < destAngle || angle > (180 - destAngle)
  }
}