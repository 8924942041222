<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 24.00 24.00"
  >
    <path
      fill="currentColor"
      d="
        M 8.60 14.34
        L 4.45 18.53
        Q 4.06 18.92 3.64 18.56
        L 2.99 18.02
        Q 2.54 17.64 2.96 17.22
        L 7.32 12.97
        Q 7.65 12.64 7.39 12.26
        L 5.10 8.90
        Q 4.61 8.19 5.48 8.15
        L 6.07 8.12
        Q 6.63 8.10 6.94 8.56
        L 8.61 11.00
        Q 9.03 11.63 9.43 10.98
        L 12.03 6.67
        Q 12.46 5.96 11.64 5.96
        L 2.03 5.98
        A 0.96 0.96 0.0 0 1 1.18 5.47
        Q 0.58 4.30 2.08 4.20
        Q 4.55 4.04 7.04 3.93
        Q 7.66 3.91 7.98 3.36
        L 8.64 2.22
        A 0.41 0.41 0.0 0 1 9.36 2.25
        L 9.97 3.48
        Q 10.22 3.99 10.80 4.00
        L 16.34 4.02
        A 0.61 0.60 -1.5 0 1 16.95 4.59
        L 16.99 5.19
        Q 17.03 5.92 16.31 5.95
        L 14.54 6.02
        Q 13.96 6.05 13.78 6.59
        Q 12.75 9.68 10.63 12.15
        Q 10.31 12.53 10.65 12.90
        Q 11.24 13.55 11.83 14.10
        Q 12.59 14.81 12.68 15.29
        Q 12.79 15.90 12.36 16.43
        A 0.59 0.58 -47.8 0 1 11.48 16.48
        L 9.41 14.34
        A 0.57 0.56 -44.7 0 0 8.60 14.34
        Z"
    />
    <path
      fill="currentColor"
      d="
  M 14.91 19.43
  L 14.17 21.51
  A 0.72 0.72 0.0 0 1 13.49 21.99
  L 12.78 21.99
  Q 12.03 22.00 12.29 21.29
  L 16.25 10.76
  A 1.34 1.33 -45.1 0 1 18.75 10.75
  L 22.80 21.52
  Q 22.97 21.97 22.49 21.98
  L 21.53 22.00
  A 0.72 0.71 -10.1 0 1 20.84 21.52
  L 20.10 19.42
  Q 19.95 18.99 19.49 18.99
  L 15.52 19.00
  Q 15.06 19.00 14.91 19.43
  Z
  M 15.99 16.60
  A 0.30 0.30 0.0 0 0 16.27 17.01
  L 18.74 17.00
  A 0.30 0.30 0.0 0 0 19.02 16.59
  L 17.77 13.40
  A 0.30 0.30 0.0 0 0 17.21 13.40
  L 15.99 16.60
  Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>