<template lang="pug">
.app-section( :class="{ 'app-section-rounded' : rounded }")
  slot
</template>

<script>
export default {
  name: "AppSection",
  props:{
    rounded: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

.app-section {
  background-color: #fff;
  padding: 8px;
}

.app-section-rounded {
  border-radius: 5px;
}
</style>
