import { defineStore } from 'pinia'

export const useImageSectionPlanesStore = defineStore('image-section-planes-store', {
  state: () => {
    return {
      imageSrc: "",
      isEditMode: false,
      editImageSectionPlaneId: '',
      editImageSectionPlaneTitle: 'untitled',
      isAnchorPointsPicking: false,

      imageSectionPlanes: {},

      imageSectionPlanesShort: []
    }
  },
})