<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 4V7.5M16 24.5V28M4 16H7.5M24.5 16H28" stroke="#303030"/>
    <circle cx="16" cy="16" r="8.5" stroke="#303030"/>
    <circle cx="16" cy="16" r="2.5" fill="#FF0000" stroke="#FF0000"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>