export class DevOptions {
  constructor (config = {}) {
    this.options = config.dev ?? {}
  }

  get isProcessesEnabled () {
    return this.options.autoUpdateProcesses
  }

  get isVisibleRevisionCloudLink () {
    return this.options.showRevisionCloudLink ?? false
  }

  get isShowCloudConverting () {
    return this.options.showCloudConverting ?? false
  }

  get isShowLogo () {
    return this.options.showLogo ?? true
  }
}