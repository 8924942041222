<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 13C5.1 13 6 12.1 6 11C6 9.9 5.1 9 4 9C2.9 9 2 9.9 2 11C2 12.1 2.9 13 4 13ZM5.41 14.02C5.04 13.96 4.67 13.92 4.28 13.92C3.29 13.92 2.35 14.13 1.5 14.5C0.76 14.82 0 17.62 0 18.43V20H4.5V18.39C4.5 17.56 5.01 14.7 5.41 14.02ZM20 13C21.1 13 22 12.1 22 11C22 9.9 21.1 9 20 9C18.9 9 18 9.9 18 11C18 12.1 18.9 13 20 13ZM24 18.43C24 17.62 23.24 14.9 22.5 14.58C21.65 14.21 20.71 14 19.72 14C19.33 14 18.96 14.04 18.59 14.1C18.99 14.78 19.5 17.56 19.5 18.39V20H24V18.43ZM16.24 11.84C15.07 11.32 13.63 10.94 12 10.94C10.37 10.94 8.93 11.33 7.76 11.84C6.68 12.32 6 17.21 6 18.39V20H18V18.39C18 17.21 17.32 12.32 16.24 11.84ZM8.07 18C8.16 17.77 8.2 13.8 8.98 13.5C9.95 13.12 10.97 12.94 12 12.94C13.03 12.94 14.05 13.12 15.02 13.5C15.79 13.8 15.83 17.77 15.93 18H8.07ZM12 6C12.55 6 13 6.45 13 7C13 7.55 12.55 8 12 8C11.45 8 11 7.55 11 7C11 6.45 11.45 6 12 6ZM12 4C10.34 4 9 5.34 9 7C9 8.66 10.34 10 12 10C13.66 10 15 8.66 15 7C15 5.34 13.66 4 12 4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>