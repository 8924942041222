import { Model } from '@vuex-orm/core'

export default class WormVersionNode extends Model {
  static entity = 'WormVersionNode'

  static primaryKey = 'uuid'

  static state ()  {
    return {
      fetching: false
    }
  }

  static fields () {
    return {
      uuid: this.attr(null),
      wormVersionUuid: this.attr(null).nullable(),
      parentUuid: this.attr(null).nullable(),
      wormNodeUuid: this.attr(null).nullable(),
      wormNodePressmark: this.string(null).nullable(),
      wormNodeTitle: this.string(null).nullable(),
      wormNodeType: this.attr(null).nullable(),
      wormNodeClassificatorFindRuleUuid: this.attr(null).nullable(),
      wormNodeClassificatorFindRuleTitle: this.attr(null).nullable(),
      wormNodeClassificatorNodeUuid: this.attr(null).nullable(),
      wormNodeClassificatorNodePressmark: this.attr(null).nullable(),
      wormNodeClassificatorNodeTitle: this.attr(null).nullable(),
      classificatorUnitValue: this.attr(null).nullable(),
      classificatorUnitType: this.attr(null).nullable(),
      cnt: this.attr(null).nullable(),
      value: this.attr(null).nullable()
    }
  }

  static apiConfig = {
    
  }

  get children(){
    return WormVersionNode.query().where('parentUuid', this.uuid).orderBy(el => el.wormNodeType.value).orderBy('uuid').get()
  }
}