import { telemetryService } from "@/_services/telemetry.service"
import { api } from '@/api'

const getDefaultState = () => ({
  receivers: [],
  receiversMap: new Map(),

  receiversWithChannels: [],
  selectedReceiver: null,
  selectedReceiverChannels: [],

  channelsSearchText: '',
  channelsMap: new Map(),
  converterList: [],
  receiverClassList: [],
  roundTypeList: [],
  convertTypeList: [],
})

export default {
  namespaced: 'true',

  state: getDefaultState(),

  actions: {
    getReceiversList(state, projectUuid) {
      return telemetryService.getReceivers(projectUuid).then(({data}) => data.map(receiver => (receiver.channels = [], receiver)))
    },

    async loadReceivers({ rootGetters, commit, state, dispatch }) {
      const projectUuid = rootGetters['project/projectUuid']
      const { data: receivers } = await telemetryService.getReceivers(projectUuid)
      
      receivers.forEach(receiver => {
        receiver.channels = state.receivers.find(rec => rec.uuid === receiver.uuid)?.channels || []
      })

      commit('SET_RECEIVER_LIST', receivers)
      
      const selectedReceiver = receivers.find(receiver => receiver.uuid === state.selectedReceiver?.uuid)
      dispatch('setSelectedReceiver', selectedReceiver || null)
    },

    loadReceiversWithChannels({ rootGetters, commit }) {
      const projectUuid = rootGetters['project/projectUuid']
      return telemetryService.getReceiversWithChannels(projectUuid).then(({ data }) => {
        commit('SET_RECEIVERWITHCHANNELS_LIST', data)
      })
    },

    saveReceiver({ rootGetters, state, dispatch }, receiver) {
      const isNewReceiver = !receiver.uuid

      const projectUuid = rootGetters['project/projectUuid']

      return api.projects.getTokenProject(projectUuid).then(data=> {
        receiver.projectToken = data.token

        return telemetryService.createReceiver(receiver).then(async ({data}) => {
          // await dispatch('loadReceivers')
          if (isNewReceiver) {
            data.channels = []
            state.receivers.push(data)
          }

          const createdReceiverIdx = state.receivers.findIndex(sReceiver => sReceiver.uuid === data.uuid)
          
          if (createdReceiverIdx >= 0) {
            const receiver = {
              ...data,
              isNew: isNewReceiver
            }
            state.receivers.splice(createdReceiverIdx, 1, receiver)
            dispatch("setSelectedReceiver", receiver)
          }
        })
      })
    },

    getReceiverChannels(state, receiver) {
      return telemetryService.getChannels(receiver.uuid).then(response => response.data)
    },

    deleteReceiver({ dispatch }, receiver) {
      return telemetryService.deleteReceiver(receiver.uuid).then(() => {
        dispatch('loadReceivers')
      })
    },

    async setSelectedReceiver({ commit, dispatch }, receiver) {
      commit('SET_SELECTED_RECEIVER', receiver)
      dispatch('channel/table/setReceiver', receiver, { root: true })
    },

    async updateSelectedReceiverChannelList({ commit, state }) {
      state.channelsMap.clear()
      
      if (!state.selectedReceiver?.uuid) {
        commit('SET_SELECTED_RECEIVER_CHANNELS', [])
        return
      }

      const receiverChannels = await telemetryService.getChannels(state.selectedReceiver.uuid).then(data => data.data)
      receiverChannels.forEach(channel => {
        channel.recvTime = channel.data = channel.dataChanging = null

        const existedChannel = state.channelsMap.get(channel.uuid)
        if (existedChannel) {
          channel.recvTime = existedChannel.recvTime
          channel.data = existedChannel.data
        }

        state.channelsMap.set(channel.uuid, channel)
      })

      commit('SET_SELECTED_RECEIVER_CHANNELS', receiverChannels)
    },

    createChannel({ dispatch }, channel) {
      return telemetryService.createChannel(channel).then(() => {
        dispatch('updateSelectedReceiverChannelList')
      })
    },

    deleteChannel({ dispatch }, channel) {
      return telemetryService.deleteChannel(channel.uuid).then(() => {
        dispatch('updateSelectedReceiverChannelList')
        dispatch('channel/table/deleteChannel', channel.uuid, { root: true })
      })
    },

    createChannelData({ dispatch }, TData) {
      return telemetryService.createChannelData(TData).then(() => {
        dispatch('updateSelectedReceiverChannelList')
      })
    },

    initReceiverClassList({ state, commit }) {
      if (state.receiverClassList.length === 0){
        telemetryService.getReceiverClassList().then(data => {
          commit('SET_RECEIVER_CLASS_LIST', data.data)
        })
      }
    },

    initConverterList({ state, commit }) {
      if (state.converterList.length === 0){
        telemetryService.getConverterList().then((converterList) => {
          commit('SET_CONVERTER_LIST', converterList)
        })
      }
    },

    initRoundTypeList({ state, commit }) {
      if (state.roundTypeList.length === 0){
        telemetryService.getRoundTypeList().then((rondTypeList) => {
          commit('SET_ROUND_TYPE_LIST', rondTypeList)
        })
      }
    },

    initConvertTypeList({ state, commit }) {
      if (state.convertTypeList.length === 0){
        telemetryService.getConvertTypeList().then((convertTypeList) => {
          console.log(convertTypeList)
          commit('SET_CONVERT_TYPE_LIST', convertTypeList)
        })
      }
    },



    resetState({ commit }) {
      commit('RESET_STATE')
    },
  },

  mutations: {
    SET_RECEIVER_LIST(state, receivers) {
      state.receivers = receivers
      state.receivers.forEach(receiver => {
        state.receiversMap.set(receiver.uuid, receiver)
      })
    },

    SET_RECEIVERWITHCHANNELS_LIST(state, receivers) {
      state.receiversWithChannels = receivers
    },

    SET_SELECTED_RECEIVER(state, receiver) {
      state.selectedReceiver = receiver
    },

    SET_SELECTED_RECEIVER_CHANNELS(state, channels) {
      state.selectedReceiverChannels = channels
    },

    SET_CHANNELS_SEARCH_TEXT(state, searchText) {
      state.channelsSearchText = searchText
    },

    SET_RECEIVER_CLASS_LIST(state, list) {
      state.receiverClassList = list
    },

    SET_ROUND_TYPE_LIST(state, list) {
      state.roundTypeList = list
    },

    SET_CONVERT_TYPE_LIST(state, list) {
      state.convertTypeList = list
    },
    
    SET_CONVERTER_LIST(state, converterList) {
      state.converterList = converterList
    },

    RESET_STATE(state) {
      const receiverClassList = state.receiverClassList
      const converterList = state.converterList
      const roundTypeList = state.roundTypeList
      Object.assign(state, getDefaultState())
      state.receiverClassList = receiverClassList
      state.converterList = converterList
      state.roundTypeList = roundTypeList  
    },
  },

  getters: {
    searchValidChannelList: ({channelsSearchText, selectedReceiverChannels}) => selectedReceiverChannels.filter(channel => channel.title.toLowerCase().includes(channelsSearchText.toLowerCase()))
  }
}