// import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode";
import { EmphasisMaterial, Mesh, PhongMaterial, VBOGeometry, math } from "@xeokit/xeokit-sdk";

const NO_COLOR = [0, 0, 0]
export class VertexPin {

  #scene; #pin;
  #position; #scale; #quaternion; #matrix; #origin;
  #visible; #color;

  constructor(scene, cfg = {}) {
    this.#scene = scene

    this.#pin = null
    this.#position = cfg.position ?? [0, 0, 0]
    this.#scale = cfg.scale ?? [1, 1, 1]
    this.#quaternion = [0, 0, 0, 1]
    this.#origin = cfg.origin ?? [0, 0, 0]

    this.#visible = cfg.visible ?? true
    this.#color = cfg.color ?? [1.0, 0.0, 1.0]

    this.#create()
  }

  #create() {
    const geometry = new VBOGeometry(this.#scene, {
      primitive: 'lines',
      positions: [
        0, -0.8, 0, 0, 0.8, 0, // y axis
        -0.8, 0, 0, 0.8, 0, 0, // x axis
        0, 0, -0.8, 0, 0, 0.8  // z axis
      ],
      indices: [0, 1, 2, 3, 4, 5]
    })

    const color = [this.#color[0] * 255, this.#color[1] * 255, this.#color[2] * 255]
    const material = new PhongMaterial(this.#scene, {
      emissive: color,
      diffuse: NO_COLOR,
      ambient: NO_COLOR,
    })

    const selectedMaterial = new EmphasisMaterial(this.#scene, {
      fill: false,
      edges: true,
      edgeColor: color,
      edgeAlpha: 1,
    })

    this.#pin = new Mesh(this.#scene, {
      geometry: geometry,
      material: material,
      selected: true,
      selectedMaterial: selectedMaterial,
      visible: this.#visible,
      origin: this.#origin
    })
  }

  setVisible(visible) {
    this.#visible = !!visible
    this.#pin.visible = this.#visible
  }

  update(transform) {
    const origin = transform.position ?? this.#origin
    const position = [0, 0, 0]
    const scale = transform.scale ?? this.#scale
    const quaternion = this.#quaternion

    this.#origin = origin
    this.#position = position
    this.#scale = scale

    this.#matrix = math.composeMat4(position, quaternion, scale)
    this.#pin.matrix = this.#matrix
    this.#pin.origin = this.#origin
  }

  destroy() {
    this.#pin?.destroy()
    this.#pin = null
  }
}