import { SelectElements } from "./tools/selectElements.js";
import { SelectionFrame } from "./tools/selectionFrame/selectionFrame.js";
import { DistanceMeasurement } from "./tools/distanceMeasurement/DistanceMeasurement.js";
import { BimLights } from "./tools/lights/bimLights.js";
import { RegimeMeasurement } from "./tools/regimeMeasurement/regimeMeasurement.js";

/*eslint-disable no-dupe-class-members*/
/**
 * ViewerToolsFacade (VTF) - Решения к Xeokit
 */
export class VTF {

  /**
   * Выделение элементов модели
  */
  static get SelectElements() {
    return SelectElements;
  }

  /**
   * Выделение элементов рамкой
   */
  static get SelectionFrame() {
    return SelectionFrame;
  }

  /**
   * Линейка
   */
  static get DistanceMeasurement() {
    return DistanceMeasurement;
  }

  /**
   * Режимные линейки
   */
  static get RegimeMeasurement() {
    return RegimeMeasurement
  }

  /**
   * Освещение сцены
   */
  static get BimLights() {
    return BimLights
  }
  
}