import { ShapeGeometry } from "@/plugins/threeJs/fork/src/geometries/shapeGeometry"
import { VBOGeometry, PhongMaterial, Mesh } from "@xeokit/xeokit-sdk"

const STATE_INHERIT = true

export class GridAxisLabel {
  constructor(owner, cfg = {}) {
    this.owner = owner

    this.font = cfg.font ?? null
    this.text = cfg.text ?? '<>'
    this.position = cfg.position ?? [0, 0, 0]
    this.scale = cfg.scale ?? 1
    this.color = cfg.color ?? [1, 1, 1]
    this.mesh = null

    this.#create()
  }

  #create() {
    const shapes = this.font.generateShapes(this.text, this.scale)
    const shapeGeometry = new ShapeGeometry(shapes, 1) 
    
    const normals = Array.from(shapeGeometry.attributes.normal.array)
    const positions = Array.from(shapeGeometry.attributes.position.array)
    const indices = Array.from(shapeGeometry.index.array)

    const xktGeometry = {
      primitive: "triangles",
      positions: positions,
      indices: indices,
      normals: normals,
      compressGeometry: true,
    }
    
    const labelGeometry = new VBOGeometry(this.owner, xktGeometry)

    const color = [this.color[0] / 2, this.color[1] / 2, this.color[2] / 2]

    const material = new PhongMaterial(this.owner, {
      emissive: color,
      diffuse: color,
      ambient: [0, 0, 0],
      specular: [0, 0, 0],
      alpha: 1
    })

    this.mesh = new Mesh(this.owner, {
      geometry: labelGeometry,
      material: material,
      position: [0, 0, 0],
      origin: this.position,
      clippable: false,
      billboard: 'spherical'
    })
    
    this.owner.addChild(this.mesh, STATE_INHERIT)
  }
}