import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import vuexStore from "@/store";
import _ from "lodash";

export function getFilteredSceneAABB(scene) {

  // Фильтр подозрительных элементов при расчете aabb для подлета камеры. Нужно ввести, когда подозрительные элементы станут корректными.
  let primaryIds = _.difference(scene.visibleObjectIds, scene.xrayedObjectIds)
  let suspectElements = vuexStore.state.project.suspectElements
  let suspectElementIds = []
  
  suspectElements?.forEach(revision => {
    revision.elements.forEach(element => {
      suspectElementIds.push(element.globalId)
    })
  })
  
  // Фильтр сломанных
  let objIds = scene.visibleObjectIds
  let brokenObjectIds = []
  objIds.forEach(id => {
    let obj = scene.objects[id]
    if (obj) {
      if (obj.aabb[3] === obj.aabb[0] && obj.aabb[4] === obj.aabb[1] && obj.aabb[5] === obj.aabb[2]) {
        brokenObjectIds.push(obj.id)
      }
    }
  })
  let secondaryIds = _.difference(primaryIds, brokenObjectIds)
  let ids = _.difference(secondaryIds, suspectElementIds)
  //let ids = _.difference(this.#_viewer.scene.visibleObjectIds, this.#_viewer.scene.xrayedObjectIds)
  
  let aabb = ids.length > 0 ? scene.getAABB(ids) : [0, 0, 0, 0, 0, 0] // scene.aabb;

  
  if (XeokitMediator.SectionCube.active) {
    if (!XeokitMediator.ElementsSelection.isShowOnlySelectedElements && !XeokitMediator.ElementsSelection.isHideSelectedElements) {
      aabb = XeokitMediator.SectionCube.aabb
    }
  }
  else { // Обрезка aabb плоскостями сечения
    Object.keys(scene.sectionPlanes).forEach(sectionPlaneKey => {
      if (scene.sectionPlanes[sectionPlaneKey].active) {
        let section = scene.sectionPlanes[sectionPlaneKey]
        if (geometry.intersection.isPlaneIntersectAABB(section, aabb)) {
          let aabbEdges = geometry.aabb.getAabbEdges(aabb)
          let aabbVertices = geometry.aabb.getAabbVertices(aabb)
          let newAabbVertices = []
          for (let i = 0; i < aabbEdges.length; i++) {
            let intersectionObject = geometry.intersection.findLineSegmentPlaneIntersectionPoint(aabbEdges[i], section)
            if (geometry.intersection.isLineIntersectsPlane(aabbEdges[i], section.dir) && intersectionObject.areIntersected) {
              newAabbVertices.push(intersectionObject.intersectionPoint)
            }
          }
          newAabbVertices.push(...geometry.intersection.findNonCutOffAabbVertices(aabbVertices, section))
          aabb = geometry.aabb.buildAabbByPointsArray(newAabbVertices)
        }
      }
    })
  }

  return aabb
}