import { api } from "@/api"
import  i18n  from '@/plugins/i18n'
// import { get } from "lodash";


function replacePairExceptions(exceptionRule, condition, aFlag){
  if(aFlag)
    exceptionRule['exceptionA'] = condition
  else
    exceptionRule['exceptionB'] = condition
  return exceptionRule;
}

export default {
  namespaced: true,

  state: {
    rule: null,
    finders: [],
  },

  getters: {
    rule: ({ rule }) => {
      if(rule && rule.finder){
        let title = rule.finder.className.replace('com.pirsbim','section')
        rule.finder.title = i18n.t(title)
      }
      return rule
    },
    
    finders: ({ finders }) => finders.map(finder=>{
      let title = finder.className.replace('com.pirsbim','section')
      finder.title = i18n.t(title)
      return finder
    }),
  },

  mutations: {
    SET_RULE: (state, rule) => { state.rule = rule },
    SET_FINDERS: (state, list) => { state.finders = list || [] },
    SET_SAMPLE_A: (state, sample) => { state.rule.sampleA = sample },
    SET_SAMPLE_B_LIST: (state, list) => { state.rule.sampleB = list },
    SET_SAMPLE_EXCEPTIONS: (state, list) => {state.rule.exceptions = list},
    SET_SAMPLE_A_VIEW: (state, list) => {state.rule.sampleA = list},
  },

  actions: {
    init ({ dispatch }) {
      dispatch('loadFinders')
    },

    loadFinders ({ commit }) {
      api.collisionRules.ruleFinderEnum()
      .then(data => {
        commit('SET_FINDERS', data)
      })
    },

    selectRule({ commit }, rule) {
      if (rule) {
        return api.collisionRules.ruleDetails(rule.uuid)
        .then(data => {
          commit('SET_RULE', data)
        })  
      }
      else {
        commit('SET_RULE', null)
      }
    },

    saveRuleDetails ({ commit }, rule) {
      return api.collisionRules.saveRuleDetails(rule)
      .then(data => {
        commit('SET_RULE', data)
        return true
      })
    },
    
    replaceSampleA ({ commit }, sample) {
      commit('SET_SAMPLE_A', sample)
    },

    updateExceptionsList({commit, getters}, {exceptionRule, sample, edit, fl}){
      edit
      if(fl){
      if(sample.edit && sample.uuid){
        let list = getters.rule.exceptions.map(i => i.exceptionA && i.exceptionA.uuid == sample.uuid ? replacePairExceptions(i, sample, true) : i)
        commit('SET_SAMPLE_EXCEPTIONS', list)
      }
      else if(sample.edit && sample.deleteUuid){
        let list = getters.rule.exceptions.map(i => i.exceptionA.deleteUuid == sample.deleteUuid ? replacePairExceptions(i, sample, true) : i)
        commit('SET_SAMPLE_EXCEPTIONS', list)
      }
      else {
        let list = [...getters.rule.exceptions, replacePairExceptions(exceptionRule, sample, true)]
        commit('SET_SAMPLE_EXCEPTIONS', list)
      }
    }
    else if(!fl){
      if(sample.edit && sample.uuid){
        let list = getters.rule.exceptions.map(i => i.uuid == exceptionRule.uuid ? replacePairExceptions(exceptionRule, sample, false) : i)
        commit('SET_SAMPLE_EXCEPTIONS', list)
      }
      else if(sample.edit && sample.deleteUuid){
        let list = getters.rule.exceptions.map(i => i.deleteUuid == exceptionRule.deleteUuid ? replacePairExceptions(exceptionRule, sample, false) : i)
        commit('SET_SAMPLE_EXCEPTIONS', list)
      }
      else {
        let list = getters.rule.exceptions.map(i => i.uuid == exceptionRule.uuid ? replacePairExceptions(exceptionRule, sample, false) : i)
        commit('SET_SAMPLE_EXCEPTIONS', list)
      }
    }
    },

    updateSampleBList ({ commit, getters }, {sample, edit}) {
      edit
      if (sample.edit && sample.uuid) {
        let list = getters.rule.sampleB.map(i => i.uuid == sample.uuid ? sample : i)
        commit('SET_SAMPLE_B_LIST', list)
      }
      else if (sample.edit && sample.deleteUuid) {
        let list = getters.rule.sampleB.map(i => i.deleteUuid == sample.deleteUuid ? sample : i)
        commit('SET_SAMPLE_B_LIST', list)
      }
      else {
        let list = [...getters.rule.sampleB, sample]
        commit('SET_SAMPLE_B_LIST', list)
      }
    },

    removeSampleExceptions({commit, getters, dispatch}, exceptionRule){
      dispatch('removeExceptionRule', exceptionRule.uuid)
      let list = getters.rule.exceptions.filter(i => i.uuid !== exceptionRule.uuid)
      commit('SET_SAMPLE_EXCEPTIONS', list)
    },

    viewButton({commit, getters}){
      let list = getters.rule.sampleA.uuid
      commit('SET_SAMPLE_A_VIEW', list)
    },

    removeSampleB ({ commit, getters, dispatch }, sample) {
      if (sample.uuid) {
        dispatch('removeRuleCondition', sample.uuid)
        let list = getters.rule.sampleB.filter(i => i.uuid !== sample.uuid)
        commit('SET_SAMPLE_B_LIST', list)
      } else if (sample.deleteUuid) {
        let list = getters.rule.sampleB.filter(i => i.deleteUuid !==sample.deleteUuid)
        commit('SET_SAMPLE_B_LIST', list)
      }
    },

    removeSampleA ({ commit }, rule) {
      api.collisionRules.removeSampleA(rule.uuid)
      commit('SET_SAMPLE_A', null)
    },

    clearRule ({ commit }, rule) {
      api.collisionRules.clearRule(rule.uuid)
      commit('SET_SAMPLE_A', null)
      commit('SET_SAMPLE_B_LIST', [])
      commit('SET_SAMPLE_EXCEPTIONS', [])
    },

    removeRuleCondition ({ commit }, ruleConditionUuid) {
      commit
      return api.collisionRules.removeRuleCondition(ruleConditionUuid)
    },

    removeExceptionRule ({ commit }, ruleConditionUuid) {
      commit
      return api.collisionRules.removeExceptionRuleCondition(ruleConditionUuid)
    },

    getRule({ commit }, rule) {
      commit
      return api.collisionRules.ruleDetails(rule.uuid).then(data => {
        return data
      })
    },
  }
}