<template lang="pug">
.app-section-header-tabs( :class="{ 'app-section-header-tabs_light-header' : light }" )
  v-row( no-gutters ).py-2
    v-col.mx-2.pointer( v-for="item in items" :key="item.value" ripple @click="handleSelect(item)" test-id="section-header-tab" ) 
      .text-center( :class="{ 'selected': item.value === value }" ) {{ $t(item.title) }}
</template>

<script>
export default {
  props: {
    value: [Number, String],
    items: { type: Array, required: true },
    light: { type: Boolean, default: false }
  },

  methods: {
    handleSelect (item) {
      let { action, value } = item
      if (action) action(value)

      this.$emit('input', value)
      this.$emit('select', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-section-header-tabs {
  display: flex;
  font: normal 12px/16px $roboto;
  background-color: var(--v-surface-lighten1);
  color: var(--v-primary-darken2);
}

.pointer:hover {
  font-weight: 500;
}

.selected {
  border-bottom: 2px solid var(--v-primary-darken2);
}

.app-section-header-tabs_light-header {
  background-color: white;
  color: var(--v-primary-darken1)
}
</style>