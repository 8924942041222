export default ({ Factory, Presets }) => ({
  // POST .../cloud/files/search (Body: CloudSearch) -> получение списка файлов и директорий пользовательского облака
  // POST .../cloud/files/download (Body: fileSize, CloudSearch) -> инициализация процесса загрузки файла из облака на сервер
  // CloudSearch: {String username, String passwd, String path, String cloudUrl, String cloudProvider,UUID modelUuid, String fileHash}

  /**
   * получение списка файлов и директорий пользовательского облака
   * @param {Object} obj Данные для nectCloud {String username, String passwd, String path, String cloudUrl, String cloudProvider}
   */
   search: (obj) => Factory.cloud.files.search.post(obj, Presets.GUEST),

   /**
   * инициализация процесса загрузки файла из облака на сервер
   * @param {Object} obj Данные для nectCloud {String username, String passwd, String path, String cloudUrl, String cloudProvider,UUID modelUuid, String fileHash}
   */

  /**
   * инициализация процесса скачивания файла 
   * @param {Object} obj 
   */
  download: (obj) => Factory.cloud.files.download[obj.fileSize].post(obj.filter),

  simpleDownload: (obj) => Factory.cloud.files.download.post(obj, { responseType: "blob" }),

  /**
   * инициализация процесса создания папки 
   * @param {Object} obj 
   */
  createFolder: (obj) => Factory.cloud.folder.post(obj),

  /**
   * инициализация процесса удаления файла
   * @param {Object} obj 
   */
  deleteItem: (obj) => Factory.cloud.files.del.post(obj),

  /**
   * инициализация процесса загрузки файла 
   * @param {Object} obj 
   */
  upload: (obj) => Factory.cloud.files.put(obj),

  /**
   * инициализация процесса загрузки файла из GoogleDrive на сервер
   * @param {Object} obj Данные для googleDrive { String fileHash, String accessToken, String downloadUrl, String filePath, UUID modelUuid}
   */

  downloadGoogle: (obj) => Factory.cloudgoogle.files.download[obj.fileSize].post(obj.filter),
})