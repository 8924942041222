import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"

let visConfigurator = function (el, opacity, color = null) {
  let scene = XeokitMediator.viewer?.scene
  if(color && scene)
    XeokitMediator.ElementsSettings.setElementsColorized(el, color)
  if(scene)
    XeokitMediator.ElementsSettings.setElementsOpacity(el, opacity)
}

const getDefaultState = () => {
  return {
    inJob: [],
    ready: [],
    new: [],
    lateness: [],
    expandElementDetails: false,
  }
}
const state = getDefaultState()

export default {

  namespaced: true,

  state,

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setElementsInJobState: (state, els) => {
      state.inJob = els
    },
    setElementsReadyState: (state, els) => {
      state.ready = els
    },
    setElementsNewState: (state, els) => {
      state.new = els
    },
    setElementsLatenessState: (state, els) => {
      state.lateness = els
    },
    setExpandElementDetails: (state, val) => {
      state.expandElementDetails = val
    }

  },
  
  actions: {
    setElementsInJobState: ({ state, commit }, els) => {
      commit('setElementsInJobState', els)
      visConfigurator(state.inJob, 0.8, [0.33, 0.33, 0.33])
    },
    setElementsReadyState: ({ state, commit }, els) => {
      commit('setElementsReadyState', els)
      visConfigurator(state.ready, 1)
    },
    setElementsNewState: ({ state, commit }, els) => {
      commit('setElementsNewState', els)
      visConfigurator(state.new, 0.3, [1, 1, 1])
    },

    setElementsLatenessState: ({ state, commit }, els) => {
      commit('setElementsLatenessState', els)
      visConfigurator(state.lateness, 0.5, [1, 0, 0])
    },  

    projectSkeletonization: () => {
      // TODO Переделать под новое XKT API
      const scene = XeokitMediator.viewer.scene
      const visibleObjects = scene.objectIds.filter(objId => scene.objects[objId].opacity > 0)
      
      visConfigurator(visibleObjects, 0.3)
    },

    dropSmetaHighlights({ state }, out) {
      console.log(out)
      let els = []

      els = els.concat(state.inJob)
      els = els.concat(state.new)
      els = els.concat(state.ready)
      els = els.concat(state.lateness)

      visConfigurator(els, 0.3)
      XeokitMediator.clearColorizeModel()
    },

    dropColorized() {
      if (XeokitMediator.viewer?.scene) XeokitMediator.clearColorizeModel()
    },

    dropAllSmetaHighlights() {
      if (!XeokitMediator.viewer || !XeokitMediator.viewer.scene) return 

      const step = 20000
      let start = 0
      let end = step
      let objects = XeokitMediator.viewer.scene.objectIds
      
      const macroTasks = () => {
        visConfigurator(objects.slice(start, end), 1)
        start += step
        end += step
        if(end < objects.length) {
          setTimeout(macroTasks)
        }
        else  {
          setTimeout(() => {
            visConfigurator(objects.slice(start, end), 1)
          })
        }
      }

      macroTasks()

      XeokitMediator.Models.restoreModels()
    },
  }
}
