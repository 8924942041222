import { Model } from '@vuex-orm/core'

export default class WormNodeType extends Model {
  static entity = 'WormNodeType'

  static primaryKey = 'name'

  static fields () {
    return {
      value: this.number(0),
      name: this.string(''),
      title: this.string('')
    }
  }

  static apiConfig = {
    actions: {
      fetch: {
        method: 'get',
        url: '/worm/types'
      }
    }
  }
}