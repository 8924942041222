import { Model } from '@vuex-orm/core'

export default class ElementRuleConditionValid extends Model {
  static entity = 'ElementRuleConditionValid'

  static primaryKey = ['ruleUuid', 'elementUuid']

  static fields () {
    return {
      ruleUuid: this.attr(null),
      elementUuid: this.attr(null),
      conditionsValid: this.attr(null)
    }
  }

  static apiConfig = {
    actions: {
      check(elementUuid,ruleUuid,nodeUuid, projectClassUuid) {
        let rq = `?elementUuid=${elementUuid}&ruleUuid=${ruleUuid}&nodeUuid=${nodeUuid}&projectClassUuid=${projectClassUuid}`
        return this.get(`/smeta/rule/checkelement${rq}`)
      }
    }
  }
}