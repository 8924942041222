<template>
  <svg
    width="28"
    height="28"
    viewBox="2 1 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6715_47)">
      <path
        d="M16.0001 5.5099L23.9878 7.10746C23.4276 7.28518 22.771 7.49505 22.0604 7.72514C19.9226 8.4174 17.2884 9.29534 15.3244 10.0318L15.6756 10.9682C17.6168 10.2402 20.2326 9.36814 22.3685 8.67651C23.4353 8.33106 24.3803 8.03129 25.0586 7.81789C25.1973 7.77423 25.3249 7.7342 25.4401 7.69813L24.5199 18.74L16.0001 25.3666L7.48006 18.7399L6.5353 7.40284L16.0001 5.5099Z"
        stroke="currentColor"
      />
      <path d="M6 7L16 10V26L7 19L6 7Z" fill="currentColor" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>