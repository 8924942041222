<template lang="pug">
  v-treeview( ref='treeview' v-bind="$attrs" :items="filteredItems" v-on="$listeners")
    template(v-slot:label="label")
      slot(name="label" v-bind="{ ...label, highlighted: itemHighlightedText(label.item) }")
</template>

<script>
export default {
  name: "treeview-with-search",

  props: {
    items: Array,
    search: String,
    itemSearchFor: [String, Function],
    itemHighlighted: [String, Function],
  },

  computed: {
    filteredItems() {
      if (!this.search) return this.items
      return this.filterTree(this.items, this.search.toLowerCase())
    },
  },

  methods: {
    filterTree(items, search) {
      return items
        .map(item => {
          const regex = new RegExp(search.split(' ').reduce((str, word) => str += '(?=.*' + word + ')', ''), 'i')
          if(typeof(this.itemSearchFor)  === 'function') {
            if((this.itemSearchFor(item)).search(regex) >= 0){
              return item
            }
          }
          else {
            if((item[this.itemSearchFor]).search(regex) >= 0){
              return item
            }
          }
          if (item.children) {
            const filteredChildren = this.filterTree(item.children, search)
            if (filteredChildren.length) {
              return {
                ...item,
                children: filteredChildren,
              };
            }
          }
          return null
        })
        .filter(item => item !== null)
    },

    itemHighlightedText(item) {
      if(this.search){
        const highlightWords = this.search.trim().split(' ')
        const highlightRegexp = new RegExp(highlightWords.join('|'), 'gi')
        if(typeof(this.itemHighlighted) === 'function'){
          return this.itemHighlighted(item).replaceAll(highlightRegexp, (word) => '<span style="color:#3B93AF;">' + word + '</span>')
        }
        else {
         return item[this.itemHighlighted].replaceAll(highlightRegexp, (word) => '<span style="color:#3B93AF;">' + word + '</span>') 
        }
      }
      else {
        if(typeof(this.itemHighlighted) === 'function'){
          return this.itemHighlighted(item)
        }
        else {
         return item[this.itemHighlighted]
        }
      }
    },

    updateAll(val) {
      this.$refs.treeview.updateAll(val)
    }
  },

}
</script>

<style>

</style>