import { getDrawings3DForTasks, selectDrawing3DForTasks } from "@/components/drawings/facades/imageSectionPlanes.facade"
import { XeokitMediator } from "../XeokitMediator"

/*eslint-disable no-dupe-class-members*/
class Snapshot {
  // TODO Добавить выбранные элементы из коллизий
  // TODO Добавить выбранный этаж из панели куба сечений
  static getTools() {

    const sectionPlanes = XeokitMediator.SectionPlanes.sectionPlanes.map(sectionPlane => ({
      pos: Array.from(sectionPlane.pos), 
      dir: Array.from(sectionPlane.dir), 
      active: sectionPlane.active,
    }))

    const measurements = Object.values(XeokitMediator.DistanceMeasurement.distanceMeasurements).map(measurement => ({
      origin: {
        worldPos: Array.from(measurement.originWorldPos),
      },
      target: {
        worldPos: Array.from(measurement.targetWorldPos),
      },
      visible: measurement.visible,
      mode: measurement.mode,
      createDate: measurement.createDate
    }))
    
    const sectionCube = {
      active: XeokitMediator.SectionCube.active,
      visible: XeokitMediator.SectionCube.visible,
      aabb: Array.from(XeokitMediator.SectionCube.aabb || []),
    }

    const coordinatePins = Object.values(XeokitMediator.PickCoordinate.coordinatePins).map(coordinatePin => ({
      coordinate: Array.from(coordinatePin.coordinate),
      visible: coordinatePin.visible,
      creationDate: coordinatePin.creationDate
    }))

    const drawings3D = getDrawings3DForTasks()
    const pickedElement = XeokitMediator.ElementsSelection.pickedElement
    const selectedElements = XeokitMediator.ElementsSelection.selectedElements
    const activeHideSelectedObjects = XeokitMediator.ElementsSelection.isHideSelectedElements
    const activeShowOnlySelectedObjects = XeokitMediator.ElementsSelection.isShowOnlySelectedElements

    const tools = {
      sectionPlanes,
      measurements,
      sectionCube,
      pickedElement,
      selectedElements,
      activeHideSelectedObjects,
      activeShowOnlySelectedObjects,
      drawings3D,
      coordinatePins
    }

    return tools
  }

  static getCamera() {
    const camera = {
      eye: Array.from(XeokitMediator.viewer.camera.eye),
      look: Array.from(XeokitMediator.viewer.camera.look),
      up: Array.from(XeokitMediator.viewer.camera.up),

      projection: XeokitMediator.ProjectionMode.projectionMode,
      scale: XeokitMediator.viewer.camera.ortho.scale
    }

    return camera
  }

  static getSnapshot() {
    return XeokitMediator.viewer.getSnapshotWithPlugins()
  }

  static async getSnapshotWithTools() {
    const snapshot = await XeokitMediator.viewer.getSnapshotWithPlugins()
    const tools = this.getTools()
    const camera = this.getCamera()

    return {
      snapshot,
      tools,
      camera,
    }
  }

  static async restoreCamera(camera) {
    await XeokitMediator.ProjectionMode.setProjectionMode(camera.projection || XeokitMediator.ProjectionMode.projectionMode)
    XeokitMediator.viewer.camera.ortho.scale = camera.scale || XeokitMediator.viewer.camera.ortho.scale
    XeokitMediator.CameraFlight.flyTo({
      eye: camera.eye,
      look: camera.look,
      up: camera.up,

      duration: 1.5,
    })
  }

  static restoreTools(tools, removeOldTools = false) {
    if (removeOldTools) {
      this.removeTools() 
    }

    tools.sectionPlanes.forEach(sectionPlane => {
      const _sectionPlane = XeokitMediator.SectionPlanes.createSectionPlane(sectionPlane.pos, sectionPlane.dir)
      sectionPlane.active ? XeokitMediator.SectionPlanes.activateSectionPlane(_sectionPlane.id) : XeokitMediator.SectionPlanes.deactivateSectionPlane(_sectionPlane.id)
    })

    XeokitMediator.SectionCube.setActive(tools.sectionCube.active)
    XeokitMediator.SectionCube.setAabb(tools.sectionCube.aabb)
    XeokitMediator.SectionCube.setVisible(tools.sectionCube.visible)

    tools.measurements.forEach(measurement => {
      const measurementVisible = measurement?.visible ?? true
      XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
        originWorldPos: measurement.origin.worldPos,
        targetWorldPos: measurement.target.worldPos,
        visible: measurementVisible,
        mode: measurement.mode,
        needAxes: measurement.mode == "ruler" ? true : false,
        createDate: measurement.createDate
      })
    })

    const coordinatePins = tools?.coordinatePins || []

    coordinatePins.forEach(coordinatePin => {
      XeokitMediator.PickCoordinate.createCoordinatePin(coordinatePin)
    })

    const drawings3D = tools?.drawings3D || {}
    
    if (drawings3D.drawing3DUuids && drawings3D.selectedDrawing3DUuid) {
      selectDrawing3DForTasks(drawings3D.selectedDrawing3DUuid)
    }

    XeokitMediator.ElementsSelection.selectElements(tools.selectedElements)
    XeokitMediator.ElementsSelection.pickElement(tools.pickedElement)
    if (tools.activeHideSelectedObjects) XeokitMediator.ElementsSelection.setHideSelectedElements(tools.activeHideSelectedObjects)
    if (tools.activeShowOnlySelectedObjects) XeokitMediator.ElementsSelection.setShowOnlySelectedElements(tools.activeShowOnlySelectedObjects)
  }

  static removeTools() {
    XeokitMediator.SectionPlanes.clearSectionPlanes()

    XeokitMediator.SectionCube.setActive(false)

    XeokitMediator.DistanceMeasurement.clearAllMeasurement()
    XeokitMediator.PickCoordinate.destroyAllCoordinatePins()

    XeokitMediator.ElementsSelection.selectElements([])
    XeokitMediator.ElementsSelection.pickElement(null)
    XeokitMediator.ElementsSelection.setHideSelectedElements(false)
    XeokitMediator.ElementsSelection.setShowOnlySelectedElements(false)
  }
}

export { Snapshot }