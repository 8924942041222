import { SceneObjects } from '@/plugins/threeJs/plugins/sceneObjects'
import { FontLoader } from './fork/examples/jsm/loaders/fontLoader'

export class ThreeMediator {

  static #_fontLoader = new FontLoader()

  static get SceneObjects() { 
    return SceneObjects 
  }

  static get fontLoader() {
    return this.#_fontLoader
  }
}