import { ImageSectionPlanesPlugin } from "@/plugins/xeokit/imagePlanes/imageSectionPlanesPlugin"

/*eslint-disable no-dupe-class-members*/
export class ImageSectionPlanes {

  static #imageSectionPlanesPlugin = null

  static get imageSectionPlanes() {
    return this.#imageSectionPlanesPlugin?._imageSectionPlanes || {}
  }

  static createImageSectionPlane(cfg) {
    return this.#imageSectionPlanesPlugin.createImageSectionPlane(cfg)
  }

  static removeImageSectionPlanes(imageSectionPlanes = []) {
    this.#imageSectionPlanesPlugin.removeImageSectionPlanes(imageSectionPlanes)
  }

  static removeAll() {
    this.#imageSectionPlanesPlugin.removeAll()
  }

  static init() {
    this.#imageSectionPlanesPlugin = new ImageSectionPlanesPlugin()
  }
}