<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.1445 6.85791L18.0015 8.28613L15.1426 4.28565L6 2L12.8569 6.57129L15.1426 11.1426L12.2861 12.8574L11.9995 16.0005L15.1426 15.7139L16.8574 12.8574L21.4287 15.1431L26 22L23.7144 12.8574L19.7139 10.001L21.1445 6.85791Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>