/* eslint-disable */
import pako from 'pako'
import { math } from '@xeokit/xeokit-sdk';
import { geometryCompressionUtils, utils } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es';
import { XeokitMediator } from '../XeokitMediator';
import { mergeAABBs, rotateAABB, setAABBCenter, shiftAABBCenter, transformAABB } from '../sectionCube/aabb.utils';
import { ModelAABBCache, ObjectAABBCache } from '../modelAABB.temp';
import { geometry } from '../plugins/geometry/geometry';

const chunkSize = 1000
const chunkTimeout = 100

function extract(elements) {
  return {
    positions: elements[0],
    normals: elements[1],
    indices: elements[2],
    edgeIndices: elements[3],
    meshPositions: elements[4],
    meshIndices: elements[5],
    meshEdgesIndices: elements[6],
    meshColors: elements[7],
    entityIDs: elements[8],
    entityMeshes: elements[9],
    entityIsObjects: elements[10],
    instancedPositionsDecodeMatrix: elements[11],
    batchedPositionsDecodeMatrix: elements[12],
    entityMeshIds: elements[13],
    entityMatrices: elements[14],
    entityUsesInstancing: elements[15]
  }
}

async function inflate(deflatedData, loader) {
  loader.fire('processing', true)

  // const inflated = {
  //   positions: new Uint16Array(pako.inflate(deflatedData.positions).buffer),
  //   normals: new Int8Array(pako.inflate(deflatedData.normals).buffer),
  //   indices: new Uint32Array(pako.inflate(deflatedData.indices).buffer),
  //   edgeIndices: new Uint32Array(pako.inflate(deflatedData.edgeIndices).buffer),
  //   meshPositions: new Uint32Array(pako.inflate(deflatedData.meshPositions).buffer),
  //   meshIndices: new Uint32Array(pako.inflate(deflatedData.meshIndices).buffer),
  //   meshEdgesIndices: new Uint32Array(pako.inflate(deflatedData.meshEdgesIndices).buffer),
  //   meshColors: new Uint8Array(pako.inflate(deflatedData.meshColors).buffer),
  //   entityIDs: pako.inflate(deflatedData.entityIDs, { to: 'string' }),
  //   entityMeshes: new Uint32Array(pako.inflate(deflatedData.entityMeshes).buffer),
  //   entityIsObjects: new Uint8Array(pako.inflate(deflatedData.entityIsObjects).buffer),
  //   instancedPositionsDecodeMatrix: new Float32Array(pako.inflate(deflatedData.instancedPositionsDecodeMatrix).buffer),
  //   batchedPositionsDecodeMatrix: new Float32Array(pako.inflate(deflatedData.batchedPositionsDecodeMatrix).buffer),
  //   entityMeshIds: new Uint32Array(pako.inflate(deflatedData.entityMeshIds).buffer),
  //   entityMatrices: new Float32Array(pako.inflate(deflatedData.entityMatrices).buffer),
  //   entityUsesInstancing: new Uint8Array(pako.inflate(deflatedData.entityUsesInstancing).buffer)
  // }

  const inflated = await new Promise(resolve => {
    setTimeout(() => {
      const inflated = {
        positions: new Uint16Array(pako.inflate(deflatedData.positions).buffer),
        normals: new Int8Array(pako.inflate(deflatedData.normals).buffer),
        indices: new Uint32Array(pako.inflate(deflatedData.indices).buffer),
        edgeIndices: new Uint32Array(pako.inflate(deflatedData.edgeIndices).buffer),
        meshPositions: new Uint32Array(pako.inflate(deflatedData.meshPositions).buffer),
        meshIndices: new Uint32Array(pako.inflate(deflatedData.meshIndices).buffer),
        meshEdgesIndices: new Uint32Array(pako.inflate(deflatedData.meshEdgesIndices).buffer),
        meshColors: new Uint8Array(pako.inflate(deflatedData.meshColors).buffer),
        entityIDs: pako.inflate(deflatedData.entityIDs, { to: 'string' }),
        entityMeshes: new Uint32Array(pako.inflate(deflatedData.entityMeshes).buffer),
        entityIsObjects: new Uint8Array(pako.inflate(deflatedData.entityIsObjects).buffer),
        instancedPositionsDecodeMatrix: new Float32Array(pako.inflate(deflatedData.instancedPositionsDecodeMatrix).buffer),
        batchedPositionsDecodeMatrix: new Float32Array(pako.inflate(deflatedData.batchedPositionsDecodeMatrix).buffer),
        entityMeshIds: new Uint32Array(pako.inflate(deflatedData.entityMeshIds).buffer),
        entityMatrices: new Float32Array(pako.inflate(deflatedData.entityMatrices).buffer),
        entityUsesInstancing: new Uint8Array(pako.inflate(deflatedData.entityUsesInstancing).buffer)
      }

      resolve(inflated)
    }, 10)
  })

  loader.fire('processing', false)

  return inflated
}

const decompressColor = (function () {
  const color2 = new Float32Array(3);
  return function (color) {
    color2[0] = color[0] / 255.0;
    color2[1] = color[1] / 255.0;
    color2[2] = color[2] / 255.0;
    return color2;
  };
})();

function load(viewer, options, inflatedData, sceneModel) {

  // const modelPartId = manifestCtx.getNextId();

  sceneModel.positionsCompression = "precompressed";
  sceneModel.normalsCompression = "precompressed";

  const positions = inflatedData.positions;
  const normals = inflatedData.normals;
  const indices = inflatedData.indices;
  const edgeIndices = inflatedData.edgeIndices;
  const meshPositions = inflatedData.meshPositions;
  const meshIndices = inflatedData.meshIndices;
  const meshEdgesIndices = inflatedData.meshEdgesIndices;
  const meshColors = inflatedData.meshColors;
  const entityIDs = JSON.parse(inflatedData.entityIDs);
  const entityMeshes = inflatedData.entityMeshes;
  const entityIsObjects = inflatedData.entityIsObjects;
  const entityMeshIds = inflatedData.entityMeshIds;
  const entityMatrices = inflatedData.entityMatrices;
  const entityUsesInstancing = inflatedData.entityUsesInstancing;

  const numMeshes = meshPositions.length;
  const numEntities = entityMeshes.length;

  const _alreadyCreatedGeometries = {};

  const modelTransform = sceneModel._transforms[options.transformId] || sceneModel.createTransform({
    id: '__default'
  })
  const rtcAABB = math.AABB3()
  const decompressedPositions = geometryCompressionUtils.decompressPositions(positions, inflatedData.batchedPositionsDecodeMatrix)
  const modelAABB = math.AABB3()
  const positionBounds = geometryCompressionUtils.getPositionsBounds(decompressedPositions)
  modelAABB[0] = positionBounds.min[0]
  modelAABB[1] = positionBounds.min[1]
  modelAABB[2] = positionBounds.min[2]
  modelAABB[3] = positionBounds.max[0]
  modelAABB[4] = positionBounds.max[1]
  modelAABB[5] = positionBounds.max[2]
  // geometry.drawer.viewAABBs([modelAABB])
  const transformedAABB = transformAABB(modelAABB, modelTransform.worldMatrix)
  const realModelAABB = setAABBCenter(modelAABB, math.getAABB3Center(transformedAABB))
  // geometry.drawer.viewAABBs([realModelAABB])
  const tileCenter = math.getAABB3Center(realModelAABB)
  rtcAABB[0] = realModelAABB[0] - tileCenter[0]
  rtcAABB[1] = realModelAABB[1] - tileCenter[1]
  rtcAABB[2] = realModelAABB[2] - tileCenter[2]
  rtcAABB[3] = realModelAABB[3] - tileCenter[0]
  rtcAABB[4] = realModelAABB[4] - tileCenter[1]
  rtcAABB[5] = realModelAABB[5] - tileCenter[2]
  const decodeMatrix = geometryCompressionUtils.createPositionsDecodeMatrix(rtcAABB)

  const entityAABBs = []

  return (function* () {
    for (let z = 0; z < numEntities; z += chunkSize) {
      for (let i = z; i < Math.min(z + chunkSize, numEntities); i++) {

        // console.log(i)

        const xktEntityId = entityIDs[i];
        const entityId = options.globalizeObjectIds ? math.globalizeObjectId(sceneModel.id, xktEntityId) : xktEntityId;
        const metaObject = viewer.metaScene.metaObjects[entityId];
        const entityDefaults = {};
        const meshDefaults = {};
        const entityMatrix = entityMatrices.subarray((i * 16), (i * 16) + 16);

        if (metaObject) {

          if (options.excludeIdsMap && entityId && options.excludeIdsMap[entityId]) {
            continue
          }

          if (options.excludeTypesMap && metaObject.type && options.excludeTypesMap[metaObject.type]) {
            continue;
          }

          if (options.includeTypesMap && metaObject.type && (!options.includeTypesMap[metaObject.type])) {
            continue;
          }

          const props = options.objectDefaults ? options.objectDefaults[metaObject.type] || options.objectDefaults["DEFAULT"] : null;

          if (props) {
            if (props.visible === false) {
              entityDefaults.visible = false;
            }
            if (props.pickable === false) {
              entityDefaults.pickable = false;
            }
            if (props.colorize) {
              meshDefaults.color = props.colorize;
            }
            if (props.opacity !== undefined && props.opacity !== null) {
              meshDefaults.opacity = props.opacity;
            }
          }
        }
        else {
          if (options.excludeUnclassifiedObjects) {
            continue;
          }
        }

        const lastEntity = (i === numEntities - 1);

        const meshIds = [];

        for (let j = entityMeshes[i], jlen = lastEntity ? entityMeshIds.length : entityMeshes[i + 1]; j < jlen; j++) {
          var jj = entityMeshIds[j];

          const lastMesh = (jj === (numMeshes - 1));
          const meshId = `${entityId}.mesh.${jj}`;

          // const color = decompressColor(meshColors.subarray((jj * 4), (jj * 4) + 3));
          // const opacity = meshColors[(jj * 4) + 3] / 255.0;
          
          //место для наблюдения
          const color = decompressColor(meshColors.subarray((j * 4), (j * 4) + 3));
          const opacity = meshColors[(j * 4) + 3] / 255.0;

          var tmpPositions = positions.subarray(meshPositions[jj], lastMesh ? positions.length : meshPositions[jj + 1]);

          if (tmpPositions.length == 0) continue // FIX: Если приходят пустые position просто пропускаем ------ на старых моделях

          var tmpNormals = normals.subarray(meshPositions[jj], lastMesh ? positions.length : meshPositions[jj + 1]);
          var tmpIndices = indices.subarray(meshIndices[jj], lastMesh ? indices.length : meshIndices[jj + 1]);
          var tmpEdgeIndices = edgeIndices.subarray(meshEdgesIndices[jj], lastMesh ? edgeIndices.length : meshEdgesIndices[jj + 1]);

          if (entityUsesInstancing[i] === 1) {

            const geometryId = `geometry.${jj}`;

            if (!(geometryId in _alreadyCreatedGeometries)) {

              sceneModel.createGeometry({
                id: geometryId,
                positionsCompressed: tmpPositions,
                normalsCompressed: tmpNormals,
                indices: tmpIndices,
                edgeIndices: tmpEdgeIndices,
                primitive: metaObject && (metaObject.type == 'IfcOpeningElement' || metaObject.type == 'IfcGridAxis') ? 'lines' : 'triangles',
                positionsDecodeMatrix: inflatedData.instancedPositionsDecodeMatrix
              });

              _alreadyCreatedGeometries[geometryId] = true;
            }

            const tempMat4 = math.mat4();
            const defaultScale = math.vec3([1, 1, 1]);
            const defaultPosition = math.vec3([0, 0, 0]);
            const defaultQuaternion = math.identityQuaternion();

            const transform = modelTransform
            const modelOffset = tileCenter || defaultPosition;
            const modelScale = transform.scale || defaultScale;
            const modelQuaternion = transform.quaternion || defaultQuaternion;
            const meshOffset = transform.position
            const meshMatrix = math.composeMat4([meshOffset[0] - modelOffset[0], meshOffset[1] - modelOffset[1], meshOffset[2] - modelOffset[2]], modelQuaternion, modelScale, tempMat4);

            sceneModel.createMesh(utils.apply(meshDefaults, {
              id: meshId,
              color: color,
              opacity: opacity,
              matrix: math.mulMat4(meshMatrix, entityMatrix),
              origin: modelOffset,
              geometryId,
            }));
            
            // Сохранение данных для линейки
            sceneModel._meshes[meshId].positions = tmpPositions
            sceneModel._meshes[meshId].normals = tmpNormals
            sceneModel._meshes[meshId].indices = tmpIndices
            sceneModel._meshes[meshId].edgeIndices = tmpEdgeIndices
            sceneModel._meshes[meshId].positionsDecodeMatrix = inflatedData.instancedPositionsDecodeMatrix
            sceneModel._meshes[meshId].entityMatrix = entityMatrix

            meshIds.push(meshId);

          }
          else {

            const decompressed = []
            geometryCompressionUtils.decompressPositions(tmpPositions, decodeMatrix, decompressed)

            sceneModel.createMesh(utils.apply(meshDefaults, {
              id: meshId,
              origin: tileCenter,
              primitive: metaObject && (metaObject.type == 'IfcOpeningElement' || metaObject.type == 'IfcGridAxis') ? 'lines' : 'triangles',
              // positionsCompressed: tmpPositions,
              positions: decompressed,
              rotation: modelTransform.rotation,
              normalsCompressed: tmpNormals,
              indices: tmpIndices,
              edgeIndices: tmpEdgeIndices,
              // positionsDecodeMatrix: inflatedData.batchedPositionsDecodeMatrix,
              color: color,
              opacity: opacity
            }));

            // Сохранение данных для линейки
            sceneModel._meshes[meshId].positions = tmpPositions
            sceneModel._meshes[meshId].normals = tmpNormals
            sceneModel._meshes[meshId].indices = tmpIndices
            sceneModel._meshes[meshId].edgeIndices = tmpEdgeIndices
            sceneModel._meshes[meshId].positionsDecodeMatrix = inflatedData.batchedPositionsDecodeMatrix

            meshIds.push(meshId);
          }
        }

        if (meshIds.length) {
          sceneModel.createEntity(utils.apply(entityDefaults, {
            id: entityId,
            isObject: (entityIsObjects[i] === 1),
            meshIds: meshIds
          }));

          const entity = sceneModel.objects[entityId]
          entity.xktVersion = "3"

          // const entity = sceneModel.objects[entityId]
          
          // ObjectAABBCache.setObjectAABB(entityId, rotateAABB(shiftAABBCenter(entity.aabb, sceneModel.position), sceneModel.rotation))
          // entityAABBs.push(entity.aabb)

          // if (lastEntity) {
          //   ModelAABBCache.setModelAABB(sceneModel.id, mergeAABBs(entityAABBs))
          // }
        }
      }

      yield { current: Math.min(z + chunkSize, numEntities), total: numEntities }
    }
  })()
}

const ParserV3 = {
  version: 3,
  parse: async function (viewer, options, elements, sceneModel) {
    const loader = XeokitMediator.xktLoader

    const deflatedData = extract(elements);
    const inflatedData = await inflate(deflatedData, loader);

    const generator = load(viewer, options, inflatedData, sceneModel);

    let done
    let destroyed

    sceneModel.once('destroyed', () => {
      destroyed = true
    })

    loader.fire('renderStart')
    
    for (;;) {

      if (done || destroyed)
        break

      await new Promise(resolve => {
        setTimeout(() => {
          let value
          
          if (!sceneModel.destroyed) {
            ({done, value} = generator.next())
          }

          if (value) {
            loader.fire('chunkParse', {
              modelId: sceneModel,
              current: value.current,
              total: value.total,
            })
          }

          resolve()
        }, chunkTimeout)
      })
    }

    loader.fire('renderDone')
  }
}

export { ParserV3 }