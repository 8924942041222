export class XKTLoadingProgress {
  _totalModels = 0
  _currentModel = {}
  _progress = 0
  _done = false
  _processing = true
  _handlers = {}

  _loaded = null
  _totalSize = null

  constructor() {}

  /**
   * @param {"update"} eventName 
   * @param {function} handler 
   */
  on(eventName, handler) {
    this._handlers[eventName] = this._handlers[eventName] || []
    this._handlers[eventName].push(handler)
  }

  get processing() {
    return this._processing
  }

  set processing(value) {
    this._processing = !!value
    this._handlers['update'].forEach(handler => handler(this))
  }

  get progress() {
    return this._progress
  }

  set progress(value) {
    this._progress = value < 0 ? 0 : value > 100 ? 100 : value
    this._handlers['update'].forEach(handler => handler(this))
  }

  get totalModels() {
    return this._totalModels
  }

  set totalModels(value) {
    this._totalModels = value || 0
    this._handlers['update'].forEach(handler => handler(this))
  }

  get currentModel() {
    return this._currentModel
  }

  set currentModel(value) {
    this._currentModel = value || {}
    this._handlers['update'].forEach(handler => handler(this))
  }

  get done() {
    return this._done
  }

  set done(value) {
    this._done = value
    this._handlers['update'].forEach(handler => handler(this))
  }

  get loaded() {
    return this._loaded
  }

  set loaded(value) {
    this._loaded = value
    this._handlers['update'].forEach(handler => handler(this))
  }

  get totalSize() {
    return this._totalSize
  }

  set totalSize(value) {
    this._totalSize = value
    this._handlers['update'].forEach(handler => handler(this))
  }
}