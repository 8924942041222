import { Profile } from "@models/Profile"

export class TaskReportVolume {
  constructor( { uuid, creator, createDate, reportDate, value } = {} ) {
    this.uuid = uuid,
    this.creator = creator ? new Profile(creator) : new Profile(),
    this.createDate = createDate,
    this.reportDate = reportDate,
    this.value = value
  }
}
