import { ReadableGeometry, PhongMaterial, Mesh } from "@xeokit/xeokit-sdk";
import { XeokitMediator } from "./XeokitMediator";
import { geometry } from "./plugins/geometry/geometry";
// import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";

export class BuildDashDottedLineGeometry {

  /** Создает оъект геометрии штрихпунктирного отрезка
   * @param {Object} cfg 
   * @param {Array<Array<Number>>} cfg.positions Начальная и конечная точки отрезка в формате [pos1, pos2]
   * @param {Number} cfg.unitNumber Количество штрихпунктирных делений
   * @returns {Object}
   */
  static buildDashDottedLineGeom(cfg = {}) {

    let pos1 = cfg.positions[0]
    let pos2 = cfg.positions[1]

    var indices = []
    var positions = []

    let fullUnitNumber = cfg.unitNumber // количество делений 6:1:1:1
    let shortUnitNumber = fullUnitNumber * 9 // количество коротких делений во всем полном делении
    let lineVec = geometry.math.subVec3(pos2, pos1) // вектор линии
    let vecLength = geometry.math.lenVec3(lineVec) // длина линии
    let shortUnitLength = vecLength / shortUnitNumber // длина короткого деления
    let longUnitLength = shortUnitLength * 6 // длина большого деления
    let lineVecNorm = geometry.math.normalizeVec3(lineVec) // нормализованный вектор линии
    let lastUnitPos = null // последняя отрисованная точка

    positions.push(pos1[0])
    positions.push(pos1[1])
    positions.push(pos1[2])
    indices.push(0)

    for (let i = 1; i < fullUnitNumber * 4; i+=4) { // пока длина не соответствует указанной
      
      lastUnitPos = lastUnitPos ?? pos1

      // большое деление
      let firstPos = geometry.math.addVec3(lastUnitPos, geometry.math.mulVec3Scalar(lineVecNorm, longUnitLength))
      positions.push(firstPos[0])
      positions.push(firstPos[1])
      positions.push(firstPos[2])
      indices.push(i)

      // короткий отступ
      let secondPos = geometry.math.addVec3(lastUnitPos, geometry.math.mulVec3Scalar(lineVecNorm, shortUnitLength * 7))
      positions.push(secondPos[0])
      positions.push(secondPos[1])
      positions.push(secondPos[2])
      indices.push(i + 1)

      // короткое деление
      let thirdPos = geometry.math.addVec3(lastUnitPos, geometry.math.mulVec3Scalar(lineVecNorm, shortUnitLength * 8))
      positions.push(thirdPos[0])
      positions.push(thirdPos[1])
      positions.push(thirdPos[2])
      indices.push(i + 2)

      // короткий отступ
      let fourthPos = geometry.math.addVec3(lastUnitPos, geometry.math.mulVec3Scalar(lineVecNorm, shortUnitLength * 9))
      positions.push(fourthPos[0])
      positions.push(fourthPos[1])
      positions.push(fourthPos[2])
      indices.push(i + 3)

      lastUnitPos = fourthPos
    }

    return {
      primitive: "lines",
      positions: positions,
      indices: indices
    }
  }
  
  /** Метод для тестов. Создает (отрисовывает) штрихпунктирную линию. 
   * Вместо него лучше использовать XeokitMediator.SceneObjects.createDashDottedSegmentMesh()
   * @param {Object} cfg 
   * @param {Array<Array<Number>>} cfg.positions Начальная и конечная точки отрезка в формате [pos1, pos2]
   * @param {Number} cfg.unitNumber Количество штрихпунктирных делений
   * @returns {Mesh}
   */
  static createDashDottedLine(positions, unitNumber) {

    let lineMesh = new Mesh(XeokitMediator.viewer.scene, {
      geometry: new ReadableGeometry(XeokitMediator.viewer.scene, this.buildDashDottedLineGeom({positions: positions, unitNumber: unitNumber})),
      material: new PhongMaterial(XeokitMediator.viewer.scene, {
        emissive: [1, 1, 1],
        diffuse: [0, 0, 0],
        ambient: [0, 0, 0],
        lineWidth: 1
      }),
      pickable: false
      
    });

    return lineMesh

  }
}