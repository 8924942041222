import { useDrawingCroppingStore, useDrawingsStore } from "@/pinia"

/*eslint-disable no-dupe-class-members*/
export class CroppingRectController {

  constructor(cfg = {}) {
    this.rectangle = cfg.rectangle
    this.layout = cfg.layout
    this.image = document.getElementById("drawingPageImage")
    this.layoutSizes = this.layout.getBoundingClientRect()
    this.rectangleSizes = this.rectangle.getBoundingClientRect()

    this.croppingRectangle = {
      left: 0,
      top: 0,
      right: null,
      bottom: null
    }

    this.croppingRectangleRelative = {
      left: 0,
      top: 0,
      right: 1,
      bottom: 1
    }

    this.LEFT_MARGIN = this.layoutSizes.left
    this.TOP_MARGIN = this.layoutSizes.top

    this.topLeftCorner = cfg.rectangle.getElementsByClassName('rectangle__top-left-corner')[0]
    this.topRightCorner = cfg.rectangle.getElementsByClassName('rectangle__top-right-corner')[0]
    this.bottomLeftCorner = cfg.rectangle.getElementsByClassName('rectangle__bottom-left-corner')[0]
    this.bottomRightCorner = cfg.rectangle.getElementsByClassName('rectangle__bottom-right-corner')[0]

    this.topLeftCornerMouseDownListener = null
    this.topRightCornerMouseDownListener = null
    this.bottomLeftCornerMouseDownListener = null
    this.bottomRightCornerMouseDownListener = null

    this.layoutMouseDownListener = null
    this.layoutMouseLeaveListener = null
    this.layoutMouseUpListener = null
    this.layoutMouseMoveListener = null

    this.topLeftCornerPressed = false
    this.topRightCornerPressed = false
    this.bottomLeftCornerPressed = false
    this.bottomRightCornerPressed = false

    this.rectangleMouseDownListener = null
  }

  activate() {
    this.#activateCornerMouseDownListeners()
    this.#activateLayoutMouseDownListener()
    this.#activateLayoutMouseleaveListener()
    this.#activateLayoutMouseUpListener()
    this.#activateLayoutMouseMoveListener()
  }

  deactivate() {
    this.rectangle.removeEventListener('mousedown', this.rectangleMouseDownListener)
    this.#deactivateCornerMouseDownListeners()
    this.#deactivateLayoutListeners()
  }

  #activateCornerMouseDownListeners() {
    this.topLeftCorner.addEventListener("mousedown", this.topLeftCornerMouseDownListener = () => {
      useDrawingCroppingStore().rectangleGrabbing = true
      this.topLeftCornerPressed = true
    })
    this.topRightCorner.addEventListener("mousedown", this.topRightCornerMouseDownListener = () => {
      useDrawingCroppingStore().rectangleGrabbing = true
      this.topRightCornerPressed = true
    })
    this.bottomLeftCorner.addEventListener("mousedown", this.bottomLeftCornerMouseDownListener = () => {
      useDrawingCroppingStore().rectangleGrabbing = true
      this.bottomLeftCornerPressed = true
    })
    this.bottomRightCorner.addEventListener("mousedown", this.bottomRightCornerMouseDownListener = () => {
      useDrawingCroppingStore().rectangleGrabbing = true
      this.bottomRightCornerPressed = true
    })
  }

  #activateLayoutMouseDownListener() {
    this.layout.addEventListener("mousedown", this.layoutMouseDownListener = () => {
      this.imageSizes = this.image.getBoundingClientRect()
      this.layoutSizes = this.layout.getBoundingClientRect()
      this.TOP_MARGIN = this.layoutSizes.top
      this.LEFT_MARGIN = this.layoutSizes.left
    })
  }

  #activateLayoutMouseleaveListener() {
    this.layout.addEventListener("mouseleave", this.layoutMouseLeaveListener = () => {
      this.#saveCoordinates()
      this.#unpress()
    })
  }

  #activateLayoutMouseUpListener() {
    this.layout.addEventListener("mouseup", this.layoutMouseUpListener = () => {
      this.#saveCoordinates()
      this.#unpress()
    })
  }

  #activateLayoutMouseMoveListener() {
    useDrawingsStore()
    
    let heightRelation = 1
    let widthRelation = 1
    let topRelation = 0
    let leftRelation = 0

    let bottomRelation = 0
    let rightRelation = 0

    this.layout.addEventListener("mousemove", this.layoutMouseMoveListener = (e) => {
      const layoutHeight = this.layoutSizes.height
      const layoutWidth = this.layoutSizes.width

      if (this.bottomRightCornerPressed) {

        let height = e.clientY - this.TOP_MARGIN
        let width = e.clientX - this.LEFT_MARGIN
        let right = layoutWidth - (e.clientX - this.LEFT_MARGIN)
        let bottom = layoutHeight - (e.clientY - this.TOP_MARGIN)

        heightRelation = (height / layoutHeight) - topRelation
        widthRelation = (width / layoutWidth) - leftRelation

        bottomRelation = bottom / layoutHeight
        rightRelation = right / layoutWidth

        this.rectangle.style.height = `${heightRelation * 100}%`
        this.rectangle.style.width = `${widthRelation * 100}%`
      }

      if (this.topRightCornerPressed) {

        let top = e.clientY - this.TOP_MARGIN
        let width = e.clientX - this.LEFT_MARGIN
        let right = layoutWidth - (e.clientX - this.LEFT_MARGIN)
      
        topRelation = top / layoutHeight
        widthRelation = (width / layoutWidth) - leftRelation
        heightRelation = 1 - bottomRelation - topRelation
        rightRelation = right / layoutWidth

        this.rectangle.style.top = `${topRelation * 100}%`
        this.rectangle.style.width = `${widthRelation * 100}%`
        this.rectangle.style.height = `${heightRelation * 100}%`
      }

      if (this.topLeftCornerPressed) {

        let left = e.clientX - this.LEFT_MARGIN
        let top = e.clientY - this.TOP_MARGIN
      
        topRelation = top / layoutHeight
        leftRelation = left / layoutWidth
        heightRelation = 1 - bottomRelation - topRelation
        widthRelation = 1 - leftRelation - rightRelation

        this.rectangle.style.top = `${topRelation * 100}%`
        this.rectangle.style.left = `${leftRelation * 100}%`
        this.rectangle.style.height = `${heightRelation * 100}%`
        this.rectangle.style.width = `${widthRelation * 100}%`
      }

      if (this.bottomLeftCornerPressed) {
        
        let left = e.clientX - this.LEFT_MARGIN
        let height = e.clientY - this.TOP_MARGIN
        let bottom = layoutHeight - (e.clientY - this.TOP_MARGIN)

        leftRelation = left / layoutWidth
        heightRelation = (height / layoutHeight) - topRelation
        widthRelation = 1 - leftRelation - rightRelation
        bottomRelation = bottom / layoutHeight

        this.rectangle.style.height = `${heightRelation * 100}%`
        this.rectangle.style.width = `${widthRelation * 100}%`
        this.rectangle.style.left = `${leftRelation * 100}%`
      }

      this.croppingRectangleRelative = {
        left: leftRelation,
        top: topRelation,
        width: widthRelation,
        height: heightRelation
      }
    })
  }

  #saveCoordinates() {
    useDrawingCroppingStore().setCroppingRectangleRelative(this.croppingRectangleRelative)
  }

  #deactivateCornerMouseDownListeners() {
    this.topLeftCorner.removeEventListener('mousedown', this.topLeftCornerMouseDownListener)
    this.topRightCorner.removeEventListener('mousedown', this.topRightCornerMouseDownListener)
    this.bottomLeftCorner.removeEventListener('mousedown', this.bottomLeftCornerMouseDownListener)
    this.bottomRightCorner.removeEventListener('mousedown', this.bottomRightCornerMouseDownListener)
  }

  #deactivateLayoutListeners() {
    this.layout.removeEventListener('mousedown', this.layoutMouseDownListener)
    this.layout.removeEventListener('mouseup', this.layoutMouseUpListener)
    this.layout.removeEventListener('mouseleave', this.layoutMouseLeaveListener)
    this.layout.removeEventListener('mousemove', this.layoutMouseMoveListener)
  }

  #unpress() {
    this.topLeftCornerPressed = false
    this.topRightCornerPressed = false
    this.bottomLeftCornerPressed = false
    this.bottomRightCornerPressed = false
    useDrawingCroppingStore().rectangleGrabbing = false
  }
}