<template>
 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3H9V4H4V9H3V3ZM20 4H15V3H21V9H20V4ZM4 20V15H3V21H9V20H4ZM20 20V15H21V21H15V20H20Z" fill="currentColor"/>
  <path d="M12 7L16.3301 9.5V14.5L12 17L7.66987 14.5V9.5L12 7Z" fill="currentColor"/>
 </svg>


</template>

<script>
export default {};
</script>

<style>
</style>