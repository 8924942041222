import { useDB } from '@/api/idb/db'
import { App } from '@app/App'

const floatingPanelStoreName = 'floatingPanels'
const floatingPanelStoreKey = 'floatingPanel'

const defaultClosePanelsKeys = {
  PROJECT_MODEL_STATUS: true,
  PROJECT_MODEL_ATTACHMENTS: true,
  MAINTENANCE_DATA: true,
  STRUCTURE_PATCH_LIST: true,
  MAINTENANCE_LINKED_ELEMENTS: true,
}

export default {
  namespaced: true,

  state: {
    visibleArray: [],
    floatingPanelsList: [],
  },

  getters: {
    floatingPanels: ({floatingPanelsList}) => async(floatingPanelKey) => {
      const data = floatingPanelsList.find(panel => panel?.floatingPanel === floatingPanelKey)      
      if (data) return data

      const defaultData = (floatingPanel, height, opened) => ({
        floatingPanel,
        height,
        opened,
      })

      const def = defaultData(floatingPanelKey, 0, !defaultClosePanelsKeys[floatingPanelKey])
      
      return useDB().getOrCreate(floatingPanelStoreName, floatingPanelKey, def).then(data => {    
        return data
      })
    },

    getVisibleArray: (state) => () => {
      return state.visibleArray
    }
  },
  
  mutations: {
    setVisibleArray(state, payload) {
      state.visibleArray = payload
    }
  },

  actions: {
    updateFloatingPanels: ({ state }, newFloatingPanels) => {
      if (App?.version?.isCloud || newFloatingPanels?.length === 0) return
      newFloatingPanels?.forEach(newPanel => {
        const index = state.floatingPanelsList.findIndex(panel => panel?.floatingPanel === newPanel.floatingPanel)
        index !== -1 ? state.floatingPanelsList[index] = newPanel : state.floatingPanelsList.push(newPanel)
      })
      return useDB().putAll(floatingPanelStoreName, newFloatingPanels, floatingPanelStoreKey)
    }
  }
}