import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"

export function createCfgByAnchorPointsData(cfg) {
  const scenePoints = cfg.sceneAnchorPoints
  const drawing2Points = cfg.drawingAnchorPoints
  const drawing3Points = cfg.drawing3DAnchorPoints

  const rectRel = cfg.croppingRectangleRelative
  const originHeight = cfg.originalHeight
  const originWidth = cfg.originalWidth

  const topAbs = rectRel.top * originHeight
  const leftAbs = rectRel.left * originWidth
  const rightAbs = (rectRel.width + rectRel.left) * originWidth
  const bottomAbs = (rectRel.height + rectRel.top) * originHeight

  const croppingRectangle = {
    left: leftAbs,
    top: topAbs,
    right: rightAbs,
    bottom: bottomAbs
  }

  const croppingHeight = croppingRectangle.bottom - croppingRectangle.top 
  const croppingWidth = croppingRectangle.right - croppingRectangle.left

  const dRelative2_1 = drawing2Points.first
  const dRelative2_2 = drawing2Points.second

  const s_1 = scenePoints.first
  const s_2 = scenePoints.second

  const d3_1 = drawing3Points.first
  const d3_2 = drawing3Points.second

  const d2X_1 = dRelative2_1[0] * croppingWidth
  const d2Y_1 = dRelative2_1[1] * croppingHeight
  const d2X_2 = dRelative2_2[0] * croppingWidth
  const d2Y_2 = dRelative2_2[1] * croppingHeight

  let smallCroppingSide
  let bigCroppingSide
  if (croppingHeight > croppingWidth) {
    smallCroppingSide = croppingWidth
    bigCroppingSide = croppingHeight
  }
  else {
    smallCroppingSide = croppingHeight
    bigCroppingSide = croppingWidth
  }

  let smallSide
  let bigSide
  
  const d2_MinX = Math.min(d2X_1, d2X_2)
  const d2_MaxX = Math.max(d2X_1, d2X_2)
  const d2_MinY = Math.min(d2Y_1, d2Y_2)
  const d2_MaxY = Math.max(d2Y_1, d2Y_2)
  const d2_Height = d2_MaxY - d2_MinY
  const d2_Width = d2_MaxX - d2_MinX
  if (d2_Height > d2_Width) {
    smallSide = d2_Width
    bigSide = d2_Height
  }
  else {
    smallSide = d2_Height
    bigSide = d2_Width
  }
  
  let d3_smallSide
  let d3_bigSide

  const d3_minX = Math.min(d3_1[0], d3_2[0])
  const d3_maxX = Math.max(d3_1[0], d3_2[0])
  const d3_minY = Math.min(d3_1[1], d3_2[1])
  const d3_maxY = Math.max(d3_1[1], d3_2[1])

  const d3_deltaX = d3_maxX - d3_minX
  const d3_deltaY = d3_maxY- d3_minY
  if (d3_deltaX > d3_deltaY) {
    d3_smallSide = d3_deltaY
    d3_bigSide = d3_deltaX
  }
  else {
    d3_smallSide = d3_deltaX
    d3_bigSide = d3_deltaY
  }
  
  const smallRelation = smallCroppingSide / smallSide
  const bigRelation = bigCroppingSide / bigSide

  const d3_originDistance = geometry.math.distance(d3_1, d3_2)
  const d3_smallRelation =  d3_smallSide / d3_originDistance
  const d3_bigRelation = d3_bigSide / d3_originDistance

  const s_distance = geometry.math.distance(s_1, s_2)
  const widthSize = s_distance * d3_smallRelation * smallRelation
  const heightSize = s_distance * d3_bigRelation * bigRelation

  const sVec = geometry.math.subVec3(s_2, s_1)

  const originNormal = [0, 0, 1]
  const sceneNormal = cfg.normal

  const sVecNormalized = geometry.math.normalizeVec3(sVec)
  const oNormal_sNormal_quat = geometry.math.quaternionByTwoVectors(originNormal, sceneNormal)

  const d3_1_firstRotated = geometry.math.vec3ApplyQuaternion(oNormal_sNormal_quat, d3_1)
  const d3_2_firstRotated = geometry.math.vec3ApplyQuaternion(oNormal_sNormal_quat, d3_2)
  const d3_vecFirstRotated = geometry.math.subVec3(d3_2_firstRotated, d3_1_firstRotated)
  
  const d3_vecFirstRotatedNormalized = geometry.math.normalizeVec3(d3_vecFirstRotated)
  const d3VecRotated_sVec_quat = geometry.math.quaternionByTwoVectors(d3_vecFirstRotatedNormalized, sVecNormalized)

  const quaternion = geometry.math.mulQuaternions(d3VecRotated_sVec_quat, oNormal_sNormal_quat)

  const d3Scaled_1 = geometry.math.mulVec3Scalar(d3_1, widthSize)
  const d3Scaled_2 = geometry.math.mulVec3Scalar(d3_2, widthSize)

  const d3Rotated_1 = geometry.math.vec3ApplyQuaternion(quaternion, d3Scaled_1)

  const d3Rotated_2 = geometry.math.vec3ApplyQuaternion(quaternion, d3Scaled_2)
  
  const d3_distance = geometry.math.distance(d3Rotated_1, d3Rotated_2)
  const d3Rotated_vec = geometry.math.subVec3(d3Rotated_2, d3Rotated_1)
  const d3Rotated_vecNorm = geometry.math.normalizeVec3(d3Rotated_vec)
  const d3_half = geometry.math.mulVec3Scalar(d3Rotated_vecNorm, d3_distance / 2)
  const d3_center = geometry.math.addVec3(d3Rotated_1, d3_half)
  
  const s_vec = geometry.math.subVec3(s_2, s_1)
  const s_vecNorm = geometry.math.normalizeVec3(s_vec)
  const s_half = geometry.math.mulVec3Scalar(s_vecNorm, s_distance / 2)
  const s_center = geometry.math.addVec3(s_1, s_half)

  const offsetVec = geometry.math.subVec3(s_center, d3_center)
  
  const baseUp = [0, 1, 0]
  const convertedNormal = geometry.math.vec3ApplyQuaternion(quaternion, originNormal)
  const convertedUp = geometry.math.vec3ApplyQuaternion(quaternion, baseUp)

  return {
    widthSize: widthSize,
    heightSize: heightSize,
    quaternion: quaternion,
    position: offsetVec,
    normal: convertedNormal,
    up: convertedUp
  }
}