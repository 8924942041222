<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4_25)">
      <path
        d="M3.5 6V3.5H6"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M3.5 8V12"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M3.5 26V28.5H6"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M3.5 13.9925V17.9925"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M3.5 19.995V23.995"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M28.475 6V3.5H25.975"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M28.475 8V12"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M24 3.5H20"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M28.475 26V28.5H25.975"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M28.475 13.9925V17.9925"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M18.01 3.5H14.01"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M28.475 19.995V23.995"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M12.005 3.5H8.005"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M24 28.4875H20"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M18.01 28.4875H14.01"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        d="M12.005 28.4875H8.005"
        stroke="currentColor"
        stroke-width="0.5675"
        stroke-miterlimit="22.9256"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 14V29H29V14H14ZM25.6675 24.4925L24.4925 25.6675L21.5 22.675L18.51 25.6675L17.335 24.4925L20.325 21.5L17.335 18.5075L18.51 17.335L21.5 20.325L24.4925 17.335L25.6675 18.5075L22.675 21.5L25.6675 24.4925Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_25">
        <rect width="32" height="32" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>