export function areOffsetsClose(offset1, offset2, epsilon) {
  return Math.abs(offset1[0] - offset2[0]) < epsilon && Math.abs(offset1[1] - offset2[1]) < epsilon && Math.abs(offset1[2] - offset2[2]) < epsilon
}

export function offsetToString(offset) {
  return Number.parseFloat(offset[0]).toFixed(3).toString() + ", " + 
         Number.parseFloat(offset[1]).toFixed(3).toString() + ", " + 
         Number.parseFloat(offset[2]).toFixed(3).toString();
}

export function createRandomColor() {
  const randomColor = Math.floor( Math.random()*16777215 ).toString(16);
  return "#" + randomColor
}

/**
 * На вход объект вида:
 * "0, 0, 0": [model_1, model_2]
 * "10, 0, 0" : [model_3, model_4, model_5],
 * где ключи - смещения групп
 */
export function offsetsGroupingToMap(offsetsGrouping) {
  const map = {}

  for (const key in offsetsGrouping) {
    const groupUuids = []

    for (const model of offsetsGrouping[key]) {
      groupUuids.push(model.uuid)
      map[model.uuid] = {
        offset: key.split(", "),
        color: model.color
      }
    }

    for (const uuid of groupUuids) {
      map[uuid].groupUuids = groupUuids
    }
  }

  return map
}