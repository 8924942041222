<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 10C7.67 10 7 10.67 7 11.5C7 12.33 7.67 13 8.5 13C9.33 13 10 12.33 10 11.5C10 10.67 9.33 10 8.5 10ZM8.5 4C7.67 4 7 4.67 7 5.5C7 6.33 7.67 7 8.5 7C9.33 7 10 6.33 10 5.5C10 4.67 9.33 4 8.5 4ZM8.5 16C7.67 16 7 16.68 7 17.5C7 18.32 7.68 19 8.5 19C9.32 19 10 18.32 10 17.5C10 16.68 9.33 16 8.5 16ZM11.5 18.5H25.5V16.5H11.5V18.5ZM11.5 12.5H25.5V10.5H11.5V12.5ZM11.5 4.5V6.5H25.5V4.5H11.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>