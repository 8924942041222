<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="32" height="32"/>
  <path d="M24.9655 26C24.1379 26 23.4368 25.7167 22.8621 25.15C22.2874 24.5833 22 23.8889 22 23.0667C22 22.4667 22.1552 21.9 22.4655 21.3667C22.7759 20.8333 23.1264 20.3111 23.5172 19.8L24.9655 18L26.4828 19.8C26.8506 20.3111 27.1954 20.8333 27.5172 21.3667C27.8391 21.9 28 22.4667 28 23.0667C28 23.8889 27.7011 24.5833 27.1034 25.15C26.5057 25.7167 25.7931 26 24.9655 26Z" fill="currentColor"/>
  <path d="M13.218 22.5L7.64661 16.5H20.3534L14.782 22.5H13.218Z" fill="currentColor" stroke="currentColor"/>
  <path d="M6.47479 13.1317L13.8994 5.70711L24.1989 16.0066L21.5795 16.5305L21.4311 16.5602L21.324 16.6673L15.6672 22.3241C14.6909 23.3004 13.108 23.3004 12.1316 22.3241L6.47479 16.6673C5.49848 15.691 5.49848 14.108 6.47479 13.1317Z" stroke="currentColor"/>
</svg>
</template>

<script>
export default {}
</script>