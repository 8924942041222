//import { authUserService } from "../_services"
import { api } from '@/api'
import { TokenDto } from '@/assets/model/Token'

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

export default {
  namespaced: true,

  state: {
    listTokens:  [],

    scopeList: {
      list: [],
      status: status.INITIAL
    },
  },

  /*token: {
    uuid: '',
    project: '',
    discribe: '',
    expiredDate: '',
    level: '',
    status: status.INITIAL
  },*/

  getters: {
    tokens: state => state.listTokens.sort(TokenDto.compareByExpired),

    scopeList: state => {
      return state.scopeList.list
    },

    isScopeListLoaded: ({ scopeList }) => scopeList.status == status.LOADED,
  },

  mutations: {
    /*SET_ITEMS_PER_PAGE: (state, number) => { 
      state.pagination.rowsPerPage = number 
    },
    SET_PAGE: (state, number) => { 
      state.pagination.page= number 
    },

    SET_TOTAL_ELEMENTS_COUNT: (state, number) => { 
      state.pagination.totalItems = number 
    },

    SET_PROJECTS: (state, projects) => {
      state.listProjects = projects
    },

    SET_FILTER: (state, filter) => {
      state.selectedFilter = filter
    },
    SET_SEARCH_TEXT: (state, text) => {
      state.searchText = text
    },*/
    setTokens: (state, tokens) => {
      state.listTokens = tokens
    },

    setScopeList: (state, scopes) => {
      state.scopeList.list = scopes
    },
    
    setScopeListStatus: ({ scopeList }, status) => {
      scopeList.status = status
    },
  },
  
  actions: {
    INIT: ({ dispatch }) => {
      dispatch('LOAD_SCOPE_LIST')
      //dispatch('LOAD_TOKENS', projectUuid)
    },

    LOAD_TOKENS: ({ commit },  projectUuid) => {
      api.tokens.listTokens(projectUuid).then(data => {
        commit('setTokens', data)
      })  
    },

    LOAD_SCOPE_LIST: ({ state, commit }) => {
      if (state.scopeList.status == status.INITIAL ) {
        commit('setScopeListStatus', status.LOADING)
        api.tokens.listScopes().then(data => {
          commit('setScopeList', data)
          commit('setScopeListStatus', status.LOADED)
        })
      }
    },

    createToken: ({ dispatch }, token) => {
      return api.tokens.addToken(token).then(() => dispatch('LOAD_TOKENS',token.project))
    },

    deleteToken: ({ dispatch }, token, projectUuid) => {

      api.tokens.deleteToken(token, projectUuid).then(() => {
        dispatch('LOAD_TOKENS', token.project)

      })
    },
  }
}