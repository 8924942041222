import { Plugin } from "@xeokit/xeokit-sdk";

export const events = Object.freeze({
  AABB_CHANGE: 'sceneAabbChanged'
})

export class AabbEventDispatcher extends Plugin {
  constructor(viewer) {
    super('event-dispatcher', viewer)

    this.viewer = viewer
  }

  fireSceneAabbChanged(aabb = null) {
    // const aabb = sceneAabb ?? getFilteredSceneAABB(this.viewer.scene)
    this.fire(events.AABB_CHANGE, aabb)
  }
}