import { AppAppearAnimation, AppGroupAnimation, AppGroupItem } from './components'

const GlobalComponents = {
  install (Vue) {
    Vue.component(AppAppearAnimation.name, AppAppearAnimation)
    Vue.component(AppGroupAnimation.name, AppGroupAnimation)
    Vue.component(AppGroupItem.name, AppGroupItem)
  }
}

export default GlobalComponents
