<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 2C4 7.40505 4 16.0855 4 19.0053C4 19.5576 4.44772 20 5 20H21"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect x="10" y="4" width="10" height="2" rx="1" fill="currentColor" />
    <path
      d="M7 10C7 9.44772 7.44772 9 8 9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H8C7.44772 11 7 10.5523 7 10Z"
      fill="currentColor"
    />
    <path
      d="M10 15C10 14.4477 10.4477 14 11 14H19C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16H11C10.4477 16 10 15.5523 10 15Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>