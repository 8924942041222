<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6099_125)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0107 18.9181H8.25056C7.95209 16.509 9.42311 17.042 10.0414 15.976V10.8808C10.2332 8.38642 12.152 5.23119 14.8382 4.22919C14.7316 2.92872 15.094 2.1186 16.0107 2.07596C16.9061 2.1186 17.2685 2.92872 17.162 4.22919C19.8482 5.23119 21.7669 8.38642 21.9588 10.8808V15.976C22.577 17.042 24.048 16.509 23.7496 18.9181H16.0107ZM16.0107 19.8348H13.623C13.6656 21.2205 14.7316 22.2225 16.0107 22.2225C17.3112 22.2225 18.3771 21.1566 18.3771 19.8348H16.0107Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6099_125">
        <rect width="32" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>