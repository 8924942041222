<template>
<svg xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24" 
      height="24"
      fill="none"
      width="24">
  <path d="M13 9H11V7H13V9M13 17H11V11H13V17M5 3H19C20.1 3 21 3.89 21 5V19C21 19.53 20.79 20.04 20.41 20.41C20.04 20.79 19.53 21 19 21H5C4.47 21 3.96 20.79 3.59 20.41C3.21 20.04 3 19.53 3 19V5C3 3.89 3.89 3 5 3M19 19V5H5V19H19Z" fill="currentColor" />
</svg>
</template>

<script>
export default {};
</script>