<template>
  <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="17" y="11" width="12" height="5" stroke="currentColor"/>
    <path d="M1 6C1 3.23858 3.23858 1 6 1H12C14.7614 1 17 3.23858 17 6V17H6C3.23858 17 1 14.7614 1 12V6Z" stroke="currentColor"/>
    <path d="M29 16V9" stroke="currentColor"/>
    <path d="M26 16V13" stroke="currentColor"/>
    <path d="M23 16V13" stroke="currentColor"/>
    <path d="M20 16V13" stroke="currentColor"/>
    <circle cx="9" cy="9" r="3.5" fill="currentColor" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>