/**
 * Default data access strategy for {@link LASLoaderPlugin}.
 */
/**
 * Перенесено из \node_modules\@xeokit\xeokit-sdk\src\plugins\LASLoaderPlugin\LASDefaultDataSource.js
 */


/**
 * Gets the contents of the given LAS file in an arraybuffer.
 *
 * @param {String|Number} src Path or ID of an LAS file.
 * @param {Function} ok Callback fired on success, argument is the LAS file in an arraybuffer.
 * @param {Function} error Callback fired on error.
 */

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

let buttonClicked = false

export async function getLAS (src, ok, error, sceneModel) {
  var defaultCallback = () => {};
  ok = ok || defaultCallback;
  error = error || defaultCallback;
  const dataUriRegex = /^data:(.*?)(;base64)?,(.*)$/;
  const dataUriRegexResult = src.match(dataUriRegex);
  if (dataUriRegexResult) { // Safari can't handle data URIs through XMLHttpRequest
    const isBase64 = !!dataUriRegexResult[2];
    var data = dataUriRegexResult[3];
    data = window.decodeURIComponent(data);

    if (isBase64) {
      data = window.atob(data);
    }

    try {
      const buffer = new ArrayBuffer(data.length);
      const view = new Uint8Array(buffer);

      for (var i = 0; i < data.length; i++) {
        view[i] = data.charCodeAt(i);
      }
      ok(buffer);
    } catch (errMsg) {
      error(errMsg);
    }
  } else {
    const request = new XMLHttpRequest();

    request.open('GET', src, true);
    request.responseType = 'arraybuffer';
    request.onreadystatechange = function () {
    if (request.readyState === 4) {
      if (request.status === 200) {
        ok(request.response);
      } else {
        error('getXKT error : ' + request.response);
      }
    }
  };

  let receivedBytesLengthCloud = 0
  buttonClicked = false
  let lastMS = performance.now()
  const byteChunks = []

  request.onprogress = function(event) {

    sceneModel.once('destroyed', () => {
      XeokitMediator.lasLoader.fire("resetCountVisibleCloudModel")
      XeokitMediator.lasLoader.fire("countVisibleCloudModel")
      request.abort()
    })

    if( buttonClicked ) {
      request.abort();
      XeokitMediator.lasLoader.fire("resetCountVisibleCloudModel")
      XeokitMediator.lasLoader.fire("countVisibleCloudModel")
      receivedBytesLengthCloud = 0
    }

    receivedBytesLengthCloud = event.loaded
    if (performance.now() - lastMS > 100) {
      XeokitMediator.lasLoader.fire("receivedBytesLengthCloud", {
        modelId: sceneModel?.id,
        bytes: receivedBytesLengthCloud
      })
      lastMS = performance.now()
    }
  };
  const bytes = new Uint8Array(receivedBytesLengthCloud)

  request.send(null);
  }
}

export function onClickedDelete() {
  buttonClicked = true
}
