<template>
  <svg
    class="svg-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_681_91)">
      <path
        d="M1.22052 2.88208V3H0.372615V2.88208H1.22052ZM0.413045 1.36484V3H0.27491V1.36484H0.413045ZM1.95508 1.46255L1.38906 3H1.24531L1.86299 1.36484H1.96294L1.95508 1.46255ZM2.46943 3L1.90229 1.46255L1.89443 1.36484H1.99438L2.61318 3H2.46943ZM2.36836 2.42275V2.5418H1.50024V2.42275H2.36836ZM3.83403 2.88208V3H2.70761V2.88208H3.83403ZM3.79809 1.46479L2.74467 3H2.6582V2.89443L3.70937 1.36484H3.79809V1.46479ZM3.73744 1.36484V1.48276H2.68739V1.36484H3.73744Z"
        transform="scale(4)"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_681_91">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
  .svg-icon {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>>