import { documentService } from "@/_services";
import { AlertService } from '@app/AlertService'

const getDefaultState = () => {
  return {
    workJournal: null,
    listJournals: [],
    listWorkers: [],

    wjStaffsNewItem: {
      number: null,
      personName: null,
      fullName: null,
      startDate: null,
      worksType: null,
      endDate: null,
      sign: null,
      archive: false,
      status: { value: 0, title: "Активная"},
    },
    wjSpecialJournalsNewItem: {
      number: null,
      specialJournalName: null,
      specialJournalDate: null,
      personName: null,
      date: null,
      sign: null,
      archive: false,
      status: { value: 0, title: "Активная"},
    },
    wjWorksNewItem: {
      number: null,
      date: null,
      workName: null,
      workConditions: null,
      workScope: null,
      workPlace: null,
      workMethod: null,
      sign: null,
      archive: false,
      status: { value: 0, title: "Активная"},
    },
    wjControlsNewItem: {
      number: null,
      infoConstructionControl: null,
      disadvantages: null,
      termEliminationViolations: null,
      signTerm: null,
      dateEliminationViolations: null,
      signDate: null,
      archive: false,
      status: { value: 0, title: "Активная"},
    },
    wjExecutiveDocsNewItem: {
      number: null,
      executiveDocName: null,
      infoAboutSign: null,
      archive: false,
      status: { value: 0, title: "Активная"},
    },
    wjStateControlsNewItem: {
      number: null,
      dateControlEvent: null,
      controlEventsInfo: null,
      termEliminationViolations: null,
      signTerm: null,
      dateEliminationViolations: null,
      signDate: null,
      archive: false,
      status: { value: 0, title: "Активная"},
    },
    wjWorkPersonNewItem: {
      uuid: null,
      workPersonUuid: null,
      number: null, 
      personName: null,
      fullName: null, 
      position: null, 
      worksType: null,
      document: null, 
      identiNumber: null, 
      sign: null,
      project: null,
      juridical: false,
    },
    wjTitleChangesNewItem: {
      number: null,
      date: null,
      changes: null,
      person: null,
      sign: null,
    },
    wjTitleNullItems: {
      journalNumber: null,
      constructionObjectType: null,
      constructionObjectName: null,
      developer: null,
      techCustomer: null,
      operator: null,
      constructionPermit: null,
      documentPerson: null,
      infoPositiveConclusion: null,
      builder: null,
      stateControlInfo: null,
      constructionInfo: null,
      startConstructionDate: null,
      endConstructionDate: null,
      startPeriod: null,
      endPeriod: null,
      developerTeam: [],
      techCustomerTeam: [],
      operatorTeam: [],
      documentPersonTeam: [],
      builderTeam: [],
      developerControlTeam: [],
      operatorControlTeam: [],
      builderControlTeam: [],
      otherPerson: [],
      changes: [],
    },
    archivedItemsNull: {
      s1: [],
      s2: [],
      s3: [],
      s4: [],
      s5: [],
      s6: [],
    },
  }
}
const state = getDefaultState()

export default {
  namespaced: true,
  
  state,

  getters: {
    wjTitle: ({workJournal}) => workJournal.wjTitle,

    wjStaffs: ({workJournal}) => workJournal.wjStaffs,
    wjSpecialJournals: ({workJournal}) => workJournal.wjSpecialJournals,
    wjWorks: ({workJournal}) => workJournal.wjWorks,
    wjControls: ({workJournal}) => workJournal.wjControls,
    wjExecutiveDocs: ({workJournal}) => workJournal.wjExecutiveDocs,
    wjStateControls: ({workJournal}) => workJournal.wjStateControls,
  },

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },

    setActiveWorkJournal: (state, workJournal) => {
      state.workJournal = workJournal
    },
    setListJournals: (state, listJournals) => {
      state.listJournals = listJournals
    },
    setWorkPersonList: (state, listWorkers) => {
      state.listWorkers = listWorkers
    },
  },

  actions: {
    changeActiveJournal({ commit }, workJournalUuid) {
      documentService.getWorkJournal(workJournalUuid).then(data => {
        commit('setActiveWorkJournal', data)
      }).catch(e => {
        AlertService.error(e.response)
      })
    },

    getJournalList({commit}, projectUuid) {
      return documentService.getProjectWorkJournals(projectUuid).then(data => {
        commit('setListJournals', data)
      }).catch(e => {
        AlertService.error(e.response)
      })
    },

    createNewJournal({commit, dispatch}, projectUuid) {
      documentService.createJournal(projectUuid).then(data => {
        commit('setActiveWorkJournal', data)
        dispatch('getJournalList', projectUuid)
      }).catch(e => {
        AlertService.error(e.response)
      })
    },

    saveWorkJournal({commit}, workJournal) {
      documentService.saveJournal(workJournal).then(data => {
        commit('setActiveWorkJournal', data)
        AlertService.success({info:"Сохранено"})
      }).catch(e => {
        AlertService.error(e.response)
      })
    },

    getWorkPersonList({commit}, projectUuid) {
      documentService.getWorkPersonList(projectUuid).then(data => {
        commit('setWorkPersonList', data)
      }).catch(e => {
        AlertService.error(e.response)
      })
    },

    saveNewWorkPerson({commit}, workPerson) {
      documentService.saveNewWorkPerson(workPerson).then(data => {
        commit('setWorkPersonList', data)
      }).catch(e => {
        AlertService.error(e.response)
      })
    }
  }
}