<template lang="pug">
  router-link.drawer-item(v-if="item.path" :to="item.path" tag="div" active-class="drawer-item--selected" @click.native="setSelectedIndex(id)")
    .drawer-item__icon
      //- img(:src="`/icons/${item.icon}`" width="32" height="32")
      v-icon( size="32" color="var(--v-primary-darken2)" ) {{ item.iconName }}
    app-appear-animation
      .drawer-item__title.clr(v-if="!mini") {{ $t(item.name) }}

  div(v-else)
    .drawer-item(:class="{'drawer-item--selected': id == selectedIndex }" @click="opened = !opened")
      .drawer-item__icon
        //- img(:src="`/icons/${item.icon}`" width="32" height="32")
        v-icon( size="32" color="var(--v-primary-darken2)" ) {{ item.iconName }}
      app-appear-animation
        .drawer-item__title.expandable-title(v-if="!mini") {{ $t(item.name) }}
      v-icon.drawer-item__arrow(color="white" size="20" :class="mini ? 'mr-0' : 'mr-6'") {{ opened ? 'arrow_drop_up' : 'arrow_drop_down' }}
    div(v-if="!mini && opened")
      router-link.small-link(v-for="(el, index) in links" :key="index" :to="el.path" tag="div" active-class="small-link--selected" @click.native="setSelectedIndex(id)") {{ el.name }}
  
</template>

<script>
import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'drawer-item',
    props: {
      item: Object,
      id: Number,
      mini: Boolean
    },
    data () {
      return {
        opened: false,
      }
    },
    computed: {
      ...mapState('menu', ['selectedIndex']),

      links () {
        return this.item.items
      }
    },
    methods: {
      ...mapMutations('menu', ['setSelectedIndex']),
    }
  }
</script>

<style lang="scss" scoped>
$drawer-item-height: 80px;
$drawer-max-width: 280px;
$drawer-item-arrow-size: 24px;

.drawer-item {
  height: $drawer-item-height;
  display: flex;
  align-items: center;
  justify-content: stretch;

  &--selected {
    // background: transparentize($color: #C4C4C4, $amount: 0.5);
    background-color: var(--v-surface-darken3)
  }

  &__icon {
    width: 56px;
    padding: 24px 0px 24px 24px;
  }

  &__title {
    font: normal 16px $roboto;
    color: #F0F0F0;

    margin-left: 24px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__arrow {
    width: 24px;
    height: 24px;
  }

  &:hover {
    // background: #19AFDE;
    background: var(--v-surface-darken3);
    opacity: 0.7;
    cursor: pointer;
  }
}

.expandable-title {
  width: calc(#{$drawer-max-width} - 80px - #{$drawer-item-arrow-size});
}

.small-link {
  font: normal 16px/20px $roboto;
  color: #505254;

  padding: 10px 10px 10px 80px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &--selected {
    background: #C4C4C4;
  }

  &:hover {
    background: #19AFDE;
    cursor: pointer;
  }
}
.clr {
  color: var(--v-serface-darken2);
}
</style>