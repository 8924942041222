export { ProjectAxisForm }

class ProjectAxisForm {
  constructor (projectAxis) {
    this.uuid = projectAxis?.uuid || null
    this.title = projectAxis?.name || ''
    this.color = projectAxis?.color || ''
    this.axis = projectAxis?.axis || []
    this.logicGroup = projectAxis && projectAxis.logicGroup ? new LogicGroup(projectAxis.logicGroup) : new LogicGroup()
  }
}

class LogicGroup {
  constructor (logicGroup) {
    this.uuid = logicGroup?.uuid || null
    this.condition = logicGroup && logicGroup.condition ? logicGroup.condition.map(item => new Condition(item)) : [ new Condition() ]
  }
}

class Condition {
  constructor (condition) {
    this.uuid = condition?.uuid || null
    this.title = condition?.title || ''
    this.sortOrder = condition?.sortOrder || 0
    this.operator = condition?.operator || null,
    this.operand = condition?.operand || null,
    this.operandUuid  = condition?.operandUuid || null
    this.value =  condition?.value || ''
  }
}

