<template>
  <svg
    width="32"
    height="32"
    viewBox="0 -1 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="6" rx="3" fill="currentColor" />
    <rect x="23" y="5" width="9" height="4" rx="2" fill="currentColor" />
    <rect x="23" y="15" width="9" height="4" rx="2" fill="currentColor" />
    <rect x="23" y="25" width="9" height="4" rx="2" fill="currentColor" />
    <path
      d="M9 3H14.25C14.25 3 16 3 16 5C16 7 16 25.0334 16 27"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="17"
      y1="7"
      x2="20"
      y2="7"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="17"
      y1="17"
      x2="20"
      y2="17"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="16"
      y1="27"
      x2="20"
      y2="27"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>