<template lang="pug">
  transition(name="appear-slide-fade" mode="out-in" appear) 
    slot
</template>

<script>
export default {
  name: 'app-appear-animation'
}
</script>

<style lang="scss" scoped>
.appear-slide-fade-enter-active {
  transition: all .4s ease;
}
.appear-slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.appear-slide-fade-enter, .appear-slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>