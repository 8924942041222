<template lang="pug">
v-container(fluid px-0 py-0)
    v-row(no-gutters)
      app-page-header.app-page_header( v-if="showHeader" @search="search" :searchValue="searchValue" :showUser="showUser" :searchTitle="headerSearchTitle"
        :profile="headerProfile" :search="headerSearch" :title="headerTitle" )

        template( v-slot:headerContentStart )
          slot( name="headerContentStart" )

        template( v-slot:headerContentCenter )
          slot( name="headerContentCenter" )
        
        template( v-slot:headerContentEnd )
          slot( name="headerContentEnd" )
          
        template( v-slot:headerAfterContent )
          slot( name="headerAfterContent" )
          
        template( v-slot:headerEnd )
          slot( name="headerEnd" )
    
      app-appear-animation
        .col-12.mt-10.px-2
          slot
    app-dialog-loading( v-model="loading" )
    app-fab( v-if="isFabVisible" :items="allFabItems" )
</template>

<script>
import AppPageHeader from '@/components/project/common/PageHeader.vue'
import AppFab from '@/components/project/common/FAB.vue'

export default {
  name: "AppPage",
  components: { 
    AppPageHeader,
    AppFab
  },
  props: {
    title: String,
    searchValue: { type: String, default: "" },
    loading: Boolean,
    fabItems: Array,
    fabWithoutDefaults: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    showUser: { type: Boolean, default: true },
    showFab: { type: Boolean, default: true },
    headerProfile: null,
    headerTitle: null,
    headerSearch: null,
    headerSearchTitle: null,
  },

  mounted() {
    document.title = this.title || this.$t('app.page.defaultTitle')
  },

  computed: {
    // fabWithoutDefaults - установлен в true, чтобы скрыть нереализованые на данный момент пункты "Сообщение" и "Заметку"
    allFabItems() {
      let items = this.fabItems || [];
      return this.fabWithoutDefaults
        ? items
        : [...this.fabDefaultItems, { divider: true }, ...items];
    },

    fabDefaultItems() {
      return [
        {
          icon: "assignment",
          title: this.$t('app.page.message'),
          action: () => this.$root.$emit("dev")
        },
        {
          icon: "book",
          title: this.$t('app.page.note'),
          action: () => this.$root.$emit("dev")
        }
      ];
    },

    isFabVisible() {
      return this.showFab && this.allFabItems.length > 0;
    }
  },

  methods: {
    search(text) {
      this.$emit("search", text);
    },
  }
};
</script>

<style scoped>
.app-page_header{
  position: fixed;
  z-index: 5;
  width: 100%;
}
</style>
