import { TrianglesInstancingLayer } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es'

TrianglesInstancingLayer.prototype.destroy = function () {
  const state = this._state;
  if (!state) {
    return
  }
  if (state.colorsBuf) {
    state.colorsBuf.destroy();
    state.colorsBuf = null;
  }
  if (state.metallicRoughnessBuf) {
    state.metallicRoughnessBuf.destroy();
    state.metallicRoughnessBuf = null;
  }
  if (state.flagsBuf) {
    state.flagsBuf.destroy();
    state.flagsBuf = null;
  }
  if (state.offsetsBuf) {
    state.offsetsBuf.destroy();
    state.offsetsBuf = null;
  }
  if (state.modelMatrixCol0Buf) {
    state.modelMatrixCol0Buf.destroy();
    state.modelMatrixCol0Buf = null;
  }
  if (state.modelMatrixCol1Buf) {
    state.modelMatrixCol1Buf.destroy();
    state.modelMatrixCol1Buf = null;
  }
  if (state.modelMatrixCol2Buf) {
    state.modelMatrixCol2Buf.destroy();
    state.modelMatrixCol2Buf = null;
  }
  if (state.modelNormalMatrixCol0Buf) {
    state.modelNormalMatrixCol0Buf.destroy();
    state.modelNormalMatrixCol0Buf = null;
  }
  if (state.modelNormalMatrixCol1Buf) {
    state.modelNormalMatrixCol1Buf.destroy();
    state.modelNormalMatrixCol1Buf = null;
  }
  if (state.modelNormalMatrixCol2Buf) {
    state.modelNormalMatrixCol2Buf.destroy();
    state.modelNormalMatrixCol2Buf = null;
  }
  if (state.pickColorsBuf) {
    state.pickColorsBuf.destroy();
    state.pickColorsBuf = null;
  }
  state.destroy();
  this._state = null;
}